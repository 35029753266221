import { Component, Input } from '@angular/core';
import { GkKendoGridFooterConfig } from './gk-kendo-grid-footer.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gk-kendo-grid-footer',
  templateUrl: './gk-kendo-grid-footer.component.html',
  styleUrls: ['./gk-kendo-grid-footer.component.scss'],
  standalone: false,
})
export class GkKendoGridFooterComponent {
  @Input()
  footerConfig: GkKendoGridFooterConfig;
  @Input()
  counter: BehaviorSubject<number>;
}
