@for (mapObject of mapObjects; let feat = $index; track feat) {
  @if (mapObject.geom) {
    @for (
      geometry of getGeometryCollectionWithWktType(mapObject)
        .mapGeometryCollection;
      let part = $index;
      track part
    ) {
      @switch (getGeometryCollectionWithWktType(mapObject).wktType) {
        @case (wktTypeEnum.Point) {
          <gk-point
            [id]="getGeometryId(mapObject, feat, part)"
            [styles]="styles"
            [geometry]="getPoint(geometry)"
            [projectionCode]="projectionCode"
          ></gk-point>
        }
        @case (wktTypeEnum.MultiPoint) {
          <gk-point
            [id]="getGeometryId(mapObject, feat, part)"
            [styles]="styles"
            [geometry]="getPoint(geometry)"
            [projectionCode]="projectionCode"
          ></gk-point>
        }
        @case (wktTypeEnum.LineString) {
          <gk-line-string
            [id]="getGeometryId(mapObject, feat, part)"
            [styles]="styles"
            [geometry]="getLineString(geometry)"
            [projectionCode]="projectionCode"
          ></gk-line-string>
        }
        @case (wktTypeEnum.MultiLineString) {
          <gk-line-string
            [id]="getGeometryId(mapObject, feat, part)"
            [styles]="styles"
            [geometry]="getLineString(geometry)"
            [projectionCode]="projectionCode"
          ></gk-line-string>
        }
        @case (wktTypeEnum.Polygon) {
          <gk-polygon
            [id]="getGeometryId(mapObject, feat, part)"
            [styles]="styles"
            [geometry]="getPolygon(geometry)"
            [projectionCode]="projectionCode"
          ></gk-polygon>
        }
        @case (wktTypeEnum.MultiPolygon) {
          <gk-polygon
            [id]="getGeometryId(mapObject, feat, part)"
            [styles]="styles"
            [geometry]="getPolygon(geometry)"
            [projectionCode]="projectionCode"
          ></gk-polygon>
        }
      }
    }
  }
}

@if (mapObjects[0]?.type === FeatureCollectionType) {
  @for (
    feature of getMapGeometryFromGeoJson(mapObjects);
    let feat = $index;
    track feat
  ) {
    @switch (feature.geometry.type.toUpperCase()) {
      @case (wktTypeEnum.Point) {
        <gk-point
          [id]="getFeatureId(feature, feat)"
          [styles]="feature.styles"
          [geometry]="getFeaturePoint(feature.geometry.coordinates)"
          [projectionCode]="projectionCode"
        ></gk-point>
      }
      @case (wktTypeEnum.MultiPoint) {
        <gk-point
          [id]="getFeatureId(feature, feat)"
          [styles]="feature.styles"
          [geometry]="getFeaturePoint(feature.geometry.coordinates)"
          [projectionCode]="projectionCode"
        ></gk-point>
      }
      @case (wktTypeEnum.LineString) {
        <gk-line-string
          [id]="getFeatureId(feature, feat)"
          [styles]="feature.styles"
          [geometry]="getLineStringFromFeature(feature.geometry.coordinates)"
          [projectionCode]="projectionCode"
        ></gk-line-string>
      }
      @case (wktTypeEnum.MultiLineString) {
        <gk-line-string
          [id]="getFeatureId(feature, feat)"
          [styles]="feature.styles"
          [geometry]="getLineStringFromFeature(feature.geometry.coordinates)"
          [projectionCode]="projectionCode"
        ></gk-line-string>
      }
      @case (wktTypeEnum.Polygon) {
        <gk-polygon
          [id]="getFeatureId(feature, feat)"
          [styles]="feature.styles"
          [geometry]="getPolygonFromFeature(feature.geometry.coordinates)"
          [projectionCode]="projectionCode"
        ></gk-polygon>
      }
      @case (wktTypeEnum.MultiPolygon) {
        <gk-polygon
          [id]="getFeatureId(feature, feat)"
          [styles]="feature.styles"
          [geometry]="getPolygonFromFeature(feature.geometry.coordinates)"
          [projectionCode]="projectionCode"
        ></gk-polygon>
      }
    }
  }
}
