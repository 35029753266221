@if (shouldShowControl()) {
  <div gkControlRow>
    <span gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
      ></gk-translated-label
      >:</span
    >
    <span gkControlValue [showLabel]="controlConfig.showLabel">
      <button
        class="btn btn-sm btn-warning cancel-btn"
        (click)="tryCancel(modalTemplate)"
        attr.aria-label="{{ translations['CANCEL_PAYMENT'] }}"
        attr.title="{{ translations['CANCEL_BUTTON_TITLE'] }}"
        [disabled]="loading || succeeded"
      >
        {{ translations['CANCEL_PAYMENT'] }}
      </button>
      @if (loading) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }
      @if (!loading && errorMsg) {
        <span class="text-danger">{{ errorMsg }}</span>
      }
      @if (succeeded) {
        <span class="text-success">{{ translations['CANCEL_SUCCEEDED'] }}</span>
      }
    </span>
  </div>
} @else {
  -
}

<ng-template
  #modalTemplate
  let-dismiss="dismiss('canceled')"
  let-close="close('accept')"
>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ translations['CONFIRMATION_MODAL_TITLE'] }}
    </h4>
    <button
      type="button"
      class="btn-close"
      attr.aria-label="{{ translations['CONFIRMATION_MODAL_CLOSE'] }}"
      [title]="translations['CONFIRMATION_MODAL_CLOSE']"
      (click)="(dismiss)"
    ></button>
  </div>
  <div class="modal-body">
    {{ translations['CONFIRMATION_TEXT'] }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-color-from-db" (click)="(close)">
      {{ translations['CONFIRMATION_ACCEPT'] }}
    </button>
    <button
      type="button"
      class="btn btn-color-from-db-outline"
      (click)="(dismiss)"
    >
      {{ translations['CONFIRMATION_DECLINE'] }}
    </button>
  </div>
</ng-template>
