import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-legend',
  templateUrl: './form-legend.component.html',
  styleUrls: ['./form-legend.component.scss'],
  standalone: false,
})
export class FormLegendComponent {
  @Input() inRow: boolean;
}
