import {
  Directive,
  EventEmitter,
  Input,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { WindowRefService } from '../../gk-dynamic-list/services/window-ref.service';

// tslint:disable-next-line:directive-selector
@Directive({
  selector: '[elementFocus]',
  standalone: false,
})
export class ElementFocusDirective implements AfterViewInit, OnDestroy {
  private isAlive = true;
  // tslint:disable-next-line:no-input-rename
  @Input('elementFocus') focusEvent: EventEmitter<string>;
  constructor(private windowRefService: WindowRefService) {}

  ngAfterViewInit(): void {
    this.subscribeToFocusEvent();
  }

  subscribeToFocusEvent(): void {
    this.focusEvent
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((id) =>
        this.windowRefService.nativeWindow.document.getElementById(id).focus(),
      );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
