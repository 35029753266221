import { FormControl } from '@angular/forms';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum APNEDNewRequestAdditionalControlName {
  ConstructionWorkDefinition = 'constructionWorkDefinition',
  ConstructionWorkBasis = 'constructionWorkBasis',
  RequestJustification = 'requestJustification',
}

export const APNEDNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...APNEDNewRequestAdditionalControlName,
};

export interface APNEDNewRequestFormGroup extends LawPersonNewRequestFormGroup {
  [APNEDNewRequestControlName.ConstructionWorkDefinition]: FormControl<string>;
  [APNEDNewRequestControlName.ConstructionWorkBasis]: FormControl<string>;
  [APNEDNewRequestControlName.RequestJustification]: FormControl<string>;
}
