@if (shouldShowControl()) {
  <div gkControlRow>
    <span gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
      ></gk-translated-label
      >:</span
    >
    <span
      gkControlValue
      [showLabel]="controlConfig.showLabel"
      [classList]="controlConfig.classList"
    >
      <button
        class="btn btn-sm btn-color-from-db d-inline"
        (click)="clickCallback()"
      >
        @if (!actionPending) {
          <span>{{ controlConfig.button.label }}</span>
        }
        @if (actionPending) {
          <span><i class="fa fa-spinner fa-pulse fa-fw"></i> </span>
        }
      </button>
    </span>
  </div>
}
