import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  ColorsService,
  DefaultConfigGuestService,
  LoginService,
  PlannedApplicationMessageService,
} from '@gk/gk-modules';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
  Observable,
  filter,
  map,
  of,
  switchMap,
  takeWhile,
  tap,
  combineLatest,
} from 'rxjs';
import { environment } from '../environments/environment.prod';
import { MainRoutes } from './guards/guard.models';
import { AppLocalStorageService } from './services/app-local-storage/app-local-storage.service';
import { AppModeProviderService } from './services/app-mode-provider/app-mode-provider.service';
import { DomRefService } from './services/dom-ref/dom-ref.service';
import { PortalConfig } from './services/portal-config/portal-config.model';
import { PortalConfigService } from './services/portal-config/portal-config.service';
import { SwManagerService } from './services/sw-manager/sw-manager.service';

declare let gtag: (config: string, id: string) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {
  private isAlive = true;
  readonly lSLangItemKey = 'hub-lang';
  readonly themeSelectedByUserClass = 'theme-selected-by-user';
  loading$: Observable<boolean> = of(true);
  portalConfig: PortalConfig;
  isNavbarVisible = true;

  constructor(
    private translate: TranslateService,
    private domRefService: DomRefService,
    private appLocalStorageService: AppLocalStorageService,
    private colorsService: ColorsService,
    private renderer: Renderer2,
    private appModeProviderService: AppModeProviderService,
    @Inject('window') public window: Window,
    private router: Router,
    public plannedApplicationMessageService: PlannedApplicationMessageService,
    public loginService: LoginService,
    private defaultConfigGuestService: DefaultConfigGuestService,
    private portalConfigService: PortalConfigService,
    private swManagerService: SwManagerService,
  ) {
    translate.setDefaultLang('pl');
  }

  ngOnInit(): void {
    this.checkRouterEventsAndShowLoadingIndicator();
    this.showAppVersionInConsoleForProdMode();
    this.subscribeToLanguageChange();
    this.setInitialLanguage();
    this.setStartUrlInLocalStorage();
    this.fetchAppColorSetAndUpdateStyles();
    this.watchCurrentSelectedTheme();
    this.plannedApplicationMessageService.maybeShowMessage();
    this.fetchDefaultConfigGuest();
    this.getPortalConfig();
  }

  getPortalConfig(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(
          (navigationEndEvent) =>
            navigationEndEvent.url.split('/')[1] as MainRoutes,
        ),
        switchMap((mainRoute) =>
          this.portalConfigService.getPortalConfByMainRoute(mainRoute),
        ),
        takeWhile(() => this.isAlive),
        tap((portalConfig) => {
          this.portalConfig = portalConfig;
        }),
      )
      .subscribe({
        next: () => {
          this.showOrHideNavbarByConfig();
        },
      });
  }

  showOrHideNavbarByConfig(): void {
    this.isNavbarVisible = !!this.portalConfig?.isNavbarVisible;
  }

  fetchDefaultConfigGuest(): void {
    this.defaultConfigGuestService
      .getConfig()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((config) => {
        this.setUpAnalytics(config.googleAnalyticsId);
      });
  }

  setUpAnalytics(googleAnalyticsId: string): void {
    if (!googleAnalyticsId) {
      return;
    }
    const scriptElement = document.createElement('script');
    scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}&ngsw-bypass=true`;
    document.head.appendChild(scriptElement);
    gtag('config', googleAnalyticsId);
  }

  checkRouterEventsAndShowLoadingIndicator(): void {
    this.loading$ = combineLatest([
      this.router.events.pipe(
        filter(
          (e) =>
            e instanceof NavigationStart ||
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel ||
            e instanceof NavigationError ||
            (e instanceof GuardsCheckStart && e.id !== 1),
        ),
        map((e) => e instanceof NavigationStart),
      ),
      this.swManagerService.swLoading,
    ]).pipe(
      map(
        ([isNavigationStart, isSwLoading]) => isNavigationStart || isSwLoading,
      ),
    );
  }

  showAppVersionInConsoleForProdMode(): void {
    if (!this.appModeProviderService.isAppInDevMode()) {
      console.log(`V: ${environment.version}`);
    }
  }

  watchCurrentSelectedTheme(): void {
    this.colorsService.defaultThemeSelected
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((defaultThemeSelected) => {
        if (defaultThemeSelected) {
          this.renderer.removeClass(
            document.body,
            this.themeSelectedByUserClass,
          );
        } else {
          this.renderer.addClass(document.body, this.themeSelectedByUserClass);
        }
      });
  }

  setInitialLanguage(): void {
    const lS = this.appLocalStorageService.getLocalStorage();
    const fromStorage = lS.getItem(this.lSLangItemKey);
    this.updateLanguage(fromStorage || 'pl');
  }

  setStartUrlInLocalStorage(): void {
    const lS = this.appLocalStorageService.getLocalStorage();
    lS.setItem('startUrl', this.window.location.origin);
  }

  subscribeToLanguageChange(): void {
    this.translate.onLangChange
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data: LangChangeEvent) => {
        this.updateLanguage(data.lang);
      });
  }

  fetchAppColorSetAndUpdateStyles(): void {
    this.colorsService.updateDocumentColors();
  }

  updateLanguage(lang: string): void {
    this.translate.use(lang);
    this.domRefService.nativeDom
      .querySelector('html')
      .setAttribute('lang', lang);
    localStorage.setItem(this.lSLangItemKey, lang);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  checkIfLocationPathNameIncludesDepartmentsPortalRoute(): boolean {
    return !window.location.pathname.includes(`/${MainRoutes.Department}`);
  }
}
