<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <app-workspace-state
      [workspaceStateDraftVersion]="workspaceStateDraftVersion"
      [workspaceStateId]="workspaceStateId"
      [getWorkspaceStateToSave]="getWorkspaceStateToSave"
      [borderClass]="'card-border-color-from-db'"
      [backgroundClass]="'card-header-color-from-db'"
      [buttonClass]="'btn-color-from-db'"
      (workspaceStateLoaded)="handleLoadWorkspaceResponse($event)"
    ></app-workspace-state>

    <section>
      <div class="card card-border-color-from-db mb-4">
        <div class="card-header text-black card-header-color-from-db">
          <label class="mb-0" for="client-sgk-info">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.CLIENT_SGK_INFO'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <input
            type="text"
            id="client-sgk-info"
            class="form-control"
            readonly
            disabled
            [value]="(sessionService.loggedUserData | async).clientSgk || ''"
          />
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'card-border-color-from-db'
        "
      >
        <div class="card-header text-black card-header-color-from-db">
          {{
            'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.CLIENT_DATA'
              | translate
          }}
        </div>
        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
            [naturalPersonOnly]="true"
            [submitted]="submitted"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <app-contact-data
      [submitted]="submitted"
      [contactDataFormGroup]="getContactDataFormGroup()"
      [borderClass]="'card-border-color-from-db'"
      [backgroundClass]="'card-header-color-from-db'"
    ></app-contact-data>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areWorkRangesChosen()
            ? 'border-danger border-2'
            : 'card-border-color-from-db'
        "
      >
        <div class="card-header text-black card-header-color-from-db">
          {{
            'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.WORK_RANGE'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="0"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          </div>
        </div>
        @if (submitted && !areWorkRangesChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.WORK_RANGE_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div class="card card-border-color-from-db mb-4">
        <div class="card-header text-black card-header-color-from-db">
          <label class="mb-0" [attr.for]="controlName.WorkSignature">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.WORK_SIGNATURE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <input
            type="text"
            [name]="controlName.WorkSignature"
            [id]="controlName.WorkSignature"
            class="form-control"
            [formControlName]="controlName.WorkSignature"
          />
        </div>
      </div>
    </section>

    <section>
      <div class="card card-border-color-from-db mb-4">
        <div class="card-header text-black card-header-color-from-db">
          <label class="mb-0" [attr.for]="controlName.WorkPurpose">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.WORK_PURPOSE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="d-flex row-select mb-2">
            <mat-checkbox
              class="p-0"
              [(ngModel)]="isArchivalWorkPurposeVisible"
              [ngModelOptions]="{ standalone: true }"
            >
              {{
                'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.ARCHIVAL_WORK_PURPOSES'
                  | translate
              }}</mat-checkbox
            >
          </div>
          <div class="d-flex row-select">
            <mat-select
              class="form-select form-select-sm"
              [id]="controlName.WorkPurpose"
              [attr.name]="controlName.WorkPurpose"
              [formControlName]="controlName.WorkPurpose"
              [placeholder]="
                'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.SELECT_WORK_PURPOSE'
                  | translate
              "
              multiple
            >
              @for (
                field of workPurposeDictionaryService.get() | async;
                track field.id
              ) {
                @if (!field.isArchival || isArchivalWorkPurposeVisible) {
                  <mat-option [value]="field.id">
                    {{ field.name }}
                  </mat-option>
                }
              }
            </mat-select>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="card card-border-color-from-db mb-4">
        <div class="card-header text-black card-header-color-from-db">
          <label class="mb-0" [attr.for]="controlName.Materials">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.MATERIALS'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <input
            type="text"
            [name]="controlName.Materials"
            [id]="controlName.Materials"
            class="form-control"
            [formControlName]="controlName.Materials"
          />
        </div>
      </div>
    </section>

    <section>
      <div class="card card-border-color-from-db mb-4">
        <div class="card-header text-black card-header-color-from-db">
          <label class="mb-0" [attr.for]="controlName.DataRange">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.DATA_RANGE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <input
            type="text"
            [name]="controlName.DataRange"
            [id]="controlName.DataRange"
            class="form-control"
            [formControlName]="controlName.DataRange"
          />
        </div>
      </div>
    </section>

    <section>
      <div class="card card-border-color-from-db mb-4">
        <div class="card-header text-black card-header-color-from-db">
          <label class="mb-0" [attr.for]="controlName.AdditionalInfo">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.ADDITIONAL_INFO'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.AdditionalInfo"
            [id]="controlName.AdditionalInfo"
            class="form-control form-control-sm"
            [formControlName]="controlName.AdditionalInfo"
          ></textarea>
        </div>
      </div>
    </section>

    <gk-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [borderClass]="'card-border-color-from-db'"
      [backgroundClass]="'card-header-color-from-db'"
    >
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
      [nonElectronicForm]="false"
      [borderClass]="'card-border-color-from-db'"
      [backgroundClass]="'card-header-color-from-db'"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
        [borderClass]="'card-border-color-from-db'"
        [backgroundClass]="'card-header-color-from-db'"
      ></app-cautions>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section>
      <button type="submit" class="btn btn-color-from-db">
        {{
          'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.SUBMIT'
            | translate
        }}
      </button>

      <app-request-preview-button
        class="ms-2"
        [buttonClass]="'btn-color-from-db'"
        [getPreviewRequestBody]="getRequestBody"
        [previewRequestUrl]="
          '/api/interesant/wniosek/udostMatSluzbieGeodezyjnej/podglad'
        "
      ></app-request-preview-button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-color-from-db" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
