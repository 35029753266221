import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { takeWhile } from 'rxjs/operators';
import {
  MapAction,
  MapObjectTableActionType,
  MapViewActionType,
} from '../../../../gk-map/models';
import {
  MapObjectApiType,
  Wkt,
} from '../../../../gk-map/models/map-object.model';
import { MapExtentUtils, ShapeUtils } from '../../../../gk-map/utils';
import { MapNavigateToMapObjectControlConfig } from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-map-navigation-to-map-object',
  templateUrl: './control-map-navigation-to-map-object.component.html',
  standalone: false,
})
export class ControlMapNavigateToMapObjectComponent
  extends Control<any>
  implements OnInit
{
  @Input()
  override controlConfig: MapNavigateToMapObjectControlConfig;
  zudpGeometry: Wkt;
  isMapFullyLoaded = false;
  noZudpGeometry: boolean;
  @Output()
  dispatchMapAction = new EventEmitter<MapAction>();

  constructor(
    protected override translateService: TranslateService,
    protected override dataService: DataService,
  ) {
    super(translateService, dataService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.getZudpGeometry();
  }

  getZudpGeometry(): void {
    this.dataService
      .getData(this.controlConfig.zudpGeometryRequestConfig, this.data)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((zudpGeometry) => {
        this.zudpGeometry = zudpGeometry;
        this.noZudpGeometry = !zudpGeometry;
      });
  }

  zoomToZudpGeometry(): void {
    const mapObject = ShapeUtils.getAnyGeometryMapObject(
      this.zudpGeometry,
      MapObjectApiType.ExtentOrPolygon,
    );

    this.dispatchMapAction.emit(
      new MapAction(MapObjectTableActionType.AddNew, mapObject),
    );

    this.dispatchMapAction.emit(
      new MapAction(MapObjectTableActionType.Select, mapObject),
    );

    this.dispatchMapAction.emit(
      new MapAction(
        MapViewActionType.ExtentToFitToChange,
        MapExtentUtils.getMapExtentFromMapObjects([mapObject]),
      ),
    );
  }
}
