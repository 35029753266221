import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  MapAction,
  MapPreviewModeState,
  MapViewActionType,
} from '../../../../models';
import { MapControl } from '../../../../controls';

@Component({
  selector: 'gk-map-pin-control',
  templateUrl: './map-pin-control.component.html',
  styleUrls: ['./map-pin-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapPinControlComponent extends MapControl {
  changeToggle(): void {
    this.dispatch.emit(
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(
          !this.mapState.viewState.previewModeState.enabled,
        ),
      ),
    );
  }
}
