import { Injectable } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { createReducedFormGroup } from '../../../../utils/reactive-form/reactive-form.util';
import {
  PaymentDetailsControlName,
  PaymentDetailsControlsExistenceParams,
  PaymentDetailsFormGroup,
} from './payment-details-form.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentDetailsFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(
    controlExistanceParams?: PaymentDetailsControlsExistenceParams,
  ): FormGroup<PaymentDetailsFormGroup> {
    const allControlsConfigFields: {
      [key in PaymentDetailsControlName]?: UntypedFormControl;
    } = {
      [PaymentDetailsControlName.PaymentOwner]: this.formBuilder.control(
        null,
        Validators.required,
      ),
      [PaymentDetailsControlName.NumberOfPayments]: this.formBuilder.control(
        1,
        Validators.required,
      ),
      [PaymentDetailsControlName.PaymentAmount]: this.formBuilder.control(
        null,
        Validators.required,
      ),
    };

    return createReducedFormGroup(
      allControlsConfigFields,
      controlExistanceParams,
    );
  }
}
