import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GkPaymentsModule } from '../../gk-payments/gk-payments.module';
import { GkComponentsModule } from '../gk-components.module';
import { AttachmentsComponent } from './attachments.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    GkComponentsModule,
    GkPaymentsModule,
  ],
  declarations: [AttachmentsComponent],
  exports: [AttachmentsComponent],
})
export class AttachmentsModule {}
