import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPerson,
} from '@gk/gk-modules';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export interface SNNewRequestDto extends ApplicantRequestDto {
  KategoriaUlicy: string;
  NowaNazwa: string;
  LokalizacjaUlicy: string;
  Uzasadnienie: string;
  InfoDodatkowe: string;
}

export class SNNewRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    public objectCategoryOrDescription: string,
    public objectProposedNewName: string,
    public objectLocation: string,
    public objectNamingJustification: string,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public comments: string,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: SNNewRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): SNNewRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        parcels,
        files,
      }),
      KategoriaUlicy: data.objectCategoryOrDescription,
      NowaNazwa: data.objectProposedNewName,
      LokalizacjaUlicy: data.objectLocation,
      Uzasadnienie: data.objectNamingJustification,
      InfoDodatkowe: data.comments,
    };
  }
}
