import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { PMFDDGRNewDataUpdateRequestComponent } from './new-request/new-request.component';
import { PermanentManagementFeeDiscountDecisionGrantingRequestRoutingModule } from './permanent-management-fee-discount-decision-granting-request-routing.module';
import { PermanentManagementFeeDiscountDecisionGrantingRequestComponent } from './permanent-management-fee-discount-decision-granting-request.component';
import { PMFDDGRNewDataUpdateRequestFormService } from './services/new-request-form/new-request-form.service';

@NgModule({
  declarations: [
    PermanentManagementFeeDiscountDecisionGrantingRequestComponent,
    PMFDDGRNewDataUpdateRequestComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    PermanentManagementFeeDiscountDecisionGrantingRequestRoutingModule,
  ],
  providers: [PMFDDGRNewDataUpdateRequestFormService],
})
export class PermanentManagementFeeDiscountDecisionGrantingRequestModule {}
