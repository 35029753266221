import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileNamingPatternsService } from './file-naming-patterns.service';
import { takeWhile } from 'rxjs';
import { FileNamingPatternFromApi } from './file-naming-patterns.model';

@Component({
  selector: 'gk-file-naming-component',
  templateUrl: './file-naming-patterns.component.html',
  styleUrls: ['./file-naming-patterns.component.scss'],
  standalone: false,
})
export class FileNamingPatternsComponent implements OnInit, OnDestroy {
  private isAlive = true;
  constructor(private fileNamingPatternsService: FileNamingPatternsService) {}

  namingPatternsUrlKey: string;
  fileNamingPatterns: FileNamingPatternFromApi[];

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.fileNamingPatternsService
      .subscribeToFileNamingPatternsData(this.namingPatternsUrlKey)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (fileNamingPatterns) => (this.fileNamingPatterns = fileNamingPatterns),
      );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
