import { Component, OnInit } from '@angular/core';

import { UtilityInfrastructure } from '../../../../gk-map/models';
import { Control } from '../control';

@Component({
  selector: 'gk-control-utility-infrastructure',
  templateUrl: './control-utility-infrastructure.component.html',
  standalone: false,
})
export class ControlUtilityInfrastructureComponent
  extends Control<UtilityInfrastructure>
  implements OnInit
{
  override ngOnInit(): void {
    this.getConvertedValue();
  }

  getConvertedValue(): string {
    switch (this.getValue()) {
      case UtilityInfrastructure.Existing:
        return 'GK.MAP.GET_INFO_ATTRIBUTES.EXISTING';
      case UtilityInfrastructure.NotExisting:
        return 'GK.MAP.GET_INFO_ATTRIBUTES.NOT_EXISTING';
      case UtilityInfrastructure.MayBeConnected:
        return 'GK.MAP.GET_INFO_ATTRIBUTES.MAY_BE_CONNECTED';
      default:
        return '';
    }
  }
}
