import { Component, Input, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { BaseStyleClassesDirective, PortalId } from '@gk/gk-modules';
import { Observable } from 'rxjs';
import { BaseNewRequestControlName } from '../base-new-request-form/base-new-request-form.model';
import { DocumentationFormType } from '../base-new-request/base-new-request.model';
import { ShippingFormComponent } from '../shipping-form/shipping-form.component';

@Component({
  selector: 'app-request-reply-form',
  templateUrl: './request-reply-form.component.html',
  styleUrl: './request-reply-form.component.scss',
  standalone: false,
})
export class RequestReplyFormComponent extends BaseStyleClassesDirective {
  @Input() submitted: boolean;
  @Input() formGroup: UntypedFormGroup;
  @Input() portalId: PortalId;
  @Input() nonElectronicForm = true;
  @Input() electronicForm = true;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  documentationFormType = DocumentationFormType;
  controlName = BaseNewRequestControlName;

  checkShippingFormGroupValidAndFormSubmitted(): boolean {
    return !this.getShippingFormGroup().valid && this.submitted;
  }

  getShippingFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      this.controlName.ShippingForm,
    ) as UntypedFormGroup;
  }

  getDocumentationFormRadioFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.DocumentationForm);
  }

  askForPostOfficeWhenPostalCodeIsNotFromDictionary(): Observable<boolean> {
    return this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary();
  }
}
