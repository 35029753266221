import { ValidatorFn, Validators } from '@angular/forms';
import { ValueType } from '../gk-dynamic-list/gk-dynamic-list.model';
import { PortalsPermissions } from './../services/portals-permissions/portals-permissions.model';
import { ControlNetworkType } from './services/control-network/control-network.model';
import { CoordinateSystemType } from './services/coordinate-system/coordinate-system.model';
import { Place } from './services/place/place.model';
import { Street } from './services/street/street.model';

export enum FieldConfigType {
  Checkbox = 'checkbox',
  CheckboxGroupRepresentative = 'checkboxGroupRepresentative',
  Datepicker = 'datepicker',
  Input = 'input',
  NestedGroup = 'nestedGroup',
  RadioOption = 'radioOption',
  SectionTitle = 'sectionTitle',
  CommunitySelect = 'communitySelect',
  DistrictSelect = 'districtSelect',
  SheetSelect = 'sheettSelect',
  OrthophotomapSelect = 'orthophotomapSelect',
  PlacesTypeahead = 'placesTypeahead',
  StreetsTypeahead = 'streetsTypeahead',
  Separator = 'separator',
  BasicSelect = 'basicSelect',
  Section = 'section',
  ControlNetwork = 'controlNetwork',
  CoordinateSystem = 'coordinateSystem',
  LandParcelNumber = 'landParcelNumber',
  LandParcelId = 'landParcelId',
  LandAndMortgageRegisterDepartmentSelect = 'landAndMortgageRegisterDepartmentSelect',
}

export enum InputType {
  Number = 'number',
  Text = 'text',
  File = 'file',
}

export class CheckboxState {
  constructor(
    public name: string,
    public value?: boolean,
  ) {}
}

export class FieldPermissionsKeysConfig {
  constructor(
    public and: string[] = [],
    public or: string[] = [],
  ) {}

  checkPermissions(globalPermissions: PortalsPermissions): boolean {
    return (
      (!this.and.length ||
        this.and.every(
          (permission) =>
            globalPermissions[permission as keyof PortalsPermissions],
        )) &&
      (!this.or.length ||
        this.or.some(
          (permission) =>
            globalPermissions[permission as keyof PortalsPermissions],
        ))
    );
  }
}

export class FieldConfig {
  constructor(
    public type: FieldConfigType,
    public name: string,
    public label?: string,
    public disabled = false,
    public placeholder = '',
    public validators?: ValidatorFn[],
    public value?: number | string | boolean,
    public isRequiredGroup = true,
    public visible = true,
    public readonly?: boolean,
    public permissionsKeys?: FieldPermissionsKeysConfig,
  ) {}
}

export class SectionTitleFieldConfig extends FieldConfig {
  constructor(
    public title: string,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.SectionTitle,
      title,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class SeparatorFieldConfig extends FieldConfig {
  constructor(public override permissionsKeys?: FieldPermissionsKeysConfig) {
    super(
      FieldConfigType.Separator,
      '',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class InputFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public inputType = InputType.Text,
    public override placeholder = '',
    public override validators: ValidatorFn[] = [],
    public specialCharactersToFocus: string[] = [],
    public fieldNameToFocus = '',
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.Input,
      name,
      label,
      false,
      placeholder,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class LandParcelNumberFieldConfig extends FieldConfig {
  constructor(public override permissionsKeys?: FieldPermissionsKeysConfig) {
    super(
      FieldConfigType.LandParcelNumber,
      ValueType.LandParcelNumber,
      'GK.DYNAMIC_FORM.LAND_PARCEL_NUMBER',
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class NestedGroupFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public children: FieldConfig[],
    public verticalAlign = false,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
    public alignItems:
      | 'align-items-start'
      | 'align-items-center'
      | 'align-items-end'
      | 'align-items-stretch'
      | 'align-items-baseline' = 'align-items-center',
    public verticalAlignOnMobile = false,
  ) {
    super(
      FieldConfigType.NestedGroup,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class CommunitySelectFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public independentDistrictSearch = false,
    public allValuesEnabled = false,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.CommunitySelect,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class DistrictSelectFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public allValuesEnabled = false,
    public fullObjectFormValue = false,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.DistrictSelect,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class SheetSelectFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public allValuesEnabled = false,
    public fullObjectFormValue = false,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.SheetSelect,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class SectionFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public allValuesEnabled = false,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.Section,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class LandAndMortgageRegisterDepartmentFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public allValuesEnabled = false,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.LandAndMortgageRegisterDepartmentSelect,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class ControlNetworkFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public controlNetworkType: ControlNetworkType,
    public allValuesEnabled = false,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.ControlNetwork,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class CoordinateSystemFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public coordinateSystemType: CoordinateSystemType,
    public allValuesEnabled = false,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.CoordinateSystem,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class PlacesTypeaheadFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public override validators: ValidatorFn[] = [],
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.PlacesTypeahead,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class StreetsTypeaheadFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public override validators: ValidatorFn[] = [],
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.StreetsTypeahead,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class CheckboxFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public override value = false,
    public override disabled = false,
    public override validators: ValidatorFn[] = [],
    public checkboxGroupName?: string,
    public toggleRequiredControlName?: string,
    public override visible = true,
    public override readonly?: boolean,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
    public cumulatedGroupValue?: boolean,
  ) {
    super(
      FieldConfigType.Checkbox,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      value,
      undefined,
      visible,
      readonly,
      permissionsKeys,
    );
  }
}

export class CheckboxGroupRepresentativeFieldConfig extends FieldConfig {
  override validators: ValidatorFn[];
  constructor(
    public override name: string,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.CheckboxGroupRepresentative,
      name,
      undefined,
      undefined,
      undefined,
      [Validators.requiredTrue],
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class RadioOptionFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public override value: string | number,
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.RadioOption,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class OrthophotomapSelectFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public override validators: ValidatorFn[] = [],
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.OrthophotomapSelect,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class DatepickerFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public startDateControlName?: string,
    public endDateControlName?: string,
    public override validators: ValidatorFn[] = [],
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.Datepicker,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class BasicSelectFieldConfig extends FieldConfig {
  constructor(
    public override name: string,
    public override label: string,
    public options: BaseSelectOption[],
    public override permissionsKeys?: FieldPermissionsKeysConfig,
  ) {
    super(
      FieldConfigType.BasicSelect,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      permissionsKeys,
    );
  }
}

export class BaseSelectOption {
  constructor(
    public value: string | number,
    public name: string,
  ) {}
}

export interface AttributesFormValue {
  administrativeUnit?: {
    community?: number;
    district?: number;
  };
  address?: {
    place?: Place;
    street?: Street;
  };
  addressDetails?: {
    postalCode?: string;
    buildingNumber?: string;
    premisesNumber?: string;
  };
  landParcel?: {
    nominator?: string;
    denominator?: string;
    additionalNumber?: string;
  };
  premises?: {
    buildingNumberInLandParcel?: string;
    buildingNumberInDistrict?: string;
    cadastralNumber?: string;
  };
  building?: {
    cadastralNumberFrom?: string;
    cadastralNumberTo?: string;
  };
  numbers?: {
    number?: string;
    oldNumber?: string;
  };
  section?: string;
  name?: string;
  cylinderNumber?: string;
  controlNetworks?: {
    horizontal?: number;
    vertical?: number;
  };
  coordinateSystems?: {
    horizontal?: number;
    vertical?: number;
  };
  landAndMortgageRegister?: {
    landAndMortgageRegisterDepartment?: string;
    landAndMortgageRegisterNumber?: string;
  };
}

export interface AddressFilter {
  MiejscowoscId: number;
  UlicaId: number;
  Kod: string;
  NumerBudynku: string;
  NumerLokalu: string;
}

export interface NumericRangeFilter {
  From: number;
  To: number;
}
