import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import {
  BOPUCConstructionWorksInformationControlName,
  BOPUCNewRequestControlName,
  BOPUCNewRequestFormGroup,
} from './new-request-form.model';

@Injectable()
export class BOPUCNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): FormGroup<BOPUCNewRequestFormGroup> {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [BOPUCNewRequestControlName.ContactData]: true,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [BOPUCNewRequestControlName.CurrentUse]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BOPUCNewRequestControlName.IntendedUse]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BOPUCNewRequestControlName.ConstructionWorksInformation]:
        this.formBuilder.group({
          [BOPUCConstructionWorksInformationControlName.ExecutionType]: [
            getDefaultControlValue(),
          ],
          [BOPUCConstructionWorksInformationControlName.PlannedStartDate]: [
            getDefaultControlValue(),
          ],
        }),
    });
  }
}
