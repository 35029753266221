import { MapRangePickerGridComponent } from './map-range-picker-grid.component';
import {
  ColumnSetting,
  ColumnType,
  GenericGridToolbarItemName,
  GridToolBarItem,
  GridToolBarItemType,
} from '../gk-kendo-grid/gk-kendo-grid.model';
import {
  iwIconBuffer,
  iwIconColorWskDzialke,
  iwIconColorZakresWczytaj,
  iwIconColorZakresZDzialki2,
  iwIconModyfikujZakres,
  iwIconPlus,
  iwIconSheetAdd,
} from '../../assets/css/ewid-svg/svg-icons';
import { AnyGeometryObject, EgibObject } from '../../gk-map/models';
import { GenericToolbarItemName } from '../gk-kendo-grid/gk-kendo-grid-toolbar/gk-kendo-grid-toolbar.model';

export const getMapRangePickerGridToolbarItems = (
  c: MapRangePickerGridComponent,
): GridToolBarItem[] => {
  return [
    {
      name: 'landParcelSearch',
      svgIcon: iwIconPlus,
      className: 'svg-icon-red',
      title: 'Szukaj działki',
      callback: () => c.openLandParcelSearchWindow(c.parentWindowInstanceName),
    },
    {
      name: 'getParcelByPointingOnMapMenu',
      svgIcon: iwIconColorWskDzialke,
      title: 'Wskaż działkę na mapie',
      type: GridToolBarItemType.Dropdown,
      data: [
        {
          name: GenericToolbarItemName.GetLandParcelByPointFromMap,
          title: 'Wskaż działkę na mapie',
          text: 'Wskaż działkę na mapie',
          payload: () => ({
            doneCallback: (egibObjects: EgibObject[]): void => {
              c.addLandParcelsToGrid(egibObjects);
            },
          }),
        },
        {
          name: GenericToolbarItemName.GetLandParcelByPolygonFromMap,
          title: 'Wskaż działkę poprzez zakres na mapie',
          text: 'Wskaż działkę poprzez zakres na mapie',
          payload: () => ({
            doneCallback: (egibObjects: EgibObject[]): void => {
              c.addLandParcelsToGrid(egibObjects);
            },
          }),
        },
        {
          name: 'getParcelsByRangeFromFile',
          title: 'Wskaż działkę poprzez zakres z pliku',
          text: 'Wskaż działkę poprzez zakres z pliku',
          callback: () => c.getParcelsByRangeFromFile(),
        },
        {
          name: 'getParcelsByIdsFromFile',
          title: c.selectByIdentifiersTranslation,
          text: c.selectByIdentifiersTranslation,
          callback: () => c.getParcelsByIdsFromFile(),
        },
      ],
    },
    {
      name: 'CreateRangeBySheet',
      svgIcon: iwIconSheetAdd,
      title: 'Stwórz zakres za pomocą arkusza',
      callback: () => c.createRangeBySheet(),
    },
    {
      name: GenericToolbarItemName.GetPolygonGeometryFromMap,
      payload: () => ({
        doneCallback: (anyGeometryObject: [AnyGeometryObject]): void => {
          c.addRangeToGrid(anyGeometryObject[0].geom);
        },
      }),
      beforeClickValidation: (): boolean => {
        return c.showMessageIfOnlyOneRangeAllowed();
      },
    },
    {
      name: GenericGridToolbarItemName.ModifyGeometry,
      svgIcon: iwIconModyfikujZakres,
      title: 'GK_KENDO_GRID.GRID_TOOLBAR.MODIFY_GEOM',
      callback: () => c.onModifyGeometryToolbarButtonClick(),
    },
    {
      name: GenericGridToolbarItemName.RangeFromSelectedParcels,
      title: 'GK.MAP.RANGE_FROM_PARCELS',
      svgIcon: iwIconColorZakresZDzialki2,
      callback: () => c.createRangeFromParcels(),
    },
    {
      name: GenericGridToolbarItemName.IncreaseRangeByBuffer,
      title: 'GK.MAP.INCREASE_RANGE_BY_BUFFER',
      type: GridToolBarItemType.ButtonWithInput,
      svgIcon: iwIconBuffer,
      inputData: {
        width: '40px',
        min: 0,
        value: 0,
        suffixValue: 'm',
      },
      callback: (value) => c.validateAndIncreaseRangeByBuffer(value as number),
    },
    {
      name: GenericGridToolbarItemName.RemoveGridItem,
    },
    {
      name: GenericGridToolbarItemName.RemoveAllGridItems,
      callback: () => c.removeAllMapRangePickerGridItems(),
    },
    {
      name: GenericGridToolbarItemName.ShowGeometry,
      payload: () => ({
        gridGeometryFieldName: 'geom',
      }),
    },
    // {
    //   name: GenericGridToolbarItemName.DrawGeometry,
    //   payload: () => ({
    //     gridGeometryFieldName: 'geom',
    //   }),
    // },
    {
      name: 'loadRangeFromFile',
      svgIcon: iwIconColorZakresWczytaj,
      title: 'Wczytaj zakres z pliku',
      text: 'Wczytaj zakres z pliku',
      showText: 'overflow',
      callback: () => c.loadRangeFromFile(),
    },
    {
      name: GenericGridToolbarItemName.SaveRangeToFiles,
      payload: () => ({
        gridGeometryFieldName: 'geom',
      }),
    },
  ];
};

export const mapRangePickerGridColumns: ColumnSetting[] = [
  {
    field: 'label',
    title: 'Obiekty',
    type: ColumnType.Text,
  },
];
