<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <app-workspace-state
      [workspaceStateDraftVersion]="workspaceStateDraftVersion"
      [workspaceStateId]="workspaceStateId"
      [getWorkspaceStateToSave]="getWorkspaceStateToSave"
      [buttonClass]="'btn-outline-primary'"
      (workspaceStateLoaded)="handleLoadWorkspaceResponse($event)"
    ></app-workspace-state>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'PERMANENT_MANAGEMENT_FEE_DISCOUNT_DECISION_GRANTING_REQUEST.NEW_REQUEST.CLIENT_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areMapObjectsChosen()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'PERMANENT_MANAGEMENT_FEE_DISCOUNT_DECISION_GRANTING_REQUEST.NEW_REQUEST.LOCATION'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          @if (
            mapState.mapObjectTablesState[parcelsMapObjectTableStateIndex]
              ?.mapObjects?.length
          ) {
            <h4 class="mt-4" tabindex="0" aria-required="true">
              {{
                'PERMANENT_MANAGEMENT_FEE_DISCOUNT_DECISION_GRANTING_REQUEST.NEW_REQUEST.CHOSEN_PARCELS'
                  | translate
              }}
            </h4>
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="parcelsMapObjectTableStateIndex"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          }

          @if (
            mapState.mapObjectTablesState[buildingsMapObjectTableStateIndex]
              ?.mapObjects?.length
          ) {
            <h4 class="mt-2" tabindex="0">
              {{
                'PERMANENT_MANAGEMENT_FEE_DISCOUNT_DECISION_GRANTING_REQUEST.NEW_REQUEST.CHOSEN_BUILDINGS'
                  | translate
              }}
            </h4>
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="buildingsMapObjectTableStateIndex"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          }

          @if (
            mapState.mapObjectTablesState[premisesMapObjectTableStateIndex]
              ?.mapObjects?.length
          ) {
            <h4 class="mt-2" tabindex="0">
              {{
                'PERMANENT_MANAGEMENT_FEE_DISCOUNT_DECISION_GRANTING_REQUEST.NEW_REQUEST.CHOSEN_PREMISES'
                  | translate
              }}
            </h4>
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="premisesMapObjectTableStateIndex"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          }
        </div>
        @if (submitted && !areMapObjectsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'PERMANENT_MANAGEMENT_FEE_DISCOUNT_DECISION_GRANTING_REQUEST.NEW_REQUEST.MAP_OBJECTS_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isReasonFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Reason">
            {{
              'PERMANENT_MANAGEMENT_FEE_DISCOUNT_DECISION_GRANTING_REQUEST.NEW_REQUEST.REASON'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.Reason"
            [id]="controlName.Reason"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.Reason"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getReasonFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <gk-attachments [portalId]="portalId" [submitted]="submitted">
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section>
      <button type="submit" class="btn btn-primary">
        {{
          'PERMANENT_MANAGEMENT_FEE_DISCOUNT_DECISION_GRANTING_REQUEST.NEW_REQUEST.SUBMIT'
            | translate
        }}
      </button>

      <app-request-preview-button
        class="ms-2"
        [buttonClass]="'btn-outline-primary'"
        [getPreviewRequestBody]="getRequestBody"
        [previewRequestUrl]="
          '/api/interesant/wniosek/bonifikataOplataTrwalyZarzad/podglad'
        "
      ></app-request-preview-button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
