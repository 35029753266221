import { FormControl } from '@angular/forms';
import { FieldId } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum SFB70CCNewRequestAdditionalControlName {
  ConstructionIntentionType = 'constructionIntentionType',
  PlannedStartDate = 'plannedStartDate',
}

export const SFB70CCNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...SFB70CCNewRequestAdditionalControlName,
};

export interface SFB70CCNewRequestFormGroup
  extends LawPersonNewRequestFormGroup {
  [SFB70CCNewRequestControlName.ConstructionIntentionType]: FormControl<
    FieldId[]
  >;
  [SFB70CCNewRequestControlName.PlannedStartDate]: FormControl<NgbDate>;
}
