<div>
  @if (isNewMessages) {
    <sup class="fw-bold new-message-notation">{{
      'GK.DYNAMIC_LIST.COMMUNICATOR.NEW' | translate
    }}</sup>
  }
  <button
    type="button"
    class="btn btn-link px-0 ms-1"
    [disabled]="!isNewMessages"
    [title]="'GK.DYNAMIC_LIST.COMMUNICATOR.NEW_LIST_MESSAGES' | translate"
    (click)="showNewMessagesModal()"
    >
    <i class="fa fa-envelope-o me-1"></i
      >{{ 'GK.DYNAMIC_LIST.COMMUNICATOR.MESSAGES' | translate }}
    </button>
  </div>
