import {
  BaseNewRequestControlName,
  LocationNewRequestControlName,
} from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum PINewRequestAdditionalControlName {
  ObjectNameAndType = 'objectNameAndType',
}

export const PINewRequestControlName = {
  ...BaseNewRequestControlName,
  ...LocationNewRequestControlName,
  ...PINewRequestAdditionalControlName,
};
