import { Component } from '@angular/core';
import { FormBaseComponent } from '../../form-base/form-base.component';
import { NestedGroupFieldConfig } from '../../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-nested-group',
  styleUrls: ['./form-nested-group.component.scss'],
  templateUrl: './form-nested-group.component.html',
  standalone: false,
})
export class FormNestedGroupComponent extends FormBaseComponent {
  override config: NestedGroupFieldConfig;
}
