// Class for ducuments with UUID instead of ID, in the future all documents will have UUID
// and probably will replace ApiNewDokumentPowiazanyDalDto from libs\gk-modules\src\lib\gk-dynamic-list\services\add-related-document\add-related-document.model.ts

export class ApiDocumentDto {
  constructor(
    public NazwaPliku: string,
    public Plik: string,
    public OwnerUuid?: string,
    public Opis?: string,
    public RodzajOpracowaniaDokumentu?: number | string,
  ) {}
}
