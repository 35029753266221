import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[gkControlRow]',
  standalone: false,
})
export class ControlRowDirective implements OnInit {
  @Input() flexDirection: 'column' | 'row' = 'row';
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'gk-control-row');
    this.renderer.addClass(this.el.nativeElement, 'd-flex');
    this.renderer.addClass(this.el.nativeElement, 'flex-wrap');
    this.renderer.addClass(this.el.nativeElement, 'flex-sm-nowrap');
    if (this.flexDirection === 'column') {
      this.renderer.addClass(this.el.nativeElement, 'flex-column');
    }
  }
}
