import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormAlertService } from '../../../services/form-alert/form-alert.service';
import { NaturalPersonControlName } from '../../services/law-person-form/law-person-form.model';

@Component({
  selector: 'gk-natural-person-form',
  templateUrl: './natural-person-form.component.html',
  standalone: false,
})
export class NaturalPersonFormComponent implements OnInit {
  @Input() forDesignerPersonType = false;
  @Input() form: UntypedFormGroup;
  @Input() isRequired: boolean;
  @Input() isPeselVisible = true;
  @Input() addingPersonsDirectlyToDb: boolean;
  @Input() idPrefix = '';
  @Input() forcePeselRequired = false;
  naturalPersonControlName = NaturalPersonControlName;

  constructor(private formAlertService: FormAlertService) {}

  ngOnInit(): void {
    this.clearValidatorsForInvisibleFormControls();
  }

  clearValidatorsForInvisibleFormControls(): void {
    if (!this.isPeselVisible) {
      this.getPeselFormControl().clearValidators();
      this.getPeselFormControl().updateValueAndValidity();
    }
  }

  getFirstNameFormControl(): UntypedFormControl {
    return this.form.get(
      this.naturalPersonControlName.FirstName,
    ) as UntypedFormControl;
  }

  getLastNameFormControl(): UntypedFormControl {
    return this.form.get(
      this.naturalPersonControlName.LastName,
    ) as UntypedFormControl;
  }

  getPeselFormControl(): UntypedFormControl {
    return this.form.get(
      this.naturalPersonControlName.Pesel,
    ) as UntypedFormControl;
  }

  getPermissionNumberFormControl(): UntypedFormControl {
    return this.form.get(
      this.naturalPersonControlName.PermissionNumber,
    ) as UntypedFormControl;
  }

  isPeselRequired(): boolean {
    return (
      (this.isRequired && this.addingPersonsDirectlyToDb) ||
      this.forcePeselRequired
    );
  }

  shouldShowRequiredAlert(formControl: UntypedFormControl): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      'required',
      undefined,
    );
  }

  shouldShowPeselAlert(formControl: UntypedFormControl): boolean {
    return !!(
      this.formAlertService.shouldShowErrorAlert(
        formControl,
        'pesel',
        undefined,
      ) && formControl.value
    );
  }
}
