import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPersonSearch,
  MapObject,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonOrDesignerRequestDto,
  NewNaturalPersonRequestDto,
  Wkt,
} from '@gk/gk-modules';
import { Address } from '../address/address.model';
import { ConnectionAndNetworkFormGroupContent } from '../connection-and-network/connection-and-network.model';
import { NewDesignerRequestControlName } from '../new-designer-request-form/new-designer-request-form.model';

export class ApiNewZudOsobyDalDto {
  constructor(
    public SameAsWnioskodawca: boolean,
    public OsobaOpis: string | null,
  ) {}
}

export class ApiNewZudObrebyDalDto {
  constructor(
    public DzialkaId: number,
    public ObrebId: number,
  ) {}
}

export class SieciPrzylaczaApi {
  constructor(
    public Id: number,
    public Ilosc: number,
  ) {}
}

export const isDesigner = (
  item: NewNaturalPersonOrDesignerRequestDto | NewLegalPersonRequestDto,
): item is NewDesignerRequestDto => 'NrInUpr' in item;

export class ApiLawPerson {
  constructor(
    public TypId: number,
    public Id: string,
    public Petent?: NewNaturalPersonRequestDto | NewLegalPersonRequestDto,
    public Uprawniony?: NewDesignerRequestDto,
  ) {}

  static fromApp(data: LawPersonSearch): ApiLawPerson {
    return data.id
      ? new this(
          data.personType && data.personType.id,
          data.id,
          undefined,
          data.mainPersonOfDesginerPortal &&
          data.dataToSend &&
          isDesigner(data.dataToSend)
            ? data.dataToSend
            : undefined,
        )
      : new this(
          data.personType && data.personType.id,
          undefined,
          isDesigner(data.dataToSend) ? undefined : data.dataToSend,
          isDesigner(data.dataToSend) ? data.dataToSend : undefined,
        );
  }
}

export class ApiNewZudSprawaWniosekDalDto {
  constructor(
    public Osoby: ApiLawPerson[],
    public Lokalizacja: string,
    public ObrebyDzialki: ApiNewZudObrebyDalDto[],
    public SieciPrzylacza: SieciPrzylaczaApi[],
    public Zakresy: Wkt[],
    public Dokumenty: ApiNewDokumentPowiazanyDalDto[],
    public Temat: string,
    public ZgodaWnioskMail: boolean,
  ) {}
}

export class Payer {
  payerName = '';
  payerAddress = '';
  payerNip = '';
}

export class Investor {
  investorName = '';
  investorAddress = '';
  investorNip = '';
}

export interface NewDesignerRequestFormModel {
  connectionsAndNetworks: ConnectionAndNetworkFormGroupContent[];
  chosenParcels: EgibObject[];
  chosenAddresses: Address[];
  typedAddress: string;
  issueRanges: MapObject[];
  lawPersons: LawPersonSearch[];
  [NewDesignerRequestControlName.CouncilSubject]: string;
  [NewDesignerRequestControlName.EmailingConsent]: boolean;
  permissionNumber: string;
}

export interface UndeterminedDocFile {
  base64: string;
  docTypeId: number | string;
  docDescription: string;
  name: string;
}

export enum ParcelTabId {
  Map = 'mapForParcelChoice',
  Form = 'formForParcelChoice',
  NoContent = 'noContent',
}

export enum MaxLengthDesignerRequest {
  CouncilSubject = 1500,
  TypedAddress = 4000,
  Description = 4000,
}
