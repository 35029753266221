import { Component } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { takeWhile } from 'rxjs';
import { GkKendoGridComponent } from '../../../../../gk-kendo';
import { LandDetailsGridDto } from './map-land-details-grid-data-model';
import { LandDetailsGridDataService } from './map-land-details-grid-data.service';
import {
  getLandDetailsGridToolbarItems,
  landDetailsGridColumns,
} from './map-land-details-grid-model';

@Component({
  selector: 'gk-land-details-grid',
  templateUrl:
    '../../../../../gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class LandDetailsGridComponent extends GkKendoGridComponent<LandDetailsGridDto> {
  constructor(private landDetailsGridDataService: LandDetailsGridDataService) {
    super(landDetailsGridDataService);
  }

  override topGridToolbarItems = getLandDetailsGridToolbarItems(this);
  override columns = landDetailsGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  protected override firstRowSelected = true;
  override sortable = true;
  private requestQuantity = 50;

  addPointGeometryFromMap(geometry: string): void {
    const payload = this.createPostBody(geometry);
    this.landDetailsGridDataService
      .queryByState(payload)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe();
  }

  private createPostBody(geometry: string): State {
    return {
      filter: {
        filters: [{ field: 'Geom', operator: 'intersects', value: geometry }],
        logic: 'and',
      },
      take: this.requestQuantity,
    };
  }
}
