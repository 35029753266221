import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ExpandibleTitleControlConfig } from '../../../gk-dynamic-list.model';
import { Control } from '../control';

@Component({
  selector: 'gk-control-expandible-title',
  templateUrl: './control-expandible-title.component.html',
  styleUrls: ['./control-expandible-title.component.scss'],
  standalone: false,
})
export class ControlExpandibleTitleComponent
  extends Control<any>
  implements OnInit
{
  readonly threeDotsLength = 3;
  @Input()
  override controlConfig: ExpandibleTitleControlConfig;
  collapsed = true;

  override ngOnInit(): void {
    super.ngOnInit();
    this.collapsed = this.controlConfig.collapsed;
  }

  switchCollapse(): void {
    this.collapsed = !this.collapsed;
  }

  getIconClass(): string {
    return `fa ${this.collapsed ? 'fa-angle-right' : 'fa-angle-down'}`;
  }

  getFullLabel(): string {
    const label = _.get(
      this.data,
      this.controlConfig.pathToLabel,
      this.getLabel(),
    );

    return this.getLabelWithCountWhenIsDefined(label);
  }

  getButtonLabel(): string {
    const maxLetters = this.controlConfig.maxLettersToShow;
    const label = this.getFullLabel();

    return label.length > maxLetters
      ? `${label.substring(0, maxLetters - this.threeDotsLength)}...`
      : label;
  }
}
