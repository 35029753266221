import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { WindowRefService } from '../../../gk-dynamic-list/services/window-ref.service';
import { FormBaseComponent } from '../../form-base/form-base.component';
import { InputFieldConfig } from '../../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  standalone: false,
})
export class FormInputComponent
  extends FormBaseComponent
  implements AfterViewInit, OnDestroy
{
  private isAlive = true;
  override config: InputFieldConfig;

  constructor(private windowRefService: WindowRefService) {
    super();
  }

  ngAfterViewInit(): void {
    this.listenToSpecialCharacterChange();
  }

  listenToSpecialCharacterChange(): void {
    this.getControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => this.focusToFieldIfSpecialCharacter(value));
  }

  focusToFieldIfSpecialCharacter(value: string): void {
    const fieldNameToFocus =
      this.windowRefService.nativeWindow.document.getElementById(
        this.config.fieldNameToFocus,
      );
    this.config.specialCharactersToFocus.forEach((specialCharacter) => {
      if (value && value.includes(specialCharacter)) {
        this.getControl().patchValue(value.replace(specialCharacter, ''));
        fieldNameToFocus.focus();
      }
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
