import {
  ApiNewDokumentPowiazanyDalDto,
  LawPersonAddress,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  DocumentationFormType,
  ExtendedApplicantRequest,
  ExtendedApplicantRequestDto,
} from '../../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../../shared/shipping-form/shipping-form.model';

export class FCNewRequest extends ExtendedApplicantRequest {
  constructor(
    investorDetails: InvestorDetails,
    proxyDetails: InvestorDetails,
    public idNumber: number,
    public phoneNumber: number,
    public email: string,
    public birthDate: NgbDate,
    public birthPlace: string,
    public address: LawPersonAddress,
    proxyDetailsCheckbox: boolean,
    public attachments: ApiNewDokumentPowiazanyDalDto,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
  ) {
    super(
      investorDetails,
      documentationForm,
      shippingForm,
      undefined,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static fromAppToApi(
    data: FCNewRequest,
    files: ApiNewDokumentPowiazanyDalDto[],
  ): ExtendedApplicantRequestDto {
    return {
      ...ExtendedApplicantRequest.mapCommonFields({
        data: {
          ...data,
          investorDetails: {
            ...data.investorDetails,
            birthPlace: data.birthPlace,
            birthDate: data.birthDate,
          },
        },
        files,
      }),
    };
  }
}
