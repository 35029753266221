import { Component, Input } from '@angular/core';
import { TitleControlConfig } from '../../../gk-dynamic-list.model';
import { Control } from '../control';

@Component({
  selector: 'gk-control-title',
  templateUrl: './control-title.component.html',
  styleUrls: ['./control-title.component.scss'],
  standalone: false,
})
export class ControlTitleComponent extends Control<any> {
  @Input() override controlConfig: TitleControlConfig;
}
