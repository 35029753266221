<form [formGroup]="formGroup" novalidate>
  <section class="card mb-4" [ngClass]="borderClass">
    <div class="card-header" [ngClass]="backgroundClass">
      {{ 'DOCUMENTATION_FORM' | translate }}
    </div>

    <div class="card-body">
      @if (electronicForm) {
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="documentationFormType.ElectronicForm"
            [attr.name]="controlName.DocumentationForm"
            [formControlName]="controlName.DocumentationForm"
            [value]="documentationFormType.ElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="documentationFormType.ElectronicForm"
          >
            {{ 'ELECTRONIC_FORM' | translate }}
          </label>
        </div>
      }

      @if (nonElectronicForm) {
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="documentationFormType.NonElectronicForm"
            [attr.name]="controlName.DocumentationForm"
            [formControlName]="controlName.DocumentationForm"
            [value]="documentationFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="documentationFormType.NonElectronicForm"
          >
            {{ 'NON_ELECTRONIC_FORM' | translate }}
          </label>
        </div>
      }
    </div>
  </section>

  @if (portalId) {
    <section
      class="card mb-4"
      [ngClass]="
        checkShippingFormGroupValidAndFormSubmitted()
          ? 'border-danger border-2'
          : borderClass
      "
    >
      <div class="card-header" [ngClass]="backgroundClass">
        {{ 'SHIPPING_FORM_HEADER' | translate }}
      </div>
      <div class="card-body">
        <app-shipping-form
          [shippingFormGroup]="getShippingFormGroup()"
          [documentationForm]="getDocumentationFormRadioFormControl()?.value"
          [portalId]="portalId"
        ></app-shipping-form>
      </div>
      @if (checkShippingFormGroupValidAndFormSubmitted()) {
        <div class="card-footer">
          <div class="alert alert-danger">
            {{ 'SHIPPING_FORM_REQUIRED' | translate }}
          </div>
        </div>
      }
    </section>
  }
</form>
