import { BaseNewRequestControlName } from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum SNNewRequestAdditionalControlName {
  ObjectCategoryOrDescription = 'objectCategoryOrDescription',
  ObjectProposedNewName = 'objectProposedNewName',
  ObjectLocation = 'objectLocation',
  ObjectNamingJustification = 'objectNamingJustification',
  Comments = 'comments',
}

export const SNNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...SNNewRequestAdditionalControlName,
};
