import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/kendo/form-field';
import { FormFieldOrientation, RadioProps } from './model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-formly-field-kendo-radio',
  template: `
    <ul
      class="k-radio-list"
      [ngClass]="{
        'flex-row': props.orientation === formFieldOrientation.Horizontal,
        'flex-column': props.orientation === formFieldOrientation.Vertical
      }"
    >
      @for (
        option of props.options | formlySelectOptions: field | async;
        track i;
        let i = $index
      ) {
        @if (!isOptionDisabled(option)) {
          <li class="k-radio-item">
            <input
              type="radio"
              #radioInput
              kendoRadioButton
              [id]="id + '_' + i"
              [name]="field.name || id"
              [value]="option.value"
              [formControl]="option.disabled ? disabledControl : formControl"
              [formlyAttributes]="field"
            />
            <label class="k-radio-label pe-2" [for]="id + '_' + i">
              {{ option.label | translate }}
            </label>
          </li>
          <li class="k-radio-item"></li>
        }
      }
    </ul>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class FormlyFieldKendoRadioComponent extends FieldType<
  FieldTypeConfig<RadioProps>
> {
  formFieldOrientation = FormFieldOrientation;

  get disabledControl(): FormControl {
    return new FormControl({ value: this.formControl.value, disabled: true });
  }

  isOptionDisabled(option: any): boolean {
    if (typeof option.disabled === 'boolean') {
      return option.disabled;
    }
    return false;
  }
}
