import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlWidth } from './control.model';

@Directive({
  selector: '[gkControlLabel]',
  standalone: false,
})
export class ControlLabelDirective implements OnInit {
  @Input() showLabel = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (this.showLabel) {
      this.renderer.addClass(
        this.el.nativeElement,
        `w-sm-${ControlWidth.Small}`,
      );
      this.renderer.addClass(this.el.nativeElement, 'w-100');
      this.renderer.addClass(this.el.nativeElement, 'fw-bold');
      this.renderer.addClass(this.el.nativeElement, 'text-right');
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }
  }
}
