import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  ActionButtonsConfig,
  GridActionButtonsConfig,
} from './gk-kendo-action-buttons-model';

@Component({
  selector: 'gk-kendo-action-buttons',
  templateUrl: './gk-kendo-action-buttons.component.html',
  styleUrls: ['./gk-kendo-action-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class GkKendoActionButtonsComponent {
  @Input()
  buttonsConfigs: GridActionButtonsConfig;

  onGridActionButtonClick(buttonItem: ActionButtonsConfig, event: Event): void {
    buttonItem.click && buttonItem.click(event);
  }

  getButtonDisabledState(gridToolbarItem: ActionButtonsConfig): boolean {
    if (typeof gridToolbarItem.disabled === 'function') {
      return gridToolbarItem.disabled();
    }

    return gridToolbarItem.disabled;
  }
}
