import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gk-kendo-grid-counter',
  styleUrls: ['./gk-kendo-grid-counter.component.scss'],
  template: `<div class="d-flex h-100">
    <div class="d-flex align-self-center">
      <span class="d-none d-md-flex"
        >{{ 'COUNTER.QUANTITY' | translate }}:&nbsp;</span
      >
      <div kendoTooltip [closable]="true" showOn="click">
        <span title="{{ 'COUNTER.QUANTITY' | translate }}">{{
          counter | async
        }}</span>
      </div>
    </div>
  </div>`,
  standalone: false,
})
export class GkKendoGridCounterComponent {
  @Input()
  counter: BehaviorSubject<number>;
}
