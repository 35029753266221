<div class="d-flex flex-column text-center align-items-center">
  <div class="alert alert-success mt-3" role="alert">
    <i class="fa fa-3x fa-check-circle mb-3" aria-hidden="true"></i>
    <h4 class="alert-heading">
      {{ 'PAYMENT_SUCCESS_SIMPLE_INFO.HEADER' | translate }}
    </h4>
    <hr />
    <p class="mb-0">{{ 'PAYMENT_SUCCESS_SIMPLE_INFO.INFO' | translate }}</p>
  </div>
</div>
