<button
  type="button"
  class="action-button"
  [ngClass]="getButtonClasses()"
  [title]="actionButtonNavItemConfig.title | translate"
  (click)="handleClick()"
  data-bs-toggle="collapse"
  data-bs-target=".navbar-collapse.show"
>
  @if (
    actionButtonNavItemConfig.showIcon && actionButtonNavItemConfig.faIconName
  ) {
    <span [ngClass]="getIconClassSet()"></span>
  }
  @if (actionButtonNavItemConfig.customIconSrc.length) {
    <span class="d-flex justify-content-center">
      <img
        class="image-width"
        [src]="actionButtonNavItemConfig.customIconSrc"
        alt="{{ actionButtonNavItemConfig.buttonLabel | translate }} - {{
          'NAVBAR.TRANSLATION.FLAG' | translate
        }}"
      />
    </span>
  }
  @if (actionButtonNavItemConfig.showLabel) {
    <span
      [ngClass]="{ 'd-xl-none': actionButtonNavItemConfig.hideLabelOnDesktop }"
    >
      {{ actionButtonNavItemConfig.buttonLabel | translate }}
    </span>
  }
  @if (actionButtonNavItemConfig.showBadge) {
    <span
      class="position-absolute top-0 translate-middle badge bg-danger action-button__badge"
    >
      {{ actionButtonNavItemConfig.badgeValue | translate }}
    </span>
  }
</button>
