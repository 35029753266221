import { Component, Input } from '@angular/core';
import { SkipLinksConfiguration } from './skip-links.model';

@Component({
  selector: 'gk-skip-links',
  templateUrl: './skip-links.component.html',
  styleUrls: ['./skip-links.component.scss'],
  standalone: false,
})
export class SkipLinksComponent {
  @Input() currentSkipLinksConfiguration: SkipLinksConfiguration;
}
