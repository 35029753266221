<div class="container content mt-5">
  <div class="card">
    @for (
      request of (masterId
        ? getRequestsByMasterId(masterId, departmentCode)
        : getDepartmentRequestsByCode(departmentCode)
      ) | async;
      track request.portalId
    ) {
      @if (request.show) {
        <div class="list-group list-group-flush">
          <button
            (click)="goToService(request.url)"
            type="button"
            class="list-group-item list-group-item-action"
            id="portal-{{ request.portalId }}"
          >
            {{ request.name }}
          </button>
        </div>
      }
    }
  </div>
  @if (masterId) {
    <button routerLink="../" type="button" class="btn btn-outline-dark mt-2">
      {{ 'OFFICE_DEPARTMENTS_PORTAL.DEPARTMENT_RETURN' | translate }}
    </button>
  }
  @if (!newRequestFromPlanningDepartmentMode && !masterId) {
    <button
      routerLink="/office-departments-portal"
      type="button"
      class="btn btn-outline-dark mt-2"
    >
      {{ 'OFFICE_DEPARTMENTS_PORTAL.DEPARTMENTS_RETURN' | translate }}
    </button>
  }
</div>
