import { BaseNewRequestControlName } from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum ALUNewRequestAdditionalControlName {
  ExclusionPurpose = 'exclusionPurpose',
  InvestmentCommencementDate = 'investmentCommencementDate',
}

export const ALUNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...ALUNewRequestAdditionalControlName,
};
