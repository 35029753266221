import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import { AuthorizedPersonToRegistryView } from '../../services/authorized-person-to-registry-view/authorized-person-to-registry-view.model';

@Component({
  selector: 'app-authorized-person-to-registry-view',
  templateUrl: './authorized-person-to-registry-view.component.html',
  styleUrls: ['./authorized-person-to-registry-view.component.scss'],
  standalone: false,
})
export class AuthorizedPersonToRegistryViewComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  @Input() showInitialEmptyRequiredRow: boolean;
  @Input() authorizedPersonsToRegistryView: AuthorizedPersonToRegistryView[] =
    [];
  @Input() isAuthorizedPersonWithLoggedUserDuplicatePrevent: boolean;
  @Output() authorizedPersonsToRegistryViewChange = new EventEmitter<
    AuthorizedPersonToRegistryView[]
  >();
  authorizedPersonDuplicateInfoText = '';

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.fetchAuthorizedPersonDuplicateInfoTranslation();
  }

  fetchAuthorizedPersonDuplicateInfoTranslation(): void {
    this.translateService
      .get('NEW_REQUEST.AUTHORIZED_PERSON_DUPLICATE_INFO')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (authorizedPersonDuplicateInfoText) =>
          (this.authorizedPersonDuplicateInfoText =
            authorizedPersonDuplicateInfoText),
      );
  }

  addNewUniquePersonToList(
    authorizedPerson: AuthorizedPersonToRegistryView,
  ): void {
    if (
      this.authorizedPersonsToRegistryView.some(
        (person) => person.pesel === authorizedPerson.pesel,
      )
    ) {
      this.toastr.info(this.authorizedPersonDuplicateInfoText);

      return;
    }
    this.authorizedPersonsToRegistryViewChange.emit([
      ...this.authorizedPersonsToRegistryView,
      authorizedPerson,
    ]);
  }

  handleAuthorizedPersonsToRegistryView(
    authorizedPersons: AuthorizedPersonToRegistryView[],
  ): void {
    this.authorizedPersonsToRegistryView = authorizedPersons;
    this.authorizedPersonsToRegistryViewChange.emit(
      this.authorizedPersonsToRegistryView,
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
