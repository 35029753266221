import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPerson,
} from '@gk/gk-modules';
import { WorkspaceState } from '../../../../services/request-workspace-state/request-workspace-state.model';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export interface PMFDDGRNewDataUpdateRequestDto extends ApplicantRequestDto {
  Uzasadnienie: string;
}

export class PMFDDGRNewDataUpdateRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    public reason: string,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: PMFDDGRNewDataUpdateRequest,
    parcels: EgibObject[],
    buildings: EgibObject[],
    premises: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): PMFDDGRNewDataUpdateRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        parcels,
        buildings,
        premises,
        files,
      }),
      Uzasadnienie: data.reason,
    };
  }
}

export interface PMFDDGRNewDataUpdateRequestFormWorkspaceState
  extends WorkspaceState {
  formValue: PMFDDGRNewDataUpdateRequest;
  parcels: EgibObject[];
  buildings: EgibObject[];
  premises: EgibObject[];
}
