import { Component } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { FormBaseComponent } from '../../form-base/form-base.component';
import { DatepickerFieldConfig } from '../../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-datepicker',
  templateUrl: './form-datepicker.component.html',
  standalone: false,
})
export class FormDatepickerComponent extends FormBaseComponent {
  override config: DatepickerFieldConfig;
  model: NgbDateStruct;
  showAlert = false;

  getStartDate(): NgbDateStruct {
    return this.group.get(this.config.startDateControlName).value;
  }

  getEndDate(): NgbDateStruct {
    return this.group.get(this.config.endDateControlName).value;
  }

  shouldCheckWhetherCurrentDateIsAfterStartDate(): boolean {
    return (
      !!this.model &&
      !!this.config.startDateControlName &&
      !!this.getStartDate()
    );
  }

  shouldCheckWhetherCurrentDateIsBeforeEndDate(): boolean {
    return (
      !!this.model && !!this.config.endDateControlName && !!this.getEndDate()
    );
  }

  isFirstDateBeforeSecond = (
    firstDate: NgbDateStruct,
    secondDate: NgbDateStruct,
  ): boolean =>
    new Date(firstDate.year, firstDate.month, firstDate.day).getTime() <
    new Date(secondDate.year, secondDate.month, secondDate.day).getTime();

  shouldUnsetCurrentModelAndShowAlertWhenComparingCurrentDateToStartDate(): boolean {
    return (
      this.shouldCheckWhetherCurrentDateIsAfterStartDate() &&
      !_.isEqual(this.getStartDate(), this.model) &&
      !this.isFirstDateBeforeSecond(this.getStartDate(), this.model)
    );
  }

  shouldUnsetCurrentModelAndShowAlertWhenComparingCurrentDateToEndDate(): boolean {
    return (
      this.shouldCheckWhetherCurrentDateIsBeforeEndDate() &&
      !_.isEqual(this.model, this.getEndDate()) &&
      !this.isFirstDateBeforeSecond(this.model, this.getEndDate())
    );
  }

  unsetCurrentModelAndShowAlert(): void {
    this.model = undefined;
    this.getControl().setValue(undefined);
    this.showAlert = true;
  }

  checkModelAndChangeIfNecessary(): void {
    this.showAlert = false;

    if (
      this.shouldUnsetCurrentModelAndShowAlertWhenComparingCurrentDateToStartDate()
    ) {
      this.unsetCurrentModelAndShowAlert();
      return;
    }

    if (
      this.shouldUnsetCurrentModelAndShowAlertWhenComparingCurrentDateToEndDate()
    ) {
      this.unsetCurrentModelAndShowAlert();
      return;
    }
  }
}
