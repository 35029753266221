import { BaseNewRequestControlName } from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum SPPNewRequestAdditionalControlName {
  Place = 'place',
  Street = 'street',
  ParcelArea = 'parcelArea',
  Comments = 'comments',
  Reason = 'reason',
  OwnershipForm = 'ownershipForm',
  AdjacentProperty = 'adjacentProperty',
  Other = 'other',
}

export const SPPNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...SPPNewRequestAdditionalControlName,
};

export enum SPPOwnershipForm {
  PerpetualUser = 0,
  CoOwner = 1,
}

export enum SPPParcelArea {
  WholeParcel = 0,
  PartOfParcel = 1,
}
