import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core';
import * as _ from 'lodash';
import {
  ColumnHeader,
  TableSelectionMode,
  WrapCellConfig,
} from './table.model';

@Component({
  selector: 'gk-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: false,
})
export class TableComponent implements OnChanges {
  @Input() settings: ColumnHeader[];
  @Input()
  records: any[];
  @Output()
  select: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input()
  cellTemplate: TemplateRef<any>;
  @Input() preCellTemplate: TemplateRef<any>;
  @Input() dictionaryFieldsTemplate: TemplateRef<any>;
  @Input()
  selectionMode: TableSelectionMode = TableSelectionMode.Multiple;
  @Input()
  loading: boolean;
  @Input()
  selectedRows: any[] = [];
  @Input() showInitialEmptyRequiredRow: boolean;

  columnMaps: ColumnHeader[] = [];
  cellMaps: ColumnHeader[] = [];
  selectedRowIndexes: number[] = [];

  ngOnChanges(): void {
    this.setColumnMapsAndFilterByVisibilityIfNecessary();
    if (this.checkIfRecordsNotPresent()) {
      return;
    }
    this.setColumnAndCellMapsFromObjectProperties();
  }

  setColumnMapsAndFilterByVisibilityIfNecessary(): void {
    if (this.settings) {
      this.columnMaps = this.filterVisibleColumns(this.settings);
    }
  }

  checkIfRecordsNotPresent(): boolean {
    return this.records && !this.records.length;
  }

  setColumnAndCellMapsFromObjectProperties(): void {
    const columnHeadersFromObjectProperties = this.settings
      ? this.settings
      : this.getColumnHeadersFromObjectProperties();
    this.columnMaps = this.filterVisibleColumns(
      columnHeadersFromObjectProperties,
    );
    this.cellMaps = this.filterColumnsWithNoValuePaths(
      columnHeadersFromObjectProperties,
    );
  }

  getColumnHeadersFromObjectProperties(): ColumnHeader[] {
    return Object.keys(this.records[0]).map((key) => ({
      pathToValue: key,
      label: `${key.slice(0, 1).toUpperCase()}${key
        .replace(/_/g, ' ')
        .slice(1)}`,
      visible: true,
      columnWidth: '',
      wrapCellConfig: new WrapCellConfig(),
    }));
  }

  handleRowSelection(
    record: any,
    $event: MouseEvent,
    selectedRowIndex: number,
  ): void {
    this.handleSelectionMode(record, $event, selectedRowIndex);
  }

  handleSelectionMode(
    record: any,
    $event: MouseEvent,
    selectedRowIndex: number,
  ): void {
    if (this.selectionMode === TableSelectionMode.Multiple) {
      if ($event.ctrlKey) {
        this.handleCtrlKey(record, selectedRowIndex);
        return;
      }
      if ($event.shiftKey) {
        this.handleShiftKey(selectedRowIndex);
        return;
      }
    }
    this.handleSingleSelection(record, selectedRowIndex);
  }

  handleCtrlKey(record: any, selectedRowIndex: number): void {
    if (this.selectedRowIndexes.includes(selectedRowIndex)) {
      return;
    }
    this.selectedRowIndexes = [...this.selectedRowIndexes, selectedRowIndex];
    this.selectedRows = [...this.selectedRows, record];
    this.select.emit(this.selectedRows);
  }

  handleShiftKey(selectedRowIndex: number): void {
    this.selectedRows = [];
    this.selectedRowIndexes = this.getSelectedRecordsRange(selectedRowIndex);
    this.selectedRows = [
      ...this.selectedRows,
      ...this.selectedRowIndexes.map((i) => this.records[i]),
    ];
    this.select.emit(this.selectedRows);
  }

  handleSingleSelection(record: any, selectedRowIndex: number): void {
    this.selectedRowIndexes = [selectedRowIndex];
    this.selectedRows = [record];
    this.select.emit(this.selectedRows);
  }

  getSelectedRecordsRange(selectedRowIndex: number): number[] {
    const selectedRowMinIndex = Math.min(...this.selectedRowIndexes);

    return selectedRowIndex < selectedRowMinIndex
      ? _.range(Math.max(...this.selectedRowIndexes), selectedRowIndex - 1)
      : _.range(selectedRowIndex, selectedRowMinIndex - 1);
  }

  isSelected(record: any): boolean {
    return this.selectedRows.some((selectedRow) =>
      _.isEqual(selectedRow, record),
    );
  }

  filterVisibleColumns(columnHeaders: ColumnHeader[]): ColumnHeader[] {
    return columnHeaders.filter((columnHeader) => columnHeader.visible);
  }

  filterColumnsWithNoValuePaths(columnHeaders: ColumnHeader[]): ColumnHeader[] {
    return columnHeaders.filter((columnHeader) => columnHeader.pathToValue);
  }

  shouldShowEmptyRequiredRow(): boolean {
    return _.isEmpty(this.records) && this.showInitialEmptyRequiredRow;
  }

  isLoading(): boolean {
    return typeof this.loading !== 'undefined'
      ? this.loading
      : !this.records || !this.records.length;
  }
}
