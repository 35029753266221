import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {
  AttachmentEvent,
  AttachmentFileInputId,
  FileExtension,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { getFileSizeString, parseFile } from '../../utils/files/files.util';

@Component({
  selector: 'app-attachment-input',
  templateUrl: './attachment-input.component.html',
  styleUrls: ['./attachment-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AttachmentInputComponent implements OnInit, OnChanges, OnDestroy {
  private isAlive = true;
  @Input() stringifiedFile: string;
  @Input() fileInputId = AttachmentFileInputId.Project;
  @Input() extensions: FileExtension[] = [];
  @Input() isRequired = false;
  @Input() wasValidated = false;
  dropText = '';
  attachmentInputTranslations: { [key: string]: string };
  @Output() fileChanged = new EventEmitter<AttachmentEvent>();
  files?: File[] = [];
  errorText = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.subscribeToDefaultDropText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const stringifiedFileChange = _.get(changes, 'stringifiedFile');
    this.checkAndInitStringifiedFile(stringifiedFileChange);
  }

  checkAndInitStringifiedFile(stringifiedFileChange: SimpleChange): void {
    if (
      stringifiedFileChange &&
      !stringifiedFileChange.firstChange &&
      !_.isEqual(
        stringifiedFileChange.currentValue,
        stringifiedFileChange.previousValue,
      )
    ) {
      this.initFileFromStringifiedFile();
    }
  }

  subscribeToDefaultDropText(): void {
    this.translateService
      .get('ATTACHMENT_INPUT')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translations) => {
        this.attachmentInputTranslations = translations;
        this.updateDropText();
      });
  }

  async initFileFromStringifiedFile(): Promise<void> {
    if (!this.stringifiedFile || !_.isString(this.stringifiedFile)) {
      this.resetFile();
      return;
    }
    const file = (await parseFile(this.stringifiedFile)) as File;

    this.stringifiedFile = '';
    this.files = [file];
    this.handleFileInputChangeAction(this.files);
  }

  updateDropText(): void {
    this.dropText =
      this.files?.[0]?.name ||
      _.get(this.attachmentInputTranslations, 'DROP_TEXT', '');
  }

  emitAttachmentEvent(): void {
    this.fileChanged.emit(
      new AttachmentEvent(this.fileInputId, this.files?.[0]),
    );
  }

  checkIfNotEmptyFile(): void {
    this.errorText = '';
    if (_.get(this.files?.[0], 'size') === 0) {
      this.files = [];
      this.errorText = _.get(
        this.attachmentInputTranslations,
        'EMPTY_FILE',
        '',
      );
    }
  }

  handleFileInputChangeAction(files: File[]): void {
    this.files = files;
    this.checkIfNotEmptyFile();
    this.updateDropText();
    this.emitAttachmentEvent();
  }

  resetFile(): void {
    this.files = [];
    this.handleFileInputChangeAction(this.files);
  }

  getCurrentFileSizeString(): string {
    const fileSize = this.files?.[0]?.size;
    return fileSize ? getFileSizeString(fileSize) : '';
  }

  getFileFormattedExtension(ext: FileExtension): string {
    return `*.${ext}`;
  }

  getAllAvailableFormattedFileExtensions(): string {
    return this.extensions.reduce(
      (formattedExts, currentExt) =>
        formattedExts
          ? `${formattedExts}, ${this.getFileFormattedExtension(currentExt)}`
          : this.getFileFormattedExtension(currentExt),
      '',
    );
  }

  getGeneratedInvalidTypeText(): string {
    const defaultInvalidText = _.get(
      this.attachmentInputTranslations,
      'INVALID_EXTENSION',
      '',
    );
    const possibleExtensionsText = _.get(
      this.attachmentInputTranslations,
      'POSSIBLE_EXTENSIONS',
      '',
    );
    return `${defaultInvalidText} ${possibleExtensionsText} ${this.getAllAvailableFormattedFileExtensions()}`;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
