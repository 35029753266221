import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  Place,
  Street,
  getValueWhenPropertyIsAddressFields,
  getValueWhenPropertyIsString,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { PostalCode } from '../../../../services/postal-code/postal-code.model';
import {
  DocumentationFormType,
  ExtendedInvestorRequest,
  ExtendedInvestorRequestDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../../utils/date-helper/date-helper.util';

export interface BPTNewRequestDto extends ExtendedInvestorRequestDto {
  DaneDotychczasowegoInwestora: InvestorDetailsRequestDto;
  ZDnia: string;
  NumerPozwolenia: string;
  NazwaIRodzajBudowy: string;
  MiejscId: string;
  Miejsc: string;
  UlicaId: string;
  Ulica: string;
  KodId: string;
  Kod: string;
  Poczta: string;
  Numer: string;
  Numer2: string;
}

export class BPTNewRequest extends ExtendedInvestorRequest {
  constructor(
    investorDetails: InvestorDetails,
    public currentInvestorDetails: InvestorDetails,
    proxyDetails: InvestorDetails,
    public buildingNameAndType: string,
    public place: Place,
    public street: Street,
    public buildingNumber: string,
    public localNumber: string,
    public postalCode: PostalCode,
    public postOffice: string,
    public permitTransferDate: NgbDate,
    public permitTransferNumber: string,
    proxyDetailsCheckbox: boolean,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
  ) {
    super(
      investorDetails,
      documentationForm,
      shippingForm,
      undefined,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static fromAppToApi(
    data: BPTNewRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): BPTNewRequestDto {
    return {
      ...ExtendedInvestorRequest.mapCommonFields({
        data,
        parcels,
        files,
      }),
      DaneDotychczasowegoInwestora: InvestorDetailsRequestDto.fromAppToApi(
        data.currentInvestorDetails,
      ),
      ZDnia: DateHelperUtil.ngbDateToApiDateTime(data.permitTransferDate),
      NumerPozwolenia: data.permitTransferNumber,
      NazwaIRodzajBudowy: data.buildingNameAndType,
      MiejscId: getValueWhenPropertyIsAddressFields(data.place),
      Miejsc: getValueWhenPropertyIsString(data.place),
      UlicaId: getValueWhenPropertyIsAddressFields(data.street),
      Ulica: getValueWhenPropertyIsString(data.street?.name),
      KodId: getValueWhenPropertyIsAddressFields(data.postalCode),
      Kod: getValueWhenPropertyIsString(data.postalCode),
      Poczta: data.postOffice,
      Numer: data.buildingNumber,
      Numer2: data.localNumber,
    };
  }
}
