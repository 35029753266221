import { ChangeDetectionStrategy, Component } from '@angular/core';
import * as _ from 'lodash';
import { scales } from '../../../configs';
import { MapControl } from '../../../controls';
import {
  MapAction,
  MapViewActionType,
  Scale,
  SourceType,
  ToolType,
} from '../../../models';

@Component({
  selector: 'gk-map-toolbar',
  templateUrl: './map-toolbar.component.html',
  styleUrls: ['./map-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapToolbarComponent extends MapControl {
  generalScales: Scale[] = [...scales];

  handleGeneralResolutionChange(resolution: number): void {
    this.dispatch.emit(
      new MapAction(MapViewActionType.ResolutionChange, resolution),
    );
  }

  shouldShowMapToolContainer(
    toolType: ToolType,
    sourceType: SourceType,
  ): boolean {
    return (
      _.get(
        this.mapState,
        `toolsState[${toolType}][${sourceType}].isActive`,
        false,
      ) &&
      _.get(
        this.mapState,
        `toolsState[${toolType}][${sourceType}].hasMapToolContainer`,
        false,
      )
    );
  }
}
