import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Section, SectionFromApi } from './section.model';

@Injectable()
export class SectionService {
  sections = new BehaviorSubject<Section[]>([]);

  constructor(private http: HttpClient) {
    this.fetchSections();
  }

  fetchSections(): void {
    this.getSections().subscribe((sections) => this.sections.next(sections));
  }

  getSections(): Observable<Section[]> {
    return this.http.get<SectionFromApi[]>('/api/system/pktosn/sekcje').pipe(
      map((sections) =>
        sections.map((section) => Section.fromApiToApp(section)),
      ),
      catchError((): [] => []),
    );
  }
}
