import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import { SNNewRequestControlName } from './new-request-form.model';

@Injectable()
export class SNNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [SNNewRequestControlName.ProxyDetailsCheckbox]: false,
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [SNNewRequestControlName.ObjectCategoryOrDescription]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [SNNewRequestControlName.ObjectProposedNewName]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [SNNewRequestControlName.ObjectLocation]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [SNNewRequestControlName.ObjectNamingJustification]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [SNNewRequestControlName.Comments]: [getDefaultControlValue()],
    });
  }
}
