import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { MapLandClassesGridDataService } from './map-land-classes-grid/map-land-classes-grid-data.service';
import { LandDetailsDto } from './map-land-details-details/map-land-details-details.model';
import { LandDetailsDtoPayload } from './map-land-details-wrapper.model';

@Injectable({
  providedIn: 'any',
})
export class MapLandDetailsWrapperService {
  public $loading = new BehaviorSubject<boolean>(false);
  public $landDetails = new Subject<LandDetailsDto>();
  public landDetailsSubscription: Subscription;

  constructor(
    private httpClient: HttpClient,
    private mapLandClassesGridDataService: MapLandClassesGridDataService,
  ) {}

  handleLandSelected(landUUID: string): void {
    this.$loading.next(true);
    this.mapLandClassesGridDataService.$loading.next(true);
    this.landDetailsSubscription = this.httpClient
      .post<LandDetailsDto>(
        'api/mapa/dzialki/szczegoly',
        this.createLandDetailsPayload(landUUID),
      )
      .subscribe((data: LandDetailsDto) => {
        this.mapLandClassesGridDataService.updateGridData(data);
        this.$landDetails.next(data);
        this.$loading.next(false);
        this.mapLandClassesGridDataService.$loading.next(false);
      });
  }

  private createLandDetailsPayload(landUUID: string): LandDetailsDtoPayload {
    return {
      uuid: landUUID,
    };
  }
}
