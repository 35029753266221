import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[app-test-email][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useValue: emailValidator,
      multi: true,
    },
  ],
  standalone: false,
})
export class EmailValidatorDirective {}

export function emailValidator(
  control: UntypedFormControl,
): { emailInvalid: true } | undefined {
  const value = control.value;
  let invalid = false;

  if (value) {
    const regExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    if (!regExp.test(value)) {
      invalid = true;
    }
  }

  return invalid ? { emailInvalid: true } : undefined;
}
