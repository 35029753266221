import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { map, takeWhile } from 'rxjs/operators';
import { GkKendoCommunicatorService } from './gk-kendo-communicator.service';
import { tap } from 'rxjs';
import {
  MessageAuthor,
  Messages,
  Recipient,
  RecipientFromApi,
} from '../../../communicator-modal/communicator.model';
import { NewMessageDto } from './gk-kendo-communicator.model';
import { GkKendoMessagesListGridDataService } from '../gk-kendo-messages-list-grid/gk-kendo-messages-list-grid-data.service';

@Component({
  selector: 'gk-kendo-communicator',
  templateUrl: './gk-kendo-communicator.component.html',
  styleUrls: ['./gk-kendo-communicator.component.scss'],
  standalone: false,
})
export class GkKendoCommunicatorComponent implements OnDestroy, AfterViewInit {
  @ViewChild('messagesContainer') messagesContainer: ElementRef;
  @ViewChildren('messagesQueryList') messagesQueryList: QueryList<Messages>;
  public messageAuthor = MessageAuthor;
  public sendMessageUrl: string;
  public refreshCallback: () => void;
  public caseId: any;
  public communicatorMessagesUrl: string;
  public recipientsUrl: string;

  protected messagesLoading = true;
  protected messagesSending = false;
  protected newMessage: string;
  protected messages: Messages[];
  protected recipients: Recipient[];
  protected selectedRecipientId: string;

  private isAlive = true;

  constructor(
    private communicatorService: GkKendoCommunicatorService,
    private gkKendoMessagesListGridDataService: GkKendoMessagesListGridDataService,
  ) {}

  ngAfterViewInit(): void {
    this.registerScrollToLastMessage();
  }

  resetForm(): void {
    this.newMessage = '';
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  protected getMessageBackgroundColorClass(author: MessageAuthor): string {
    switch (author) {
      case MessageAuthor.Odgik:
        return 'message-color-red';
      case MessageAuthor.CoordinationMeeting:
        return 'message-color-green';
      case MessageAuthor.DesignerPortal:
      case MessageAuthor.Surveyor:
        return 'message-color-blue';
      default:
        return '';
    }
  }

  protected handleSendMessage(event: Event): void {
    event.preventDefault();
    this.sendMessage();
  }

  protected sendMessage(): void {
    this.communicatorService
      .sendMessage(this.completeDataToDto(), this.sendMessageUrl)
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => (this.messagesSending = true)),
      )
      .subscribe(() => {
        this.resetForm();
        this.fetchMessages();
      });
  }

  public fetchMessages(): void {
    this.messagesLoading = true;
    this.communicatorService
      .fetchCommunicatorMessages(this.communicatorMessagesUrl)
      .pipe(
        takeWhile(() => this.isAlive),
        map((data) => data.map((item) => Messages.fromApiToApp(item))),
      )
      .subscribe({
        next: (messages) => {
          this.messages = messages;
          this.messagesLoading = false;
          this.messagesSending = false;
          this.handleRefreshCallback();
          this.gkKendoMessagesListGridDataService?.rebind();
        },
        error: () => (this.messagesLoading = false),
      });
  }

  private handleRefreshCallback(): void {
    if (this.refreshCallback) {
      this.refreshCallback();
    }
  }

  public fetchRecipients(): void {
    this.communicatorService
      .getRecipients(this.recipientsUrl)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((recipients) => {
        this.selectedRecipientId = recipients.KoncowyAdresatUuid;
        this.recipients = recipients.Adresaci.map(
          (recipient: RecipientFromApi) => Recipient.fromApiToApp(recipient),
        );
      });
  }

  private completeDataToDto(): NewMessageDto {
    return {
      Komunikat: this.newMessage,
      IdSprawy: this.caseId,
      ZudUuid: this.caseId,
      AdresaciUuids: this.selectedRecipientId ? [this.selectedRecipientId] : [],
    };
  }

  private registerScrollToLastMessage(): void {
    this.messagesQueryList.changes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.scrollToBottom();
      });
  }

  private scrollToBottom(): void {
    try {
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  shouldShowLegendItem(
    messages: Messages[],
    messageAuthor: MessageAuthor,
  ): boolean {
    return messages?.some((message) => message.author === messageAuthor);
  }
}
