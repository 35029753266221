import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BaseStyleClassesDirective } from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { isObservable, map, Observable, of, takeWhile } from 'rxjs';
import {
  BsMessageType,
  maxWorkspaceStateSizeInBytes,
  WorkspaceState,
  WorkspaceStateDraftVersion,
  WorkspaceStateId,
} from '../../services/request-workspace-state/request-workspace-state.model';
import { RequestWorkspaceStateService } from '../../services/request-workspace-state/request-workspace-state.service';
import { getJsonMemorySize } from '../../utils/json.util';
@Component({
  selector: 'app-workspace-state',
  templateUrl: './workspace-state.component.html',
  styleUrl: './workspace-state.component.scss',
  standalone: false,
})
export class WorkspaceStateComponent<TWorkspaceState extends WorkspaceState>
  extends BaseStyleClassesDirective
  implements OnInit, OnDestroy
{
  private isAlive = true;
  workspaceStateTranslations: { [key: string]: string } = {};
  workspaceStateLoading = false;
  workspaceMsgType: BsMessageType;
  workspaceMsgTxt = '';
  @Input() workspaceStateDraftVersion: WorkspaceStateDraftVersion;
  @Input() workspaceStateId: WorkspaceStateId;
  @Input() getWorkspaceStateToSave: () =>
    | Observable<TWorkspaceState>
    | TWorkspaceState;
  @Output() workspaceStateLoaded = new EventEmitter<TWorkspaceState>();

  constructor(
    private requestWorkspaceStateService: RequestWorkspaceStateService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToWorkspaceStateTranslations();
  }

  subscribeToWorkspaceStateTranslations(): void {
    this.translateService
      .get('WORKSPACE_STATE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translations) => (this.workspaceStateTranslations = translations),
      );
  }

  setWorkspaceMsg(type: BsMessageType, text: string): void {
    this.workspaceMsgType = type;
    this.workspaceMsgTxt = text;
  }

  setWorkspaceLoading(translationKey: string): void {
    this.setWorkspaceMsg(
      BsMessageType.Info,
      this.workspaceStateTranslations[translationKey],
    );
    this.workspaceStateLoading = true;
  }

  handleWorkspaceJobDone(success: boolean, translationKey: string): void {
    this.setWorkspaceMsg(
      success ? BsMessageType.Success : BsMessageType.Danger,
      this.workspaceStateTranslations[translationKey],
    );
    this.workspaceStateLoading = false;
  }

  hasWorkspaceProperMemorySize(workspace: TWorkspaceState): boolean {
    const jsonSize = getJsonMemorySize(_.cloneDeep(workspace));

    return jsonSize <= maxWorkspaceStateSizeInBytes;
  }

  loadWorkspaceState(): void {
    this.setWorkspaceLoading('DOWNLOADING');
    this.requestWorkspaceStateService
      .getWorkspaceState<TWorkspaceState>(this.workspaceStateId)
      .subscribe({
        next: (state) => {
          try {
            this.handleLoadWorkspaceResponse(state);
          } catch {
            this.handleWorkspaceJobDone(false, 'NOT_COMPATIBLE');
          }
        },
        error: () => this.handleWorkspaceJobDone(false, 'ERROR_OCCURED'),
      });
  }

  handleLoadWorkspaceResponse(state: TWorkspaceState): void {
    if (!state) {
      this.handleWorkspaceJobDone(false, 'EMPTY_WORKSPACE');
      return;
    }
    if (state.draftVersion !== this.workspaceStateDraftVersion) {
      this.handleWorkspaceJobDone(false, 'NOT_COMPATIBLE');
      return;
    }
    this.workspaceStateLoaded.emit(state);
    this.handleWorkspaceJobDone(true, 'LOADED_SUCCEED');
  }

  saveWorkspaceState(): void {
    this.setWorkspaceLoading('UPLOADING');
    const workspaceToSaveResult = this.getWorkspaceStateToSave();
    (isObservable(workspaceToSaveResult)
      ? workspaceToSaveResult
      : of(workspaceToSaveResult)
    )
      .pipe(
        takeWhile(() => this.isAlive),
        map((workspace) => ({
          draftVersion: this.workspaceStateDraftVersion,
          ...workspace,
        })),
      )
      .subscribe((workspaceToSave) => {
        if (!this.hasWorkspaceProperMemorySize(workspaceToSave)) {
          this.handleWorkspaceJobDone(false, 'TOO_BIG');
          return;
        }
        this.requestWorkspaceStateService
          .updateWorkspaceState(this.workspaceStateId, workspaceToSave)
          .subscribe({
            next: () => this.handleWorkspaceJobDone(true, 'UPLOADED_SUCCEED'),
            error: () => this.handleWorkspaceJobDone(false, 'ERROR_OCCURED'),
          });
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
