import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, interval, Subject } from 'rxjs';

@Injectable()
export class SwManagerService {
  public newAppVersionAvailable = new Subject<void>();
  public swLoading = new BehaviorSubject<boolean>(false);
  private isFirstInitialization = true;
  private readonly CHECK_INTERVAL = 1000 * 60 * 5;

  constructor(
    @Inject('window') private window: Window,
    private swUpdate: SwUpdate,
  ) {}

  public init(): void {
    if (!this.isSWEnabled()) {
      return;
    }
    this.checkForUpdatesImmediately();
    this.subscribeToSwAvailable();
    this.setCheckInterval();
  }

  private isSWEnabled(): boolean {
    if (!this.swUpdate.isEnabled) {
      console.warn(
        'Service worker is not available in development mode! Use production mode instead.',
      );
    }

    return this.swUpdate.isEnabled;
  }

  private checkForUpdatesImmediately(): void {
    if (!this.isSWEnabled()) {
      return;
    }

    this.swUpdate.checkForUpdate();
  }

  private setCheckInterval(): void {
    interval(this.CHECK_INTERVAL).subscribe(() => {
      this.isFirstInitialization = false;
      this.swUpdate.checkForUpdate();
    });
  }

  private subscribeToSwAvailable(): void {
    this.swUpdate.versionUpdates.subscribe((event) => {
      switch (event.type) {
        case 'VERSION_DETECTED':
          this.handleVersionDetected();

          break;
        case 'VERSION_READY':
          this.handleVersionReady();

          break;

        case 'NO_NEW_VERSION_DETECTED':
          this.handleNoNewVersion();

          break;

        default:
          console.warn('Nieznany typ zdarzenia:', event);
      }
    });
  }

  private handleVersionDetected(): void {
    if (this.isFirstInitialization) {
      this.swLoading.next(true);
    }
  }

  private handleVersionReady(): void {
    if (this.isFirstInitialization) {
      this.updateAppVersion();
    } else {
      this.newAppVersionAvailable.next();
    }
  }

  private handleNoNewVersion(): void {
    this.isFirstInitialization = false;
    this.swLoading.next(false);
  }

  public updateAppVersion(): void {
    if (!this.isSWEnabled()) {
      return;
    }
    this.swLoading.next(true);
    this.swUpdate.activateUpdate().then(() => {
      this.window.document.location.reload();
    });
  }
}
