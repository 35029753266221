import { AbstractControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { Community } from '../../../../gk-dynamic-form/services/cached/cached.model';
import { CachedService } from '../../../../gk-dynamic-form/services/cached/cached.service';
import { FilterOperatorType } from '../../../../gk-dynamic-list/gk-dynamic-list.model';
import { ApiFilter } from '../../../../gk-dynamic-list/services/services.model';
import { isNotEmptyObject } from '../../../../utils/utils';
import { PprfBuildingFormModel } from '../../pprf-building-form/pprf-building-form.model';
import { PprfParcelFormModel } from '../../pprf-parcel-form/pprf-parcel-form.model';
import { PprfPremisesFormModel } from '../../pprf-premises-form/pprf-premises-form.model';
import { PprfPropertyFormModel } from '../../pprf-property-form/pprf-property-form.model';
import {
  PprfTransactionAndPricingFormControlName,
  PprfTransactionAndPricingFormModel,
} from '../../pprf-transaction-and-pricing-form/pprf-transaction-and-pricing-form.model';

export class RtParcelFiltersDto {
  constructor(
    public SloPrzeznMpzp: number[],
    public SposUzytId: number[],
    public PowDlkOd: number,
    public PowDlkDo: number,
  ) {}

  static fromAppToApi(
    data: PprfParcelFormModel,
  ): RtParcelFiltersDto | undefined {
    const instance = new this(
      data.developmentPlanDesignation,
      data.useMethod,
      data.areaFrom,
      data.areaTo,
    );

    return isNotEmptyObject(instance) ? instance : undefined;
  }
}

export class RtBuildingFiltersDto {
  constructor(
    public FunBudId: number[],
    public RokBudowyOd: number,
    public RokBudowyDo: number,
    public KondNadOd: number,
    public KondNadDo: number,
    public KondPodOd: number,
    public KondPodDo: number,
    public PowUzOd: number,
    public PowUzDo: number,
  ) {}

  static fromAppToApi(
    data: PprfBuildingFormModel,
  ): RtBuildingFiltersDto | undefined {
    const instance = new this(
      data.buildingType,
      data.constructionYearFrom,
      data.constructionYearTo,
      data.floorsAboveFrom,
      data.floorsAboveTo,
      data.floorsBelowFrom,
      data.floorsBelowTo,
      data.usableAreaFrom,
      data.usableAreaTo,
    );

    return isNotEmptyObject(instance) ? instance : undefined;
  }
}

export class RtPremisesFiltersDto {
  constructor(
    public IdFunLok: number[],
    public IdSloKondygn: number[],
    public IlIzbOd: number,
    public IlIzbDo: number,
    public PowUzLokOd: number,
    public PowUzLokDo: number,
    public PowPomieszczenPrzynaleznychLokOd: number,
    public PowPomieszczenPrzynaleznychLokDo: number,
  ) {}

  static fromAppToApi(
    data: PprfPremisesFormModel,
  ): RtPremisesFiltersDto | undefined {
    const instance = new this(
      data.premisesFunction,
      data.floor,
      data.roomsNumberFrom,
      data.roomsNumberTo,
      data.premisesUsableAreaFrom,
      data.premisesUsableAreaTo,
      data.associatedPremisesAreaFrom,
      data.associatedPremisesAreaTo,
    );

    return isNotEmptyObject(instance) ? instance : undefined;
  }
}

export class ParcelFiltersDto {
  constructor(
    public NrL: number,
    public NrM: number,
    public NrAdd: number,
  ) {}

  static fromAppToApi(data: PprfParcelFormModel): ParcelFiltersDto | undefined {
    const instance = new this(
      data.numberNumerator,
      data.numberDenominator,
      data.numberAdditional,
    );

    return isNotEmptyObject(instance) ? instance : undefined;
  }
}

export class RtRelationsFiltersDto {
  constructor(
    public RtDzialka: RtParcelFiltersDto,
    public RtBudynek: RtBuildingFiltersDto,
    public RtLokal: RtPremisesFiltersDto,
    public Dzialka: ParcelFiltersDto,
    public ObrebId: number[],
    public GminaId: number[],
    public Geom: string,
  ) {}

  static fromAppToApi(
    data: PprfAttributesSearchFormModel,
    geom: string,
  ): RtRelationsFiltersDto {
    const instance = new this(
      RtParcelFiltersDto.fromAppToApi(data.parcel),
      RtBuildingFiltersDto.fromAppToApi(data.building),
      RtPremisesFiltersDto.fromAppToApi(data.premises),
      ParcelFiltersDto.fromAppToApi(data.parcel),
      data.transactionAndPricing.district || undefined,
      data.transactionAndPricing.cadastralUnit?.map(
        (cadastralUnit) => cadastralUnit.id,
      ),
      geom,
    );

    return isNotEmptyObject(instance) ? instance : undefined;
  }
}

export enum PprfAttributesSearchFilterName {
  Type = 'Typ',
  TransactionAndPricingDateFrom = 'DataTranOd',
  TransactionAndPricingDateTo = 'DataTranDo',
  RegistrationDateFrom = 'DataDOd',
  RegistrationDateTo = 'DataDDo',
  ValuationPurpose = 'CelSzacowania',
  MarketType = 'RodzajRynku',
  PropertyType = 'TypNieruch',
  Seller = 'Zbywajacy',
  Buyer = 'Nabywca',
  TransactionType = 'FormaObrotu',
  GrossTransactionPrice = 'Cena',
  RtRelations = 'RtRelations',
  Address = 'Adres',
  HasDigitalDocumentation = 'Zbior',
  Valued = 'Wyceniona',
  Share = 'Udzial',
  RightType = 'RodzajPrawNieruch',
  LandRegistryAreaFrom = 'PowNieruchGrunOd',
  LandRegistryAreaTo = 'PowNieruchGrunDo',
}

export class PprfAddressSearchFormModelDto {
  constructor(
    public MiejscowoscId: string | number,
    public UlicaId: string | number,
    public NumerBudynku: string | number,
  ) {}

  static fromAppToApi(
    data: PprfPropertyFormModel,
  ): PprfAddressSearchFormModelDto {
    const instance = new this(data.place?.id, data.street?.id, data.number);

    return isNotEmptyObject(instance) ? instance : undefined;
  }
}

export class PprfAttributesSearchFormModelDto {
  constructor(
    public Typ: number[],
    public DataTransOd: string,
    public DataTransDo: string,
    public DataDOd: string,
    public DataDdo: string,
    public CelSzacowania: number[],
    public FormaObrotu: number[],
    public TypNieruch: number[],
    public Zbywajacy: number[],
    public Nabywca: number[],
    public RodzajRynku: number[],
    public Cena: number[],
    public RtRelations: ApiFilter,
    public Adres: ApiFilter,
    public Zbior: boolean,
    public Wyceniona: boolean,
    public UdzialL: number,
    public Udzial: number,
    public UdzialM: number,
    public RodzajPrawNieruch: number[],
    public PowNieruchGrunOd: number,
    public PowNieruchGrunDo: number,
  ) {}

  static fromAppToApi(
    data: PprfAttributesSearchFormModel,
    geom: string,
  ): ApiFilter[] {
    const getShareByRangesControl = data.property.getShareByRangesControl();

    return [
      new ApiFilter(
        PprfAttributesSearchFilterName.Type,
        data.transactionAndPricing.type,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.TransactionAndPricingDateFrom,
        data.transactionAndPricing.transactionAndPricingDateFrom,
        FilterOperatorType.GreaterThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.TransactionAndPricingDateTo,
        data.transactionAndPricing.transactionAndPricingDateTo,
        FilterOperatorType.LessThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.RegistrationDateFrom,
        data.transactionAndPricing.registrationDateFrom,
        FilterOperatorType.GreaterThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.RegistrationDateTo,
        data.transactionAndPricing.registrationDateTo,
        FilterOperatorType.LessThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.ValuationPurpose,
        data.transactionAndPricing.valuationPurpose,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.MarketType,
        data.transactionAndPricing.marketType,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.PropertyType,
        data.property.propertyType,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Seller,
        data.transactionAndPricing.seller,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Buyer,
        data.transactionAndPricing.buyer,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.TransactionType,
        data.transactionAndPricing.transactionType,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.GrossTransactionPrice,
        data.transactionAndPricing.grossTransactionPrice,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.RtRelations,
        RtRelationsFiltersDto.fromAppToApi(data, geom),
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Address,
        PprfAddressSearchFormModelDto.fromAppToApi(data.property),
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.HasDigitalDocumentation,
        data.transactionAndPricing.hasDigitalDocumentation,
        FilterOperatorType.Equals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Valued,
        data.transactionAndPricing.hasPrice,
        FilterOperatorType.Equals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Share,
        getShareByRangesControl.from,
        FilterOperatorType.GreaterThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Share,
        getShareByRangesControl.to,
        FilterOperatorType.LessThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.RightType,
        data.property.rightType,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.LandRegistryAreaFrom,
        data.property.landRegistryAreaFrom,
        FilterOperatorType.GreaterThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.LandRegistryAreaTo,
        data.property.landRegistryAreaTo,
        FilterOperatorType.LessThanOrEquals,
      ),
    ].filter(
      (filter) =>
        filter.value !== undefined &&
        filter.value !== null &&
        (!Array.isArray(filter.value) || filter.value.length > 0),
    );
  }
}

export class PprfAttributesSearchFormModel {
  constructor(
    public transactionAndPricing: PprfTransactionAndPricingFormModel,
    public property: PprfPropertyFormModel,
    public parcel: PprfParcelFormModel,
    public building: PprfBuildingFormModel,
    public premises: PprfPremisesFormModel,
  ) {}
}

export const getAttributesSearchFormModelNewInstance =
  (): PprfAttributesSearchFormModel =>
    new PprfAttributesSearchFormModel(
      PprfTransactionAndPricingFormModel.getEmptyModel(),
      PprfPropertyFormModel.getEmptyModel(),
      PprfParcelFormModel.getEmptyModel(),
      PprfBuildingFormModel.getEmptyModel(),
      PprfPremisesFormModel.getEmptyModel(),
    );

export enum PprfFormNavId {
  TransactionAndPricing = 'transactionAndPricing',
  Property = 'property',
  Parcel = 'parcel',
  Building = 'building',
  Premises = 'premises',
}

export const pprfBaseFormlyPresetsConfig: Partial<FormlyFieldConfig> = {
  type: 'select',
  defaultValue: null,
  props: {
    multiple: true,
  },
};

export const pprfCommunityFormlyPresetsConfigFactory =
  (): Partial<FormlyFieldConfig> => ({
    ...pprfBaseFormlyPresetsConfig,
    key: PprfTransactionAndPricingFormControlName.CadastralUnit,
    props: {
      ...pprfBaseFormlyPresetsConfig.props,
      valuePrimitive: undefined,
      valueProp: (value: Community) => value,
      compareWith: (o1: Community, o2: Community) => o1?.id === o2?.id,
    },
    hooks: {
      onInit: (field): void => {
        field.options.fieldChanges
          .pipe(
            filter((e) => {
              return (
                e.type === 'hidden' &&
                e.value === 1 &&
                e.field?.key ===
                  PprfTransactionAndPricingFormControlName.CadastralUnit
              );
            }),
            tap(() => {
              field.formControl.setValue(null);
            }),
          )
          .subscribe();
      },
    },
  });

export const pprfDictrictFormlyPresetsConfigFactory = (
  cachedService: CachedService,
): Partial<FormlyFieldConfig> => ({
  ...pprfBaseFormlyPresetsConfig,
  key: PprfTransactionAndPricingFormControlName.District,
  props: {
    ...pprfBaseFormlyPresetsConfig.props,
    valuePrimitive: undefined,
  },
  hooks: {
    onInit: (field): void => {
      field.options.fieldChanges
        .pipe(
          filter((e) => {
            return (
              e.type === 'hidden' &&
              e.value === 1 &&
              e.field?.key === PprfTransactionAndPricingFormControlName.District
            );
          }),
          tap(() => {
            field.formControl.setValue(null);
          }),
        )
        .subscribe();
    },
    afterViewInit: (field): void => {
      const communityControl = field.parent.get(
        PprfTransactionAndPricingFormControlName.CadastralUnit,
      ).formControl;
      communityControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((communities: Community[]) => {
          field.formControl.reset();
          if (communities?.length) {
            field.props.options = communities.flatMap(
              (community) => community.districts,
            );
          } else {
            field.props.options = cachedService.allDistricts;
          }
        });

      maybeEmitInitialValueOfCommunityAfterFormReopen(communityControl);
    },
  },
});

const maybeEmitInitialValueOfCommunityAfterFormReopen = (
  communityControl: AbstractControl,
): void => {
  setTimeout(() => {
    const currentCommunityValue = communityControl.value;
    if (currentCommunityValue) {
      communityControl.setValue(currentCommunityValue, {
        emitEvent: true,
      });
    }
  });
};
