import { HttpHeaders } from '@angular/common/http';
import { FormlyFormOptions } from '@ngx-formly/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { FilterRightOperand } from '../gk-dynamic-list/gk-dynamic-list.model';
import { LandParcelDto } from '../gk-dynamic-list/services/dynamic-list/dynamic-list.model';

export const landParcelNumberPattern = new RegExp(
  '^\\d+(\\/\\d+)?(-[a-zA-Z]\\d+)?$',
);

export function createLandParcelDtoObjectFromString(
  rightOperand: FilterRightOperand,
): LandParcelDto {
  const landParcelNumber = rightOperand as string;
  const splittedLandParcelNumber = landParcelNumber.split(/\/|-/);

  return new LandParcelDto(
    +splittedLandParcelNumber[0],
    splittedLandParcelNumber[1] ? +splittedLandParcelNumber[1] : undefined,
    splittedLandParcelNumber[2],
  );
}

export function getFileNameFromResponseHeaders(headers: HttpHeaders): string {
  const filename = headers
    .get('content-disposition')!
    .split('; ')[1]
    .replace(/%20/g, ' ');

  return decodeURIComponent(filename.substring(10, filename.length - 1));
}

export function isObservable(
  obj: unknown,
): obj is Observable<FormlyFormOptions[]> {
  return obj instanceof Observable;
}

export function isBehaviorSubject(
  obj: unknown,
): obj is BehaviorSubject<FormlyFormOptions[]> {
  return obj instanceof BehaviorSubject;
}

export function isAnonymousSubject(
  obj: unknown,
): obj is AnonymousSubject<FormlyFormOptions[]> {
  return obj instanceof AnonymousSubject;
}

export function isObjectOfTypeObservable(
  obj: unknown,
): obj is
  | Observable<FormlyFormOptions[]>
  | BehaviorSubject<FormlyFormOptions[]>
  | AnonymousSubject<FormlyFormOptions[]> {
  return isObservable(obj) || isBehaviorSubject(obj) || isAnonymousSubject(obj);
}

export const getCacheString = (...keys: any[]): string => keys.join('_');

export const isNotEmptyObject = (obj: any): boolean => {
  if (obj === null || obj === undefined) return false;
  if (typeof obj !== 'object') return true;
  const hasOwn = Object.prototype.hasOwnProperty.bind(obj);

  return Object.keys(obj).some((key) => {
    if (hasOwn(key)) {
      const value = obj[key];
      return value !== undefined && isNotEmptyObject(value);
    }
    return false;
  });
};

export function createAndClickTemporaryLink(
  document: Document,
  dataURI: string,
  fileName: string,
): void {
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = dataURI;
  link.download = fileName;
  link.click();
  link.remove();
}

export function saveFileByTemporaryLinkBase(
  document: Document,
  geom: string,
  fileName: string,
): void {
  const dataURI = `data:text/plain;base64,${btoa(geom)}`;
  createAndClickTemporaryLink(document, dataURI, fileName);
}

export function validateIntegerInput(key: string): boolean {
  return /^\d$/.test(key);
}

export function validateFloatInput(key: string): boolean {
  return /^\d$/.test(key) || key === '.' || key === ',';
}
