import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { SeparatorControlConfig } from '../../../gk-dynamic-list.model';
import { Control } from '../control';

@Component({
  selector: 'gk-control-separator',
  templateUrl: './control-separator.component.html',
  styleUrls: ['./control-separator.component.scss'],
  standalone: false,
})
export class ControlSeparatorComponent extends Control<any> {
  @Input() override controlConfig: SeparatorControlConfig;

  getSize(): string {
    return _.get(
      this.data,
      this.controlConfig.pathToCssWidthValue,
      this.controlConfig.staticCssWidthValue,
    );
  }
}
