import { Component, OnInit } from '@angular/core';
import { ListConfigs } from '@gk/gk-modules';
import { ConfigManagerService } from '../../../services/config-manager/config-manager.service';

@Component({
  templateUrl: './user-requests-dynamic.component.html',
  styleUrls: ['./user-requests-dynamic.component.scss'],
  standalone: false,
})
export class UserRequestsDynamicComponent implements OnInit {
  listConfigs: ListConfigs;
  constructor(private configManagerService: ConfigManagerService) {}

  ngOnInit(): void {
    this.setListConfigs();
  }

  setListConfigs(): void {
    this.listConfigs = this.configManagerService.getConfigForPrzp();
  }
}
