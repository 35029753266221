import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ColorsService,
  DepartmentCode,
  GkDynamicNavbarUiStateStoreService,
  NavbarUiState,
  PortalId,
  SystemConfig,
  SystemConfigBlobGuestService,
  SystemConfigGuestService,
  WebPortal,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs';
import { PortalTranslationDropdownItemId } from '../../../../components/navbar-provider/dynamic-navbar.configuration';
import { MainRoutes } from '../../../../guards/guard.models';
import { SessionService } from '../../../../services/session/session.service';
import { SystemCountyBackgroundService } from '../../../../services/system-county-background/system-county-background.service';
import { WebPortalService } from '../../../../services/web-portal/web-portal.service';
import { WebRequestService } from '../../../../services/web-request/web-request.service';
import { WindowRefService } from '../../../../services/window-ref/window-ref.service';
import { PokCardOpacity } from '../departments.model';
import { ModalDocumentVerificationComponent } from './modal-document-verification/modal-document-verification.component';
import { ModalRequestsListComponent } from './modal-requests-list/modal-requests-list.component';

@Component({
  selector: 'app-geodesy',
  templateUrl: './geodesy.component.html',
  styleUrls: ['./geodesy.component.scss'],
  standalone: false,
})
export class GeodesyComponent implements OnInit, OnDestroy {
  private isAlive = true;
  webCmsCountiesHeaderText: SafeHtml;
  webPortalData: WebPortal[];
  departmentCode = DepartmentCode;
  @ViewChild(ModalRequestsListComponent)
  modalRequestsListComponent: ModalRequestsListComponent;
  @ViewChild(ModalDocumentVerificationComponent)
  modalDocumentVerificationComponent: ModalDocumentVerificationComponent;
  webCmsEnabled: string;
  mainPortalData: WebPortal;
  portalCardHoverIds: { [index: number]: boolean } = [];
  defaultThemeSelected: boolean;
  pokCardOpacity = PokCardOpacity;
  backgroundUrlFromDb: string;
  webFooterLogoExists = false;
  navBarUiState: NavbarUiState;
  translationNavItemId: string;
  interModWarningText: string;

  constructor(
    private route: ActivatedRoute,
    private windowRefService: WindowRefService,
    public systemConfigGuestService: SystemConfigGuestService,
    public webPortalService: WebPortalService,
    public sessionService: SessionService,
    public systemConfigBlobGuestService: SystemConfigBlobGuestService,
    private colorsService: ColorsService,
    private systemCountyBackgroundService: SystemCountyBackgroundService,
    private gkDynamicNavbarUiStateStoreService: GkDynamicNavbarUiStateStoreService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private webRequestService: WebRequestService,
    @Inject('window') public window: Window,
  ) {}

  ngOnInit(): void {
    this.subscribeDefaultThemeSelected();
    this.subscribeToRouteParams();
    this.fetchWebPortalData();
    this.subscribeToWebCmsEnabled();
    this.fetchMainPortalData();
    this.sessionService.fetchSystemLoggedUserData();
    this.subscribeToWebFooterLogo();
    this.subscribeToNavbarUiState();
    this.subscribeInterModWarningText();
  }

  subscribeDefaultThemeSelected(): void {
    this.colorsService.defaultThemeSelected
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((defaultThemeSelected) => {
        if (defaultThemeSelected) {
          this.defaultThemeSelected = true;
          this.maybeSetBackgroundImageFromDb();
        } else {
          this.defaultThemeSelected = false;
          this.backgroundUrlFromDb = '';
        }
      });
  }

  subscribeToRouteParams(): void {
    this.route.queryParams
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((params) => {
        if (params['showDocumentVerificationWindow']) {
          setTimeout(() => this.showModalDocumentVerification());
        }
      });
  }

  setTranslationHeader(): void {
    this.systemConfigGuestService
      .getSysConfigGuestValueByKey(this.getTranslatedHeaderKey())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (webCmsCountiesHeaderTranslation) =>
          (this.webCmsCountiesHeaderText =
            this.sanitizer.bypassSecurityTrustHtml(
              webCmsCountiesHeaderTranslation,
            )),
      );
  }

  getTranslatedHeaderKey(): string {
    switch (this.translationNavItemId) {
      case PortalTranslationDropdownItemId.PolishTranslation:
        return SystemConfig.WebCmsCountiesHeaderTextPl;
      case PortalTranslationDropdownItemId.EnglishTranslation:
        return SystemConfig.WebCmsCountiesHeaderTextEn;
      case PortalTranslationDropdownItemId.CzechTranslation:
        return SystemConfig.WebCmsCountiesHeaderTextCz;
      case PortalTranslationDropdownItemId.GermanTranslation:
        return SystemConfig.WebCmsCountiesHeaderTextDe;
      default:
        return SystemConfig.WebCmsCountiesHeaderTextPl;
    }
  }

  subscribeToWebCmsEnabled(): void {
    this.systemConfigGuestService
      .getSysConfigGuestValueByKey(SystemConfig.WebCmsEnabled)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((webCmsEnabled) => (this.webCmsEnabled = webCmsEnabled));
  }

  fetchWebPortalData(): void {
    this.webPortalService
      .getGeodesyWebPortals()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => (this.webPortalData = data));
  }

  fetchMainPortalData(): void {
    this.webPortalService
      .getMainWebPortalData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (mainWebPortalData) => (this.mainPortalData = mainWebPortalData),
      );
  }

  getEServiceUrl(webPortalUrl: string): string {
    if (webPortalUrl.includes('https://') || webPortalUrl.includes('http://')) {
      return webPortalUrl;
    } else {
      return `${window.location.origin}/${webPortalUrl}`;
    }
  }

  goToInstructions(): void {
    this.windowRefService.nativeWindow.location.href = `${window.location.origin}/dokuweb/`;
  }

  goToFaq(): void {
    this.windowRefService.nativeWindow.location.href = `${window.location.origin}/dokuweb/faq/faq/`;
  }

  showModalRequestsList(): void {
    this.modalRequestsListComponent.showModal();
  }

  showModalDocumentVerification(): void {
    this.modalDocumentVerificationComponent.showModal();
  }

  getTeamViewerDownloadUrl(): string {
    return `https://www.geomatyka-krakow.pl/download/TeamViewerQS-idc6s8u3r3.exe`;
  }

  getPokCardBackgroundColor(opacity: string): string {
    return `rgba(255,255,255, ${opacity})`;
  }

  maybeSetBackgroundImageFromDb(): void {
    this.systemCountyBackgroundService
      .getApplicationBase64BackgroundFromDb()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((backgroundBase64) => {
        if (backgroundBase64) {
          this.backgroundUrlFromDb = `url(data:image/png;base64,${backgroundBase64})`;
        } else {
          this.backgroundUrlFromDb = '';
        }
      });
  }

  subscribeToWebFooterLogo(): void {
    this.systemConfigBlobGuestService
      .getSysConfigWebFooterLogoData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((webFooterLogoBase64Data) => {
        if (webFooterLogoBase64Data) {
          this.webFooterLogoExists = true;
        }
      });
  }

  handleWebPortalRedirect(webPortal: WebPortal): void {
    if (webPortal.portalId === PortalId.OtherGeodesyRequests) {
      this.router.navigate([
        `/${MainRoutes.Department}`,
        DepartmentCode.Geodesy,
        PortalId.OtherGeodesyRequests,
      ]);

      return;
    }
    if (webPortal.portalId === PortalId.InterMod) {
      const url = this.getEServiceUrl(webPortal.url);
      const relativeUrl = url.substring(
        url.indexOf(webPortal.apiUrl) + webPortal.apiUrl.length,
      );

      this.webRequestService.fetchArrayBufferData(relativeUrl).subscribe({
        next: () => {
          window.location.href = url;
        },
        error: (err) => {
          if (err.status === 401) {
            this.toastr.warning(this.interModWarningText);
          } else {
            window.location.href = url;
          }
        },
      });

      return;
    }
    this.window.location.href = this.getEServiceUrl(webPortal.url);
  }

  subscribeToNavbarUiState(): void {
    this.gkDynamicNavbarUiStateStoreService.navbarUiState.subscribe(
      (navBarUiState) => {
        this.navBarUiState = navBarUiState;
        this.maybeSetTranslationNavItemId(
          navBarUiState?.navAction.payload.navItemId,
        );
        this.setTranslationHeader();
      },
    );
  }

  subscribeInterModWarningText(): void {
    this.translateService
      .get('TOASTR.INTER_MOD_WARNING_TEXT')
      .subscribe((warningText) => {
        this.interModWarningText = warningText;
      });
  }

  maybeSetTranslationNavItemId(navItemId: string): void {
    if (
      Object.values(PortalTranslationDropdownItemId).includes(
        navItemId as PortalTranslationDropdownItemId,
      )
    ) {
      this.translationNavItemId = navItemId;
    }
  }

  getTranslatedWebPortalNameBySelectedInformationPortalTranslation(
    webPortal: WebPortal,
  ): string {
    switch (this.translationNavItemId) {
      case PortalTranslationDropdownItemId.PolishTranslation:
        return webPortal.name;
      case PortalTranslationDropdownItemId.EnglishTranslation:
        return webPortal.nameEn || webPortal.name;
      case PortalTranslationDropdownItemId.GermanTranslation:
        return webPortal.nameDe || webPortal.name;
      case PortalTranslationDropdownItemId.CzechTranslation:
        return webPortal.nameCz || webPortal.name;
      default:
        return webPortal.name;
    }
  }

  getTranslatedWebDescriptionNameBySelectedInformationPortalTranslation(
    webPortal: WebPortal,
  ): string {
    switch (this.translationNavItemId) {
      case PortalTranslationDropdownItemId.PolishTranslation:
        return webPortal.description;
      case PortalTranslationDropdownItemId.EnglishTranslation:
        return webPortal.descriptionEn || webPortal.description;
      case PortalTranslationDropdownItemId.GermanTranslation:
        return webPortal.descriptionDe || webPortal.description;
      case PortalTranslationDropdownItemId.CzechTranslation:
        return webPortal.descriptionCs || webPortal.description;
      default:
        return webPortal.description;
    }
  }

  getTranslateKeyWithAddedLanguageSuffix(translateKeyBase: string): string {
    switch (this.translationNavItemId) {
      case PortalTranslationDropdownItemId.PolishTranslation:
        return `${translateKeyBase}`;
      case PortalTranslationDropdownItemId.EnglishTranslation:
        return `${translateKeyBase}_ENGLISH`;
      case PortalTranslationDropdownItemId.GermanTranslation:
        return `${translateKeyBase}_GERMAN`;
      case PortalTranslationDropdownItemId.CzechTranslation:
        return `${translateKeyBase}_CZECH`;
      default:
        return `${translateKeyBase}`;
    }
  }

  getTranslateUsefulInfoNavInstructionKey(): string {
    const usefulNavButtonInstrutionBase =
      'OFFICE_DEPARTMENTS_PORTAL.GEODESY.USEFUL_INFO_NAV.BUTTONS.INSTRUCTIONS';
    return this.getTranslateKeyWithAddedLanguageSuffix(
      usefulNavButtonInstrutionBase,
    );
  }

  getTranslateUsefulInfoNavRequestsKey(): string {
    const usefulNavButtonRequestsBase =
      'OFFICE_DEPARTMENTS_PORTAL.GEODESY.USEFUL_INFO_NAV.BUTTONS.REQUESTS';
    return this.getTranslateKeyWithAddedLanguageSuffix(
      usefulNavButtonRequestsBase,
    );
  }

  getTranslateUsefulInfoNavDocVerificationKey(): string {
    const usefulNavButtonDocVerificationBase =
      'OFFICE_DEPARTMENTS_PORTAL.GEODESY.USEFUL_INFO_NAV.BUTTONS.DOCUMENT_VERIFICATION';
    return this.getTranslateKeyWithAddedLanguageSuffix(
      usefulNavButtonDocVerificationBase,
    );
  }

  getTranslateUsefulInfoNavRemoteHelpKey(): string {
    const usefulNavButtonRemoteHelpBase =
      'OFFICE_DEPARTMENTS_PORTAL.GEODESY.USEFUL_INFO_NAV.BUTTONS.REMOTE_HELP';
    return this.getTranslateKeyWithAddedLanguageSuffix(
      usefulNavButtonRemoteHelpBase,
    );
  }

  getTranslateUsefulInfoNavAccessibilityStatementKey(): string {
    const usefulNavButtonAccessibilityStatementBase = 'ACCESSIBILITY_STATEMENT';
    return this.getTranslateKeyWithAddedLanguageSuffix(
      usefulNavButtonAccessibilityStatementBase,
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
