<section class="mt-4">
  <div class="card mb-4" [ngClass]="borderClass">
    <div class="card-header" [ngClass]="backgroundClass">
      {{ workspaceStateTranslations['HEADER'] }}
    </div>

    <div class="card-body">
      <button
        [disabled]="workspaceStateLoading"
        type="button"
        class="btn btn-sm me-1"
        [ngClass]="buttonClass"
        (click)="saveWorkspaceState()"
      >
        <span class="fa fa-paperclip"></span>
        {{ workspaceStateTranslations['SAVE'] }}
      </button>

      <button
        [disabled]="workspaceStateLoading"
        type="button"
        class="btn btn-sm"
        [ngClass]="buttonClass"
        (click)="loadWorkspaceState()"
      >
        <span class="fa fa-inbox"></span>
        {{ workspaceStateTranslations['RESTORE'] }}
      </button>
    </div>

    @if (workspaceMsgTxt) {
      <div class="card-footer">
        <div class="d-flex flex-column">
          <span [ngClass]="'text-' + workspaceMsgType">
            @if (workspaceStateLoading) {
              <i class="fa fa-spinner fa-pulse fa-fw"></i>
            }
            {{ workspaceMsgTxt }}</span
          >
        </div>
      </div>
    }
  </div>
</section>
