import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import { RENewRequestControlName } from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class RENewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [RENewRequestControlName.ProxyDetailsCheckbox]: false,
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [RENewRequestControlName.RequestType]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [RENewRequestControlName.RoadNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [RENewRequestControlName.Place]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [RENewRequestControlName.PurposeOfPropertyUse]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [RENewRequestControlName.PurposeOfPropertyUseChanged]:
        this.formBuilder.group({
          [RENewRequestControlName.WillUseOfPropertyChange]: false,
          [RENewRequestControlName.ChangedPurposeOfPropertyUse]: [
            getDefaultControlValue(true),
            Validators.required,
          ],
        }),
    });
  }
}
