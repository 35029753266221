import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequestInProgressGuard } from '../../guards/request-in-progress/request-in-progress.guard';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRoutes } from './../../guards/guard.models';
import { MainRequestsListComponent } from './../../shared/main-requests-list/main-requests-list.component';
import { PMFDDGRNewDataUpdateRequestComponent } from './new-request/new-request.component';
import { PermanentManagementFeeDiscountDecisionGrantingRequestComponent } from './permanent-management-fee-discount-decision-granting-request.component';

const permanentManagementFeeDiscountDecisionGrantingRequestRoutes: Routes = [
  {
    path: MainRoutes.PermanentManagementFeeDiscountDecisionGrantingRequest,
    component: PermanentManagementFeeDiscountDecisionGrantingRequestComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: PMFDDGRNewDataUpdateRequestComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(
      permanentManagementFeeDiscountDecisionGrantingRequestRoutes,
    ),
  ],
  providers: [SessionGuard, RequestInProgressGuard],
  exports: [RouterModule],
})
export class PermanentManagementFeeDiscountDecisionGrantingRequestRoutingModule {}
