import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WebPortal } from '@gk/gk-modules';
import * as _ from 'lodash';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
} from 'rxjs';
import { MainRoutes } from '../../guards/guard.models';
import { WebPortalService } from '../web-portal/web-portal.service';

@Injectable()
export class AppTitleService {
  currentTitle = new BehaviorSubject<string>('');
  currentPortalRoute = new BehaviorSubject<MainRoutes>(MainRoutes.NoAccess);
  currentWebPortal = new BehaviorSubject<WebPortal>(null);

  constructor(
    private title: Title,
    private router: Router,
    private webPortalService: WebPortalService,
  ) {
    this.listenToRoute();
  }

  private getPortalPath(url: string): string {
    return _.get(url.split('/'), '[1]', '');
  }

  private listenToRoute(): void {
    this.router.events
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(() => {
        const path = this.getPortalPath(this.router.url);
        this.handleTitleInUnsupportedPaths(path);
        this.setTitle(path);
      });
  }

  private setTitle(path: string): void {
    this.currentPortalRoute.next(path as MainRoutes);
    this.webPortalService
      .getWebPortalDataByCurrentRoute(path as MainRoutes)
      .pipe(
        tap((webPortal) => {
          this.currentWebPortal.next(webPortal);
        }),
        map((webPortal) => {
          return webPortal?.name || '';
        }),
      )
      .subscribe((titleToSet) => {
        this.title.setTitle(titleToSet);
        this.currentTitle.next(titleToSet);
      });
  }

  private handleTitleInUnsupportedPaths(path: string): void {
    const accessibilityRoutes = [
      MainRoutes.AccessibilityStatementForClient,
      MainRoutes.AccessibilityStatementRegular,
      MainRoutes.AccessibilityStatementMobile,
    ];

    if (accessibilityRoutes.includes(path as MainRoutes)) {
      const titleToSet = 'Deklaracja dostępności';
      this.title.setTitle(titleToSet);
      this.currentTitle.next(titleToSet);
    } else {
      const titleToSet = 'Portal';
      this.title.setTitle(titleToSet);
      this.currentTitle.next(titleToSet);
    }
  }
}
