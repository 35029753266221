import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeWhile } from 'rxjs/operators';
import {
  PaymentAmountType,
  StampDutyPaymentDto,
} from '../../services/stamp-duty/stamp-duty.model';
import { PortalId } from '../../utils/portal-id/portal-id.model';
import { isNotEmptyObject } from '../../utils/utils';
import { Invoice, Payment } from '../payments.model';
import { PaymentsService } from '../payments.service';
import { SelectionPaymentProviderComponent } from '../selection-payment-provider/selection-payment-provider.component';
import { PaymentProviderTypes } from '../selection-payment-provider/selection-payment-provider.model';

@Component({
  selector: 'gk-payment-button',
  templateUrl: './payment-button.component.html',
  styleUrls: ['./payment-button.component.scss'],
  standalone: false,
})
export class PaymentButtonComponent implements OnDestroy, OnInit {
  private isAlive = true;
  @Input()
  invoices: Payment[] | Invoice[] = [];
  @Input()
  pathToAmount: string;
  @Input()
  portalName: string;
  @Input()
  invoiceIdFieldName = 'id';
  @Input()
  multiplePaymentProviders = true;
  @Input() documentSectionId: number | string;
  @Input() portalId: PortalId;
  @Input() buttonClass = 'btn btn-sm btn-success';
  @Input() buttonIconVisible = false;
  @Input() paymentInfoTooltipVisible = true;
  @Input() paymentWithoutCheckingLimit = false;
  @Input() paymentOwnerRequired = false;
  @Input() paymentInNewTab = false;
  @Input() paymentAmountType: PaymentAmountType;
  @Input() paymentSuccessSimpleInfo = false;
  @Input() abortPendingStatusOnRedirect = false;
  @Output()
  isPaymentInProgress: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  isPaymentBeginCalled: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public paymentService: PaymentsService,
    private ngbModal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.paymentService.isPaymentInProgress
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((isPaymentInProgress) => {
        this.isPaymentInProgress.emit(isPaymentInProgress);
      });
    this.emitEventIfPaymentBeginIsCalled();
  }

  emitEventIfPaymentBeginIsCalled(): void {
    this.paymentService.isPaymentBeginCalled
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((isPaymentBeginCalled) => {
        this.isPaymentBeginCalled.emit(isPaymentBeginCalled);
      });
  }

  pay(): void {
    const stampDutyPayment = StampDutyPaymentDto.fromAppToApi({
      documentSectionId: this.documentSectionId,
      portalId: this.portalId,
    });
    this.paymentService.pay({
      invoices: this.invoices,
      pathToAmount: this.pathToAmount,
      portalName: this.portalName,
      invoiceIdFieldName: this.invoiceIdFieldName,
      multiplePaymentProviders: this.multiplePaymentProviders,
      parent: this,
      openSelectedProviderWindow: this.selectPaymentProvider.bind(this),
      stampDutyPayment: isNotEmptyObject(stampDutyPayment)
        ? stampDutyPayment
        : undefined,
      paymentWithoutCheckingLimit: this.paymentWithoutCheckingLimit,
      paymentOwnerRequired: this.paymentOwnerRequired,
      paymentInNewTab: this.paymentInNewTab,
      paymentSuccessSimpleInfo: this.paymentSuccessSimpleInfo,
      paymentAmountType: this.paymentAmountType,
      abortPendingStatusOnRedirect: this.abortPendingStatusOnRedirect,
    });
  }

  async selectPaymentProvider(paymentProviders: string[]): Promise<string> {
    const modalRef = this.ngbModal.open(SelectionPaymentProviderComponent, {
      size: 'lg',
      centered: true,
    });

    modalRef.componentInstance.paymentProviders = paymentProviders;

    return new Promise<string>((resolve) => {
      modalRef.componentInstance.providerSelectedChange.subscribe(
        (provider: PaymentProviderTypes) => {
          resolve(provider);
          setTimeout(() => {
            modalRef.close();
          }, 1000);
        },
      );
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
