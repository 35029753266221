@if (!linkNavItemConfig.external) {
  <a
    class="btn"
    [id]="linkNavItemConfig.navItemId"
    [ngClass]="getCustomButtonClass()"
    [routerLink]="linkNavItemConfig.navigationRoute"
    [queryParams]="linkNavItemConfig.navigationQueryParams"
    [title]="linkNavItemConfig.linkLabel | translate"
    (click)="handleClick()"
    data-bs-toggle="collapse"
    data-bs-target=".navbar-collapse.show"
  >
    @if (linkNavItemConfig.showIcon && linkNavItemConfig.faIconName) {
      <span [ngClass]="getIconClassSet()"></span>
    }
    @if (linkNavItemConfig.showLabel) {
      <span>
        {{ linkNavItemConfig.linkLabel | translate }}
      </span>
    }
    @if (
      !linkNavItemConfig.showLabel && linkNavItemConfig.showLabelOnlyOnMobile
    ) {
      <span class="d-xl-none">
        {{ linkNavItemConfig.linkLabel | translate }}
      </span>
    }
  </a>
} @else {
  <a
    class="btn"
    [id]="linkNavItemConfig.navItemId"
    [ngClass]="getCustomButtonClass()"
    [href]="linkNavItemConfig.navigationRoute"
    [title]="linkNavItemConfig.linkLabel | translate"
    [target]="linkNavItemConfig.target"
    (click)="handleClick()"
  >
    @if (linkNavItemConfig.showIcon && linkNavItemConfig.faIconName) {
      <span [ngClass]="getIconClassSet()"></span>
    }
    @if (linkNavItemConfig.showLabel) {
      <span>
        {{ linkNavItemConfig.linkLabel | translate }}
      </span>
    }
    @if (
      !linkNavItemConfig.showLabel && linkNavItemConfig.showLabelOnlyOnMobile
    ) {
      <span class="d-xl-none">
        {{ linkNavItemConfig.linkLabel | translate }}
      </span>
    }
  </a>
}
