import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPersonAddress,
} from '@gk/gk-modules';
import {
  DocumentationFormType,
  ExtendedApplicantRequest,
  ExtendedApplicantRequestDto,
  ZamowienieDzialkaDto,
} from '../../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../../shared/shipping-form/shipping-form.model';

export interface SFMPNewRequestDto extends ExtendedApplicantRequestDto {
  Dzialka: ZamowienieDzialkaDto;
}

export class SFMPNewRequest extends ExtendedApplicantRequest {
  constructor(
    investorDetails: InvestorDetails,
    proxyDetails: InvestorDetails,
    public idNumber: number,
    public phoneNumber: number,
    public email: string,
    public address: LawPersonAddress,
    proxyDetailsCheckbox: boolean,
    public attachments: ApiNewDokumentPowiazanyDalDto,
    documentationForm: DocumentationFormType,
    public landParcel: ZamowienieDzialkaDto,
    shippingForm: Shipping,
  ) {
    super(
      investorDetails,
      documentationForm,
      shippingForm,
      undefined,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }
  static fromAppToApi(
    data: SFMPNewRequest,
    parcel: EgibObject,
    files: ApiNewDokumentPowiazanyDalDto[],
  ): SFMPNewRequestDto {
    return {
      ...ExtendedApplicantRequest.mapCommonFields({
        data,
        files,
      }),
      Dzialka: ZamowienieDzialkaDto.fromAppToApi(parcel),
    };
  }
}
