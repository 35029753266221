import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import {
  LBRCNNewDataChangesNotificationControlName,
  LBRCNNewDataChangesNotificationFormGroup,
} from './new-data-changes-notification-form.model';

@Injectable()
export class LBRCNNewDataChangesNotificationFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): FormGroup<LBRCNNewDataChangesNotificationFormGroup> {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [LBRCNNewDataChangesNotificationControlName.ProxyDetailsCheckbox]:
            false,
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [LBRCNNewDataChangesNotificationControlName.ChangeScope]: [
        '',
        [Validators.required],
      ],
      [LBRCNNewDataChangesNotificationControlName.DemonstrationOfApplicantRight]:
        [undefined, [Validators.required]],
      [LBRCNNewDataChangesNotificationControlName.DeterminationOfLocation]: [
        '',
      ],
    });
  }
}
