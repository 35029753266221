import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GeometryControl } from '../../../../../controls';
import { MapPolygon } from '../../../../../models/map-object.model';

@Component({
  selector: 'gk-polygon',
  templateUrl: './polygon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PolygonComponent extends GeometryControl {
  @Input() override geometry: MapPolygon;
}
