import { Component } from '@angular/core';
import * as _ from 'lodash';

import { DateHelperUtil } from '../../../utils/date-helper.util';
import { Control } from '../control';

@Component({
  selector: 'gk-control-date-time',
  templateUrl: './control-date-time.component.html',
  standalone: false,
})
export class ControlDateTimeComponent extends Control<string> {
  getUiDate(): string {
    return DateHelperUtil.apiToUiDate(
      _.get(this.data, this.controlConfig.pathToValue),
    );
  }
}
