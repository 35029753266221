import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  BaseStyleClassesDirective,
  DocumentSectionService,
  markFormGroupsTouched,
  PortalId,
} from '@gk/gk-modules';
import { distinctUntilChanged, Observable, of, takeWhile } from 'rxjs';
import { InvestorDetailsFormService } from '../../services/investor-details-form/investor-details-form.service';
import { InvestorDetailsComponent } from '../../shared/investor-details/investor-details.component';

@Component({
  selector: 'app-proxy-details',
  templateUrl: './proxy-details.component.html',
  standalone: false,
})
export class ProxyDetailsComponent
  extends BaseStyleClassesDirective
  implements OnInit, OnChanges, OnDestroy
{
  private isAlive = true;
  proxyDetailsVisible = false;
  proxyAttachmentsSelected = false;
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @Input() portalId: PortalId;
  @Input() formGroup: FormGroup;
  @Input() submitted: boolean;
  @Input() controlName: Record<'ProxyDetails' | 'ProxyDetailsCheckbox', string>;

  constructor(
    private investorDetailsFormService: InvestorDetailsFormService,
    public documentSectionService: DocumentSectionService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToProxyDetailsCheckboxFormControl();
    this.watchProxyAttachmentsAndPayment();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const submittedChange = changes['submitted'];
    if (submittedChange && submittedChange.currentValue) {
      const proxyDetailsFormGroup = this.getProxyDetailsFormGroup();
      if (!proxyDetailsFormGroup) {
        return;
      }
      markFormGroupsTouched(proxyDetailsFormGroup);
    }
  }

  watchProxyAttachmentsAndPayment(): void {
    const selected$ = this.documentSectionService.getProxyAttachmentsSelected(
      this.portalId,
    );
    selected$
      .pipe(
        takeWhile(() => this.isAlive),
        distinctUntilChanged(),
      )
      .subscribe((proxyAttachmentsSelected) => {
        this.proxyAttachmentsSelected = proxyAttachmentsSelected;
        this.getProxyDetailsCheckboxFormControl().setValue(
          proxyAttachmentsSelected,
        );
      });
  }

  subscribeToProxyDetailsCheckboxFormControl(): void {
    this.getProxyDetailsCheckboxFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((proxyDetailsChecked) => {
        this.toggleFormVisibilityByProxyDetailsChecked(proxyDetailsChecked);
      });
  }

  toggleFormVisibilityByProxyDetailsChecked(
    proxyDetailsChecked: boolean,
  ): void {
    const isProxyDetailsFormGroup = this.formGroup.contains(
      this.controlName.ProxyDetails,
    );
    if (proxyDetailsChecked && !isProxyDetailsFormGroup) {
      this.formGroup.addControl(
        this.controlName.ProxyDetails,
        this.investorDetailsFormService.getFormGroup(
          false,
          undefined,
          undefined,
          false,
        ),
      );
    } else if (isProxyDetailsFormGroup && !this.proxyAttachmentsSelected) {
      this.formGroup.removeControl(this.controlName.ProxyDetails);
    }
    this.setProxyDetailsVisibility(proxyDetailsChecked);
  }

  getProxyDetailsCheckboxFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.ProxyDetailsCheckbox,
    ) as UntypedFormControl;
  }

  isProxyDetailsInvalidAndValidated(): boolean {
    const proxyDetailsFormGroup = this.getProxyDetailsFormGroup();

    return (
      proxyDetailsFormGroup &&
      !proxyDetailsFormGroup.valid &&
      this.proxyDetailsVisible &&
      this.submitted
    );
  }

  getProxyDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      this.controlName.ProxyDetails,
    ) as UntypedFormGroup;
  }

  isProxyDetailsVisible(): boolean {
    const shouldBeVisible =
      this.proxyDetailsVisible ||
      (this.submitted && this.proxyAttachmentsSelected);

    if (shouldBeVisible && !this.proxyDetailsVisible) {
      this.setProxyDetailsVisibility(true);
      this.getProxyDetailsCheckboxFormControl().setValue(true);
    }

    return shouldBeVisible;
  }

  setProxyDetailsVisibility(visible: boolean): void {
    this.proxyDetailsVisible = visible;
  }

  askForPostOfficeWhenPostalCodeIsNotFromDictionary(
    customPostOfficeModalHeaderInfo?: string,
  ): Observable<boolean> {
    return this.investorDetailsComponent
      ? this.investorDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(
          customPostOfficeModalHeaderInfo,
        )
      : of(true);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
