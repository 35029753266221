import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiNewDokumentPowiazanyDalDto,
  AttachmentsComponent,
  auxiliaryBuildingToolState,
  ColumnHeader,
  DocSignService,
  EgibObject,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapAction,
  MapExtentUtils,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  MapViewActionType,
  markFormGroupsTouched,
  PortalId,
  PzService,
  ShapeUtils,
  StreetService,
  ToolType,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeWhile,
  tap,
  throwError,
} from 'rxjs';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import {
  BsMessageType,
  WorkspaceStateDraftVersion,
  WorkspaceStateId,
} from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import {
  SPPNewRequestControlName,
  SPPOwnershipForm,
  SPPParcelArea,
} from '../services/new-request-form/new-request-form.model';
import { SPPNewRequestFormService } from '../services/new-request-form/new-request-form.service';
import {
  SPPNewRequest,
  SPPNewRequestDto,
  SupplementaryParcelPurchaseRequestFormWorkspaceState,
} from '../services/new-request/new-request.model';

@Component({
  selector: 'app-new-supplementary-parcel-purchase-request',
  templateUrl: './new-supplementary-parcel-purchase-request.component.html',
  styleUrls: ['./new-supplementary-parcel-purchase-request.component.scss'],
  standalone: false,
})
export class NewSupplementaryParcelPurchaseRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  override readonly controlName = SPPNewRequestControlName;
  override portalId = PortalId.SupplementaryParcelPurchase;
  readonly mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('areaInSquareMeters', 'GK.MAP.AREA_IN_SQUARE_METERS'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
  );
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  successSigned = false;
  parcelArea = SPPParcelArea;
  ownershipForm = SPPOwnershipForm;
  workspaceStateDraftVersion =
    WorkspaceStateDraftVersion.SupplementaryParcelPurchaseRequestFormVersion;
  workspaceStateId = WorkspaceStateId.SupplementaryParcelPurchaseRequestForm;

  constructor(
    private sppNewRequestFormService: SPPNewRequestFormService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override streetService: StreetService,
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override translateService: TranslateService,
    private toastr: ToastrService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public override pzService: PzService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    this.fetchApplicantMapSettings();
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.SupplementaryParcelPurchase,
      this.mapStateService.getViewState(
        MapId.SupplementaryParcelPurchase,
        mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.AnyPolygon, ToolType.LandParcel, ToolType.Building],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      {
        ...this.initialToolsState,
        [ToolType.Building]: auxiliaryBuildingToolState,
      },
      this.mapStateService.getLayersState(
        MapId.SupplementaryParcelPurchase,
        mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  createForm(): void {
    this.formGroup = this.sppNewRequestFormService.getFormGroup();
  }

  isPlaceFormControlValid(): boolean {
    return this.getPlaceFormControl().valid;
  }

  areLandParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
    );
  }

  handleSubmit(): void {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup() as UntypedFormGroup);
    if (!this.isRequestValid()) {
      return;
    }

    this.sendRequest();
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areLandParcelsChosen()
    );
  }

  sendRequest(): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.requestReplyFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() => this.getRequestBody()),
        switchMap((body) =>
          this.docSignService.addToSign(
            body,
            '/api/interesant/wniosek/zakupDzialkaUzupelniajaca/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  getRequestBody = (): Observable<SPPNewRequestDto> => {
    return from(
      Promise.all(this.attachmentsComponent.getConvertedFiles()).catch(
        (error) => new Error(error),
      ),
    ).pipe(
      switchMap((apiFiles) => {
        const apiFilesArray = Object.values(apiFiles).flatMap((file) => file);
        if (
          (Array.isArray(apiFilesArray) &&
            apiFilesArray.some((file) => file instanceof Error)) ||
          apiFiles instanceof Error
        ) {
          this.errorSubmitMessage = this.wrongFilesErrorText;
          this.toastr.error(this.wrongFilesErrorText);

          return throwError(() => new Error(this.wrongFilesErrorText));
        }

        return of(apiFiles);
      }),
      map((apiFiles) =>
        SPPNewRequest.fromAppToApi(
          this.getFormValue(),
          this.getIssueRanges(),
          this.getLandParcels(),
          apiFiles as ApiNewDokumentPowiazanyDalDto[],
        ),
      ),
      catchError((err) => {
        console.error(err);

        return throwError(() => err);
      }),
    );
  };

  getFormValue(): SPPNewRequest {
    return this.formGroup.getRawValue();
  }

  getWorkspaceStateToSave =
    (): SupplementaryParcelPurchaseRequestFormWorkspaceState => ({
      formValue: this.getFormValue(),
      parcels: this.getLandParcels(),
      issueRanges: this.getIssueRanges(),
    });

  handleLoadWorkspaceResponse(
    state: SupplementaryParcelPurchaseRequestFormWorkspaceState,
  ): void {
    this.formGroup.patchValue(state.formValue);

    this.handleMapAction(
      new MapAction(MapObjectTableActionType.AddNew, [...state.parcels]),
    );
    if (state.issueRanges) {
      const mapObjects = state.issueRanges.map((workRange) =>
        ShapeUtils.getAnyGeometryMapObject(
          workRange,
          MapObjectApiType.ExtentOrPolygon,
        ),
      );
      this.handleMapAction(
        new MapAction(MapObjectTableActionType.AddToExisting, mapObjects),
      );
      this.handleMapAction(
        new MapAction(
          MapViewActionType.ExtentToFitToChange,
          MapExtentUtils.getMapExtentFromMapObjects(mapObjects),
        ),
      );
      this.handleMapAction(
        new MapAction(MapObjectTableActionType.SelectRange, mapObjects),
      );
    }
  }

  getIssueRanges(): string[] {
    return [...this.mapState.mapObjectTablesState[0].mapObjects]
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .map((issueRange) => issueRange.geom);
  }

  getLandParcels(): EgibObject[] {
    return [...this.mapState.mapObjectTablesState[0].mapObjects].filter(
      <(mapObject: MapObject) => mapObject is EgibObject>(
        ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
      ),
    );
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl('/supplementary-parcel-purchase/requests-list');
  }

  isReasonFormControlValid(): boolean {
    return this.getReasonFormControl().valid;
  }

  getReasonFormControl(): AbstractControl<string> {
    return this.formGroup.get(this.controlName.Reason);
  }

  isParcelAreaFormControlValid(): boolean {
    return this.getParcelAreaFormControl().valid;
  }

  getParcelAreaFormControl(): AbstractControl<string> {
    return this.formGroup.get(this.controlName.ParcelArea);
  }

  isOwnershipFormFormControlValid(): boolean {
    return this.getOwnershipFormFormControl().valid;
  }

  getOwnershipFormFormControl(): AbstractControl<string> {
    return this.formGroup.get(this.controlName.OwnershipForm);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
