<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <app-workspace-state
      [workspaceStateDraftVersion]="workspaceStateDraftVersion"
      [workspaceStateId]="workspaceStateId"
      [getWorkspaceStateToSave]="getWorkspaceStateToSave"
      [buttonClass]="'btn-outline-primary'"
      (workspaceStateLoaded)="handleLoadWorkspaceResponse($event)"
    ></app-workspace-state>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.CLIENT_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isChangeScopeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.ChangeScope">
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.CHANGE_SCOPE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.ChangeScope"
            [id]="controlName.ChangeScope"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.ChangeScope"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getChangeScopeFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areMapObjectsChosen()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.LOCATION'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>
        @if (submitted && !areMapObjectsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.MAP_OBJECTS_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.DeterminationOfLocation">
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.DETERMINATION_OF_LOCATION'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.DeterminationOfLocation"
            [id]="controlName.DeterminationOfLocation"
            class="form-control"
            [formControlName]="controlName.DeterminationOfLocation"
          ></textarea>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isDemonstrationOfApplicantRightFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label
            class="mb-0"
            [attr.for]="controlName.DemonstrationOfApplicantRight"
          >
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.DEMONSTRATION_OF_APPLICANT_RIGHT'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="d-flex row-select">
            <select
              [attr.id]="controlName.DemonstrationOfApplicantRight"
              [title]="'NEW_DESIGNER_REQUEST_FORM.PERSON_TYPE' | translate"
              [name]="controlName.DemonstrationOfApplicantRight"
              [formControlName]="controlName.DemonstrationOfApplicantRight"
              class="form-select form-select-sm required"
              aria-required="true"
            >
              @for (
                field of demonstrationOfApplicantRightService.getDictionary()
                  | async;
                track field.id
              ) {
                <option [ngValue]="field.id">
                  {{ field.name }}
                </option>
              }
            </select>
          </div>
        </div>

        @if (
          shouldShowRequiredAlert(getDemonstrationOfApplicantRightFormControl())
        ) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <gk-attachments [portalId]="portalId" [submitted]="submitted">
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section>
      <button type="submit" class="btn btn-primary">
        {{
          'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION.SUBMIT'
            | translate
        }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
