import { Directive, OnDestroy } from '@angular/core';
import {
  ButtonStateControl,
  CommunicatorListItem,
  ListConfigs,
  ModalCommunicatorComponent,
  ModalUtils,
  WebPortal,
} from '@gk/gk-modules';
import { Observable } from 'rxjs';
import { filter, switchMap, takeWhile } from 'rxjs/operators';
import { AppTitleService } from '../../services/app-title/app-title.service';
import { WebPortalService } from '../../services/web-portal/web-portal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Directive()
export abstract class BaseRequestsListDirective implements OnDestroy {
  isAlive = true;
  listConfigs: ListConfigs;
  dynamicListItem: any;
  communicatorModalRef: any;

  constructor(
    public appTitleService: AppTitleService,
    public webPortalService: WebPortalService,
    public modalService: NgbModal,
  ) {}

  getCurrentPortalType(): Observable<WebPortal> {
    return this.appTitleService.currentPortalRoute.pipe(
      takeWhile(() => this.isAlive),
      filter((portalRoute) => !!portalRoute),
      switchMap((portalRoute) =>
        this.webPortalService.getWebPortalDataByCurrentRoute(portalRoute),
      ),
    );
  }

  handleOpenCommunicator(
    dynamicListItem: CommunicatorListItem,
    buttonStateControl: ButtonStateControl,
  ): void {
    this.dynamicListItem = dynamicListItem;
    buttonStateControl.activeLoadingSpinner();
    this.openCommunicatorModal(dynamicListItem);
    buttonStateControl.stopLoadingSpinner();
  }

  openCommunicatorModal(dynamicListItem: CommunicatorListItem): void {
    const buttonElement = document.activeElement as HTMLElement;
    buttonElement.blur();
    this.communicatorModalRef = this.modalService.open(
      ModalCommunicatorComponent,
      ModalUtils.getModalOptions(),
    ).componentInstance;
    this.communicatorModalRef.listItem = dynamicListItem;
    this.communicatorModalRef.controlConfigs =
      this.listConfigs.communicatorControlConfig;
  }

  handleReadNewMessage(dynamicListItem: any, refreshCallback: () => any): void {
    this.dynamicListItem = dynamicListItem;
    this.readNewMessage(dynamicListItem, refreshCallback);
  }

  readNewMessage(dynamicListItem: any, refreshCallback: () => any): void {
    this.communicatorModalRef = this.modalService.open(
      ModalCommunicatorComponent,
      ModalUtils.getModalOptions(),
    ).componentInstance;
    this.communicatorModalRef.listItem = dynamicListItem[0];
    this.communicatorModalRef.controlConfigs =
      this.listConfigs.communicatorControlConfig;
    this.communicatorModalRef.refreshCallback = refreshCallback;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
