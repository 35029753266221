import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TfaService } from '../../../gk-user-settings/services/tfa/tfa.service';
import { TfaTotpInitializationCredentialsService } from '../services/tfa-totp-initialization-credentials/tfa-totp-initialization-credentials.service';
import { TfaWebAuthnInitializationCredentialsService } from '../services/tfa-web-authn-initialization-credentials/tfa-web-authn-initialization-credentials.service';

@Component({
  selector: 'gk-tfa-reset-code',
  templateUrl: './tfa-reset-code.component.html',
  styleUrls: ['./tfa-reset-code.component.scss'],
  standalone: false,
})
export class TfaResetCodeComponent {
  constructor(
    public modal: NgbActiveModal,
    public tfaService: TfaService,
    public tfaTotpInitializationCredentialsService: TfaTotpInitializationCredentialsService,
    public tfaWebAuthnInitializationCredentialsService: TfaWebAuthnInitializationCredentialsService,
  ) {}
}
