import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ControlConfig } from '@gk/gk-modules';
import {
  combineLatest,
  forkJoin,
  Observable,
  switchMap,
  takeWhile,
} from 'rxjs';
import { ConfigManagerService } from '../../../services/config-manager/config-manager.service';
import { EcoDocumentSearchService } from '../../../services/eco-document-search/eco-document-search.service';
import {
  EcoDocumentHistory,
  EcoDocumentRouteParams,
} from '../../../services/eco-document/eco-document.model';
import { DateHelperUtil } from '../../../utils/date-helper/date-helper.util';

@Component({
  selector: 'app-eco-document-details',
  templateUrl: './eco-document-details.component.html',
  standalone: false,
})
export class EcoDocumentDetailsComponent implements OnInit, OnDestroy {
  private isAlive = true;
  detailsConfigs: ControlConfig[];
  loading = false;
  fetchedDetails: any = {};
  fetchedHistory: EcoDocumentHistory[] = [];
  routeParams: ParamMap;
  queryParams: ParamMap;

  constructor(
    private route: ActivatedRoute,
    private ecoDocumentSearch: EcoDocumentSearchService,
    private configManagerService: ConfigManagerService,
  ) {}

  ngOnInit(): void {
    this.fetchDetailsConfigs();
    this.fetchDocumentDetails();
  }

  fetchDetailsConfigs(): void {
    this.detailsConfigs =
      this.configManagerService.getConfigForEcoPortalDocumentDetails();
  }

  fetchDocumentDetails(): void {
    this.loading = true;
    combineLatest([this.route.paramMap, this.route.queryParamMap])
      .pipe(
        switchMap(([params, queryParams]) => {
          this.loading = true;
          this.fetchedDetails = {};
          this.routeParams = params;
          this.queryParams = queryParams;
          return forkJoin(this.getEcoDocumentRequestsArray());
        }),
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([details, history]) => {
        this.fetchedDetails = details;
        if (history) {
          this.fetchedHistory = history;
        }
        this.loading = false;
      });
  }

  getEcoDocumentRequestsArray(): Observable<any>[] {
    const idParam = this.routeParams.get(EcoDocumentRouteParams.Id);
    const versionParam = this.queryParams.get(EcoDocumentRouteParams.Version);
    let requestsArray = [
      this.ecoDocumentSearch.getEcoDocumentDetails(idParam, versionParam),
    ];
    if (!this.isDocumentHistoryFetched()) {
      requestsArray = [
        ...requestsArray,
        this.ecoDocumentSearch.getEcoDocumentHistory(idParam),
      ];
    }

    return requestsArray;
  }

  isDocumentHistoryFetched(): boolean {
    return !!(this.fetchedHistory && this.fetchedHistory.length);
  }

  getUiDate(apiDate: string | null): string {
    return apiDate ? DateHelperUtil.apiToUiDateTime(apiDate) : '-';
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
