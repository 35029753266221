import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'gk-translated-label',
  templateUrl: './translated-label.component.html',
  styleUrls: ['./translated-label.component.scss'],
  standalone: false,
})
export class TranslatedLabelComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input() configLabel = '';
  translatedLabel = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateLabel();
  }

  getConfigLabel(): string {
    if (!this.configLabel) {
      throw new Error(
        "Property label for control was not properly set in configuration! Label can't be an empty string.",
      );
    }

    return this.configLabel;
  }

  translateLabel(): void {
    this.translateService
      .get(this.getConfigLabel())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => (this.translatedLabel = text));
  }

  getLabel(): string {
    return this.translatedLabel || this.configLabel;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
