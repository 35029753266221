import { ConversionUtils, GeomUtils, ShapeUtils } from '../../gk-map/utils';
import { Polygon } from 'ol/geom';

import { EgibObject } from '../../gk-map/models';

export class MapRangePickerGridItem {
  constructor(
    public type: RangePickerFeatureType,
    public uuid: number | string,
    public label: string,
    public geom: string,
    public communityId?: number,
    public districtId?: number,
    public isArchival?: boolean,
  ) {}

  static getLabelForAreaFeature(wkt: string): string {
    const geom = ConversionUtils.getGeometryFromWkt(wkt);
    const area = ShapeUtils.getArea(geom as Polygon);
    const coordinates = GeomUtils.getPolygonGeometryCoordinatesCount(geom);
    return `Obszar o pow. ${area} ha, ${coordinates} wierzch.`;
  }

  static landParcelFromApiToApp(
    dto: SimpleDzialkaResponseDto,
  ): MapRangePickerGridItem {
    return new this(
      RangePickerFeatureType.LandParcel,
      dto.Uuid,
      `Działka ${dto.Numer}, obręb ${dto.ObrebNazwa}`,
      dto.Geom,
      dto.JednostkaEwidencyjnaId,
      dto.ObrebId,
    );
  }

  static fromWktToRange(wkt: string): MapRangePickerGridItem {
    return new this(
      RangePickerFeatureType.Range,
      'range',
      this.getLabelForAreaFeature(wkt),
      wkt,
    );
  }

  static fromEgibObjectToApp(egibObject: EgibObject): MapRangePickerGridItem {
    return new this(
      RangePickerFeatureType.LandParcel,
      egibObject.uuid,
      `Działka ${egibObject.mapObjectNumber}, obręb ${egibObject.districtName}`,
      egibObject.geom,
      egibObject.communityId,
      egibObject.districtId,
    );
  }
}

export enum RangePickerFeatureType {
  Range = 'range',
  LandParcel = 'landParcel',
}

export class SimpleDzialkaResponseDto {
  Uuid: string;
  Id: number;
  Arowa: boolean;
  Numer: string;
  ArkuszId: number | null;
  ArkuszNazwa: string;
  JednostkaRejestrowaId: number | null;
  JednostkaRejestrowaNazwa: string;
  ObrebId: number;
  ObrebNazwa: string;
  GrrejId: number;
  IsArchival: boolean;
  Adres: string;
  JednostkaEwidencyjnaId: number;
  JednostkaEwidencyjnaNazwa: string;
  Geom: string;
  NrKw: string;
  Klasouzytki: string[];
  KlasouzytkiPowierzchniaText: string[];
  InneDokumenty: string;
  PowierzchniaEwidText: string;
}

export class SimpleLandParcelResponse {
  constructor(
    public uuid: string,
    public id: number,
    public arowa: boolean,
    public number: string,
    public sheetId: number | null,
    public sheetName: string,
    public registerUnitId: number | null,
    public registerUnitName: string,
    public circuitId: number,
    public circuitName: string,
    public grrejId: number,
    public isArchival: boolean,
    public address: string,
    public inventoryUnitId: number,
    public inventoryUnitName: string,
    public geom: string,
    public mortgageNumber: string,
    public landUserTypes: string[],
    public landUserAreaText: string[],
    public otherDocuments: string,
    public inventoryAreaText: string,
  ) {}

  static fromAppToApi(dto: SimpleDzialkaResponseDto): SimpleLandParcelResponse {
    return new this(
      dto.Uuid,
      dto.Id,
      dto.Arowa,
      dto.Numer,
      dto.ArkuszId,
      dto.ArkuszNazwa,
      dto.JednostkaRejestrowaId,
      dto.JednostkaRejestrowaNazwa,
      dto.ObrebId,
      dto.ObrebNazwa,
      dto.GrrejId,
      dto.IsArchival,
      dto.Adres,
      dto.JednostkaEwidencyjnaId,
      dto.JednostkaEwidencyjnaNazwa,
      dto.Geom,
      dto.NrKw,
      dto.Klasouzytki,
      dto.KlasouzytkiPowierzchniaText,
      dto.InneDokumenty,
      dto.PowierzchniaEwidText,
    );
  }
}

export interface PracaLocationResponseDto {
  PracaNotInObszarMiasta: boolean;
  PracaPartiallyInObszarMiasta: boolean;
}

export interface MapRangePickerGridFormControlValue {
  data: MapRangePickerGridItem[];
  selection: MapRangePickerGridItem[];
}
