import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { BusinessPurposesCheckboxControlComponent } from './business-purposes-checkbox-control/business-purposes-checkbox-control.component';
import { OCFCRNewRequestComponent } from './new-request/new-request.component';
import { OwnershipConversionFeeCalculationRequestRoutingModule } from './ownership-conversion-fee-calculation-request-routing.module';
import { OwnershipConversionFeeCalculationRequestComponent } from './ownership-conversion-fee-calculation-request.component';
import { OCFCRNewRequestFormService } from './services/new-request-form/new-request-form.service';

@NgModule({
  declarations: [
    OwnershipConversionFeeCalculationRequestComponent,
    OCFCRNewRequestComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    OwnershipConversionFeeCalculationRequestRoutingModule,
    BusinessPurposesCheckboxControlComponent,
  ],
  providers: [OCFCRNewRequestFormService],
})
export class OwnershipConversionFeeCalculationRequestModule {}
