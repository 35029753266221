import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddedDocToSignResponse,
  AddedDocToSignResponseFromApi,
  ApiNewDokumentPowiazanyDalDto,
  LawPersonSearch,
  LawPersonService,
  isLegalPerson,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodes } from 'http-status-codes';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatest, map, of, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DesignerSettingsService } from '../designer-settings/designer-settings.service';
import { SettingsService } from '../settings/settings.service';
import {
  ApiLawPerson,
  ApiNewZudObrebyDalDto,
  ApiNewZudSprawaWniosekDalDto,
  NewDesignerRequestFormModel,
  SieciPrzylaczaApi,
} from './new-designer-request.model';

@Injectable()
export class NewDesignerRequestService {
  constructor(
    private httpClient: HttpClient,
    private designerSettingsService: DesignerSettingsService,
    private lawPersonService: LawPersonService,
    private hubSettingsService: SettingsService,
    private translateService: TranslateService,
    private toastr: ToastrService,
  ) {}

  createNewDesignerRequestApiBody(
    formValue: NewDesignerRequestFormModel,
    apiDocuments: ApiNewDokumentPowiazanyDalDto[],
  ): ApiNewZudSprawaWniosekDalDto {
    return new ApiNewZudSprawaWniosekDalDto(
      formValue.lawPersons.map((lp) => ApiLawPerson.fromApp(lp)),
      formValue.typedAddress,
      formValue.chosenParcels.map(
        (chosenParcel) =>
          new ApiNewZudObrebyDalDto(
            chosenParcel.parcelId,
            chosenParcel.districtId,
          ),
      ),
      formValue.connectionsAndNetworks.map(
        (connectionAndNetwork) =>
          new SieciPrzylaczaApi(
            _.isString(connectionAndNetwork.connectionAndNetwork.id)
              ? parseInt(
                  connectionAndNetwork.connectionAndNetwork.id as string,
                  10,
                )
              : connectionAndNetwork.connectionAndNetwork.id,
            connectionAndNetwork.quantity,
          ),
      ),
      formValue.issueRanges.map((issueRange) => issueRange.geom),
      apiDocuments,
      formValue.councilSubject,
      formValue.emailingConsent,
    );
  }

  validateDtoAndAddToSignOrDirectly(
    formValue: NewDesignerRequestFormModel,
    apiDocuments: ApiNewDokumentPowiazanyDalDto[],
  ): Observable<AddedDocToSignResponse> {
    return combineLatest([
      this.getDesignerRequestUrl(),
      this.getDesignerRequestStringifiedBody(formValue, apiDocuments),
    ]).pipe(
      switchMap(([url, body]) =>
        this.httpClient.post<AddedDocToSignResponseFromApi>(url, body, {
          observe: 'response',
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }),
      ),
      map((addedDocToSignResponseFromApi) => {
        if (addedDocToSignResponseFromApi.status === StatusCodes.NO_CONTENT) {
          return AddedDocToSignResponse.directlyAddedRequest();
        } else {
          return AddedDocToSignResponse.fromApiToApp(
            addedDocToSignResponseFromApi.body,
          );
        }
      }),
    );
  }

  addExternalPersonsToDbOrPrepareStringDataAndGet(
    lawPersons: LawPersonSearch[],
  ): Observable<LawPersonSearch[]> {
    return zip(
      ...lawPersons.map((lawPerson) => {
        if (isLegalPerson(lawPerson) && !lawPerson.id) {
          return this.hubSettingsService.getHubSettings().pipe(
            switchMap((settings) =>
              this.lawPersonService
                .addLegalPerson(
                  lawPerson.data,
                  settings.addingPersonsDirectlyToDb,
                  '/api/zudp/projektant/petent',
                  true,
                )
                .pipe(
                  map((legalPerson) => ({
                    ...legalPerson,
                    personType: lawPerson.personType,
                  })),
                ),
            ),
          );
        } else {
          return of(lawPerson);
        }
      }),
    );
  }

  getDesignerRequestStringifiedBody(
    formValue: NewDesignerRequestFormModel,
    apiDocuments: ApiNewDokumentPowiazanyDalDto[],
  ): Observable<string> {
    return this.addExternalPersonsToDbOrPrepareStringDataAndGet(
      formValue.lawPersons,
    ).pipe(
      switchMap(
        (lawPersons) =>
          new Observable<string>((observer) => {
            let stringifiedBody: string;
            try {
              stringifiedBody = JSON.stringify(
                this.createNewDesignerRequestApiBody(
                  { ...formValue, lawPersons },
                  apiDocuments,
                ),
              );
            } catch (error) {
              console.error(error);
              this.showFilesProcessingError();
              observer.error(error);
              observer.complete();
            }

            observer.next(stringifiedBody);
            observer.complete();
          }),
      ),
    );
  }

  showFilesProcessingError(): void {
    this.translateService.get('PROCESSING_FILES_ERROR').subscribe((data) => {
      this.toastr.error(data);
    });
  }

  getDesignerRequestUrl(): Observable<string> {
    return this.designerSettingsService
      .isPzSessionOrPzSignRequired()
      .pipe(
        map((isPzSessionOrPzSignRequired) =>
          isPzSessionOrPzSignRequired
            ? '/api/zudp/projektant/wniosek/addToSign'
            : '/api/zudp/projektant/wniosek',
        ),
      );
  }

  getResponseStatusMessage(error: string): string {
    return _.get(error, 'error.ResponseStatus.Message', '');
  }
}
