@if (shippingTypes) {
  <form [formGroup]="shippingFormGroup">
    @for (shippingType of shippingTypes; track shippingType) {
      <div class="form-check form-check-inline mb-2">
        <input
          type="radio"
          id="shipping-type-{{ shippingType.id }}"
          [attr.name]="shippingFormControlName.ShippingForm"
          [formControlName]="shippingFormControlName.ShippingForm"
          [value]="shippingType.id"
          class="form-check-input"
        />
        <label
          class="form-check-label"
          for="shipping-type-{{ shippingType.id }}"
        >
          {{ shippingType.name }}</label
        >
      </div>
    }
    @if (shippingFormType?.postalDataRequired) {
      <div class="form-check my-3">
        <input
          type="checkbox"
          class="form-check-input"
          [id]="shippingFormControlName.IsOtherAddress"
          [formControlName]="shippingFormControlName.IsOtherAddress"
        />
        <label
          class="form-check-label fw-bold"
          [attr.for]="shippingFormControlName.IsOtherAddress"
          >{{ 'SHIPPING_FORM.OTHER_ADDRESS' | translate }}</label
        >
      </div>
    }
    @if (shippingFormType?.postalDataRequired) {
      <div class="form-group">
        <label [attr.for]="shippingFormControlName.NameSurname">{{
          'SHIPPING_FORM.FIRSTNAME_LASTNAME' | translate
        }}</label>
        <input
          type="text"
          class="form-control form-control-sm required"
          [id]="shippingFormControlName.NameSurname"
          [formControlName]="shippingFormControlName.NameSurname"
          aria-required="true"
        />
      </div>
    }
    @if (shippingFormType?.emailRequired) {
      <div class="form-group">
        <label class="text-nowrap" [attr.for]="shippingFormControlName.Email">{{
          'SHIPPING_FORM.EMAIL' | translate
        }}</label>
        <input
          type="text"
          [name]="shippingFormControlName.Email"
          [id]="shippingFormControlName.Email"
          class="form-control form-control-sm required"
          [formControlName]="shippingFormControlName.Email"
          aria-required="true"
        />
      </div>
    }
    @if (shippingFormType?.ftpAddressRequired) {
      <div class="form-group">
        <label
          class="text-nowrap"
          [attr.for]="shippingFormControlName.FtpAddress"
          >{{ 'SHIPPING_FORM.FTP_ADRESS' | translate }}</label
        >
        <input
          type="text"
          [name]="shippingFormControlName.FtpAddress"
          [id]="shippingFormControlName.FtpAddress"
          class="form-control form-control-sm required"
          [formControlName]="shippingFormControlName.FtpAddress"
          aria-required="true"
        />
      </div>
    }
  </form>
}
@if (shippingTypes && shippingFormType?.postalDataRequired) {
  <gk-address-form
    [isRequired]="true"
    [form]="getAddressFormGroup()"
    [primitivePostalCode]="primitivePostalCode"
    [wasFormValidated]="wasFormValidated"
  ></gk-address-form>
}

<gk-post-office-modal
  [addressFormGroup]="getAddressFormGroup()"
  [idPrefix]="idPrefix"
>
</gk-post-office-modal>
