import {
  BaseNewRequestControlName,
  LocationNewRequestControlName,
} from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum CNNewRequestAdditionalControlName {
  ConstructionWorksCommencementDate = 'constructionWorksCommencementDate',
  Description = 'description',
  BuildingNameAndType = 'buildingNameAndType',
  ConstructionIntention = 'constructionIntention',
}

export const CNNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...LocationNewRequestControlName,
  ...CNNewRequestAdditionalControlName,
};

export enum ConstructionNotificationType {
  ConstructionIntention = 0,
  ConstructionWorksExecution = 1,
}
