import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestControlName } from '../../../../shared/base-new-request-form/base-new-request-form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';

@Injectable({
  providedIn: 'root',
})
export class ANRNewRequestFormService {
  constructor(private baseNewRequestFormService: BaseNewRequestFormService) {}

  getFormGroup(): UntypedFormGroup {
    return this.baseNewRequestFormService.getCommonApplicantFormGroup({
      [BaseNewRequestControlName.LawPerson]: false,
      [BaseNewRequestControlName.ProxyDetailsCheckbox]: false,
      [BaseNewRequestControlName.ContactData]: false,
      [ECSControlName.ElectronicCorrespondenceStatement]: false,
    });
  }
}
