import { FormControl } from '@angular/forms';
import { FieldId } from '@gk/gk-modules';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum GACSDRNewRequestAdditionalControlName {
  WorkSignature = 'workSignature',
  WorkPurpose = 'workPurpose',
  Materials = 'materials',
  DataRange = 'dataRange',
  AdditionalInfo = 'additionalInfo',
}

export const GACSDRNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...GACSDRNewRequestAdditionalControlName,
};

export interface GACSDRNewRequestFormGroup
  extends LawPersonNewRequestFormGroup {
  [GACSDRNewRequestControlName.WorkSignature]: FormControl<string>;
  [GACSDRNewRequestControlName.WorkPurpose]: FormControl<FieldId[]>;
  [GACSDRNewRequestControlName.Materials]: FormControl<string>;
  [GACSDRNewRequestControlName.DataRange]: FormControl<string>;
  [GACSDRNewRequestControlName.AdditionalInfo]: FormControl<string>;
}
