import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import {
  PropertyUsageControlName,
  PropertyUsageFormGroup,
} from './business-purposes-checkbox-control-form.model';

@Injectable({
  providedIn: 'root',
})
export class PropertyUsageFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(): FormGroup<PropertyUsageFormGroup> {
    return this.formBuilder.group({
      [PropertyUsageControlName.BusinessUsage]: [getDefaultControlValue()],
      [PropertyUsageControlName.BusinessUsageFull]: [true],
      [PropertyUsageControlName.BusinessUsageShare]: [getDefaultControlValue()],
    });
  }
}
