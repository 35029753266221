import { Component, Input, OnInit } from '@angular/core';
import { GkKendoMessagesListGridDto } from './gk-kendo-messages-list-grid-data-model';
import {
  getGkKendoMessagesListToolBarItems,
  gkKendoMessagesListGridColumns,
} from './gk-kendo-messages-list-grid-model';
import { GkKendoGridComponent } from '../gk-kendo-grid.component';
import { takeWhile } from 'rxjs/operators';
import { filter } from 'rxjs';
import { GkKendoMessagesListGridDataService } from './gk-kendo-messages-list-grid-data.service';
import { GenericGridToolbarItemMessagesListPayload } from '../gk-kendo-grid.model';

@Component({
  selector: 'gk-kendo-messages-list-grid',
  templateUrl: '../gk-kendo-grid.component.html',
  standalone: false,
})
export class GkKendoMessagesListGridComponent
  extends GkKendoGridComponent<GkKendoMessagesListGridDto>
  implements OnInit
{
  @Input() payload: GenericGridToolbarItemMessagesListPayload;
  override autoBind = true;
  override columns = gkKendoMessagesListGridColumns;
  override bottomGridToolbarItems = getGkKendoMessagesListToolBarItems(this);
  selectedNewMessage: GkKendoMessagesListGridDto;
  communicatorWindowTitleTranslation: string;

  constructor(
    gkKendoMessagesListGridDataService: GkKendoMessagesListGridDataService,
  ) {
    super(gkKendoMessagesListGridDataService);
  }

  ngOnInit(): void {
    this.setProperUrlForGridService();
    this.handleNewMessageGridSelected();
    this.subscribeToCommunicatorWindowTitleTranslation();
  }

  setProperUrlForGridService(): void {
    this.gkKendoMessagesListGridDataService.url = this.payload.newMessagesUrl;
  }

  subscribeToCommunicatorWindowTitleTranslation(): void {
    this.translateService
      .get('GK.DYNAMIC_LIST.COMMUNICATOR.WINDOW_TITLE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) =>
          (this.communicatorWindowTitleTranslation = translation),
      );
  }

  handleNewMessageGridSelected(): void {
    this.gkKendoMessagesListGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedNewMessage = selection.selectedItems[0];
      });
  }
}
