import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import {
  SCOLNewCarryOutRequestControlName,
  SCOLNewCarryOutRequestFormGroup,
} from './new-carry-out-request-form.model';

@Injectable()
export class SCOLNewCarryOutRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): FormGroup<SCOLNewCarryOutRequestFormGroup> {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [SCOLNewCarryOutRequestControlName.ProxyDetailsCheckbox]: false,
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [SCOLNewCarryOutRequestControlName.Reason]: ['', [Validators.required]],
      [SCOLNewCarryOutRequestControlName.DemonstrationOfApplicantRight]: [
        undefined,
        [Validators.required],
      ],
      [SCOLNewCarryOutRequestControlName.EnableWorkByPerson]: [
        '',
        [Validators.required],
      ],
    });
  }
}
