export enum UploadingState {
  Waiting = 0,
  Uploading = 1,
  Failed = 2,
  Success = 3,
}

export interface FileWithCustomUploading extends File {
  uploadingState?: UploadingState;
  docType?: number;
  description?: string;
  progress?: number;
  uuid?: string;
}

export class ApiNewDokumentPowiazanyDalDto {
  constructor(
    public NazwaPliku: string,
    public Plik: string,
    public RodzajOpracowaniaDokumentu: number | string,
    public Opis: string,
    public Owner?: string,
    public OwnerId?: number,
  ) {}
}

export interface ApiIncomingDocument {
  Id: number;
  DokId: number;
  Uuid: string;
  ObiektNadrzedny?: any;
  Nazwa: string;
  Rozmiar: string;
  NazdokId?: number;
  IdRodzDok?: any;
  Rodzaj: string;
  CelPracy?: any;
  Opis: string;
  DataD: string;
  DataK?: string;
  Status?: any;
  DodanyPrzez: string;
  Zakres: boolean;
  Wl: string;
  IdGrupy: number;
  ZalecanyDoPracyPrawnej?: boolean;
  ZalecanyDoPracyNieprawnej?: boolean;
  Token?: any;
}

export class IncomingDocument {
  constructor(
    public fileId: number,
    public docId: number,
    public name: string,
    public size: string,
    public type: string,
    public description: string,
    public addedDate: string,
  ) {}

  static fromApiToApp = (
    apiIncDocument: ApiIncomingDocument,
  ): IncomingDocument =>
    new IncomingDocument(
      apiIncDocument.Id,
      apiIncDocument.DokId,
      apiIncDocument.Nazwa,
      apiIncDocument.Rozmiar,
      apiIncDocument.Rodzaj,
      apiIncDocument.Opis,
      apiIncDocument.DataD,
    );
}
