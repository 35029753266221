import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  PropertyUsageControlName,
  PropertyUsageFormGroup,
} from '../services/business-purposes-checkbox-control-form/business-purposes-checkbox-control-form.model';

@Component({
  selector: 'app-business-purposes-checkbox-control',
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './business-purposes-checkbox-control.component.html',
  styleUrl: './business-purposes-checkbox-control.component.scss',
})
export class BusinessPurposesCheckboxControlComponent {
  @Input() formGroup: FormGroup<PropertyUsageFormGroup>;
  controlName = PropertyUsageControlName;

  isOtherInformationsGroupValid(): boolean {
    const propertyUsageFormGroup = this.formGroup;
    return (
      propertyUsageFormGroup.valid &&
      (propertyUsageFormGroup.get(PropertyUsageControlName.BusinessUsage)?.value
        ? propertyUsageFormGroup.get(PropertyUsageControlName.BusinessUsageFull)
            ?.value
          ? true
          : !!propertyUsageFormGroup.get(
              PropertyUsageControlName.BusinessUsageShare,
            )?.value
        : true)
    );
  }
}
