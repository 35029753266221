import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  filter,
  finalize,
  map,
  Observable,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { TfaType } from '../../../gk-user-settings/services/tfa/tfa.model';
import { TfaWebAuthnInitializationService } from '../services/tfa-web-authn-initialization/tfa-web-authn-initialization.service';
import {
  TfaWebAuthnUserCredential,
  TfaWebAuthnUserCredentials,
} from '../services/tfa-web-authn-user-credentials/tfa-web-authn-user-credentials.model';
import { TfaWebAuthnUserCredentialsService } from '../services/tfa-web-authn-user-credentials/tfa-web-authn-user-credentials.service';
import { TfaService } from './../../../gk-user-settings/services/tfa/tfa.service';

@Component({
  selector: 'gk-web-authn-settings',
  templateUrl: './web-authn-settings.component.html',
  styleUrls: ['./web-authn-settings.component.scss'],
  standalone: false,
})
export class WebAuthnSettingsComponent implements OnInit, OnDestroy {
  private isAlive = true;
  private deletionInProgressState: string[] = [];
  private deletionInProgress = new BehaviorSubject<string[]>([]);
  constructor(
    private modal: NgbActiveModal,
    public tfaWebAuthnUserCredentialsService: TfaWebAuthnUserCredentialsService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private tfaWebAuthnInitializationService: TfaWebAuthnInitializationService,
    public tfaService: TfaService,
  ) {}

  ngOnInit(): void {
    this.fetchCurrentCredentials().subscribe();
  }

  close(): void {
    this.modal.close();
  }

  deleteCredential(credential: TfaWebAuthnUserCredential): void {
    this.emitDeleteInProgress(credential.id);
    this.tfaWebAuthnUserCredentialsService
      .delete(credential.id)
      .pipe(
        takeWhile(() => this.isAlive),
        switchMap(() => this.fetchCurrentCredentials()),
        finalize(() => this.removeDeleteInProgress(credential.id)),
      )
      .subscribe({
        next: () => {
          this.showRemovedCredentialToastr();
        },
      });
  }

  emitDeleteInProgress(credentialId: string): void {
    this.deletionInProgressState = [
      ...this.deletionInProgressState,
      credentialId,
    ];
    this.deletionInProgress.next(this.deletionInProgressState);
  }

  removeDeleteInProgress(credentialId: string): void {
    this.deletionInProgressState = this.deletionInProgressState.filter(
      (item) => item !== credentialId,
    );

    this.deletionInProgress.next(this.deletionInProgressState);
  }

  isDeletionInProgress(credentialId: string): Observable<boolean> {
    return this.deletionInProgress.pipe(
      map((credentialsInProgressIds) =>
        credentialsInProgressIds.includes(credentialId),
      ),
      filter(Boolean),
    );
  }

  fetchCurrentCredentials(): Observable<TfaWebAuthnUserCredentials> {
    return this.tfaWebAuthnUserCredentialsService
      .fetchCurrentCredentials()
      .pipe(
        takeWhile(() => this.isAlive),
        tap((data) => {
          if (!data.credentialIds.length) {
            this.showDisabledWebAuthnToastr();
            this.modal.close();
          }
        }),
      );
  }

  showDisabledWebAuthnToastr(): void {
    this.translateService
      .get('GK.USER_SETTINGS.WEB_AUTHN_SETTINGS.DISABLED_WEB_AUTHN')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => this.toastr.success(text));
  }

  showRemovedCredentialToastr(): void {
    this.translateService
      .get('GK.USER_SETTINGS.WEB_AUTHN_SETTINGS.DELETED_KEY')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => this.toastr.success(text));
  }

  addKey(): void {
    this.tfaService.tfaTypeInOperation.next(TfaType.WebAuthn);
    this.tfaWebAuthnInitializationService
      .register()
      .pipe(
        takeWhile(() => this.isAlive),
        switchMap(() => this.tfaService.showTfaResetCodeModalIfNecessary()),
        finalize(() => this.tfaService.tfaTypeInOperation.next(undefined)),
      )
      .subscribe({
        next: () => {
          this.fetchCurrentCredentials().subscribe();
          this.showAddedCredentialToastr();
          this.showTfaResetCode();
        },
      });
  }

  showTfaResetCode(): void {
    this.tfaService.showTfaResetCodeModalIfNecessary().pipe();
  }

  showAddedCredentialToastr(): void {
    this.translateService
      .get('GK.USER_SETTINGS.WEB_AUTHN_SETTINGS.ADDED_KEY')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => this.toastr.success(text));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
