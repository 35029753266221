import {
  Component,
  EventEmitter,
  AfterViewInit,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import {
  MapAction,
  MapLayersActionType,
  TreeNodeAction,
  StorageSuffix,
  MapViewActionType,
} from '../../../../models';
import { MapControl } from '../../../../controls';
import { MapDataStorageUtils } from '../../../../utils';

@Component({
  selector: 'gk-layers-tree-sidebar',
  templateUrl: './layers-tree-sidebar.component.html',
  styleUrls: ['./layers-tree-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LayersTreeSidebarComponent
  extends MapControl
  implements OnInit, AfterViewInit
{
  id: string;
  elementFocusTrigger = new EventEmitter<string>();

  ngOnInit(): void {
    this.id = `${this.mapState.id}-layers-tree-collapse`;
  }

  ngAfterViewInit(): void {
    this.triggerFocus();
  }

  triggerFocus(): void {
    this.elementFocusTrigger.emit(this.id);
  }

  handleToggleSidebar(): void {
    this.dispatch.emit(
      new MapAction(
        MapViewActionType.IsSidebarExpandedChange,
        !this.mapState.viewState.isSidebarExpanded,
      ),
    );
  }

  handleTreeNodeState(treeNodeAction: TreeNodeAction): void {
    this.dispatch.emit(
      new MapAction(MapLayersActionType.TreeNodeChange, treeNodeAction.payload),
    );
    MapDataStorageUtils.setValue(
      this.mapState.id,
      StorageSuffix.LayersTree,
      treeNodeAction.payload,
    );
  }
}
