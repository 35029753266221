<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'STREET_NAMING.CLIENT_DATA' | translate }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isObjectCategoryOrDescriptionFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label
            class="mb-0"
            [attr.for]="controlName.ObjectCategoryOrDescription"
          >
            {{ 'STREET_NAMING.OBJECT_CATEGORY_OR_DESCRIPTION' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.ObjectCategoryOrDescription"
                [id]="controlName.ObjectCategoryOrDescription"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.ObjectCategoryOrDescription"
              ></textarea>
            </div>
          </div>
        </div>

        @if (!isObjectCategoryOrDescriptionFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isObjectProposedNewNameFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.ObjectProposedNewName">
            {{ 'STREET_NAMING.OBJECT_PROPOSED_NEW_NAME' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.ObjectProposedNewName"
                [id]="controlName.ObjectProposedNewName"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.ObjectProposedNewName"
              ></textarea>
            </div>
          </div>
        </div>

        @if (!isObjectProposedNewNameFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isObjectLocationFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.ObjectLocation">
            {{ 'STREET_NAMING.OBJECT_LOCATION' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.ObjectLocation"
                [id]="controlName.ObjectLocation"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.ObjectLocation"
              ></textarea>
            </div>
          </div>
        </div>

        @if (!isObjectLocationFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div class="card mb-4">
        <div class="card-header text-white bg-dark">
          {{ 'STREET_NAMING.OBJECT_DESIGNATION' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isObjectNamingJustificationFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label
            class="mb-0"
            [attr.for]="controlName.ObjectNamingJustification"
          >
            {{ 'STREET_NAMING.OBJECT_NAMING_JUSTIFICATION' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.ObjectNamingJustification"
                [id]="controlName.ObjectNamingJustification"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.ObjectNamingJustification"
              ></textarea>
            </div>
          </div>
        </div>

        @if (!isObjectNamingJustificationFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <gk-attachments [portalId]="portalId" [submitted]="submitted">
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section>
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Comments">
            {{ 'STREET_NAMING.ADDITIONAL_INFORMATION' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.Comments"
                [id]="controlName.Comments"
                class="form-control form-control-sm"
                [formControlName]="controlName.Comments"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'STREET_NAMING.SUBMIT' | translate }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>
