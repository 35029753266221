import { BaseNewRequestControlName } from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum NALUNewRequestAdditionalControlName {
  DateOff = 'dateOff',
}

export const NALUNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...NALUNewRequestAdditionalControlName,
};
