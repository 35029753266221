import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  BaseStyleClassesDirective,
  GkPersonSearchModule,
} from '@gk/gk-modules';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-data',
  imports: [CommonModule, GkPersonSearchModule, TranslateModule],
  templateUrl: './contact-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactDataComponent extends BaseStyleClassesDirective {
  @Input() submitted: boolean;
  @Input() contactDataFormGroup: FormGroup;
}
