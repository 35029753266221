import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiSystemSession,
  BasicResponse,
  LawPersonSearchFromApi,
  LoggedUserData,
  LoggedUserDataResponseDto,
  NaturalPersonSearch,
  StorageUtils,
  SystemSession,
} from '@gk/gk-modules';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  interval,
  throwError,
} from 'rxjs';
import { catchError, map, takeWhile, tap } from 'rxjs/operators';
import { RedirectionAfterLogoutQueryParamKey } from '../../modules/office-departments-portal/departments/departments.model';
import { SessionRefreshTriggerService } from '../session-refresh-trigger/session-refresh-trigger.service';

@Injectable()
export class SessionService {
  private isSetSessionTTLInterval = false;
  private isRegisteredRefreshTrigger = false;
  readonly timeForExitInSeconds = 60;
  readonly timeBelowWhichTtlIsCheckedInSeconds = 300;
  readonly logoutErrorMessageKey = 'logoutErrorMessage';
  readonly SESSION_CONFLICT_MESSAGE =
    'Na to konto zalogował się inny użytkownik';
  systemSession = new BehaviorSubject<SystemSession>(undefined);
  loggedUserData = new ReplaySubject<LoggedUserData>();
  sessionLifetime = new Subject<number>();
  tTLIntervalInMiliseconds = 180000;
  calculatedSessionSecondsLeft = new Subject<number>();

  constructor(
    private http: HttpClient,
    private sessionRefreshTriggerService: SessionRefreshTriggerService,
    private modalService: NgbModal,
    @Inject('window') public window: Window,
  ) {}

  refreshSession(): Subscription {
    return this.http
      .get('/api/system/keepsession')
      .pipe(
        tap(() => {
          this.fetchSessionLifetime();
          this.resetLogoutErrorMessageKey();
        }),
      )
      .subscribe({
        error: (err) => {
          if (err.status === 401) {
            const errorMessage = err.error.ResponseStatus.Message;
            if (
              errorMessage &&
              errorMessage.includes(this.SESSION_CONFLICT_MESSAGE)
            ) {
              StorageUtils.setItem(this.logoutErrorMessageKey, errorMessage);
            }

            this.systemSession.next(SystemSession.getEmpty());
            this.navigateAfterSessionEnd();
          }
        },
      });
  }

  resetLogoutErrorMessageKey(): void {
    StorageUtils.setItem(this.logoutErrorMessageKey, null);
  }

  navigateAfterSessionEnd(): void {
    this.window.location.href = `${window.location.origin}?${RedirectionAfterLogoutQueryParamKey}=true`;
  }

  private registerRefreshTrigger(): void {
    if (this.isRegisteredRefreshTrigger) {
      return;
    }

    this.isRegisteredRefreshTrigger = true;
    this.sessionRefreshTriggerService.activateTrigger();
    this.sessionRefreshTriggerService.sessionRefreshTrigger
      .pipe(takeWhile(() => this.isSessionSet(this.systemSession.getValue())))
      .subscribe(() => this.refreshSession());
  }

  getSystemSession(): Observable<SystemSession> {
    return this.http
      .get<ApiSystemSession>('/api/system/session')
      .pipe(
        map((systemSessionFromApi) =>
          SystemSession.fromApiToApp(systemSessionFromApi),
        ),
      );
  }

  authenticateGuest(): Observable<any> {
    return this.http.post('/api/system/guest/authenticate', undefined);
  }

  logOffSystemSession(): Observable<any> {
    this.modalService.dismissAll();
    const headers = new HttpHeaders().set('Accept', 'text/html');
    return this.http
      .post('/api/system/pz/logout', null, {
        responseType: 'text',
        headers: headers,
      })
      .pipe(
        tap((response) => {
          if (response) {
            this.renderHtmlResponse(response);
          }
        }),
        tap(() => this.systemSession.next(SystemSession.getEmpty())),
        catchError((error) => {
          window.location.href = window.location.origin;

          return throwError(() => error);
        }),
      );
  }

  renderHtmlResponse(logoutResponseHtml: string): void {
    const windowInstance = this.window.open('');
    if (!windowInstance) {
      return;
    }
    windowInstance.document.write(logoutResponseHtml);
  }

  fetchSystemLoggedUserData(): void {
    this.http
      .get<LoggedUserDataResponseDto>('/api/system/logged/user')
      .subscribe((loggedUserFromApi) => {
        if (loggedUserFromApi) {
          this.loggedUserData.next(
            LoggedUserData.fromApiToApp(loggedUserFromApi),
          );
        }
      });
  }

  getSystemLoggedUserAuthorizedPersonData(): Observable<NaturalPersonSearch> {
    return this.http
      .get<LawPersonSearchFromApi>('/api/system/logged/user/uprawniony')
      .pipe(
        map((authorizedPerson) =>
          NaturalPersonSearch.fromApiToApp(authorizedPerson),
        ),
      );
  }

  fetchSystemSession(): Subscription {
    return this.getSystemSession().subscribe((sessionData) => {
      this.systemSession.next(sessionData);
      if (this.isSessionSet(sessionData)) {
        this.registerRefreshTrigger();
        this.fetchSessionLifetime();
        this.setSyncTTLInterval();
      }
    });
  }

  fetchSessionLifetime(): void {
    this.http
      .get<BasicResponse<number>>('/api/system/session/ttl')
      .pipe(map((response) => response.Result))
      .subscribe({
        next: (lifetime) =>
          this.sessionLifetime.next(lifetime - this.timeForExitInSeconds),
        error: () => {
          window.location.href = window.location.origin;
        },
      });
  }

  isSessionSet(systemSession: SystemSession): boolean {
    return (
      systemSession &&
      systemSession.isAuthenticated &&
      systemSession.isAuthenticated()
    );
  }

  setSyncTTLInterval(): void {
    if (!this.isSetSessionTTLInterval) {
      this.isSetSessionTTLInterval = true;
      interval(this.tTLIntervalInMiliseconds)
        .pipe(takeWhile(() => this.isSessionSet(this.systemSession.getValue())))
        .subscribe(() => this.fetchSessionLifetime());
    }
  }
}
