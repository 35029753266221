import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gk-payment-success-simple-info',
  templateUrl: './payment-success-simple-info.component.html',
  standalone: true,
  imports: [TranslateModule],
})
export class PaymentSuccessSimpleInfoComponent {}
