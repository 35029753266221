import { ApiNewDokumentPowiazanyDalDto, EgibObject } from '@gk/gk-modules';
import {
  AuthorizedPersonData,
  AuthorizedPersonDataRequestDto,
} from '../../../../services/authorized-person-form/authorized-person-form.model';
import {
  ExtendedApplicantRequest,
  ExtendedApplicantRequestDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import {
  FeeWaiverCause,
  FeeWaiverCauseDto,
  FeeWaiverForm,
} from '../../../../shared/fee-waiver/fee-waiver.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import { PlanningDepartmentRequestType } from '../../shared/planning-department.model';
import {
  ExtractCertificateSubject,
  ExtractCertificateSubjectCheckbox,
  getRequestSubjectValueFromCheckboxData,
} from '../extract-certificate-subject/extract-certificate-subject.model';

export interface ECNewRequestDto extends ExtendedApplicantRequestDto {
  DaneUpowaznionego: AuthorizedPersonDataRequestDto;
  Uwagi: string;
  PrzyczynaZwolnieniaZOplatySkarbowej: FeeWaiverCause;
  PrzyczynaZwolnieniaOpis: string;
  PrzedmiotWniosku: ExtractCertificateSubject;
  IloscSztuk: number;
}

export class ECNewRequest extends ExtendedApplicantRequest {
  constructor(
    investorDetails: InvestorDetails,
    public authorizedDetailsForm: AuthorizedPersonData,
    public comments: string,
    public feeWaiverForm: FeeWaiverForm,
    public dismissalReasonExplanationDescription: string,
    public subject: ExtractCertificateSubjectCheckbox,
    shippingForm: Shipping,
    public quantity: number,
    public requestFormType: PlanningDepartmentRequestType,
  ) {
    super(
      investorDetails,
      undefined,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: ECNewRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): ECNewRequestDto {
    return {
      ...ExtendedApplicantRequest.mapCommonFields({
        data,
        parcels,
        files,
      }),
      DaneUpowaznionego: AuthorizedPersonDataRequestDto.fromAppToApi(
        data.authorizedDetailsForm,
      ),
      Uwagi: data.comments,
      PrzyczynaZwolnieniaZOplatySkarbowej: FeeWaiverCauseDto.fromAppToApi(
        data.feeWaiverForm,
      ),
      PrzyczynaZwolnieniaOpis: data.dismissalReasonExplanationDescription,
      PrzedmiotWniosku: getRequestSubjectValueFromCheckboxData(
        data.subject,
        data.requestFormType,
      ),
      IloscSztuk: data.quantity,
    };
  }
}
