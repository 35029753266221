import { Component, Input } from '@angular/core';
import {
  BsColor,
  DynamicNavbarConfig,
  TextColor,
} from '../../gk-dynamic-navbar.model';
import { GkDynamicNavbarUiStateStoreService } from '../../state/gk-dynamic-navbar-ui-state-store.service';

@Component({
  selector: 'gk-nav-base',
  templateUrl: './nav-base.component.html',
  styleUrls: ['./nav-base.component.scss'],
  standalone: false,
})
export class NavBaseComponent {
  @Input()
  navbarConfig: DynamicNavbarConfig;

  constructor(
    public gkDynamicNavbarUiStateStoreService: GkDynamicNavbarUiStateStoreService,
  ) {}

  getBgClass(): string {
    return `bg-${this.navbarConfig.generalNavConfig.color}`;
  }

  getButtonClass(): string {
    return `btn-${this.navbarConfig.generalNavConfig.color}`;
  }

  getTextColor(): string {
    switch (this.navbarConfig.generalNavConfig.color) {
      case BsColor.Dark:
        return TextColor.Light;
      case BsColor.Primary:
        return TextColor.Light;
      case BsColor.Secondary:
        return TextColor.White;
      case BsColor.Success:
        return TextColor.Light;
      case BsColor.ColorFromDb:
        return TextColor.ColorFromDb;
      case BsColor.Light:
        return TextColor.Dark;
      default:
        throw Error('Unhandled color');
    }
  }

  dispatch(navbarAction: any): void {
    this.gkDynamicNavbarUiStateStoreService.dispatchNavbarAction(navbarAction);
  }
}
