import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { Geometry } from 'ol/geom';
import { MapControl } from '../../../../controls';
import { ProjectionCode } from '../../../../models';
import { MapMeasurementService } from '../../../../services/map-measurement/map-measurement.service';

@Component({
  selector: 'gk-map-measurement-result',
  templateUrl: './map-measurement-result.component.html',
  styleUrls: [
    './map-measurement-result.component.scss',
    '../map-tooltip/map-tooltip.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapMeasurementResultComponent extends MapControl {
  @Input()
  movingPointerCoordinate: Coordinate;
  @Input() sketch: Geometry;
  defaultProjectionCode = ProjectionCode.PseudoMercator;

  constructor(public mapMeasurementService: MapMeasurementService) {
    super();
  }
}
