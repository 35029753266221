<section class="my-2">
  @if (shouldShowProvideFieldsAlert) {
    <div class="alert alert-danger mt-1">
      <span>
        {{ 'PROVIDE_REQUIRED_FIELDS' | translate }}
      </span>
    </div>
  }

  @if (errorSubmitMessage) {
    <div class="alert alert-danger mt-2">
      <span>{{ errorSubmitMessage }}</span>
    </div>
  }
</section>
