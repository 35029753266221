import { BaseNewRequestControlName } from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum LOBWNewRequestAdditionalControlName {
  LaneOccupancyPurpose = 'laneOccupancyPurpose',
  RoadNumber = 'roadNumber',
  Place = 'locationPlace',
  Street = 'locationStreet',
  LaneOccupancyDate = 'laneOccupancyDate',
  LaneOccupancyArea = 'laneOccupancyArea',
}

export const LOBWNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...LOBWNewRequestAdditionalControlName,
};

export enum LaneOccupancyAreaControlName {
  RoadWidth = 'roadWidth',
  Road = 'road',
  RoadsideComponents = 'roadsideComponents',
  RoadLaneOtherElements = 'roadLaneOtherElements',
  MapState = 'mapState',
  DateRange = 'dateRange',
  CalculatedArea = 'calculatedArea',
}

export enum LaneOccupancyAreaWidth {
  LessThan20PercentWidth = 1,
  Between20And50PercentWidth = 2,
  MoreThan50PercentWidth = 3,
}
