import { Component, Input } from '@angular/core';
import { LogoImageNavItemConfig } from '../../gk-dynamic-navbar.model';
import { NavBaseComponent } from '../nav-base/nav-base.component';

@Component({
  selector: 'gk-nav-item-logo-image',
  templateUrl: './nav-item-logo-image.component.html',
  styleUrls: ['./nav-item-logo-image.component.scss'],
  standalone: false,
})
export class NavItemLogoImageComponent extends NavBaseComponent {
  @Input()
  logoImageNavItemConfig: LogoImageNavItemConfig;
}
