import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, map } from 'rxjs';
import { PaymentAmountType, StampDutyOffice } from '../../services';
import { StampDutyService } from '../../services/stamp-duty/stamp-duty.service';
import { PortalId } from '../../utils/portal-id/portal-id.model';
import { validateFloatInput, validateIntegerInput } from '../../utils/utils';
import { PaymentsService } from '../payments.service';
import {
  PaymentDetailsControlName,
  PaymentDetailsFormGroup,
} from './services/payment-details-form/payment-details-form.model';
import { PaymentDetailsFormService } from './services/payment-details-form/payment-details-form.service';
import { PaymentDetails } from './services/payment-details/payment-details.model';

@Component({
  selector: 'gk-payment-details-modal',
  templateUrl: './payment-details-modal.component.html',
  styleUrl: './payment-details-modal.component.scss',
  standalone: false,
})
export class PaymentDetailsModalComponent implements OnInit, OnChanges {
  @Input() portalId: PortalId;
  @Input() paymentAmountType = PaymentAmountType.FixedSingle;
  formGroup: FormGroup<PaymentDetailsFormGroup>;
  formControlName = PaymentDetailsControlName;

  constructor(
    public stampDutyService: StampDutyService,
    private modal: NgbActiveModal,
    private paymentsService: PaymentsService,
    private paymentDetailsFormService: PaymentDetailsFormService,
  ) {}

  ngOnInit(): void {
    this.createFormGroup();
    this.setPaymentOwnerIfOneOwner();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['paymentAmountType'] &&
      !changes['paymentAmountType'].firstChange
    ) {
      this.createFormGroup();
    }
  }

  createFormGroup(): void {
    this.formGroup = this.paymentDetailsFormService.getFormGroup({
      [PaymentDetailsControlName.PaymentOwner]: true,
      [PaymentDetailsControlName.NumberOfPayments]:
        this.paymentAmountType === PaymentAmountType.FixedMultiple,
      [PaymentDetailsControlName.PaymentAmount]:
        this.paymentAmountType === PaymentAmountType.AnyEntered,
    });
  }

  setPaymentOwnerIfOneOwner(): void {
    this.stampDutyService
      .getOwners(this.portalId)
      .pipe(
        filter((owners) => owners.length === 1),
        map((owners) => owners[0]),
      )
      .subscribe((owner) => {
        this.getPaymentOwnerFormControl().setValue(owner);
      });
  }

  getPaymentOwner(): StampDutyOffice {
    return this.getPaymentOwnerFormControl()?.value;
  }

  getPaymentOwnerFormControl(): FormControl<StampDutyOffice> {
    return this.formGroup.get(
      PaymentDetailsControlName.PaymentOwner,
    ) as FormControl<StampDutyOffice>;
  }

  getNumberOfPaymentsFormControl(): FormControl<number> {
    return this.formGroup.get(
      PaymentDetailsControlName.NumberOfPayments,
    ) as FormControl<number>;
  }

  getPaymentAmountFormControl(): FormControl<number> {
    return this.formGroup.get(
      PaymentDetailsControlName.PaymentAmount,
    ) as FormControl<number>;
  }

  close(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();

      return;
    }
    this.modal.close();
  }

  dismiss(): void {
    this.modal.dismiss();
    this.paymentsService.isPaymentInProgress.emit(false);
  }

  validateIntegerInput(event: KeyboardEvent): void {
    if (!validateIntegerInput(event.key)) {
      event.preventDefault();
    }
  }

  validateFloatInput(event: KeyboardEvent): void {
    if (!validateFloatInput(event.key)) {
      event.preventDefault();
    }
  }

  getFormValue(): PaymentDetails {
    return this.formGroup.getRawValue();
  }
}
