import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue, MapState } from '@gk/gk-modules';
import { ECSControlName } from '../../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../../shared/base-new-request/base-new-request.model';
import {
  LaneOccupancyAreaControlName,
  LOBWNewRequestControlName,
} from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class LOBWNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [LOBWNewRequestControlName.ProxyDetailsCheckbox]: false,
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [LOBWNewRequestControlName.RoadNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LOBWNewRequestControlName.Place]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LOBWNewRequestControlName.Street]: [getDefaultControlValue()],
      [LOBWNewRequestControlName.LaneOccupancyPurpose]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LOBWNewRequestControlName.LaneOccupancyArea]: this.formBuilder.array([]),
    });
  }

  getLaneOccupancyAreaFormGroup(mapState: MapState): UntypedFormGroup {
    return this.formBuilder.group({
      [LaneOccupancyAreaControlName.RoadWidth]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LaneOccupancyAreaControlName.Road]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LaneOccupancyAreaControlName.RoadsideComponents]:
        getDefaultControlValue(),
      [LaneOccupancyAreaControlName.RoadLaneOtherElements]:
        getDefaultControlValue(),
      [LaneOccupancyAreaControlName.MapState]: mapState,
      [LaneOccupancyAreaControlName.DateRange]: null,
      [LaneOccupancyAreaControlName.CalculatedArea]: null,
    });
  }
}
