<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isBuildingSectionValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.BUILDING' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="0"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          </div>
        </div>

        @if (submitted && !isBuildingSectionValid()) {
          <div class="card-footer">
            @if (!isOneMapObjectSelected()) {
              <div class="alert alert-danger mt-2">
                {{
                  'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.SINGLE_BUILDING_REQUIRED'
                    | translate
                }}
              </div>
            }
            @if (isOneMapObjectSelected() && !isPlaceSelected()) {
              <div class="alert alert-danger mt-2">
                {{
                  'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.PLACE_REQUIRED'
                    | translate
                }}
              </div>
            }
            @if (
              isOneMapObjectSelected() &&
              !isStreetSelectedOrPlaceWithNullStreet()
            ) {
              <div class="alert alert-danger mt-2">
                {{
                  'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.STREET_REQUIRED'
                    | translate
                }}
              </div>
            }
          </div>
        }
      </div>
    </section>

    <gk-attachments [portalId]="portalId" [submitted]="submitted">
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestBodyValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section>
      <button type="submit" class="btn btn-primary" [disabled]="docSignPending">
        {{ 'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.SUBMIT' | translate }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>
