import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { delay, switchMap, takeWhile, tap } from 'rxjs/operators';
import { TfaType } from '../../../gk-user-settings/services/tfa/tfa.model';
import { TfaService } from '../../../gk-user-settings/services/tfa/tfa.service';
import { MessageStatus } from '../services/message-status/message-status.model';
import { NewPasswordService } from '../services/new-password/new-password.service';
import { SessionToolsService } from '../services/session-tools/session-tools.service';
import { TfaConfirmationService } from '../services/tfa-confirmation/tfa-confirmation.service';
import {
  TfaResetControlName,
  TfaResetFormGroup,
} from '../services/tfa-reset-form/tfa-reset-form.model';
import { TfaResetFormService } from '../services/tfa-reset-form/tfa-reset-form.service';
import { TfaResetService } from '../services/tfa-reset/tfa-reset.service';
import { TfaTotpInitializationCredentialsService } from '../services/tfa-totp-initialization-credentials/tfa-totp-initialization-credentials.service';
import { SessionToolsBaseComponent } from '../session-tools-base.component';

@Component({
  selector: 'gk-tfa-reset',
  templateUrl: './tfa-reset.component.html',
  styleUrls: ['../session-tools.component.scss', './tfa-reset.component.scss'],
  standalone: false,
})
export class TfaResetComponent
  extends SessionToolsBaseComponent
  implements OnInit, OnDestroy
{
  successDefaultMessage: string;
  isAlive = true;
  formGroup: FormGroup<TfaResetFormGroup>;
  controlNameEnum = TfaResetControlName;

  constructor(
    public tfaResetFormService: TfaResetFormService,
    public tfaTotpInitializationCredentialsService: TfaTotpInitializationCredentialsService,
    public override tfaService: TfaService,
    public override router: Router,
    public override translateService: TranslateService,
    public override newPasswordService: NewPasswordService,
    public override tfaConfirmationService: TfaConfirmationService,
    public override tfaResetService: TfaResetService,
    public override sessionToolsService?: SessionToolsService,
  ) {
    super(
      translateService,
      router,
      newPasswordService,
      tfaService,
      tfaConfirmationService,
    );
  }

  override ngOnInit() {
    this.createForm();
    super.ngOnInit();
  }

  createForm(): void {
    this.formGroup = this.tfaResetFormService.getFormGroup();
  }

  tfaResetSubmitHandle(): void {
    this.messageText = undefined;
    this.actionRequestInProgress = true;
    if (!this.formGroup.valid) {
      this.actionRequestInProgress = false;
      return;
    }
    this.tfaResetService
      .reset(this.formGroup.getRawValue())
      .pipe(
        takeWhile(() => this.isAlive),
        switchMap(() =>
          this.translateService.get(
            'SESSION_TOOLS.TFA_RESET.SUCCESSFULLY_RESET',
          ),
        ),
        tap((successMessage) => {
          this.setMessageStatus(MessageStatus.Success);
          this.messageText = successMessage;
        }),
        delay(2000),
      )
      .subscribe({
        next: () => {
          this.actionRequestInProgress = false;
          this.switchToLoginMode();
        },
        error: (error) => {
          console.error(error);
          this.actionRequestInProgress = false;
          this.setMessageStatus(MessageStatus.Error);
          this.messageText = this.errorDefaultMessage;
        },
      });
  }

  cancel(): void {
    this.tfaService.tfaTypeInOperation
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((tfaType) => {
        switch (tfaType) {
          case TfaType.Totp: {
            this.switchToTfaConfirmationMode();

            break;
          }
          case TfaType.WebAuthn: {
            this.switchToLoginMode();

            break;
          }
        }
      });
  }
}
