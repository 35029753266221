import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MapControl } from '../../../../controls';
import {
  MapAction,
  MapObjectTableActionType,
  ToolActionType,
} from '../../../../models';
import { MapDrawingActionService } from '../../../../services/map-drawing-action/map-drawing-action.service';

@Component({
  selector: 'gk-clear-button',
  templateUrl: './clear-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ClearButtonComponent extends MapControl {
  constructor(private mapDrawingActionService: MapDrawingActionService) {
    super();
  }
  clearMapObjects(): void {
    this.dispatch.emit(new MapAction(ToolActionType.ClearAllMapObjects));
    this.dispatch.emit(new MapAction(ToolActionType.ClearMeasurements));
    this.dispatch.emit(
      new MapAction(
        MapObjectTableActionType.DeselectAll,
        undefined,
        this.mapState.mapObjectTableStateCurrentIndex,
      ),
    );
    this.mapDrawingActionService.emitBreakSignal();
  }
}
