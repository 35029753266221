import { Component, OnInit } from '@angular/core';
import { FormBaseComponent } from '../../../form-base/form-base.component';
import { BasicSelectFieldConfig } from '../../../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-basic-select',
  templateUrl: './basic-select.component.html',
  standalone: false,
})
export class BasicSelectComponent extends FormBaseComponent implements OnInit {
  override config: BasicSelectFieldConfig;

  ngOnInit(): void {
    this.setControlValueToFirstOption();
  }

  setControlValueToFirstOption(): void {
    this.getControl().setValue(this.config.options[0]);
  }
  compareFormat(
    value1: BasicSelectFieldConfig,
    value2: BasicSelectFieldConfig,
  ): boolean | undefined {
    return value1 && value2 && value1.name === value2.name;
  }
}
