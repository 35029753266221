import { inject } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ConfigOption, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldConfigPresetProvider } from '@ngx-formly/core/lib/models';
import { distinctUntilChanged } from 'rxjs/operators';
import { CachedService } from '../../../gk-dynamic-form/services/cached/cached.service';
import { DictionaryField } from '../../../gk-dynamic-list/services/dictionary/dictionary.model';
import { FormFieldType, FormFieldWrappers } from '../custom-types/model';
import { FormlyFieldKendoSelectComponentProps } from '../custom-types/select.type';
import { PprfTransactionAndPricingFormControlName } from '../../../gk-components/property-price-register-form/pprf-transaction-and-pricing-form/pprf-transaction-and-pricing-form.model';
import { Community } from '../../../gk-dynamic-form/services/cached/cached.model';

export function dictionaryFieldValidator(
  control: AbstractControl,
): ValidationErrors {
  const controlValue = control.value as DictionaryField;

  return controlValue?.id ? null : { required: true };
}

const defaultItem = {
  id: null,
  name: 'Wszystkie',
} as DictionaryField;

export class CommunitiesPresetProvider
  implements FormlyFieldConfigPresetProvider
{
  cachedService = inject(CachedService);
  constructor(
    public configFactory?: (cachedService?: CachedService) => FormlyFieldConfig,
  ) {}

  getConfiguration(): FormlyFieldConfig {
    const config = this.configFactory?.(this.cachedService);
    return {
      key: 'community',
      type: FormFieldType.KendoSelect,
      wrappers: [FormFieldWrappers.FormField],
      defaultValue: defaultItem,
      ...(config ?? {}),
      props: {
        label: 'Jednostka ewidencyjna',
        options: this.cachedService.communitiesWithDistricts,
        valueProp: 'id',
        labelProp: 'name',
        valuePrimitive: false,
        ...(config?.props ?? {}),
      } as FormlyFieldKendoSelectComponentProps,
    };
  }
}

export class DistrictsPresetProvider
  implements FormlyFieldConfigPresetProvider
{
  cachedService = inject(CachedService);
  constructor(
    public configFactory?: (cachedService?: CachedService) => FormlyFieldConfig,
  ) {}

  getConfiguration(): FormlyFieldConfig {
    const config = this.configFactory?.(this.cachedService);
    return {
      key: 'district',
      type: FormFieldType.KendoSelect,
      wrappers: [FormFieldWrappers.FormField],
      defaultValue: defaultItem,
      ...(config ?? {}),
      props: {
        label: 'Obręb',
        options: this.cachedService.allDistricts,
        valueProp: 'id',
        labelProp: 'name',
        valuePrimitive: false,
        ...(config?.props ?? {}),
      } as FormlyFieldKendoSelectComponentProps,
      hooks: {
        // @todo() https://formly.dev/docs/guide/expression-properties/#3-get-notified-about-an-expression-changes
        afterViewInit: (field): void => {
          const parentField = field.parent.get('community');
          const communityControl = parentField?.formControl;
          communityControl?.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
              field.formControl.reset();
              if (value?.districts?.length) {
                field.props.options = value.districts;
              } else {
                field.props.options = this.cachedService.allDistricts;
              }
            });
        },
        ...(config?.hooks ?? {}),
      },
    };
  }
}

export class SheetsPresetProvider implements FormlyFieldConfigPresetProvider {
  constructor(public configFactory?: () => FormlyFieldConfig) {}
  getConfiguration(): FormlyFieldConfig {
    const config = this.configFactory?.();
    return {
      key: 'sheet',
      type: FormFieldType.KendoSelect,
      wrappers: [FormFieldWrappers.FormField],
      defaultValue: defaultItem,
      ...(config ?? {}),
      props: {
        label: 'Arkusz',
        options: [],
        valueProp: 'id',
        labelProp: 'name',
        valuePrimitive: false,
        disabled: true,
        ...(config?.props ?? {}),
      } as FormlyFieldKendoSelectComponentProps,
      hooks: {
        // @todo() https://formly.dev/docs/guide/expression-properties/#3-get-notified-about-an-expression-changes
        afterViewInit: (field): void => {
          const districtControl = field.parent.get('district').formControl;
          districtControl.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
              field.formControl.reset();
              if (value?.id) {
                field.props.disabled = false;
                field.props.options = value.sheets;
              } else {
                field.props.disabled = true;
              }
            });
        },
        ...(config?.hooks ?? {}),
      },
    };
  }
}

export const DictrictMultiSelectKendoFormlyPresetsConfigFactory = (
  cachedService: CachedService,
): Partial<FormlyFieldConfig> => ({
  key: 'district',
  type: FormFieldType.MultiSelect,
  wrappers: [FormFieldWrappers.FormField],
  defaultValue: {
    id: null,
    name: 'Wszystkie',
  },
  props: {
    label: 'Obręb',
    options: cachedService.allDistricts,
    valueProp: 'id',
    labelProp: 'name',
    valuePrimitive: false,
  } as FormlyFieldKendoSelectComponentProps,
  hooks: {
    afterViewInit: (field): void => {
      const communityControl = field.parent.get(
        PprfTransactionAndPricingFormControlName.CadastralUnit,
      ).formControl;
      communityControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((communities: Community[]) => {
          field.formControl.reset([]);
          if (communities?.length) {
            field.props.options = communities.flatMap(
              (community) => community.districts,
            );
          } else {
            field.props.options = cachedService.allDistricts;
          }
        });
    },
  },
});

export function registerKendoFormlyPresets(
  params: {
    communitiesConfigFactory?: (
      cachedService?: CachedService,
    ) => FormlyFieldConfig;
    districtsConfigFactory?: (
      cachedService?: CachedService,
    ) => FormlyFieldConfig;
    sheetsConfigFactory?: () => FormlyFieldConfig;
  } = {},
): ConfigOption {
  return {
    presets: [
      {
        name: 'communities',
        config: new CommunitiesPresetProvider(params.communitiesConfigFactory),
      },
      {
        name: 'districts',
        config: new DistrictsPresetProvider(params.districtsConfigFactory),
      },
      {
        name: 'sheets',
        config: new SheetsPresetProvider(params.sheetsConfigFactory),
      },
    ],
  };
}
