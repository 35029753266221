import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  DictionaryField,
  FormAlertService,
  ValidationErrorType,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, filter, takeWhile } from 'rxjs';
import { DocumentSubjectService } from '../../../services/document-subject/document-subject.service';
import { DocumentTypeService } from '../../../services/document-type/document-type.service';
import { EcoNewsletterControlName } from '../../../services/eco-newsletter-form/eco-newsletter-form.model';
import { EcoNewsletterFormService } from '../../../services/eco-newsletter-form/eco-newsletter-form.service';
import {
  EcoNewsletter,
  EcoNewsletterForm,
} from '../../../services/eco-newsletter/eco-newsletter.model';
import { EcoNewsletterService } from '../../../services/eco-newsletter/eco-newsletter.service';

@Component({
  selector: 'app-eco-newsletter',
  templateUrl: 'eco-newsletter.component.html',
  standalone: false,
})
export class EcoNewsletterComponent implements OnInit, OnDestroy {
  newsletterTopicsFormGroup: UntypedFormGroup;
  documentTypes: DictionaryField[];
  documentSubjects: DictionaryField[];
  ecoNewsletterControlName = EcoNewsletterControlName;
  newsletterInfoTextTranslation: string;
  submitted = false;
  loading = false;
  private isAlive = true;

  constructor(
    private documentTypeService: DocumentTypeService,
    private documentSubjectService: DocumentSubjectService,
    private ecoNewsletterFormService: EcoNewsletterFormService,
    private ecoNewsletterService: EcoNewsletterService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private formAlertService: FormAlertService,
  ) {}

  ngOnInit(): void {
    this.fetchDocumentTypesAndSubjects();
    this.subscribeToNewsletterInfoTextTranslation();
  }

  subscribeToNewsletterInfoTextTranslation(): void {
    this.translateService
      .get('ECO_PORTAL.NEWSLETTER_SUCCESS_SUBSCRIBE_INFO')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) => (this.newsletterInfoTextTranslation = translation),
      );
  }

  fetchDocumentTypesAndSubjects(): void {
    combineLatest([
      this.getDocumentSubjectsListRequest(),
      this.getDocumentTypesListRequest(),
    ])
      .pipe(
        takeWhile(() => this.isAlive),
        filter(
          ([documentSubjects, documentTypes]) =>
            !!(documentSubjects.length && documentTypes.length),
        ),
      )
      .subscribe(([documentSubjects, documentTypes]) => {
        this.documentSubjects = documentSubjects;
        this.documentTypes = documentTypes;
        this.createForm();
      });
  }

  createForm(): void {
    this.newsletterTopicsFormGroup = this.ecoNewsletterFormService.getFormGroup(
      this.documentSubjects,
      this.documentTypes,
    );
  }

  getDocumentTypesListRequest(): BehaviorSubject<DictionaryField[]> {
    return this.documentTypeService.documentTypes;
  }

  getDocumentSubjectsListRequest(): BehaviorSubject<DictionaryField[]> {
    return this.documentSubjectService.documentSubjects;
  }

  getDocumentTypesFormControls(): UntypedFormControl[] {
    return this.getDocumentTypesFormArray().controls as UntypedFormControl[];
  }

  getDocumentTypesFormArray(): UntypedFormArray {
    return this.newsletterTopicsFormGroup.get(
      this.ecoNewsletterControlName.Types,
    ) as UntypedFormArray;
  }

  getDocumentSubjectsFormArray(): UntypedFormArray {
    return this.newsletterTopicsFormGroup.get(
      this.ecoNewsletterControlName.Subjects,
    ) as UntypedFormArray;
  }

  getDocumentSubjectsFormControls(): UntypedFormControl[] {
    return this.getDocumentSubjectsFormArray().controls as UntypedFormControl[];
  }

  getEmailFormControl(): UntypedFormControl {
    return this.newsletterTopicsFormGroup.get(
      this.ecoNewsletterControlName.Email,
    ) as UntypedFormControl;
  }

  submitForm(): void {
    this.loading = true;
    this.submitted = true;
    if (!this.isFormValid()) {
      this.loading = false;

      return;
    }
    const ajaxBody = EcoNewsletter.createFromForm(
      this.documentTypes,
      this.documentSubjects,
      this.getFormValue(),
    ).getApiObject();

    this.ecoNewsletterService
      .subscribeToNewsletter(ajaxBody)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: () => {
          this.toastr.success(this.newsletterInfoTextTranslation);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  isFormValid(): boolean {
    return this.newsletterTopicsFormGroup.valid;
  }

  getFormValue(): EcoNewsletterForm {
    return this.newsletterTopicsFormGroup.value;
  }

  shouldShowRequiredAlert(formControl: UntypedFormControl): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      'required',
      this.submitted,
    );
  }

  shouldShowAtLeastOneRequiredFromArray(
    abstractControl: AbstractControl,
  ): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      abstractControl,
      ValidationErrorType.AtLeastOneRequiredFromArray,
      this.submitted,
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
