import { Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/kendo/form-field';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { KendoWindowInstanceNames } from '../../services/kendo-window/kendo-window.model';

export interface FormlyFieldRangePickerComponentProps
  extends FormlyFieldSelectProps {
  parentWindowInstanceName: KendoWindowInstanceNames;
  coverageOfOriginalRangeValidation?: boolean;
}

@Component({
  selector: 'gk-formly-field-range-picker',
  template: `
    <gk-map-range-picker-grid
      [parentWindowInstanceName]="props.parentWindowInstanceName"
      [formlyAttributes]="field"
      [formControl]="formControl"
      [ngClass]="{ required: props.required }"
      [coverageOfOriginalRangeValidation]="
        props.coverageOfOriginalRangeValidation!
      "
    ></gk-map-range-picker-grid>
  `,
  standalone: false,
})
export class FormlyFieldRangePickerComponent extends FieldType<
  FieldTypeConfig<FormlyFieldRangePickerComponentProps>
> {}
