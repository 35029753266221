import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { TfaService } from '../../../gk-user-settings/services/tfa/tfa.service';
import { MessageStatus } from '../services/message-status/message-status.model';
import { NewPasswordService } from '../services/new-password/new-password.service';
import { ResetPasswordControlName } from '../services/reset-password-form/reset-password-form.model';
import { ResetPasswordService } from '../services/reset-password/reset-password.service';
import { SessionToolsService } from '../services/session-tools/session-tools.service';
import { TfaConfirmationService } from '../services/tfa-confirmation/tfa-confirmation.service';
import { TfaWebAuthnVerificationService } from '../services/tfa-web-authn-verification/tfa-web-authn-verification.service';
import { SessionToolsBaseComponent } from '../session-tools-base.component';
import { ResetPasswordFormService } from './../services/reset-password-form/reset-password-form.service';

@Component({
  selector: 'gk-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    '../session-tools.component.scss',
    './reset-password.component.scss',
  ],
  standalone: false,
})
export class ResetPasswordComponent
  extends SessionToolsBaseComponent
  implements OnInit
{
  isAlive = true;
  formGroup: UntypedFormGroup;
  controlNameEnum = ResetPasswordControlName;
  successDefaultMessage: string;

  constructor(
    public override translateService: TranslateService,
    public resetPasswordFormService: ResetPasswordFormService,
    public resetPasswordService: ResetPasswordService,
    public override router: Router,
    public override newPasswordService: NewPasswordService,
    public override tfaService: TfaService,
    public override tfaConfirmationService: TfaConfirmationService,
    public override sessionToolsService?: SessionToolsService,
    public override tfaWebAuthnVerificationService?: TfaWebAuthnVerificationService,
  ) {
    super(
      translateService,
      router,
      newPasswordService,
      tfaService,
      tfaConfirmationService,
    );
  }

  override ngOnInit() {
    this.createForm();
    this.fetchDefaultSuccessMessage();
    super.ngOnInit();
  }

  createForm(): void {
    this.formGroup = this.resetPasswordFormService.getFormGroup();
  }

  fetchDefaultSuccessMessage(): void {
    this.translateService
      .get('SESSION_TOOLS.RESET_PASSWORD.SUCCESS')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((message) => {
        this.successDefaultMessage = message;
      });
  }

  resetPasswordSubmitHandle(): void {
    this.actionRequestInProgress = true;
    this.submitted = true;
    if (!this.formGroup?.valid) {
      this.actionRequestInProgress = false;
      return;
    }
    this.resetPasswordService
      .resetPassword(this.formGroup.getRawValue())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: () => {
          this.actionRequestInProgress = false;
          this.handleSuccessLoginResponse();
        },
        error: () => {
          this.setMessageStatus(MessageStatus.Error);
          this.messageText = this.errorDefaultMessage;
          this.actionRequestInProgress = false;
        },
      });
  }

  override handleSuccessLoginResponse(): void {
    this.setMessageStatus(MessageStatus.Success);
    this.messageText = this.successDefaultMessage;
  }

  shouldShowLoginControlRequiredMessage(): boolean {
    return (
      this.isLoginFormControlReadyToShowAlert() &&
      this.getLoginFormControlRequiredError()
    );
  }

  getLoginFormControlRequiredError(): boolean {
    const formControlErrors = this.getLoginFormControl().errors;

    return !!formControlErrors?.['required'];
  }

  isLoginFormControlReadyToShowAlert(): boolean {
    const control = this.getLoginFormControl();
    return this.submitted && control.invalid;
  }

  getLoginFormControl(): AbstractControl {
    return this.formGroup.get(ResetPasswordControlName.Login);
  }
}
