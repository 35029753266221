import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { InvestorDetailsFormService } from '../../../../services/investor-details-form/investor-details-form.service';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { BPTNewRequestControlName } from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class BPTNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private investorDetailsFormService: InvestorDetailsFormService,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonInvestorFormGroup({
        [ECSControlName.ElectronicCorrespondenceStatement]: false,
      }).controls,
      [BPTNewRequestControlName.CurrentInvestorDetails]:
        this.investorDetailsFormService.getFormGroup(),
      [BPTNewRequestControlName.PermitTransferDate]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [BPTNewRequestControlName.PermitTransferNumber]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [BPTNewRequestControlName.BuildingNameAndType]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [BPTNewRequestControlName.Place]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BPTNewRequestControlName.Street]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BPTNewRequestControlName.StreetAbsence]: false,
      [BPTNewRequestControlName.BuildingNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BPTNewRequestControlName.LocalNumber]: [getDefaultControlValue()],
      [BPTNewRequestControlName.PostalCode]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BPTNewRequestControlName.PostOffice]: [getDefaultControlValue()],
    });
  }
}
