import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ClosedAreasService } from '../../../services/closed-areas/closed-areas.service';
import { UiVerboseService } from '../../../services/ui-verbose/ui-verbose.service';

@Component({
  selector: 'app-info-crossing-closed-areas-modal',
  templateUrl: './info-crossing-closed-areas-modal.component.html',
  styleUrls: ['./info-crossing-closed-areas-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InfoCrossingClosedAreasModalComponent {
  closedAreas = this.closedAreasService.closedAreasResponse;

  constructor(
    private closedAreasService: ClosedAreasService,
    private ngbActiveModal: NgbActiveModal,
    private uiVerboseService: UiVerboseService,
  ) {}

  close(): void {
    this.ngbActiveModal.close();
  }

  dismiss(): void {
    this.ngbActiveModal.dismiss();
  }

  getUiBoolean(val: boolean): Observable<string> {
    return this.uiVerboseService.getUiBoolean(val);
  }
}
