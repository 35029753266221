<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <app-workspace-state
      [workspaceStateDraftVersion]="workspaceStateDraftVersion"
      [workspaceStateId]="workspaceStateId"
      [getWorkspaceStateToSave]="getWorkspaceStateToSave"
      [buttonClass]="'btn-outline-primary'"
      (workspaceStateLoaded)="handleLoadWorkspaceResponse($event)"
    ></app-workspace-state>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.CLIENT_DATA' | translate }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.LOCATION' | translate }}
        </div>

        <div class="card-body">
          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Place">
              {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.PLACE' | translate }}
            </label>

            <input
              type="text"
              [name]="controlName.Place"
              [id]="controlName.Place"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.Place"
              [ngbTypeahead]="searchPlace"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              (focusout)="setPlaceByName()"
            />

            @if (shouldShowRequiredAlert(getPlaceFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Street">
              {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.STREET' | translate }}
            </label>

            <input
              type="text"
              [name]="controlName.Street"
              [id]="controlName.Street"
              class="form-control form-control-sm"
              [ngbTypeahead]="searchStreet"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              [formControlName]="controlName.Street"
              (focusout)="setStreetByName()"
              [autocomplete]="undefined"
            />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted &&
          (!areLandParcelsChosen() || !isParcelAreaFormControlValid())
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.MAP_LOCATION' | translate }}
        </div>

        <div class="card-body">
          <div class="mb-2">
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="whole-parcel"
                [attr.name]="controlName.ParcelArea"
                [formControlName]="controlName.ParcelArea"
                [value]="parcelArea.WholeParcel"
                class="form-check-input"
              />

              <label class="form-check-label" for="whole-parcel">
                {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.ENTIRE_PARCEL' | translate }}
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="part-of-parcel-control"
                [attr.name]="controlName.ParcelArea"
                [formControlName]="controlName.ParcelArea"
                [value]="parcelArea.PartOfParcel"
                class="form-check-input"
              />

              <label class="form-check-label" for="part-of-parcel-control">
                {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.PART_OF_PARCEL' | translate }}
              </label>
            </div>

            @if (shouldShowRequiredAlert(getParcelAreaFormControl())) {
              <div class="alert alert-danger">
                {{ 'RADIO_REQUIRED' | translate }}
              </div>
            }
          </div>

          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>

        @if (submitted && !areLandParcelsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'SUPPLEMENTARY_PARCEL_PURCHASE.LAND_PARCEL_REQUIRED' | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isReasonFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Reason">
            {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.REASON' | translate }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.Reason"
            [id]="controlName.Reason"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.Reason"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getReasonFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <gk-attachments [portalId]="portalId" [submitted]="submitted">
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !isOwnershipFormFormControlValid()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Comments">
            {{
              'SUPPLEMENTARY_PARCEL_PURCHASE.ADDITIONAL_INFORMATION' | translate
            }}
          </label>
        </div>
        <div class="card-body">
          <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              [id]="ownershipForm.PerpetualUser"
              [attr.name]="controlName.OwnershipForm"
              [formControlName]="controlName.OwnershipForm"
              [value]="ownershipForm.PerpetualUser"
            />
            <label
              class="form-check-label"
              [attr.for]="ownershipForm.PerpetualUser"
              >{{
                'SUPPLEMENTARY_PARCEL_PURCHASE.PERPETUAL_USER' | translate
              }}</label
            >
          </div>
          <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              [id]="ownershipForm.CoOwner"
              [attr.name]="controlName.OwnershipForm"
              [formControlName]="controlName.OwnershipForm"
              [value]="ownershipForm.CoOwner"
            />
            <label
              class="form-check-label"
              [attr.for]="ownershipForm.CoOwner"
              >{{ 'SUPPLEMENTARY_PARCEL_PURCHASE.CO_OWNER' | translate }}</label
            >
          </div>
          @if (shouldShowRequiredAlert(getOwnershipFormFormControl())) {
            <div class="alert alert-danger mt-2">
              {{
                'SUPPLEMENTARY_PARCEL_PURCHASE.OWNERSHIP_REQUIRED' | translate
              }}
            </div>
          }
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              [id]="controlName.AdjacentProperty"
              [formControlName]="controlName.AdjacentProperty"
            />
            <label
              class="form-check-label"
              [attr.for]="controlName.AdjacentProperty"
              >{{
                'SUPPLEMENTARY_PARCEL_PURCHASE.ADJACENT_PROPERTY' | translate
              }}</label
            >
          </div>
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              [id]="controlName.Other"
              [formControlName]="controlName.Other"
            />
            <label class="form-check-label" [attr.for]="controlName.Other">{{
              'SUPPLEMENTARY_PARCEL_PURCHASE.OTHER' | translate
            }}</label>
          </div>
          @if (formGroup.get(controlName.Other).value) {
            <div class="row mt-2">
              <div class="col-12">
                <textarea
                  [name]="controlName.Comments"
                  [id]="controlName.Comments"
                  class="form-control form-control-sm"
                  [formControlName]="controlName.Comments"
                ></textarea>
              </div>
            </div>
          }
        </div>
      </div>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.SUBMIT' | translate }}
      </button>

      <app-request-preview-button
        class="ms-2"
        [buttonClass]="'btn-outline-primary'"
        [getPreviewRequestBody]="getRequestBody"
        [previewRequestUrl]="
          '/api/interesant/wniosek/zakupDzialkaUzupelniajaca/podglad'
        "
      ></app-request-preview-button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>
