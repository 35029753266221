import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPersonAddress,
  Place,
  Street,
  getValueWhenPropertyIsAddressFields,
  getValueWhenPropertyIsString,
} from '@gk/gk-modules';
import { PostalCode } from '../../../../../services/postal-code/postal-code.model';
import {
  DocumentationFormType,
  ExtendedApplicantRequest,
  ExtendedApplicantRequestDto,
} from '../../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../../shared/shipping-form/shipping-form.model';

export interface PINewRequestDto extends ExtendedApplicantRequestDto {
  NazwaIRodzajObiektu: string;
  MiejscId: string;
  Miejsc: string;
  UlicaId: string;
  Ulica: string;
  KodId: string;
  Kod: string;
  Poczta: string;
  Numer: string;
  Numer2: string;
  Opis: string;
}

export class PINewRequest extends ExtendedApplicantRequest {
  constructor(
    public demolitionIntention: number,
    investorDetails: InvestorDetails,
    proxyDetails: InvestorDetails,
    public objectNameAndType: string,
    public place: Place,
    public street: Street,
    public buildingNumber: string,
    public localNumber: string,
    public postalCode: PostalCode,
    public postOffice: string,
    public description: string,
    public idNumber: number,
    public phoneNumber: number,
    public email: string,
    public address: LawPersonAddress,
    proxyDetailsCheckbox: boolean,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
  ) {
    super(
      investorDetails,
      documentationForm,
      shippingForm,
      undefined,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }
  static fromAppToApi(
    data: PINewRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): PINewRequestDto {
    return {
      ...ExtendedApplicantRequest.mapCommonFields({
        data,
        parcels,
        files,
      }),
      NazwaIRodzajObiektu: data.objectNameAndType,
      MiejscId: getValueWhenPropertyIsAddressFields(data.place),
      Miejsc: getValueWhenPropertyIsString(data.place),
      UlicaId: getValueWhenPropertyIsAddressFields(data.street),
      Ulica: getValueWhenPropertyIsString(data.street),
      KodId: getValueWhenPropertyIsAddressFields(data.postalCode),
      Kod: getValueWhenPropertyIsString(data.postalCode),
      Poczta: data.postOffice,
      Numer: data.buildingNumber,
      Numer2: data.localNumber,
      Opis: data.description,
    };
  }
}
