import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeComponent } from 'angularx-qrcode';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginFormService } from './services/login-form/login-form.service';
import { LoginService } from './services/login/login.service';
import { NewPasswordFormService } from './services/new-password-form/new-password-form.service';
import { NewPasswordService } from './services/new-password/new-password.service';
import { ResetPasswordFormService } from './services/reset-password-form/reset-password-form.service';
import { ResetPasswordService } from './services/reset-password/reset-password.service';
import { TfaConfirmationFormService } from './services/tfa-confirmation-form/tfa-confirmation-form.service';
import { TfaConfirmationService } from './services/tfa-confirmation/tfa-confirmation.service';
import { TfaResetFormService } from './services/tfa-reset-form/tfa-reset-form.service';
import { TfaResetService } from './services/tfa-reset/tfa-reset.service';
import { SessionToolInfoComponent } from './session-tool-info/session-tool-info.component';
import { SessionToolsComponent } from './session-tools.component';
import { TfaConfirmationComponent } from './tfa-confirmation/tfa-confirmation.component';
import { TfaResetCodeComponent } from './tfa-reset-code/tfa-reset-code.component';
import { TfaResetComponent } from './tfa-reset/tfa-reset.component';
import { WebAuthnSettingsComponent } from './web-authn-settings/web-authn-settings.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    QRCodeComponent,
  ],
  declarations: [
    SessionToolsComponent,
    LoginComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    TfaConfirmationComponent,
    WebAuthnSettingsComponent,
    TfaResetCodeComponent,
    TfaResetComponent,
    SessionToolInfoComponent,
  ],
  exports: [SessionToolsComponent, LoginComponent, NewPasswordComponent],
  providers: [
    LoginFormService,
    LoginService,
    ResetPasswordFormService,
    ResetPasswordService,
    NewPasswordFormService,
    NewPasswordService,
    TfaConfirmationFormService,
    TfaConfirmationService,
    TfaResetService,
    TfaResetFormService,
  ],
})
export class SessionToolsModule {}
