import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPerson,
} from '@gk/gk-modules';
import {
  DocumentationFormType,
  InvestorRequest,
  InvestorRequestDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export interface DPNewRequestDto extends InvestorRequestDto {
  NazwaRozbiorki: string;
}

export class DPNewRequest extends InvestorRequest {
  constructor(
    lawPerson: LawPerson,
    contactData: LawPerson,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public demolitionInformation: string,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
  ) {
    super(
      lawPerson,
      contactData,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static fromAppToApi(
    data: DPNewRequest,
    parcels: EgibObject[],
    buildings: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): DPNewRequestDto {
    return {
      ...InvestorRequest.mapCommonFields({
        data,
        parcels,
        buildings,
        files,
      }),
      NazwaRozbiorki: data.demolitionInformation,
    };
  }
}
