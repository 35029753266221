import { Component } from '@angular/core';
import { GridToolBarItem } from '../gk-kendo-grid.model';
import { GkKendoDocumentationGridDataService } from './gk-kendo-documentation-grid-data.service';

@Component({
  selector: 'gk-kendo-documentation-grid-wrapper',
  template:
    '<gk-kendo-documentation-grid [topGridToolbarItems]="topGridToolbarItems"></gk-kendo-documentation-grid>',
  providers: [GkKendoDocumentationGridDataService],
  standalone: false,
})
export class GkKendoDocumentationGridWrapperComponent {
  topGridToolbarItems: GridToolBarItem[] = [];
  constructor(
    private gkKendoDocumentationGridDataService: GkKendoDocumentationGridDataService,
  ) {}

  loadDocumentationFromAPI(url: string): void {
    this.gkKendoDocumentationGridDataService.queryByUrlParams(url);
  }
}
