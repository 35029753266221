import { ApiNewDokumentPowiazanyDalDto } from '@gk/gk-modules';
import {
  BaseRequest,
  CommonRequestDtoBase,
  DocumentationFormType,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export interface ANRNewRequestDto extends CommonRequestDtoBase {
  MiejscId: string | number;
  UlicaId: string | number;
  BudynekId: string | number;
}

export class ANRNewRequest extends BaseRequest {
  constructor(
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
  ) {
    super(undefined, undefined, documentationForm, shippingForm);
  }

  static fromAppToApi(
    data: ANRNewRequest,
    placeId: string | number,
    streetId: string | number,
    buildingId: string | number,
    files: ApiNewDokumentPowiazanyDalDto[],
  ): ANRNewRequestDto {
    return {
      ...BaseRequest.mapCommonFieldsShared({
        data,
        files,
      }),
      MiejscId: placeId,
      UlicaId: streetId,
      BudynekId: buildingId,
    };
  }
}
