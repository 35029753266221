import { BaseNewRequestControlName } from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum ECNewRequestFormAdditionalControlName {
  DismissalReasonExplanationDescription = 'dismissalReasonExplanationDescription',
  Comments = 'comments',
  Subject = 'subject',
  WrittenExtractCheckbox = 'writtenExtractCheckbox',
  GraphicExtractCheckbox = 'graphicExtractCheckbox',
  CertificateCheckbox = 'certificateCheckbox',
  Quantity = 'quantity',
  RodoClauseAgreeCheckbox = 'rodoClauseAgreeCheckbox',
}

export const ECNewRequestFormControlName = {
  ...BaseNewRequestControlName,
  ...ECNewRequestFormAdditionalControlName,
};
