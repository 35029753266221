import { EgibObject } from '@gk/gk-modules';
import { ZamowienieDzialkaDto } from '../../../../shared/base-new-request/base-new-request.model';
import {
  FeeWaiverCause,
  FeeWaiverCauseDto,
  FeeWaiverControlName,
  FeeWaiverForm,
} from '../../../../shared/fee-waiver/fee-waiver.model';
import {
  Shipping,
  ShippingDto,
} from '../../../../shared/shipping-form/shipping-form.model';
import { PlanningDepartmentRequestType } from '../../shared/planning-department.model';
import {
  ExtractCertificateSubject,
  ExtractCertificateSubjectCheckbox,
  getRequestSubjectValueFromCheckboxData,
} from '../extract-certificate-subject/extract-certificate-subject.model';

export interface ExtractCertificateFeeRequest {
  subject: ExtractCertificateSubjectCheckbox;
  requestFormType: PlanningDepartmentRequestType;
  quantity: number;
  [FeeWaiverControlName.FeeWaiverForm]: FeeWaiverForm;
  shippingForm?: Shipping;
}

export class ExtractCertificateFeeRequestDto {
  constructor(
    public PrzedmiotWniosku: ExtractCertificateSubject,
    public Dzialki: ZamowienieDzialkaDto[],
    public IloscSztuk: number,
    public PrzyczynaZwolnieniaZOplatySkarbowej: FeeWaiverCause,
    public Odbior: ShippingDto,
  ) {}

  static fromAppToApi(
    data: ExtractCertificateFeeRequest,
    parcels: EgibObject[],
  ): ExtractCertificateFeeRequestDto {
    return new this(
      getRequestSubjectValueFromCheckboxData(
        data.subject,
        data.requestFormType,
      ),
      parcels.map((parcel) => ZamowienieDzialkaDto.fromAppToApi(parcel)),
      data.quantity,
      FeeWaiverCauseDto.fromAppToApi(data[FeeWaiverControlName.FeeWaiverForm]),
      ShippingDto.fromAppToApi(data.shippingForm),
    );
  }
}

export interface ExtractCertificateFeeResponseDto {
  Kwota: number;
  Status: string;
}

export class ExtractCertificateFeeResponse {
  constructor(
    public amount: number,
    public status: string,
  ) {}

  static fromAppToApi(
    data: ExtractCertificateFeeResponseDto,
  ): ExtractCertificateFeeResponse {
    return new this(data.Kwota, data.Status);
  }
}
