import { ApplicantType } from '@gk/gk-modules';

export interface HubSettingsFromApi {
  ZapisBezposredniOsobEnabled: boolean;
  PlanowanieAutoDoo: boolean;
  PrzpWyborWnioskodawcyZRegonTryb: ApplicantType;
  PrzpMaxIloscDni: number;
  PrzpPorozumienieEnabled: boolean;
}

export class HubSettings {
  constructor(
    public addingPersonsDirectlyToDb: boolean,
    public isPlanningDepartmentPriceShown: boolean,
    public przpApplicantType: ApplicantType,
    public przpMaxDays: number,
    public przpAgreementEnabled: boolean,
  ) {}

  static fromApiToApp(hubSettingsFromApi: HubSettingsFromApi): HubSettings {
    return new this(
      hubSettingsFromApi.ZapisBezposredniOsobEnabled,
      hubSettingsFromApi.PlanowanieAutoDoo,
      hubSettingsFromApi.PrzpWyborWnioskodawcyZRegonTryb,
      hubSettingsFromApi.PrzpMaxIloscDni,
      hubSettingsFromApi.PrzpPorozumienieEnabled,
    );
  }
}
