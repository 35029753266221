import { FormControl } from '@angular/forms';
import { FieldId } from '@gk/gk-modules';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum PLDACPSDNewRequestAdditionalControlName {
  ConstructionIntentionType = 'constructionIntentionType',
  ConstructionProjectName = 'constructionProjectName',
}

export const PLDACPSDNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...PLDACPSDNewRequestAdditionalControlName,
};

export enum ElectronicCorrespondenceStatement {
  Consent = 'electronicCorrespondenceStatementConsent',
  NotConsent = 'electronicCorrespondenceStatementNotConsent',
}

export interface PLDACPSDNewRequestFormGroup
  extends LawPersonNewRequestFormGroup {
  [PLDACPSDNewRequestControlName.ConstructionIntentionType]: FormControl<
    FieldId[]
  >;
  [PLDACPSDNewRequestControlName.ConstructionProjectName]: FormControl<string>;
}
