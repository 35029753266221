import { BaseNewRequestControlName } from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum RENewRequestAdditionalControlName {
  RequestType = 'requestType',
  RoadNumber = 'roadNumber',
  Place = 'roadExitLocationPlace',
  PurposeOfPropertyUse = 'purposeOfPropertyUse',
  PurposeOfPropertyUseChanged = 'purposeOfPropertyUseChanged',
  WillUseOfPropertyChange = 'willUseOfPropertyChange',
  ChangedPurposeOfPropertyUse = 'changedPurposeOfPropertyUse',
}

export const RENewRequestControlName = {
  ...BaseNewRequestControlName,
  ...RENewRequestAdditionalControlName,
};
