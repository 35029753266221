import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Observable, takeWhile } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownNavItemId } from '../../gk-dynamic-navbar.model';
import { NavbarUiState } from '../../state/gk-dynamic-navbar-ui-state-store.model';
import { GkDynamicNavbarUiStateStoreService } from '../../state/gk-dynamic-navbar-ui-state-store.service';
import { NavBaseComponent } from '../nav-base/nav-base.component';

@Component({
  selector: 'gk-nav-item-dropdown',
  templateUrl: './nav-item-dropdown.component.html',
  styleUrls: ['./nav-item-dropdown.component.scss'],
  standalone: false,
})
export class NavItemDropdownComponent
  extends NavBaseComponent
  implements OnInit
{
  private isAlive = true;
  @HostBinding('class.nav-item')
  navItemClass = true;
  @HostBinding('class.dropdown')
  dropdownClass = true;
  @Input()
  dropdownNavItemConfig: any;
  navBarUiState: NavbarUiState;

  constructor(
    public override gkDynamicNavbarUiStateStoreService: GkDynamicNavbarUiStateStoreService,
  ) {
    super(gkDynamicNavbarUiStateStoreService);
  }

  ngOnInit(): void {
    this.subscribeToNavbarUiState();
  }

  subscribeToNavbarUiState(): void {
    this.gkDynamicNavbarUiStateStoreService.navbarUiState
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((navBarUiState) => {
        this.navBarUiState = navBarUiState;
      });
  }

  getModifiers(): string {
    return `${
      this.dropdownNavItemConfig.itemsHorizontal
        ? 'dropdown-menu--horizontal '
        : ''
    }${this.dropdownNavItemConfig.itemsLeft ? 'dropdown-menu--left ' : ''}${
      this.dropdownNavItemConfig.customDropdownClass || ''
    }`;
  }

  getIconClassSet(): string {
    return `fa fa-${this.dropdownNavItemConfig.faIconName} fa-lg`;
  }

  getCustomIconSrc(): Observable<string> {
    const navItemId = this.dropdownNavItemConfig.navItemId as DropdownNavItemId;
    return this.gkDynamicNavbarUiStateStoreService
      .getCustomIconSrc(navItemId)
      .pipe(map((customIconSrc) => customIconSrc[navItemId]));
  }
}
