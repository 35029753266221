export enum PortalId {
  IntraEwid = 1,
  Client = 3,
  MClient = 11,
  OtherGeodesyRequests = 16,
  InterMod = 99,
  Designer = 9,
  Przp = 10,
  RoadExit = 23,
  LaneOccupancyInfrastructureDevices = 21,
  LaneOccupancyBuildingObjects = 22,
  ExcludingLandFromAgriculturalProductionStageFirst = 24,
  ExcludingLandFromAgriculturalProductionStageSecond = 25,
  BuildingPermitTransfer = 33,
  LaneOccupancyBuildingWorks = 20,
  EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest = 26,
  EnvironmentalProtectionPortalFishingCardRequest = 29,
  EnvironmentalProtectionPortalWaterEquipmentRequest = 28,
  EnvironmentalProtectionPortalTreesAndShrubsFellingRequest = 27,
  ConstructionPortalConstructionNotificationRequest = 30,
  ConstructionPortalDemolitionNotificationRequest = 31,
  ConstructionPortalConstructionLogRequest = 32,
  ConstructionPortalPremiseIndependenceRequest = 34,
  PlanningDepartmentExtractFromLocalSpatialDevelopmentPlanRequest = 35,
  PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlanRequest = 36,
  PlanningDepartmentExtractFromStudy = 37,
  AddressNumberRequestPortal = 15,
  Surveyor = 14,
  SupplementaryParcelPurchase = 40,
  PropertyLease = 41,
  OwnershipConversion = 38,
  GrantingRights = 43,
  StreetNaming = 42,
  CommunalPremisePurchase = 39,
  LandAndBuildingRegisterDataChangesNotificationRequest = 44,
  SoilClassificationOfLandNewCarryOutRequest = 45,
  PresentationCommentsLandBuildingRegistryProject = 46,
  AccessToInformationOnEnvironmentAndItsProtection = 47,
  BuildingPermit = 48,
  DemolitionPermit = 49,
  BuildingOrPartUseChange = 50,
  TemporaryStructureBuildingPermit = 51,
  BuildingPermitModificationDecision = 52,
  NotObjectedNotificationRightsObligationsTransfer = 53,
  PlotLandDevArchConstrProjSeparateDecision = 54,
  AdjacentPropertyNeedEnterDecision = 55,
  SingleFamilyBuilding70ConstructionConversion = 56,
  SingleFamilyBuildingConstructionConversion = 57,
  GeodeticAndCartographicServiceDataRequest = 58,
  LandAndBuildingRegisterDataUpdateRequest = 61,
  LandAndBuildingRegisterDataUpdateUponEntitiesRequest = 62,
  PermanentManagementFeeDiscountDecisionGrantingRequest = 63,
  OwnershipConversionFeeCalculationRequest = 64,
}
