import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'numberCell',
  standalone: false,
})
export class NumberCellPipe implements PipeTransform {
  public separator: string;
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string | number, separator = '.'): SafeHtml {
    this.separator = separator;
    const [integerPart, decimalPart] = value
      ? `${value}`.split(this.separator)
      : [];
    const formattedInteger = this.getIntegerCell(integerPart);
    const formattedDecimal = this.getDecimalCell(decimalPart);
    const tableContent = `<table><tr>${formattedInteger}${formattedDecimal}</tr></table>`;

    const contentSanitized = this.sanitizer.sanitize(
      SecurityContext.HTML,
      tableContent,
    );
    const strippedContent = contentSanitized.replace(
      /^<table><tr>(.*)<\/tr><\/table>$/,
      '$1',
    );

    return this.sanitizer.bypassSecurityTrustHtml(strippedContent);
  }

  getIntegerCell(value?: string | number): string {
    return this.getCell(value, 'text-end pe-0 border-end-0');
  }

  getDecimalCell(value?: string | number): string {
    return this.getCell(value, 'text-start ps-0 border-start-0', true);
  }

  private getCell(
    value?: string | number,
    cellClasses = '',
    isDecimal = false,
  ): string {
    return `<td class="${cellClasses}">${
      value ? (isDecimal ? `${this.separator}${value}` : value) : ''
    }</td>`;
  }
}
