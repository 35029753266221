import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import { MemoryUnit } from '../../../../utils/files/files.model';
import { AddRelatedDocumentControlConfig } from '../../../gk-dynamic-list.model';
import {
  FileWithCustomUploading,
  UploadingState,
} from '../../../services/add-related-document/add-related-document.model';
import { AddRelatedDocumentService } from '../../../services/add-related-document/add-related-document.service';
import { DataService } from '../../../services/data/data.service';
import { Dictionary } from '../../../services/dictionary/dictionary.model';
import { DictionaryService } from '../../../services/dictionary/dictionary.service';
import { DynamicListManagerService } from '../../../services/dynamic-list-manager/dynamic-list-manager.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-add-related-document',
  styleUrls: ['./control-add-related-document.component.scss'],
  templateUrl: './control-add-related-document.component.html',
  standalone: false,
})
export class ControlAddRelatedDocumentComponent
  extends Control<any>
  implements OnInit
{
  @Input()
  override controlConfig: AddRelatedDocumentControlConfig;
  files: FileWithCustomUploading[] = [];
  isUploading = false;
  uploadingStateEnum = UploadingState;
  resSuccess = 0;
  resFailed = 0;
  resExpected = 0;
  documentTypeDictType = '4';
  documentTypeDict = {} as Dictionary;
  showInvalidMsg = false;

  constructor(
    private addRelatedDocumentService: AddRelatedDocumentService,
    private dictionaryService: DictionaryService,
    private dynamicListManagerService: DynamicListManagerService,
    protected override translateService: TranslateService,
    protected override dataService: DataService,
    private toastr: ToastrService,
  ) {
    super(translateService, dataService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.initDocumentTypeDict();
  }

  initDocumentTypeDict(): void {
    this.documentTypeDict = this.dictionaryService.getDictionary(
      this.documentTypeDictType,
    );
  }

  handleAction(): void {
    this.showInvalidMsg = false;

    this.files.forEach((file) => {
      file.progress = file.progress || 0;
      file.uploadingState = file.uploadingState || UploadingState.Waiting;
      file.uuid = UUID.UUID();
      file.docType =
        file.docType || (this.documentTypeDict?.fields?.[0]?.id as number);
    });
  }

  maybeActivateButton(): void {
    if (this.resSuccess + this.resFailed === this.resExpected) {
      this.isUploading = false;
    }
  }

  removeFile(uuid: string): void {
    this.files = this.files.filter((file) => file.uuid !== uuid);
  }

  getNumberOfFilesToUpload(): number {
    return this.files.filter(
      (file) => file.uploadingState !== UploadingState.Success,
    ).length;
  }

  getFileSizeString(sizeInBytes: number): string {
    return sizeInBytes >= 1024
      ? `${this.getFileSizeInKB(sizeInBytes)}${MemoryUnit.KiloByte}`
      : `${sizeInBytes}${MemoryUnit.Byte}`;
  }

  getFileSizeInKB = (sizeInBytes: number): number =>
    Math.round(sizeInBytes / 1024);

  areFilesValid(): boolean {
    return !this.files.some((file) => !file.docType);
  }

  resetResponses(): void {
    this.resSuccess = 0;
    this.resFailed = 0;
  }

  isFileUploadingOrSucceed(file: FileWithCustomUploading): boolean {
    return (
      file.uploadingState === UploadingState.Uploading ||
      file.uploadingState === UploadingState.Success
    );
  }

  upload(): void {
    if (!this.areFilesValid()) {
      this.showInvalidMsg = true;
      return;
    } else {
      this.showInvalidMsg = false;
    }

    this.isUploading = true;
    if (!this.checkIfDocIdIsDefined()) {
      return;
    }
    this.resetResponses();
    this.resExpected = this.getNumberOfFilesToUpload();

    if (this.controlConfig.sendAllFilesInOneRequest) {
      this.sendAllFilesInOneRequest();
    } else {
      this.sendFilesInSeparateRequests();
    }
  }

  sendAllFilesInOneRequest(): void {
    this.files.forEach((file) => {
      if (file.uploadingState === UploadingState.Success) {
        return;
      }

      file.uploadingState = UploadingState.Uploading;
    });
    this.addRelatedDocumentService
      .postNewDocuments(this.files, this.getValue(), this.controlConfig.url)
      .then(
        (result) => {
          result.pipe(takeWhile(() => this.isAlive)).subscribe({
            next: (event: HttpEvent<any>) => {
              if (event && event.type === HttpEventType.UploadProgress) {
                this.files.forEach((file) => {
                  if (file.uploadingState === UploadingState.Uploading) {
                    this.handleUploadInProgress(file, event);
                  }
                });
              } else if (event instanceof HttpResponse) {
                this.files.forEach((file) => {
                  if (file.uploadingState === UploadingState.Uploading) {
                    this.handleSuccessUpload(file);
                  }
                });
              }
            },
            error: (err) => {
              console.error(err);
              this.files.forEach((file) => {
                if (file.uploadingState === UploadingState.Uploading) {
                  this.handleFailUpload(file);
                }
              });
            },
          });
        },
        (err) => {
          console.error(err);
          this.files.forEach((file) => {
            if (file.uploadingState === UploadingState.Uploading) {
              this.handleFailUpload(file);
            }
          });
        },
      );
  }

  sendFilesInSeparateRequests(): void {
    this.files.forEach((file) => {
      if (file.uploadingState === UploadingState.Success) {
        return;
      }

      file.uploadingState = UploadingState.Uploading;

      this.addRelatedDocumentService
        .postNewDocument(file, this.getValue(), this.controlConfig.url)
        .then(
          (result) => {
            result.pipe(takeWhile(() => this.isAlive)).subscribe({
              next: (event: HttpEvent<any>) => {
                if (event && event.type === HttpEventType.UploadProgress) {
                  this.handleUploadInProgress(file, event);
                } else if (event instanceof HttpResponse) {
                  this.handleSuccessUpload(file);
                }
              },
              error: () => this.handleFailUpload(file),
            });
          },
          () => this.handleFailUpload(file),
        );
    });
  }

  checkIfDocIdIsDefined(): boolean {
    if (!this.getValue()) {
      this.translateService
        .get('GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.NO_RELATED_UDP_CASE')
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((text) => {
          this.toastr.warning(text, null, { closeButton: true });
          this.isUploading = false;
        });

      return false;
    }
    this.isUploading = false;

    return true;
  }

  handleUploadInProgress(file: FileWithCustomUploading, event: any): void {
    file.progress = Math.round((100 * event.loaded) / event.total);
  }

  handleSuccessUpload(file: FileWithCustomUploading): void {
    file.uploadingState = UploadingState.Success;
    this.resSuccess++;
    this.removeFile(file.uuid);
    this.maybeActivateButton();
    this.dynamicListManagerService.refreshDocumentsListControl.next();
  }

  handleFailUpload(file: FileWithCustomUploading): void {
    file.uploadingState = UploadingState.Failed;
    this.resFailed++;
    file.progress = 100;
    this.maybeActivateButton();
    this.dynamicListManagerService.refreshDocumentsListControl.next();
  }
}
