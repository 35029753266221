import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  FieldId,
  LawPerson,
} from '@gk/gk-modules';
import {
  DocumentationFormType,
  InvestorRequest,
  InvestorRequestDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export interface BPNewRequestDto extends InvestorRequestDto {
  RodzajZamierzeniaBudowlanego: FieldId[];
  NazwaZamierzeniaBudowlanego: string;
}

export class BPNewRequest extends InvestorRequest {
  constructor(
    lawPerson: LawPerson,
    contactData: LawPerson,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public constructionIntentionType: FieldId[],
    public constructionProjectName: string,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
  ) {
    super(
      lawPerson,
      contactData,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static fromAppToApi(
    data: BPNewRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): BPNewRequestDto {
    return {
      ...InvestorRequest.mapCommonFields({
        data,
        parcels,
        files,
      }),
      RodzajZamierzeniaBudowlanego: data.constructionIntentionType,
      NazwaZamierzeniaBudowlanego: data.constructionProjectName,
    };
  }
}
