import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { MainRoutes } from '../../../guards/guard.models';
import { SessionService } from '../../../services/session/session.service';
import { HubSettings } from '../../../services/settings/settings.model';
import { SettingsService } from '../../../services/settings/settings.service';
import {
  FreeServicesCaseType,
  freeServicesCaseTypeRoutingParam,
} from '../free-services-portal-routing.module';

@Injectable({
  providedIn: 'root',
})
export class ValidRequestTypeGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionService: SessionService,
    private settingsService: SettingsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.sessionService.getSystemSession().pipe(
      switchMap((session) =>
        session.isAuthenticated()
          ? this.settingsService.getHubSettings()
          : of(undefined),
      ),
      map((hubSettings) =>
        hubSettings
          ? this.checkAccess(
              route.params[freeServicesCaseTypeRoutingParam],
              hubSettings,
            )
          : undefined,
      ),
      catchError(() => {
        this.navigateToNoAccess();
        return of(false);
      }),
    );
  }

  checkAccess(caseType: string, hubSettings: HubSettings): boolean {
    switch (caseType) {
      case FreeServicesCaseType.Request:
        return true;
      case FreeServicesCaseType.Agreement:
        if (!hubSettings.przpAgreementEnabled) {
          this.navigateToNoAccess();
        }

        return hubSettings.przpAgreementEnabled;
      default:
        this.navigateToNotFound();

        return false;
    }
  }

  navigateToNotFound(): void {
    this.router.navigateByUrl(`/${MainRoutes.NotFound}`);
  }

  navigateToNoAccess(): void {
    this.router.navigateByUrl(`/${MainRoutes.NoAccess}`);
  }
}
