import { Component } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { GkKendoGridComponent } from '../../../../../gk-kendo';
import { MapLandClassesGridDto } from './map-land-classes-grid-data-model';
import { MapLandClassesGridDataService } from './map-land-classes-grid-data.service';
import { mapLandClassesGridColumns } from './map-land-classes-grid-model';

@Component({
  selector: 'gk-map-land-classes-grid',
  templateUrl:
    '../../../../../gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class MapLandClassesGridComponent extends GkKendoGridComponent<MapLandClassesGridDto> {
  constructor(mapLandClassesGridDataService: MapLandClassesGridDataService) {
    super(mapLandClassesGridDataService);
  }

  override columns = mapLandClassesGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
}
