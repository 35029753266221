import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/kendo/form-field';
import { iwIconWyczysc } from '../../../assets/css/ewid-svg/svg-icons';
import { GenericToolbarItemName } from '../../gk-kendo-grid/gk-kendo-grid-toolbar/gk-kendo-grid-toolbar.model';
import { GkKendoGridToolbarComponent } from '../../gk-kendo-grid/gk-kendo-grid-toolbar/gk-kendo-grid-toolbar.component';
import {
  GridToolBarItem,
  GridToolBarItemType,
} from '../../gk-kendo-grid/gk-kendo-grid.model';
import { AnyGeometryObject } from '../../../gk-map/models';

type SelectRangeProps = FormlyFieldProps;

@Component({
  selector: 'gk-formly-field-kendo-select-range',
  template: `
    <gk-kendo-grid-toolbar
      #gridToolbar
      [formlyAttributes]="field"
      [formControl]="formControl"
      [gridToolbarItems]="getToolbarItems()"
      [overflow]="false"
      [parent]="gridToolbar"
    ></gk-kendo-grid-toolbar>
  `,
  standalone: false,
})
export class FormlyFieldKendoSelectRangeComponent extends FieldType<
  FieldTypeConfig<SelectRangeProps>
> {
  @ViewChild('gridToolbar', { static: false })
  gridToolbar!: GkKendoGridToolbarComponent;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  getToolbarItems(): GridToolBarItem[] {
    return [
      {
        name: GenericToolbarItemName.GetPolygonGeometryFromMap,
        title:
          'COORDINATION_MEETING_PORTAL.CASE_STANCE_ADD.BUTTONS.ADD_GEOM_TO_POSITION',
        text: 'COORDINATION_MEETING_PORTAL.CASE_STANCE_ADD.BUTTONS.ADD_GEOM_TO_POSITION',
        showText: 'overflow',
        payload: () => ({
          doneCallback: (anyGeometryObject: AnyGeometryObject): void => {
            this.formControl.setValue(anyGeometryObject);
            this.changeDetectorRef.markForCheck();
          },
        }),
      },
      {
        name: 'clearSelectedRange',
        svgIcon: iwIconWyczysc,
        className: 'svg-icon-blue',
        title: 'COORDINATION_MEETING_PORTAL.CASE_STANCE_ADD.BUTTONS.CLEAR',
        text: 'COORDINATION_MEETING_PORTAL.CASE_STANCE_ADD.BUTTONS.CLEAR',
        showText: 'overflow',
        callback: () => this.clearGeomFromMap(),
        visible: () => this.isExitGeomFromMap(),
      },
      {
        name: 'selectRangeFromMap',
        text: () => this.getAreaText(),
        type: GridToolBarItemType.Text,
        visible: () => this.isExitGeomFromMap(),
      },
    ];
  }

  isExitGeomFromMap(): boolean {
    return !!this.formControl.value;
  }

  clearGeomFromMap(): void {
    this.formControl.setValue(null);
  }

  getAreaText(): string {
    return `${this.formControl.value?.area} [ha]`;
  }
}
