import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  DescendantsSelectionState,
  TreeNode,
  TreeNodeAction,
  TreeNodeActionType,
} from '../../../../../models';

@Component({
  selector: 'gk-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TreeNodeComponent {
  @Input()
  providedData: TreeNode = new TreeNode('root', 'root', undefined, 'root');
  @Output()
  dispatch = new EventEmitter<TreeNodeAction>();

  isRoot = (node: TreeNode): boolean => node.name && node.namespace === 'root';

  isLeaf = (node: TreeNode): boolean => !node.children.length;

  changeExpanded(): void {
    this.dispatch.emit(
      new TreeNodeAction(
        TreeNodeActionType.ChangeExpanded,
        this.getUpdatedNodeExpandedState(
          this.providedData,
          !this.providedData.expanded,
        ),
      ),
    );
  }

  changeSelected(selected: boolean): void {
    this.dispatch.emit(
      new TreeNodeAction(
        TreeNodeActionType.ChangeSelected,
        this.getUpdatedNodeSelectedState(this.providedData, selected),
      ),
    );
  }

  getUpdatedNodeSelectedState(node: TreeNode, selected: boolean): TreeNode {
    return {
      ...node,
      selected,
      children: node.children.map((child) =>
        this.getUpdatedNodeSelectedState(child, selected),
      ),
    };
  }

  getUpdatedNodeExpandedState(node: TreeNode, expanded: boolean): TreeNode {
    return {
      ...node,
      expanded,
    };
  }

  handleChildChange(treeNodeAction: TreeNodeAction): void {
    this.dispatch.emit(
      new TreeNodeAction(
        treeNodeAction.type,
        this.getUpdatedTreeNodeState(treeNodeAction),
      ),
    );
  }

  getUpdatedTreeNodeState(treeNodeAction: TreeNodeAction): TreeNode | never {
    switch (treeNodeAction.type) {
      case TreeNodeActionType.ChangeExpanded:
        return {
          ...this.providedData,
          children: this.getUpdatedChildren(treeNodeAction),
        };
      case TreeNodeActionType.ChangeSelected:
        return {
          ...this.providedData,
          selected: treeNodeAction.payload.selected,
          children: this.getUpdatedChildren(treeNodeAction),
        };
      default:
        throw new Error('Not handled action type from TreeNodeActionType.');
    }
  }

  getUpdatedChildren(treeNodeAction: TreeNodeAction): TreeNode[] {
    return this.providedData.children.map((child) =>
      child.name === treeNodeAction.payload.name
        ? { ...child, ...treeNodeAction.payload }
        : child,
    );
  }

  isPartiallySelected = (treeNode: TreeNode): boolean =>
    this.getDescendantsSelectionState(treeNode) ===
    DescendantsSelectionState.PartiallySelected;

  getDescendantsSelectionState(treeNode: TreeNode): DescendantsSelectionState {
    const selectedDescendantsCount = this.getDescendantsCount(
      treeNode,
      true,
      'selected',
    );
    const descendantsCount = this.getDescendantsCount(treeNode);

    if (selectedDescendantsCount === descendantsCount) {
      return DescendantsSelectionState.AllSelected;
    }

    if (
      selectedDescendantsCount > 0 &&
      selectedDescendantsCount < descendantsCount
    ) {
      return DescendantsSelectionState.PartiallySelected;
    }

    return DescendantsSelectionState.NoneSelected;
  }

  getDescendantsCount = (
    treeNode: TreeNode,
    shouldCheckProperty = false,
    propertyToCheck?: string,
  ): number =>
    treeNode.children
      .map((child) =>
        this.getDescendantsCount(child, shouldCheckProperty, propertyToCheck),
      )
      .reduce(
        (prevNode, currentNode) => prevNode + currentNode,
        this.getTreeNodeChildrenLengthBasedOnProperty(
          treeNode,
          shouldCheckProperty,
          propertyToCheck,
        ),
      );

  getTreeNodeChildrenLengthBasedOnProperty = (
    treeNode: TreeNode,
    shouldCheckProperty: boolean,
    propertyToCheck: string,
  ): number =>
    shouldCheckProperty
      ? treeNode.children.filter(
          (child) => child[propertyToCheck as keyof TreeNode],
        ).length
      : treeNode.children.length;

  getOrientedCaretClass = (): string =>
    this.providedData.expanded ? 'fa-caret-down' : 'fa-caret-right';
}
