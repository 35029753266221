import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiNewDokumentPowiazanyDalDto,
  AttachmentsComponent,
  ColumnHeader,
  DocSignService,
  EgibObject,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapAction,
  MapId,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  StreetService,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  catchError,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  takeWhile,
  tap,
  throwError,
} from 'rxjs';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import {
  BsMessageType,
  WorkspaceStateDraftVersion,
  WorkspaceStateId,
} from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import {
  PMFDDGRNewDataUpdateRequestControlName,
  PMFDDGRNewDataUpdateRequestFormGroup,
} from '../services/new-request-form/new-request-form.model';
import { PMFDDGRNewDataUpdateRequestFormService } from '../services/new-request-form/new-request-form.service';
import {
  PMFDDGRNewDataUpdateRequest,
  PMFDDGRNewDataUpdateRequestDto,
  PMFDDGRNewDataUpdateRequestFormWorkspaceState,
} from '../services/new-request/new-request.model';
import { MainRoutes } from './../../../guards/guard.models';

@Component({
  selector: 'app-pmfddgr-new-request',
  templateUrl: './new-request.component.html',
  standalone: false,
})
export class PMFDDGRNewDataUpdateRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  override controlName = PMFDDGRNewDataUpdateRequestControlName;
  override formGroup: FormGroup<PMFDDGRNewDataUpdateRequestFormGroup>;
  override portalId =
    PortalId.PermanentManagementFeeDiscountDecisionGrantingRequest;
  parcelsMapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.LandParcel],
    true,
  );
  buildingsMapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'GK.MAP.REFERENCE_NUMBER'),
      new ColumnHeader('districtName', 'GK.MAP.DISTRICT'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.Building],
    false,
  );
  premisesMapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'GK.MAP.REFERENCE_NUMBER'),
      new ColumnHeader('districtName', 'GK.MAP.DISTRICT'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    false,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.Premises],
    false,
  );
  parcelsMapObjectTableStateIndex = 0;
  buildingsMapObjectTableStateIndex = 1;
  premisesMapObjectTableStateIndex = 2;
  mapSettings: MapSettings;
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  successSigned = false;
  workspaceStateDraftVersion =
    WorkspaceStateDraftVersion.PermanentManagementFeeDiscountDecisionGrantingRequestFormVersion;
  workspaceStateId =
    WorkspaceStateId.PermanentManagementFeeDiscountDecisionGrantingRequestForm;

  constructor(
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    private pmfddgrNewDataUpdateRequestFormService: PMFDDGRNewDataUpdateRequestFormService,
    public override streetService: StreetService,
    private toastr: ToastrService,
    public override router: Router,
    private modalService: NgbModal,
    public override pzService: PzService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.createForm();
    this.fetchMapSettings();
  }

  createForm(): void {
    this.formGroup = this.pmfddgrNewDataUpdateRequestFormService.getFormGroup();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Applicant)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.mapSettings = mapSettings;
        this.setMapState();
      });
  }

  override setMapState(): void {
    this.mapState = new MapState(
      MapId.PermanentManagementFeeDiscountDecisionGrantingRequest,
      this.mapStateService.getViewState(
        MapId.PermanentManagementFeeDiscountDecisionGrantingRequest,
        this.mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel, ToolType.Building, ToolType.Premises],
        this.mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.PermanentManagementFeeDiscountDecisionGrantingRequest,
        this.mapSettings,
        MapPortalName.Applicant,
      ),
      this.getMapObjectTablesState(),
    );
  }

  getMapObjectTablesState(): MapObjectTableState[] {
    const mapObjectTablesState = [];
    mapObjectTablesState[this.parcelsMapObjectTableStateIndex] =
      this.parcelsMapObjectTableState;
    mapObjectTablesState[this.buildingsMapObjectTableStateIndex] =
      this.buildingsMapObjectTableState;
    mapObjectTablesState[this.premisesMapObjectTableStateIndex] =
      this.premisesMapObjectTableState;

    return mapObjectTablesState;
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty || this.attachmentsComponent.areChosenDocuments()
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areMapObjectsChosen()
    );
  }

  handleSubmit(): void {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }

    this.sendRequest();
  }

  sendRequest(): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.requestReplyFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() => this.getRequestBody()),
        switchMap((body) =>
          this.docSignService.addToSign(
            body,
            '/api/interesant/wniosek/bonifikataOplataTrwalyZarzad/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (error) => this.handleSendAndValidateFailure(error),
      });
  }

  getRequestBody = (): Observable<PMFDDGRNewDataUpdateRequestDto> => {
    return from(
      Promise.all(this.attachmentsComponent.getConvertedFiles()).catch(
        (error) => new Error(error),
      ),
    ).pipe(
      switchMap((apiFiles) => {
        const apiFilesArray = Object.values(apiFiles).flatMap((file) => file);
        if (
          (Array.isArray(apiFilesArray) &&
            apiFilesArray.some((file) => file instanceof Error)) ||
          apiFiles instanceof Error
        ) {
          this.errorSubmitMessage = this.wrongFilesErrorText;
          this.toastr.error(this.wrongFilesErrorText);

          return throwError(() => new Error(this.wrongFilesErrorText));
        }

        return of(apiFiles);
      }),
      map((apiFiles) =>
        PMFDDGRNewDataUpdateRequest.fromAppToApi(
          this.getFormValue(),
          this.getLandParcels(),
          this.getBuildings(),
          this.getPremises(),
          apiFiles as ApiNewDokumentPowiazanyDalDto[],
        ),
      ),
      catchError((err) => {
        console.error(err);

        return throwError(() => err);
      }),
    );
  };

  getWorkspaceStateToSave =
    (): PMFDDGRNewDataUpdateRequestFormWorkspaceState => ({
      formValue: this.getFormValue(),
      parcels: this.getLandParcels(),
      buildings: this.getBuildings(),
      premises: this.getPremises(),
    });

  handleLoadWorkspaceResponse(
    state: PMFDDGRNewDataUpdateRequestFormWorkspaceState,
  ): void {
    this.formGroup.patchValue(state.formValue);
    if (state.parcels?.length) {
      this.handleMapAction(
        new MapAction(
          MapObjectTableActionType.AddNew,
          [...state.parcels],
          this.parcelsMapObjectTableStateIndex,
        ),
      );
    }
    if (state.buildings?.length) {
      this.handleMapAction(
        new MapAction(
          MapObjectTableActionType.AddNew,
          [...state.buildings],
          this.buildingsMapObjectTableStateIndex,
        ),
      );
    }
    if (state.premises?.length) {
      this.handleMapAction(
        new MapAction(
          MapObjectTableActionType.AddNew,
          [...state.premises],
          this.premisesMapObjectTableStateIndex,
        ),
      );
    }
  }

  areMapObjectsChosen(): boolean {
    return !!(
      this.getLandParcels()?.length ||
      this.getBuildings()?.length ||
      this.getPremises()?.length
    );
  }

  getLandParcels(): EgibObject[] {
    return this.mapState.mapObjectTablesState[
      this.parcelsMapObjectTableStateIndex
    ].mapObjects as EgibObject[];
  }

  getBuildings(): EgibObject[] {
    return this.mapState.mapObjectTablesState[
      this.buildingsMapObjectTableStateIndex
    ].mapObjects as EgibObject[];
  }

  getPremises(): EgibObject[] {
    return this.mapState.mapObjectTablesState[
      this.premisesMapObjectTableStateIndex
    ].mapObjects as EgibObject[];
  }

  getFormValue(): PMFDDGRNewDataUpdateRequest {
    return this.formGroup.getRawValue();
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.PermanentManagementFeeDiscountDecisionGrantingRequest}/requests-list`,
    );
  }

  isReasonFormControlValid(): boolean {
    return this.getReasonFormControl().valid;
  }

  getReasonFormControl(): AbstractControl<string> {
    return this.formGroup.get(this.controlName.Reason);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
