import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  DictionaryField,
  DictionaryFieldFromApi,
} from '@gk/gk-modules';
import { BehaviorSubject, catchError, map } from 'rxjs';
import { handleApiError } from '../../../../services/services.utils';

@Injectable()
export class ExclusionPurposeService {
  exclusionPurposes = new BehaviorSubject<DictionaryField[]>([]);

  constructor(private httpClient: HttpClient) {
    this.fetchExclusionPurposesList();
  }

  fetchExclusionPurposesList(): void {
    this.httpClient
      .get<ApiResponse<DictionaryFieldFromApi[]>>(
        '/api/srodowisko/slo/wylgrunzprodrol/cel',
      )
      .pipe(
        map((data) => data.Response),
        map((exclusionPurposes) =>
          exclusionPurposes.map((exclusionPurpose) =>
            DictionaryField.fromApiToApp(exclusionPurpose),
          ),
        ),
        catchError(handleApiError),
      )
      .subscribe((data) => this.exclusionPurposes.next(data));
  }
}
