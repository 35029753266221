import { FormControl } from '@angular/forms';
import { FieldId } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum SFBCCNewRequestAdditionalControlName {
  ConstructionIntentionType = 'constructionIntentionType',
  PlannedStartDate = 'plannedStartDate',
}

export const SFBCCNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...SFBCCNewRequestAdditionalControlName,
};

export interface SFBCCNewRequestFormGroup extends LawPersonNewRequestFormGroup {
  [SFBCCNewRequestControlName.ConstructionIntentionType]: FormControl<
    FieldId[]
  >;
  [SFBCCNewRequestControlName.PlannedStartDate]: FormControl<NgbDate>;
}
