<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h2 class="mt-5 mb-3">
      {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.TITLE' | translate }}
    </h2>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.CLIENT_DATA' | translate }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLaneOccupancyPurposeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.LaneOccupancyPurpose">
            {{
              'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.OCCUPANCY_PURPOSE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.LaneOccupancyPurpose"
            [id]="controlName.LaneOccupancyPurpose"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.LaneOccupancyPurpose"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getLaneOccupancyPurposeFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLocationFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.LOCATION' | translate }}
        </div>

        <div class="card-body">
          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.RoadNumber">
              {{
                'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.ROAD_NUMBER' | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.RoadNumber"
              [id]="controlName.RoadNumber"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.RoadNumber"
            />

            @if (shouldShowRequiredAlert(getRoadNumberFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Place">
              {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.PLACE' | translate }}
            </label>

            <input
              type="text"
              [name]="controlName.Place"
              [id]="controlName.Place"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.Place"
              [ngbTypeahead]="searchPlace"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              (focusout)="setPlaceByName()"
            />

            @if (shouldShowRequiredAlert(getPlaceFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Street">
              {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.STREET' | translate }}
            </label>

            <input
              type="text"
              [name]="controlName.Street"
              [id]="controlName.Street"
              class="form-control form-control-sm"
              [ngbTypeahead]="searchStreet"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              [formControlName]="controlName.Street"
              (focusout)="setStreetByName()"
              [autocomplete]="undefined"
            />
          </div>
        </div>
      </div>
    </section>

    <ng-container [formArrayName]="controlName.LaneOccupancyArea">
      @for (
        laneOccupancyAreaFormGroup of getLaneOccupancyAreaFormArray().controls;
        track i;
        let i = $index
      ) {
        <section [formGroupName]="i">
          <div
            class="card mb-4"
            [ngClass]="
              !isLaneOccupancyAreaFormGroupValid(i) && submitted
                ? 'border-danger border-2'
                : 'border-dark'
            "
          >
            <div class="card-header text-white bg-dark">
              {{
                'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.AREA_TITLE' | translate
              }}
              #{{ i + 1 }}
              <button
                (click)="deleteLaneOccupancyAreaFormControl(i)"
                type="button"
                title="minus"
                class="btn btn-danger fa fa-minus float-end"
              ></button>
            </div>
            <div class="card-body">
              <gk-map
                [mapState]="getLaneOccupancyAreaMapStateFormControl(i).value"
                (dispatch)="handleLaneOccupancyAreaMapAction($event, i)"
              ></gk-map>
              <div class="my-2">
                @if (
                  getLaneOccupancyMapObjectsTableState(i).mapObjects.length
                ) {
                  <gk-map-object-table
                    [mapState]="
                      getLaneOccupancyAreaMapStateFormControl(i).value
                    "
                    [mapObjectTableStateIndex]="0"
                    (dispatch)="handleLaneOccupancyAreaMapAction($event, i)"
                  ></gk-map-object-table>
                }
              </div>
              @if (getLaneOccupancyAreaCalculatedAreaFormControl(i).value) {
                <div class="mb-2 row">
                  <div class="col-auto pe-0">
                    <label class="text-nowrap" for="calculated-area">
                      {{
                        'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.CALCULATED_AREA_LABEL'
                          | translate
                      }}
                    </label>
                  </div>
                  <div class="col-auto ps-0">
                    <div class="input-group input-group-sm ps-2">
                      <input
                        type="text"
                        name="calculated-area"
                        [defaultValue]="
                          getLaneOccupancyAreaCalculatedAreaFormControl(i).value
                        "
                        id="calculated-area"
                        class="form-control"
                        readonly
                      />
                      <span class="input-group-text">m²</span>
                    </div>
                  </div>
                </div>
              }
              <div class="mb-2">
                <label
                  class="text-nowrap"
                  [attr.for]="laneOccupancyAreaControlName.RoadWidth + '-' + i"
                >
                  {{
                    'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.OCCUPANCY_WIDTH'
                      | translate
                  }}
                </label>
                <select
                  [attr.id]="laneOccupancyAreaControlName.RoadWidth + '-' + i"
                  [title]="
                    'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.OCCUPANCY_WIDTH'
                      | translate
                  "
                  [name]="laneOccupancyAreaControlName.RoadWidth + '-' + i"
                  [formControlName]="laneOccupancyAreaControlName.RoadWidth"
                  class="form-select form-select-sm required"
                  aria-required="true"
                >
                  <option
                    [ngValue]="laneOccupancyAreaWidth.LessThan20PercentWidth"
                  >
                    {{
                      'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.ROAD_LESS_THAN_20_PERCENT_WIDTH'
                        | translate
                    }}
                  </option>
                  <option
                    [ngValue]="
                      laneOccupancyAreaWidth.Between20And50PercentWidth
                    "
                  >
                    {{
                      'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.ROAD_BETWEEN_20_AND_50_PERCENT_WIDTH'
                        | translate
                    }}
                  </option>
                  <option
                    [ngValue]="laneOccupancyAreaWidth.MoreThan50PercentWidth"
                  >
                    {{
                      'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.ROAD_MORE_THAN_50_PERCENT_WIDTH'
                        | translate
                    }}
                  </option>
                </select>
                @if (shouldShowRequiredAlert(getRoadWidthFormControl(i))) {
                  <div class="alert alert-danger">
                    {{ 'FIELD_REQUIRED' | translate }}
                  </div>
                }
              </div>
              <div class="mb-2">
                <label [attr.for]="laneOccupancyAreaControlName.Road + '-' + i">
                  {{
                    'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.ROAD_AREA' | translate
                  }}
                </label>
                <div class="input-group input-group-sm">
                  <input
                    type="number"
                    [name]="laneOccupancyAreaControlName.Road + '-' + i"
                    [id]="laneOccupancyAreaControlName.Road + '-' + i"
                    class="form-control required"
                    aria-required="true"
                    [formControlName]="laneOccupancyAreaControlName.Road"
                  />
                  <span class="input-group-text">m²</span>
                </div>
                @if (
                  shouldShowRequiredAlert(getLaneOccupancyAreaFormControl(i))
                ) {
                  <div class="alert alert-danger">
                    {{ 'FIELD_REQUIRED' | translate }}
                  </div>
                }
              </div>
              <div class="mb-2">
                <label
                  [attr.for]="
                    laneOccupancyAreaControlName.RoadsideComponents + '-' + i
                  "
                >
                  {{
                    'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.ROADSIDE_COMPONENTS_AREA'
                      | translate
                  }}
                </label>
                <div class="input-group input-group-sm">
                  <input
                    type="number"
                    [name]="
                      laneOccupancyAreaControlName.RoadsideComponents + '-' + i
                    "
                    [id]="
                      laneOccupancyAreaControlName.RoadsideComponents + '-' + i
                    "
                    class="form-control"
                    [formControlName]="
                      laneOccupancyAreaControlName.RoadsideComponents
                    "
                  />
                  <span class="input-group-text">m²</span>
                </div>
                @if (
                  shouldShowRequiredAlert(
                    getLaneOccupancyRoadsideComponentsAreaFormControl(i)
                  )
                ) {
                  <div class="alert alert-danger">
                    {{ 'FIELD_REQUIRED' | translate }}
                  </div>
                }
              </div>
              <div class="mb-2">
                <label
                  [attr.for]="
                    laneOccupancyAreaControlName.RoadLaneOtherElements + '-' + i
                  "
                >
                  {{
                    'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.ROAD_LANE_OTHER_ELEMENTS'
                      | translate
                  }}
                </label>
                <div class="input-group input-group-sm">
                  <input
                    type="number"
                    [name]="
                      laneOccupancyAreaControlName.RoadLaneOtherElements +
                      '-' +
                      i
                    "
                    [id]="
                      laneOccupancyAreaControlName.RoadLaneOtherElements +
                      '-' +
                      i
                    "
                    class="form-control"
                    [formControlName]="
                      laneOccupancyAreaControlName.RoadLaneOtherElements
                    "
                  />
                  <span class="input-group-text">m²</span>
                </div>
                @if (
                  shouldShowRequiredAlert(
                    getLaneOccupancyRoadLaneOtherElementsAreaFormControl(i)
                  )
                ) {
                  <div class="alert alert-danger">
                    {{ 'FIELD_REQUIRED' | translate }}
                  </div>
                }
              </div>
              <div class="mb-2">
                <label class="mb-0">
                  {{
                    'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.LANE_OCCUPANCY_DATE'
                      | translate
                  }}
                </label>
                <app-date-range-picker
                  (dateRangeChanged)="updateDateRangeFormControl($event, i)"
                  [required]="true"
                ></app-date-range-picker>
                @if (isDateRangeInvalid(i) && submitted) {
                  <div class="alert alert-danger">
                    {{ 'PERIOD_INVALID' | translate }}
                  </div>
                }
              </div>
            </div>
            @if (submitted && !isSomeLaneOccupancyIssueRangeChosen(i)) {
              <div class="card-footer">
                <div class="alert alert-danger mt-2">
                  {{
                    'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.ISSUE_RANGE_REQUIRED'
                      | translate
                  }}
                </div>
              </div>
            }
          </div>
        </section>
      }
    </ng-container>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !areSomeLaneOccupancyAreaFormGroups() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.AREA_TITLE' | translate }}
        </div>

        <div class="card-body d-grid">
          <button
            (click)="addLaneOccupancyAreaFormControl()"
            type="button"
            title="plus"
            class="btn btn-lg btn-outline-dark fa fa-plus"
          ></button>
        </div>

        @if (!areSomeLaneOccupancyAreaFormGroups() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.OCCUPANCY_AREA_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <gk-attachments [portalId]="portalId" [submitted]="submitted">
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          {{
            'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.PERSONAL_DATA_PROCESING_LABEL'
              | translate
          }}
        </div>

        <div class="card-body">
          <P
            [innerHTML]="
              'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.PERSONAL_DATA_PROCESING'
                | translate
            "
          >
          </P>
        </div>
      </div>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS.SUBMIT' | translate }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
