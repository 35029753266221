import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ColumnHeader,
  defaultMapGeometryStyles,
  initialToolsState,
  MapAction,
  MapId,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
} from '@gk/gk-modules';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-designer-map',
  templateUrl: './designer-map.component.html',
  standalone: false,
})
export class DesignerMapComponent implements OnInit, OnDestroy {
  private isAlive = true;
  designerMapState = MapState.getInitialStruct();
  initialToolsState = _.cloneDeep(initialToolsState);
  defaultMapGeometryStyles = _.cloneDeep(defaultMapGeometryStyles);
  pendingMapActions: MapAction[] = [];

  constructor(
    private mapSettingsService: MapSettingsService,
    private mapStateService: MapStateService,
  ) {}

  ngOnInit(): void {
    this.subscribeToMapSettings();
    this.subscribeToPendingMapActions();
  }

  subscribeToMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Designer)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((designerMapSettings) => {
        this.setDesignerMapState(designerMapSettings);
        this.handlePendingMapActions(this.pendingMapActions);
      });
  }

  subscribeToPendingMapActions(): void {
    this.mapSettingsService.pendingMapActions
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (pendingMapActions) => (this.pendingMapActions = pendingMapActions),
      );
  }

  setDesignerMapState(designerMapSettings: MapSettings): void {
    this.designerMapState = new MapState(
      MapId.Designer,
      this.mapStateService.getViewState(MapId.Designer, designerMapSettings),
      this.mapStateService.getToolbarState([], designerMapSettings.papers),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.Designer,
        designerMapSettings,
        MapPortalName.Designer,
      ),
      [
        new MapObjectTableState(
          [new ColumnHeader('typeName', 'GK.MAP.TYPE', true)],
          undefined,
          this.defaultMapGeometryStyles,
          false,
          false,
          true,
        ),
      ],
    );
  }

  handleMapAction(mapAction: MapAction): void {
    this.designerMapState = this.mapStateService.getUpdatedMapState(
      mapAction,
      this.designerMapState,
    );
  }

  handlePendingMapActions(pendingMapActions: MapAction[]): void {
    pendingMapActions.forEach((pendingMapAction) =>
      this.handleMapAction(pendingMapAction),
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
