import { FormControl, FormGroup } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum BOPUCNewRequestAdditionalControlName {
  CurrentUse = 'currentUse',
  IntendedUse = 'intendedUse',
  ConstructionWorksInformation = 'constructionWorksInformation',
}

export const BOPUCNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...BOPUCNewRequestAdditionalControlName,
};

export enum BOPUCConstructionWorksInformationControlName {
  ExecutionType = 'executionType',
  PlannedStartDate = 'plannedStartDate',
}

export interface BOPUCNewRequestFormGroup extends LawPersonNewRequestFormGroup {
  [BOPUCNewRequestControlName.CurrentUse]: FormControl<string>;
  [BOPUCNewRequestControlName.IntendedUse]: FormControl<string>;
  [BOPUCNewRequestControlName.ConstructionWorksInformation]: FormGroup<BOPUCConstructionWorksInformationFormGroup>;
}

export interface BOPUCConstructionWorksInformationFormGroup {
  [BOPUCConstructionWorksInformationControlName.ExecutionType]: FormControl<string>;
  [BOPUCConstructionWorksInformationControlName.PlannedStartDate]: FormControl<NgbDate>;
}
