import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPerson,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  DocumentationFormType,
  InvestorRequest,
  InvestorRequestDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../../utils/date-helper/date-helper.util';

export interface TSBPNewRequestDto extends InvestorRequestDto {
  InformacjaZgloszenie: string;
  DataZgloszenia: string;
  TerminRozpoczeciaRobot: string;
}

export class TSBPRequestInformation {
  constructor(
    public worksType: string,
    public requestDate: NgbDate,
  ) {}
}

export class TSBPNewRequest extends InvestorRequest {
  constructor(
    lawPerson: LawPerson,
    contactData: LawPerson,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public requestInformation: TSBPRequestInformation,
    public worksStartDate: NgbDate,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
  ) {
    super(
      lawPerson,
      contactData,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static fromAppToApi(
    data: TSBPNewRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): TSBPNewRequestDto {
    return {
      ...InvestorRequest.mapCommonFields({
        data,
        parcels,
        files,
      }),
      InformacjaZgloszenie: data.requestInformation.worksType,
      DataZgloszenia: DateHelperUtil.ngbDateToApiDateTime(
        data.requestInformation.requestDate,
      ),
      TerminRozpoczeciaRobot: DateHelperUtil.ngbDateToApiDateTime(
        data.worksStartDate,
      ),
    };
  }
}
