import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EgibObject } from '@gk/gk-modules';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ExtractCertificateFeeRequest,
  ExtractCertificateFeeRequestDto,
  ExtractCertificateFeeResponse,
  ExtractCertificateFeeResponseDto,
} from './extract-certificate-fee.model';

@Injectable()
export class ExtractCertificateFeeService {
  constructor(private httpClient: HttpClient) {}

  calculatePayment(
    formValue: ExtractCertificateFeeRequest,
    parcels: EgibObject[],
  ): Observable<ExtractCertificateFeeResponse> {
    return this.httpClient
      .post<ExtractCertificateFeeResponseDto>(
        '/api/interesant/planowanie/wypisWyrys/calculate/oplata',
        ExtractCertificateFeeRequestDto.fromAppToApi(formValue, parcels),
      )
      .pipe(
        map((calculatedPaymentFromApi) =>
          ExtractCertificateFeeResponse.fromAppToApi(calculatedPaymentFromApi),
        ),
      );
  }
}
