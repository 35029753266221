import { LayerImageComponent } from 'ng-openlayers';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import Map from 'ol/Map';
import { Layer } from 'ol/layer';
import ImageLayer from 'ol/layer/Image';
import { ImageWMS, TileWMS } from 'ol/source';
import { MapControl } from '../../../../controls';
import {
  ProjectionCode,
  ProjectionSource,
  TreeNode,
  WmsLayerOptions,
  WmsLayerParams,
  WmsLayerType,
} from '../../../../models';

@Component({
  selector: 'gk-wms-layers',
  templateUrl: './wms-layers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WmsLayersComponent
  extends MapControl
  implements OnChanges, AfterViewInit
{
  @Input() mapInstance: Map;
  @Output() shouldLoadGeometryLayers = new EventEmitter<boolean>();
  wmsLayerTypeEnum = WmsLayerType;
  @ViewChild('layerImage') layerImageComponent: LayerImageComponent;

  ngOnChanges(simpleChanges: SimpleChanges): void {
    const mapStateChange = simpleChanges['mapState'] as SimpleChange;
    this.updateEachExternalWmsLayerParams(
      mapStateChange.currentValue.layersState.treeNode,
    );
    if (this.shouldUpdateWmsLayerParams(mapStateChange)) {
      this.updateEachWmsLayerParams(
        mapStateChange.currentValue.layersState.treeNode,
      );
    }
  }

  ngAfterViewInit(): void {
    this.updateEachWmsLayerParams(this.mapState.layersState.treeNode);
    this.shouldLoadGeometryLayers.emit(true);
  }

  shouldUpdateWmsLayerParams(mapStateChange: SimpleChange): boolean {
    return (
      !mapStateChange.firstChange &&
      !_.isEqual(
        mapStateChange.previousValue.layersState.treeNode,
        mapStateChange.currentValue.layersState.treeNode,
      )
    );
  }

  updateEachWmsLayerParams(currentTreeNode: TreeNode): void {
    this.mapState.layersState.wmsLayersOptions.forEach((wmsOption, index) =>
      this.updateWmsLayerParams(
        this.getNewWmsLayerParams(wmsOption, currentTreeNode),
        index,
      ),
    );
  }

  updateEachExternalWmsLayerParams(currentTreeNode: TreeNode): void {
    this.mapState?.layersState?.wmsLayersOptions
      .filter((wmsOption) => wmsOption.isExternal)
      .forEach((wmsOption) => {
        this.updateExternalWmsLayerParams(wmsOption, currentTreeNode);
      });
  }

  updateExternalWmsLayerParams(
    wmsOption: WmsLayerOptions,
    treeNode: TreeNode,
  ): void {
    const shouldBeVisible = TreeNode.getSelectedLeafNames(treeNode).includes(
      wmsOption.params.LAYERS,
    );
    wmsOption.externalShouldBeVisible = shouldBeVisible;
  }

  getNewWmsLayerParams(
    wmsOption: WmsLayerOptions,
    treeNode: TreeNode,
  ): WmsLayerParams {
    return {
      ...wmsOption.params,
      LAYERS: this.getMatchingLayersNamesString(
        TreeNode.getSelectedLeafNames(treeNode),
        wmsOption.baseLayers.split(','),
      ),
    };
  }

  getMatchingLayersNamesString(
    namesFromTreeNode: string[],
    namesFromWmsOptions: string[],
  ): string {
    return _.intersection(namesFromTreeNode, namesFromWmsOptions).toString();
  }

  updateWmsLayerParams(params: WmsLayerParams, index: number): void {
    const currentWmsLayer = this.mapInstance.getLayers().item(index) as Layer;
    (currentWmsLayer.getSource() as ImageWMS | TileWMS)?.updateParams(params);
  }

  getProjection(index: number): ProjectionCode {
    switch (
      this.mapState.layersState.wmsLayersOptions[index].projectionSource
    ) {
      case ProjectionSource.Native:
        return this.mapState.viewState.nativeProjectionCode;
      default:
        return ProjectionCode.PseudoMercator;
    }
  }

  getOverviewmapLayers(): ImageLayer<any>[] | undefined {
    const imageWmsSource = this.layerImageComponent?.instance?.getSource();
    if (!imageWmsSource) {
      return undefined;
    }

    return [
      new ImageLayer({
        source: imageWmsSource,
      }),
    ];
  }
}
