import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  ValidatePostalCode,
  emailValidator,
  getDefaultControlValue,
  streetFromDictionaryValidator,
} from '@gk/gk-modules';
import { ShippingFormControlName } from '../../shared/shipping-form/shipping-form.model';

@Injectable()
export class ShippingFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(
    disableEmail = false,
    primitivePostalCode?: boolean,
  ): UntypedFormGroup {
    return this.formBuilder.group({
      [ShippingFormControlName.ShippingForm]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [ShippingFormControlName.NameSurname]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [ShippingFormControlName.IsOtherAddress]: false,
      [LawPersonControlName.Address]: this.formBuilder.group(
        {
          [LawPersonAddressControlName.Place]: [
            getDefaultControlValue(),
            Validators.required,
          ],
          [LawPersonAddressControlName.Street]: [
            getDefaultControlValue(),
            Validators.required,
          ],
          [LawPersonAddressControlName.StreetAbsence]: false,
          [LawPersonAddressControlName.BuildingNumber]: [
            getDefaultControlValue(),
            Validators.required,
          ],
          [LawPersonAddressControlName.LocalNumber]: [getDefaultControlValue()],
          [LawPersonAddressControlName.PostalCode]: [
            getDefaultControlValue(),
            [
              Validators.required,
              primitivePostalCode ? ValidatePostalCode : null,
            ].filter(Boolean),
          ],
          [LawPersonAddressControlName.PostOffice]: [getDefaultControlValue()],
        },
        { validator: streetFromDictionaryValidator() },
      ),
      [ShippingFormControlName.Email]: [
        getDefaultControlValue(disableEmail),
        [Validators.required, emailValidator],
      ],
      [ShippingFormControlName.FtpAddress]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
    });
  }
}
