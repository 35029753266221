import { District, DistrictFromApi } from '@gk/gk-modules';

export interface CommunityFromApi {
  Id: number;
  Nazwa: string;
  Urzad: string;
  Gus: string;
  MiastoId: number;
  Miasto: string;
  MiastoGus: string;
  Obreby: DistrictFromApi[];
}

export class Community {
  constructor(
    public id: number,
    public name: string,
    public districts: District[],
  ) {}

  static fromApiToApp(communityFromApi: CommunityFromApi): Community {
    return new this(
      communityFromApi.Id,
      communityFromApi.Nazwa,
      communityFromApi.Obreby.map((districtFromApi) =>
        District.fromApiToApp(districtFromApi),
      ),
    );
  }
}

export interface ResponseCommunityFromApi {
  UpdateNeeded: boolean;
  Count: number;
  Timestamp: string;
  Response: CommunityFromApi[];
}

export class SelectOption {
  constructor(
    public id: number | string,
    public name: string,
  ) {}
}
