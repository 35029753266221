import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { FormBaseComponent } from '../../form-base/form-base.component';
import { CheckboxFieldConfig } from '../../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  standalone: false,
})
export class FormCheckboxComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  override config: CheckboxFieldConfig;

  ngOnInit(): void {
    this.maybeSubscribeToChanges();
  }

  maybeSubscribeToChanges(): void {
    if (!this.config.toggleRequiredControlName) {
      return;
    }

    this.getControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => this.handleValueChange(value));
  }

  handleValueChange(value: boolean): void {
    const togglingControl = this.getControlToToggleRequired();
    if (value) {
      togglingControl.setValidators(
        Validators.compose([...this.config.validators, Validators.required]),
      );
    } else {
      togglingControl.setValidators(this.config.validators);
    }
    togglingControl.updateValueAndValidity();
  }

  getControlToToggleRequired(): AbstractControl {
    return this.group.get(this.config.toggleRequiredControlName);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
