import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  getDefaultControlValue,
  streetFromDictionaryValidator,
} from '@gk/gk-modules';
import { ECSControlName } from '../../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../../shared/base-new-request-form/base-new-request-form.service';
import { DNNewRequestControlName } from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class DNNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        ...this.baseNewRequestFormService.getCommonInvestorFormGroup({
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        }).controls,
        ...this.baseNewRequestFormService.getAddressFormGroup().controls,
        [DNNewRequestControlName.TestimonialCheckbox]: [
          getDefaultControlValue(),
        ],
        [DNNewRequestControlName.BuildingNameAndType]: [
          getDefaultControlValue(),
          [Validators.required],
        ],
        [DNNewRequestControlName.Description]: [
          getDefaultControlValue(),
          [Validators.required],
        ],
        [DNNewRequestControlName.DemolitionWorksCommencementDate]: [
          getDefaultControlValue(),
          [Validators.required],
        ],
      },
      { validator: streetFromDictionaryValidator() },
    );
  }
}
