import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPerson,
  LawPersonAddress,
  getValueWhenPropertyIsAddressFields,
  getValueWhenPropertyIsString,
} from '@gk/gk-modules';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export interface CPPNewRequestDto extends ApplicantRequestDto {
  DaneLokalu: PremisesAddressRequestDto;
  InfoDodatkowe: string;
}

export class CPPNewRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    public premisesAddress: LawPersonAddress,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public comments: string,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: CPPNewRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): CPPNewRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        parcels,
        files,
      }),
      DaneLokalu: PremisesAddressRequestDto.fromApiToApp(data.premisesAddress),
      InfoDodatkowe: data.comments,
    };
  }
}

export class PremisesAddressRequestDto {
  constructor(
    public MiejscId: string | number,
    public Miejsc: string,
    public UlicaId: string | number,
    public Ulica: string,
    public KodId: string | number,
    public Kod: string,
    public Numer: string,
    public Numer2: string,
  ) {}

  static fromApiToApp(data: LawPersonAddress): PremisesAddressRequestDto {
    return new this(
      getValueWhenPropertyIsAddressFields(data.place),
      getValueWhenPropertyIsString(data.place),
      getValueWhenPropertyIsAddressFields(data.street),
      getValueWhenPropertyIsString(data.street),
      getValueWhenPropertyIsAddressFields(data.postalCode),
      getValueWhenPropertyIsString(data.postalCode),
      data.buildingNumber,
      data.localNumber,
    );
  }
}
