import { Component } from '@angular/core';
import { WrapCellConfig } from '../../../../gk-components/table/table.model';
import { Control } from '../control';
@Component({
  selector: 'gk-control-text',
  templateUrl: './control-text.component.html',
  standalone: false,
})
export class ControlTextComponent extends Control<string> {
  public isControlValueString(): boolean {
    return typeof this.getValue() === 'string';
  }

  public getWrapContentConfig(): WrapCellConfig {
    return this.controlConfig.wrapContentConfig;
  }

  public isWrappingEnabled(): boolean {
    return this.getWrapContentConfig().wrapContent;
  }

  public getCharactersLimitUntilWrap(): number {
    return this.getWrapContentConfig().characterLimitUntilWrap;
  }

  public getSliceCount(): number {
    return this.isWrappingEnabled()
      ? this.getCharactersLimitUntilWrap()
      : this.getValue().length;
  }

  public isValueExceedingCharacterLimit(): boolean {
    return this.getValue().length > this.getCharactersLimitUntilWrap();
  }

  public isTooltipEnabled(): boolean {
    return (
      this.getValue() &&
      this.isWrappingEnabled() &&
      this.isValueExceedingCharacterLimit()
    );
  }
}
