import { BaseNewRequestControlName } from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum CLNewRequestAdditionalControlName {
  DecisionNumber = 'decisionNumber',
  DecisionDate = 'decisionDate',
  DecisionPublishedBy = 'decisionPublishedBy',
  ConstructionLogType = 'constructionLogType',
}

export const CLNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...CLNewRequestAdditionalControlName,
};
