import { Extent } from 'ol/extent';
import WKT from 'ol/format/WKT';
import {
  Circle,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from 'ol/geom';
import Geometry from 'ol/geom/Geometry';
import { DrawEvent } from 'ol/interaction/Draw';
import { transformExtent } from 'ol/proj';
import { IDxf } from 'dxf-parser';
import {
  GeometryFormat,
  DxfEntityTypes,
  MapLineString,
  MapPoint,
  MapPolygon,
  OpenLayersGeometryType,
  ProjectionCode,
  Wkt,
  WktType,
  INVALID_WKT_GEOMETRY,
  GmlTags,
} from '../../models';
import { fromCircle } from 'ol/geom/Polygon';

export class ConversionUtils {
  static getTransformedExtent(
    mapExtent: Extent,
    projectionFrom: ProjectionCode,
    projectionTo: ProjectionCode,
  ): Extent {
    return transformExtent(mapExtent, projectionFrom, projectionTo);
  }

  static getGeometryFromDrawing(
    drawing: DrawEvent,
    projectionFrom: ProjectionCode,
    projectionTo: ProjectionCode,
  ): Geometry {
    let geometry = drawing.feature.getGeometry();
    if (geometry.getType() === 'Circle' && geometry instanceof Circle) {
      geometry = fromCircle(geometry, 64);
    }

    return geometry.transform(projectionFrom, projectionTo);
  }

  static getGeometryFormat(geom: string): string {
    if (this.isDxf(geom)) {
      return GeometryFormat.Dxf;
    }

    if (this.isWkt(geom)) {
      return GeometryFormat.Wkt;
    }

    if (this.isGml(geom)) {
      return GeometryFormat.Gml;
    }

    return GeometryFormat.Txt;
  }

  private static isDxf(geom: string): boolean {
    const dxfPattern = /^\s*0\s*\n\s*SECTION\s*\n\s*2\s*\n\s*HEADER/;
    return dxfPattern.test(geom);
  }

  private static isWkt(geom: string): boolean {
    try {
      new WKT().readGeometry(geom);
      return true;
    } catch {
      return false;
    }
  }

  static isGml(geom: string): boolean {
    return /<gml:/.test(geom) || /xmlns/.test(geom);
  }

  static getGeometryFromWkt(wkt: Wkt): Geometry {
    return new WKT().readGeometry(wkt);
  }

  static getWktFromGeometry(geometry: Geometry): Wkt {
    return new WKT().writeGeometry(geometry, { decimals: 2 });
  }

  static getWktGeometryFromDxf(dxf: IDxf): Wkt {
    if (dxf.entities.length !== 1) {
      return INVALID_WKT_GEOMETRY;
    }

    const entity = dxf.entities[0] as any;

    if (
      (entity.type === DxfEntityTypes.POLYLINE ||
        entity.type === DxfEntityTypes.LWPOLYLINE) &&
      entity.shape
    ) {
      if (entity.vertices && entity.vertices.length > 0) {
        let coordinates = entity.vertices.map((vertex: any) => [
          vertex.x,
          vertex.y,
        ]);

        if (
          coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
          coordinates[0][1] !== coordinates[coordinates.length - 1][1]
        ) {
          coordinates = [...coordinates, [...coordinates[0]]];
        }

        return `POLYGON ((${coordinates.map((coord: [number, number]) => coord.join(' ')).join(', ')}))`;
      }
    }

    throw new Error(
      'Nie znaleziono wystarczającej liczby punktów do utworzenia poligonu',
    );
  }

  static getWktGeometryFromGml(gmlDoc: Document): Wkt {
    const polygons = this.getElementsByTagNameIgnoringNamespaces(
      gmlDoc,
      GmlTags.POLYGON,
    );
    if (polygons.length !== 1) {
      return INVALID_WKT_GEOMETRY;
    }

    const posList = polygons
      ? this.getElementsByTagNameIgnoringNamespaces(
          polygons[0],
          GmlTags.POSLIST,
        )[0]?.textContent
      : null;

    if (posList) {
      const coords = posList.trim().split(/\s+/).map(Number);
      const points = Array.from(
        { length: coords.length / 2 },
        (_, i) => `${coords[i * 2]} ${coords[i * 2 + 1]}`,
      );

      const closedPoints =
        points[0] !== points[points.length - 1]
          ? [...points, points[0]]
          : points;

      return `POLYGON((${closedPoints.join(', ')}))`;
    }

    return INVALID_WKT_GEOMETRY;
  }

  static getElementsByTagNameIgnoringNamespaces(
    xmlNode: Document | Element,
    tagName: string,
  ): Element[] {
    const allElements = Array.from(
      xmlNode.getElementsByTagName('*'),
    ) as Element[];

    return allElements.filter((element) => {
      const localName = element.localName || element.nodeName.split(':').pop();

      return localName === tagName;
    });
  }

  static getWktType(wktGeometry: Wkt): WktType {
    return ConversionUtils.getAlphabetCharacters<WktType>(wktGeometry);
  }

  static getAlphabetCharacters<T extends string>(string: string): T {
    return string.replace(/[^A-Za-z]/g, '') as T;
  }

  static convertStringWithNewLinesToArray(string: string): string[] {
    return string
      .split(/\r?\n/)
      .map((id) => id.trim())
      .filter(Boolean);
  }

  static getMapGeometryCollectionBasedOnWktType(
    wktGeometry: Wkt,
    wktType: WktType,
  ): MapPolygon[] | MapPoint[] | MapLineString[] | undefined {
    const normalizedWktType = (wktType as string).toUpperCase();
    switch (normalizedWktType) {
      case WktType.Point:
        return [
          (this.getGeometryFromWkt(wktGeometry) as Point).getCoordinates(),
        ];
      case WktType.LineString:
        return [
          (this.getGeometryFromWkt(wktGeometry) as LineString).getCoordinates(),
        ];
      case WktType.Polygon:
        return [
          (this.getGeometryFromWkt(wktGeometry) as Polygon).getCoordinates(),
        ];
      case WktType.MultiPoint:
        return (
          this.getGeometryFromWkt(wktGeometry) as MultiPoint
        ).getCoordinates();
      case WktType.MultiLineString:
        return (
          this.getGeometryFromWkt(wktGeometry) as MultiLineString
        ).getCoordinates();
      case WktType.MultiPolygon:
        return (this.getGeometryFromWkt(wktGeometry) as MultiPolygon)
          .getCoordinates()
          .map((polygon) => polygon[0]);
      default:
        return undefined;
        break;
    }
  }

  static convertGeoJSONToWkt(geometry: any): Wkt {
    const format = new WKT();
    let geom;

    switch (geometry.type) {
      case 'Point':
        geom = new Point(geometry.coordinates);
        break;
      case 'LineString':
        geom = new LineString(geometry.coordinates);
        break;
      case 'Polygon':
        geom = new Polygon([geometry.coordinates]);
        break;
      case 'MultiPoint':
        geom = new MultiPoint(geometry.coordinates);
        break;
      case 'MultiLineString':
        geom = new MultiLineString(geometry.coordinates);
        break;
      case 'MultiPolygon':
        geom = new MultiPolygon(geometry.coordinates);
        break;
      default:
        throw new Error(`Unsupported geometry type: ${geometry.type}`);
    }

    return format.writeGeometry(geom);
  }

  static getReducedGeometry(geometry: Geometry): Geometry {
    switch (geometry.getType()) {
      case OpenLayersGeometryType.Polygon:
        return new Polygon([(geometry as Polygon).getCoordinates()[0]]);
      case OpenLayersGeometryType.MultiPolygon:
        return new MultiPolygon([
          (geometry as MultiPolygon)
            .getCoordinates()
            .map((polygon) => polygon[0]),
        ]);
      default:
        return geometry;
    }
  }
}
