import { ZudSprawyResponseDto } from '../gk-dynamic-list/gk-dynamic-list.data.model';

export enum MessageAuthor {
  Odgik = 'Osrodek',
  CoordinationMeeting = 'Narada Koordynacyjna',
  DesignerPortal = 'Portal Projektanta',
  Surveyor = 'Geodeta',
  EService = 'E-usługa',
}

export interface CaseFromApi {
  Nazwa: string;
  Id: string;
}

export interface NewMessageDto {
  newMessage: string;
  recipient: string;
  uuid: string;
  portalId: number;
}

export interface MessageRecipientFromApi {
  Adresat: string;
  AdresatUuid: string;
  DataOdczytu: string;
  Odczytal: string;
}

export interface MessageFromApi {
  CzatId: number;
  Autor: MessageAuthor;
  DataD: string;
  DataOdczytu: string;
  Dodal: string;
  Komunikat: string;
  Odczytal: string;
  OdczytalId: number;
  Adresaci: Array<MessageRecipientFromApi>;
  Sprawa: CaseFromApi;
}

export interface RecipientFromApi {
  Nazwa: string;
  Id: string;
}

export interface SurveyorListItem {
  Id: number;
  IdPracy: string;
}

export interface EservicesListItem {
  DokId: number;
  Sygnatura: string;
}

export class Messages {
  constructor(
    public chatId: number,
    public author: MessageAuthor,
    public addDate: string,
    public readDate: string,
    public addPerson: string,
    public messageContent: string,
    public readPerson: string,
    public readPersonId: number,
    public recipients: Array<MessageRecipientFromApi>,
    public recipientsName: string,
    public readerString: string,
  ) {}

  static fromApiToApp(messageFromApi: MessageFromApi): Messages {
    return new this(
      messageFromApi.CzatId,
      messageFromApi.Autor,
      messageFromApi.DataD,
      messageFromApi.DataOdczytu,
      messageFromApi.Dodal,
      messageFromApi.Komunikat,
      messageFromApi.Odczytal,
      messageFromApi.OdczytalId,
      messageFromApi.Adresaci,
      this.recipientsToString(messageFromApi.Adresaci),
      this.readerToString(messageFromApi),
    );
  }

  static recipientsToString(
    recipients: Array<MessageRecipientFromApi>,
  ): string {
    return recipients.reduce(
      (acc: string, value: MessageRecipientFromApi) =>
        `${acc + value.Adresat} `,
      '',
    );
  }

  static readerToString(messageFromApi: MessageFromApi): string {
    let result = '';

    if (messageFromApi.DataOdczytu && messageFromApi.Odczytal) {
      result += `${messageFromApi.Odczytal} - ${this.convertDateString(
        messageFromApi.DataOdczytu,
      )}\n`;
    }
    messageFromApi.Adresaci.forEach((adresat) => {
      if (adresat.DataOdczytu && adresat.Odczytal) {
        result += `${adresat.Odczytal} - ${this.convertDateString(
          adresat.DataOdczytu,
        )}\n`;
      }
    });

    return result;
  }

  static convertDateString(inputDate: string): string {
    if (!inputDate) {
      return '';
    }
    const parts = inputDate.split('T');
    const datePart = parts[0];
    const timePart = parts[1].replace(/\.\d+/g, '');

    return `${datePart} ${timePart}`;
  }
}

export class Recipient {
  constructor(
    public name: string,
    public id: string,
  ) {}

  static fromApiToApp(recipientsFromApi: RecipientFromApi): Recipient {
    return new this(recipientsFromApi.Nazwa, recipientsFromApi.Id);
  }
}

export class CommunicatorListItem {
  constructor(
    public id: number | string,
    public caseSignature: string,
  ) {}

  static fromApiDesignerToApp(
    listItemFromApi: ZudSprawyResponseDto,
  ): CommunicatorListItem {
    return new this(listItemFromApi.Uuid, listItemFromApi.Sygnatura);
  }

  static fromApiSurveyorToApp(
    listItemFromApi: SurveyorListItem,
  ): CommunicatorListItem {
    return new this(listItemFromApi.Id, listItemFromApi.IdPracy);
  }

  static fromApiEServicesToApp(
    listItemFromApi: EservicesListItem,
  ): CommunicatorListItem {
    return new this(listItemFromApi.DokId, listItemFromApi.Sygnatura);
  }
}
