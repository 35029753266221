import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { FormBaseComponent } from '../../../form-base/form-base.component';
import { LandAndMortgageRegisterDepartmentFieldConfig } from '../../../gk-dynamic-form.model';
import { LandAndMortgageRegisterDepartment } from './services/land-and-mortgage-register/land-and-mortgage-register-department.model';
import { LandAndMortgageRegisterDepartmentService } from './services/land-and-mortgage-register/land-and-mortgage-register-department.service';

@Component({
  selector: 'gk-land-and-mortgage-register-department-select',
  templateUrl: './land-and-mortgage-register-department-select.component.html',
  standalone: false,
})
export class LandAndMortgageRegisterDepartmentSelectComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  override config: LandAndMortgageRegisterDepartmentFieldConfig;
  registers: LandAndMortgageRegisterDepartment[] = [];

  constructor(
    private landAndMortgageRegisterDepartmentService: LandAndMortgageRegisterDepartmentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.patchEmptyValue();
    this.subscribeToRegisters();
  }

  subscribeToRegisters(): void {
    this.landAndMortgageRegisterDepartmentService
      .getLandAndMortgageRegisterDepartments()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((registers) => (this.registers = registers));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
