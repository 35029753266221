import { ApiNewDokumentPowiazanyDalDto } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  AuthorizedPersonData,
  AuthorizedPersonDataRequestDto,
} from '../../../../../services/authorized-person-form/authorized-person-form.model';
import {
  ExtendedInvestorRequest,
  ExtendedInvestorRequestDto,
} from '../../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../../shared/investor-details/investor-details.model';
import { DateHelperUtil } from '../../../../../utils/date-helper/date-helper.util';

export enum ConstructionLogType {
  DB1 = 1,
  DB2 = 2,
  DB3 = 3,
  DB2_A = 4,
}

export interface CLNewRequestDto extends ExtendedInvestorRequestDto {
  DaneUpowaznionego: AuthorizedPersonDataRequestDto;
  NumerDecyzji: string;
  DataDecyzji: string;
  WydanaPrzez: string;
  RodzajDziennikaBudowy: ConstructionLogType;
}

export class CLNewRequest extends ExtendedInvestorRequest {
  constructor(
    investorDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
    proxyDetails: InvestorDetails,
    public authorizedDetailsCheckbox: boolean,
    public authorizedDetailsForm: AuthorizedPersonData,
    public decisionNumber: string,
    public decisionDate: NgbDate,
    public decisionPublishedBy: string,
    public constructionLogType: ConstructionLogType,
  ) {
    super(
      investorDetails,
      undefined,
      undefined,
      undefined,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static fromAppToApi(
    data: CLNewRequest,
    files: ApiNewDokumentPowiazanyDalDto[],
  ): CLNewRequestDto {
    return {
      ...ExtendedInvestorRequest.mapCommonFields({
        data,
        files,
      }),
      DaneUpowaznionego: AuthorizedPersonDataRequestDto.fromAppToApi(
        data.authorizedDetailsForm,
      ),
      NumerDecyzji: data.decisionNumber,
      DataDecyzji: DateHelperUtil.ngbDateToApiDateTime(data.decisionDate),
      WydanaPrzez: data.decisionPublishedBy,
      RodzajDziennikaBudowy: data.constructionLogType,
    };
  }
}
