<div class="container container-e-service-request">
  <app-request-title></app-request-title>

  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <section
      class="card mb-4"
      [ngClass]="
        !isInvestorDetailsFormGroupValid() && submitted
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark" tabindex="0">
        {{
          'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.APPLICANT_DETAILS.TITLE'
            | translate
        }}
      </div>

      <div class="card-body">
        <app-investor-details
          #investorDetailsComponent
          [investorDetailsFromGroup]="getInvestorDetailsFormGroup()"
          [isPeselVisible]="false"
          [idPrefix]="'applicant-details-'"
          [isIdVisible]="false"
          [isPhoneNumberRequired]="false"
          [wasFormValidated]="submitted"
          [legalPersonEnabled]="false"
        ></app-investor-details>
      </div>
    </section>

    <app-fee-waiver [parentForm]="getFeeWaiverFormGroup()"></app-fee-waiver>

    @if (isSocialWelfareCertificateVisible()) {
      <section
        class="card mb-4"
        [ngClass]="
          isSocialWelfareCertificateRequiredAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.SOCIAL_WELFARE_CERTIFICATE.TITLE'
              | translate
          }}
        </div>
        <div class="card-body">
          <gk-file-input
            class="mb-2 required bg-color-from-db-for-button-child"
            [showPreviews]="true"
            (filesChange)="
              handleFileInputAction(
                planningDepartmentDocTypeId.SocialWelfareCertificate,
                $event
              )
            "
          >
          </gk-file-input>
        </div>
      </section>
    }

    @if (isDismissalReasonExplanationVisible()) {
      <section
        class="card mb-4"
        [ngClass]="
          isDismissalReasonExplanationRequiredAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
        tabindex="0"
        aria-required="true"
      >
        <div class="card-header text-white bg-dark">
          {{
            'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.DISMISSAL_REASON_EXPLANATION.TITLE'
              | translate
          }}
        </div>
        <div class="card-body">
          <div class="mb-4">
            <textarea
              aria-required="true"
              [attr.placeholder]="
                'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.DISMISSAL_REASON_EXPLANATION.DESCRIPTION_PLACEHOLDER'
                  | translate
              "
              [name]="controlName.DismissalReasonExplanationDescription"
              [id]="controlName.DismissalReasonExplanationDescription"
              class="form-control required"
              [formControlName]="
                controlName.DismissalReasonExplanationDescription
              "
            ></textarea>
          </div>
          <gk-file-input
            class="mb-2 requiredbg-color-from-db-for-button-child"
            [showPreviews]="true"
            (filesChange)="
              handleFileInputAction(
                planningDepartmentDocTypeId.DismissalReasonExplanation,
                $event
              )
            "
          >
          </gk-file-input>
        </div>
      </section>
    }

    <section
      class="card mb-4"
      [ngClass]="
        checkParcelsNotChosenAndFormSubmitted()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark" tabindex="0">
        {{
          'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.CHOOSE_PARCELS.TITLE'
            | translate
        }}
      </div>

      <gk-map
        [mapState]="mapState"
        (dispatch)="handleMapAction($event)"
      ></gk-map>

      @if (mapState.mapObjectTablesState[0].mapObjects.length) {
        <gk-map-object-table
          class="m-2"
          [mapState]="mapState"
          [mapObjectTableStateIndex]="0"
          (dispatch)="handleMapAction($event)"
        ></gk-map-object-table>
      }

      @if (submitted && !areParcelsChosen()) {
        <div class="card-footer">
          <div class="alert alert-danger mt-2" tabindex="0">
            {{ 'PLANNING_DEPARTMENT.VALIDATION.PARCELS_REQUIRED' | translate }}
          </div>
        </div>
      }
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          isDescriptionFormControlInvalidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Comments">
            {{
              'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.COMMENTS.TITLE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.Comments"
            [id]="controlName.Comments"
            class="form-control"
            [formControlName]="controlName.Comments"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getDescriptionFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger" tabindex="0">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    @if (
      requestFormType ===
        planningDepartmentRequestTypeEnum.ExtractFromLocalSpatialDevelopmentPlan ||
      requestFormType === planningDepartmentRequestTypeEnum.ExtractFromStudy
    ) {
      <section>
        <div
          [formGroupName]="controlName.Subject"
          class="card mb-4"
          [ngClass]="
            isSubjectFormGroupInvalidAndFormSubmitted()
              ? 'border-danger border-2'
              : 'border-dark'
          "
        >
          <div class="card-header text-white bg-dark" tabindex="0">
            {{
              'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.SUBJECT.TITLE'
                | translate
            }}
          </div>
          <div class="card-body">
            <div class="form-check">
              <input
                type="checkbox"
                [id]="controlName.WrittenExtractCheckbox"
                [formControlName]="controlName.WrittenExtractCheckbox"
                class="form-check-input"
              />
              <label
                class="form-check-label"
                [attr.for]="controlName.WrittenExtractCheckbox"
              >
                {{
                  'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.SUBJECT.FIELDS.WRITTEN_EXTRACT'
                    | translate
                }}
              </label>
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                [id]="controlName.GraphicExtractCheckbox"
                [formControlName]="controlName.GraphicExtractCheckbox"
                class="form-check-input"
              />
              <label
                class="form-check-label"
                [attr.for]="controlName.GraphicExtractCheckbox"
              >
                {{
                  'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.SUBJECT.FIELDS.GRAPHIC_EXTRACT'
                    | translate
                }}
              </label>
            </div>
          </div>
          @if (shouldShowAtLeastOneRequiredAlert(getSubjectFormGroup())) {
            <div class="card-footer">
              <div class="alert alert-danger" tabindex="0">
                {{
                  'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.SUBJECT.VALIDATION.FIELD_REQUIRED'
                    | translate
                }}
              </div>
            </div>
          }
        </div>
      </section>
    }

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          isShippingFormGroupInvalidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark" tabindex="0">
          {{
            'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.SHIPPING.TITLE'
              | translate
          }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [idPrefix]="'shipping-form-'"
            [removeDisabledControls]="true"
            [portalId]="portalId"
            [primitivePostalCode]="true"
            [wasFormValidated]="submitted"
          ></app-shipping-form>
        </div>
        @if (isShippingFormGroupInvalidAndFormSubmitted()) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{
                'PLANNING_DEPARTMENT.VALIDATION.SHIPPING_FORM_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </section>
    }

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          isQuantityFormControlInvalidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Quantity">
            {{
              'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.QUANTITY.TITLE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <input
            [name]="controlName.Quantity"
            [id]="controlName.Quantity"
            class="form-control"
            [formControlName]="controlName.Quantity"
            [readonly]="quantityFormControlShouldBeReadonly"
            min="1"
            type="number"
          />
        </div>
      </div>
    </section>

    <app-authorized-person
      [submitted]="submitted"
      [parentForm]="getAuthorizedDetailsFormGroup()"
    ></app-authorized-person>

    @if (
      (settingsService.getHubSettings() | async)?.isPlanningDepartmentPriceShown
    ) {
      <section
        class="card mb-4"
        [ngClass]="
          isCalculatedPaymentInValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark" tabindex="0">
          {{ 'PLANNING_DEPARTMENT.PAYMENT' | translate }}
        </div>
        <div class="card-body">
          @if (calculatedPricePending) {
            <div class="col">
              <span>
                <i class="fa fa-cog fa-spin"></i>
                {{
                  'PLANNING_DEPARTMENT.CALCULATED_PAYMENT_PENDING_INFO'
                    | translate
                }}
              </span>
            </div>
          } @else {
            @if (!calculatedPriceErrorOccurred) {
              <div class="col">
                <div class="row">
                  <span>
                    <b>{{
                      'PLANNING_DEPARTMENT.CALCULATED_PAYMENT_AMOUNT'
                        | translate
                    }}</b>
                    {{ calculatedPrice | currency: 'PLN' }}
                  </span>
                </div>
                <div class="row">
                  <span>
                    <b>{{
                      'PLANNING_DEPARTMENT.CALCULATED_PAYMENT_INFO' | translate
                    }}</b>
                    {{ calculatedPriceResponseStatus }}
                  </span>
                </div>
              </div>
            } @else {
              <div
                class="alert alert-danger"
                role="alert"
                tabindex="0"
                [innerHTML]="calculatedPriceErrorResponseStatus"
              >
                {{ calculatedPriceErrorResponseStatus }}
              </div>
            }
          }
        </div>
      </section>
    }

    @if (
      (planningDepartmentConfigService.getPortalConfig() | async)?.departmentId
    ) {
      <section class="card mb-4">
        <div class="card-header text-white bg-dark">
          {{ 'RODO_CLAUSE' | translate }}
        </div>

        <div class="card-body">
          <gk-rodo-clause
            [departmentId]="
              (planningDepartmentConfigService.getPortalConfig() | async)
                ?.departmentId
            "
          ></gk-rodo-clause>

          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              [id]="controlName.RodoClauseAgreeCheckbox"
              [formControlName]="controlName.RodoClauseAgreeCheckbox"
            />

            <label
              class="form-check-label"
              [attr.for]="controlName.RodoClauseAgreeCheckbox"
            >
              {{ 'AGREE' | translate }}
            </label>
          </div>
        </div>
      </section>
    }

    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="!getRodoClauseAgreeCheckboxFormControl().value"
    >
      {{ 'PLANNING_DEPARTMENT.SUBMIT_REQUEST' | translate }}
    </button>

    <app-doc-sign-info
      [messageText]="docSignMsgTxt"
      [messageType]="docSignMsgType"
      [docSignUrl]="docSignUrl"
      [docSignPending]="docSignPending"
    ></app-doc-sign-info>

    <div class="mt-2"><app-form-legend></app-form-legend></div>
  </form>
</div>
