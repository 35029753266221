import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { FormBaseComponent } from '../../form-base/form-base.component';
import { StreetsTypeaheadFieldConfig } from '../../gk-dynamic-form.model';
import { Place } from '../../services/place/place.model';
import { Street } from '../../services/street/street.model';
import { StreetService } from '../../services/street/street.service';

@Component({
  selector: 'gk-form-streets-typeahead',
  styleUrls: ['./form-streets-typeahead.component.scss'],
  templateUrl: './form-streets-typeahead.component.html',
  standalone: false,
})
export class FormStreetsTypeaheadComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  override config: StreetsTypeaheadFieldConfig;
  miejscowoscId: number | string;
  model: Street | string;

  constructor(private streetService: StreetService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToPlace();
    this.getControl().disable();
  }

  getPlaceControl(): AbstractControl {
    return this.group.get('place');
  }

  subscribeToPlace(): void {
    this.getPlaceControl()
      .valueChanges.pipe(
        takeWhile(() => this.isAlive),
        debounceTime(200),
        distinctUntilChanged(),
      )
      .subscribe((value: Place | string) => this.handlePlaceChange(value));
  }

  handlePlaceChange(value: Place | string): void {
    if (_.isObject(value)) {
      this.miejscowoscId = _.get(value, 'id');
      this.getControl().enable();
    } else {
      this.getControl().disable();
    }
  }

  search = (text$: Observable<string>): Observable<Street[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        this.streetService.getStreets(term, this.miejscowoscId),
      ),
      tap((res) => {
        if (!res.length) {
          this.wrapCurrentModel();
        }
      }),
    );

  wrapCurrentModel(): void {
    const name = _.get<string | Street, string>(this.model, 'name');
    const newModel = new Street(null, !name && name !== '' ? this.model : name);

    this.getControl().setValue(newModel);
  }

  formatter = (dbModel: Street): string => dbModel.name;

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
