<div class="modal-header">
  <h4 class="modal-title">
    {{ 'GK.COMPONENTS.PAYMENT_OWNER_MODAL.PAYMENT' | translate }}
  </h4>
</div>
<div class="modal-body">
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label [attr.for]="formControlName.PaymentOwner">{{
            'GK.COMPONENTS.PAYMENT_OWNER_MODAL.CHOOSE_OWNER' | translate
          }}</mat-label>
          <mat-select
            #paymentOwner
            [id]="formControlName.PaymentOwner"
            [formControlName]="formControlName.PaymentOwner"
          >
            @for (
              owner of stampDutyService.getOwners(this.portalId) | async;
              track $index
            ) {
              <mat-option [value]="owner">{{ owner.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    @if (getNumberOfPaymentsFormControl()) {
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label [attr.for]="formControlName.NumberOfPayments">{{
              'GK.COMPONENTS.PAYMENT_OWNER_MODAL.PAYMENTS_NUMBER' | translate
            }}</mat-label>
            <input
              [id]="formControlName.NumberOfPayments"
              [formControlName]="formControlName.NumberOfPayments"
              matInput
              type="number"
              min="0"
              step="1"
              (keypress)="validateIntegerInput($event)"
            />
            <mat-error>{{ 'FIELD_REQUIRED' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    }

    @if (getPaymentAmountFormControl()) {
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label [attr.for]="formControlName.PaymentAmount">{{
              'GK.COMPONENTS.PAYMENT_OWNER_MODAL.PAYMENT_AMOUNT' | translate
            }}</mat-label>
            <input
              [id]="formControlName.PaymentAmount"
              [formControlName]="formControlName.PaymentAmount"
              matInput
              type="number"
              min="0"
              step="0.01"
              (keypress)="validateFloatInput($event)"
            />
            <mat-error>{{ 'FIELD_REQUIRED' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    }
  </form>

  @if (paymentOwner?.value?.accountNumber) {
    <div class="text-center">
      <p>
        {{ 'ACCOUNT_NUMBER' | translate }}:
        <strong class="text-break">
          <span>{{ paymentOwner?.value?.accountNumber }}</span>
        </strong>
      </p>
    </div>
  }
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="dismiss()">
    {{ 'GK.COMPONENTS.PAYMENT_OWNER_MODAL.CANCEL' | translate }}
  </button>

  <button type="button" class="btn btn-primary" (click)="close()">
    {{ 'GK.COMPONENTS.PAYMENT_OWNER_MODAL.GO_TO_PAYMENT' | translate }}
  </button>
</div>
