import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ngfModule } from 'angular-file';
import { FileSaverModule } from 'ngx-filesaver';
import { GkComponentsModule } from '../gk-components/gk-components.module';
import { PlaceService } from '../gk-dynamic-form/services/place/place.service';
import { StreetService } from '../gk-dynamic-form/services/street/street.service';
import { GkModalNewMessagesListComponent } from '../gk-modal-new-messages-list/gk-modal-new-messages-list.component';
import { GkPaymentsModule } from '../gk-payments/gk-payments.module';
import { ColumnManagerComponent } from './components/column-manager/column-manager.component';
import { ControlRendererComponent } from './components/control-renderer/control-renderer.component';
import {
  ControlAddRelatedDocumentComponent,
  ControlAreaComponent,
  ControlArrayComponent,
  ControlBoolComponent,
  ControlDateTimeComponent,
  ControlDefaultComponent,
  ControlDictComponent,
  ControlDocumentsListComponent,
  ControlFileComponent,
  ControlGetDataAndRenderComponent,
  ControlGetDataArrayAndRenderComponent,
  ControlInputFileComponent,
  ControlLinkComponent,
  ControlMapNavigateToMapObjectComponent,
  ControlModalComponent,
  ControlNestedAccordionComponent,
  ControlNumberComponent,
  ControlObjectComponent,
  ControlObjectDtoDetailsComponent,
  ControlObjectPositionComponent,
  ControlPaymentComponent,
  ControlPrintComponent,
  ControlSeparatorComponent,
  ControlStaticDictComponent,
  ControlStaticLinkComponent,
  ControlTableComponent,
  ControlTabstripBookmarkComponent,
  ControlTextComponent,
  ControlTextModalComponent,
  ControlTitleComponent,
  ControlUtilityInfrastructureComponent,
} from './components/controls';
import { AppActionLinkComponent } from './components/controls/app-action-link/app-action-link.component';
import { ControlCancelPaymentComponent } from './components/controls/control-cancel-payment/control-cancel-payment.component';
import { ControlCancelRequestComponent } from './components/controls/control-cancel-request/control-cancel-request.component';
import { ControlDynamicMapObjectComponent } from './components/controls/control-dynamic-map-object/control-dynamic-map-object.component';
import { ControlExpandibleTitleComponent } from './components/controls/control-expandible-title/control-expandible-title.component';
import { ControlFilterAndRenderComponent } from './components/controls/control-filter-and-render/control-filter-and-render.component';
import { ControlButtonComponent } from './components/controls/control-text-with-buttons/control-button/control-button.component';
import { ControlTextWithButtonsComponent } from './components/controls/control-text-with-buttons/control-text-with-buttons.component';
import { ControlTextWithCallbackButtonComponent } from './components/controls/control-text-with-callback-button/control-text-with-callback-button.component';
import { DynamicAccordionBodyComponent } from './components/dynamic-accordion-body/dynamic-accordion-body.component';
import { DynamicListComponent } from './components/dynamic-list/dynamic-list.component';
import { FilterCreatorComponent } from './components/filter-creator/filter-creator.component';
import { ListHeaderComponent } from './components/list-header/list-header.component';
import { NewMessagesComponent } from './components/new-messages/new-messages.component';
import { TranslatedLabelComponent } from './components/translated-label/translated-label.component';
import { ControlColumnDirective } from './directives/control-column.directive';
import { ControlLabelDirective } from './directives/control-label.directive';
import { ControlRowDirective } from './directives/control-row.directive';
import { ControlValueDirective } from './directives/control-value.directive';
import { TruncatedTextTooltipDirective } from './directives/truncated-text-tooltip.directive';
import { NgbDateCustomParserFormatter } from './ngb-date-custom-parser-formatter';
import { AddRelatedDocumentService } from './services/add-related-document/add-related-document.service';
import { ConfirmationDocumentService } from './services/confirmation-document/confirmation-document.service';
import { ControlCommunicationService } from './services/control-communication/control-communication.service';
import { DataService } from './services/data/data.service';
import { DictionaryService } from './services/dictionary/dictionary.service';
import { DomRefService } from './services/dom-ref/dom-ref.service';
import { DynamicListManagerService } from './services/dynamic-list-manager/dynamic-list-manager.service';
import { DynamicListService } from './services/dynamic-list/dynamic-list.service';
import { RequestService } from './services/request/request.service';
import { DocSignService } from './services/trusted-profile-sign/doc-sign.service';
import { WindowRefService } from './services/window-ref.service';
import { windowFactory } from './services/window/window.factory';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    TranslateModule,
    ngfModule,
    RouterModule.forChild([]),
    GkPaymentsModule,
    GkComponentsModule,
    FileSaverModule,
    TruncatedTextTooltipDirective,
  ],
  declarations: [
    ControlLabelDirective,
    ControlValueDirective,
    ControlRowDirective,
    ControlColumnDirective,
    DynamicAccordionBodyComponent,
    FilterCreatorComponent,
    ListHeaderComponent,
    NewMessagesComponent,
    GkModalNewMessagesListComponent,
    DynamicListComponent,
    ColumnManagerComponent,
    ControlRendererComponent,
    ControlDynamicMapObjectComponent,
    ControlDefaultComponent,
    ControlAddRelatedDocumentComponent,
    ControlTabstripBookmarkComponent,
    ControlNumberComponent,
    ControlTextComponent,
    ControlTextModalComponent,
    ControlBoolComponent,
    ControlLinkComponent,
    ControlStaticLinkComponent,
    ControlObjectComponent,
    ControlObjectDtoDetailsComponent,
    ControlDateTimeComponent,
    ControlDictComponent,
    ControlArrayComponent,
    ControlGetDataAndRenderComponent,
    ControlGetDataArrayAndRenderComponent,
    ControlFileComponent,
    ControlObjectPositionComponent,
    ControlDocumentsListComponent,
    ControlSeparatorComponent,
    ControlTitleComponent,
    ControlNestedAccordionComponent,
    ControlPaymentComponent,
    ControlStaticDictComponent,
    ControlTableComponent,
    ControlModalComponent,
    ControlMapNavigateToMapObjectComponent,
    ControlInputFileComponent,
    ControlUtilityInfrastructureComponent,
    ControlAreaComponent,
    ControlExpandibleTitleComponent,
    ControlFilterAndRenderComponent,
    ControlCancelPaymentComponent,
    ControlCancelRequestComponent,
    ControlTextWithButtonsComponent,
    ControlTextWithCallbackButtonComponent,
    ControlButtonComponent,
    TranslatedLabelComponent,
    AppActionLinkComponent,
    ControlPrintComponent,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    DynamicListService,
    DictionaryService,
    DataService,
    AddRelatedDocumentService,
    DomRefService,
    DynamicListManagerService,
    WindowRefService,
    ConfirmationDocumentService,
    ControlCommunicationService,
    DocSignService,
    StreetService,
    PlaceService,
    RequestService,
    { provide: 'window', useFactory: windowFactory },
  ],
  exports: [
    DynamicListComponent,
    RouterModule,
    ControlRendererComponent,
    ControlDynamicMapObjectComponent,
    GkModalNewMessagesListComponent,
  ],
})
export class GkDynamicListModule {}
