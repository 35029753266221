<form [formGroup]="formGroup" novalidate>
  <div class="form-check mb-3">
    <input
      type="checkbox"
      class="form-check-input"
      [id]="controlName.BusinessUsage"
      [formControlName]="controlName.BusinessUsage"
    />
    <label class="form-check-label" [attr.for]="controlName.BusinessUsage">{{
      'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.FOR_BUSINESS_PURPOSES'
        | translate
    }}</label>
  </div>

  <div *ngIf="formGroup.get(controlName.BusinessUsage)?.value" class="ms-3">
    <div class="form-check">
      <input
        type="radio"
        class="form-check-input"
        [id]="controlName.BusinessUsageFull"
        [formControlName]="controlName.BusinessUsageFull"
        [value]="true"
      />
      <label
        class="form-check-label"
        [attr.for]="controlName.BusinessUsageFull"
        >{{
          'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.FULL_USE' | translate
        }}</label
      >
    </div>

    <div class="form-check">
      <input
        type="radio"
        class="form-check-input"
        [id]="controlName.BusinessUsageFull + '_partial'"
        [formControlName]="controlName.BusinessUsageFull"
        [value]="false"
      />
      <label
        class="form-check-label"
        [attr.for]="controlName.BusinessUsageFull + '_partial'"
        >{{
          'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.PARTIAL_USE' | translate
        }}</label
      >
    </div>

    <div
      *ngIf="!formGroup.get(controlName.BusinessUsageFull)?.value"
      class="mt-2 row"
    >
      <div class="col-auto pe-0">
        <label class="text-nowrap" for="businessUsageShare">{{
          'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.SHARE_VALUE' | translate
        }}</label>
      </div>
      <div class="col-auto px-0">
        <div class="input-group input-group-sm ps-2">
          <input
            type="number"
            class="form-control"
            id="businessUsageShare"
            [formControlName]="controlName.BusinessUsageShare"
            min="0"
            max="100"
          />
          <span class="input-group-text">%</span>
        </div>
      </div>
      <div class="col-auto ps-0">
        <span class="ps-2">{{
          'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.SHARE_INFO' | translate
        }}</span>
      </div>
    </div>
  </div>
</form>
