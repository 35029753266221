import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FileSaverService } from 'ngx-filesaver';
import { takeWhile } from 'rxjs/operators';
import { getFileNameFromResponseHeaders } from '../../../../utils/utils';
import { FileControlConfig } from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-file',
  templateUrl: './control-file.component.html',
  styleUrls: ['./control-file.component.scss'],
  standalone: false,
})
export class ControlFileComponent extends Control<any> {
  @Input()
  override controlConfig: FileControlConfig;
  loading = false;
  errorMsg = '';

  constructor(
    protected override translateService: TranslateService,
    protected override dataService: DataService,
    private fileSaverService: FileSaverService,
  ) {
    super(translateService, dataService);
  }

  getFileNameAndExtension(headers?: HttpHeaders): string {
    return this.controlConfig.pathToFileNameAndExtension
      ? _.get(
          this.data,
          this.controlConfig.pathToFileNameAndExtension,
          'XYZ.pdf',
        )
      : getFileNameFromResponseHeaders(headers);
  }

  getFileDataFromResponse(response: any): Blob {
    return this.controlConfig.pathToFileData
      ? _.get(response, this.controlConfig.pathToFileData)
      : response;
  }

  getFile(): void {
    this.loading = true;

    this.dataService
      .getData(
        { ...this.controlConfig.requestConfig, observe: 'response' },
        this.data,
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (response) => {
          this.fileSaverService.save(
            this.getFileDataFromResponse(response.body),
            this.getFileNameAndExtension(response.headers),
          );
          this.loading = false;
        },
        error: (errorResponse) => {
          this.handleFailResponse(errorResponse);
        },
      });
  }

  resolveBlobErrorResponse(fileReader: FileReader): any {
    return JSON.parse(fileReader.result as string);
  }

  parseErrorBlob(err: Blob): Promise<string> {
    const fileReader = new FileReader();

    return new Promise((resolve) => {
      fileReader.readAsText(err);
      fileReader.onload = () =>
        resolve(this.resolveBlobErrorResponse(fileReader));
    });
  }

  async handleFailResponse(err: HttpErrorResponse): Promise<void> {
    this.errorMsg = _.get(
      await this.parseErrorBlob(err.error),
      'ResponseStatus.Message',
    );
    this.loading = false;
  }
}
