import { BaseNewRequestControlName } from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum LOBONewRequestAdditionalControlName {
  LaneOccupancyPurpose = 'laneOccupancyPurpose',
  RoadNumber = 'roadNumber',
  Place = 'locationPlace',
  Street = 'locationStreet',
  AreaWithoutRoadEngineeringFacilities = 'areaWithoutRoadEngineeringFacilities',
  LaneOccupancyDate = 'laneOccupancyDate',
}

export const LOBONewRequestControlName = {
  ...BaseNewRequestControlName,
  ...LOBONewRequestAdditionalControlName,
};
