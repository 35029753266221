import { FormControl, FormGroup } from '@angular/forms';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';
import { PropertyUsageFormGroup } from '../business-purposes-checkbox-control-form/business-purposes-checkbox-control-form.model';

export enum OCFCRNewRequestAdditionalControlName {
  Comments = 'comments',
  PropertyUsage = 'propertyUsage',
  DiscountConditions = 'discountConditions',
  ForHousingPurposes = 'forHousingPurposes',
}

export const OCFCRNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...OCFCRNewRequestAdditionalControlName,
};

export interface OCFCRNewRequestFormGroup extends LawPersonNewRequestFormGroup {
  [OCFCRNewRequestControlName.Comments]: FormControl<string>;
  [OCFCRNewRequestControlName.PropertyUsage]: FormGroup<PropertyUsageFormGroup>;
  [OCFCRNewRequestControlName.DiscountConditions]: FormControl<boolean>;
  [OCFCRNewRequestControlName.ForHousingPurposes]: FormControl<boolean>;
}
