import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiNewDokumentPowiazanyDalDto,
  AttachmentsComponent,
  DocSignService,
  FormAlertService,
  MapSettingsService,
  MapStateService,
  PortalId,
  PzService,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, switchMap, takeWhile, tap } from 'rxjs';
import { ProxyDetailsComponent } from '../../../components/proxy-details/proxy-details.component';
import { MainRoutes } from '../../../guards/guard.models';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import { DocsTableEditableComponent } from '../../designer-portal/new-designer-request/docs-table-editable/docs-table-editable.component';
import { FCNewRequestControlName } from './services/new-request-form/new-request-form.model';
import { FCNewRequestFormService } from './services/new-request-form/new-request-form.service';
import { FCNewRequest } from './services/new-request/new-request.model';

@Component({
  selector: 'app-fishing-card-request',
  templateUrl: './fishing-card-request.component.html',
  standalone: false,
})
export class FishingCardRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(DocsTableEditableComponent)
  docsTableEditableComponent: DocsTableEditableComponent;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  @ViewChild(ProxyDetailsComponent)
  proxyDetailsComponent: ProxyDetailsComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  override controlName = FCNewRequestControlName;
  formValue: FCNewRequest;
  formNotValidTranslation: string;
  override portalId = PortalId.EnvironmentalProtectionPortalFishingCardRequest;

  constructor(
    public fcNewRequestFormService: FCNewRequestFormService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public override formAlertService: FormAlertService,
    public override pzService: PzService,
    public override mapSettingsService: MapSettingsService,
    public override mapStateService: MapStateService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    super.subscribeToDocSignTranslations();
    this.subscribeToFormNotValidTranslation();
  }

  createForm(): void {
    this.formGroup = this.fcNewRequestFormService.getFormGroup();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.VALIDATION.FORM_NOT_VALID_ERROR',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation),
      );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    forkJoin([
      this.investorDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.requestReplyFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.proxyDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
    ])
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            FCNewRequest.fromAppToApi(this.getFormValue(), apiDocuments),
            '/api/interesant/wniosek/wydanieKartyWedkarskiej/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.EnvironmentalProtectionPortalFishingCardRequest}/requests-list`,
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid && this.attachmentsComponent.areDocumentsValid()
    );
  }

  getFormValue(): FCNewRequest {
    return this.formGroup.getRawValue();
  }

  getBirthDateFormControl(): UntypedFormControl {
    return this.formGroup.get(
      FCNewRequestControlName.BirthDate,
    ) as UntypedFormControl;
  }

  getBirthPlaceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      FCNewRequestControlName.BirthPlace,
    ) as UntypedFormControl;
  }

  checkInvestorDetailsFormGroupAndBirthPlaceDateFormControlsValidAndFormValidated(): boolean {
    return (
      (!this.getInvestorDetailsFormGroup().valid && this.submitted) ||
      (!this.getBirthPlaceFormControl().valid && this.submitted) ||
      (!this.getBirthDateFormControl().valid && this.submitted)
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
