import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPerson,
  Place,
  Street,
} from '@gk/gk-modules';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import {
  DateHelperUtil,
  NgbDateRange,
} from '../../../../utils/date-helper/date-helper.util';

export interface PLNewRequestDto extends ApplicantRequestDto {
  MiejscId: string | number;
  Miejsc: string;
  UlicaId: string | number;
  Ulica: string;
  CelDzierzawy: string;
  Powierzchnia: string;
  DzierzawaOd: string;
  DzierzawaDo: string;
  InfoDodatkowe: string;
}

export class PLNewRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    public purpose: string,
    public leaseDate: NgbDateRange,
    public place: Place,
    public street: Street,
    public areaToLease: string,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public comments: string,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: PLNewRequest,
    issueRanges: string[],
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): PLNewRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        issueRanges,
        parcels,
        files,
      }),
      MiejscId: data.place.id,
      Miejsc: data.place.name,
      UlicaId: data.street && data.street.id,
      Ulica: data.street && data.street.name,
      CelDzierzawy: data.purpose,
      Powierzchnia: data.areaToLease,
      DzierzawaOd: DateHelperUtil.ngbDateToApiDateTime(data.leaseDate.from),
      DzierzawaDo: DateHelperUtil.ngbDateToApiDateTime(data.leaseDate.to),
      InfoDodatkowe: data.comments,
    };
  }
}
