import {
  Component,
  forwardRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  providers: [
    {
      provide: ToolBarToolComponent,
      useExisting: forwardRef(() => GkToolbarTextComponent),
    },
  ],
  selector: 'gk-kendo-toolbar-text',
  template: `
    <ng-template #toolbarTemplate>
      <div class="ms-1" [innerHTML]="boldAfterColon(text)"></div>
    </ng-template>
    <ng-template #popupTemplate>
      <div class="ms-1" [innerHTML]="boldAfterColon(text)"></div>
    </ng-template>
  `,
  standalone: false,
})
export class GkToolbarTextComponent extends ToolBarToolComponent {
  @Input() text: string;

  @ViewChild('toolbarTemplate', { static: true })
  public override toolbarTemplate: TemplateRef<unknown>;

  @ViewChild('popupTemplate', { static: true })
  public override popupTemplate: TemplateRef<unknown>;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  boldAfterColon(text: string): SafeHtml {
    if (!text) {
      return '';
    }

    const parts = text.split(':');
    if (parts.length > 1) {
      parts[1] = `<b>${parts[1]}</b>`;
    }

    const result = parts.join(':');
    return this.sanitizer.bypassSecurityTrustHtml(result);
  }
}
