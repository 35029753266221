import { FormControl } from '@angular/forms';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum DPNewRequestAdditionalControlName {
  DemolitionInformation = 'demolitionInformation',
}

export const DPNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...DPNewRequestAdditionalControlName,
};

export interface DPNewRequestFormGroup extends LawPersonNewRequestFormGroup {
  [DPNewRequestControlName.DemolitionInformation]: FormControl<string>;
}
