import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import {
  SFB70CCNewRequestControlName,
  SFB70CCNewRequestFormGroup,
} from './new-request-form.model';

@Injectable()
export class SFB70CCNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): FormGroup<SFB70CCNewRequestFormGroup> {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [SFB70CCNewRequestControlName.ContactData]: true,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [SFB70CCNewRequestControlName.ConstructionIntentionType]: [
        [],
        [Validators.required],
      ],
      [SFB70CCNewRequestControlName.PlannedStartDate]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
    });
  }
}
