import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { FormBaseComponent } from '../../../form-base/form-base.component';
import { SectionFieldConfig } from '../../../gk-dynamic-form.model';
import { Section } from '../../../services/section/section.model';
import { SectionService } from '../../../services/section/section.service';

@Component({
  selector: 'gk-form-section',
  templateUrl: './section.component.html',
  standalone: false,
})
export class SectionComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  override config: SectionFieldConfig;
  sections: Section[] = [];

  constructor(private sectionService: SectionService) {
    super();
  }

  ngOnInit(): void {
    this.patchEmptyValue();
    this.subscribeToSections();
  }

  subscribeToSections(): void {
    this.sectionService.sections
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((sections) => (this.sections = sections));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
