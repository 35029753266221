import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { TableControlConfig } from '../../../gk-dynamic-list.model';
import { Control } from '../control';

@Component({
  selector: 'gk-control-table',
  templateUrl: './control-table.component.html',
  styleUrls: ['./control-table.component.scss'],
  standalone: false,
})
export class ControlTableComponent extends Control<any> {
  @Input() override controlConfig: TableControlConfig;

  getDataArray(): any[] {
    return _.get(this.data, this.controlConfig.pathToDataArray, []);
  }

  getTableClass(): string {
    return this.controlConfig.tableClass;
  }
}
