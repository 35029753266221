import { Directive, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  AddedDocToSignResponse,
  DictionaryField,
  DocKeyId,
  DocSign,
  DocSignService,
  DocSignStatus,
  FormAlertService,
  MapAction,
  MapComponent,
  MapObjectApiType,
  MapObjectTableActionType,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  MapViewActionType,
  PetentData,
  Place,
  PortalId,
  PzService,
  ShapeUtils,
  Street,
  StreetService,
  Wkt,
  defaultMapGeometryStyles,
  initialToolsStateWithButtonText,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FileSaverService } from 'ngx-filesaver';
import { WKT } from 'ol/format';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeWhile,
} from 'rxjs';
import { NewRequestModel } from '../../modules/free-services-portal/services/new-request/new-request.model';
import { NewRequestHelperService } from '../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../services/place/place.service';
import { BsMessageType } from '../../services/request-workspace-state/request-workspace-state.model';

@Directive()
export abstract class BaseNewRequestComponent implements OnInit {
  readonly initialToolsState = _.cloneDeep(initialToolsStateWithButtonText);
  readonly defaultMapGeometryStyles = _.cloneDeep(defaultMapGeometryStyles);
  isAlive = true;
  petentData = {} as PetentData;
  model: NewRequestModel;
  docSignUrl: SafeUrl = '';
  docSignMsgType: BsMessageType;
  docSignMsgTxt = '';
  docSignTranslations: Record<string, string> = {};
  docSignPending = false;
  wrongFilesErrorText: string;
  mapState = MapState.getInitialStruct();
  formGroup: UntypedFormGroup;
  controlName: any;
  submitted: boolean;
  portalId: PortalId;
  errorSubmitMessage = '';
  @ViewChild(MapComponent, { read: ElementRef }) mapComponent: ElementRef;

  constructor(
    protected pzService?: PzService,
    protected newRequestHelperService?: NewRequestHelperService,
    protected docSignService?: DocSignService,
    protected router?: Router,
    protected translateService?: TranslateService,
    protected mapSettingsService?: MapSettingsService,
    protected mapStateService?: MapStateService,
    protected formAlertService?: FormAlertService,
    protected placeService?: PlaceService,
    protected streetService?: StreetService,
    protected fileSaverService?: FileSaverService,
  ) {}

  ngOnInit(): void {
    this.subscribeToPetentData();
    this.subscribeToDocSignTranslations();
  }

  subscribeToPetentData(): void {
    this.pzService
      .getPetentData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((petentData) => (this.petentData = petentData));
  }

  subscribeToDocSignTranslations(): void {
    this.translateService
      .get('DOC_SIGN')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translations) => (this.docSignTranslations = translations));
  }

  fetchWrongFileText(): void {
    this.translateService
      .get('WRONG_FILES')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => (this.wrongFilesErrorText = text));
  }

  fetchApplicantMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Applicant)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.setMapState(mapSettings);
      });
  }

  protected setMapState?(mapSettings: MapSettings): void;

  showOnMap({
    geom,
    scrollToMap = false,
    scrollCallerElementRef,
  }: {
    geom: Wkt;
    scrollToMap: boolean;
    scrollCallerElementRef?: ElementRef;
  }): void {
    const mapObject = ShapeUtils.getAnyGeometryMapObject(
      geom,
      MapObjectApiType.ExtentOrPolygon,
    );
    const extent = new WKT().readGeometry(mapObject.geom).getExtent();
    this.handleMapAction(
      new MapAction(MapViewActionType.ExtentToFitToChange, extent),
    );
    this.handleMapAction(
      new MapAction(MapObjectTableActionType.Select, mapObject),
    );
    if (scrollToMap) {
      this.scrollToMap(scrollCallerElementRef);
    }
  }

  scrollToMap(elementRef: ElementRef): void {
    this.mapComponent.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
    this.handleMapAction(
      new MapAction(MapViewActionType.BackScrollIntoViewRefChange, elementRef),
    );
  }

  handleMapAction(mapAction: MapAction): void {
    this.mapState = this.mapStateService.getUpdatedMapState(
      mapAction,
      this.mapState,
    );
  }

  handleSendAndValidateFailure(error?: Error): void {
    if (error) {
      console.error(error);
    }
    this.setDocSignMsg(BsMessageType.Danger, 'SENDING_FAILED');
    this.docSignPending = false;
  }

  getShippingFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      this.controlName.ShippingForm,
    ) as UntypedFormGroup;
  }

  handleSendAndValidateSuccess(
    addedDocToSignResponse: AddedDocToSignResponse,
  ): void {
    this.docSignPending = true;
    this.docSignUrl = this.newRequestHelperService.sanitizeDocSignUrl(
      addedDocToSignResponse.docSigningUrl,
    );
    this.openSigningWindow(addedDocToSignResponse.docSigningUrl);
    this.waitForDocSign(addedDocToSignResponse.docKeyId);
  }

  openSigningWindow(windowUrl: SafeUrl): void {
    this.newRequestHelperService.openWindowWithSafeUrl(windowUrl);
  }

  waitForDocSign(docKeyId: DocKeyId): void {
    this.setDocSignMsg(BsMessageType.Info, 'WAITING_FOR_SIGN');

    this.docSignService
      .setDocSignStatusCheckInterval(docKeyId)
      .pipe(
        takeWhile(() => this.isAlive),
        distinctUntilChanged(_.isEqual),
      )
      .subscribe((docSignOrError: DocSign | Error) =>
        this.handleDocSignChange(docSignOrError),
      );
  }

  handleDocSignChange(docSignOrError: DocSign | Error): void {
    switch (_.get(docSignOrError, 'status')) {
      case DocSignStatus.Success:
        this.handleDocSignSuccess();
        break;
      case DocSignStatus.Pending:
        this.setDocSignMsg(BsMessageType.Info, 'WAITING_FOR_SIGN');
        break;
      case DocSignStatus.Canceled:
        this.setDocSignMsg(BsMessageType.Danger, 'SIGN_CANCELED');
        this.docSignPending = false;
        this.docSignUrl = '';
        break;
      case DocSignStatus.Failure:
      default:
        this.handleDocSignFailure();
        break;
    }
  }

  handleDocSignSuccess(): void {
    this.docSignUrl = '';
    this.router.navigateByUrl(
      this.router.url.replace('new-request', 'new-request-summary'),
    );
  }

  handleDocSignFailure(): void {
    this.setDocSignMsg(BsMessageType.Danger, 'SIGN_FAILED');
    this.docSignPending = false;
    this.docSignUrl = '';
  }

  setDocSignMsg(type: BsMessageType, translationKey: string): void {
    this.docSignMsgType = type;
    this.docSignMsgTxt = this.docSignTranslations[translationKey];
  }

  shouldShowRequiredAlert(formControl: AbstractControl): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      'required',
      this.submitted,
    );
  }

  searchPlace = ($term: Observable<string>): Observable<Place[]> =>
    $term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => this.placeService.getLocalPlacesByTerm(term)),
    );

  formatter = (dictField: DictionaryField): string => dictField.name;

  searchStreet = ($term: Observable<string>): Observable<Street[]> =>
    $term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        this.streetService.getStreets(term, this.getPlaceId()),
      ),
    );

  getPlaceId(): number | undefined {
    const place = this.getPlaceFormControl().value;

    return place && place.id;
  }

  setPlaceByName(): void {
    const formControl = this.getPlaceFormControl();
    if (!_.get(formControl.value, 'id') && _.isString(formControl.value)) {
      this.placeService
        .getLocalPlacesByTerm(formControl.value)
        .pipe(
          takeWhile(() => this.isAlive),
          takeWhile(
            () =>
              !_.get(formControl.value, 'id') && _.isString(formControl.value),
          ),
        )
        .subscribe((places) => {
          if (
            _.get(places, '[0].name', '').toUpperCase() ===
            formControl.value.toUpperCase()
          ) {
            formControl.setValue(places[0]);
          } else {
            formControl.setValue(
              new Place(undefined, formControl.value, undefined),
            );
          }
        });
    }
  }

  setStreetByName(): void {
    const formControl = this.getStreetFormControl();
    if (!_.get(formControl.value, 'id') && _.isString(formControl.value)) {
      this.streetService
        .getStreets(formControl.value, this.getPlaceId())
        .pipe(
          takeWhile(() => this.isAlive),
          takeWhile(
            () =>
              !_.get(formControl.value, 'id') && _.isString(formControl.value),
          ),
        )
        .subscribe((streets) => {
          if (
            _.get(streets, '[0].name', '').toUpperCase() ===
            formControl.value.toUpperCase()
          ) {
            formControl.setValue(streets[0]);
          } else {
            formControl.setValue(new Street(undefined, formControl.value));
          }
        });
    }
  }

  getStreetFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.Street);
  }

  getPlaceFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.Place);
  }

  isLawPersonFormGroupValid(): boolean {
    return this.getLawPersonFormGroup().valid;
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formGroup.get(this.controlName.LawPerson) as UntypedFormGroup;
  }

  isInvestorDetailsFormGroupValid(): boolean {
    return this.getInvestorDetailsFormGroup().valid;
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      this.controlName.InvestorDetails,
    ) as UntypedFormGroup;
  }

  getContactDataFormGroup(): UntypedFormGroup {
    return this.formGroup.get(this.controlName.ContactData) as UntypedFormGroup;
  }
}
