import { ApiNewDokumentPowiazanyDalDto, LawPerson } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  DocumentationFormType,
  InvestorRequest,
  InvestorRequestDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../../utils/date-helper/date-helper.util';

export interface NONROTNewRequestDto extends InvestorRequestDto {
  NazwaDotychczasowegoInwestora: string;
  DataZlozeniaZgloszenia: string;
  RodzajIZakresRobot: string;
}

export class NONROTRequestInformation {
  constructor(
    public worksType: string,
    public requestDate: NgbDate,
  ) {}
}

export class NONROTNewRequest extends InvestorRequest {
  constructor(
    lawPerson: LawPerson,
    contactData: LawPerson,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public requestInformation: NONROTRequestInformation,
    public currentInvestorName: string,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
  ) {
    super(
      lawPerson,
      contactData,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static fromAppToApi(
    data: NONROTNewRequest,
    files: ApiNewDokumentPowiazanyDalDto[],
  ): NONROTNewRequestDto {
    return {
      ...InvestorRequest.mapCommonFields({
        data,
        files,
      }),
      NazwaDotychczasowegoInwestora: data.currentInvestorName,
      DataZlozeniaZgloszenia: DateHelperUtil.ngbDateToApiDateTime(
        data.requestInformation.requestDate,
      ),
      RodzajIZakresRobot: data.requestInformation.worksType,
    };
  }
}
