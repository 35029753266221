import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import {
  ColumnHeader,
  TableSelectionMode,
} from '../../../../gk-components/table/table.model';
import { BuildingService } from '../../../../gk-dynamic-form/services/building/building.service';
import { PlaceAndStreetControlName } from '../../../../gk-dynamic-form/services/place-and-street-form/place-and-street-form.model';
import { PlaceAndStreetFormService } from '../../../../gk-dynamic-form/services/place-and-street-form/place-and-street-form.service';
import { Place } from '../../../../gk-dynamic-form/services/place/place.model';
import { PlaceService } from '../../../../gk-dynamic-form/services/place/place.service';
import { Street } from '../../../../gk-dynamic-form/services/street/street.model';
import { StreetService } from '../../../../gk-dynamic-form/services/street/street.service';
import { DictionaryField } from '../../../../gk-dynamic-list/services/dictionary/dictionary.model';
import { MapControl } from '../../../controls';
import {
  EgibObject,
  MapAction,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  PolygonTopologyValidation,
  ToolActionType,
  ToolType,
  isEgibObject,
} from '../../../models';
import { PolygonTopologyService } from '../../../services';
import { GeomUtils } from '../../../utils/geom/geom.utils';

@Component({
  selector: 'gk-map-object-table',
  templateUrl: './map-object-table.component.html',
  styleUrls: ['./map-object-table.component.scss'],
  providers: [PolygonTopologyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapObjectTableComponent
  extends MapControl
  implements OnDestroy, OnChanges, OnInit
{
  private isAlive = true;
  @Input() specifiedMapObjectTableState: MapObjectTableState;
  @Input() mapObjectTableStateIndex: number;
  @Input() attributesFormMode: boolean;
  @Input() singleObjectMode = false;
  @Input() coverageOfOriginalRangeValidation: boolean;
  @Input() deeplyClonedMapObjectTableState: MapObjectTableState;
  @Output()
  dispatchZoomToSelected = new EventEmitter<boolean>();
  @Output()
  parentDispatch = new EventEmitter<MapAction>();
  tempMapObjectIndex: number;
  enabledToEdit: MapObjectApiType[] = [MapObjectApiType.ExtentOrPolygon];
  placeAndStreetFormGroup: UntypedFormGroup;
  placeAndStreetControlName = PlaceAndStreetControlName;
  buildingStatusDictionary: DictionaryField[] = [];
  tableSelectionMode = TableSelectionMode;
  rangeCoverageValidationTranslation: string;

  constructor(
    private polygonTopologyService: PolygonTopologyService,
    private placeService: PlaceService,
    private streetService: StreetService,
    private changeDetectorRef: ChangeDetectorRef,
    private placeAndStreetFormService: PlaceAndStreetFormService,
    private formBuilder: UntypedFormBuilder,
    private buildingService: BuildingService,
    private translateService: TranslateService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.createPlaceAndStreetFormGroup();
    this.fetchBuildingStatusDictionary();
    this.fetchRangeCoverageValidationTranslation();
  }

  fetchBuildingStatusDictionary(): void {
    this.buildingService.buildingState
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (dictionaryFields) =>
          (this.buildingStatusDictionary = dictionaryFields),
      );
  }

  createPlaceAndStreetFormGroup(): void {
    this.placeAndStreetFormGroup =
      this.placeAndStreetFormService.getPlaceAndStreetFormGroup();
  }

  fetchRangeCoverageValidationTranslation(): void {
    this.translateService
      .get('GK.MAP.PREVIOUS_RANGE_COVERAGE_VALIDATION')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.rangeCoverageValidationTranslation = data;
      });
  }

  ngOnChanges(): void {
    this.addNewMapObjectToMapObjectFormArray();
  }

  addNewMapObjectToMapObjectFormArray(): void {
    const mapObjectsFormArray = this.getMapObjectsFormArray();
    if (
      !mapObjectsFormArray ||
      !this.getMapObjectTableState().placeAndStreetInputs
    ) {
      return;
    }
    this.getNewMapObjects().forEach((mapObject) => {
      const formGroup = this.placeAndStreetFormService.getMapObjectFormGroup(
        mapObject.uuid,
      );
      this.updateMapObjectPlaceOnSelectedPlaceFormControlValueChange(
        mapObject,
        formGroup,
      );
      this.updateMapObjectStreetOnStreetFormControlValueChange(
        mapObject,
        formGroup,
      );
      mapObjectsFormArray.push(formGroup);
    });
  }

  updateMapObjectPlaceOnSelectedPlaceFormControlValueChange(
    mapObject: MapObject,
    formGroup: UntypedFormGroup,
  ): void {
    formGroup
      .get(PlaceAndStreetControlName.Place)
      .get(PlaceAndStreetControlName.SelectedPlace)
      .valueChanges.pipe(
        tap((place: Place) => {
          mapObject.place = place;
        }),
        switchMap((place) => {
          return this.fetchStreetsOfPlace(mapObject, place.id);
        }),
        takeWhile(() => this.isAlive),
      )
      .subscribe(() => {
        this.getStreetFormGroup(mapObject.uuid).setValue(null);
        this.changeDetectorRef.detectChanges();
      });
  }

  updateMapObjectStreetOnStreetFormControlValueChange(
    mapObject: MapObject,
    formGroup: UntypedFormGroup,
  ): void {
    formGroup
      .get(PlaceAndStreetControlName.Street)
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((street: Street) => {
        mapObject.street = street;
      });
  }

  getNewMapObjects(): MapObject[] {
    const mapObjectsFormArray = this.getMapObjectsFormArray();
    const currentMapObjectFormGorupsUuids = mapObjectsFormArray.value.map(
      (element: MapObject) => element.uuid,
    );
    return this.getMapObjectTableState().mapObjects.filter(
      (mapObject) => !currentMapObjectFormGorupsUuids.includes(mapObject.uuid),
    );
  }

  selectAllMapObjects(): void {
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.SelectAll,
      ),
    );
  }

  deselectAllMapObjects(): void {
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.DeselectAll,
      ),
    );
  }

  zoomToSelectedMapObjects(): void {
    const selectedMapObjects = this.getMapObjectTableState().selectedMapObjects;
    if (_.isEmpty(selectedMapObjects)) {
      return;
    }
    this.dispatchZoomToSelected.emit(true);
    if (this.attributesFormMode) {
      return;
    }
    this.dispatchExtentToFitTo(selectedMapObjects);
  }

  zoomToMapObject(mapObject: MapObject): void {
    this.dispatchExtentToFitTo([mapObject]);
  }

  removeSelectedMapObjects(): void {
    this.dismissEditing();
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.RemoveSelected,
      ),
    );
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.DeselectAll,
      ),
    );
  }

  removeAllMapObjects(): void {
    this.dismissEditing();
    this.removeAllMapObjectFormGroupsFromFormArray();
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.DeselectAll,
      ),
    );
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.RemoveAll,
      ),
    );
  }

  removeAllMapObjectFormGroupsFromFormArray(): void {
    this.placeAndStreetFormGroup.controls[
      PlaceAndStreetControlName.MapObjectsArray
    ] = this.formBuilder.array([]);
  }

  removeMapObject(mapObject: MapObject): void {
    if (this.isEdited(mapObject)) {
      this.dismissEditing();
    }
    this.removeMapObjectFormGroupFromFormArray(mapObject);
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.Deselect,
        mapObject,
      ),
    );
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.Remove,
        mapObject,
      ),
    );
    this.dispatch.emit(
      new MapAction(ToolActionType.Clear, {
        value: mapObject,
        options: { toolType: ToolType.LandParcel },
      }),
    );
  }

  removeMapObjectFormGroupFromFormArray(mapObject: MapObject): void {
    const formArray = this.getMapObjectsFormArray();
    formArray.controls.forEach((mapObjectFormControl, index) => {
      if (mapObject.uuid === mapObjectFormControl.value.uuid) {
        formArray.removeAt(index);
      }
    });
  }

  startEditing(mapObject: MapObject): void {
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.Edit,
        mapObject,
      ),
    );
  }

  dismissEditing(): void {
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.RestoreInitialEditedMapObject,
      ),
    );
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.MapObjectsUpdate,
      ),
    );
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.RemoveFromEdition,
      ),
    );
  }

  submitEditing(): void {
    const mapObjectTableState = this.getMapObjectTableState();
    if (
      mapObjectTableState.editedMapObject.type !==
      MapObjectApiType.ExtentOrPolygon
    ) {
      this.dispatch.emit(
        this.getNewMapActionWithMapObjectTableStateIndex(
          MapObjectTableActionType.RemoveFromEdition,
        ),
      );
      return;
    }
    if (
      this.coverageOfOriginalRangeValidation &&
      !GeomUtils.validateCoverageOfRanges(
        this.deeplyClonedMapObjectTableState.mapObjects,
        mapObjectTableState.initialEditedMapObject,
        mapObjectTableState.editedMapObject,
      )
    ) {
      this.showRangeCoverageValidationToastr();

      return;
    }
    this.dispatchTopologyValidationLoader(true);
    this.polygonTopologyService
      .getPolygonTopologyValidation(mapObjectTableState.editedMapObject.geom)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((validationStatus) => {
        this.handleValidationStatus(validationStatus);
        this.dispatchTopologyValidationLoader(false);
      });
  }

  showRangeCoverageValidationToastr(): void {
    this.toastrService.warning(this.rangeCoverageValidationTranslation);
  }

  handleValidationStatus(validationStatus: PolygonTopologyValidation): void {
    this.dispatch.emit(
      validationStatus.isValid
        ? this.getNewMapActionWithMapObjectTableStateIndex(
            MapObjectTableActionType.RemoveFromEdition,
          )
        : this.getNewMapActionWithMapObjectTableStateIndex(
            MapObjectTableActionType.IsVisibleTopologyValidationErrorChange,
            true,
          ),
    );
  }

  dispatchTopologyValidationLoader(shouldShow: boolean): void {
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.IsVisibleTopologyValidationLoaderChange,
        shouldShow,
      ),
    );
  }

  shouldShowEditButton(mapObject: MapObject): boolean {
    return _.includes(this.enabledToEdit, mapObject.type);
  }

  isEdited(mapObject: MapObject): boolean {
    const editedObject = this.getMapObjectTableState().editedMapObject;
    return !!editedObject && mapObject.uuid === editedObject.uuid;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  handleTableSelection(mapObject: MapObject[]): void {
    this.dispatch.emit(
      this.getNewMapActionWithMapObjectTableStateIndex(
        MapObjectTableActionType.Select,
        mapObject,
      ),
    );
  }

  getSelectedRows(): MapObject[] {
    return this.getMapObjectTableState().selectedMapObjects;
  }

  getColumnHeaders(): ColumnHeader[] {
    const mapObjectTableState = this.getMapObjectTableState();
    return [
      ...[
        new ColumnHeader(
          undefined,
          'GK.MAP.PLACE',
          mapObjectTableState.placeAndStreetInputs,
        ),
        new ColumnHeader(
          undefined,
          'GK.MAP.STREET',
          mapObjectTableState.placeAndStreetInputs,
        ),
      ],
      ...(mapObjectTableState.columnHeader || []),
      ...[
        new ColumnHeader(
          undefined,
          'GK.MAP.BUILDING_LOCATION',
          mapObjectTableState.isEnabledBuildingLocation,
        ),
        new ColumnHeader(
          undefined,
          'GK.MAP.BUILDING_STATUS',
          mapObjectTableState.isEnabledBuildingStatus,
        ),
      ],
      ...[
        new ColumnHeader(
          undefined,
          'GK.MAP.PREVIEW',
          mapObjectTableState.isEnabledPreview,
        ),
        new ColumnHeader(
          undefined,
          'GK.MAP.REMOVE',
          mapObjectTableState.isEnabledRemoving,
        ),
        new ColumnHeader(
          undefined,
          'GK.MAP.EDIT',
          mapObjectTableState.isEnabledEditing,
        ),
      ],
    ];
  }

  getPlacesByDistrictId(mapObject: MapObject): Observable<Place[]> {
    if (!isEgibObject(mapObject)) {
      return of([]);
    }

    return _.isUndefined(mapObject.placesOfDistrict)
      ? this.placeService.getPlacesOfDistrict(mapObject.districtId).pipe(
          takeWhile(() => this.isAlive),
          tap(
            (placesOfDistrict) =>
              (mapObject.placesOfDistrict = placesOfDistrict),
          ),
          filter((placesOfDistrict) => !!placesOfDistrict.length),
          tap(() =>
            this.getSelectedPlaceFormControl(mapObject.uuid).setValue(
              mapObject.placesOfDistrict[0],
            ),
          ),
        )
      : of(mapObject.placesOfDistrict);
  }

  fetchStreetsOfPlace(
    mapObject: MapObject,
    placeId: number | string,
  ): Observable<Street[]> {
    return this.streetService.getStreets('', placeId).pipe(
      map((streets) => streets.map((street) => ({ ...street, placeId }))),
      tap((streets) => {
        this.getPlaceFormGroup(mapObject.uuid).patchValue({
          streetsOptions: streets,
        });
        if (!streets.length) {
          this.getStreetFormGroup(mapObject.uuid).disable();
          mapObject.place.hasNullStreet = true;
        } else {
          this.getStreetFormGroup(mapObject.uuid).enable();
          mapObject.place.hasNullStreet = false;
        }
      }),
      takeWhile(() => this.isAlive),
    );
  }

  trackStreet(_index: number, street: Street): string {
    return `${street.id}_${street.placeId}`;
  }

  getSelectedPlaceFormControl(uuid: string | number): UntypedFormControl {
    return this.getPlaceFormGroup(uuid).get(
      PlaceAndStreetControlName.SelectedPlace,
    ) as UntypedFormControl;
  }

  getStreetsOptionsFormControl(uuid: string | number): UntypedFormControl {
    return this.getPlaceFormGroup(uuid).get(
      PlaceAndStreetControlName.StreetsOptions,
    ) as UntypedFormControl;
  }

  getStreetFormGroup(uuid: string | number): UntypedFormControl {
    return this.getMapObjectsFormGroupByUuid(uuid).get(
      PlaceAndStreetControlName.Street,
    ) as UntypedFormControl;
  }

  getPlaceFormGroup(uuid: string | number): UntypedFormGroup {
    return this.getMapObjectsFormGroupByUuid(uuid).get(
      PlaceAndStreetControlName.Place,
    ) as UntypedFormGroup;
  }

  getMapObjectsFormGroupByUuid(uuid: string | number): UntypedFormGroup {
    const controls = this.getMapObjectsFormArray().controls;

    return controls.find(
      (formGroup) =>
        formGroup.get(PlaceAndStreetControlName.Uuid).value === uuid,
    ) as UntypedFormGroup;
  }

  getMapObjectsFormArray(): UntypedFormArray {
    return (
      this.placeAndStreetFormGroup &&
      (this.placeAndStreetFormGroup.get(
        PlaceAndStreetControlName.MapObjectsArray,
      ) as UntypedFormArray)
    );
  }

  getBuildingStatusField(egibObject: EgibObject): string {
    const properDictionaryField = this.buildingStatusDictionary.find(
      (dictionaryField) => dictionaryField.id === egibObject.buildingStatusId,
    );

    return properDictionaryField ? properDictionaryField.name : '';
  }

  getMapObjectTableState(): MapObjectTableState {
    return (
      this.specifiedMapObjectTableState ||
      this.mapState.mapObjectTablesState[this.mapObjectTableStateIndex]
    );
  }

  private getNewMapActionWithMapObjectTableStateIndex(
    type: any,
    payload?: any,
  ): MapAction {
    return new MapAction(type, payload, this.mapObjectTableStateIndex);
  }
}
