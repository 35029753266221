<div class="container container-e-service-request">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section
      class="card mb-4"
      [ngClass]="
        checkInvestorDetailsFormGroupAndBirthPlaceDateFormControlsValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.APPLICANT_DETAILS.TITLE' | translate
        }}
      </div>

      <div class="card-body">
        <app-investor-details
          [legalPersonEnabled]="false"
          [investorDetailsFromGroup]="getInvestorDetailsFormGroup()"
        ></app-investor-details>

        <div class="row">
          <div class="col-md-4 col-sm-6 col-xs-12 my-1">
            <label class="text-nowrap" [attr.for]="controlName.BirthDate">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.APPLICANT_DETAILS.FIELDS.BIRTH_DATE'
                  | translate
              }}
            </label>

            <div class="input-group">
              <input
                class="form-control form-control-sm required"
                aria-required="true"
                [attr.placeholder]="
                  'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                "
                [id]="controlName.BirthDate"
                [name]="controlName.BirthDate"
                [formControlName]="controlName.BirthDate"
                ngbDatepicker
                #d="ngbDatepicker"
              />

              <button
                class="btn btn-outline-secondary fa fa-calendar"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>

            @if (shouldShowRequiredAlert(this.getBirthDateFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="col-md-4 col-sm-6 col-xs-12 my-1">
            <label class="text-nowrap" [attr.for]="controlName.BirthPlace">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.APPLICANT_DETAILS.FIELDS.BIRTH_PLACE'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.BirthPlace"
              [id]="controlName.BirthPlace"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.BirthPlace"
            />

            @if (shouldShowRequiredAlert(this.getBirthPlaceFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>
        </div>
      </div>
    </section>

    <app-proxy-details
      #proxyDetails
      [portalId]="portalId"
      [formGroup]="formGroup"
      [controlName]="controlName"
      [submitted]="submitted"
    ></app-proxy-details>

    <gk-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [requiredPowerOfAttorney]="proxyDetails.proxyDetailsVisible"
    >
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <button type="submit" class="btn btn-primary">
      {{
        'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.FIELDS.SUBMIT_REQUEST'
          | translate
      }}
    </button>

    <app-doc-sign-info
      [messageText]="docSignMsgTxt"
      [messageType]="docSignMsgType"
      [docSignUrl]="docSignUrl"
      [docSignPending]="docSignPending"
    ></app-doc-sign-info>

    <div class="mt-2"><app-form-legend></app-form-legend></div>
  </form>
</div>
