import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { MapAction } from '../../../gk-map/models';
import { ListDataConfig } from '../../gk-dynamic-list.model';
import { DataService } from '../../services/data/data.service';
import { DynamicListManagerService } from '../../services/dynamic-list-manager/dynamic-list-manager.service';

@Component({
  selector: 'gk-dynamic-accordion-body',
  templateUrl: './dynamic-accordion-body.component.html',
  styleUrls: ['./dynamic-accordion-body.component.scss'],
  standalone: false,
})
export class DynamicAccordionBodyComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input()
  listDataConfig: ListDataConfig;
  @Input()
  data: any;
  @Input() preventLoadedFirstAccordionDataEimtting = false;
  @Output()
  dispatchMapAction = new EventEmitter<MapAction>();
  loading = false;
  triggered = false;
  fetchedData: any = {};

  constructor(
    private dataService: DataService,
    private dynamicListManagerService: DynamicListManagerService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  setLoadingToFalseAndTriggerLoadedFirstAccordionData(): void {
    this.loading = false;
    this.changeDetectorRef.markForCheck();
    if (!this.triggered) {
      this.triggered = true;
      this.emitLoadedFirstAccordionData();
    }
  }

  getData(): any {
    return { ...this.data, ...this.fetchedData };
  }

  fetchData(): void {
    if (_.isEmpty(this.listDataConfig.requestConfig)) {
      this.emitLoadedFirstAccordionData();
      return;
    }

    this.loading = true;
    this.dataService
      .getData(this.listDataConfig.requestConfig, this.data)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (data) => {
          this.fetchedData = data;
          this.setLoadingToFalseAndTriggerLoadedFirstAccordionData();
          this.persistDataIfAny();
        },
        error: () => this.setLoadingToFalseAndTriggerLoadedFirstAccordionData(),
      });
  }

  persistDataIfAny(): void {
    if (!this.listDataConfig.persistDataConfig) {
      return;
    }
    const { persistDataConfig, requestConfig } = this.listDataConfig;
    const { propertiesToPersist } = persistDataConfig;

    if (!propertiesToPersist) return;

    const dataToPersist =
      this.fetchedData[requestConfig.propertyToWrapWith] ?? this.fetchedData;

    propertiesToPersist.forEach(({ propertyName, path }) => {
      dataToPersist.forEach((dataProperty: any) => {
        dataProperty[propertyName] = this.data[path];
      });
    });
  }

  emitLoadedFirstAccordionData(): void {
    if (this.preventLoadedFirstAccordionDataEimtting) {
      return;
    }
    this.dynamicListManagerService.loadedFirstAccordionData.next();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
