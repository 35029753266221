import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ListConfigs,
  ListState,
  MapAction,
  MapSettingsService,
  CommunicatorListItem,
} from '@gk/gk-modules';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeWhile } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppTitleService } from '../../../services/app-title/app-title.service';
import { ConfigManagerService } from '../../../services/config-manager/config-manager.service';
import { DesignerSettingsService } from '../../../services/designer-settings/designer-settings.service';
import { WebPortalService } from '../../../services/web-portal/web-portal.service';
import { BaseRequestsListDirective } from '../../../shared/base-requests-list/base-requests-list.component';

@Component({
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.scss'],
  standalone: false,
})
export class RequestsListComponent
  extends BaseRequestsListDirective
  implements OnInit, OnDestroy
{
  override listConfigs: ListConfigs;
  listState = {} as ListState;
  pendingMapActions: MapAction[] = [];

  constructor(
    private mapSettingsService: MapSettingsService,
    private configManagerService: ConfigManagerService,
    public override appTitleService: AppTitleService,
    public override webPortalService: WebPortalService,
    public override modalService: NgbModal,
    private designerSettingsService: DesignerSettingsService,
  ) {
    super(appTitleService, webPortalService, modalService);
  }

  ngOnInit(): void {
    this.setListConfigs();
  }

  setListConfigs(): void {
    this.getCurrentPortalType()
      .pipe(
        takeWhile(() => this.isAlive),
        switchMap((webPortal) =>
          this.configManagerService.getConfigForDesignerDtoList(
            webPortal.portalId,
            (dynamicListItem, buttonStateControl) =>
              this.handleOpenCommunicator(
                CommunicatorListItem.fromApiDesignerToApp(dynamicListItem),
                buttonStateControl,
              ),
            (dynamicListItem, refreshCallback) =>
              this.handleReadNewMessage(dynamicListItem, refreshCallback),
          ),
        ),
        tap((listConfigs) => {
          this.designerSettingsService.listConfigs.next(listConfigs);
        }),
      )
      .subscribe((listConfigs) => {
        this.listConfigs = listConfigs;
      });
  }

  handleMapAction(mapAction: MapAction): void {
    this.pendingMapActions = [...this.pendingMapActions, mapAction];
  }

  dispatchPendingMapActions(): void {
    this.mapSettingsService.pendingMapActions.next(this.pendingMapActions);
  }

  override ngOnDestroy(): void {
    if (this.communicatorModalRef) {
      this.communicatorModalRef.closeModal();
    }
    this.dispatchPendingMapActions();
    super.ngOnDestroy();
  }
}
