import { FormControl } from '@angular/forms';
import { FieldId } from '@gk/gk-modules';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum BPNewRequestAdditionalControlName {
  ConstructionIntentionType = 'constructionIntentionType',
  ConstructionProjectName = 'constructionProjectName',
}

export const BPNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...BPNewRequestAdditionalControlName,
};

export interface BPNewRequestFormGroup extends LawPersonNewRequestFormGroup {
  [BPNewRequestControlName.ConstructionIntentionType]: FormControl<FieldId[]>;
  [BPNewRequestControlName.ConstructionProjectName]: FormControl<string>;
}
