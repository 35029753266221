import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPersonAddress,
  Place,
  Street,
  getValueWhenPropertyIsAddressFields,
  getValueWhenPropertyIsString,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { PostalCode } from '../../../../../services/postal-code/postal-code.model';
import {
  DocumentationFormType,
  ExtendedInvestorRequest,
  ExtendedInvestorRequestDto,
} from '../../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../../../utils/date-helper/date-helper.util';
import { ConstructionNotificationType } from '../new-request-form/new-request-form.model';

export interface CNNewRequestDto extends ExtendedInvestorRequestDto {
  RodzajZgloszenia: ConstructionNotificationType;
  NazwaIRodzajBudowy: string;
  MiejscId: string;
  Miejsc: string;
  UlicaId: string;
  Ulica: string;
  KodId: string;
  Kod: string;
  Poczta: string;
  Numer: string;
  Numer2: string;
  Opis: string;
  TerminRozpoczeciaRobotBudowlanych: string;
}

export class CNNewRequest extends ExtendedInvestorRequest {
  constructor(
    investorDetails: InvestorDetails,
    proxyDetails: InvestorDetails,
    public buildingNameAndType: string,
    public place: Place,
    public street: Street,
    public buildingNumber: string,
    public localNumber: string,
    public postalCode: PostalCode,
    public postOffice: string,
    public description: string,
    public constructionWorksCommencementDate: NgbDate,
    public constructionIntention: ConstructionNotificationType,
    public phoneNumber: number,
    public email: string,
    public address: LawPersonAddress,
    proxyDetailsCheckbox: boolean,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
  ) {
    super(
      investorDetails,
      documentationForm,
      shippingForm,
      undefined,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }
  static fromAppToApi(
    data: CNNewRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): CNNewRequestDto {
    return {
      ...ExtendedInvestorRequest.mapCommonFields({
        data,
        parcels,
        files,
      }),
      RodzajZgloszenia: data.constructionIntention,
      NazwaIRodzajBudowy: data.buildingNameAndType,
      MiejscId: getValueWhenPropertyIsAddressFields(data.place),
      Miejsc: getValueWhenPropertyIsString(data.place),
      UlicaId: getValueWhenPropertyIsAddressFields(data.street),
      Ulica: getValueWhenPropertyIsString(data.street),
      KodId: getValueWhenPropertyIsAddressFields(data.postalCode),
      Kod: getValueWhenPropertyIsString(data.postalCode),
      Poczta: data.postOffice,
      Numer: data.buildingNumber,
      Numer2: data.localNumber,
      Opis: data.description,
      TerminRozpoczeciaRobotBudowlanych: DateHelperUtil.ngbDateToApiDateTime(
        data.constructionWorksCommencementDate,
      ),
    };
  }
}
