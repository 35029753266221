import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs';
import {
  getScaleByResolution,
  getScaleByZoom,
  scales,
} from '../../../gk-map/configs';
import { MapControl } from '../../../gk-map/controls';
import {
  MapAction,
  MapViewActionType,
  PrintAttributesFormState,
  SourceType,
  ToolType,
} from '../../../gk-map/models';
import {
  GMPPrintAttributesControlName,
  GMPPrintAttributesFormGroup,
} from '../../services/print-attributes-form/print-attributes-form.model';
import { GMPPrintFooterService } from '../../services/print-footer/print-footer.service';
import { GMPPrintQualityService } from '../../services/print-quality/print-quality.service';
import { GMPPrintTemplateService } from '../../services/print-template/print-template.service';

@Component({
  selector: 'gk-gmp-print-attributes-form',
  templateUrl: './print-attributes-form.component.html',
  styleUrl: './print-attributes-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GMPPrintAttributesFormComponent
  extends MapControl
  implements OnInit, OnChanges, OnDestroy
{
  private isAlive = true;
  @Input() formGroup: FormGroup<GMPPrintAttributesFormGroup>;
  controlName = GMPPrintAttributesControlName;
  mapScales = scales;
  constructor(
    public gmpPrintTemplateService: GMPPrintTemplateService,
    public gmpPrintQualityService: GMPPrintQualityService,
    public gmpPrintFooterService: GMPPrintFooterService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.emitResolutionOnFormControlChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentResolution = _.get(
      changes['mapState'],
      'currentValue.viewState.resolution',
    );
    const previousResolution = _.get(
      changes['mapState'],
      'previousValue.viewState.resolution',
    );

    if (this.formGroup && !_.isEqual(currentResolution, previousResolution)) {
      const zoom = getScaleByResolution(currentResolution).zoom;
      this.getMapScaleResolutionFormControl().setValue(zoom, {
        emitEvent: false,
      });
    }
  }

  emitResolutionOnFormControlChange(): void {
    this.getMapScaleResolutionFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((zoom) => {
        const resolution = getScaleByZoom(zoom).resolution;
        this.dispatch.emit(
          new MapAction(MapViewActionType.ResolutionChange, resolution),
        );
      });
  }

  getMapScaleResolutionFormControl(): FormControl<number> {
    return this.formGroup.get(
      GMPPrintAttributesControlName.MapScaleResolution,
    ) as FormControl<number>;
  }

  shouldControlBeVisible(controlName: GMPPrintAttributesControlName): boolean {
    const config = this.getPrintAttributesFormState().config;

    return config.attributesFormVisibleFields.includes(controlName);
  }

  getPrintAttributesFormState(): PrintAttributesFormState {
    return this.mapState.toolsState[ToolType.Print][
      SourceType.PrintAttributesForm
    ] as PrintAttributesFormState;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
