import {
  DictionaryField,
  DictionaryFieldFromApi,
  FieldId,
} from '../../gk-dynamic-list/services/dictionary/dictionary.model';
import { PortalId } from '../../utils/portal-id/portal-id.model';

export interface StampDutyOfficeFromApi extends DictionaryFieldFromApi {
  NrKonta: string;
}

export class StampDutyOffice extends DictionaryField {
  constructor(
    id: FieldId,
    name: string,
    public accountNumber: string,
  ) {
    super(id, name);
  }

  static override fromApiToApp(
    officeFromApi: StampDutyOfficeFromApi,
  ): StampDutyOffice {
    return new this(
      officeFromApi.Id,
      officeFromApi.Nazwa,
      officeFromApi.NrKonta,
    );
  }
}

export enum StampDutyPaymentStatus {
  NotApplicable,
  Paid,
  Pending,
  Unpaid,
}

export interface StampDutyPayment {
  documentSectionId: string | number;
  portalId: PortalId;
  ownerId: string | number;
  numberOfPayments?: number;
  paymentAmount?: number;
}

export class StampDutyPaymentDto {
  constructor(
    public KdokRodzId: string | number,
    public PortalId: PortalId,
    public OwnerId?: string | number,
    public Ilosc?: number,
    public Kwota?: number,
  ) {}

  static fromAppToApi(payment: Partial<StampDutyPayment>): StampDutyPaymentDto {
    return new this(
      payment.documentSectionId,
      payment.portalId,
      payment.ownerId,
      payment.numberOfPayments,
      payment.paymentAmount,
    );
  }
}

export enum PaymentAmountType {
  FixedSingle = 1,
  FixedMultiple,
  AnyEntered,
}
