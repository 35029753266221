import { Component, Input } from '@angular/core';
import {
  DropdownNavItemConfig,
  NavItemConfig,
  NavItemType,
} from '../../gk-dynamic-navbar.model';
import { NavBaseComponent } from '../nav-base/nav-base.component';

@Component({
  selector: 'gk-nav-item-renderer',
  templateUrl: './nav-item-renderer.component.html',
  styleUrls: ['./nav-item-renderer.component.scss'],
  standalone: false,
})
export class NavItemRendererComponent extends NavBaseComponent {
  @Input()
  navItemConfig: NavItemConfig;
  navItemTypeEnum = NavItemType;
  @Input()
  dropdownConfig: DropdownNavItemConfig;
}
