import { Component, Type } from '@angular/core';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/kendo/form-field';
import { FormlyFieldTextArea } from '@ngx-formly/kendo/textarea';

type TextAreaProps = FormlyFieldProps;

export interface FormlyTextAreaFieldConfig
  extends FormlyFieldConfig<TextAreaProps> {
  type: 'textarea' | Type<FormlyFieldTextArea>;
}

@Component({
  selector: 'formly-field-kendo-textarea',
  template: `
    <textarea
      kendoTextArea
      [formControl]="formControl"
      [formlyAttributes]="field"
      [ngClass]="{ required: props.required }"
      [cols]="props.cols"
      [rows]="props.rows"
    ></textarea>
  `,
  standalone: false,
})
export class FormlyFieldKendoTextAreaComponent extends FieldType<
  FieldTypeConfig<TextAreaProps>
> {}
