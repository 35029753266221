import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../../shared/base-new-request-form/base-new-request-form.service';
import {
  FellingObjectsControlName,
  TASFNewRequestControlName,
} from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class TASFNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup({
        [TASFNewRequestControlName.ProxyDetailsCheckbox]: false,
        [ECSControlName.ElectronicCorrespondenceStatement]: false,
      }).controls,
      [TASFNewRequestControlName.TreesList]: this.formBuilder.array([]),
      [TASFNewRequestControlName.ShrubsList]: this.formBuilder.array([]),
      [TASFNewRequestControlName.BusinessPurpose]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TASFNewRequestControlName.FellingCause]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TASFNewRequestControlName.FellingDate]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TASFNewRequestControlName.GrowthPlace]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TASFNewRequestControlName.InvestmentDescription]: [
        getDefaultControlValue(),
      ],
      [TASFNewRequestControlName.Place]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TASFNewRequestControlName.Street]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TASFNewRequestControlName.StreetAbsence]: false,
      [TASFNewRequestControlName.LandRegister]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TASFNewRequestControlName.LandNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }

  getTreeFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [FellingObjectsControlName.InventoryNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [FellingObjectsControlName.SpeciesName]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [FellingObjectsControlName.TrunkCircumference]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }

  getShrubFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [FellingObjectsControlName.InventoryNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [FellingObjectsControlName.SpeciesName]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [FellingObjectsControlName.ProjectionSurface]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }
}
