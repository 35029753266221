import { ColumnSetting, ColumnType } from '../../../../../gk-kendo';

export const mapLandClassesGridColumns: ColumnSetting[] = [
  {
    field: 'use',
    title: 'LAND_DETAILS.CLASS.USE',
    type: ColumnType.Text,
  },
  {
    field: 'class',
    title: 'LAND_DETAILS.CLASS.TITLE',
    type: ColumnType.Text,
  },
  {
    field: 'area',
    title: 'LAND_DETAILS.CLASS.AREA',
    type: ColumnType.Text,
  },
];
