import { FormControl } from '@angular/forms';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum ATIOEAIPNewRequestAdditionalControlName {
  DetailedScope = 'detailedScope',
  AdditionalIndications = 'additionalIndications',
}

export const ATIOEAIPNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...ATIOEAIPNewRequestAdditionalControlName,
};

export interface ATIOEAIPNewRequestFormGroup
  extends LawPersonNewRequestFormGroup {
  [ATIOEAIPNewRequestControlName.DetailedScope]: FormControl<string>;
  [ATIOEAIPNewRequestControlName.AdditionalIndications]: FormControl<string>;
}
