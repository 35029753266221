import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addTextAfterBase',
  standalone: false,
})
export class AddTextAfterBasePipe implements PipeTransform {
  transform(value: string, baseString: string, textToInsert: string): string {
    const regex = new RegExp(`(${baseString}-)(\\w+)`);
    return value.replace(regex, `$1${textToInsert}-$2`);
  }
}
