import { FormControl } from '@angular/forms';
import { FieldId } from '@gk/gk-modules';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum SCOLNewCarryOutRequestAdditionalControlName {
  Reason = 'reason',
  DemonstrationOfApplicantRight = 'demonstrationOfApplicantRight',
  EnableWorkByPerson = 'enableWorkByPerson',
}

export const SCOLNewCarryOutRequestControlName = {
  ...BaseNewRequestControlName,
  ...SCOLNewCarryOutRequestAdditionalControlName,
};

export interface SCOLNewCarryOutRequestFormGroup
  extends LawPersonNewRequestFormGroup {
  [SCOLNewCarryOutRequestControlName.Reason]: FormControl<string>;
  [SCOLNewCarryOutRequestControlName.DemonstrationOfApplicantRight]: FormControl<FieldId>;
  [SCOLNewCarryOutRequestControlName.EnableWorkByPerson]: FormControl<string>;
}
