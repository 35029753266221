import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  FieldId,
  LawPerson,
} from '@gk/gk-modules';
import { WorkspaceState } from '../../../../services/request-workspace-state/request-workspace-state.model';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import {
  AuthorizedPersonToRegistryView,
  AuthorizedPersonToRegistryViewDto,
} from './../../../../services/authorized-person-to-registry-view/authorized-person-to-registry-view.model';

export interface SCOLNewCarryOutRequestDto extends ApplicantRequestDto {
  Uzasadnienie: string;
  WladanieId: FieldId;
  UpowaznieniInfo: AuthorizedPersonToRegistryViewDto[];
}

export class SCOLNewCarryOutRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    public reason: string,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public demonstrationOfApplicantRight: FieldId,
    public enableWorkByPerson: string,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: SCOLNewCarryOutRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): SCOLNewCarryOutRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        parcels,
        files,
      }),
      Uzasadnienie: data.reason,
      WladanieId: data.demonstrationOfApplicantRight,
      UpowaznieniInfo: [
        AuthorizedPersonToRegistryView.fromStringDataToApi(
          data.enableWorkByPerson,
        ),
      ],
    };
  }
}

export interface SCOLNewCarryOutRequestFormWorkspaceState
  extends WorkspaceState {
  formValue: SCOLNewCarryOutRequest;
  parcels: EgibObject[];
}
