import { PlanningDepartmentRequestType } from '../../shared/planning-department.model';

export enum ExtractCertificateSubject {
  Certificate = 1,
  ExtractFromSpatialDevelopmentPlan = 2,
  WrittenExtractFromSpatialDevelopmentPlan = 3,
  GraphicExtractFromSpatialDevelopmentPlan = 4,
  ExtractFromStudy = 5,
  WrittenExtractFromStudy = 6,
  GraphicExtractFromStudy = 7,
}

export interface ExtractCertificateSubjectCheckbox {
  writtenExtractCheckbox: ExtractCertificateSubject;
  graphicExtractCheckbox: ExtractCertificateSubject;
  certificateCheckbox: ExtractCertificateSubject;
}

export const getRequestSubjectValueFromCheckboxData = (
  data: ExtractCertificateSubjectCheckbox,
  requestFormType: PlanningDepartmentRequestType,
): ExtractCertificateSubject => {
  let requestSubjectValue: ExtractCertificateSubject;

  if (data.graphicExtractCheckbox && !data.writtenExtractCheckbox) {
    requestSubjectValue =
      requestFormType ===
      PlanningDepartmentRequestType.ExtractFromLocalSpatialDevelopmentPlan
        ? ExtractCertificateSubject.GraphicExtractFromSpatialDevelopmentPlan
        : ExtractCertificateSubject.GraphicExtractFromStudy;
  } else if (!data.graphicExtractCheckbox && data.writtenExtractCheckbox) {
    requestSubjectValue =
      requestFormType ===
      PlanningDepartmentRequestType.ExtractFromLocalSpatialDevelopmentPlan
        ? ExtractCertificateSubject.WrittenExtractFromSpatialDevelopmentPlan
        : ExtractCertificateSubject.WrittenExtractFromStudy;
  } else if (data.graphicExtractCheckbox && data.writtenExtractCheckbox) {
    requestSubjectValue =
      requestFormType ===
      PlanningDepartmentRequestType.ExtractFromLocalSpatialDevelopmentPlan
        ? ExtractCertificateSubject.ExtractFromSpatialDevelopmentPlan
        : ExtractCertificateSubject.ExtractFromStudy;
  } else if (data.certificateCheckbox) {
    requestSubjectValue = ExtractCertificateSubject.Certificate;
  }

  return requestSubjectValue;
};
