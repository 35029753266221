import { Component } from '@angular/core';
import { GkKendoDocumentationGridDataService } from './gk-kendo-documentation-grid-data.service';
import { GkKendoDocumentationGridDto } from './gk-kendo-documentation-grid-data-model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { GkKendoGridComponent } from '../gk-kendo-grid.component';
import { gkKendoDocumentationGridColumns } from './gk-kendo-documentation-grid-model';

@Component({
  selector: 'gk-kendo-documentation-grid',
  templateUrl: '../gk-kendo-grid.component.html',
  standalone: false,
})
export class GkKendoDocumentationGridComponent extends GkKendoGridComponent<GkKendoDocumentationGridDto> {
  override columns = gkKendoDocumentationGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;

  constructor(
    gkKendoDocumentationGridDataService: GkKendoDocumentationGridDataService,
  ) {
    super(gkKendoDocumentationGridDataService);
  }
}
