import { BaseNewRequestControlName } from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum OCNewRequestAdditionalControlName {
  Comments = 'comments',
}

export const OCNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...OCNewRequestAdditionalControlName,
};
