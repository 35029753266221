import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { atLeastOneValidator, getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { AuthorizedPersonControlName } from '../../../../services/authorized-person-form/authorized-person-form.model';
import { AuthorizedPersonFormService } from '../../../../services/authorized-person-form/authorized-person-form.service';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { FeeWaiverControlName } from '../../../../shared/fee-waiver/fee-waiver.model';
import { FeeWaiverFormService } from '../../../../shared/fee-waiver/fee-waver-form.service';
import { ECNewRequestFormControlName } from './new-request-form.model';

@Injectable()
export class ECNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authorizedPersonFormService: AuthorizedPersonFormService,
    private feeWaiverFormService: FeeWaiverFormService,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonInvestorFormGroup(
        {
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
          [ECNewRequestFormControlName.ProxyDetailsCheckbox]: false,
          [ECNewRequestFormControlName.DocumentationForm]: false,
        },
        {
          isInvestorPhoneNumberRequired: false,
          isInvestorPhoneNumberValid: false,
          investorPrimitivePostalCode: false,
          disableShippingEmail: true,
          shippingPrimitivePostalCode: true,
        },
      ).controls,
      [ECNewRequestFormControlName.DismissalReasonExplanationDescription]: [
        getDefaultControlValue(true),
        [Validators.required],
      ],
      [ECNewRequestFormControlName.Comments]: [getDefaultControlValue()],
      [ECNewRequestFormControlName.Quantity]: [
        1,
        [Validators.required, Validators.min(1)],
      ],
      [ECNewRequestFormControlName.Subject]: this.formBuilder.group(
        {
          [ECNewRequestFormControlName.WrittenExtractCheckbox]: [true],
          [ECNewRequestFormControlName.GraphicExtractCheckbox]: [true],
          [ECNewRequestFormControlName.CertificateCheckbox]: [false],
        },
        { validator: atLeastOneValidator() },
      ),
      [AuthorizedPersonControlName.AuthorizedDetailsForm]:
        this.authorizedPersonFormService.getFormGroup(),
      [FeeWaiverControlName.FeeWaiverForm]:
        this.feeWaiverFormService.getFormGroup(),
      [ECNewRequestFormControlName.RodoClauseAgreeCheckbox]: [false],
    });
  }
}
