import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-document-verification',
  templateUrl: './modal-document-verification.component.html',
  styleUrls: ['./modal-document-verification.component.scss'],
  standalone: false,
})
export class ModalDocumentVerificationComponent {
  @ViewChild('modalContent') modal: NgbModalRef;
  ngbModalRef: NgbModalRef;
  documentNotFound = false;

  constructor(private modalService: NgbModal) {}

  showModal(): void {
    this.ngbModalRef = this.modalService.open(this.modal);
  }

  onExit(): void {
    this.ngbModalRef.close();
  }

  onDocumentNotFoundEvent(documentNotFound: boolean): void {
    this.documentNotFound = documentNotFound;
  }
}
