@if (coordinationLayers) {
  <div class="map-mouse-position">
    <div class="map-mouse-position-coordinates">
      <kendo-dropdownlist
        id="dropdown"
        class="map-mouse-position-dropdown border-0"
        [data]="coordinationLayers"
        [value]="coordinationLayers[0]"
        [textField]="'text'"
        [valueField]="'value'"
        [fillMode]="'flat'"
        (selectionChange)="onValueChange($event)"
        [popupSettings]="{ appendTo: 'component' }"
      >
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <span class="dropdown-coords">{{ currentCoordinatesParsed }}</span>

          {{ dataItem?.name }}
        </ng-template>

        <ng-template kendoDropDownListItemTemplate let-dataItem>
          {{ dataItem?.name }}
        </ng-template>
      </kendo-dropdownlist>
    </div>

    <div
      class="map-mouse-position-altitude-system pe-3 text-end mt-3 p-1 d-inline-block float-end"
    >
      {{ 'GK.MAP.ALTITUDE_SYSTEM_SHORT' | translate }}:
      {{ systemCityData.altitudeSpatialRef }}
    </div>
  </div>
}
