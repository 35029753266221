import { FieldConfigType } from '../../gk-dynamic-form/gk-dynamic-form.model';
import { FilterOperatorType } from '../../gk-dynamic-list/gk-dynamic-list.model';

export enum MapObjectsApplicantEndpoint {
  LandParcel = '/api/interesant/dzialki/search',
  Building = '/api/interesant/budynki/search',
  Premises = '/api/interesant/lokale/search',
  District = '/api/interesant/obreby/search',
  LandUse = '/api/interesant/uzytekgruntowy/search',
  ClassificationContour = '/api/interesant/konturklasy/search',
  ControlPoint = '/api/interesant/punktyosnowy/search',
  BoundaryPoint = '/api/interesant/punktygraniczne/search',
}

export enum BasicSearchFieldPath {
  GminaId = 'administrativeUnit.community',
  ObrebId = 'administrativeUnit.district',
  ArkuszId = 'administrativeUnit.sheet',
  Numer = 'landParcel.number',
  NrL = 'landParcel.nominator',
  NrM = 'landParcel.denominator',
  NrAdd = 'landParcel.additionalNumber',
  Identyfikator = FieldConfigType.LandParcelId,
  NumerEwidencyjny = 'premises.cadastralNumber',
  NumerBudWObrebie = 'premises.buildingNumberInDistrict',
  NumerBudWDzialce = 'premises.buildingNumberInLandParcel',
  NumerPunktu = 'numbers.number',
  StaryNumerPunktu = 'numbers.oldNumber',
  Sekcja = 'section',
  Nazwa = 'name',
  NrGlowicy = 'cylinderNumber',
  IdOsnowa = 'controlNetworks.horizontal',
  IdOsnowaWys = 'controlNetworks.vertical',
  IdUklPoz = 'coordinateSystems.horizontal',
  IdUklWys = 'coordinateSystems.vertical',
}

export const BasicFieldContainsTypeSearch = ['NumerPunktu', 'StaryNumerPunktu'];

export enum AddressSearchFieldPath {
  MiejscowoscId = 'address.place.id',
  UlicaId = 'address.street.id',
  NumerBudynku = 'addressDetails.buildingNumber',
  NumerLokalu = 'addressDetails.premisesNumber',
  Kod = 'addressDetails.postalCode',
}

export enum BuildingSearchFieldPath {
  From = 'building.cadastralNumberFrom',
  To = 'building.cadastralNumberTo',
}

export enum LandAndMortgageRegisterSearchFieldPath {
  KwWydzialId = 'landAndMortgageRegister.landAndMortgageRegisterDepartment',
  KwNumer = 'landAndMortgageRegister.landAndMortgageRegisterNumber',
}

export class AjaxFilter {
  constructor(
    public field: string,
    public value: { [key: string]: string } | string | string[],
    public operator = FilterOperatorType.Equals,
  ) {}
}

export interface AjaxFilterWrapper {
  filter: {
    logic: 'and';
    filters: AjaxFilter[];
  };
  take: number;
}

export enum AjaxRequestStatus {
  DataNotFound = 'data-not-found',
  DataFound = 'data-found',
  Error = 'error',
  NoFilterApplied = 'no-filter-applied',
  Idle = 'idle',
  Pending = 'pending',
  NotAllDataPreviewed = 'not-all-data-previewed',
  NoPlaceId = 'no-place-id',
  NoStreetId = 'no-street-id',
}
