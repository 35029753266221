import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import {
  FieldConfig,
  FieldConfigType,
  NestedGroupFieldConfig,
} from './gk-dynamic-form.model';

@Component({
  selector: 'gk-dynamic-form',
  styleUrls: ['dynamic-form.component.scss'],
  templateUrl: './dynamic-form.component.html',
  standalone: false,
})
export class DynamicFormComponent implements OnInit {
  @Input()
  fieldConfigs: FieldConfig[] = [];
  @Input()
  submitted = false;
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.fb.group({});

    const formControlConfigs = this.getDataFieldConfigs();
    formControlConfigs.forEach((controlConfig) => {
      this.createControl(this.form, controlConfig);
    });
  }

  private getDataFieldConfigs(): FieldConfig[] {
    return this.fieldConfigs
      ? this.fieldConfigs.filter((config) => {
          if (this.isDataConfigType(config)) {
            return config.type;
          }

          return undefined;
        })
      : [];
  }

  private isDataConfigType(config: FieldConfig): boolean {
    return !_.includes(
      [FieldConfigType.SectionTitle, FieldConfigType.Separator],
      config.type,
    );
  }

  private createControl(
    destinationGroup: UntypedFormGroup,
    fieldConfig: FieldConfig,
  ): void {
    if (fieldConfig.type === FieldConfigType.NestedGroup) {
      const nestedFormGroup = this.fb.group({});
      (fieldConfig as NestedGroupFieldConfig).children.forEach((config) => {
        this.createControl(nestedFormGroup, config);
      });

      destinationGroup.addControl(fieldConfig.name, nestedFormGroup);
    } else {
      destinationGroup.addControl(
        fieldConfig.name,
        this.createFormControl(fieldConfig),
      );
    }
  }

  private createFormControl(config: FieldConfig): UntypedFormControl {
    let value = config.value;
    if (config.type === FieldConfigType.Checkbox && !_.isBoolean(value)) {
      value = false;
    }
    const disabled = config.disabled;

    return this.fb.control(
      { disabled, value },
      Validators.compose(config.validators),
    );
  }
}
