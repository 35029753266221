import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeWhile } from 'rxjs/operators';
import { ModalUtils } from '../../../gk-map/utils';
import { GkModalNewMessagesListComponent } from '../../../gk-modal-new-messages-list/gk-modal-new-messages-list.component';
import { MessagesListConfig } from '../../gk-dynamic-list.model';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'gk-new-messages',
  templateUrl: './new-messages.component.html',
  styleUrls: ['./new-messages.component.scss'],
  standalone: false,
})
export class NewMessagesComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input() messagesListConfig: MessagesListConfig;
  isNewMessages = false;

  constructor(
    protected dataService: DataService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.shouldShowNewMessageNotation();
  }

  showNewMessagesModal(): void {
    const modalRef = this.modalService.open(
      GkModalNewMessagesListComponent,
      ModalUtils.getModalOptions(),
    ).componentInstance as GkModalNewMessagesListComponent;
    modalRef.columns = this.messagesListConfig.columnHeader;
    modalRef.messagesListRequestConfig =
      this.messagesListConfig.messagesListRequestConfig;
    modalRef.callbackParseDataToApp = (item: any) =>
      this.messagesListConfig.parseCallback(item);
    modalRef.callbackButtonConfig =
      this.messagesListConfig.callbackButtonConfig;
    modalRef.showMessageNotation
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.shouldShowNewMessageNotation());
  }

  shouldShowNewMessageNotation(): void {
    this.dataService
      .getData(this.messagesListConfig.controlDataConfig, [])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => (this.isNewMessages = !!data));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
