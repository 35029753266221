import { CommonModule, registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { GkComponentsModule } from '../gk-components/gk-components.module';
import { GkModalConfirmComponent } from '../gk-modal-confirm/gk-modal-confirm.component';
import { PaymentButtonComponent } from './payment-button/payment-button.component';
import { PaymentDetailsModalComponent } from './payment-details-modal/payment-details-modal.component';
import { PaymentsService } from './payments.service';
import { PaymentsComponent } from './payments/payments.component';
import { PendingComponent } from './payments/pending/pending.component';
import { UnpaidComponent } from './payments/unpaid/unpaid.component';

registerLocaleData(localePl, 'pl');

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    GkComponentsModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    ToastrModule.forRoot(),
    NgbModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pl',
    },
    PaymentsService,
  ],
  exports: [PaymentsComponent, PaymentButtonComponent, GkModalConfirmComponent],
  declarations: [
    PaymentsComponent,
    PaymentButtonComponent,
    PendingComponent,
    UnpaidComponent,
    GkModalConfirmComponent,
    PaymentDetailsModalComponent,
  ],
})
export class GkPaymentsModule {}
