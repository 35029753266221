import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import {
  LBRDURNewDataUpdateRequestControlName,
  LBRDURNewDataUpdateRequestFormGroup,
} from './new-data-update-request-form.model';

@Injectable()
export class LBRDURNewDataUpdateRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): FormGroup<LBRDURNewDataUpdateRequestFormGroup> {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [LBRDURNewDataUpdateRequestControlName.ProxyDetailsCheckbox]: false,
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [LBRDURNewDataUpdateRequestControlName.ChangeScope]: [
        '',
        [Validators.required],
      ],
      [LBRDURNewDataUpdateRequestControlName.DemonstrationOfApplicantRight]: [
        undefined,
        [Validators.required],
      ],
      [LBRDURNewDataUpdateRequestControlName.DeterminationOfLocation]: [''],
    });
  }
}
