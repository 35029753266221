import { Directive, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import {
  MapAction,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  MapSheetFormValue,
  MapState,
  MapViewActionType,
  SourceState,
  SourceType,
  ToolActionType,
  ToolsState,
  ToolState,
  ToolType,
} from '../../models';
import { ConversionUtils, MapExtentUtils, ShapeUtils } from '../../utils';

@Directive()
export class MapControl {
  @Input()
  mapState!: MapState;
  @Input()
  toolType!: ToolType;
  @Input()
  sourceType!: SourceType;
  @Output()
  dispatch = new EventEmitter<MapAction>();

  getToolTypes(toolsState: ToolsState): ToolType[] {
    return Object.keys(toolsState) as ToolType[];
  }

  getSourceTypes(toolState: ToolState): SourceType[] {
    return _.intersection(
      Object.keys(toolState) as SourceType[],
      Object.keys(SourceType) as SourceType[],
    );
  }

  getActiveTool(toolsState: ToolsState): ToolType | undefined {
    return this.getToolTypes(toolsState).find(
      (toolType) => toolsState[toolType].isActive,
    );
  }

  getActiveSource(
    toolsState: ToolsState,
    toolType: ToolType,
  ): SourceType | undefined {
    return this.getSourceTypes(toolsState[toolType]).find(
      (sourceType) => toolsState[toolType][sourceType].isActive,
    );
  }

  getActiveSourceState(toolsState: ToolsState): SourceState | undefined {
    const activeTool = this.getActiveTool(toolsState);
    if (activeTool) {
      const activeSource = this.getActiveSource(toolsState, activeTool);
      if (activeSource) {
        return toolsState[activeTool][activeSource];
      }

      return undefined;
    }

    return undefined;
  }

  dispatchExtentToFitTo(mapObjects: MapObject[]): void {
    this.dispatch.emit(
      new MapAction(
        MapViewActionType.ExtentToFitToChange,
        MapExtentUtils.getMapExtentFromMapObjects(mapObjects),
      ),
    );
  }

  dispatchSelect(mapObjects: MapObject[]): void {
    this.dispatch.emit(
      new MapAction(MapObjectTableActionType.Select, mapObjects),
    );
  }

  dispatchMapSheetMapObjects(
    toolType: ToolType,
    formValue: MapSheetFormValue,
  ): void {
    const polygon = ShapeUtils.getPolygon(
      ShapeUtils.getMapSheetCoordinates(formValue),
    );
    const mapObject = ShapeUtils.getAnyGeometryMapObject(
      ConversionUtils.getWktFromGeometry(polygon),
      MapObjectApiType.ExtentOrPolygon,
      formValue.scale,
      formValue.paper,
    );

    this.dispatch.emit(
      new MapAction(ToolActionType.MapObjectsToolChange, {
        value: [mapObject],
        options: { toolType, newSet: true },
      }),
    );
  }

  areLandParcelAndRangeDefinedInToolTypesConfiguration(): boolean {
    return [ToolType.LandParcel, ToolType.AnyPolygon].every((toolType) =>
      this.mapState?.toolbarState?.toolTypes?.includes(toolType),
    );
  }
}
