import { Component, Input } from '@angular/core';
import { SystemConfigBlobGuestService } from '../../services/system-config-blob-guest/system-config-blob-guest.service';
import { SafeHtml } from '@angular/platform-browser';
import { TypeOfAccessibilityStatement } from './accessibility-statement.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'gk-accessibility-statement',
  templateUrl: './accessibility-statement.component.html',
  styles: [],
  standalone: false,
})
export class AccessibilityStatementComponent {
  @Input() statementType: string;

  constructor(
    public systemConfigBlobGuestService: SystemConfigBlobGuestService,
  ) {}

  getHtmlContent(): Observable<SafeHtml> {
    switch (this.statementType) {
      case TypeOfAccessibilityStatement.ForClient:
        return this.systemConfigBlobGuestService.getAccessibilityStatementHtmlContentForClientPortal();
      case TypeOfAccessibilityStatement.Regular:
        return this.systemConfigBlobGuestService.getAccessibilityStatementHtmlContent();
      case TypeOfAccessibilityStatement.Mobile:
        return this.systemConfigBlobGuestService.getAccessibilityMobileStatementHtmlContent();
      default:
        throw Error('Unhandled type of accessibility statement');
    }
  }
}
