import { Coordinate } from 'ol/coordinate';

export interface ClickedPointCoords {
  coords: Coordinate;
  name: string;
}

export interface CoordinationLayerResponseRaw {
  Id: number;
  Nazwa: string;
  Epsg: number;
}

export class CoordinationLayerResponse {
  constructor(
    public id: number,
    public name: string,
    public epsg: number,
  ) {}

  static fromApiToApp(
    coordinationLayerResponseRaw: CoordinationLayerResponseRaw,
  ): CoordinationLayerResponse {
    return new this(
      coordinationLayerResponseRaw.Id,
      coordinationLayerResponseRaw.Nazwa,
      coordinationLayerResponseRaw.Epsg,
    );
  }
}

export interface ApplicantIsEntityForMapObjectResponseDto {
  IdObiektu: string;
  TypObiektu: string;
  CzyPodmiot: boolean;
}

export class ApplicantIsEntityForMapObjectResponse {
  constructor(
    public id: string,
    public type: string,
    public isEntity: boolean,
    public isEntityDescription?: string,
  ) {}

  static fromApiToApp(
    applicantIsEntityForMapObjectResponseDto: ApplicantIsEntityForMapObjectResponseDto,
  ): ApplicantIsEntityForMapObjectResponse {
    return new this(
      applicantIsEntityForMapObjectResponseDto.IdObiektu,
      applicantIsEntityForMapObjectResponseDto.TypObiektu,
      applicantIsEntityForMapObjectResponseDto.CzyPodmiot,
    );
  }
}

export type ApplicantIsEntityPostDto =
  | { DzialkiIds: Array<string | number> }
  | { BudynkiIds: Array<string | number> }
  | { DzialkiIds: Array<string | number>; BudynkiIds: Array<string | number> };
