<div
  class="map"
  #kendoContextMenu
  [ngClass]="{ 'e-services-heights': isEservicePortal() }"
>
  @if (mapState.viewState.isSidebarExpanded) {
    <gk-layers-tree-sidebar
      [mapState]="mapState"
      (dispatch)="dispatch.emit($event)"
    ></gk-layers-tree-sidebar>
  }

  <aol-map
    #map
    [contextMenu]="mapContextMenu"
    (moveEnd)="handleMoveEnd($event)"
    (pointerMove)="handleMovingPointer($event)"
  >
    <aol-interaction-doubleclickzoom
      [duration]="20"
    ></aol-interaction-doubleclickzoom>
    <aol-interaction-dragzoom [duration]="20"></aol-interaction-dragzoom>
    <aol-interaction-mousewheelzoom
      [duration]="0"
    ></aol-interaction-mousewheelzoom>
    <aol-interaction-dragpan [kinetic]="kinetic"></aol-interaction-dragpan>
    <aol-interaction-pinchzoom
      [constrainResolution]="true"
    ></aol-interaction-pinchzoom>
    @if (shouldEnableSelection()) {
      <aol-interaction-select
        (olSelect)="handleFeatureSelection($event)"
        [condition]="pointerMoveSelection"
      ></aol-interaction-select>
    }
    @if (isEditedMapObject()) {
      <aol-interaction-modify
        [features]="editableFeatures"
        (olModifyStart)="handleTableFeatureModificationStart()"
        (olModifyEnd)="
          handleTableFeatureModificationEnd($any($event.features.getArray()[0]))
        "
      ></aol-interaction-modify>
    }
    @if (shouldEnableTranslation()) {
      <aol-interaction-translate
        [features]="movableFeatures"
        (translateStart)="handleTranslationStart($event)"
        (translateEnd)="handleTranslationEnd($event)"
      ></aol-interaction-translate>
    }
    @for (toolType of mapState.toolbarState.toolTypes; track $index) {
      @for (
        sourceType of getSourceTypes(mapState.toolsState[toolType]);
        track $index
      ) {
        @if (
          drawingInProgress &&
          isMeasurement(toolType) &&
          shouldShowTooltip(toolType, sourceType)
        ) {
          <gk-map-measurement-result
            [movingPointerCoordinate]="movingPointerCoordinate"
            [toolType]="toolType"
            [sourceType]="sourceType"
            [mapState]="mapState"
            [sketch]="sketch"
          ></gk-map-measurement-result>
        }
        @if (
          isMeasurement(toolType) && shouldShowTooltip(toolType, sourceType)
        ) {
          @for (
            mapMeasurementStaticResult of mapState.toolsState[toolType]
              ?.measurementStaticOverlays;
            track $index
          ) {
            <gk-map-measurement-result
              [movingPointerCoordinate]="
                mapMeasurementStaticResult.movingPointerCoordinate
              "
              [sourceType]="mapMeasurementStaticResult.sourceType"
              [mapState]="mapState"
              [sketch]="mapMeasurementStaticResult.sketch"
            ></gk-map-measurement-result>
          }
        }
        @if (shouldShowPopup(toolType, sourceType)) {
          <gk-map-popup
            [toolType]="toolType"
            [sourceType]="sourceType"
            [mapState]="mapState"
            (dispatch)="dispatch.emit($event)"
          ></gk-map-popup>
        }
      }
      @if (shouldShowPopupControl(toolType)) {
        <gk-popup-control
          [mapState]="mapState"
          [toolType]="toolType"
          (dispatch)="dispatch.emit($event)"
        ></gk-popup-control>
      }
    }

    @if (shouldShowTooltip()) {
      <gk-map-tooltip
        [movingPointerCoordinate]="movingPointerCoordinate"
        [toolType]="getActiveTool(this.mapState.toolsState)!"
        [sourceType]="
          getActiveSource(
            this.mapState.toolsState,
            getActiveTool(this.mapState.toolsState)!
          )!
        "
        [mapState]="mapState"
      ></gk-map-tooltip>
    }

    @if (shouldEnableDrawing()) {
      <aol-interaction-draw
        #interactionDraw
        [type]="$any(getActiveSourceType())"
        [style]="getDrawInteractionStyle()"
        (drawStart)="handleDrawStart($event)"
        (drawEnd)="handleDrawEnd($event)"
      ></aol-interaction-draw>
    }

    @if (isEnclaveSourceActive()) {
      <aol-interaction-draw-hole-in-polygon
        (drawEnd)="handleTableFeatureModificationEnd($event)"
        (drawError)="onEnclaveDrawError($event)"
      ></aol-interaction-draw-hole-in-polygon>
    }

    @if (shouldShowGeometry()) {
      <gk-map-geometry
        [mapObjects]="
          mapState.toolsState[getActiveTool(this.mapState.toolsState)]
            .mapObjects
        "
        [styles]="
          mapState.toolsState[getActiveTool(this.mapState.toolsState)]
            .mapGeometryStyleConfig
        "
        [projectionCode]="mapState.viewState.nativeProjectionCode"
        [source]="
          getActiveSource(
            this.mapState.toolsState,
            getActiveTool(this.mapState.toolsState)!
          )!
        "
        (dispatch)="dispatch.emit($event)"
      ></gk-map-geometry>
    }

    <aol-control-zoom
      [zoomInLabel]="zoomInNode"
      [zoomInTipLabel]="'GK.MAP.ZOOM_IN' | translate"
      [zoomOutLabel]="zoomOutNode"
      [zoomOutTipLabel]="'GK.MAP.ZOOM_OUT' | translate"
    ></aol-control-zoom>
    <aol-control-zoomtoextent
      [label]="zoomToFullExtentNode"
      [tipLabel]="'GK.MAP.ZOOM_TO_FULL_EXTENT' | translate"
      [extent]="fullDefaultExtent"
    ></aol-control-zoomtoextent>
    <aol-control-scaleline></aol-control-scaleline>
    @if (movingPointerCoordinate) {
      <aol-control-mouseposition
        [projection]="mapState.viewState.nativeProjectionCode"
        [coordinateFormat]="coordinateFormat"
      ></aol-control-mouseposition>
    }
    @if (mapState.viewState.backScrollIntoViewRef) {
      <button
        type="button"
        id="btn-back-scroll-into-view"
        class="btn btn-color-from-db mb-md-0 mb-5"
        (click)="backScrollIntoView()"
      >
        {{ 'GK.MAP.BACK_TO_PREVIOUS' | translate }}
      </button>
    }
    @if (mapState.viewState.previewModeState?.enabled) {
      <div
        class="map-drawing-footer position-absolute m-0 p-0 d-flex justify-content-end align-items-center"
      >
        <button
          type="button"
          class="btn btn-light btn-sm mx-2"
          (click)="onGridElementPreviewCancel()"
        >
          {{ 'GK.MAP.CANCEL' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-light btn-sm mx-2"
          (click)="
            onGridElementPreviewDone(
              mapState.viewState.previewModeState?.doneCallback!
            )
          "
        >
          {{ 'GK.MAP.SUBMIT' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-light btn-sm mx-2"
          (click)="onGridElementPreviewExit()"
        >
          {{ 'GK.MAP.PREVIEW_MODE_EXIT' | translate }}
        </button>
      </div>
    }
    <gk-help-control></gk-help-control>

    @if (!isEservicePortal()) {
      <gk-map-pin-control
        [mapState]="mapState"
        (dispatch)="dispatch.emit($event)"
      ></gk-map-pin-control>
    }

    <aol-view
      [resolution]="mapState.viewState.resolution"
      [zoom]="0"
      [resolutions]="resolutions"
      [constrainResolution]="true"
    >
      <aol-coordinate
        [x]="center[0]"
        [y]="center[1]"
        [srid]="mapState.viewState.nativeProjectionCode"
      >
      </aol-coordinate>
    </aol-view>

    <gk-wms-layers
      [mapState]="mapState"
      [mapInstance]="map.instance"
      (dispatch)="dispatch.emit($event)"
      (shouldLoadGeometryLayers)="loadGeometryLayers($event)"
    ></gk-wms-layers>

    @if (shouldShowGeometryForSpecificMapObjectTableMapObjects()) {
      <gk-map-geometry
        [mapObjects]="getCurrentActiveMapObjectTableState().selectedMapObjects"
        [styles]="getCurrentActiveMapObjectTableState().mapGeometryStyleConfig"
        [projectionCode]="mapState.viewState.nativeProjectionCode"
        [source]="addOnTypeEnum.Table"
        (dispatch)="dispatch.emit($event)"
      ></gk-map-geometry>
    }
  </aol-map>

  @if (!isEservicePortal() && canOpenMapMousePosition()) {
    <gk-map-mouse-position
      [currentCoordinates]="movingPointerCoordinate"
      [coordinationLayers]="coordinationLayers"
    ></gk-map-mouse-position>
  }
</div>

<span #zoomInNode> <i class="fa fa-plus" aria-hidden="true"></i> </span>
<span #zoomOutNode> <i class="fa fa-minus" aria-hidden="true"></i> </span>
<span #zoomToFullExtentNode>
  <i class="gk-map-icon-zoom-to-extent" aria-hidden="true"></i>
</span>

<context-menu
  [disabled]="!(isPolygonDrawing() || isLineStringDrawing())"
  #mapContextMenu
>
  <ng-template contextMenuItem>
    <div (click)="enterInteractionDraw()">
      {{ 'GK.MAP.ACCEPT' | translate }}
    </div>
  </ng-template>
  <ng-template contextMenuItem>
    <div (click)="cancelInteractionDraw()">
      {{ 'GK.MAP.CANCEL' | translate }}
    </div>
  </ng-template>
</context-menu>

@if (
  !(isPolygonDrawing() || isLineStringDrawing()) &&
  !isEservicePortal() &&
  this.canOpenKendoContextMenu()
) {
  <kendo-contextmenu
    [target]="kendoContextMenu"
    [items]="menuItems"
    [appendTo]="kendoContextMenuContainer"
    (select)="onMenuSelect($event)"
    (popupOpen)="onMenuOpen()"
  >
    <ng-template kendoMenuItemTemplate let-item="item">
      @if (item.layerItem) {
        <div
          class="context-layer-item"
          (contextmenu)="onCoordRightClick($event, item.coords)"
        >
          <span class="coord-numbers" (click)="onCoordClick($event)">
            {{ item.coords[0] }}, {{ item.coords[1] }}
          </span>
          <span class="coord-name">{{ item.name }}</span>
        </div>
      } @else {
        <span [innerHTML]="item.text"></span>
      }
    </ng-template>
  </kendo-contextmenu>
}
