export interface ApiDocType {
  Id: number;
  Nazwa: string;
  Prefix: string;
  NazdokId?: number;
  IsObligatory?: boolean;
  MaxSize?: number;
}

export class DocType<T = number> {
  public labelKey?: string;
  public downloadLabelKey?: string;
  public uploadAvailable = true;
  constructor(
    public id: T,
    public name: string,
    public sqlPattern?: SqlPattern,
    public isObligatory?: boolean,
    public matchingRequestFileId?: string,
    public maxSize?: number,
  ) {}

  static fromApiToApp(apiDocType: ApiDocType): DocType {
    return new this(
      apiDocType.Id,
      apiDocType.Nazwa,
      apiDocType.Prefix,
      apiDocType.IsObligatory,
      undefined,
      apiDocType.MaxSize,
    );
  }
}

export const powerOfAttorneyDocTypeId = 10035;

export type SqlPattern = string | null;
/*
  possible patterns:
 'ane%' - startsWith
 '%dz%' - contains
 '%.zip' - endsWith
*/
