import { Injectable } from '@angular/core';
import { PortalId } from '@gk/gk-modules';
import { map, Observable } from 'rxjs';
import { PortalConfigGetter } from '../../services/portal-config/portal-config.interface';
import {
  defaultPortalConfig,
  PortalConfig,
} from '../../services/portal-config/portal-config.model';
import { InterModLoginTypeService } from './intermod-login-type.service';

@Injectable({
  providedIn: 'root',
})
export class InterModConfigService implements PortalConfigGetter {
  constructor(private interModLoginTypeService: InterModLoginTypeService) {}
  getPortalConfig(): Observable<PortalConfig> {
    return this.interModLoginTypeService.getAvailableTypes().pipe(
      map((loginType) => {
        const config: PortalConfig = {
          ...defaultPortalConfig,
          isPerunAuthenticationAvailable: loginType.ewidLogging,
          isPzAuthenticationAvailable: loginType.wkLogging,
          portalId: PortalId.InterMod,
          redirectCookieEnabled: true,
          isNavbarVisible: false,
          sessionToolsInfoAlertsVisible: true,
          bothPersonsLoginInfoKey: 'FIRST_EXTERNAL_LOGIN_VIA_SURVYOR_PORTAL',
          naturalPersonLoginInfoKey: 'FIRST_EXTERNAL_LOGIN_VIA_SURVYOR_PORTAL',
          legalPersonLoginInfoKey: null,
        };

        return config;
      }),
    );
  }
}
