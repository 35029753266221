import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ColumnHeader,
  defaultMapGeometryStyles,
  initialToolsStateWithButtonText,
  ListConfigs,
  MapAction,
  MapId,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  SourceType,
  ToolType,
} from '@gk/gk-modules';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs';
import { ConfigManagerService } from '../../../services/config-manager/config-manager.service';

@Component({
  selector: 'app-free-services-map',
  templateUrl: './free-services-map.component.html',
  standalone: false,
})
export class FreeServicesMapComponent implements OnInit, OnDestroy {
  private isAlive = true;
  freeServicesMapState = MapState.getInitialStruct();
  initialToolsState = _.cloneDeep(initialToolsStateWithButtonText);
  defaultMapGeometryStyles = _.cloneDeep(defaultMapGeometryStyles);
  availableParcelsListConfigs: ListConfigs;

  constructor(
    private mapSettingsService: MapSettingsService,
    private mapStateService: MapStateService,
    private configManagerService: ConfigManagerService,
  ) {}

  ngOnInit(): void {
    this.subscribeToMapSettings();
    this.fetchAvailableParcelsListConfigs();
  }

  subscribeToMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.FreeServices)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((freeServicesMapSettings) =>
        this.setFreeServicesMapState(freeServicesMapSettings),
      );
  }

  fetchAvailableParcelsListConfigs(): void {
    this.availableParcelsListConfigs =
      this.configManagerService.getConfigForAvailableParcelsList();
  }

  setFreeServicesMapState(freeServicesMapSettings: MapSettings): void {
    this.freeServicesMapState = new MapState(
      MapId.FreeServices,
      this.mapStateService.getViewState(
        MapId.FreeServices,
        freeServicesMapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.Info, ToolType.LandParcel],
        freeServicesMapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      {
        ...this.initialToolsState,
        [ToolType.LandParcel]: {
          buttonText: 'GK.MAP.TOOL_TYPE.LAND_PARCEL',
          isActive: false,
          icon: 'gk-map-icon-select-land-parcel',
          label: 'GK.MAP.SELECT_LAND_PARCEL',
          mapObjects: [],
          mapGeometryStyleConfig:
            this.initialToolsState[ToolType.LandParcel].mapGeometryStyleConfig,
          [SourceType.AttributesForm]:
            this.initialToolsState[ToolType.LandParcel][
              SourceType.AttributesForm
            ],
        },
      },
      this.mapStateService.getLayersState(
        MapId.FreeServices,
        freeServicesMapSettings,
        MapPortalName.FreeServices,
      ),
      [
        new MapObjectTableState(
          [
            new ColumnHeader('districtName', 'GK.MAP.DISTRICT'),
            new ColumnHeader('mapObjectNumber', 'GK.MAP.PARCEL_NUMBER'),
          ],
          undefined,
          this.defaultMapGeometryStyles,
          true,
          false,
          true,
        ),
      ],
    );
  }

  handleMapAction(mapAction: MapAction): void {
    this.freeServicesMapState = this.mapStateService.getUpdatedMapState(
      mapAction,
      this.freeServicesMapState,
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
