import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPerson,
} from '@gk/gk-modules';
import { WorkspaceState } from '../../../../services/request-workspace-state/request-workspace-state.model';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import { PropertyUsage } from '../business-purposes-checkbox-control/business-purposes-checkbox-control.model';

export interface OCFCRNewRequestDto extends ApplicantRequestDto {
  InfoDodatkowe: string;
  Bonifikata99: boolean;
  LokalLubBudynekWylacznieDoPotrzebMieszkaniowych: boolean;
  NieruchomoscDoCelowProwadzeniaDzialalnosciCalosc: boolean;
  NieruchomoscDoCelowProwadzeniaDzialalnosciCzesc: boolean;
  ProcentCzesciPrzeznaczonejNaDzialalnosc: number;
}

export class OCFCRNewRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public comments: string,
    public propertyUsage: PropertyUsage,
    public discountConditions: boolean,
    public forHousingPurposes: boolean,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: OCFCRNewRequest,
    issueRanges: string[],
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): OCFCRNewRequestDto {
    const isBusinessUsage = data.propertyUsage?.businessUsage;
    const isBusinessUsageFull = isBusinessUsage
      ? data.propertyUsage.businessUsageFull
      : false;
    const isBusinessUsagePart = isBusinessUsage
      ? data.propertyUsage.businessUsageFull === false
      : false;
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        issueRanges,
        files,
        parcels,
      }),
      InfoDodatkowe: data.comments,
      NieruchomoscDoCelowProwadzeniaDzialalnosciCalosc: isBusinessUsageFull,
      NieruchomoscDoCelowProwadzeniaDzialalnosciCzesc: isBusinessUsagePart,
      ProcentCzesciPrzeznaczonejNaDzialalnosc: isBusinessUsagePart
        ? data.propertyUsage.businessUsageShare
        : null,
      Bonifikata99: data.discountConditions,
      LokalLubBudynekWylacznieDoPotrzebMieszkaniowych: data.forHousingPurposes,
    };
  }
}

export interface OCFCRNewRequestFormWorkspaceState extends WorkspaceState {
  formValue: OCFCRNewRequest;
  parcels: EgibObject[];
  issueRanges: string[];
}
