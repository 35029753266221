import { BaseNewRequestControlName } from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum PLNewRequestAdditionalControlName {
  Place = 'place',
  Street = 'street',
  AreaToLease = 'areaToLease',
  Comments = 'comments',
  LeaseDate = 'leaseDate',
  Purpose = 'purpose',
}

export const PLNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...PLNewRequestAdditionalControlName,
};
