<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <app-workspace-state
      [workspaceStateDraftVersion]="workspaceStateDraftVersion"
      [workspaceStateId]="workspaceStateId"
      [getWorkspaceStateToSave]="getWorkspaceStateToSave"
      (workspaceStateLoaded)="handleLoadWorkspaceResponse($event)"
    ></app-workspace-state>

    <section
      class="card mb-4"
      [ngClass]="
        !isLawPersonFormGroupValid() && submitted
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.APPLICANT_DETAILS.TITLE'
            | translate
        }}
      </div>

      <div class="card-body">
        <gk-law-person-simple-form
          [lawPersonFormGroup]="getLawPersonFormGroup()"
        >
        </gk-law-person-simple-form>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLocationFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.LOCATION'
              | translate
          }}
        </div>

        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6 col-12">
              <label class="text-nowrap" [attr.for]="controlName.Place">
                {{
                  'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.PLACE'
                    | translate
                }}
              </label>

              <input
                type="text"
                [name]="controlName.Place"
                [id]="controlName.Place"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.Place"
                [ngbTypeahead]="searchPlace"
                [resultFormatter]="formatter"
                [inputFormatter]="formatter"
                (focusout)="setPlaceByName()"
              />

              @if (shouldShowRequiredAlert(getPlaceFormControl())) {
                <div class="alert alert-danger">
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>

            <div class="col-md-6 col-12">
              <label class="text-nowrap" [attr.for]="controlName.Street">
                {{
                  'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.STREET'
                    | translate
                }}
              </label>

              <ng-container>
                /
                <input
                  type="checkbox"
                  class="me-1"
                  [id]="controlName.StreetAbsence"
                  [name]="controlName.StreetAbsence"
                  [formControlName]="controlName.StreetAbsence"
                />

                <label [attr.for]="controlName.StreetAbsence">
                  {{ 'ADDRESS_FORM.STREET_DISABLED' | translate }}
                </label>
              </ng-container>

              <input
                type="text"
                [name]="controlName.Street"
                [id]="controlName.Street"
                class="form-control form-control-sm required"
                aria-required="true"
                [ngbTypeahead]="searchStreet"
                [resultFormatter]="formatter"
                [inputFormatter]="formatter"
                [formControlName]="controlName.Street"
                (focusout)="setStreetByName()"
                [autocomplete]="undefined"
              />

              @if (shouldShowRequiredAlert(getStreetFormControl())) {
                <div class="alert alert-danger">
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>

            <div class="col-md-6 col-12">
              <label class="text-nowrap" [attr.for]="controlName.LandNumber">
                {{
                  'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.LAND_NUMBER'
                    | translate
                }}
              </label>

              <input
                type="text"
                [name]="controlName.LandNumber"
                [id]="controlName.LandNumber"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.LandNumber"
              />

              @if (shouldShowRequiredAlert(getLandNumberFormControl())) {
                <div class="alert alert-danger">
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>

            <div class="col-md-6 col-12">
              <label class="text-nowrap" [attr.for]="controlName.LandRegister">
                {{
                  'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.LAND_REGISTER'
                    | translate
                }}
              </label>

              <input
                type="text"
                [name]="controlName.LandRegister"
                [id]="controlName.LandRegister"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.LandRegister"
              />

              @if (shouldShowRequiredAlert(getLandRegisterFormControl())) {
                <div class="alert alert-danger">
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkParcelsAndIssueRangeValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.PARCELS'
            | translate
        }}
      </div>

      <div class="card-body">
        <gk-map
          [mapState]="mapState"
          (dispatch)="handleMapAction($event)"
        ></gk-map>

        <div class="mt-2">
          @if (mapState.mapObjectTablesState[0].mapObjects.length) {
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="0"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          }
        </div>
      </div>

      @if (checkParcelsAndIssueRangeValidAndFormValidated()) {
        <div class="card-footer">
          <div class="alert alert-danger mt-2">
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.PARCELS_REQUIRED'
                | translate
            }}
          </div>
        </div>
      }
    </section>

    <section class="px-4 pt-4 border border-dark rounded mb-4">
      <h2 class="mb-4">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.TREES_LIST'
            | translate
        }}
      </h2>

      <ng-container [formArrayName]="controlName.TreesList">
        @for (
          treeFormGroup of getTreesListFormArray().controls;
          track i;
          let i = $index
        ) {
          <section [formGroupName]="i">
            <div
              class="card mb-4"
              [ngClass]="
                checkTreeFormGroupValidAndFormValidated(i)
                  ? 'border-danger border-2'
                  : 'border-dark'
              "
            >
              <div class="card-header text-white bg-dark">
                {{
                  'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.TREE'
                    | translate
                }}
                #{{ i + 1 }}
                <button
                  (click)="deleteTreeFormControl(i)"
                  type="button"
                  aria-label="minus"
                  title="minus"
                  class="btn btn-danger fa fa-minus float-end"
                ></button>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-4">
                    <label
                      [attr.for]="
                        fellingObjectsControlName.InventoryNumber + '-tree-' + i
                      "
                    >
                      {{
                        'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.INVENTORY_NUMBER'
                          | translate
                      }}
                      <sup>1</sup>
                    </label>
                    <input
                      type="text"
                      [name]="
                        fellingObjectsControlName.InventoryNumber + '-tree-' + i
                      "
                      [id]="
                        fellingObjectsControlName.InventoryNumber + '-tree-' + i
                      "
                      class="form-control form-control-sm required"
                      aria-required="true"
                      [formControlName]="
                        fellingObjectsControlName.InventoryNumber
                      "
                    />
                    @if (
                      shouldShowRequiredAlert(
                        getTreeInventoryNumberFormControl(i)
                      )
                    ) {
                      <div class="alert alert-danger">
                        {{ 'FIELD_REQUIRED' | translate }}
                      </div>
                    }
                  </div>
                  <div class="col-4">
                    <label
                      [attr.for]="
                        fellingObjectsControlName.SpeciesName + '-tree-' + i
                      "
                    >
                      {{
                        'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.SPECIES_NAME'
                          | translate
                      }}
                      <sup>2</sup>
                    </label>
                    <input
                      type="text"
                      [name]="
                        fellingObjectsControlName.SpeciesName + '-tree-' + i
                      "
                      [id]="
                        fellingObjectsControlName.SpeciesName + '-tree-' + i
                      "
                      class="form-control form-control-sm required"
                      aria-required="true"
                      [formControlName]="fellingObjectsControlName.SpeciesName"
                    />
                    @if (
                      shouldShowRequiredAlert(getTreeSpeciesNameFormControl(i))
                    ) {
                      <div class="alert alert-danger">
                        {{ 'FIELD_REQUIRED' | translate }}
                      </div>
                    }
                  </div>
                  <div class="col-4">
                    <label
                      [attr.for]="
                        fellingObjectsControlName.TrunkCircumference + '-' + i
                      "
                    >
                      {{
                        'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.TRUNK_CIRCUMFERENCE'
                          | translate
                      }}
                      <sup>3</sup>
                    </label>
                    <div class="input-group input-group-sm">
                      <input
                        type="number"
                        [name]="
                          fellingObjectsControlName.TrunkCircumference + '-' + i
                        "
                        [id]="
                          fellingObjectsControlName.TrunkCircumference + '-' + i
                        "
                        class="form-control required"
                        aria-required="true"
                        [formControlName]="
                          fellingObjectsControlName.TrunkCircumference
                        "
                      />
                      <span class="input-group-text">cm</span>
                    </div>
                    @if (
                      shouldShowRequiredAlert(
                        getTreeTrunkCircumferenceFormControl(i)
                      )
                    ) {
                      <div class="alert alert-danger">
                        {{ 'FIELD_REQUIRED' | translate }}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        <section>
          <div
            class="card mb-4"
            [ngClass]="
              !areSomeTreesOrShrubsFormGroups() && submitted
                ? 'border-danger border-2'
                : 'border-dark'
            "
          >
            <div class="card-header text-white bg-dark">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.TREE'
                  | translate
              }}
            </div>

            <div class="card-body d-grid">
              <button
                (click)="addTreeFormControl()"
                type="button"
                aria-label="plus"
                title="plus"
                class="btn btn-lg btn-outline-dark fa fa-plus"
              ></button>
            </div>

            @if (!areSomeTreesOrShrubsFormGroups() && submitted) {
              <div class="card-footer">
                <div class="alert alert-danger mt-2">
                  {{
                    'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.VALIDATION.TREE_OR_SHRUB_REQUIRED'
                      | translate
                  }}
                </div>
              </div>
            }
          </div>
        </section>
      </ng-container>

      @if (areSomeTreesFormGroups()) {
        <div
          class="jumbotron py-3"
          [innerHTML]="
            'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.INFO.TREES_LIST'
              | translate
          "
        ></div>
      }
    </section>

    <section class="px-4 pt-4 border border-dark rounded mb-4">
      <h2 class="mb-4">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.SHRUBS_LIST'
            | translate
        }}
      </h2>

      <ng-container [formArrayName]="controlName.ShrubsList">
        @for (
          shrubFormGroup of getShrubsListFormArray().controls;
          track i;
          let i = $index
        ) {
          <section [formGroupName]="i">
            <div
              class="card mb-4"
              [ngClass]="
                checkShrubFormGroupValidAndFormValidated(i)
                  ? 'border-danger border-2'
                  : 'border-dark'
              "
            >
              <div class="card-header text-white bg-dark">
                {{
                  'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.SHRUB'
                    | translate
                }}
                #{{ i + 1 }}
                <button
                  (click)="deleteShrubFormControl(i)"
                  type="button"
                  aria-label="minus"
                  title="minus"
                  class="btn btn-danger fa fa-minus float-end"
                ></button>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-4">
                    <label
                      [attr.for]="
                        fellingObjectsControlName.InventoryNumber +
                        '-shrub-' +
                        i
                      "
                    >
                      {{
                        'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.INVENTORY_NUMBER'
                          | translate
                      }}
                      <sup>1</sup>
                    </label>
                    <input
                      type="text"
                      [name]="
                        fellingObjectsControlName.InventoryNumber +
                        '-shrub-' +
                        i
                      "
                      [id]="
                        fellingObjectsControlName.InventoryNumber +
                        '-shrub-' +
                        i
                      "
                      class="form-control form-control-sm required"
                      aria-required="true"
                      [formControlName]="
                        fellingObjectsControlName.InventoryNumber
                      "
                    />
                    @if (
                      shouldShowRequiredAlert(
                        getShrubInventoryNumberFormControl(i)
                      )
                    ) {
                      <div class="alert alert-danger">
                        {{ 'FIELD_REQUIRED' | translate }}
                      </div>
                    }
                  </div>
                  <div class="col-4">
                    <label
                      [attr.for]="
                        fellingObjectsControlName.SpeciesName + '-shrub-' + i
                      "
                    >
                      {{
                        'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.SPECIES_NAME'
                          | translate
                      }}
                      <sup>2</sup>
                    </label>
                    <input
                      type="text"
                      [name]="
                        fellingObjectsControlName.SpeciesName + '-shrub-' + i
                      "
                      [id]="
                        fellingObjectsControlName.SpeciesName + '-shrub-' + i
                      "
                      class="form-control form-control-sm required"
                      aria-required="true"
                      [formControlName]="fellingObjectsControlName.SpeciesName"
                    />
                    @if (
                      shouldShowRequiredAlert(getShrubSpeciesNameFormControl(i))
                    ) {
                      <div class="alert alert-danger">
                        {{ 'FIELD_REQUIRED' | translate }}
                      </div>
                    }
                  </div>
                  <div class="col-4">
                    <label
                      [attr.for]="
                        fellingObjectsControlName.ProjectionSurface + '-' + i
                      "
                    >
                      {{
                        'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.PROJECTION_SURFACE'
                          | translate
                      }}
                    </label>
                    <div class="input-group input-group-sm">
                      <input
                        type="number"
                        [name]="
                          fellingObjectsControlName.ProjectionSurface + '-' + i
                        "
                        [id]="
                          fellingObjectsControlName.ProjectionSurface + '-' + i
                        "
                        class="form-control required"
                        aria-required="true"
                        [formControlName]="
                          fellingObjectsControlName.ProjectionSurface
                        "
                      />
                      <span class="input-group-text">m²</span>
                    </div>
                    @if (
                      shouldShowRequiredAlert(
                        getShrubProjectionSurfaceFormControl(i)
                      )
                    ) {
                      <div class="alert alert-danger">
                        {{ 'FIELD_REQUIRED' | translate }}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        <section>
          <div
            class="card mb-4"
            [ngClass]="
              !areSomeTreesOrShrubsFormGroups() && submitted
                ? 'border-danger border-2'
                : 'border-dark'
            "
          >
            <div class="card-header text-white bg-dark">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.SHRUB'
                  | translate
              }}
            </div>

            <div class="card-body d-grid">
              <button
                (click)="addShrubFormControl()"
                type="button"
                aria-label="plus"
                title="plus"
                class="btn btn-lg btn-outline-dark fa fa-plus"
              ></button>
            </div>

            @if (!areSomeTreesOrShrubsFormGroups() && submitted) {
              <div class="card-footer">
                <div class="alert alert-danger mt-2">
                  {{
                    'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.VALIDATION.TREE_OR_SHRUB_REQUIRED'
                      | translate
                  }}
                </div>
              </div>
            }
          </div>
        </section>
      </ng-container>

      @if (areSomeShrubsFormGroups()) {
        <div
          class="jumbotron py-3"
          [innerHTML]="
            'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.INFO.SHRUBS_LIST'
              | translate
          "
        ></div>
      }
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          checkFellingCauseFormControlValidAndFormValidated()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.FellingCause">
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.FELLING_CAUSE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.FellingCause"
            [id]="controlName.FellingCause"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.FellingCause"
          ></textarea>
        </div>

        <div class="card-footer">
          <p class="text-muted">
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.INFO.FELLING_CAUSE'
                | translate
            }}
          </p>

          @if (shouldShowRequiredAlert(getFellingCauseFormControl())) {
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          checkGrowthPlaceFormControlValidAndFormValidated()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.GrowthPlace">
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.GROWTH_PLACE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.GrowthPlace"
            [id]="controlName.GrowthPlace"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.GrowthPlace"
          ></textarea>
        </div>

        <div class="card-footer">
          <p class="text-muted">
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.INFO.GROWTH_PLACE'
                | translate
            }}
          </p>

          @if (shouldShowRequiredAlert(getGrowthPlaceFormControl())) {
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          checkBusinessPurposeFormControlValidAndFormValidated()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.BUSINESS_PURPOSE'
              | translate
          }}
        </div>

        <div class="card-body">
          <div class="form-check form-check-inline">
            <input
              type="radio"
              [id]="'felling-purpose-' + fellingPurpose.Business"
              [attr.name]="controlName.BusinessPurpose"
              [formControlName]="controlName.BusinessPurpose"
              [value]="fellingPurpose.Business"
              class="form-check-input"
            />

            <label
              class="form-check-label"
              [attr.for]="'felling-purpose-' + fellingPurpose.Business"
            >
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.IS_BUSINESS_PURPOSE'
                  | translate
              }}
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              type="radio"
              [id]="'felling-purpose-' + fellingPurpose.NotBusiness"
              [attr.name]="controlName.BusinessPurpose"
              [formControlName]="controlName.BusinessPurpose"
              [value]="fellingPurpose.NotBusiness"
              class="form-check-input"
            />

            <label
              class="form-check-label"
              [attr.for]="'felling-purpose-' + fellingPurpose.NotBusiness"
            >
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.IS_NOT_BUSINESS_PURPOSE'
                  | translate
              }}
            </label>
          </div>
        </div>

        @if (shouldShowRequiredAlert(getBusinessPurposeFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkFellingDateFormControlValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        <label class="text-nowrap" [attr.for]="controlName.FellingDate">
          {{
            'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.FELLING_DATE'
              | translate
          }}
        </label>
      </div>

      <div class="card-body">
        <div class="input-group">
          <input
            class="form-control form-control-sm required"
            aria-required="true"
            [attr.placeholder]="'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate"
            [id]="controlName.FellingDate"
            [name]="controlName.FellingDate"
            [formControlName]="controlName.FellingDate"
            ngbDatepicker
            #d="ngbDatepicker"
          />

          <button
            class="btn btn-outline-secondary fa fa-calendar"
            (click)="d.toggle()"
            type="button"
          ></button>
        </div>
      </div>

      <div class="card-footer">
        <p class="text-muted">
          {{
            'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.INFO.FELLING_DATE'
              | translate
          }}
        </p>

        @if (shouldShowRequiredAlert(getFellingDateFormControl())) {
          <div class="alert alert-danger">
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          checkInvestmentDescriptionFormControlValidAndFormValidated()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.InvestmentDescription">
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.INVESTMENT_DESCRIPTION'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            #investmentDescription
            [name]="controlName.InvestmentDescription"
            [id]="controlName.InvestmentDescription"
            class="form-control"
            [ngClass]="{ required: investmentDescription.required }"
            [attr.aria-required]="investmentDescription.required"
            [formControlName]="controlName.InvestmentDescription"
          ></textarea>
        </div>

        <div class="card-footer">
          <p class="text-muted">
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.INFO.INVESTMENT_DESCRIPTION'
                | translate
            }}
          </p>

          @if (shouldShowRequiredAlert(getInvestmentDescriptionFormControl())) {
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>
      </div>
    </section>

    <gk-attachments [portalId]="portalId" [submitted]="submitted">
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <button type="submit" class="btn btn-primary">
      {{
        'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.FIELDS.SUBMIT_REQUEST'
          | translate
      }}
    </button>

    <app-request-preview-button
      class="ms-2"
      [buttonClass]="'btn-outline-primary'"
      [getPreviewRequestBody]="getRequestBody"
      [previewRequestUrl]="
        '/api/interesant/wniosek/wycinkaDrzewIKrzewow/podglad'
      "
    ></app-request-preview-button>

    <app-request-cancel-button></app-request-cancel-button>

    <app-doc-sign-info
      [messageText]="docSignMsgTxt"
      [messageType]="docSignMsgType"
      [docSignUrl]="docSignUrl"
      [docSignPending]="docSignPending"
    ></app-doc-sign-info>

    <div class="mt-2"><app-form-legend></app-form-legend></div>
  </form>
</div>
