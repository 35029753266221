import {
  PaymentAmountType,
  StampDutyPaymentStatus,
} from '../../../../services';

export interface SampleDocumentApi {
  PlikId: number | string;
  NazwaWysw: string;
}
export class SampleDocument {
  constructor(
    public id: number | string,
    public displayName: string,
  ) {}

  static fromApiToApp(sampleDocumentApi: SampleDocumentApi): SampleDocument {
    return new this(sampleDocumentApi.PlikId, sampleDocumentApi.NazwaWysw);
  }
}

export interface AlternativeDocumentTypeApi {
  Id: number;
  Nazwa: string;
  MaxSize: number;
}

export interface StampDutyApi {
  KwotaTyp: PaymentAmountType;
  StatusZaplaty: StampDutyPaymentStatus;
  DataZaplaty: string;
}

export interface DocumentSectionApi {
  Id: number;
  Nazwa: string;
  IsObligatory: boolean;
  MaxSize: number;
  Pliki?: SampleDocumentApi[];
  AlternativeKdokRodz?: AlternativeDocumentTypeApi[];
  OplataSkarbowa: StampDutyApi;
}

export class AlternativeDocumentType {
  constructor(
    public id: number | string,
    public name: string,
    public maxSize: number,
  ) {}

  static fromApiToApp(
    apiAlternativeDocument: AlternativeDocumentTypeApi,
  ): AlternativeDocumentType {
    return new this(
      apiAlternativeDocument.Id,
      apiAlternativeDocument.Nazwa,
      apiAlternativeDocument.MaxSize,
    );
  }
}

export class StampDuty {
  constructor(
    public amountType: PaymentAmountType,
    public status: StampDutyPaymentStatus,
    public paymentDate: string,
    public isInitialPaid?: boolean,
    public isInitialPending?: boolean,
    public isPaymentEnabled?: boolean,
  ) {}

  static fromApiToApp(apiStampDuty: StampDutyApi): StampDuty {
    return new this(
      apiStampDuty.KwotaTyp,
      apiStampDuty.StatusZaplaty,
      apiStampDuty.DataZaplaty,
      apiStampDuty.StatusZaplaty === StampDutyPaymentStatus.Paid,
      apiStampDuty.StatusZaplaty === StampDutyPaymentStatus.Pending,
      !(
        apiStampDuty.StatusZaplaty === StampDutyPaymentStatus.NotApplicable ||
        apiStampDuty.StatusZaplaty === null
      ),
    );
  }
}

export class DocumentSection {
  public uploadAvailable = true;
  constructor(
    public id: number | string,
    public name: string,
    public isObligatory: boolean,
    public maxSize: number,
    public sampleDocuments?: SampleDocument[],
    public alternativeDocumentTypes?: AlternativeDocumentType[],
    public stampDuty?: StampDuty,
  ) {}

  static fromApiToApp(apiDocument: DocumentSectionApi): DocumentSection {
    return new this(
      apiDocument.Id,
      apiDocument.Nazwa,
      apiDocument.IsObligatory,
      apiDocument.MaxSize,
      apiDocument.Pliki?.map((file) => SampleDocument.fromApiToApp(file)),
      apiDocument.AlternativeKdokRodz?.map((alternativeDocument) =>
        AlternativeDocumentType.fromApiToApp(alternativeDocument),
      ),
      apiDocument.OplataSkarbowa
        ? StampDuty.fromApiToApp(apiDocument.OplataSkarbowa)
        : null,
    );
  }
}

export type ChosenDocumentSections = {
  [key: number | string]: File[];
};

export enum DocumentSectionTypeId {
  PowerOfAttorney = 10035,
  RequestStampDuty = 10040,
  PowerOfAttorneyStampDuty = 10075,
  ModernizationProject = 10074,
  OtherFile = 8,
}
