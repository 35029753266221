import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import {
  BPMDDecisionInformationControlName,
  BPMDNewRequestControlName,
  BPMDNewRequestFormGroup,
} from './new-request-form.model';

@Injectable()
export class BPMDNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): FormGroup<BPMDNewRequestFormGroup> {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [BPMDNewRequestControlName.ContactData]: true,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [BPMDNewRequestControlName.DecisionInformation]: this.formBuilder.group({
        [BPMDDecisionInformationControlName.PublicationDate]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [BPMDDecisionInformationControlName.DecisionNumber]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [BPMDDecisionInformationControlName.CaseNumber]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [BPMDDecisionInformationControlName.ConstructionProjectName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
    });
  }
}
