import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

@Component({
  selector: 'gk-gk-document-preview',
  imports: [PdfJsViewerModule, TranslateModule],
  templateUrl: './gk-document-preview.component.html',
  styleUrl: './gk-document-preview.component.scss',
})
export class GkDocumentPreviewComponent {
  pdfSrc: string | Blob | Uint8Array;
  imgSrc: string;
  textSrc: string;
}
