import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasicResponse } from '../../../utils/basic-response/basic-response.model';
import {
  GkCoordinate,
  OpenLayersGeometryType,
  PolygonsUnion,
  PolygonsUnionFromApi,
  PolygonTopologyValidation,
  PolygonValidationDto,
  Wkt,
} from '../../models';
import DxfWriter from 'dxf-writer';
import { GeomUtils } from '../../utils';
import GML from 'ol/format/GML';
import { WKT } from 'ol/format';
import Feature from 'ol/Feature';

@Injectable()
export class PolygonTopologyService {
  constructor(private http: HttpClient) {}

  getPolygonTopologyValidation(
    polygon: Wkt,
  ): Observable<PolygonTopologyValidation> {
    const params = {
      Wkt: polygon,
    };

    return this.http
      .post<PolygonValidationDto>('/api/mapa/geom/polygon/check', params)
      .pipe(
        map((data: PolygonValidationDto) =>
          PolygonTopologyValidation.fromApiToApp(data),
        ),
      );
  }

  convertRangeFileToWkt(fileString: string): Observable<Wkt> {
    let body;

    try {
      body = {
        File: btoa(fileString),
      };
    } catch (err) {
      return of('');
    }

    return this.http
      .post<BasicResponse<Wkt>>('/api/system/geometry/convert/file/wkt', body)
      .pipe(map((response) => response.Result));
  }

  convertRangeToTxt(geom: string): Observable<Wkt> {
    const body = {
      Wkt: geom,
    };

    return this.http.post('/api/system/geometry/convert/wkt/file', body, {
      responseType: 'text',
    });
  }

  convertRangeToDxf(geom: Wkt): Observable<string> {
    const writer = new DxfWriter();
    writer.setUnits('Meters');
    const geometry = GeomUtils.getGeometryFromWkt(geom);
    if (geometry.getGeometryType() !== OpenLayersGeometryType.Polygon) {
      throw new Error('Provided WKT is not a Polygon');
    }

    const exteriorCoords = geometry.getExteriorRing().getCoordinates();

    writer.drawPolyline(
      exteriorCoords.map((coord: GkCoordinate) => [coord.x, coord.y, 0]),
      true,
    );

    const numInteriorRings = geometry.getNumInteriorRing();
    Array.from({ length: numInteriorRings }).forEach((_, i) => {
      const interiorCoords = geometry.getInteriorRingN(i).getCoordinates();
      writer.drawPolyline(
        interiorCoords.map((coord: GkCoordinate) => [coord.x, coord.y, 0]),
        true,
      );
    });

    return of(writer.toDxfString());
  }

  convertRangeToGml(geom: Wkt): Observable<string> {
    const wktFormat = new WKT();
    const gmlFormat = new GML({
      featureNS: 'http://www.opengis.net/gml/3.2',
      featureType: 'Feature',
      srsName: 'EPSG:3857',
    });

    const olGeometry = wktFormat.readGeometry(geom);

    const gml = gmlFormat.writeFeaturesNode([
      new Feature({
        geometry: olGeometry,
      }),
    ]);

    const serializer = new XMLSerializer();
    return of(serializer.serializeToString(gml));
  }

  getPolygonsUnion(
    polygons: Wkt[],
    buffer?: number,
  ): Observable<PolygonsUnion> {
    const body = {
      Geom: polygons,
      Bufor: buffer,
    };

    return this.http
      .post<PolygonsUnionFromApi>('/api/mapa/geom/polygons/union', body)
      .pipe(map((data) => PolygonsUnion.fromApiToApp(data)));
  }
}
