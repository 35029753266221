import { FormControl, FormGroup } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum TSBPNewRequestAdditionalControlName {
  RequestInformation = 'requestInformation',
  WorksStartDate = 'worksStartDate',
}

export const TSBPNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...TSBPNewRequestAdditionalControlName,
};

export enum TSBPRequestInformationControlName {
  WorksType = 'worksType',
  RequestDate = 'requestDate',
}

export interface TSBPNewRequestFormGroup extends LawPersonNewRequestFormGroup {
  [TSBPNewRequestControlName.RequestInformation]: FormGroup<TSBPRequestInformationFormGroup>;
  [TSBPNewRequestControlName.WorksStartDate]: FormControl<NgbDate>;
}

export interface TSBPRequestInformationFormGroup {
  [TSBPRequestInformationControlName.WorksType]: FormControl<string>;
  [TSBPRequestInformationControlName.RequestDate]: FormControl<NgbDate>;
}
