import { Component } from '@angular/core';
import { FormBaseComponent } from '../../form-base/form-base.component';
import { SectionTitleFieldConfig } from '../../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-section-title',
  styleUrls: ['./form-section-title.component.scss'],
  templateUrl: './form-section-title.component.html',
  standalone: false,
})
export class FormSectionTitleComponent extends FormBaseComponent {
  override config: SectionTitleFieldConfig;
}
