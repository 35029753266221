import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { DynamicFormComponent } from '../../../../../gk-dynamic-form/gk-dynamic-form.component';
import {
  AttributesFormValue,
  FieldConfig,
} from '../../../../../gk-dynamic-form/gk-dynamic-form.model';
import { MapControl } from '../../../../controls';
import {
  AjaxFilterWrapper,
  AjaxRequestStatus,
  AttributesFormResult,
  AttributesFormState,
  MapAction,
  MapObjectTableActionType,
  MapObjectTableState,
  MapObjectsResponse,
  SourceType,
  ToolType,
} from '../../../../models';
import {
  AttributesFilterService,
  MapRequestsService,
  MapStateService,
} from '../../../../services';

@Component({
  selector: 'gk-attributes-form',
  templateUrl: './attributes-form.component.html',
  providers: [AttributesFilterService, MapRequestsService, MapStateService],
  standalone: false,
})
export class AttributesFormComponent
  extends MapControl
  implements AfterViewInit, OnInit, OnDestroy
{
  private isAlive = true;
  @ViewChild('formContainer')
  formContainer: DynamicFormComponent;
  override toolType: ToolType;
  formValue: AttributesFormValue;
  count: number;
  totalCount: number;
  mapObjectTableState: MapObjectTableState;
  requestStatus = AjaxRequestStatus.Idle;
  requestStatusEnum = AjaxRequestStatus;
  override sourceType = SourceType.AttributesForm;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private attributesFilterService: AttributesFilterService,
    private mapRequestsService: MapRequestsService,
    private mapStateService: MapStateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setMapObjectTableState();
  }

  ngAfterViewInit(): void {
    this.patchFormValueFromToolsState();
    this.listenToFormChangesAndDispatchSourceAction();
  }

  setMapObjectTableState(): void {
    this.mapObjectTableState = (
      this.mapState.toolsState[this.toolType][
        this.sourceType
      ] as AttributesFormState
    ).mapObjectTableState;
  }

  isSingleObjectMode(): boolean {
    const activeTool =
      this.mapState.toolsState[this.getActiveTool(this.mapState.toolsState)];

    return this.mapState && activeTool && activeTool.singleObjectMode;
  }

  patchFormValueFromToolsState(): void {
    this.formContainer.form.patchValue({
      ...this.getAttributesFormState().formValue,
    });
    this.formValue = this.formContainer.form.value;
    this.changeDetectorRef.detectChanges();
  }

  getAttributesFormState(): AttributesFormState {
    return this.mapState.toolsState[this.toolType][
      this.sourceType
    ] as AttributesFormState;
  }

  listenToFormChangesAndDispatchSourceAction(): void {
    this.formContainer.form.valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((formValue) => {
        this.requestStatus = AjaxRequestStatus.Idle;
        this.formValue = formValue;
      });
  }

  applyFilter(): void {
    const attributesFilter = this.attributesFilterService.getAttributesFilter(
      this.formValue,
      100,
    );
    if (!this.validateAddress()) {
      return;
    }
    if (!attributesFilter.filter.filters.length) {
      this.requestStatus = AjaxRequestStatus.NoFilterApplied;
      return;
    }

    this.searchMapObjects(attributesFilter);
  }

  validateAddress(): boolean {
    const place = this.formValue?.address?.place;
    const street = this.formValue?.address?.street;
    if (this.isElementNotFromDictionary(place)) {
      this.requestStatus = AjaxRequestStatus.NoPlaceId;

      return false;
    }
    if (this.isElementNotFromDictionary(street)) {
      this.requestStatus = AjaxRequestStatus.NoStreetId;

      return false;
    }

    return true;
  }

  isElementNotFromDictionary(
    element: { id: number | string; name: string } | string,
  ): boolean {
    const isStringElement = _.isString(element);
    return isStringElement ? element.length > 0 : element?.name && !element?.id;
  }

  searchMapObjects(attributesFilter: AjaxFilterWrapper): void {
    this.requestStatus = AjaxRequestStatus.Pending;
    this.mapRequestsService
      .searchMapObjects(
        this.toolType,
        attributesFilter,
        this.mapState?.viewState?.checkingIfApplicantIsEntityUrl,
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.requestStatus = this.getRequestStatus(data);
        this.handleMapObjectTableState(
          new MapAction(MapObjectTableActionType.AddNew, data.response),
        );
      });
  }

  getRequestStatus(data: MapObjectsResponse): AjaxRequestStatus {
    if (_.isEmpty(data.response)) {
      return AjaxRequestStatus.DataNotFound;
    }

    if (!data.isThatAll) {
      this.count = data.count;
      this.totalCount = data.totalCount;
      return AjaxRequestStatus.NotAllDataPreviewed;
    }

    return AjaxRequestStatus.DataFound;
  }

  clearFilter(): void {
    this.formContainer.form.reset();
  }

  closeModal(zoomToSelected: boolean, dismiss: boolean): void {
    this.activeModal.close({
      formValue: this.formValue,
      mapObjects: !dismiss ? this.mapObjectTableState.selectedMapObjects : [],
      mapObjectTableState: this.mapObjectTableState,
      zoomToSelected,
    } as AttributesFormResult);
  }

  handleMapObjectTableState(mapAction: MapAction): void {
    this.mapObjectTableState =
      this.mapStateService.getUpdatedMapObjectTableState(
        mapAction,
        this.mapObjectTableState,
      );
  }

  shouldShowConfirmationButton(): boolean {
    return !_.isEmpty(this.mapObjectTableState.selectedMapObjects);
  }

  getFieldConfigs(): FieldConfig[] {
    return this.getAttributesFormState().fieldConfigs;
  }

  getFormHeader(): string {
    return this.getAttributesFormState().formHeader;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
