import {
  BaseNewRequestControlName,
  LocationNewRequestControlName,
} from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum DNNewRequestAdditionalControlName {
  DemolitionWorksCommencementDate = 'demolitionWorksCommencementDate',
  Description = 'description',
  BuildingNameAndType = 'buildingNameAndType',
  TestimonialCheckbox = 'testimonialCheckbox',
}

export const DNNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...LocationNewRequestControlName,
  ...DNNewRequestAdditionalControlName,
};
