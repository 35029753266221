import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-control-validation',
  templateUrl: './form-control-validation.component.html',
  standalone: false,
})
export class ControlValidationComponent {
  @Input()
  fieldConfig: FieldConfig;
  @Input()
  formGroup: UntypedFormGroup;
  @Input()
  submitted = false;

  getControl(): AbstractControl {
    return this.formGroup.get(this.fieldConfig.name);
  }

  hasError(errorName: string): boolean {
    const control = this.getControl();
    return !!(control && control.errors && control.errors[errorName]);
  }

  isFieldReadyToShowAlert(): boolean {
    const control = this.getControl();
    return !!(control && (control.dirty || control.touched || this.submitted));
  }

  shouldShowFieldIsRequiredAlert(): boolean {
    return this.isFieldReadyToShowAlert() && this.hasError('required');
  }

  shouldShowFieldIsTooShortAlert(): boolean {
    return this.isFieldReadyToShowAlert() && this.hasError('minlength');
  }

  shouldShowFieldIsTooLongAlert(): boolean {
    return this.isFieldReadyToShowAlert() && this.hasError('maxlength');
  }

  shouldShowAnyAlert(): boolean {
    return (
      this.shouldShowFieldIsRequiredAlert() ||
      this.shouldShowFieldIsTooShortAlert() ||
      this.shouldShowFieldIsTooLongAlert()
    );
  }
}
