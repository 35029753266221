import {
  ApiNewDokumentPowiazanyDalDto,
  BuildingDto,
  EgibObject,
  LawPerson,
  LawPersonType,
  MapObject,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
  Place,
  PremisesDto,
  Street,
} from '@gk/gk-modules';
import { PostalCode } from '../../services/postal-code/postal-code.model';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../investor-details/investor-details.model';
import { Shipping, ShippingDto } from '../shipping-form/shipping-form.model';

export class ZamowienieDzialkaDto {
  constructor(
    public Uuid: string,
    public DzialkaId: number,
    public Numer: string,
    public Gmina: string,
    public Obreb: string,
    public Arkusz: string,
  ) {}
  static fromAppToApi(data: EgibObject): ZamowienieDzialkaDto {
    return new this(
      data.uuid,
      data.parcelId,
      data.mapObjectNumber,
      data.cadastralUnit,
      data.districtName,
      data.mapSheet,
    );
  }
}

export class ZamowienieGeomDto {
  constructor(public Geom: string) {}
  static fromAppToApi(data: MapObject): ZamowienieGeomDto {
    return new this(data.geom);
  }
}

export enum DocumentationFormType {
  NonElectronicForm = 0,
  ElectronicForm = 1,
}

type ApplicationPerson =
  | NewNaturalPersonRequestDto
  | NewLegalPersonRequestDto
  | NewDesignerRequestDto;

export enum ApplicationPersonType {
  Investor = 'DaneInwestora',
  Applicant = 'Wnioskodawca',
}

export interface CommonRequestDtoBase {
  Zalaczniki?: ApiNewDokumentPowiazanyDalDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  Dzialki?: ZamowienieDzialkaDto[];
  Budynki?: BuildingDto[];
  Lokale?: PremisesDto[];
  Geom?: string[];
  ZgodaNaKorespondencjeElektroniczna?: boolean;
  DanePelnomocnika?: InvestorDetailsRequestDto | undefined;
}

export interface InvestorRequestDto extends CommonRequestDtoBase {
  DaneInwestora: ApplicationPerson;
}

export interface ExtendedInvestorRequestDto extends CommonRequestDtoBase {
  DaneInwestora: InvestorDetailsRequestDto;
}

export interface ApplicantRequestDto extends CommonRequestDtoBase {
  Wnioskodawca: ApplicationPerson;
}

export interface ExtendedApplicantRequestDto extends CommonRequestDtoBase {
  Wnioskodawca: InvestorDetailsRequestDto;
}

export interface ApiBasedApplicantWithContactDataRequestDto
  extends CommonRequestDtoBase {
  KorespondencjaTelefon: number;
  KorespondencjaEmail: string;
}
export abstract class BaseRequest {
  constructor(
    public lawPerson?: LawPerson,
    public contactData?: LawPerson,
    public documentationForm?: DocumentationFormType,
    public shippingForm?: Shipping,
    public electronicCorrespondenceStatement?: boolean,
    public proxyDetails?: InvestorDetails,
    public proxyDetailsCheckbox?: boolean,
  ) {}

  protected static mapPersonDetails(
    lawPerson: LawPerson,
    contactData: LawPerson,
  ): ApplicationPerson {
    const personData =
      lawPerson.type === LawPersonType.Natural
        ? NewNaturalPersonRequestDto.fromAppToApi(lawPerson)
        : NewLegalPersonRequestDto.fromAppToApi(lawPerson);

    return {
      ...personData,
      Telefon: contactData?.naturalPersonData?.phoneNumber,
      Email: contactData?.naturalPersonData?.email,
    };
  }

  protected static mapCommonFieldsShared({
    data,
    parcels,
    buildings,
    premises,
    issueRanges,
    files,
  }: {
    data: BaseRequest;
    parcels?: EgibObject[];
    buildings?: EgibObject[];
    premises?: EgibObject[];
    issueRanges?: string[];
    files?: ApiNewDokumentPowiazanyDalDto[];
  }): CommonRequestDtoBase {
    return {
      Zalaczniki: files,
      FormaDecyzji: data.documentationForm,
      Odbior: data.shippingForm
        ? ShippingDto.fromAppToApi(data.shippingForm)
        : undefined,
      Dzialki: parcels?.map((parcel) =>
        ZamowienieDzialkaDto.fromAppToApi(parcel),
      ),
      Budynki: buildings?.map((building) => BuildingDto.fromAppToApi(building)),
      Lokale: premises?.map((premise) => PremisesDto.fromAppToApi(premise)),
      Geom: issueRanges,
      ZgodaNaKorespondencjeElektroniczna:
        data.electronicCorrespondenceStatement,
      DanePelnomocnika: data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : undefined,
    };
  }
}

export class InvestorRequest extends BaseRequest {
  constructor(
    lawPerson: LawPerson,
    contactData: LawPerson,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
  ) {
    super(
      lawPerson,
      contactData,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static mapCommonFields({
    data,
    parcels,
    buildings,
    premises,
    issueRanges,
    files,
  }: {
    data: InvestorRequest;
    parcels?: EgibObject[];
    buildings?: EgibObject[];
    premises?: EgibObject[];
    issueRanges?: string[];
    files?: ApiNewDokumentPowiazanyDalDto[];
  }): InvestorRequestDto {
    return {
      ...BaseRequest.mapCommonFieldsShared({
        data,
        parcels,
        buildings,
        premises,
        issueRanges,
        files,
      }),
      DaneInwestora: BaseRequest.mapPersonDetails(
        data.lawPerson,
        data.contactData,
      ),
    };
  }
}

export class ExtendedInvestorRequest extends BaseRequest {
  constructor(
    public investorDetails: InvestorDetails,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
  ) {
    super(
      undefined,
      undefined,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static mapCommonFields({
    data,
    parcels,
    buildings,
    premises,
    issueRanges,
    files,
  }: {
    data: ExtendedInvestorRequest;
    parcels?: EgibObject[];
    buildings?: EgibObject[];
    premises?: EgibObject[];
    issueRanges?: string[];
    files?: ApiNewDokumentPowiazanyDalDto[];
  }): ExtendedInvestorRequestDto {
    return {
      ...BaseRequest.mapCommonFieldsShared({
        data,
        parcels,
        buildings,
        premises,
        issueRanges,
        files,
      }),
      DaneInwestora: InvestorDetailsRequestDto.fromAppToApi(
        data.investorDetails,
      ),
    };
  }
}

export class ApplicantRequest extends BaseRequest {
  constructor(
    lawPerson: LawPerson,
    contactData: LawPerson,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
  ) {
    super(
      lawPerson,
      contactData,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static mapCommonFields({
    data,
    parcels,
    buildings,
    premises,
    issueRanges,
    files,
  }: {
    data: ApplicantRequest;
    parcels?: EgibObject[];
    buildings?: EgibObject[];
    premises?: EgibObject[];
    issueRanges?: string[];
    files?: ApiNewDokumentPowiazanyDalDto[];
  }): ApplicantRequestDto {
    return {
      ...BaseRequest.mapCommonFieldsShared({
        data,
        parcels,
        buildings,
        premises,
        issueRanges,
        files,
      }),
      Wnioskodawca: BaseRequest.mapPersonDetails(
        data.lawPerson,
        data.contactData,
      ),
    };
  }
}

export class ExtendedApplicantRequest extends BaseRequest {
  constructor(
    public investorDetails: InvestorDetails,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
  ) {
    super(
      undefined,
      undefined,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static mapCommonFields({
    data,
    parcels,
    buildings,
    premises,
    issueRanges,
    files,
  }: {
    data: ExtendedApplicantRequest;
    parcels?: EgibObject[];
    buildings?: EgibObject[];
    premises?: EgibObject[];
    issueRanges?: string[];
    files?: ApiNewDokumentPowiazanyDalDto[];
  }): ExtendedApplicantRequestDto {
    return {
      ...BaseRequest.mapCommonFieldsShared({
        data,
        parcels,
        buildings,
        premises,
        issueRanges,
        files,
      }),
      Wnioskodawca: InvestorDetailsRequestDto.fromAppToApi(
        data.investorDetails,
      ),
    };
  }
}

export class ApiBasedApplicantWithContactDataRequest extends BaseRequest {
  constructor(
    contactData: LawPerson,
    documentationForm?: DocumentationFormType,
    shippingForm?: Shipping,
    electronicCorrespondenceStatement?: boolean,
    proxyDetails?: InvestorDetails,
    proxyDetailsCheckbox?: boolean,
  ) {
    super(
      undefined,
      contactData,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static mapCommonFields({
    data,
    parcels,
    buildings,
    premises,
    issueRanges,
    files,
  }: {
    data?: ApiBasedApplicantWithContactDataRequest;
    parcels?: EgibObject[];
    buildings?: EgibObject[];
    premises?: EgibObject[];
    issueRanges?: string[];
    files?: ApiNewDokumentPowiazanyDalDto[];
  }): ApiBasedApplicantWithContactDataRequestDto {
    return {
      ...BaseRequest.mapCommonFieldsShared({
        data,
        parcels,
        buildings,
        premises,
        issueRanges,
        files,
      }),
      KorespondencjaTelefon: data.contactData?.naturalPersonData.phoneNumber,
      KorespondencjaEmail: data.contactData?.naturalPersonData.email,
    };
  }
}

export class PayerDetailsData {
  constructor(
    public companyName: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public phone: string,
    public companyShortcut?: string,
    public pesel?: string,
    public nip?: string,
    public regon?: string,
    public bankAccountNumber?: string,
    public rodzPetId?: number,
    public address?: PayerDetailsAddress,
  ) {}

  static fromApiToApp(event: PayerDetailsData): PayerDetailsData {
    return new this(
      event.companyName,
      event.firstName,
      event.lastName,
      event.email,
      event.phone,
      event.companyShortcut,
      event.pesel,
      event.nip,
      event.regon,
      event.bankAccountNumber,
      event.rodzPetId,
      event.address,
    );
  }
}

export interface PayerDetailsAddress {
  country?: string;
  voivodeship?: string;
  county?: string;
  community?: string;
  buildingNumber: string;
  localNumber?: string;
  place: Place | string;
  street: Street | string;
  postalCode: PostalCode | string;
}
