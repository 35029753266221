import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { shareReplay, takeWhile } from 'rxjs/operators';
import {
  BookmarkControlConfig,
  RequestConfig,
} from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-tabstrip-bookmark',
  styleUrls: ['./control-tabstrip-bookmark.component.scss'],
  templateUrl: './control-tabstrip-bookmark.component.html',
  standalone: false,
})
export class ControlTabstripBookmarkComponent
  extends Control<any>
  implements OnInit
{
  @Input()
  override controlConfig: BookmarkControlConfig;
  @Input()
  override data: any;
  dataTableBookmark: Array<Observable<any>> = [];

  constructor(
    protected override translateService: TranslateService,
    protected override dataService: DataService,
  ) {
    super(translateService, dataService);
  }

  override ngOnInit(): void {
    this.dataTableBookmark = this.controlConfig.bookmarkSectionSettings.map(
      (bookmark) => {
        return this.getDataTableBookmark(bookmark.requestConfig);
      },
    );
  }

  getDataTableBookmark(requestConfig: RequestConfig): Observable<any> {
    return this.dataService.getData(requestConfig, this.data).pipe(
      takeWhile(() => this.isAlive),
      shareReplay(1),
    );
  }
}
