import { Component, Input } from '@angular/core';
import {
  NavItemsSectionConfig,
  NavSectionType,
} from '../../gk-dynamic-navbar.model';
import { NavBaseComponent } from '../nav-base/nav-base.component';

@Component({
  selector: 'gk-nav-section-renderer',
  templateUrl: './nav-section-renderer.component.html',
  styleUrls: ['./nav-section-renderer.component.scss'],
  standalone: false,
})
export class NavSectionRendererComponent extends NavBaseComponent {
  @Input()
  navSectionConfig: NavItemsSectionConfig;
  navSectionTypeEnum = NavSectionType;
}
