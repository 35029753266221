import { Component, Input } from '@angular/core';
import { WrapCellConfig } from '../table.model';

@Component({
  selector: 'gk-table-tooltip-data-cell',
  templateUrl: './table-tooltip-data-cell.component.html',
  styleUrls: ['./table-tooltip-data-cell.component.scss'],
  standalone: false,
})
export class TableTooltipDataCellComponent {
  @Input() wrapCellConfig: WrapCellConfig;
  @Input() record: string;

  public shouldDisableTooltip(): boolean {
    return (
      !this.record ||
      this.record.toString().length <=
        this.wrapCellConfig.characterLimitUntilWrap
    );
  }

  public isRecordWrapped(): boolean {
    return (
      this.record &&
      this.record.toString().length >
        this.wrapCellConfig.characterLimitUntilWrap
    );
  }
}
