import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiNewDokumentPowiazanyDalDto,
  AttachmentsComponent,
  ColumnHeader,
  DemonstrationOfApplicantRightService,
  DocSignService,
  EgibObject,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapId,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  StreetService,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, from, switchMap, takeWhile, tap } from 'rxjs';
import { ProxyDetailsComponent } from '../../../components/proxy-details/proxy-details.component';
import { MainRoutes } from '../../../guards/guard.models';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import {
  SFB70CCNewRequestControlName,
  SFB70CCNewRequestFormGroup,
} from '../services/new-request-form/new-request-form.model';
import { SFB70CCNewRequestFormService } from '../services/new-request-form/new-request-form.service';
import { SFB70CCNewRequest } from '../services/new-request/new-request.model';
import { SingleFamilyBuildingConstructionIntentionTypeDictionaryService } from '../services/sfb-construction-intention-type-dictionary/sfb-construction-intention-type-dictionary.service';

@Component({
  selector: 'app-sfb70cc-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
  standalone: false,
})
export class SFB70CCNewRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  override controlName = SFB70CCNewRequestControlName;
  override formGroup: FormGroup<SFB70CCNewRequestFormGroup>;
  override portalId = PortalId.SingleFamilyBuilding70ConstructionConversion;
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('districtName', 'GK.MAP.DISTRICT'),
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.LandParcel],
    true,
  );
  mapSettings: MapSettings;
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild(ProxyDetailsComponent)
  proxyDetailsComponent: ProxyDetailsComponent;
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  successSigned = false;

  constructor(
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    private sfb70ccNewRequestFormService: SFB70CCNewRequestFormService,
    public override streetService: StreetService,
    private toastr: ToastrService,
    public override router: Router,
    private modalService: NgbModal,
    public override pzService: PzService,
    public demonstrationOfApplicantRightService: DemonstrationOfApplicantRightService,
    public singleFamilyBuildingConstructionIntentionTypeDictionaryService: SingleFamilyBuildingConstructionIntentionTypeDictionaryService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.createForm();
    this.fetchMapSettings();
  }

  createForm(): void {
    this.formGroup = this.sfb70ccNewRequestFormService.getFormGroup();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Applicant)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.mapSettings = mapSettings;
        this.setMapState();
      });
  }

  override setMapState(): void {
    this.mapState = new MapState(
      MapId.SingleFamilyBuilding70ConstructionConversion,
      this.mapStateService.getViewState(
        MapId.SingleFamilyBuilding70ConstructionConversion,
        this.mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel],
        this.mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.SingleFamilyBuilding70ConstructionConversion,
        this.mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty || this.attachmentsComponent.areChosenDocuments()
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.errorSubmitMessage = this.wrongFilesErrorText;
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areMapObjectsChosen()
    );
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    forkJoin([
      this.lawPersonSimpleFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.requestReplyFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.proxyDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
    ])
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            SFB70CCNewRequest.fromAppToApi(
              this.getFormValue(),
              this.getLandParcels(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/zgloszenieBudowyBudynkuJednorodzinnego70/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (error) => this.handleSendAndValidateFailure(error),
      });
  }

  getLandParcels(): EgibObject[] {
    return this.mapState.mapObjectTablesState[0].mapObjects as EgibObject[];
  }

  getFormValue(): SFB70CCNewRequest {
    return this.formGroup.getRawValue();
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.SingleFamilyBuilding70ConstructionConversion}/requests-list`,
    );
  }

  areMapObjectsChosen(): boolean {
    return !!this.mapState.mapObjectTablesState[0].mapObjects?.length;
  }

  isConstructionIntentionTypeFormControlValid(): boolean {
    return this.getConstructionIntentionTypeFormControl().valid;
  }

  getConstructionIntentionTypeFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.ConstructionIntentionType);
  }

  isPlannedStartDateFormControlValid(): boolean {
    return this.getPlannedStartDateFormControl().valid;
  }

  getPlannedStartDateFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.PlannedStartDate);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
