import { ChangeDetectionStrategy, Component } from '@angular/core';
import { filter, map, Observable, shareReplay, switchMap } from 'rxjs';
import { MainRoutes } from '../../guards/guard.models';
import { AppTitleService } from '../../services/app-title/app-title.service';
import { WebPortalService } from '../../services/web-portal/web-portal.service';

@Component({
  selector: 'app-request-cancel-button',
  templateUrl: './request-cancel-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RequestCancelButtonComponent {
  private routerLinkCache$: Observable<string[]>;
  constructor(
    private webPortalService: WebPortalService,
    private appTitleService: AppTitleService,
  ) {}

  getRouterLink(): Observable<string[]> {
    if (!this.routerLinkCache$) {
      this.routerLinkCache$ = this.appTitleService.currentPortalRoute.pipe(
        filter((portalRoute) => !!portalRoute),
        switchMap((portalRoute) =>
          this.webPortalService.getWebPortalDataByCurrentRoute(portalRoute),
        ),
        map((webPortal) =>
          webPortal.departmentCode
            ? [
                `/${MainRoutes.Department}/${webPortal.departmentCode}${webPortal.masterId ? `/${webPortal.masterId}` : ''}`,
              ]
            : [`/${MainRoutes.OfficeDepartmentsPortalHub}`],
        ),
        shareReplay(1),
      );
    }
    return this.routerLinkCache$;
  }
}
