import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Coordinate } from 'ol/coordinate';
import { map, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiListResponse } from '../../../services';
import { BuildingDetailsDto } from '../../components/map/map-building-details/map-building-details.model';
import {
  AjaxFilterWrapper,
  BoundaryPoint,
  BoundaryPointFromApi,
  ClassificationContour,
  ClassificationContourFromApi,
  ControlPoint,
  ControlPointFromApi,
  DistrictMapObject,
  DistrictMapObjectFromApi,
  EgibObject,
  EgibObjectFromApi,
  LandUse,
  LandUseFromApi,
  MapObject,
  MapObjectApiType,
  MapObjectAttributesFromApi,
  MapObjectDynamicAttributesFromApi,
  MapObjectFromApi,
  MapObjectInfo,
  MapObjectInfoFromApi,
  MapObjectsApplicantEndpoint,
  MapObjectsResponse,
  ToolType,
  Wkt,
} from '../../models';
import {
  ApplicantIsEntityForMapObjectResponse,
  ApplicantIsEntityForMapObjectResponseDto,
  ApplicantIsEntityPostDto,
  CoordinationLayerResponse,
  CoordinationLayerResponseRaw,
} from './map-request.model';

@Injectable()
export class MapRequestsService {
  private apllicantIsEntityDescriptionCache: Map<boolean, string> = new Map();
  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) {}

  searchMapObjects(
    toolType: ToolType,
    ajaxFilterWrapper: AjaxFilterWrapper,
    checkingIfApplicantIsEntityUrl?: string,
  ): Observable<MapObjectsResponse> {
    const endpoint = this.getEndpoint(toolType);

    return this.fetchMapObjectsDataFromApi(endpoint, ajaxFilterWrapper).pipe(
      switchMap((data) =>
        this.processMapObjectsData(
          data,
          toolType,
          checkingIfApplicantIsEntityUrl,
        ),
      ),
      catchError(() => of(MapObjectsResponse.getInitialStruct())),
    );
  }

  getEndpoint(toolType: ToolType): MapObjectsApplicantEndpoint | never {
    switch (toolType) {
      case ToolType.LandParcel:
      case ToolType.RectangularExtent:
        return MapObjectsApplicantEndpoint.LandParcel;
      case ToolType.Building:
        return MapObjectsApplicantEndpoint.Building;
      case ToolType.Premises:
        return MapObjectsApplicantEndpoint.Premises;
      case ToolType.District:
        return MapObjectsApplicantEndpoint.District;
      case ToolType.LandUse:
        return MapObjectsApplicantEndpoint.LandUse;
      case ToolType.ClassificationContour:
        return MapObjectsApplicantEndpoint.ClassificationContour;
      case ToolType.ControlPoint:
        return MapObjectsApplicantEndpoint.ControlPoint;
      case ToolType.BoundaryPoint:
        return MapObjectsApplicantEndpoint.BoundaryPoint;
      default:
        throw new Error('No endpoint for not handled map object type.');
    }
  }

  fetchMapObjectsDataFromApi(
    endpoint: string,
    ajaxFilterWrapper: AjaxFilterWrapper,
  ): Observable<ApiListResponse<MapObjectFromApi>> {
    return this.http.post<ApiListResponse<MapObjectFromApi>>(
      endpoint,
      ajaxFilterWrapper,
    );
  }

  processMapObjectsData(
    data: ApiListResponse<MapObjectFromApi>,
    toolType: ToolType,
    checkingIfApplicantIsEntityUrl?: string,
  ): Observable<MapObjectsResponse> {
    const response = MapObjectsResponse.fromApiToApp(
      data,
      data.Response.map((mapObjectFromApi) =>
        this.convertDataFromApi(toolType, mapObjectFromApi),
      ),
    );

    if (checkingIfApplicantIsEntityUrl) {
      return this.setApplicantIsEntityForMapObjects(
        response,
        toolType,
        checkingIfApplicantIsEntityUrl,
      );
    } else {
      return of(response);
    }
  }

  setApplicantIsEntityForMapObjects(
    mapObjectsResponse: MapObjectsResponse,
    toolType: ToolType,
    checkingIfApplicantIsEntityUrl: string,
  ): Observable<MapObjectsResponse> {
    const currentDateString = new Date().toLocaleDateString();
    return this.fetchApllicantIsEntityDescriptions().pipe(
      switchMap(() =>
        this.checkIfApplicantIsEntity(
          mapObjectsResponse.response.map((mapObject) =>
            this.getProperMapObjectIdForEntityChecking(mapObject),
          ),
          toolType,
          checkingIfApplicantIsEntityUrl,
        ),
      ),
      map((isEntityResponse) => {
        return {
          ...mapObjectsResponse,
          response: mapObjectsResponse.response.map((mapObject) => {
            const isEntity = isEntityResponse.find(
              (entity) =>
                `${entity.id}` ===
                `${this.getProperMapObjectIdForEntityChecking(mapObject)}`,
            );
            return {
              ...mapObject,
              entityInEgib: isEntity?.isEntity,
              entityInEgibDescription: `${this.apllicantIsEntityDescriptionCache.get(
                isEntity?.isEntity,
              )} ${currentDateString}`,
            };
          }),
        };
      }),
    );
  }

  getProperMapObjectIdForEntityChecking(mapObject: MapObject): number | string {
    switch (mapObject.type) {
      case MapObjectApiType.Building:
        return (mapObject as EgibObject).buildingId;
      case MapObjectApiType.LandParcel:
        return (mapObject as EgibObject).parcelId;
      default:
        throw new Error('Not handled map object type for entity checking.');
    }
  }

  fetchApllicantIsEntityDescriptions(): Observable<string | any> {
    const occursPerDayKey = 'OCCURS_PER_DAY';
    const doesNotOccurPerDayKey = 'DOES_NOT_OCCUR_PER_DAY';
    if (this.apllicantIsEntityDescriptionCache.size) {
      return of(null);
    } else {
      return this.translateService
        .get([occursPerDayKey, doesNotOccurPerDayKey])
        .pipe(
          map((translations) => {
            this.apllicantIsEntityDescriptionCache.set(
              true,
              translations[occursPerDayKey],
            );
            this.apllicantIsEntityDescriptionCache.set(
              false,
              translations[doesNotOccurPerDayKey],
            );
          }),
        );
    }
  }

  checkIfApplicantIsEntity(
    objectsIds: Array<string | number>,
    toolType: ToolType,
    url: string,
  ): Observable<ApplicantIsEntityForMapObjectResponse[]> {
    return this.http
      .post<
        ApplicantIsEntityForMapObjectResponseDto[]
      >(url, this.getApplicantIsEntityBody(objectsIds, toolType))
      .pipe(
        map((response) =>
          response.map((data) =>
            ApplicantIsEntityForMapObjectResponse.fromApiToApp(data),
          ),
        ),
      );
  }

  getApplicantIsEntityBody(
    objectsIds: Array<string | number>,
    toolType: ToolType,
  ): ApplicantIsEntityPostDto {
    switch (toolType) {
      case ToolType.Building:
        return { BudynkiIds: objectsIds };
      case ToolType.LandParcel:
        return { DzialkiIds: objectsIds };
      default:
        throw new Error('Not handled map object type.');
    }
  }

  convertDataFromApi(
    toolType: ToolType,
    mapObjectFromApi: MapObjectFromApi,
  ): MapObject | never {
    switch (toolType) {
      case ToolType.LandParcel:
      case ToolType.Building:
      case ToolType.Premises:
      case ToolType.RectangularExtent:
        return EgibObject.fromApiToApp(mapObjectFromApi as EgibObjectFromApi);
      case ToolType.BoundaryPoint:
        return BoundaryPoint.fromApiToApp(
          mapObjectFromApi as BoundaryPointFromApi,
        );
      case ToolType.LandUse:
        return LandUse.fromApiToApp(mapObjectFromApi as LandUseFromApi);
      case ToolType.ClassificationContour:
        return ClassificationContour.fromApiToApp(
          mapObjectFromApi as ClassificationContourFromApi,
        );
      case ToolType.District:
        return DistrictMapObject.fromApiToApp(
          mapObjectFromApi as DistrictMapObjectFromApi,
        );
      case ToolType.ControlPoint:
        return ControlPoint.fromApiToApp(
          mapObjectFromApi as ControlPointFromApi,
        );
      default:
        throw new Error('Not handled map object type.');
    }
  }

  getMapObjectsInfo(wkt: Wkt): Observable<MapObjectInfo[]> {
    const postBody = {
      Wkt: wkt,
    };

    return this.http
      .post<MapObjectInfoFromApi[]>('/api/mapa/objects/byGeom', postBody)
      .pipe(
        map((mapObjectsInfo) =>
          mapObjectsInfo.map((mapObjectInfo) =>
            MapObjectInfo.fromApiToApp(mapObjectInfo),
          ),
        ),
      );
  }

  getAvailableCoordinationLayers(): Observable<CoordinationLayerResponse[]> {
    return this.http
      .get<CoordinationLayerResponseRaw[]>('/api/system/ukladyWspXy/uzywane')
      .pipe(
        map((response) => {
          return response.map((coordinationLayerResponseRaw) =>
            CoordinationLayerResponse.fromApiToApp(
              coordinationLayerResponseRaw,
            ),
          );
        }),
      );
  }

  getBuildinObjectInfo(
    objectCoordinate: Coordinate,
  ): Observable<BuildingDetailsDto> {
    const coordinateX = objectCoordinate[0];
    const coordinateY = objectCoordinate[1];

    const pointString = `POINT(${coordinateX} ${coordinateY})`;

    const postBody = {
      wkt: pointString,
    };

    return this.http.post<BuildingDetailsDto>(
      '/api/mapa/budynki/szczegoly',
      postBody,
    );
  }

  getLandParcelAttributes(
    uuid: string,
  ): Observable<MapObjectAttributesFromApi> {
    return this.http.get<MapObjectAttributesFromApi>(
      `/api/interesant/przp/dzialka/${uuid}`,
    );
  }

  getBdot500Attributes(
    uuid: string,
  ): Observable<MapObjectDynamicAttributesFromApi> {
    return this.http.get<MapObjectDynamicAttributesFromApi>(
      `/api/interesant/przp/bdot500/${uuid}`,
    );
  }

  getGesutAttributes(
    uuid: string,
  ): Observable<MapObjectDynamicAttributesFromApi> {
    return this.http.get<MapObjectDynamicAttributesFromApi>(
      `/api/interesant/przp/gesut/${uuid}`,
    );
  }
}
