import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  finalize,
  from,
  map,
  Observable,
  of,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs';
import { SessionToolMode } from '../gk-components/session-tools/services/session-tools/session-tools.model';
import { TfaConfirmationService } from '../gk-components/session-tools/services/tfa-confirmation/tfa-confirmation.service';
import { TfaTotpInitializationCredentialsService } from '../gk-components/session-tools/services/tfa-totp-initialization-credentials/tfa-totp-initialization-credentials.service';
import { TfaWebAuthnInitializationCredentialsService } from '../gk-components/session-tools/services/tfa-web-authn-initialization-credentials/tfa-web-authn-initialization-credentials.service';
import { TfaWebAuthnInitializationService } from '../gk-components/session-tools/services/tfa-web-authn-initialization/tfa-web-authn-initialization.service';
import { WebAuthnSettingsComponent } from '../gk-components/session-tools/web-authn-settings/web-authn-settings.component';
import { TfaType } from './services/tfa/tfa.model';
import { TfaService } from './services/tfa/tfa.service';

@Component({
  selector: 'gk-user-settings',
  templateUrl: './gk-user-settings.component.html',
  styleUrls: ['./gk-user-settings.component.scss'],
  standalone: false,
})
export class GkUserSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('authenticationChangeConfirmationModal')
  authenticationChangeConfirmationModal: NgbModalRef;
  @Input() requiredTfaInfoVisible: boolean;
  @Input() closeButtonVisible = true;
  @Input() webAuthnSettingsVisible = true;
  disableLoading = new BehaviorSubject<boolean>(false);
  tfaType = TfaType;
  sessionToolMode = SessionToolMode;
  private isAlive = true;

  constructor(
    public modal: NgbActiveModal,
    private ngbModal: NgbModal,
    private toastr: ToastrService,
    private translateService: TranslateService,
    public tfaService: TfaService,
    public tfaConfirmationService: TfaConfirmationService,
    public tfaTotpInitializationCredentialsService: TfaTotpInitializationCredentialsService,
    public tfaWebAuthnInitializationService: TfaWebAuthnInitializationService,
    public tfaWebAuthnInitializationCredentialsService: TfaWebAuthnInitializationCredentialsService,
  ) {}

  ngOnInit(): void {
    this.fetchCurrentTfaType();
  }

  fetchCurrentTfaType(): void {
    this.tfaService.fetchCurrentTfaType().subscribe();
  }

  isWebAuthnPublicKeySupported(): boolean {
    return typeof PublicKeyCredential !== 'undefined';
  }

  enableTfaByEmail = () => {
    this.tfaService.tfaTypeInOperation.next(TfaType.Email);
    this.enableTfa();
  };

  enableTfaByTotp = () => {
    this.tfaService.tfaTypeInOperation.next(TfaType.Totp);
    this.enableTfa();
  };

  enableTfaByWebAuthn = () => {
    this.tfaService.tfaTypeInOperation.next(TfaType.WebAuthn);
    this.enableTfa();
  };

  enableTfa(): void {
    this.enableTfaOrShowConfirmationWindowOnTfaTypeChange()
      .pipe(
        takeWhile(() => this.isAlive),
        filter(Boolean),
        switchMap(() => this.tfaService.initializeTfa()),
        tap(() => this.executeProperConfirmationTool()),
        switchMap(() => this.tfaConfirmationService.verifiedConfirmation),
        switchMap(() => this.tfaService.showTfaResetCodeModalIfNecessary()),
        take(1),
        takeUntil(this.tfaConfirmationService.canceledConfirmation),
        finalize(() => this.tfaService.tfaTypeInOperation.next(undefined)),
      )
      .subscribe({
        next: () => this.showSuccessfullyInitialisedToastr(),
        complete: () => this.handleInitializationComplete(),
      });
  }

  executeProperConfirmationTool(): void {
    this.tfaService.tfaTypeInOperation
      .pipe(
        takeWhile(() => this.isAlive),
        filter(Boolean),
        take(1),
        tap(() => this.tfaConfirmationService.emitInitTfaConfirmationMode()),
      )
      .subscribe((tfaTypeInOperation) => {
        switch (tfaTypeInOperation) {
          case TfaType.Email: {
            this.tfaConfirmationService.tfaConfirmationInOperation.next(true);

            break;
          }
          case TfaType.Totp: {
            this.tfaTotpInitializationCredentialsService.forceFetch();
            this.tfaConfirmationService.tfaConfirmationInOperation.next(true);

            break;
          }
          case TfaType.WebAuthn: {
            this.tfaWebAuthnInitializationService.register(true).subscribe({
              error: (error) => {
                console.error(error);
                this.tfaConfirmationService.canceledConfirmation.next();
              },
            });

            break;
          }
        }
      });
  }

  handleInitializationComplete(): void {
    this.tfaService
      .fetchCurrentTfaType()
      .pipe(
        takeWhile(() => this.isAlive),
        take(1),
      )
      .subscribe((tfaType) => {
        switch (tfaType) {
          case TfaType.Email:
          case TfaType.Totp: {
            this.tfaConfirmationService.tfaConfirmationInOperation.next(false);

            break;
          }
        }
      });
  }

  enableTfaOrShowConfirmationWindowOnTfaTypeChange(): Observable<boolean> {
    return this.tfaService.isTfaEnabled().pipe(
      takeWhile(() => this.isAlive),
      take(1),
      switchMap((isTfaEnabled) =>
        isTfaEnabled
          ? from(
              this.ngbModal.open(this.authenticationChangeConfirmationModal)
                .result,
            )
          : of(true),
      ),
    );
  }

  showSuccessfullyInitialisedToastr(): void {
    this.translateService
      .get('SESSION_TOOLS.TFA_BASE_CONFIRMATION.SUCCESSFULLY_INITIALISED')
      .pipe(
        takeWhile(() => this.isAlive),
        takeUntil(this.tfaService.forcedTfaActivationInProgress),
      )
      .subscribe((text) => this.toastr.success(text));
  }

  disableTfa(): void {
    this.disableLoading.next(true);
    this.tfaService
      .disableTfa()
      .pipe(
        takeWhile(() => this.isAlive),
        switchMap(() => this.tfaService.fetchCurrentTfaType()),
        finalize(() => this.disableLoading.next(false)),
      )
      .subscribe(() => {
        this.showSuccessfullyDisabledToastr();
      });
  }

  showSuccessfullyDisabledToastr(): void {
    this.translateService
      .get('SESSION_TOOLS.TFA_BASE_CONFIRMATION.SUCCESSFULLY_DISABLED')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => this.toastr.success(text));
  }

  isAjaxPendingAction(): Observable<boolean> {
    return combineLatest([
      this.tfaService.tfaTypeInOperation,
      this.disableLoading,
      this.tfaService.currentTfaTypeNotFetchedYet(),
    ]).pipe(
      takeWhile(() => this.isAlive),
      map(
        ([enableLoading, disableLoading, currentTfaTypeNotFetchedYet]) =>
          !!enableLoading || disableLoading || currentTfaTypeNotFetchedYet,
      ),
    );
  }

  openWebAuthnSettings(): void {
    this.ngbModal.open(WebAuthnSettingsComponent, { size: 'lg' });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
