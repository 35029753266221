import { BaseNewRequestControlName } from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum LOIDNewRequestAdditionalControlName {
  LaneOccupancyPurpose = 'laneOccupancyPurpose',
  RoadNumber = 'roadNumber',
  Place = 'locationPlace',
  Street = 'locationStreet',
  AreaWithoutRoadEngineeringFacilities = 'areaWithoutRoadEngineeringFacilities',
  AreaOfRoadEngineeringFacilities = 'areaOfRoadEngineeringFacilities',
  LaneOccupancyDate = 'laneOccupancyDate',
}

export const LOIDNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...LOIDNewRequestAdditionalControlName,
};
