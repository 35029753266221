import { DatePipe } from '@angular/common';
import {
  PaymentAmountType,
  StampDutyPaymentDto,
} from '../services/stamp-duty/stamp-duty.model';

export interface InvoiceFromApi {
  FileUuid: string;
  DataWystawieniaOplaty: string;
  NaleznaOplata: string;
  NrKontaBank: string;
  Oplata: string;
}

export interface UnpaidInvoiceFromApi extends InvoiceFromApi {
  FaktUuid: string;
  Dotyczy: string;
  OwnerRodzaj: string;
  OwnerSygnatura: string;
}

export interface PendingInvoiceFromApi extends InvoiceFromApi {
  TransId: number;
}

export class Invoice {
  [key: string]: any;
  constructor(
    public id: any,
    public fileId: string,
    public paymentIssueData: string,
    public feeDue: string,
    public payment: string,
    public bankAccountNumber: string,
  ) {
    this.parseAndSetBankAccountNumber(bankAccountNumber);
    this.parseAndSetPaymentIssueData(paymentIssueData);
  }

  parseAndSetBankAccountNumber(bankAccountNumber: string): void {
    this.bankAccountNumber = bankAccountNumber.replace(
      /(\d{2})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})/,
      '$1 $2 $3 $4 $5 $6 $7',
    );
  }

  parseAndSetPaymentIssueData(paymentIssueData: string): void {
    this.paymentIssueData = new DatePipe('pl-PL').transform(
      paymentIssueData,
      'yyyy-MM-dd',
    );
  }
}

export class UnpaidInvoice extends Invoice {
  constructor(
    invoiceId: string,
    public fileUuid: string,
    public applied: string,
    paymentIssueData: string,
    bankAccountNumber: string,
    payment: string,
    feeDue: string,
    public ownerType: string,
    public ownerSignature: string,
  ) {
    super(
      invoiceId,
      fileUuid,
      paymentIssueData,
      feeDue,
      payment,
      bankAccountNumber,
    );
  }

  static fromApiToApp(
    unpaidInvoiceFromApi: UnpaidInvoiceFromApi,
  ): UnpaidInvoice {
    return new this(
      unpaidInvoiceFromApi.FaktUuid,
      unpaidInvoiceFromApi.FileUuid,
      unpaidInvoiceFromApi.Dotyczy,
      unpaidInvoiceFromApi.DataWystawieniaOplaty,
      unpaidInvoiceFromApi.NrKontaBank,
      unpaidInvoiceFromApi.Oplata,
      unpaidInvoiceFromApi.NaleznaOplata,
      unpaidInvoiceFromApi.OwnerRodzaj,
      unpaidInvoiceFromApi.OwnerSygnatura,
    );
  }
}

export class PendingInvoice extends Invoice {
  static fromApiToApp(
    pendingInvoiceFromApi: PendingInvoiceFromApi,
  ): PendingInvoice {
    return new this(
      pendingInvoiceFromApi.TransId,
      pendingInvoiceFromApi.FileUuid,
      pendingInvoiceFromApi.DataWystawieniaOplaty,
      pendingInvoiceFromApi.NaleznaOplata,
      pendingInvoiceFromApi.Oplata,
      pendingInvoiceFromApi.NrKontaBank,
    );
  }
}

export interface PaymentResponse {
  FaktUuids: number[];
  RedirectUrl: string;
}

export enum PaymentStatus {
  Unpaid = 'Niezaplacona',
  InProgress = 'WTrakcie',
  Canceled = 'Anulowana',
  Paid = 'Zaplacona',
  PaidUnchecked = 'TransakcjaZaplaconaNiezaznaczona',
}

export interface Payment {
  [key: string]: any;
  FaktUuid: string;
  TransId: number;
  FaktNr: number;
  OplataDokwUuid: number;
  OplataKwota: number;
  OplataKwotaFormatted: string;
  OplataNr: string;
  OplataXadesDokwUuid: string;
  StatusPlatnosci: PaymentStatus;
  StatusPlatnosciEn: string;
  StatusPlatnosciPl: string;
}

export enum PaymentLimitEndpoint {
  Amount = '/api/system/konfig/guest/faktury.limit_platnosci_kwota',
  Message = '/api/system/konfig/guest/faktury.limit_platnosci_komunikat',
}

export interface FileUuids {
  FileUuids: string[];
}

export interface PaymentParams {
  invoices?: Payment[] | Invoice[];
  pathToAmount?: string;
  portalName?: string;
  invoiceIdFieldName?: string;
  multiplePaymentProviders: boolean;
  parent?: any;
  openSelectedProviderWindow: (providers: string[]) => Promise<string>;
  stampDutyPayment?: StampDutyPaymentDto;
  paymentWithoutCheckingLimit?: boolean;
  paymentOwnerRequired?: boolean;
  paymentInNewTab?: boolean;
  paymentSuccessSimpleInfo?: boolean;
  paymentAmountType?: PaymentAmountType;
  abortPendingStatusOnRedirect?: boolean;
}
