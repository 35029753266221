import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { BsMessageType } from '../../services/request-workspace-state/request-workspace-state.model';

@Component({
  selector: 'app-doc-sign-info',
  templateUrl: './doc-sign-info.component.html',
  imports: [CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocSignInfoComponent {
  @Input() messageText: string;
  @Input() messageType: BsMessageType;
  @Input() docSignUrl: SafeUrl;
  @Input() docSignPending: boolean;
}
