import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPersonAddress,
} from '@gk/gk-modules';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  DocumentationFormType,
  ExtendedInvestorRequest,
  ExtendedInvestorRequestDto,
  ZamowienieGeomDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../../utils/date-helper/date-helper.util';

export interface ALUNewRequestDto extends ExtendedInvestorRequestDto {
  CelWylaczenia: number;
  TerminRozpoczeciaInwestycji: string;
}

export class ALUNewRequest extends ExtendedInvestorRequest {
  constructor(
    investorDetails: InvestorDetails,
    proxyDetails: InvestorDetails,
    public idNumber: number,
    public phoneNumber: number,
    public email: string,
    public address: LawPersonAddress,
    proxyDetailsCheckbox: boolean,
    public exclusionPurpose: number,
    public investmentCommencementDate: NgbDateStruct,
    public attachments: ApiNewDokumentPowiazanyDalDto,
    documentationForm: DocumentationFormType,
    public exclusionArea: ZamowienieGeomDto,
    shippingForm: Shipping,
  ) {
    super(
      investorDetails,
      documentationForm,
      shippingForm,
      undefined,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }
  static fromAppToApi(
    data: ALUNewRequest,
    parcels: EgibObject[],
    issueRanges: string[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): ALUNewRequestDto {
    return {
      ...ExtendedInvestorRequest.mapCommonFields({
        data,
        parcels,
        issueRanges,
        files,
      }),
      CelWylaczenia: data.exclusionPurpose,
      TerminRozpoczeciaInwestycji: data.investmentCommencementDate
        ? DateHelperUtil.ngbDateToApiDateTime(data.investmentCommencementDate)
        : undefined,
    };
  }
}
