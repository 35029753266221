import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';

import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { ALUNewRequestControlName } from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class ALUNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonInvestorFormGroup({
        [ECSControlName.ElectronicCorrespondenceStatement]: false,
      }).controls,
      [ALUNewRequestControlName.ExclusionPurpose]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [ALUNewRequestControlName.InvestmentCommencementDate]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }
}
