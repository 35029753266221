<form [formGroup]="form" novalidate class="pb-3">
  <fieldset>
    <legend class="d-none">
      {{ 'LAW_PERSON_TYPE_FORM.GROUP_LABEL' | translate }}
    </legend>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        [id]="idPrefix + lawPersonEnum.Natural"
        [attr.name]="lawPersonControlName.Type"
        [value]="lawPersonEnum.Natural"
        [formControlName]="lawPersonControlName.Type"
      />
      <label
        class="form-check-label"
        [attr.for]="idPrefix + lawPersonEnum.Natural"
      >
        {{ 'LAW_PERSON_TYPE_FORM.NATURAL_PERSON' | translate }}
      </label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        [id]="idPrefix + lawPersonEnum.Legal"
        [attr.name]="lawPersonControlName.Type"
        [value]="lawPersonEnum.Legal"
        [formControlName]="lawPersonControlName.Type"
      />
      <label
        class="form-check-label"
        [attr.for]="idPrefix + lawPersonEnum.Legal"
      >
        {{ 'LAW_PERSON_TYPE_FORM.INSTITUTION' | translate }}
      </label>
    </div>
  </fieldset>
</form>
