import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-request-validation-info',
  templateUrl: './request-validation-info.component.html',
  imports: [CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestValidationInfoComponent {
  @Input() shouldShowProvideFieldsAlert: boolean;
  @Input() errorSubmitMessage: string;
}
