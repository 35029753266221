import { Component } from '@angular/core';

import { Control } from '../control';

@Component({
  selector: 'gk-control-static-link',
  templateUrl: './control-static-link.component.html',
  standalone: false,
})
export class ControlStaticLinkComponent extends Control<string> {}
