import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import { MapControl } from '../../../../controls';
import {
  MapAction,
  SourceActionType,
  OverlayPositioning,
  PointState,
  ToolType,
  SourceType,
  ToolActionType,
} from '../../../../models';
import { ConversionUtils } from '../../../../utils';

@Component({
  selector: 'gk-map-popup',
  templateUrl: './map-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapPopupComponent extends MapControl {
  overlayPositioning = OverlayPositioning.BottomLeft;
  autoPanAnimationOptions = { duration: 250 };
  toolTypeEnum = ToolType;

  getPoint(): Coordinate | never {
    switch (this.sourceType) {
      case SourceType.Point:
        return (
          ConversionUtils.getGeometryFromWkt(
            (
              this.mapState.toolsState[this.toolType][
                this.sourceType
              ] as PointState
            ).lastWkt,
          ) as Point
        ).getCoordinates();
      default:
        throw new Error(`Cant't retrieve point coordinate for popup position.`);
    }
  }

  closePopup(): void {
    this.dispatch.emit(
      new MapAction(ToolActionType.IsVisiblePopupChange, {
        value: false,
        options: { toolType: this.toolType },
      }),
    );
    this.dispatch.emit(
      new MapAction(SourceActionType.IsVisibleTooltipChange, {
        value: true,
        options: { toolType: this.toolType, sourceType: this.sourceType },
      }),
    );
  }
}
