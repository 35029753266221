import { FormControl } from '@angular/forms';

export enum PropertyUsageControlName {
  BusinessUsage = 'businessUsage',
  BusinessUsageFull = 'businessUsageFull',
  BusinessUsageShare = 'businessUsageShare',
}

export interface PropertyUsageFormGroup {
  [PropertyUsageControlName.BusinessUsage]: FormControl<boolean>;
  [PropertyUsageControlName.BusinessUsageFull]: FormControl<boolean>;
  [PropertyUsageControlName.BusinessUsageShare]: FormControl<number | null>;
}
