import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import { PropertyUsageFormService } from '../business-purposes-checkbox-control-form/business-purposes-checkbox-control-form.service';
import {
  OCFCRNewRequestControlName,
  OCFCRNewRequestFormGroup,
} from './new-request-form.model';

@Injectable()
export class OCFCRNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
    private propertyUsageFormService: PropertyUsageFormService,
  ) {}

  getFormGroup(): FormGroup<OCFCRNewRequestFormGroup> {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [OCFCRNewRequestControlName.ProxyDetailsCheckbox]: false,
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [OCFCRNewRequestControlName.Comments]: [getDefaultControlValue()],
      [OCFCRNewRequestControlName.PropertyUsage]:
        this.propertyUsageFormService.getFormGroup(),
      [OCFCRNewRequestControlName.DiscountConditions]: [
        getDefaultControlValue(),
      ],
      [OCFCRNewRequestControlName.ForHousingPurposes]: [
        getDefaultControlValue(),
      ],
    });
  }
}
