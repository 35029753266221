import { FormControl } from '@angular/forms';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum PMFDDGRNewDataUpdateRequestAdditionalControlName {
  Reason = 'reason',
}

export const PMFDDGRNewDataUpdateRequestControlName = {
  ...BaseNewRequestControlName,
  ...PMFDDGRNewDataUpdateRequestAdditionalControlName,
};

export interface PMFDDGRNewDataUpdateRequestFormGroup
  extends LawPersonNewRequestFormGroup {
  [PMFDDGRNewDataUpdateRequestControlName.Reason]: FormControl<string>;
}
