import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  GkPostalCode,
  LawPersonAddressControlName,
  LawPersonControlName,
  PetentData,
  Place,
  PostOfficeModalComponent,
  PzService,
  ReceivingTypesItemForms,
  ShippingForm,
  ShippingService,
  Street,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, switchMap, takeWhile, tap } from 'rxjs';
import { DocumentationFormType } from '../base-new-request/base-new-request.model';
import { ShippingFormControlName } from './shipping-form.model';

@Component({
  selector: 'app-shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.scss'],
  standalone: false,
})
export class ShippingFormComponent implements OnInit, OnChanges, OnDestroy {
  private isAlive = true;
  @Input() shippingFormGroup: UntypedFormGroup;
  @Input() portalId: number | string;
  @Input() removeDisabledControls: boolean;
  @Input() idPrefix = '';
  @Input() primitivePostalCode: boolean;
  @Input() wasFormValidated: boolean;
  @Input() documentationForm: DocumentationFormType;
  shippingFormControlName = ShippingFormControlName;
  lawPersonAddressControlName = LawPersonAddressControlName;
  documentationFormType = DocumentationFormType;
  shippingTypes: ShippingForm[];
  customPostOfficeModalHeaderInfo: string;
  postOfficeModalHeaderInfoTranslation: string;
  @ViewChild(PostOfficeModalComponent)
  postOfficeModalComponent: PostOfficeModalComponent;
  shippingFormType: ShippingForm;

  constructor(
    private shippingService: ShippingService,
    private translateService: TranslateService,
    private pzService: PzService,
  ) {}

  ngOnInit(): void {
    this.copyApplicantDataFromPz().subscribe();
    this.setDefaultShippingForm();
    this.setProperShippingDataInputs();
    this.fetchPostOfficeModalHeaderInfoTranslation();
    this.adjustFormToIsOtherAddressValueOnChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['documentationForm'] &&
      !changes['documentationForm'].firstChange
    ) {
      this.setDefaultShippingForm();
    }
  }

  adjustFormToIsOtherAddressValueOnChange(): void {
    this.getIsOtherAddressFormControl()
      .valueChanges.pipe(
        takeWhile(() => this.isAlive),
        switchMap((isOtherAddressValue) =>
          this.adjustShippingFormValueToOtherAddressValue(isOtherAddressValue),
        ),
      )
      .subscribe((isOtherAddressValue) => {
        if (!isOtherAddressValue) {
          this.getNameSurnameFormControl().disable();
          this.getAddressFormGroup().disable({ emitEvent: false });
        } else {
          this.getNameSurnameFormControl().enable();
          this.getAddressFormGroup().enable();
        }
      });
  }

  adjustShippingFormValueToOtherAddressValue(
    isOtherAddressValue: boolean,
  ): Observable<boolean> {
    if (isOtherAddressValue) {
      return of(isOtherAddressValue);
    } else {
      return this.copyApplicantDataFromPz().pipe(
        takeWhile(() => this.isAlive),
        map(() => isOtherAddressValue),
      );
    }
  }

  copyApplicantDataFromPz(): Observable<PetentData> {
    return this.pzService.getPetentData().pipe(
      takeWhile(() => this.isAlive),
      tap((petentData) => {
        this.getNameSurnameFormControl()?.setValue(
          `${petentData.firstname} ${petentData.lastname}`,
        );
        this.getAddressFormGroup()?.patchValue({
          [LawPersonAddressControlName.Place]: new Place(
            petentData.placeId,
            petentData.place,
            true,
          ),
          [LawPersonAddressControlName.Street]: new Street(
            petentData.streetId,
            petentData.fullStreet,
          ),
          [LawPersonAddressControlName.PostalCode]: new GkPostalCode(
            petentData.postalCodeId,
            petentData.postalCode,
          ),
          [LawPersonAddressControlName.BuildingNumber]:
            petentData.buildingNumber,
          [LawPersonAddressControlName.LocalNumber]: petentData.localNumber,
          [LawPersonAddressControlName.StreetAbsence]: !petentData.street,
        });
      }),
    );
  }

  setDefaultShippingForm(): void {
    this.fetchShippingForms()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        if (!this.shippingTypes?.length) {
          return;
        }
        this.getShippingFormTypeRadioFormControl().setValue(
          this.shippingTypes[0].id,
        );
      });
  }

  setProperShippingDataInputs(): void {
    this.disableShippingDataFormGroups();
    this.getShippingFormTypeRadioFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((shippingFormValue: number) => {
        const isOtherAddressValue = this.getIsOtherAddressFormControl().value;
        this.disableShippingDataFormGroups();
        this.shippingFormType = this.shippingTypes.find(
          (shippingType) => shippingType.id === shippingFormValue,
        );
        if (
          shippingFormValue &&
          this.shippingFormType.postalDataRequired &&
          isOtherAddressValue
        ) {
          this.getNameSurnameFormControl().enable();
          this.getAddressFormGroup().enable();

          return;
        }
        if (shippingFormValue && this.shippingFormType.emailRequired) {
          this.getEmailFormControl().enable();

          return;
        }
        if (shippingFormValue && this.shippingFormType.ftpAddressRequired) {
          this.getFtpAddressFormControl().enable();

          return;
        }
      });
  }

  fetchShippingForms(): Observable<ShippingForm[]> {
    if (!this.portalId) {
      return of([]);
    }

    return this.shippingService
      .getShippingForms(
        this.portalId,
        this.documentationForm === DocumentationFormType.ElectronicForm
          ? ReceivingTypesItemForms.Electronic
          : ReceivingTypesItemForms.Printed,
      )
      .pipe(tap((data) => (this.shippingTypes = data)));
  }

  fetchPostOfficeModalHeaderInfoTranslation(): void {
    this.translateService
      .get('SHIPPING_CUSTOM_POST_OFFICE_MODAL_HEADER_INFO')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (postOfficeModalHeaderInfoTranslation) =>
          (this.postOfficeModalHeaderInfoTranslation =
            postOfficeModalHeaderInfoTranslation),
      );
  }

  disableShippingDataFormGroups(): void {
    this.getNameSurnameFormControl().disable();
    this.getEmailFormControl().disable();
    this.getFtpAddressFormControl().disable();
    this.getAddressFormGroup().disable({ emitEvent: false });
  }

  getIsOtherAddressFormControl(): UntypedFormControl {
    return this.shippingFormGroup.get(
      this.shippingFormControlName.IsOtherAddress,
    ) as UntypedFormControl;
  }

  getNameSurnameFormControl(): UntypedFormControl {
    return this.shippingFormGroup.get(
      this.shippingFormControlName.NameSurname,
    ) as UntypedFormControl;
  }

  getEmailFormControl(): UntypedFormControl {
    return this.shippingFormGroup.get(
      this.shippingFormControlName.Email,
    ) as UntypedFormControl;
  }

  getFtpAddressFormControl(): UntypedFormControl {
    return this.shippingFormGroup.get(
      this.shippingFormControlName.FtpAddress,
    ) as UntypedFormControl;
  }

  getAddressFormGroup(): UntypedFormGroup {
    return this.shippingFormGroup.get(
      LawPersonControlName.Address,
    ) as UntypedFormGroup;
  }

  getShippingFormTypeRadioFormControl(): FormControl<number> {
    return this.shippingFormGroup.get(
      this.shippingFormControlName.ShippingForm,
    ) as FormControl<number>;
  }

  askForPostOfficeWhenPostalCodeIsNotFromDictionary(
    customPostOfficeModalHeaderInfo = this.postOfficeModalHeaderInfoTranslation,
  ): Observable<boolean> {
    return this.postOfficeModalComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(
      customPostOfficeModalHeaderInfo,
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
