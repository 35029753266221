<kendo-gridlayout
  [cols]="['40%', '60%']"
  class="h-100 m-0"
  [rows]="['100%']"
  [gap]="{ rows: 1, cols: 2 }"
>
  <gk-land-details-grid></gk-land-details-grid>
  <kendo-gridlayout
    [rows]="['6%', '46%', '47%']"
    class="h-100 m-0"
    [gap]="{ rows: 3, cols: 0 }"
  >
    <gk-kendo-grid-toolbar
      #gridToolbar
      [gridToolbarItems]="getToolbarItems()"
      [overflow]="gridToolbarOverflow"
      [parent]="gridToolbar"
      [position]="'center'"
      [horizontalPosition]="'center'"
    ></gk-kendo-grid-toolbar>
    <gk-map-land-details-details></gk-map-land-details-details>
    <gk-map-land-classes-grid></gk-map-land-classes-grid>
  </kendo-gridlayout>
</kendo-gridlayout>
