import {
  EgibObject,
  MapAction,
  MapObjectTableActionType,
  MapPreviewModeState,
  MapViewActionType,
  SourceActionType,
  SourceType,
  ToolActionType,
  ToolsState,
  ToolState,
  ToolType,
} from '../../gk-map/models';
import { GkKendoGridMapService } from './gk-kendo-grid-map.service';
import { inject } from '@angular/core';
import { takeWhile } from 'rxjs';
import * as _ from 'lodash';
import { GridToolbarItemMapDrawingPayload } from './gk-kendo-grid.model';
import { MapExtentUtils } from '../../gk-map/utils';
import { ToastrService } from 'ngx-toastr';
import { ConversionUtils } from '../../gk-map/utils/conversion/conversion.utils';
import { Feature, FeatureCollection } from 'geojson';

export abstract class GkKendoMapBase {
  public isAlive = true;
  public toastr = inject(ToastrService);
  protected gkKendoGridMapService = inject(GkKendoGridMapService);
  protected gridToolbarItemMapDrawingPayload: GridToolbarItemMapDrawingPayload;

  public onGetGeometryFromMapToolbarButtonClick(
    payload: GridToolbarItemMapDrawingPayload,
    sourceType: SourceType,
  ): void {
    this.dispatchPreviewModeChange(true);
    this.dispatchToolAndSourceActivationChange(
      ToolType.AnyPolygon,
      sourceType,
      true,
    );

    this.gridToolbarItemMapDrawingPayload = payload;
  }

  public onGetLandParcelFromMapToolbarButtonClick(
    payload: GridToolbarItemMapDrawingPayload,
    sourceType: SourceType,
  ): void {
    this.dispatchToolAndSourceActivationChange(
      ToolType.LandParcel,
      sourceType,
      true,
    );
    this.dispatchPreviewModeChange(true);

    this.gridToolbarItemMapDrawingPayload = payload;
  }

  protected dispatch(action: MapAction): void {
    this.gkKendoGridMapService.$pendingMapActions.next([action]);
  }

  protected subscribeToMapActions(): void {
    this.gkKendoGridMapService.$pendingMapActions
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapActions) => {
        mapActions.forEach((mapAction) => {
          if (mapAction.type === ToolActionType.MapObjectsToolChange) {
            this.handleMapObjectsToolChange(mapAction);
          }
          if (mapAction.type === MapViewActionType.IsPreviewModeChange) {
            this.handlePreviewModeChange(mapAction);
          }
        });
      });
  }

  protected deactivateAllSources(toolType: ToolType): void {
    this.getSourceTypes(
      this.gkKendoGridMapService.$mapState.value?.toolsState[toolType],
    ).forEach((sourceType) =>
      this.dispatchSourceActivationChange(sourceType, false, toolType),
    );
  }

  protected dispatchToolActivationChange(
    toolType: ToolType,
    isActive: boolean,
  ): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(ToolActionType.IsActiveToolChange, {
        value: isActive,
        options: { toolType },
      }),
    ]);
  }

  protected getToolTypes(toolsState: ToolsState): ToolType[] {
    return Object.keys(toolsState) as ToolType[];
  }

  protected dispatchPreviewModeChange(isActive: boolean): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(isActive),
      ),
    ]);
  }

  protected dispatchToolAndSourceActivationChange(
    toolType: ToolType,
    sourceType: SourceType,
    isActive: boolean,
  ): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(ToolActionType.IsActiveToolChange, {
        value: isActive,
        options: { toolType },
      }),
      new MapAction(SourceActionType.IsActiveSourceChange, {
        value: isActive,
        options: {
          toolType,
          sourceType,
        },
      }),
    ]);
  }

  protected showGeomOnMap(
    wktGeom: string | string[],
    doneCallback?: () => any,
  ): void {
    const mapObjectsArray = (Array.isArray(wktGeom) ? wktGeom : [wktGeom]).map(
      (wktGeomItem) => ({ geom: wktGeomItem }),
    );
    const geometryExtent =
      MapExtentUtils.getMapExtentFromMapObjects(mapObjectsArray);

    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(true, doneCallback),
      ),
      //  @todo fix geometry preview style
      // new MapAction(
      //   MapObjectTableActionType.MapGeometryStyleConfigChange,
      //   new MapGeometryStyleConfig(
      //     new MapFeatureStyle(
      //       Color.Transparent,
      //       Color.GeometryPreview,
      //       4,
      //       [6, 6],
      //     ),
      //   ),
      // ),

      new MapAction(MapObjectTableActionType.Select, mapObjectsArray),
      new MapAction(MapViewActionType.ExtentToFitToChange, geometryExtent),
    ]);
  }

  protected showDxfOnMap(geoJSON: FeatureCollection): void {
    const mapObjectsArray = geoJSON.features.map((feature: Feature) => {
      const wkt = ConversionUtils.convertGeoJSONToWkt(feature.geometry);

      return { geom: wkt };
    });
    const geometryExtent =
      MapExtentUtils.getMapExtentFromMapObjects(mapObjectsArray);

    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(true),
      ),
      new MapAction(MapObjectTableActionType.Select, geoJSON),
      new MapAction(MapViewActionType.ExtentToFitToChange, geometryExtent),
    ]);
  }

  private handleMapObjectsToolChange(mapAction: MapAction): void {
    const toolType = mapAction.payload.options.toolType as ToolType;

    switch (toolType) {
      case ToolType.LandParcel:
        this.handleLandParcelToolChange(mapAction);
        break;
      case ToolType.AnyPolygon:
        this.handleAnyPolygonToolChange(mapAction);
        break;
    }

    if (toolType) {
      this.dispatchToolActivationChange(toolType, false);
      this.deactivateAllSources(toolType);
    }
    this.dispatchPreviewModeChange(false);
  }

  private getSourceTypes(toolState: ToolState): SourceType[] {
    return _.intersection(
      Object.keys(toolState) as SourceType[],
      Object.keys(SourceType) as SourceType[],
    );
  }

  private dispatchSourceActivationChange(
    sourceType: SourceType,
    isActive: boolean,
    toolType: ToolType,
  ): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(isActive),
      ),
      new MapAction(SourceActionType.IsActiveSourceChange, {
        value: isActive,
        options: { toolType, sourceType },
      }),
    ]);
  }

  private handleLandParcelToolChange(mapAction: MapAction): void {
    const landParcels = mapAction.payload.value as EgibObject[];
    this.gridToolbarItemMapDrawingPayload?.doneCallback(landParcels);
  }

  private handleAnyPolygonToolChange(mapAction: MapAction): void {
    const polygon = mapAction.payload.value;
    this.gridToolbarItemMapDrawingPayload?.doneCallback(polygon);
  }

  private handlePreviewModeChange(mapAction: MapAction): void {
    this.dispatchToolAndSourceActivationChange(
      ToolType.Enclave,
      SourceType.AddEnclave,
      false,
    );

    if (!mapAction.payload) {
      this.gridToolbarItemMapDrawingPayload?.doneCallback(mapAction);
    }
  }
}
