import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiNewDokumentPowiazanyDalDto,
  AttachmentsComponent,
  ColumnHeader,
  DemonstrationOfApplicantRightService,
  DocSignService,
  EgibObject,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapId,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  StreetService,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, from, switchMap, takeWhile, tap } from 'rxjs';
import { ProxyDetailsComponent } from '../../../components/proxy-details/proxy-details.component';
import { MainRoutes } from '../../../guards/guard.models';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import {
  TSBPNewRequestControlName,
  TSBPNewRequestFormGroup,
  TSBPRequestInformationControlName,
  TSBPRequestInformationFormGroup,
} from '../services/new-request-form/new-request-form.model';
import { TSBPNewRequestFormService } from '../services/new-request-form/new-request-form.service';
import { TSBPNewRequest } from '../services/new-request/new-request.model';

@Component({
  selector: 'app-tsbp-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
  standalone: false,
})
export class TSBPNewRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  override controlName = TSBPNewRequestControlName;
  requestInformationControlName = TSBPRequestInformationControlName;
  override formGroup: FormGroup<TSBPNewRequestFormGroup>;
  override portalId = PortalId.TemporaryStructureBuildingPermit;
  parcelsMapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.LandParcel],
    false,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    false,
  );
  parcelsMapObjectTableStateIndex = 0;
  mapSettings: MapSettings;
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild(ProxyDetailsComponent)
  proxyDetailsComponent: ProxyDetailsComponent;
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  successSigned = false;

  constructor(
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    private tsbpNewRequestFormService: TSBPNewRequestFormService,
    public override streetService: StreetService,
    private toastr: ToastrService,
    public override router: Router,
    private modalService: NgbModal,
    public override pzService: PzService,
    public demonstrationOfApplicantRightService: DemonstrationOfApplicantRightService,
    protected override fileSaverService: FileSaverService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
      fileSaverService,
    );
  }

  override ngOnInit(): void {
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.createForm();
    this.fetchMapSettings();
  }

  createForm(): void {
    this.formGroup = this.tsbpNewRequestFormService.getFormGroup();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Applicant)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.mapSettings = mapSettings;
        this.setMapState();
      });
  }

  override setMapState(): void {
    this.mapState = new MapState(
      MapId.TemporaryStructureBuildingPermit,
      this.mapStateService.getViewState(
        MapId.TemporaryStructureBuildingPermit,
        this.mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel],
        this.mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.TemporaryStructureBuildingPermit,
        this.mapSettings,
        MapPortalName.Applicant,
      ),
      this.getMapObjectTablesState(),
    );
  }

  getMapObjectTablesState(): MapObjectTableState[] {
    const mapObjectTablesState = [];
    mapObjectTablesState[this.parcelsMapObjectTableStateIndex] =
      this.parcelsMapObjectTableState;

    return mapObjectTablesState;
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty || this.attachmentsComponent.areChosenDocuments()
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.errorSubmitMessage = this.wrongFilesErrorText;
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areParcelsChosen()
    );
  }

  areParcelsChosen(): boolean {
    return !!this.mapState.mapObjectTablesState[
      this.parcelsMapObjectTableStateIndex
    ].mapObjects?.length;
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    forkJoin([
      this.lawPersonSimpleFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.requestReplyFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.proxyDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
    ])
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            TSBPNewRequest.fromAppToApi(
              this.getFormValue(),
              this.getLandParcels(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/pozwolenieNaBudoweTymczasowegoBud/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (error) => this.handleSendAndValidateFailure(error),
      });
  }

  getLandParcels(): EgibObject[] {
    return this.mapState.mapObjectTablesState[
      this.parcelsMapObjectTableStateIndex
    ].mapObjects as EgibObject[];
  }

  getFormValue(): TSBPNewRequest {
    return this.formGroup.getRawValue();
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.TemporaryStructureBuildingPermit}/requests-list`,
    );
  }

  isRequestInformationFormGroupValid(): boolean {
    return this.getRequestInformationFormGroup().valid;
  }

  getRequestInformationFormGroup(): FormGroup<TSBPRequestInformationFormGroup> {
    return this.formGroup.get(
      this.controlName.RequestInformation,
    ) as FormGroup<TSBPRequestInformationFormGroup>;
  }

  isWorksStartDateFormControlValid(): boolean {
    return this.getWorksStartDateFormControl().valid;
  }

  getWorksStartDateFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.WorksStartDate);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
