import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gk-session-tool-info',
  templateUrl: './session-tool-info.component.html',
  styleUrls: ['./session-tool-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SessionToolInfoComponent {
  readonly closedText = 'closed';
  @Input() infoText: string;
  @Input() localStorageCacheId: string;
  @Input() closeButton: boolean;

  setInLocalStorageAsRead(): void {
    if (!this.localStorageCacheId) {
      return;
    }
    localStorage.setItem(this.localStorageCacheId, this.closedText);
  }

  isRead(): boolean {
    return (
      this.localStorageCacheId &&
      localStorage.getItem(this.localStorageCacheId) === this.closedText
    );
  }
}
