import { Injectable } from '@angular/core';
import { GridDataService } from '../../../../../gk-kendo';
import {
  ClassDetailsGridDto,
  LandDetailsDto,
} from '../map-land-details-details/map-land-details-details.model';
import { MapLandClassesGridDto } from './map-land-classes-grid-data-model';

@Injectable({
  providedIn: 'any',
})
export class MapLandClassesGridDataService extends GridDataService<MapLandClassesGridDto> {
  constructor() {
    super(undefined, 'POST');
  }

  public updateGridData(data: LandDetailsDto): void {
    const gridData = this.transformSelectedItemToGridData(data);
    this.next({
      data: gridData,
      total: gridData.length,
    });
  }

  private transformSelectedItemToGridData(
    data: LandDetailsDto,
  ): MapLandClassesGridDto[] {
    return data.KlasouzytkiSzczegoly.map((use: ClassDetailsGridDto) => ({
      use: use.UzytekNazwa,
      class: use.KlasaNazwa,
      area: use.Powierzchnia,
    }));
  }
}
