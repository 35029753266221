<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST.INVESTOR_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <app-contact-data
      [submitted]="submitted"
      [contactDataFormGroup]="getContactDataFormGroup()"
    ></app-contact-data>

    <app-proxy-details
      #proxyDetails
      [portalId]="portalId"
      [formGroup]="formGroup"
      [controlName]="controlName"
      [submitted]="submitted"
    ></app-proxy-details>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isConstructionIntentionTypeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label
            class="mb-0"
            [attr.for]="controlName.ConstructionIntentionType"
          >
            {{
              'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST.CONSTRUCTION_INTENTION_TYPE'
                | translate
            }}
          </label>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <mat-select
                class="form-select form-select-sm required"
                [id]="controlName.ConstructionIntentionType"
                [attr.name]="controlName.ConstructionIntentionType"
                [formControlName]="controlName.ConstructionIntentionType"
                [placeholder]="
                  'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST.CONSTRUCTION_INTENTION_TYPE_PLACEHOLDER'
                    | translate
                "
                multiple
              >
                @for (
                  constructionIntentionType of constructionIntentionTypeDictionaryService.get()
                    | async;
                  track constructionIntentionType.id
                ) {
                  <mat-option [value]="constructionIntentionType.id">
                    {{ constructionIntentionType.name }}
                  </mat-option>
                }
              </mat-select>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <p class="text-muted mb-0">
            {{
              'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST.CONSTRUCTION_INTENTION_TYPE_INFO'
                | translate
            }}
          </p>
          @if (!isConstructionIntentionTypeFormControlValid() && submitted) {
            <div class="alert alert-danger mt-2">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isConstructionProjectNameFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.ConstructionProjectName">
            {{
              'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST.CONSTRUCTION_PROJECT_NAME'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.ConstructionProjectName"
                [id]="controlName.ConstructionProjectName"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.ConstructionProjectName"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <p class="text-muted mb-0">
            {{
              'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST.CONSTRUCTION_PROJECT_NAME_DESCRIPTION'
                | translate
            }}
          </p>
          @if (!isConstructionProjectNameFormControlValid() && submitted) {
            <div class="alert alert-danger mt-2">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areMapObjectsChosen()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST.LOCATION'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>
        @if (submitted && !areMapObjectsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST.MAP_OBJECTS_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-electronic-correspondence-statement
      [formGroup]="formGroup"
      [submitted]="submitted"
    >
    </app-electronic-correspondence-statement>

    <gk-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [requiredPowerOfAttorney]="proxyDetails.proxyDetailsVisible"
    >
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section>
      <button type="submit" class="btn btn-primary">
        {{
          'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST.SUBMIT'
            | translate
        }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
