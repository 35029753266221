import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../../shared/base-new-request-form/base-new-request-form.service';
import { WENewRequestControlName } from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class WENewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonInvestorFormGroup({
        [ECSControlName.ElectronicCorrespondenceStatement]: false,
      }).controls,
      [WENewRequestControlName.BirthDate]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.BirthPlace]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.WatercraftType]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.StagingPoint]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.DriveType]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.TotalLength]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.SideHeight]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.LargestWidth]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.SeatsNumber]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.ConstructionMaterialType]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.TechnicalConditionStatementCheckbox]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WENewRequestControlName.CommitmentStatementCheckbox]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
    });
  }
}
