import { FormControl, UntypedFormGroup } from '@angular/forms';
import { LawPerson } from '@gk/gk-modules';
import { ECSControlName } from '../../components/electronic-correspondence-statement/models/form/form.model';
import { DocumentationFormType } from '../base-new-request/base-new-request.model';
import { Shipping } from '../shipping-form/shipping-form.model';
import { InvestorDetails } from './../investor-details/investor-details.model';

export enum BaseNewRequestControlName {
  LawPerson = 'lawPerson',
  InvestorDetails = 'investorDetails',
  DocumentationForm = 'documentationForm',
  ShippingForm = 'shippingForm',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
  ContactData = 'contactData',
}

export enum LocationNewRequestControlName {
  BuildingNumber = 'buildingNumber',
  PostalCode = 'postalCode',
  Place = 'place',
  Street = 'street',
  StreetAbsence = 'streetAbsence',
  LocalNumber = 'localNumber',
  PostOffice = 'postOffice',
}

export type BaseNewRequestControlsExistenceParams = {
  [key in BaseNewRequestControlName]?: boolean;
} & {
  [key in ECSControlName]?: boolean;
};

export const getDefaultBaseNewRequestControlsExistenceParams =
  (): BaseNewRequestControlsExistenceParams => ({
    [BaseNewRequestControlName.LawPerson]: true,
    [BaseNewRequestControlName.InvestorDetails]: true,
    [BaseNewRequestControlName.DocumentationForm]: true,
    [BaseNewRequestControlName.ShippingForm]: true,
    [BaseNewRequestControlName.ProxyDetailsCheckbox]: true,
    [BaseNewRequestControlName.ProxyDetails]: false,
    [BaseNewRequestControlName.ContactData]: false,
    [ECSControlName.ElectronicCorrespondenceStatement]: true,
  });

export interface BaseNewRequestParams {
  documentationFormType?: DocumentationFormType;
  isInvestorPhoneNumberRequired?: boolean;
  isInvestorPhoneNumberValid?: boolean;
  investorPrimitivePostalCode?: boolean;
  isInvestorEmailRequired?: boolean;
  disableShippingEmail?: boolean;
  shippingPrimitivePostalCode?: boolean;
}

export const getDefaultBaseNewRequestParams = (): BaseNewRequestParams => ({
  documentationFormType: DocumentationFormType.ElectronicForm,
  isInvestorPhoneNumberRequired: true,
  isInvestorPhoneNumberValid: true,
  investorPrimitivePostalCode: false,
  isInvestorEmailRequired: true,
  disableShippingEmail: false,
  shippingPrimitivePostalCode: false,
});

export interface BaseNewRequestFormGroup {
  [BaseNewRequestControlName.DocumentationForm]: FormControl<DocumentationFormType>;
  [BaseNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [BaseNewRequestControlName.ProxyDetailsCheckbox]: FormControl<boolean>;
  [BaseNewRequestControlName.ProxyDetails]?: FormControl<InvestorDetails>;
  [BaseNewRequestControlName.ContactData]?: FormControl<LawPerson>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
}

export interface LawPersonNewRequestFormGroup extends BaseNewRequestFormGroup {
  [BaseNewRequestControlName.LawPerson]: FormControl<LawPerson>;
}

export interface InvestorDetailsNewRequestFormGroup
  extends BaseNewRequestFormGroup {
  [BaseNewRequestControlName.InvestorDetails]: UntypedFormGroup;
}
