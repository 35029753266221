import { FormControl, FormGroup } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum NONROTNewRequestAdditionalControlName {
  CurrentInvestorName = 'currentInvestorName',
  RequestInformation = 'requestInformation',
}

export const NONROTNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...NONROTNewRequestAdditionalControlName,
};

export enum NONROTRequestInformationControlName {
  WorksType = 'worksType',
  RequestDate = 'requestDate',
}

export interface NONROTNewRequestFormGroup
  extends LawPersonNewRequestFormGroup {
  [NONROTNewRequestControlName.CurrentInvestorName]: FormControl<string>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
  [NONROTNewRequestControlName.RequestInformation]: FormGroup<NONROTRequestInformationFormGroup>;
}

export interface NONROTRequestInformationFormGroup {
  [NONROTRequestInformationControlName.WorksType]: FormControl<string>;
  [NONROTRequestInformationControlName.RequestDate]: FormControl<NgbDate>;
}
