import { Component } from '@angular/core';

import { Control } from '../control';

@Component({
  selector: 'gk-control-bool',
  templateUrl: './control-bool.component.html',
  standalone: false,
})
export class ControlBoolComponent extends Control<boolean> {}
