import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getFileNameFromResponseHeaders } from '@gk/gk-modules';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestPreviewService {
  constructor(private httpClient: HttpClient) {}

  previewNewRequest<TBody, TResponse extends boolean = false>(
    body: TBody,
    options?: { fileName: TResponse; url: string },
  ): TResponse extends true
    ? Observable<{ file: Blob; fileName: string }>
    : Observable<Blob>;
  previewNewRequest<TBody>(
    body: TBody,
    options = { fileName: false, url: '' },
  ): Observable<{ file: Blob; fileName: string } | Blob> {
    return this.httpClient
      .post(options.url, body, { responseType: 'blob', observe: 'response' })
      .pipe(
        map((response) => {
          return options.fileName
            ? {
                file: response.body,
                fileName: getFileNameFromResponseHeaders(response.headers),
              }
            : response.body;
        }),
      );
  }
}
