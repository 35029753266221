import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MapControl } from '../../controls';

@Component({
  selector: 'gk-map',
  templateUrl: './map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .mapContainer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    `,
  ],
  standalone: false,
})
export class MapComponent extends MapControl {}
