import { BaseDetailSettings, DetailType } from '../../../../../gk-kendo';
export class LandDetails {
  static fromApiToApp(data: LandDetailsDto): BaseDetailSettings[] {
    return [
      {
        title: 'LAND_DETAILS.NUMBER',
        value: data?.Numer,
        type: DetailType.Text,
      },
      {
        title: 'LAND_DETAILS.IDENTIFIER',
        value: data?.Identyfikator,
        type: DetailType.Text,
      },
      {
        title: 'LAND_DETAILS.REGISTRATION_DISTRICT',
        value: data?.ObrebNazwa,
        type: DetailType.Text,
      },
      {
        title: 'LAND_DETAILS.SHEET',
        value: data?.ArkuszNazwa,
        type: DetailType.Text,
      },
      {
        title: 'LAND_DETAILS.REGISTRATION_UNIT',
        value: data?.JednostkaRejestrowaNazwa,
        type: DetailType.Text,
      },
      {
        title: 'LAND_DETAILS.LAND_REGISTRY_AREA',
        value: data?.PowierzchniaEwidText,
        type: DetailType.Text,
      },
      {
        title: 'LAND_DETAILS.ADDRESS',
        value: data?.Adres,
        type: DetailType.Text,
      },
      {
        title: 'LAND_DETAILS.ADDITIONAL_ATTRIBUTES',
        value: data?.AtrybutyDodatkowe,
        type: DetailType.Text,
      },
    ];
  }
}

export interface LandDetailsDto {
  Numer: string;
  Identyfikator: string;
  ObrebNazwa: string;
  ArkuszNazwa: string;
  JednostkaRejestrowaNazwa: string;
  PowierzchniaEwidText: string;
  Adres: string;
  AtrybutyDodatkowe: string;
  KlasouzytkiSzczegoly: ClassDetailsGridDto[];
}

export interface ClassDetailsGridDto {
  UzytekNazwa: string;
  KlasaNazwa: string;
  Powierzchnia: string;
  KdzialkaArowa: boolean;
}
