import { Component } from '@angular/core';
import {
  BsColor,
  CollapsibleTargetPrefix,
  NavbarColor,
} from '../../gk-dynamic-navbar.model';
import { NavBaseComponent } from '../nav-base/nav-base.component';

@Component({
  selector: 'gk-dynamic-navbar',
  templateUrl: './dynamic-navbar.component.html',
  styleUrls: ['./dynamic-navbar.component.scss'],
  standalone: false,
})
export class DynamicNavbarComponent extends NavBaseComponent {
  collapsed = true;

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  getNavbarModificatorClass(): string {
    return `navbar--${this.collapsed ? 'collapsed' : 'expanded'}`;
  }

  getNavbarAdditionalClasses(): string {
    return this.navbarConfig.generalNavConfig.additionalCssClasses;
  }

  getNavbarColor(): string {
    switch (this.navbarConfig.generalNavConfig.color) {
      case BsColor.Dark:
      case BsColor.Secondary:
      case BsColor.Primary:
      case BsColor.Success:
        return NavbarColor.Dark;
      case BsColor.Light:
        return NavbarColor.Light;
      case BsColor.ColorFromDb:
        return NavbarColor.ColorFromDb;
      default:
        throw Error('Unhandled color');
    }
  }

  getCollapseTarget(): string {
    return `${CollapsibleTargetPrefix}-${this.navbarConfig.generalNavConfig.id}`;
  }

  getCollapseTargetSelector(): string {
    return `.${this.getCollapseTarget()}`;
  }
}
