import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  getDefaultControlValue,
  LawPersonAddressControlName,
  streetFromDictionaryValidator,
} from '@gk/gk-modules';
import { ECSControlName } from '../../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from './../../../../../shared/base-new-request-form/base-new-request-form.service';
import {
  CNNewRequestControlName,
  ConstructionNotificationType,
} from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class CNNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        ...this.baseNewRequestFormService.getCommonInvestorFormGroup({
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        }).controls,
        ...this.baseNewRequestFormService.getAddressFormGroup().controls,
        [LawPersonAddressControlName.BuildingNumber]: [
          getDefaultControlValue(),
        ],
        [CNNewRequestControlName.ConstructionIntention]: [
          ConstructionNotificationType.ConstructionIntention,
          [Validators.required],
        ],
        [CNNewRequestControlName.BuildingNameAndType]: [
          getDefaultControlValue(),
          [Validators.required],
        ],
        [CNNewRequestControlName.Description]: [
          getDefaultControlValue(),
          [Validators.required],
        ],
        [CNNewRequestControlName.ConstructionWorksCommencementDate]: [
          getDefaultControlValue(),
          [Validators.required],
        ],
      },
      { validator: streetFromDictionaryValidator() },
    );
  }
}
