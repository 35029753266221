<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.INVESTOR_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <app-contact-data
      [submitted]="submitted"
      [contactDataFormGroup]="getContactDataFormGroup()"
    ></app-contact-data>

    <app-proxy-details
      #proxyDetails
      [portalId]="portalId"
      [formGroup]="formGroup"
      [controlName]="controlName"
      [submitted]="submitted"
    ></app-proxy-details>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isCurrentInvestorNameFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.CURRENT_INVESTOR_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <label
                class="text-nowrap"
                [attr.for]="controlName.CurrentInvestorName"
              >
                {{
                  'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.CURRENT_INVESTOR_NAME'
                    | translate
                }}
              </label>
              <input
                type="text"
                class="form-control form-control-sm"
                [id]="controlName.CurrentInvestorName"
                [name]="controlName.CurrentInvestorName"
                [formControlName]="controlName.CurrentInvestorName"
              />
            </div>
          </div>
        </div>

        @if (!isCurrentInvestorNameFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section [formGroup]="getRequestInformationFormGroup()">
      <div
        class="card mb-4"
        [ngClass]="
          !isRequestInformationFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.REQUEST_INFORMATION'
              | translate
          }}
        </div>

        <div class="card-body">
          <div class="mb-2">
            <label
              class="text-nowrap"
              [attr.for]="requestInformationControlName.WorksType"
            >
              {{
                'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.WORKS_TYPE'
                  | translate
              }}
            </label>

            <textarea
              [name]="requestInformationControlName.WorksType"
              [id]="requestInformationControlName.WorksType"
              class="form-control form-control-sm"
              [formControlName]="requestInformationControlName.WorksType"
            ></textarea>
          </div>

          <div class="mb-2">
            <label
              class="text-nowrap"
              [attr.for]="requestInformationControlName.RequestDate"
            >
              {{
                'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.REQUEST_DATE'
                  | translate
              }}
            </label>

            <div class="input-group">
              <input
                class="form-control form-control-sm"
                [attr.placeholder]="
                  'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                "
                [id]="requestInformationControlName.RequestDate"
                [name]="requestInformationControlName.RequestDate"
                [formControlName]="requestInformationControlName.RequestDate"
                ngbDatepicker
                #requestDateDatePicker="ngbDatepicker"
              />
              <button
                class="btn btn-outline-secondary fa fa-calendar"
                (click)="requestDateDatePicker.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
        @if (!isRequestInformationFormGroupValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELDS_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-electronic-correspondence-statement
      [formGroup]="formGroup"
      [submitted]="submitted"
    >
    </app-electronic-correspondence-statement>

    <gk-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [requiredPowerOfAttorney]="proxyDetails.proxyDetailsVisible"
    >
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section>
      <button type="submit" class="btn btn-primary">
        {{
          'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST.SUBMIT'
            | translate
        }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
