import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import { TitleControlConfig } from '../../../../../gk-dynamic-list/gk-dynamic-list.model';
import { dynamicMapObjectAttributesControlConfig } from '../../../../configs/tools-state.config';
import { MapControl } from '../../../../controls';
import {
  AjaxRequestStatus,
  AttributesDynamicListResult,
  MapObjectInfo,
  SubjectType,
  ToolType,
} from '../../../../models';
import { MapRequestsService } from '../../../../services';

@Component({
  selector: 'gk-attributes-dynamic-list',
  templateUrl: './attributes-dynamic-list.component.html',
  providers: [MapRequestsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AttributesDynamicListComponent
  extends MapControl
  implements OnInit, OnDestroy
{
  private isAlive = true;
  @Input()
  mapObject: MapObjectInfo;
  attributes: any;
  requestStatusEnum = AjaxRequestStatus;
  requestStatus = AjaxRequestStatus.Idle;
  override toolType = ToolType.Info;
  errorResponse: HttpErrorResponse;
  dynamicMapObjectControlConfig = dynamicMapObjectAttributesControlConfig;

  constructor(
    private activeModal: NgbActiveModal,
    private mapRequestsService: MapRequestsService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.provideProperAttributes();
  }

  provideProperAttributes(): void {
    const attributes = this.getCurrentAttributes();
    attributes
      ? this.setAttributesAndRequestStatus(attributes)
      : this.requestForAttributes();
  }

  getCurrentAttributes(): any {
    return _.get(
      this.mapState.toolsState[
        this.toolType
      ].requestedMapObjectsWithAttributes.find(
        (mapObject) =>
          mapObject.uuid === this.mapObject.uuid &&
          mapObject.type === this.mapObject.type,
      ),
      'attributes',
    );
  }

  setAttributesAndRequestStatus(attributes: any): void {
    this.attributes = attributes;
    this.requestStatus = AjaxRequestStatus.DataFound;
  }

  shouldGenerateAttributesDynamically(): boolean {
    switch (this.mapObject.type) {
      case SubjectType.LandParcel:
        return false;
      case SubjectType.BdotObject:
      case SubjectType.GesutObject:
        return true;
      default:
        return false;
    }
  }

  isLandParcel(): boolean {
    return this.mapObject.type === SubjectType.LandParcel;
  }

  getDynamicAttributesTitleConfig(): TitleControlConfig {
    return new TitleControlConfig(_.get(this.attributes, '[0].Grupa'), true);
  }

  requestForAttributes(): void {
    this.requestStatus = AjaxRequestStatus.Pending;
    switch (this.mapObject.type) {
      case SubjectType.LandParcel:
        this.mapRequestsService
          .getLandParcelAttributes(this.mapObject.uuid)
          .pipe(takeWhile(() => this.isAlive))
          .subscribe({
            next: (attributes) => {
              this.attributes = attributes;
              this.handleSuccessResponse();
            },
            error: (errorResponse: HttpErrorResponse) => {
              this.errorResponse = errorResponse;
              this.handleErrorResponse();
            },
          });
        break;
      case SubjectType.BdotObject:
        this.requestForAttributesOfBdot500();
        break;
      case SubjectType.GesutObject:
        this.requestForAttributesOfGesut();
        break;
      default:
        this.requestStatus = AjaxRequestStatus.DataNotFound;
        this.changeDetectorRef.markForCheck();
    }
  }

  handleSuccessResponse(): void {
    this.requestStatus = AjaxRequestStatus.DataFound;
    this.changeDetectorRef.markForCheck();
  }

  handleErrorResponse(): void {
    const errorMsg = _.get(this.errorResponse, 'error.ResponseStatus.Message');
    this.toastr.error(errorMsg);
    this.requestStatus = AjaxRequestStatus.DataNotFound;
    this.changeDetectorRef.markForCheck();
  }

  requestForAttributesOfBdot500(): void {
    this.mapRequestsService
      .getBdot500Attributes(this.mapObject.uuid)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (attributes) => {
          this.attributes = attributes;
          this.handleSuccessResponse();
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.errorResponse = errorResponse;
          this.handleErrorResponse();
        },
      });
  }

  requestForAttributesOfGesut(): void {
    this.mapRequestsService
      .getGesutAttributes(this.mapObject.uuid)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (attributes) => {
          this.attributes = attributes;
          this.handleSuccessResponse();
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.errorResponse = errorResponse;
          this.handleErrorResponse();
        },
      });
  }

  closeModal(): void {
    this.activeModal.close({
      requestedMapObjectWithAttributes: {
        ...this.mapObject,
        attributes: this.attributes,
      },
    } as AttributesDynamicListResult);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
