import { Component } from '@angular/core';
import * as _ from 'lodash';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { FormBaseComponent } from '../../form-base/form-base.component';
import { PlacesTypeaheadFieldConfig } from '../../gk-dynamic-form.model';
import { Place } from '../../services/place/place.model';
import { PlaceService } from '../../services/place/place.service';

@Component({
  selector: 'gk-form-places-typeahead',
  styleUrls: ['./form-places-typeahead.component.scss'],
  templateUrl: './form-places-typeahead.component.html',
  standalone: false,
})
export class FormPlacesTypeaheadComponent extends FormBaseComponent {
  override config: PlacesTypeaheadFieldConfig;
  model: Place | string;

  constructor(private placeService: PlaceService) {
    super();
  }

  search = (text$: Observable<string>): Observable<Place[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        this.placeService.getPlacesByTerm(
          term,
          '/api/interesant/slo/miejscowosci/local/cachedautocomplete',
        ),
      ),
      tap((res) => {
        if (!res.length) {
          this.wrapCurrentModel();
        }
      }),
    );

  wrapCurrentModel(): void {
    const name = _.get<string | Place, string>(this.model, 'name');
    const newModel: Place = {
      id: null,
      name: !name && name !== '' ? this.model : name,
      isLocal: false,
    };

    this.getControl().setValue(newModel);
  }

  formatter = (dbModel: Place): string => dbModel.name;
}
