import { ChangeDetectionStrategy, Component } from '@angular/core';
import * as _ from 'lodash';

import { ControlObjectComponent } from '../control-object/control-object.component';
import { DetailsRow } from '../control-object/control-object.model';

@Component({
  selector: 'gk-control-object-dto-details',
  templateUrl: './control-object-dto-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ControlObjectDtoDetailsComponent extends ControlObjectComponent {
  getRows(): DetailsRow[] {
    if (this.isEmptyValue()) {
      return [];
    }

    const data = this.getValue();
    const extraRows: DetailsRow[] = [
      new DetailsRow(
        _.get(data, 'Par1.Label') as string,
        _.get(data, 'Par1.Value') as string,
      ),
      new DetailsRow(
        _.get(data, 'Par2.Label') as string,
        _.get(data, 'Par2.Value') as string,
      ),
    ].filter(
      (row: DetailsRow) => !_.isUndefined(row.value) && !_.isNull(row.value),
    );

    return [...this.getSimplyKeyValueRowsWithOmit(), ...extraRows];
  }
}
