<aol-layer-vector>
  <aol-source-vector>
    <aol-feature [id]="id">
      <aol-geometry-polygon>
        <aol-collection-coordinates
          [coordinates]="geometry"
          [srid]="projectionCode"
        ></aol-collection-coordinates>
      </aol-geometry-polygon>
      <aol-style>
        <aol-style-stroke
          [color]="styles.featureStyle.strokeColor"
          [width]="styles.featureStyle.strokeWidth"
          [lineDash]="styles.featureStyle.lineDash"
        ></aol-style-stroke>
        <aol-style-fill
          [color]="styles.featureStyle.fillColor"
        ></aol-style-fill>
      </aol-style>
    </aol-feature>
  </aol-source-vector>
</aol-layer-vector>
