import { Component, Input } from '@angular/core';
import {
  CollapsibleTargetPrefix,
  NavItemsSectionConfig,
} from '../../gk-dynamic-navbar.model';
import { NavBaseComponent } from '../nav-base/nav-base.component';

@Component({
  selector: 'gk-nav-items-section',
  templateUrl: './nav-items-section.component.html',
  styleUrls: ['./nav-items-section.component.scss'],
  standalone: false,
})
export class NavItemsSectionComponent extends NavBaseComponent {
  @Input() navItemsSectionConfig: NavItemsSectionConfig;

  isCollapsible(): boolean {
    return this.navItemsSectionConfig.isCollapsible;
  }

  getCollapseClasses(): string {
    return this.isCollapsible() ? 'collapse navbar-collapse' : '';
  }

  getCollapseTargetClassName(): string {
    return this.isCollapsible()
      ? `${CollapsibleTargetPrefix}-${this.navbarConfig.generalNavConfig.id}`
      : '';
  }
}
