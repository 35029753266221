import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
import { finalize, takeWhile } from 'rxjs/operators';
import { MapAction, MapState, ToolType } from '../../../gk-map/models';
import {
  ApiValue,
  CellSize,
  ColumnConfig,
  DictionaryControlConfig,
  Filter,
  ListConfigs,
  ListData,
  ListState,
  ListType,
  Record,
  SortType,
  ValueType,
} from '../../gk-dynamic-list.model';
import { DictionaryService } from '../../services/dictionary/dictionary.service';
import { DomRefService } from '../../services/dom-ref/dom-ref.service';
import { DynamicListManagerService } from '../../services/dynamic-list-manager/dynamic-list-manager.service';
import { DynamicListService } from '../../services/dynamic-list/dynamic-list.service';
import { ApiFullFilter, SortObject } from '../../services/services.model';
import { jsSortTypeToApiDir } from '../../services/services.utils';
import { DateHelperUtil } from '../../utils/date-helper.util';

@Component({
  selector: 'gk-dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.scss'],
  standalone: false,
})
export class DynamicListComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  private isAlive = true;
  @Input()
  listConfigs = ListConfigs.getInitialStruct();
  @Input()
  listState = ListState.getInitialStruct();
  @Input() set apiFullFilter(data: ApiFullFilter) {
    this._apiFullFilter = data;
    if (data) {
      this.getListDataPage();
    }
  }
  get apiFullFilter(): ApiFullFilter {
    return this._apiFullFilter;
  }
  @Input()
  mapState: MapState;
  @Input()
  toolType: ToolType;
  private _apiFullFilter: ApiFullFilter;

  @Output()
  dispatchMapAction = new EventEmitter<MapAction>();
  @ViewChild('acc')
  set acc(accordion: NgbAccordionDirective) {
    this.accordion = accordion;
  }
  get acc(): NgbAccordionDirective {
    return this.accordion;
  }

  listData: ListData;
  currentAppliedFilters: Filter[] = [];
  totalCount = 0;
  currentPage = 1;
  requests = 0;
  responses = 0;
  CellSize = CellSize;
  SortType = SortType;
  ValueType = ValueType;
  listTypeEnum = ListType;
  accordion: NgbAccordionDirective;
  initedAccordion = false;
  shouldScrollToPanel = false;
  readonly spinnerName = 'dynamic-list-spinner';

  constructor(
    private dynamicListService: DynamicListService,
    private dictionaryService: DictionaryService,
    private renderer: Renderer2,
    private dom: DomRefService,
    private cdRef: ChangeDetectorRef,
    private dynamicListManagerService: DynamicListManagerService,
  ) {}

  private detectChanges(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.fetchDictionaries();
    this.handlePanelChangeByUuid();
    this.handlePanelReload();
    if (!this.listConfigs.generalConfig.filtersAndSearchEventFromParent) {
      this.getListDataPage(undefined, false);
      this.subToLoadedFirstAccordionData();
    }
  }

  ngAfterViewChecked(): void {
    this.adjustRecordsWidthToDynamicListHeaderWidth();

    if (!this.initedAccordion && this.acc) {
      this.initAccordion();
    }

    if (this.shouldScrollToPanel) {
      this.scrollToRecentlyOpenedPanel();
    }
  }

  subToLoadedFirstAccordionData(): void {
    this.dynamicListManagerService.loadedFirstAccordionData
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.shouldScrollToPanel = true;
      });
  }

  scrollToRecentlyOpenedPanel(): void {
    this.shouldScrollToPanel = false;
    if (!this.listState.recentlyOpenedPanelId) {
      return;
    }
    const elToScrollTo = this.dom.nativeDom.querySelector(
      `[id="${this.listState.recentlyOpenedPanelId}"]`,
    );
    elToScrollTo.scrollIntoView();
  }

  initAccordion(): void {
    this.initedAccordion = true;

    if (
      this.listConfigs.generalConfig.shouldOpenRecentlyOpenedPanelOnInit &&
      this.listState.recentlyOpenedPanelId
    ) {
      this.togglePanel(this.listState.recentlyOpenedPanelId);
      return;
    }

    if (this.listConfigs.generalConfig.shouldOpenFirstPanelOnInit) {
      const panelToToggleId = _.get(this.acc, 'panels.first.id');
      this.togglePanel(panelToToggleId);
    }
  }

  togglePanel(panelId: string): void {
    if (!panelId) {
      return;
    }
    this.acc.toggle(panelId);
    this.detectChanges();
  }

  onShow(panelId: string): void {
    if (panelId) {
      this.listState.recentlyOpenedPanelId = panelId;
      this.shouldScrollToPanel = true;
    } else {
      this.listState.recentlyOpenedPanelId = undefined;
    }
  }

  handlePanelChangeByUuid(): void {
    this.dynamicListService.caseUuid
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((uuid) => this.togglePanel(uuid));
  }

  handlePanelReload(): void {
    this.dynamicListService.reloadList
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.refreshDynamicList(true);
      });
  }

  adjustRecordsWidthToDynamicListHeaderWidth(): void {
    const cards = this.dom.nativeDom.querySelectorAll('.card');
    const listWidth = this.dom.nativeDom.querySelector(
      '.dynamic-list__header',
    ).scrollWidth;
    if (cards && this.accordion) {
      _.forEach(cards, (card) => {
        this.renderer.setStyle(card, 'width', `${listWidth}px`);
      });
    }
  }

  fetchDictionaries(): void {
    if (!_.isEmpty(this.listConfigs.dictionaryConfigs)) {
      this.dictionaryService.fetchDictsForConfigs(
        this.listConfigs.dictionaryConfigs,
        this.listConfigs.generalConfig.lang,
      );
    }
  }

  shouldShowListAndPagination(): boolean {
    return !!this.listConfigs.columnConfigs.find((config) => config.show);
  }

  handleColumnLabelClick(columnConfig: ColumnConfig): void {
    if (columnConfig.sortable) {
      columnConfig.currentSortType = this.getColumnNextSortType(
        columnConfig.currentSortType,
      );
      this.getListDataPage(undefined, false);
    }
  }

  handleFilterChange(filters: Filter[]): void {
    this.currentAppliedFilters = filters;
    this.getListDataPage();
  }

  private getColumnNextSortType(currentSortType: SortType): SortType {
    return (currentSortType + 1) % 3;
  }

  get pageLimit(): number {
    return this.listConfigs.generalConfig.onPageMaxCountLimit;
  }

  get portalId(): number {
    return this.listConfigs.generalConfig.portalId;
  }

  get collectionSize(): number {
    return Math.ceil(this.totalCount / this.pageLimit) * 10;
  }

  throwError = (columnConfig: ColumnConfig, value: ApiValue): Error => {
    throw new Error(
      `Konfiguracja niezgodna z odpowiedzią serwera!\n${JSON.stringify(
        columnConfig,
      )}\n${value}\n`,
    );
  };

  getValueForType = (
    record: Record,
    columnConfig: ColumnConfig,
  ): string | number | boolean => {
    const value: ApiValue = _.get(
      record,
      columnConfig.controlConfig.pathToValue,
    );
    if (value === null || value === undefined) {
      return '-';
    }

    switch (columnConfig.controlConfig.valueType) {
      case ValueType.DateTime:
        if (!_.isString(value) || !DateHelperUtil.isApiDateTimeFormat(value)) {
          this.throwError(columnConfig, value);
        }
        return DateHelperUtil.apiToUiDate(value);
      case ValueType.Dict:
        if (
          (!_.isString(value) && !_.isNumber(value)) ||
          !(columnConfig.controlConfig as DictionaryControlConfig)
            .dictionaryType
        ) {
          this.throwError(columnConfig, value);
        }
        return this.dictionaryService.getFieldNameById(
          (columnConfig.controlConfig as DictionaryControlConfig)
            .dictionaryType,
          value,
        );
      case ValueType.Text:
        if (!_.isString(value)) {
          this.throwError(columnConfig, value);
        }
        return value;
      case ValueType.Number:
        if (!_.isNumber(value)) {
          this.throwError(columnConfig, value);
        }
        return value;
      case ValueType.Bool:
        if (!_.isBoolean(value)) {
          this.throwError(columnConfig, value);
        }
        return value;
      case ValueType.LandParcelNumber:
        if (!_.isArray(value)) {
          this.throwError(columnConfig, value);
        }
        return value.join();
      default:
        return value;
    }
  };

  getSortObjectsList(columnConfigs: ColumnConfig[]): SortObject[] {
    return _.reduce(
      columnConfigs,
      (arr, nextConfig) => [
        ...arr,
        nextConfig.currentSortType
          ? new SortObject(
              jsSortTypeToApiDir(nextConfig.currentSortType),
              nextConfig.controlConfig.specificSortField ||
                nextConfig.controlConfig.pathToValue,
            )
          : undefined,
      ],
      [],
    ).filter((sortObj) => sortObj);
  }

  getSkipValue(): number {
    return this.currentPage * this.pageLimit - this.pageLimit;
  }

  getListDataPage(
    openLastAccordion = false,
    skipInitialRequestApiFullFilter = true,
  ): void {
    this.requests++;
    const toSkip = this.getSkipValue();
    const toTake = this.pageLimit;
    const portalId = this.portalId;
    const sortList = this.getSortObjectsList(this.listConfigs.columnConfigs);
    const filtersFromParent =
      this.listConfigs.generalConfig.filtersAndSearchEventFromParent;

    this.dynamicListService
      .getListData(
        this.listConfigs.generalConfig.url,
        toSkip,
        toTake,
        sortList,
        filtersFromParent ? undefined : this.currentAppliedFilters,
        this.getApiFullFilterIfConfigured(
          skipInitialRequestApiFullFilter &&
            this.currentAppliedFilters?.length > 0,
        ),
        portalId,
      )
      .pipe(
        takeWhile(() => this.isAlive),
        finalize(() => {
          this.responses++;
        }),
      )
      .subscribe({
        next: (response) => {
          this.totalCount = response.TotalCount;
          this.acc;
          setTimeout(() => {
            this.listData = response.Response.map(
              this.listConfigs.generalConfig.mapResponseDataFunction,
            );
            if (openLastAccordion) {
              this.acc.expand(this.listState.recentlyOpenedPanelId);
            }
          });
        },
      });
  }

  getApiFullFilterIfConfigured(
    skipInitialRequestApiFullFilter: boolean,
  ): ApiFullFilter | undefined {
    if (this.listConfigs.generalConfig.filtersAndSearchEventFromParent) {
      return this.apiFullFilter;
    }
    if (
      this.listConfigs.generalConfig.initialRequestApiFullFilter &&
      !skipInitialRequestApiFullFilter
    ) {
      return this.listConfigs.generalConfig.initialRequestApiFullFilter;
    }

    return undefined;
  }

  refreshDynamicList(openLastAccordion = false): void {
    this.getListDataPage(openLastAccordion, false);
  }

  getRefreshButtonClass(): string {
    return `btn-${this.listConfigs.generalConfig.mainColor}`;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
