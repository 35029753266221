import { FormControl, FormGroup } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum BPMDNewRequestAdditionalControlName {
  DecisionInformation = 'decisionInformation',
}

export const BPMDNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...BPMDNewRequestAdditionalControlName,
};

export enum BPMDDecisionInformationControlName {
  PublicationDate = 'publicationDate',
  DecisionNumber = 'decisionNumber',
  CaseNumber = 'caseNumber',
  ConstructionProjectName = 'constructionProjectName',
}

export interface BPMDNewRequestFormGroup extends LawPersonNewRequestFormGroup {
  [BPMDNewRequestControlName.DecisionInformation]: FormGroup<BPMDDecisionInformationFormGroup>;
}

export interface BPMDDecisionInformationFormGroup {
  [BPMDDecisionInformationControlName.PublicationDate]: FormControl<NgbDate>;
  [BPMDDecisionInformationControlName.DecisionNumber]: FormControl<string>;
  [BPMDDecisionInformationControlName.CaseNumber]: FormControl<string>;
  [BPMDDecisionInformationControlName.ConstructionProjectName]: FormControl<string>;
}
