import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../../shared/base-new-request-form/base-new-request-form.service';
import { FCNewRequestControlName } from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class FCNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonInvestorFormGroup({
        [ECSControlName.ElectronicCorrespondenceStatement]: false,
      }).controls,
      [FCNewRequestControlName.BirthDate]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [FCNewRequestControlName.BirthPlace]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
    });
  }
}
