<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <app-workspace-state
      [workspaceStateDraftVersion]="workspaceStateDraftVersion"
      [workspaceStateId]="workspaceStateId"
      [getWorkspaceStateToSave]="getWorkspaceStateToSave"
      [borderClass]="'card-border-color-from-db'"
      [backgroundClass]="'card-header-color-from-db'"
      [buttonClass]="'btn-color-from-db'"
      (workspaceStateLoaded)="handleLoadWorkspaceResponse($event)"
    ></app-workspace-state>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'card-border-color-from-db'
        "
      >
        <div class="card-header text-black card-header-color-from-db">
          {{
            'LAND_AND_BUILDING_REGISTER_DATA_UPDATE_UPON_ENTITIES_REQUEST.NEW_DATA_UPDATE_REQUEST.CLIENT_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
            [naturalPersonOnly]="true"
            [submitted]="submitted"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <app-contact-data
      [submitted]="submitted"
      [contactDataFormGroup]="getContactDataFormGroup()"
      [borderClass]="'card-border-color-from-db'"
      [backgroundClass]="'card-header-color-from-db'"
    ></app-contact-data>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isChangeScopeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'card-border-color-from-db'
        "
      >
        <div class="card-header text-black card-header-color-from-db">
          <label class="mb-0" [attr.for]="controlName.ChangeScope">
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_UPDATE_UPON_ENTITIES_REQUEST.NEW_DATA_UPDATE_REQUEST.CHANGE_SCOPE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.ChangeScope"
            [id]="controlName.ChangeScope"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.ChangeScope"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getChangeScopeFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areMapObjectsChosen()
            ? 'border-danger border-2'
            : 'card-border-color-from-db'
        "
      >
        <div class="card-header text-black card-header-color-from-db">
          {{
            'LAND_AND_BUILDING_REGISTER_DATA_UPDATE_UPON_ENTITIES_REQUEST.NEW_DATA_UPDATE_REQUEST.LOCATION'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="0"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          </div>
        </div>
        @if (submitted && !areMapObjectsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'LAND_AND_BUILDING_REGISTER_DATA_UPDATE_UPON_ENTITIES_REQUEST.NEW_DATA_UPDATE_REQUEST.MAP_OBJECTS_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div class="card mb-4 card-border-color-from-db">
        <div class="card-header text-black card-header-color-from-db">
          <label class="mb-0" [attr.for]="controlName.DeterminationOfLocation">
            {{
              'LAND_AND_BUILDING_REGISTER_DATA_UPDATE_UPON_ENTITIES_REQUEST.NEW_DATA_UPDATE_REQUEST.DETERMINATION_OF_LOCATION'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.DeterminationOfLocation"
            [id]="controlName.DeterminationOfLocation"
            class="form-control"
            [formControlName]="controlName.DeterminationOfLocation"
          ></textarea>
        </div>
      </div>
    </section>

    <gk-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [borderClass]="'card-border-color-from-db'"
      [backgroundClass]="'card-header-color-from-db'"
    >
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
      [borderClass]="'card-border-color-from-db'"
      [backgroundClass]="'card-header-color-from-db'"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
        [borderClass]="'card-border-color-from-db'"
        [backgroundClass]="'card-header-color-from-db'"
      ></app-cautions>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section>
      <button type="submit" class="btn btn-color-from-db">
        {{
          'LAND_AND_BUILDING_REGISTER_DATA_UPDATE_UPON_ENTITIES_REQUEST.NEW_DATA_UPDATE_REQUEST.SUBMIT'
            | translate
        }}
      </button>

      <app-request-preview-button
        class="ms-2"
        [buttonClass]="'btn btn-color-from-db'"
        [getPreviewRequestBody]="getRequestBody"
        [previewRequestUrl]="
          '/api/interesant/wniosek/aktualizacjaDanychEgibPrzezPodmiotWlad/podglad'
        "
      ></app-request-preview-button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-color-from-db" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
