import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';

@Injectable()
export class BaseDetailsService {
  public $loading = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  getDetailsByUrl<T>(url: string): Observable<T> {
    this.$loading.next(true);

    return this.httpClient.get<T>(url).pipe(
      tap(() => {
        this.$loading.next(false);
      }),
      catchError((err) => {
        this.$loading.next(false);

        return throwError(err);
      }),
    );
  }
}
