import { Component, OnInit } from '@angular/core';

import { Control } from '../control';

@Component({
  selector: 'gk-control-link',
  templateUrl: './control-link.component.html',
  standalone: false,
})
export class ControlLinkComponent extends Control<string> implements OnInit {
  href = '';

  override ngOnInit(): void {
    super.ngOnInit();
    this.initLinkHref();
  }

  hasHref(): boolean {
    return !!this.href;
  }

  getEvaluatedHref(): string {
    try {
      return eval(this.getValue());
    } catch (err) {
      console.error('Niepoprawna wartość urla:', this.getValue());
      return '';
    }
  }

  initLinkHref(): void {
    this.href = this.isEmptyValue() ? '' : this.getEvaluatedHref();
  }
}
