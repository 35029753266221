import { BaseNewRequestControlName } from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum WENewRequestAdditionalControlName {
  BirthPlace = 'birthPlace',
  BirthDate = 'birthDate',
  WatercraftType = 'watercraftType',
  StagingPoint = 'stagingPoint',
  DriveType = 'driveType',
  TotalLength = 'totalLength',
  SideHeight = 'sideHeight',
  LargestWidth = 'largestWidth',
  SeatsNumber = 'seatsNumber',
  ConstructionMaterialType = 'constructionMaterialType',
  TechnicalConditionStatementCheckbox = 'technicalConditionStatement',
  CommitmentStatementCheckbox = 'commitmentStatementCheckbox',
}

export const WENewRequestControlName = {
  ...BaseNewRequestControlName,
  ...WENewRequestAdditionalControlName,
};
