import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import {
  TSBPNewRequestControlName,
  TSBPNewRequestFormGroup,
  TSBPRequestInformationControlName,
} from './new-request-form.model';

@Injectable()
export class TSBPNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): FormGroup<TSBPNewRequestFormGroup> {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [TSBPNewRequestControlName.ContactData]: true,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [TSBPNewRequestControlName.RequestInformation]: this.formBuilder.group({
        [TSBPRequestInformationControlName.WorksType]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [TSBPRequestInformationControlName.RequestDate]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [TSBPNewRequestControlName.WorksStartDate]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }
}
