import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PprfPremisesFormModel } from './pprf-premises-form.model';
import { PprfPremisesFormService } from './pprf-premises-form.service';

@Component({
  selector: 'gk-pprf-premises-form',
  templateUrl: './pprf-premises-form.component.html',
  standalone: false,
})
export class PprfPremisesFormComponent {
  @Input() form: FormGroup;
  @Input() formModel: PprfPremisesFormModel;
  formlyFormConfig = this.pprfPremisesFormService.getFormConfig();

  constructor(private pprfPremisesFormService: PprfPremisesFormService) {}
}
