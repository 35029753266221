import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'gk-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
  standalone: false,
})
export class PaymentSuccessComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input()
  imgSrc = 'assets/payment-successful.png';
  @Output()
  redirectTrigger = new EventEmitter<void>();
  counter = 6;

  ngOnInit(): void {
    this.setCountdownInterval();
  }

  setCountdownInterval(): void {
    interval(1000)
      .pipe(takeWhile(() => this.shouldCountDown()))
      .subscribe(() => {
        if (!--this.counter) {
          this.emitRedirectTrigger();
        }
      });
  }

  shouldCountDown(): boolean {
    return this.isAlive && this.counter > 0;
  }

  emitRedirectTrigger(): void {
    this.redirectTrigger.emit();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
