import { FormControl } from '@angular/forms';
import { FieldId } from '@gk/gk-modules';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum LBRDURNewDataUpdateRequestAdditionalControlName {
  ChangeScope = 'changeScope',
  DemonstrationOfApplicantRight = 'demonstrationOfApplicantRight',
  DeterminationOfLocation = 'determinationOfLocation',
}

export const LBRDURNewDataUpdateRequestControlName = {
  ...BaseNewRequestControlName,
  ...LBRDURNewDataUpdateRequestAdditionalControlName,
};

export interface LBRDURNewDataUpdateRequestFormGroup
  extends LawPersonNewRequestFormGroup {
  [LBRDURNewDataUpdateRequestControlName.ChangeScope]: FormControl<string>;
  [LBRDURNewDataUpdateRequestControlName.DemonstrationOfApplicantRight]: FormControl<FieldId>;
  [LBRDURNewDataUpdateRequestControlName.DeterminationOfLocation]: FormControl<string>;
}
