import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ClosedArea } from '../../services/closed-areas/closed-areas.model';
import { UiVerboseService } from '../../services/ui-verbose/ui-verbose.service';
import { DateHelperUtil } from '../../utils/date-helper/date-helper.util';

@Component({
  selector: 'app-closed-areas-table',
  templateUrl: './closed-areas-table.component.html',
  styleUrls: ['./closed-areas-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ClosedAreasTableComponent {
  @Input() closedAreas: ClosedArea[] = [];

  constructor(private uiVerboseService: UiVerboseService) {}

  getUiBoolean(val: boolean): Observable<string> {
    return this.uiVerboseService.getUiBoolean(val);
  }

  getUiDate(apiDate: string | null): string {
    return apiDate ? DateHelperUtil.apiToUiDate(apiDate) : '-';
  }
}
