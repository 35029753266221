import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Observable, filter, takeWhile } from 'rxjs';
import { Place } from '../../gk-dynamic-form/services/place/place.model';
import { GkPostalCode } from '../../gk-dynamic-form/services/postal-code/postal-code.model';
import { Street } from '../../gk-dynamic-form/services/street/street.model';
import { PzService } from '../../services/pz/pz.service';
import { PostOfficeModalComponent } from '../post-office-modal/post-office-modal.component';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  NaturalPersonControlName,
} from '../services/law-person-form/law-person-form.model';
import { LawPersonType } from '../services/law-person/law-person.model';

@Component({
  selector: 'gk-law-person-simple-form',
  templateUrl: './law-person-simple-form.component.html',
  standalone: false,
})
export class LawPersonSimpleFormComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input() lawPersonFormGroup: FormGroup;
  @Input() naturalPersonOnly = false;
  @Input() submitted: boolean;
  lawPersonAddressControlName = LawPersonAddressControlName;
  @ViewChild(PostOfficeModalComponent)
  postOfficeModalComponent: PostOfficeModalComponent;

  constructor(private pzService: PzService) {}

  ngOnInit(): void {
    this.enableOrDisablePersonFormGroupOnTypeFormControlChange();
    this.setDefaultPersonType();
    this.copyApplicantDataFromPz();
  }

  setDefaultPersonType(): void {
    this.getTypeFormControl().setValue(LawPersonType.Natural);
  }

  enableOrDisablePersonFormGroupOnTypeFormControlChange(): void {
    const naturalPersonFormGroup = this.getNaturalPersonFormGroup();
    const legalPersonFormGroup = this.getLegalPersonFormGroup();
    this.getTypeFormControl()
      .valueChanges.pipe(
        takeWhile(() => this.isAlive),
        filter(() => !this.naturalPersonOnly),
      )
      .subscribe((value) => {
        if (value === LawPersonType.Legal) {
          naturalPersonFormGroup?.disable();
          legalPersonFormGroup?.enable();
        } else {
          naturalPersonFormGroup?.enable();
          legalPersonFormGroup?.disable();
        }
      });
  }

  getNaturalPersonFormGroup(): UntypedFormGroup {
    return this.lawPersonFormGroup.get(
      LawPersonControlName.NaturalPersonData,
    ) as UntypedFormGroup;
  }

  getLegalPersonFormGroup(): UntypedFormGroup {
    return this.lawPersonFormGroup.get(
      LawPersonControlName.LegalPersonData,
    ) as UntypedFormGroup;
  }

  getAddressFormGroup(): UntypedFormGroup {
    return this.lawPersonFormGroup.get(
      LawPersonControlName.Address,
    ) as UntypedFormGroup;
  }

  isLegalPersonChosen(): boolean {
    return this.getTypeFormControl().value === LawPersonType.Legal;
  }

  isNaturalPersonChosen(): boolean {
    return this.getTypeFormControl().value === LawPersonType.Natural;
  }

  getTypeFormControl(): UntypedFormControl {
    return this.lawPersonFormGroup.get(
      LawPersonControlName.Type,
    ) as UntypedFormControl;
  }

  askForPostOfficeWhenPostalCodeIsNotFromDictionary(): Observable<boolean> {
    return this.postOfficeModalComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary();
  }

  copyApplicantDataFromPz(): void {
    this.pzService
      .getPetentData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((petentData) => {
        this.getNaturalPersonFormGroup()?.patchValue({
          [NaturalPersonControlName.FirstName]: petentData.firstname,
          [NaturalPersonControlName.LastName]: petentData.lastname,
          [NaturalPersonControlName.PhoneNumber]: petentData.phone,
          [NaturalPersonControlName.Email]: petentData.email,
        });
        this.getAddressFormGroup()?.patchValue({
          [LawPersonAddressControlName.Place]: new Place(
            petentData.placeId,
            petentData.place,
            true,
          ),
          [LawPersonAddressControlName.Street]: new Street(
            petentData.streetId,
            petentData.street,
          ),
          [LawPersonAddressControlName.PostalCode]: new GkPostalCode(
            petentData.postalCodeId,
            petentData.postalCode,
          ),
          [LawPersonAddressControlName.BuildingNumber]:
            petentData.buildingNumber,
          [LawPersonAddressControlName.LocalNumber]: petentData.localNumber,
          [LawPersonAddressControlName.StreetAbsence]: !petentData.street,
        });
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
