import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gk-help-control',
  templateUrl: './help-control.component.html',
  styleUrls: ['./help-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HelpControlComponent {
  toggle = false;

  changeToggle(): void {
    this.toggle = !this.toggle;
  }
}
