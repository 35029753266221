import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import { PLNewRequestControlName } from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class PLNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [PLNewRequestControlName.ProxyDetailsCheckbox]: false,
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [PLNewRequestControlName.Place]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [PLNewRequestControlName.Street]: [getDefaultControlValue()],
      [PLNewRequestControlName.AreaToLease]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [PLNewRequestControlName.Comments]: [getDefaultControlValue()],
      [PLNewRequestControlName.LeaseDate]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [PLNewRequestControlName.Purpose]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }
}
