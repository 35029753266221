import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import {
  MapObjectApiType,
  MapObject,
  MapObjectTableState,
} from '../../../../../models';
import * as _ from 'lodash';
import { PolygonTopologyService } from '../../../../../services';
import { ToastrService } from 'ngx-toastr';
import { RangeFromParcelsButtonBaseComponent } from '../range-from-parcels-button-base.component';

@Component({
  selector: 'gk-range-from-selected-parcels-button',
  templateUrl: './range-from-selected-parcels-button.component.html',
  providers: [PolygonTopologyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RangeFromSelectedParcelsButtonComponent
  extends RangeFromParcelsButtonBaseComponent
  implements OnDestroy, OnInit
{
  @Input() specifiedMapObjectTableState: MapObjectTableState;
  noParcelsTranslation: string;
  successRangeAdditionTranslation: string;
  constructor(
    public override polygonTopologyService: PolygonTopologyService,
    public override toastr: ToastrService,
    protected translateService: TranslateService,
    public override changeDetectorRef: ChangeDetectorRef,
  ) {
    super(polygonTopologyService, toastr, changeDetectorRef);
  }

  ngOnInit(): void {
    this.fetchNoParcelsNotificationText();
    this.fetchSuccessRangeAdditionNotificationText();
  }

  fetchNoParcelsNotificationText(): void {
    this.translateService
      .get('GK.MAP.SELECT_PARCELS')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translation) => (this.noParcelsTranslation = translation));
  }

  fetchSuccessRangeAdditionNotificationText(): void {
    this.translateService
      .get('GK.MAP.SUCCESS_RANGE_ADDITION')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) => (this.successRangeAdditionTranslation = translation),
      );
  }

  getParcels(): MapObject[] {
    return !this.mapState && this.specifiedMapObjectTableState
      ? this.specifiedMapObjectTableState.selectedMapObjects
      : this.mapState.mapObjectTablesState.length === 1
        ? this.mapState.mapObjectTablesState[0].selectedMapObjects.filter(
            (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
          )
        : this.mapState.mapObjectTablesState.find(
            (mapObjectTableState) =>
              _.isEmpty(mapObjectTableState.mapObjectTypes) ||
              mapObjectTableState.mapObjectTypes.includes(
                MapObjectApiType.LandParcel,
              ),
          ).selectedMapObjects;
  }
}
