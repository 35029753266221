import {
  ActionButtonConfig,
  ActionType,
  AdditionalCouncilState,
  AddRelatedDocumentControlConfig,
  ApiFilter,
  ApiFullFilter,
  AppActionLinkControlConfig,
  ArrayControlConfig,
  BookmarkControlConfig,
  BookmarkTabstripConfig,
  BsColor,
  ButtonControlConfig,
  ButtonStateControl,
  CallbackButtonConfig,
  CancelPaymentControlConfig,
  CancelRequestControlConfig,
  CellSize,
  ColumnConfig,
  ColumnHeader,
  CommunicatorControlConfig,
  ControlConfig,
  ControlTextWithButtonsControlConfig,
  ControlTextWithCallbackButtonControlConfig,
  DictionaryConfig,
  DictionaryControlConfig,
  DictionaryField,
  DocumentsListControlConfig,
  ExpandibleTitleControlConfig,
  FileControlConfig,
  FilterAndRenderControlConfig,
  FilterCondition,
  FilterLogicOperator,
  FilterOperator,
  FilterOperatorType,
  FilterRule,
  GeneralConfig,
  GetDataAndRenderControlConfig,
  InputFileControlConfig,
  ListConfigs,
  ListDataConfig,
  ListType,
  MapNavigateToMapObjectControlConfig,
  MessagesListConfig,
  NestedAccordionControlConfig,
  NewMessage,
  OrderPaymentControlConfig,
  PrzpRequestStatus,
  RequestConfig,
  RequestParam,
  RequestStatus,
  RequestType,
  SortType,
  SpecificValueOfPropertyConf,
  StaticDictControlConfig,
  TableControlConfig,
  TextModalControlConfig,
  TitleControlConfig,
  ValueType,
  WrapCellConfig,
} from '@gk/gk-modules';
import { SurveyorPendingWork } from './modules/surveyor-portal/shared/surveyor-pending-works.model';
import { DateHelperUtil } from './utils/date-helper/date-helper.util';

enum DictionaryType {
  StatusWniosku = '1',
  StatusSprawy = '2',
  StatusRealizacji = '3',
  RodzajDokumentu = '4',
  Community = '5',
  PrzpRegistry = '6',
}

const paymentStaticDictFields: DictionaryField[] = [
  new DictionaryField('Niezaplacona', 'Niezapłacona'),
  new DictionaryField('WTrakcie', 'W trakcie płatności'),
  new DictionaryField('Anulowana', 'Anulowana'),
  new DictionaryField('Zaplacona', 'Zapłacona'),
  new DictionaryField(
    'TransakcjaZaplaconaNiezaznaczona',
    'Zapłacona, nieoznaczona',
  ),
];

const jobStatusStaticDictFields: DictionaryField[] = [
  new DictionaryField('T', 'Akceptacja'),
  new DictionaryField('N', 'Brak akceptacji'),
  new DictionaryField('U', 'Akcept. z uwagami'),
  new DictionaryField('P', 'Do poprawy'),
  new DictionaryField('B', 'Nieobecność'),
];

const isWorkEndedStaticDictFields: DictionaryField[] = [
  new DictionaryField(0, 'Nie'),
  new DictionaryField(1, 'Tak'),
  new DictionaryField(2, 'Częściowo'),
];

const facilityNatureStaticDictFields: DictionaryField[] = [
  new DictionaryField(1, 'Powierzchniowy'),
  new DictionaryField(2, 'Liniowy'),
  new DictionaryField(3, 'Punktowy'),
];

const ordererStaticDictFields: DictionaryField[] = [
  new DictionaryField(1, 'Organ Służby Geodezyjnej I Kartograficznej'),
  new DictionaryField(2, 'Podmiot Publiczny Nie Będący Organem Służby GIK'),
  new DictionaryField(3, 'Podmiot Niepubliczny'),
];

const additionalCouncilStateDictFields: DictionaryField[] = [
  new DictionaryField(AdditionalCouncilState.Available, ''),
  new DictionaryField(
    AdditionalCouncilState.RequestSubmitted,
    'Wniosek został zgłoszony.',
  ),
  new DictionaryField(
    AdditionalCouncilState.UnfinishedMainConference,
    'Narada główna nie została zakończona.',
  ),
  new DictionaryField(
    AdditionalCouncilState.FinishedMainConference,
    'Narada główna jest już zakończona.',
  ),
];

export const definedFiltersForValue: { [valueType: string]: FilterOperator[] } =
  {
    [ValueType.LandParcelNumber]: [
      new FilterOperator(FilterOperatorType.Equals, 'równe'),
      new FilterOperator(FilterOperatorType.Contains, 'zawiera'),
      new FilterOperator(FilterOperatorType.InList, 'zawiera się w liście'),
    ],
    [ValueType.Text]: [
      new FilterOperator(FilterOperatorType.Equals, 'równe'),
      new FilterOperator(FilterOperatorType.Contains, 'zawiera'),
      new FilterOperator(FilterOperatorType.NotContains, 'nie zawiera'),
      new FilterOperator(FilterOperatorType.StartsWith, 'zaczyna się od'),
      new FilterOperator(FilterOperatorType.EndsWith, 'kończy się na'),
    ],
    [ValueType.Number]: [
      new FilterOperator(FilterOperatorType.Equals, 'równe'),
      new FilterOperator(FilterOperatorType.LessThan, 'mniejsze'),
      new FilterOperator(
        FilterOperatorType.LessThanOrEquals,
        'mniejsze lub równe',
      ),
      new FilterOperator(FilterOperatorType.GreaterThan, 'większe'),
      new FilterOperator(
        FilterOperatorType.GreaterThanOrEquals,
        'większe lub równe',
      ),
    ],
    [ValueType.DateTime]: [
      new FilterOperator(FilterOperatorType.LessThan, 'przed'),
      new FilterOperator(FilterOperatorType.GreaterThan, 'po'),
      new FilterOperator(FilterOperatorType.Between, 'pomiędzy'),
    ],
    [ValueType.Bool]: [
      new FilterOperator(FilterOperatorType.Equals, 'równe'),
      new FilterOperator(FilterOperatorType.NotEquals, 'różne'),
    ],
    [ValueType.Dict]: [
      new FilterOperator(FilterOperatorType.Equals, 'równe'),
      new FilterOperator(FilterOperatorType.NotEquals, 'różne'),
    ],
  };

export const generalConfigGiKList: GeneralConfig = new GeneralConfig(
  15,
  true,
  true,
  '/api/interesant/zamowienia/search',
  'Moja lista zamówień',
);

export const generalConfigPrzpGiKList: GeneralConfig = new GeneralConfig(
  15,
  true,
  true,
  '/api/interesant/przp/zamowienia/search',
  'Moja lista zamówień',
);

export const columnConfigsGiKList: ColumnConfig[] = [
  new ColumnConfig(
    true,
    SortType.None,
    true,
    definedFiltersForValue['text'],
    new ControlConfig('Nr kancelaryjny', 'Numer', false, ValueType.Text),
    true,
    CellSize.TextBig,
  ),
  new ColumnConfig(
    true,
    SortType.None,
    true,
    definedFiltersForValue['dateTime'],
    new ControlConfig(
      'Data rozpoczęcia',
      'DataRozpocz',
      false,
      ValueType.DateTime,
    ),
    true,
    CellSize.DateTime,
  ),
  new ColumnConfig(
    true,
    SortType.None,
    true,
    definedFiltersForValue['text'],
    new ControlConfig('Status', 'Status', false, ValueType.Text),
    true,
    CellSize.TextBig,
  ),
  new ColumnConfig(
    true,
    SortType.None,
    true,
    definedFiltersForValue['dateTime'],
    new ControlConfig(
      'Data załatwienia',
      'DataZalatw',
      false,
      ValueType.DateTime,
    ),
    true,
    CellSize.DateTime,
  ),
  new ColumnConfig(
    true,
    SortType.None,
    true,
    definedFiltersForValue['boolean'],
    new ControlConfig('Dokumenty z ODGiK', 'Dokumenty', false, ValueType.Bool),
    true,
    CellSize.BoolSize,
  ),
];

export const getDtoPrzpConfig = (): ListConfigs =>
  new ListConfigs(
    generalConfigPrzpGiKList,
    [],
    columnConfigsGiKList,
    new ListDataConfig(
      new RequestConfig(
        '/api/interesant/przp/zamowienia/szczegoly',
        RequestType.Post,
        [new RequestParam('ZamowUuid', 'ZamowUuid')],
      ),
      [
        new ControlConfig(
          'NEW_REQUEST.PUBLIC_TASK_CASE_REFERENCE',
          'Szczegoly.SygnaturaZadPubl',
          true,
          ValueType.Text,
          ['Szczegoly.SygnaturaZadPubl'],
        ),
        new ControlConfig(
          'NEW_REQUEST.TASK_DESCRIPTION',
          'Szczegoly.Par1.Value',
          true,
          ValueType.Text,
          ['Szczegoly.Par1.Value'],
        ),
        new ControlConfig(
          'NEW_REQUEST.LEGAL_PROVISION',
          'Szczegoly.Par2.Value',
          true,
          ValueType.Text,
          ['Szczegoly.Par2.Value'],
        ),
        new ControlConfig(
          'NEW_REQUEST.CHOOSE_RANGE_TYPE',
          'PozycjeTxt',
          true,
          ValueType.Text,
          [],
          null,
          null,
          [],
          null,
          new WrapCellConfig(true, 200),
        ),
        new ControlConfig(
          'NEW_REQUEST.PERIOD',
          'Szczegoly.CzasDostepuTxt',
          true,
          ValueType.Text,
          ['Szczegoly.CzasDostepuTxt'],
        ),
        new ControlConfig('Etap sprawy', 'EtapSprawy', true, ValueType.Text),
        new ArrayControlConfig(
          'Dokumenty wysłane',
          'DokPrzych',
          true,
          [],
          new FileControlConfig(
            'poj. link',
            '',
            false,
            undefined,
            new RequestConfig(
              '/api/interesant/przp/dokprzych/download',
              RequestType.Post,
              [new RequestParam('FileUuids', 'Uuid', true)],
              false,
              undefined,
              'blob',
            ),
            '',
            'Nazwa',
          ),
        ),
        new ArrayControlConfig(
          'Dokumenty z ODGiK',
          'DokWych',
          true,
          [],
          new FileControlConfig(
            'poj. link',
            '',
            false,
            undefined,
            new RequestConfig(
              '/api/interesant/edok/download',
              RequestType.Post,
              [new RequestParam('FileUuids', 'Uuid', true)],
              false,
              undefined,
              'blob',
            ),
            '',
            'Nazwa',
          ),
        ),
        new InputFileControlConfig(
          'Czynności',
          'ZamowUuid',
          true,
          ['CanAddAttachment'],
          [],
          [],
          '/api/interesant/przp/dokprzych/add',
        ),
        new CancelRequestControlConfig(
          'Czynności',
          'Anulowanie wniosku',
          true,
          'Status',
          'ZamowUuid',
          new RequestConfig(
            '`/api/interesant/zamowienia/${this.data.ZamowUuid}/anuluj`',
            RequestType.Post,
            [],
            true,
          ),
          undefined,
          [PrzpRequestStatus.Registered],
        ),
      ],
    ),
  );

export const getDtoDesignerConfig = (
  portalId?: number,
  isPzSessionOrPzSignRequired?: boolean,
  openCommunicatorCallback?: (
    dynamicListItem: any,
    buttonStateControl: ButtonStateControl,
  ) => void,
  readNewMessageCallback?: (
    dynamicListItem: any,
    refreshCallback: () => any,
  ) => void,
): ListConfigs =>
  new ListConfigs(
    new GeneralConfig(
      100,
      true,
      true,
      '/api/zudp/projektant/sprawy/search',
      'Moja lista spraw',
      'pl',
      false,
      true,
      undefined,
      undefined,
      BsColor.ColorFromDb,
      undefined,
      undefined,
      new ApiFullFilter([
        new ApiFilter(
          'DataZglosz',
          DateHelperUtil.dateToApiDateTime(DateHelperUtil.getDateYearsAgo(3)),
          FilterOperatorType.GreaterThanOrEquals,
        ),
      ]),
    ),
    [
      new DictionaryConfig(
        DictionaryType.StatusSprawy,
        'Status narady',
        '/api/zudp/slo/statussprawy',
        'Id',
        'Nazwa',
        'Response',
      ),
      new DictionaryConfig(
        DictionaryType.StatusWniosku,
        'Status wniosku',
        '/api/zudp/slo/statuswniosku',
        'Id',
        'Nazwa',
        'Response',
      ),
      new DictionaryConfig(
        DictionaryType.StatusRealizacji,
        'Status realizacji',
        '/api/zudp/slo/realizacja',
        'Id',
        'Nazwa',
        'Response',
      ),
      new DictionaryConfig(
        DictionaryType.RodzajDokumentu,
        'Rodzaj dokumentu',
        '/api/zudp/projektant/slo/kdokrodz',
        'Id',
        'Nazwa',
        'Response',
      ),
    ],
    [
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['text'],
        new ControlConfig('Sygnatura', 'Sygnatura', false, ValueType.Text),
        true,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['text'],
        new ControlConfig(
          'Sygnatura wniosku',
          'SygnaturaWniosku',
          false,
          ValueType.Text,
        ),
        false,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        true,
        SortType.Desc,
        true,
        definedFiltersForValue['dateTime'],
        new ControlConfig(
          'Data zgłoszenia wniosku',
          'DataZglosz',
          false,
          ValueType.DateTime,
        ),
        true,
        CellSize.DateTime,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['dictionary'],
        new DictionaryControlConfig(
          'Status wniosku',
          'StatusWnioskuId',
          false,
          undefined,
          DictionaryType.StatusWniosku,
        ),
        true,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['dictionary'],
        new DictionaryControlConfig(
          'Status realizacji',
          'RealizacjaId',
          false,
          undefined,
          DictionaryType.StatusRealizacji,
        ),
        false,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['text'],
        new ControlConfig('Lokalizacja', 'Lokalizacja', false, ValueType.Text),
        true,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['dictionary'],
        new DictionaryControlConfig(
          'Status narady',
          'StatusSprawyId',
          false,
          undefined,
          DictionaryType.StatusSprawy,
        ),
        false,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        false,
        undefined,
        new ControlConfig(
          'Status narady',
          'StatusSprawy',
          false,
          ValueType.Text,
          undefined,
          'StatusSprawyId',
        ),
        true,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        definedFiltersForValue['dateTime'],
        new ControlConfig(
          'Data uzgodnienia',
          'DataUzgodnienie',
          false,
          ValueType.DateTime,
        ),
        false,
        CellSize.DateTime,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        definedFiltersForValue['text'],
        new ControlConfig(
          'Uwagi do uzgodnienia',
          'UwagiUzgodnienie',
          false,
          ValueType.Text,
        ),
        false,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        false,
        definedFiltersForValue['dateTime'],
        new ControlConfig(
          'Data narady',
          'DataPosiedz',
          false,
          ValueType.DateTime,
        ),
        true,
        CellSize.DateTime,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        false,
        definedFiltersForValue['dateTime'],
        new ControlConfig(
          'Data zakończenia narady',
          'DataPosiedzEnd',
          false,
          ValueType.DateTime,
        ),
        true,
        CellSize.DateTime,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        definedFiltersForValue['dateTime'],
        new ControlConfig(
          'Data realizacji',
          'DataRealizacji',
          false,
          ValueType.DateTime,
        ),
        false,
        CellSize.DateTime,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        definedFiltersForValue['text'],
        new ControlConfig(
          'Uwagi do realizacji',
          'UwagiRealizacja',
          false,
          ValueType.Text,
        ),
        false,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        true,
        definedFiltersForValue['boolean'],
        new ControlConfig(
          'Czy jest odpowiedź ośrodka?',
          'IsOdpOsrodka',
          false,
          ValueType.Bool,
        ),
        false,
        CellSize.BoolSize,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        definedFiltersForValue['number'],
        new ControlConfig(
          'Liczba obiektów',
          'IleObiektow',
          false,
          ValueType.Number,
        ),
        false,
        CellSize.TextSmall,
      ),
    ],
    new ListDataConfig(
      new RequestConfig(
        '`/api/zudp/projektant/sprawy/${data.Uuid}`',
        RequestType.Get,
        [],
        true,
      ),
      [
        new ControlConfig('Nr sprawy', 'NrSprawy', true, ValueType.Text),
        new ControlTextWithButtonsControlConfig(
          'Narada dodatkowa',
          'StatusWnioskuNaradyDodatkowejId',
          true,
          [
            new ButtonControlConfig(
              'Wyślij wniosek',
              new RequestConfig(
                isPzSessionOrPzSignRequired
                  ? '/api/zudp/projektant/wniosek/naradadod/addToSign'
                  : '/api/zudp/projektant/wniosek/naradadod',
                RequestType.Post,
                [new RequestParam('ZudUuid', 'Uuid')],
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                '/designer-portal/requests-list',
              ),
              isPzSessionOrPzSignRequired ? true : false,
              'Wniosek został złożony',
              AdditionalCouncilState.Available,
            ),
          ],
          additionalCouncilStateDictFields,
        ),
        new ControlConfig('Nr wniosku', 'NrWniosku', true, ValueType.Text),
        new ControlTextWithCallbackButtonControlConfig(
          'Wiadomości',
          true,
          new CallbackButtonConfig(
            'Komunikator',
            (dynamicListItem, buttonStateControl) => {
              openCommunicatorCallback(dynamicListItem, buttonStateControl);
            },
          ),
        ),
        new ArrayControlConfig(
          'Wnioskodawca',
          'Wnioskodawca',
          true,
          undefined,
          new ControlConfig('Wnioskodawca', '', false, ValueType.Text),
        ),
        new ArrayControlConfig(
          'Płatnik',
          'Platnik',
          true,
          undefined,
          new ControlConfig('Płatnik', '', false, ValueType.Text),
        ),
        new ArrayControlConfig(
          'Inwestor',
          'Inwestor',
          true,
          undefined,
          new ControlConfig('Inwestor', '', false, ValueType.Text),
        ),
        new ArrayControlConfig(
          'Projektant',
          'Projektant',
          true,
          undefined,
          new ControlConfig('Projektant', '', false, ValueType.Text),
        ),
        new ArrayControlConfig(
          'Pełnomocnik',
          'Pelnomocnik',
          true,
          undefined,
          new ControlConfig('Pełnomocnik', '', false, ValueType.Text),
        ),
        new ControlConfig('Lokalizacja', 'Lokalizacja', true, ValueType.Text),
        new NestedAccordionControlConfig(
          'Działka, obręb',
          true,
          [],
          new ListDataConfig(undefined, [
            new TableControlConfig(
              'Działka, obręb',
              false,
              'DzialkaObreb',
              [],
              [
                new ControlConfig('Działka', 'Nr', false, ValueType.Text),
                new ControlConfig('Obręb', 'Obreb', false, ValueType.Text),
              ],
            ),
          ]),
          true,
          undefined,
          undefined,
          'DzialkaObreb.length',
        ),
        new NestedAccordionControlConfig(
          'Przyłącza i sieci',
          true,
          [],
          new ListDataConfig(undefined, [
            new ArrayControlConfig(
              'Przyłącza i sieci',
              'PrzylaczaISieci',
              false,
              ['PrzylaczaISieci'],
              new ControlConfig('Przyłącze/sieć', '', false, ValueType.Text),
            ),
          ]),
          true,
          undefined,
          25,
          'PrzylaczaISieci.length',
        ),
        new ControlConfig(
          'Charakterystyka',
          'Charakterystyka',
          true,
          ValueType.Text,
        ),
        new DictionaryControlConfig(
          'Status wniosku',
          'StatusWnioskuId',
          true,
          undefined,
          DictionaryType.StatusWniosku,
        ),
        new ControlConfig(
          'Opis statusu wniosku',
          'OdpOsrodka',
          true,
          ValueType.Text,
        ),
        new ControlConfig(
          'Status narady',
          'StatusSprawy',
          true,
          ValueType.Text,
        ),
        new ControlConfig(
          'Informacje dodatkowe',
          'DodatkoweInf',
          true,
          ValueType.Text,
        ),
        new ControlConfig(
          'Przewodniczący narady',
          'Przewodniczacy',
          true,
          ValueType.Text,
        ),
        new ControlConfig(
          'Stanowisko przewodniczącego',
          'StanowiskoPrzewodn',
          true,
          ValueType.Text,
        ),
        new ControlConfig(
          'Przewodniczący narady dodatkowej',
          'PrzewodniczacyDod',
          true,
          ValueType.Text,
        ),
        new ControlConfig(
          'Stanowisko przewodniczącego narady dodatkowej',
          'StanowiskoPrzewodnDod',
          true,
          ValueType.Text,
        ),
        new MapNavigateToMapObjectControlConfig(
          'Zakres graficzny sprawy',
          '',
          true,
          new RequestConfig(
            '`/api/zudp/projektant/sprawy/${data.Uuid}/geom`',
            RequestType.Get,
            [],
            true,
            undefined,
            undefined,
            'Result',
          ),
          '/designer-portal/map',
        ),
        new CancelRequestControlConfig(
          'Anulowanie wniosku',
          'Anulowanie wniosku',
          true,
          'StatusWnioskuId',
          'Uuid',
          new RequestConfig(
            `/api/zudp/projektant/wniosek/anuluj`,
            RequestType.Post,
            [],
            false,
          ),
          undefined,
          [RequestStatus.Waiting],
        ),
        new NestedAccordionControlConfig(
          'Opłata',
          true,
          ['Oplaty'],
          new ListDataConfig(undefined, [
            new TableControlConfig(
              'Oplata',
              false,
              'Oplaty',
              [],
              [
                new ControlConfig('Numer', 'OplataNr', false, ValueType.Text),
                new ControlConfig(
                  'Kwota',
                  'OplataKwotaFormatted',
                  false,
                  ValueType.Text,
                  ['DataWystawieniaOplaty'],
                ),
                new StaticDictControlConfig(
                  'Status płatności',
                  'StatusPlatnosci',
                  false,
                  paymentStaticDictFields,
                  ['StatusPlatnosci'],
                ),
                new OrderPaymentControlConfig(
                  'Opłać zamówienie',
                  '',
                  false,
                  'designer-portal',
                  'OplataKwota',
                  ['DataWystawieniaOplaty'],
                ),
                new CancelPaymentControlConfig(
                  'Anulowanie płatności',
                  false,
                  'StatusPlatnosci',
                  'TransId',
                  '/api/payments/webewidpayments/cancel',
                  [],
                  'paymentTrigger',
                ),
              ],
            ),
          ]),
          true,
        ),
        new TitleControlConfig('Stanowiska z Narady Koordynacyjnej', true, []),
        new GetDataAndRenderControlConfig(
          new RequestConfig(
            '`/api/zudp/projektant/sprawy/${data.Uuid}/opinie`',
            RequestType.Post,
            [],
            true,
            undefined,
            undefined,
            'Response',
            'Opinie',
          ),
          [
            new FilterAndRenderControlConfig(
              'Opinie',
              [new FilterRule('RodzajNarady', FilterCondition.Equals, 0)],
              FilterLogicOperator.And,
              'NaradaGlowna',
              [
                new ExpandibleTitleControlConfig(
                  'Narada główna',
                  true,
                  [],
                  new ListDataConfig(undefined, [
                    new TableControlConfig(
                      'Narada główna',
                      false,
                      'NaradaGlowna',
                      [],
                      [
                        new ControlConfig(
                          'Nazwa branży',
                          'BranzaNazwa',
                          false,
                          ValueType.Text,
                          [],
                        ),
                        new ControlConfig(
                          'Przedstawiciel',
                          'Przedstawiciel',
                          false,
                          ValueType.Text,
                          [],
                        ),
                        new StaticDictControlConfig(
                          'Status',
                          'Akceptacja',
                          false,
                          jobStatusStaticDictFields,
                        ),
                        new TextModalControlConfig(
                          'Uwagi',
                          'Uwagi',
                          false,
                          [],
                          60,
                        ),
                      ],
                    ),
                  ]),
                  true,
                  undefined,
                  undefined,
                  'NaradaGlowna.length',
                ),
              ],
            ),
            new FilterAndRenderControlConfig(
              'Opinie',
              [new FilterRule('RodzajNarady', FilterCondition.Equals, 1)],
              FilterLogicOperator.And,
              'NaradaDod',
              [
                new ExpandibleTitleControlConfig(
                  'Narada dodatkowa',
                  true,
                  [],
                  new ListDataConfig(undefined, [
                    new TableControlConfig(
                      'Narada dodatkowa',
                      false,
                      'NaradaDod',
                      [],
                      [
                        new ControlConfig(
                          'Nazwa branży',
                          'BranzaNazwa',
                          false,
                          ValueType.Text,
                          [],
                        ),
                        new ControlConfig(
                          'Przedstawiciel',
                          'Przedstawiciel',
                          false,
                          ValueType.Text,
                          [],
                        ),
                        new StaticDictControlConfig(
                          'Status',
                          'Akceptacja',
                          false,
                          jobStatusStaticDictFields,
                        ),
                        new TextModalControlConfig(
                          'Uwagi',
                          'Uwagi',
                          false,
                          [],
                          60,
                        ),
                      ],
                    ),
                  ]),
                  true,
                  undefined,
                  undefined,
                  'NaradaDod.length',
                ),
              ],
            ),
          ],
        ),
        new TitleControlConfig('Dokumenty Narady Koordynacyjnej', true, []),
        new ExpandibleTitleControlConfig(
          'Dokumenty sprawy',
          true,
          [],
          new ListDataConfig(undefined, [
            new DocumentsListControlConfig(
              'Lista dokumentów wysłanych do urzędu',
              false,
              'Uuid',
              undefined,
              [],
              new RequestConfig(
                '`/api/zudp/projektant/sprawy/${data.Uuid}/wniosek/dokprzych`',
                RequestType.Get,
                [],
                true,
                undefined,
                undefined,
                'Response',
              ),
              undefined,
              new RequestConfig(
                '/api/zudp/projektant/wniosek/dokprzych/download',
                RequestType.Post,
                [
                  new RequestParam('FileUuids', 'Uuid', true),
                  new RequestParam('PortalId', undefined, undefined, portalId),
                ],
                false,
                undefined,
                'blob',
                undefined,
                undefined,
                undefined,
                'response',
              ),
              false,
              true,
              'Rodzaj',
              'Nazwa',
              'Opis',
              'Rozmiar',
              'DataD',
              true,
              undefined,
              undefined,
              'DokPrzychCount',
            ),
            new AddRelatedDocumentControlConfig(
              'Dodaj nowy dokument do wniosku',
              'DokUuid',
              true,
              '/api/zudp/projektant/wniosek/dokprzych/add',
              [],
              undefined,
              [{ pathToValue: 'StatusWniosku', value: 'Odrzucony' }],
            ),
          ]),
          true,
          undefined,
          undefined,
          'DokPrzychCount',
        ),
        new ExpandibleTitleControlConfig(
          'Lista dokumentów z Urzędu',
          true,
          [],
          new ListDataConfig(undefined, [
            new DocumentsListControlConfig(
              'Lista dokumentów z Urzędu',
              false,
              undefined,
              'DokWych',
              [],
              undefined,
              undefined,
              new RequestConfig(
                '/api/zudp/projektant/sprawy/dokwych/download',
                RequestType.Post,
                [new RequestParam('FileUuids', 'Uuid', true)],
                false,
                undefined,
                'blob',
              ),
              false,
              true,
              'Rodzaj',
              'Nazwa',
              'Opis',
              'Rozmiar',
              'DataD',
              true,
            ),
          ]),
          true,
          undefined,
          undefined,
          'DokWych.length',
        ),
      ],
    ),
    new MessagesListConfig(
      new RequestConfig(
        '`/api/zudp/projektant/komunikator/statusnowychwiad`',
        RequestType.Get,
        [],
        true,
        undefined,
        undefined,
        'Result',
      ),
      [
        new ColumnHeader('caseSignature', 'ID pracy'),
        new ColumnHeader('author', 'Od'),
        new ColumnHeader('addDate', 'Data'),
      ],
      new RequestConfig(
        '`/api/zudp/projektant/komunikator/nowewiadomosci`',
        RequestType.Get,
        [],
        true,
      ),
      (message) => NewMessage.fromApiDesignerToApp(message),
      new CallbackButtonConfig(
        'Odczytaj',
        (
          dynamicListItem: any,
          _buttonStateControl: ButtonStateControl,
          refreshCallback,
        ) => {
          readNewMessageCallback(dynamicListItem, refreshCallback);
        },
      ),
    ),
    new CommunicatorControlConfig(
      undefined,
      new RequestConfig(
        '`/api/zudp/projektant/komunikator/sprawa/${data.id}/wiadomosci`',
        RequestType.Get,
        [],
        true,
      ),
      new RequestConfig(
        '`/api/zudp/projektant/komunikator/wiadomosc/dodaj`',
        RequestType.Post,
        [
          new RequestParam('OdgikUserUuid', 'recipient', undefined),
          new RequestParam('Komunikat', 'newMessage', undefined),
          new RequestParam('ZudUuid', 'id', undefined, portalId),
        ],
        true,
      ),
      false,
      false,
      true,
      new RequestConfig(
        '`/api/zudp/projektant/sprawy/${data.id}/narada/czytrwajaca`',
        RequestType.Get,
        [],
        true,
        undefined,
        undefined,
        'Result',
      ),
    ),
  );

export const dtoAvailableParcelsConfig = new ListConfigs(
  new GeneralConfig(
    100,
    false,
    true,
    '/api/interesant/przp/dzialki/search',
    'Lista dostępnych działek',
    'pl',
    false,
    false,
    undefined,
    undefined,
    BsColor.ColorFromDb,
  ),
  [
    new DictionaryConfig(
      DictionaryType.Community,
      'Gminy',
      '/api/interesant/slo/gminy',
      'Id',
      'Nazwa',
      'Response',
    ),
    new DictionaryConfig(
      DictionaryType.PrzpRegistry,
      'Rejestr',
      '/api/interesant/slo/rej/publ',
      'Id',
      'Nazwa',
      'Response',
    ),
  ],
  [
    new ColumnConfig(
      true,
      SortType.None,
      true,
      definedFiltersForValue['text'],
      new ControlConfig(
        'Sygnatura sprawy',
        'SygnaturaSprawy',
        false,
        ValueType.Text,
      ),
      true,
      CellSize.TextBig,
    ),
    new ColumnConfig(
      true,
      SortType.None,
      true,
      definedFiltersForValue['dictionary'],
      new DictionaryControlConfig(
        'Rejestr',
        'RejestrId',
        false,
        undefined,
        DictionaryType.PrzpRegistry,
      ),
      true,
      CellSize.TextBig,
    ),
    new ColumnConfig(
      true,
      SortType.None,
      false,
      definedFiltersForValue['text'],
      new ControlConfig(
        'Obręb',
        'Obreb',
        false,
        ValueType.Text,
        undefined,
        'ObrebId',
      ),
      true,
      CellSize.TextBig,
    ),
    new ColumnConfig(
      true,
      SortType.None,
      true,
      definedFiltersForValue['dictionary'],
      new DictionaryControlConfig(
        'Gmina',
        'GminaId',
        false,
        undefined,
        DictionaryType.Community,
      ),
      false,
      CellSize.TextSmall,
      true,
      false,
      false,
      true,
    ),
    new ColumnConfig(
      true,
      SortType.None,
      true,
      definedFiltersForValue['text'],
      new ControlConfig('Adres', 'Adres', false, ValueType.Text),
      true,
      CellSize.TextBig,
    ),
    new ColumnConfig(
      true,
      SortType.None,
      true,
      definedFiltersForValue['text'],
      new ControlConfig('Numer', 'Numer', false, ValueType.Text),
      true,
      CellSize.TextSmall,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      true,
      definedFiltersForValue['text'],
      new ControlConfig(
        'Władający',
        'Wladajacy',
        false,
        ValueType.Text,
        undefined,
        undefined,
        undefined,
        undefined,
        'NEW_REQUEST.OWNER_FILTER_PLACEHOLDER',
      ),
      false,
      CellSize.TextBig,
      undefined,
      undefined,
      undefined,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new AppActionLinkControlConfig('Akcja', 'Id', true, [
        new ActionButtonConfig(
          undefined,
          'Podgląd',
          BsColor.Success,
          ActionType.ShowOnMap,
          new RequestConfig(
            '/api/mapa/dzialki/geom',
            RequestType.Get,
            [new RequestParam('Uuids', 'KdzialkaUuid', true)],
            false,
          ),
        ),
        new ActionButtonConfig(
          undefined,
          'Pokaż atrybuty',
          BsColor.Success,
          ActionType.ShowParcelAttributes,
          undefined,
          [new SpecificValueOfPropertyConf('RejestrId', 1)],
        ),
      ]),
      true,
      CellSize.TextSmall,
    ),
  ],
  new ListDataConfig(undefined, [], ListType.ListGroup),
);

export const getDtoEServicesConfig = (
  portalId: number = undefined,
  openCommunicatorCallback?: (
    dynamicListItem: any,
    buttonStateControl: ButtonStateControl,
  ) => void,
  readNewMessageCallback?: (
    dynamicListItem: any,
    refreshCallback: () => any,
  ) => void,
  webChatEnabled?: boolean,
): ListConfigs =>
  new ListConfigs(
    new GeneralConfig(
      20,
      true,
      true,
      '/api/interesant/euslugi/wnioski/search',
      'Złożone wnioski',
      'pl',
      true,
      true,
      undefined,
      undefined,
      undefined,
      undefined,
      portalId,
    ),
    [
      new DictionaryConfig(
        DictionaryType.RodzajDokumentu,
        'Rodzaj dokumentu',
        '/api/eod/slo/kdokrodz/portal',
        'Id',
        'Nazwa',
        'Response',
        { PortalId: portalId },
      ),
    ],
    [
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['text'],
        new ControlConfig('Wniosek', 'Numer', false, ValueType.Text),
        true,
        CellSize.TextSmall,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['text'],
        new ControlConfig(
          'Sprawa',
          'SygnaturaListaSpraw',
          false,
          ValueType.Text,
        ),
        true,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['dateTime'],
        new ControlConfig(
          'Data wpływu',
          'DataWplywu',
          false,
          ValueType.DateTime,
        ),
        true,
        CellSize.DateTime,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['text'],
        new ControlConfig('Temat', 'Temat', false, ValueType.Text),
        true,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['dateTime'],
        new ControlConfig(
          'Data realizacji',
          'DataRealizacji',
          false,
          ValueType.DateTime,
        ),
        true,
        CellSize.DateTime,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['text'],
        new ControlConfig('Status', 'Status', false, ValueType.Text),
        true,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        true,
        SortType.None,
        true,
        definedFiltersForValue['landParcelNumber'],
        new ControlConfig(
          'Numer działki',
          'Dzialki',
          false,
          ValueType.LandParcelNumber,
          undefined,
          'Polozenie',
          'Polozenie',
        ),
        true,
        CellSize.TextSmall,
      ),
    ],
    new ListDataConfig(
      undefined,
      [
        new ExpandibleTitleControlConfig(
          'Lista dokumentów wysłanych do urzędu',
          true,
          [],
          new ListDataConfig(undefined, [
            new FileControlConfig(
              'REQUESTS_LIST.REQUEST',
              '',
              true,
              ['DokUuid'],
              new RequestConfig(
                '/api/interesant/euslugi/wnioski/download',
                RequestType.Post,
                [
                  new RequestParam('DokUuid', 'DokUuid'),
                  new RequestParam('PortalId', undefined, undefined, portalId),
                ],
                undefined,
                undefined,
                'blob',
              ),
              '',
              undefined,
              'REQUESTS_LIST.DOWNLOAD_REQUEST_WITH_ATTACHMENTS',
            ),
          ]),
          true,
        ),
        new GetDataAndRenderControlConfig(
          new RequestConfig(
            '`/api/interesant/euslugi/wniosek/${data.DokId}/oplaty/szczegoly`',
            RequestType.Get,
            [],
            true,
            undefined,
            undefined,
            undefined,
            'Oplata',
          ),
          [
            new TableControlConfig(
              'Oplata',
              false,
              'Oplata',
              [],
              [
                new ControlConfig('Numer', 'OplataNr', false, ValueType.Text),
                new ControlConfig(
                  'Kwota',
                  'OplataKwotaFormatted',
                  false,
                  ValueType.Text,
                  ['DataWystawieniaOplaty'],
                ),
                new StaticDictControlConfig(
                  'Status płatności',
                  'StatusPlatnosci',
                  false,
                  paymentStaticDictFields,
                  ['StatusPlatnosci'],
                ),
                new OrderPaymentControlConfig(
                  'Opłać zamówienie',
                  '',
                  false,
                  undefined,
                  'OplataKwota',
                  ['DataWystawieniaOplaty'],
                ),
              ],
            ),
          ],
          ['Oplaty'],
          'paymentTrigger',
        ),
        new AddRelatedDocumentControlConfig(
          'Dodaj potwierdzenie zapłaty',
          'DokUuid',
          true,
          '/api/interesant/euslugi/wnioski/potwierdzenie/zaplaty/dodaj',
          ['Oplaty'],
          undefined,
          [{ pathToValue: 'Status', value: 'Zakończony' }],
        ),
        new ExpandibleTitleControlConfig(
          'Lista dokumentów z Urzędu',
          true,
          [],
          new ListDataConfig(undefined, [
            new DocumentsListControlConfig(
              'Lista dokumentów z Urzędu',
              false,
              'Id',
              undefined,
              [],
              new RequestConfig(
                '`/api/interesant/euslugi/wnioski/${data.DokId}/dokwych`',
                RequestType.Get,
                [],
                true,
                undefined,
                undefined,
                'Response',
              ),
              undefined,
              new RequestConfig(
                '/api/system/edok/download',
                RequestType.Post,
                [new RequestParam('FileUuids', 'Uuid', true)],
                false,
                undefined,
                'blob',
              ),
              false,
              true,
              'Rodzaj',
              'Nazwa',
              'Opis',
              'Rozmiar',
              'DataD',
              true,
            ),
          ]),
          true,
          undefined,
        ),
        new AddRelatedDocumentControlConfig(
          'Dodaj nowy dokument do wniosku w celu uzupełnienia',
          'DokUuid',
          true,
          '/api/interesant/euslugi/wnioski/dokumenty',
          ['CanAddAttachment'],
          undefined,
          undefined,
          true,
        ),
        ...(webChatEnabled
          ? [
              new ControlTextWithCallbackButtonControlConfig(
                'Wiadomości',
                false,
                new CallbackButtonConfig(
                  'Komunikator',
                  (dynamicListItem, buttonStateControl) => {
                    openCommunicatorCallback(
                      dynamicListItem,
                      buttonStateControl,
                    );
                  },
                ),
                undefined,
                'd-flex justify-content-center pt-2  ',
              ),
            ]
          : []),
      ],
      ListType.Accordion,
    ),
    webChatEnabled
      ? new MessagesListConfig(
          new RequestConfig(
            `/api/interesant/komunikator/${portalId}/statusnowychwiad`,
            RequestType.Get,
            [],
            false,
            undefined,
            undefined,
            'Result',
          ),
          [
            new ColumnHeader('caseSignature', 'Nr wniosku'),
            new ColumnHeader('author', 'Od'),
            new ColumnHeader('addDate', 'Data'),
          ],
          new RequestConfig(
            `/api/interesant/komunikator/portal/${portalId}/nowewiadomosci`,
            RequestType.Get,
            [],
            false,
          ),
          (message) => NewMessage.fromApiSurveyorToApp(message),
          new CallbackButtonConfig(
            'Odczytaj',
            (
              dynamicListItem: any,
              _buttonStateControl: ButtonStateControl,
              refreshCallback,
            ) => {
              readNewMessageCallback(dynamicListItem, refreshCallback);
            },
          ),
        )
      : undefined,
    new CommunicatorControlConfig(
      undefined,
      new RequestConfig(
        '`/api/interesant/komunikator/dok/${data.id}/wiadomosci`',
        RequestType.Get,
        [],
        true,
      ),
      new RequestConfig(
        '`/api/interesant/komunikator/wiadomosc`',
        RequestType.Post,
        [
          new RequestParam('IdSprawy', 'id', undefined),
          new RequestParam('Komunikat', 'newMessage', undefined),
          new RequestParam('PortalId', 'portalId', undefined),
        ],
        true,
      ),
      false,
      false,
      false,
      undefined,
      portalId,
    ),
  );

export const ecoPortalDocumentsConfig = new ListConfigs(
  new GeneralConfig(
    20,
    false,
    true,
    '/api/srodowisko/wykazdok/search',
    'Wyniki wyszukiwania',
    'pl',
    undefined,
    undefined,
    true,
    true,
    BsColor.ColorFromDb,
  ),
  undefined,
  [
    new ColumnConfig(
      true,
      SortType.None,
      false,
      undefined,
      new ControlConfig('Numer', 'Numer', false, ValueType.Text),
      true,
      CellSize.DateTime,
    ),
    new ColumnConfig(
      true,
      SortType.None,
      false,
      undefined,
      new ControlConfig('Nazwa dokumentu', 'Nazwa', false, ValueType.Text),
      true,
      CellSize.TextBig,
    ),
    new ColumnConfig(
      true,
      SortType.None,
      false,
      undefined,
      new ControlConfig('Znak sprawy', 'ZnakSprawy', false, ValueType.Text),
      true,
      CellSize.TextBig,
    ),
    new ColumnConfig(
      true,
      SortType.None,
      false,
      undefined,
      new ControlConfig(
        'Data publikacji',
        'DataPublikacji',
        false,
        ValueType.DateTime,
      ),
      true,
      CellSize.DateTime,
    ),
    new ColumnConfig(
      true,
      SortType.None,
      false,
      undefined,
      new ControlConfig('Urząd', 'Urzad', false, ValueType.Text),
      true,
      CellSize.TextBig,
    ),
    new ColumnConfig(
      true,
      SortType.None,
      false,
      undefined,
      new ControlConfig('Dokument utworzył', 'Utworzyl', false, ValueType.Text),
      true,
      CellSize.TextSmall,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new AppActionLinkControlConfig('Akcja', 'Id', true, [
        new ActionButtonConfig(
          '/eco-portal/document-details',
          'Podgląd',
          BsColor.ColorFromDb,
          ActionType.Route,
        ),
      ]),
      true,
      CellSize.TextSmall,
    ),
  ],
  new ListDataConfig(
    undefined,
    [
      new ControlConfig('Numer karty/rok', 'NrKartyRok', true, ValueType.Text),
      new ControlConfig(
        'Rodzaj dokumentu',
        'RodzajDokumentu',
        true,
        ValueType.Text,
      ),
      new ControlConfig(
        'Nazwa dokumentu',
        'NazwaDokumentu',
        true,
        ValueType.Text,
      ),
      new ControlConfig(
        'Zakres przedmiotowy dokumentu - opis dokumentu',
        'Opis',
        true,
        ValueType.Text,
      ),
      new NestedAccordionControlConfig(
        'Obszar, którego dokument dotyczy, zgodnie z podziałem administracyjnym kraju',
        true,
        [],
        new ListDataConfig(undefined, [
          new ControlConfig('Województwo', 'Wojewodztwo', true, ValueType.Text),
          new ControlConfig('Powiat', 'Powiat', true, ValueType.Text),
        ]),
        false,
      ),
      new ControlConfig('Znak sprawy', 'ZnakSprawy', true, ValueType.Text),
      new NestedAccordionControlConfig(
        'Dokument utworzył',
        true,
        [],
        new ListDataConfig(undefined, [
          new ControlConfig('Nazwa', 'Tworca.Nazwa', true, ValueType.Text),
          new ControlConfig(
            'Ulica',
            'Tworca.Adres.Ulica',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Numer domu',
            'Tworca.Adres.Numer',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Numer lokalu',
            'Tworca.Adres.NumerLokalu',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Miejscowość',
            'Tworca.Adres.Miejscowosc',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Kod pocztowy',
            'Tworca.Adres.KodPocztowy',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Poczta',
            'Tworca.Adres.Poczta',
            true,
            ValueType.Text,
          ),
          new ControlConfig('Regon', 'Tworca.Regon', true, ValueType.Text),
          new ControlConfig('Nip', 'Tworca.Nip', true, ValueType.Text),
        ]),
        false,
      ),
      new ControlConfig('Data dokumentu', 'DataDok', true, ValueType.DateTime),
      new NestedAccordionControlConfig(
        'Dokument zatwierdził',
        true,
        [],
        new ListDataConfig(undefined, [
          new ControlConfig(
            'Nazwa',
            'OsobaAkceptujaca.Nazwa',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Ulica',
            'OsobaAkceptujaca.Adres.Ulica',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Numer domu',
            'OsobaAkceptujaca.Adres.Numer',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Numer lokalu',
            'OsobaAkceptujaca.Adres.NumerLokalu',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Miejscowość',
            'OsobaAkceptujaca.Adres.Miejscowosc',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Kod pocztowy',
            'OsobaAkceptujaca.Adres.KodPocztowy',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Poczta',
            'OsobaAkceptujaca.Adres.Poczta',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Regon',
            'OsobaAkceptujaca.Regon',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Nip',
            'OsobaAkceptujaca.Nip',
            true,
            ValueType.Text,
          ),
        ]),
        false,
      ),
      new ControlConfig(
        'Data zatwierdzenia dokumentu',
        'DataAkcept',
        true,
        ValueType.DateTime,
      ),
      new NestedAccordionControlConfig(
        'Miejsce przechowywania dokumentu',
        true,
        [],
        new ListDataConfig(undefined, [
          new ControlConfig(
            'Urząd',
            'MiejscePrzechowywania.Urzad',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Grupa użytkowników',
            'MiejscePrzechowywania.GrupyUzytkownikow',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Ulica',
            'MiejscePrzechowywania.Adres.Ulica',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Numer domu',
            'MiejscePrzechowywania.Adres.Numer',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Numer lokalu',
            'MiejscePrzechowywania.Adres.NumerLokalu',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Miejscowość',
            'MiejscePrzechowywania.Adres.Miejscowosc',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Kod pocztowy',
            'MiejscePrzechowywania.Adres.KodPocztowy',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Poczta',
            'MiejscePrzechowywania.Adres.Poczta',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Email',
            'MiejscePrzechowywania.Email',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Telefon',
            'MiejscePrzechowywania.Telefon',
            true,
            ValueType.Text,
          ),
          new ControlConfig(
            'Miejsce przechowywania',
            'MiejscePrzechowywania.MiejscePrzechowywania',
            true,
            ValueType.Text,
          ),
        ]),
        false,
      ),
      new ControlConfig(
        'Adres elektroniczny zawierający odnośnik do dokumentu',
        'HyperLink',
        true,
        ValueType.Link,
      ),
      new ControlConfig(
        'Czy dokument jest ostateczny tak/nie',
        'Ostateczny',
        true,
        ValueType.Bool,
      ),
      new ControlConfig(
        'Czy dokument jest zniszczony',
        'Zniszczony',
        true,
        ValueType.Bool,
      ),
      new ControlConfig(
        'Numery kart innych dokumentów w sprawie (Karta 1)',
        'Karta1Ids',
        true,
        ValueType.Array,
      ),
      new ControlConfig(
        'Numery kart innych dokumentów w sprawie (Karta 2)',
        'Karta2Ids',
        true,
        ValueType.Array,
      ),
      new ControlConfig(
        'Zastrzeżenia dotyczące nieudostępniania informacji',
        'Zastrzezenia',
        true,
        ValueType.Text,
      ),
      new ControlConfig('Uwagi', 'Uwagi', true, ValueType.Text),
    ],
    ListType.ListGroup,
  ),
);

export const getSurveyorPortalAcceptedWorksListConfig = (
  supplementaryDeclarationCallback: (
    dynamicListItem: any,
    buttonStateControl: ButtonStateControl,
  ) => void,
  openCommunicatorCallback?: (
    dynamicListItem: any,
    buttonStateControl: ButtonStateControl,
  ) => void,
  readNewMessageCallback?: (
    dynamicListItem: any,
    refreshCallback: () => any,
  ) => void,
): ListConfigs =>
  new ListConfigs(
    new GeneralConfig(
      50,
      true,
      true,
      '/api/geodeta/prace/przyjete/search',
      'Prace przyjęte',
      'pl',
      false,
      false,
      undefined,
      undefined,
    ),
    undefined,
    [
      new ColumnConfig(
        false,
        SortType.None,
        false,
        undefined,
        new ControlConfig(
          'ID pracy skrót',
          'IdPracySkrot',
          false,
          ValueType.Text,
        ),
        true,
        CellSize.TextSmall,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        undefined,
        new ControlConfig('Nr wyk.', 'NrUWykonawcy', false, ValueType.Text),
        true,
        CellSize.TextSmall,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        undefined,
        new ControlConfig(
          'Naz. ob./Ozn. nier.',
          'Lokalizacja',
          false,
          ValueType.Text,
        ),
        true,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        undefined,
        new ControlConfig('Cel pracy', 'CelPracy', false, ValueType.Text),
        true,
        CellSize.TextSmall,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        undefined,
        new ControlConfig('Data stat.', 'StatusData', false, ValueType.Text),
        true,
        CellSize.TextSmall,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        undefined,
        new ControlConfig('Status', 'StatusOpis', false, ValueType.Text),
        true,
        CellSize.TextBig,
      ),
      new ColumnConfig(
        false,
        SortType.None,
        false,
        undefined,
        new ControlConfig('Wiad.', 'Wiadomosc', false, ValueType.Bool),
        true,
        CellSize.TextSmall,
      ),
    ],
    new ListDataConfig(
      new RequestConfig(
        '`/api/geodeta/prace/przyjete/${data.Id}`',
        RequestType.Get,
        [],
        true,
      ),
      [
        new ControlConfig('ID pracy', 'IdPracy', true, ValueType.Text),
        new ControlTextWithCallbackButtonControlConfig(
          'Zgłoszenie uzupełniające',
          true,
          new CallbackButtonConfig(
            'Utwórz zgłoszenie',
            (dynamicListItem: any, buttonStateControl: ButtonStateControl) => {
              supplementaryDeclarationCallback(
                dynamicListItem,
                buttonStateControl,
              );
            },
          ),
        ),
        new ControlConfig('ID operatu', 'IdOperatu', true, ValueType.Text),
        new ControlConfig(
          'Nr operatu na półce',
          'NrOperatuNaPolce',
          true,
          ValueType.Text,
        ),
        new ControlConfig('Zakres', 'Zakres', true, ValueType.Text),
        new ControlConfig(
          'Praca zaniechana',
          'PracaZaniechana',
          true,
          ValueType.Bool,
        ),
        new ControlConfig('Obręb', 'ObrebNazwa', true, ValueType.Text),

        new ArrayControlConfig(
          'Opłata',
          'Oplata',
          true,
          undefined,
          new ControlConfig('Opłata', '', false, ValueType.Text),
        ),
        new ControlConfig(
          'Data zgłoszenia',
          'DataZgloszenia',
          true,
          ValueType.DateTime,
        ),
        new ControlConfig(
          'Data przyjęcia zgłoszenia',
          'DataPrzyjeciaZgloszenia',
          true,
          ValueType.DateTime,
        ),
        new ControlConfig(
          'Termin rozpoczęcia prac',
          'TerminRozpoczeciaPrac',
          true,
          ValueType.DateTime,
        ),
        new ControlConfig(
          'Przewidywana data zakończenia',
          'DataPrzewidywanaZakonczenia',
          true,
          ValueType.DateTime,
        ),
        new ArrayControlConfig(
          'Cel pracy',
          'CelPracy',
          true,
          undefined,
          new ControlConfig('Cel pracy', '', false, ValueType.Text),
        ),
        new ControlConfig(
          'Numer u wykonawcy',
          'NrUWykonawcy',
          true,
          ValueType.Text,
        ),
        new ControlConfig('Wykonawca', 'Wykonawca', true, ValueType.Text),
        new ArrayControlConfig(
          'Uprawnieni',
          'Uprawnieni',
          true,
          undefined,
          new ControlConfig('Uprawnieni', '', false, ValueType.Text),
        ),
        new ControlConfig(
          'Oznaczenie nieruchomości',
          'OznaczenieNieruchomosci',
          true,
          ValueType.Text,
        ),
        new ControlConfig(
          'Nazwa obiektu',
          'NazwaObiektu',
          true,
          ValueType.Text,
        ),
        new ControlConfig(
          'Informacje dodatkowe',
          'InformacjeDodatkowe',
          true,
          ValueType.Text,
        ),
        new ControlConfig(
          'Portal zgłaszajacy',
          'PortalZglaszajacy',
          true,
          ValueType.Text,
        ),
        new StaticDictControlConfig(
          'Praca zakończona',
          'PracaZakonczona',
          true,
          isWorkEndedStaticDictFields,
        ),
        new StaticDictControlConfig(
          'Charakter obiektu',
          'CharakterObiektu',
          true,
          facilityNatureStaticDictFields,
        ),
        new StaticDictControlConfig(
          'Zamawiający',
          'Zamawiajacy',
          true,
          ordererStaticDictFields,
        ),
        new ControlConfig(
          'Uwagi do zakończenia pracy',
          'UwagiDoZakonczeniaPracy',
          true,
          ValueType.Text,
        ),
        new ControlConfig('ID', 'KergId', true, ValueType.Text),
        new MapNavigateToMapObjectControlConfig(
          'Zakres graficzny operatu',
          '',
          true,
          new RequestConfig(
            '`/api/geodeta/prace/przyjete/${data.Id}/zakres`',
            RequestType.Get,
            [],
            true,
            undefined,
            'json',
            'Result',
          ),
          '/surveyor-portal/map',
        ),
        new ControlTextWithCallbackButtonControlConfig(
          'Wiadomości',
          true,
          new CallbackButtonConfig(
            'Komunikator',
            (dynamicListItem, buttonStateControl) => {
              openCommunicatorCallback(dynamicListItem, buttonStateControl);
            },
          ),
        ),
        new ExpandibleTitleControlConfig(
          'Lista dokumentów z Urzędu',
          true,
          [],
          new ListDataConfig(undefined, [
            new DocumentsListControlConfig(
              'Lista dokumentów z Urzędu',
              false,
              'Uuid',
              undefined,
              [],
              new RequestConfig(
                '`/api/geodeta/prace/przyjete/${data.KergId}/dokwych`',
                RequestType.Get,
                [],
                true,
                undefined,
                undefined,
                'Response',
              ),
              undefined,
              new RequestConfig(
                '/api/geodeta/dokumentywychodzace/download',
                RequestType.Post,
                [
                  new RequestParam('FileUuids', 'Uuid', true),
                  new RequestParam('KergId', 'OwnerId'),
                ],
                false,
                undefined,
                'blob',
              ),
              false,
              true,
              'Rodzaj',
              'Nazwa',
              'Opis',
              'Rozmiar',
              'DataD',
              true,
              'Blokuj',
              'SURVEYOR_PORTAL.BLOCKED_FILE',
            ),
            new BookmarkControlConfig(
              'Zakładki',
              false,
              [],
              [
                new BookmarkTabstripConfig(
                  'Opłata',
                  [
                    new ColumnHeader('Oplata', 'Opłata'),
                    new ColumnHeader('DataWystawOpl', 'Data wystaw.'),
                    new ColumnHeader('NiezaleznaOplata', 'Niezalezna opłata'),
                    new ColumnHeader('DataZaplacona', 'Data zapłaty'),
                    new ColumnHeader('DataAnulowaniaOpl', 'Data anulowania'),
                  ],
                  new RequestConfig(
                    '`/api/geodeta/prace/przyjete/${data.Id}/oplaty`',
                    RequestType.Get,
                    [],
                    true,
                    undefined,
                    undefined,
                    'Oplaty',
                  ),
                  true,
                ),
                new BookmarkTabstripConfig(
                  'EKS',
                  [
                    new ColumnHeader('Czynnosc', 'Czynność'),
                    new ColumnHeader('DataRozpoczecia', 'Data rozp.'),
                    new ColumnHeader('Prowadzacy', 'Wykonał'),
                    new ColumnHeader('Odbierajacy', 'Przypisany do'),
                    new ColumnHeader('NrOperatu', 'Id Oper.(Nr Oper. półka)'),
                    new ColumnHeader('Uwagi', 'Uwagi'),
                  ],
                  new RequestConfig(
                    '`/api/geodeta/prace/${data.Id}/eks`',
                    RequestType.Get,
                    [],
                    true,
                    undefined,
                    undefined,
                    'Response',
                  ),
                ),
              ],
            ),
          ]),
          true,
          undefined,
          undefined,
          'DokWychCount',
        ),
      ],
      ListType.Accordion,
    ),
    new MessagesListConfig(
      new RequestConfig(
        '`/api/geodeta/komunikator/prace/przyjete/statusnowychwiad`',
        RequestType.Get,
        [],
        true,
        undefined,
        undefined,
        'Result',
      ),
      [
        new ColumnHeader('caseSignature', 'ID pracy'),
        new ColumnHeader('author', 'Od'),
        new ColumnHeader('addDate', 'Data'),
      ],
      new RequestConfig(
        '`/api/geodeta/komunikator/prace/przyjete/nowewiadomosci`',
        RequestType.Get,
        [],
        true,
      ),
      (message) => NewMessage.fromApiSurveyorToApp(message),
      new CallbackButtonConfig(
        'Odczytaj',
        (
          dynamicListItem: any,
          _buttonStateControl: ButtonStateControl,
          refreshCallback,
        ) => {
          readNewMessageCallback(dynamicListItem, refreshCallback);
        },
      ),
    ),
    new CommunicatorControlConfig(
      new RequestConfig(
        '`/api/geodeta/komunikator/prace/przyjete/${data.id}/adresaci`',
        RequestType.Get,
        [],
        true,
      ),
      new RequestConfig(
        '`/api/geodeta/komunikator/prace/przyjete/${data.id}/wiadomosci`',
        RequestType.Get,
        [],
        true,
      ),
      new RequestConfig(
        '`/api/geodeta/komunikator/prace/przyjete/wiadomosc/dodaj`',
        RequestType.Post,
        [
          new RequestParam('IdSprawy', 'id', undefined),
          new RequestParam('Komunikat', 'newMessage', undefined),
          new RequestParam('AdresaciUuids', 'recipient', true),
        ],
        true,
      ),
    ),
  );

export const surveyorPortalPendingWorksListConfig = new ListConfigs(
  new GeneralConfig(
    20,
    false,
    true,
    '/api/geodeta/prace/oczekujace',
    'Prace oczekujące',
    'pl',
    false,
    false,
    true,
    false,
    undefined,
    SurveyorPendingWork.fromApi,
  ),
  undefined,
  [
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new ControlConfig('ID pracy', 'id', false, ValueType.Number),
      true,
      CellSize.TextSmall,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new ControlConfig('Nr wyk.', 'numberAtContractor', false, ValueType.Text),
      true,
      CellSize.TextSmall,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new ControlConfig(
        'Naz. ob./Ozn. nier.',
        'objectNameOrPropertyDesignation',
        false,
        ValueType.Text,
      ),
      true,
      CellSize.TextBig,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new ControlConfig(
        'Data zgł.',
        'declarationDate',
        false,
        ValueType.DateTime,
      ),
      true,
      CellSize.TextSmall,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new ControlConfig(
        'Data zak.',
        'expectedEndDate',
        false,
        ValueType.DateTime,
      ),
      true,
      CellSize.TextSmall,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new ControlConfig('Cel pracy', 'workPurpose', false, ValueType.Text),
      true,
      CellSize.TextSmall,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new ControlConfig('Status', 'status', false, ValueType.Text),
      true,
      CellSize.TextSmall,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new ControlConfig('Zakr.', 'isRange', false, ValueType.Text),
      true,
      CellSize.TextSmall,
    ),
    new ColumnConfig(
      false,
      SortType.None,
      false,
      undefined,
      new ControlConfig('Wiad.', 'chatStatus', false, ValueType.Text),
      true,
      CellSize.TextSmall,
    ),
  ],
  new ListDataConfig(undefined, [], ListType.ListGroup),
);
