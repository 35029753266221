@if (getMapObjectTableState()) {
  @if (getMapObjectTableState().title) {
    <div class="mt-2 mb-1 fw-bold text-center">
      <span>{{ getMapObjectTableState().title | translate }}</span>
    </div>
  }
  <div class="d-flex flex-wrap">
    @if (!singleObjectMode) {
      <button
        type="button"
        class="btn btn-outline-color-from-db btn-sm gk-map-icon-select-all bg-white text-secondary text-secondary"
        (click)="selectAllMapObjects()"
        [attr.aria-label]="'GK.MAP.SELECT_ALL_OBJECTS' | translate"
        [title]="'GK.MAP.SELECT_ALL_OBJECTS' | translate"
      ></button>
    }
    <button
      type="button"
      class="btn btn-outline-color-from-db btn-sm gk-map-icon-deselect-all bg-white text-secondary"
      (click)="deselectAllMapObjects()"
      [attr.aria-label]="'GK.MAP.DESELECT_ALL_OBJECTS' | translate"
      [title]="'GK.MAP.DESELECT_ALL_OBJECTS' | translate"
    ></button>
    @if (getMapObjectTableState().isEnabledRemoving) {
      <button
        type="button"
        class="btn btn-outline-color-from-db btn-sm gk-map-icon-remove-selected bg-white text-secondary"
        (click)="removeSelectedMapObjects()"
        [attr.aria-label]="'GK.MAP.REMOVE_SELECTED_OBJECTS' | translate"
        [title]="'GK.MAP.REMOVE_SELECTED_OBJECTS' | translate"
      ></button>
    }
    @if (getMapObjectTableState().isEnabledRemoving) {
      <button
        type="button"
        class="btn btn-outline-color-from-db btn-sm gk-map-icon-remove bg-white text-secondary"
        (click)="removeAllMapObjects()"
        [attr.aria-label]="'GK.MAP.REMOVE_ALL_OBJECTS' | translate"
        [title]="'GK.MAP.REMOVE_ALL_OBJECTS' | translate"
      ></button>
    }
    <button
      type="button"
      class="btn btn-outline-color-from-db btn-sm bg-white text-secondary"
      [ngClass]="getMapObjectTableState().zoomIcon"
      (click)="zoomToSelectedMapObjects()"
      [attr.aria-label]="getMapObjectTableState().zoomTitle | translate"
      [title]="getMapObjectTableState().zoomTitle | translate"
    >
      @if (getMapObjectTableState().zoomLabel) {
        <span>{{ getMapObjectTableState().zoomLabel | translate }}</span>
      }
    </button>
    @if (
      areLandParcelAndRangeDefinedInToolTypesConfiguration() &&
      getMapObjectTableState().rangeFromParcelsVisible
    ) {
      <gk-range-from-selected-parcels-button
        [mapState]="mapState"
        (dispatch)="dispatch.emit($event)"
        [specifiedMapObjectTableState]="specifiedMapObjectTableState"
        [attributesFormMode]="attributesFormMode"
        [selectionMode]="
          singleObjectMode
            ? tableSelectionMode.Single
            : tableSelectionMode.Multiple
        "
        (parentDispatch)="parentDispatch.emit($event)"
      ></gk-range-from-selected-parcels-button>
    }
  </div>
  <div
    class="table-responsive table-bordered table-hover map-object-table bg-white text-center"
    [style.max-height.rem]="getMapObjectTableState().relativeMaxHeight"
  >
    <gk-table
      (select)="handleTableSelection($event)"
      [selectedRows]="getSelectedRows()"
      [records]="getMapObjectTableState().mapObjects"
      [settings]="getColumnHeaders()"
      [cellTemplate]="cellTemplate"
      [preCellTemplate]="placeAndStreetInputsTemplate"
      [dictionaryFieldsTemplate]="dictionaryFieldsTemplate"
      [selectionMode]="
        singleObjectMode
          ? tableSelectionMode.Single
          : tableSelectionMode.Multiple
      "
      [showInitialEmptyRequiredRow]="
        getMapObjectTableState().showInitialEmptyRequiredRow
      "
      [loading]="false"
    ></gk-table>
  </div>
  <ng-container [formGroup]="placeAndStreetFormGroup">
    <ng-container formArrayName="mapObjectsArray">
      <ng-template
        #placeAndStreetInputsTemplate
        let-record="record"
        let-index="index"
      >
        @if (getMapObjectTableState().placeAndStreetInputs) {
          <td>
            <select
              (click)="$event.stopPropagation()"
              [title]="placeAndStreetControlName.Place"
              [name]="placeAndStreetControlName.Place"
              [formControl]="getSelectedPlaceFormControl(record.uuid)"
              [ngClass]="{
                required: getMapObjectTableState().placeInputRequired
              }"
              [attr.aria-required]="getMapObjectTableState().placeInputRequired"
              class="form-select form-select-sm"
            >
              @for (
                opt of record.placesOfDistrict ||
                  (getPlacesByDistrictId(record) | async);
                track opt
              ) {
                <option [ngValue]="opt">
                  {{ opt.name }}
                </option>
              }
            </select>
          </td>
          <td>
            <select
              (click)="$event.stopPropagation()"
              [title]="placeAndStreetControlName.Street"
              [name]="placeAndStreetControlName.Street"
              [formControl]="getStreetFormGroup(record.uuid)"
              [ngClass]="{
                required: getMapObjectTableState().streetInputRequired
              }"
              [attr.aria-required]="
                getMapObjectTableState().streetInputRequired
              "
              class="form-select form-select-sm"
            >
              @for (
                opt of getStreetsOptionsFormControl(record.uuid).value;
                track trackStreet
              ) {
                <option [ngValue]="opt">
                  {{ opt.name }}
                </option>
              }
            </select>
          </td>
        }
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #dictionaryFieldsTemplate let-record="record">
    @if (getMapObjectTableState().isEnabledBuildingLocation) {
      <td>
        <span>{{ record.buildingPositionTranslationKey | translate }}</span>
      </td>
    }
    @if (getMapObjectTableState().isEnabledBuildingStatus) {
      <td>
        <span>{{ getBuildingStatusField(record) }}</span>
      </td>
    }
  </ng-template>
  <ng-template #cellTemplate let-record="record">
    @if (getMapObjectTableState().isEnabledPreview) {
      <td>
        @if (record.geom) {
          <button
            type="button"
            class="btn btn-outline-color-from-db btn-sm gk-map-icon-zoom-to-extent bg-white text-secondary"
            (click)="zoomToMapObject(record)"
            [attr.aria-label]="'GK.MAP.ZOOM_TO_OBJECT' | translate"
            [title]="'GK.MAP.ZOOM_TO_OBJECT' | translate"
          ></button>
        }
        @if (!record.geom) {
          <span>{{ 'GK.MAP.NO_GEOMETRY' | translate }}</span>
        }
      </td>
    }
    @if (getMapObjectTableState().isEnabledRemoving) {
      <td>
        <button
          type="button"
          class="btn btn-danger btn-sm gk-map-icon-remove"
          (click)="removeMapObject(record)"
          [attr.aria-label]="'GK.MAP.REMOVE' | translate"
          [title]="'GK.MAP.REMOVE' | translate"
        ></button>
      </td>
    }
    @if (getMapObjectTableState().isEnabledEditing) {
      <td>
        @if (shouldShowEditButton(record)) {
          @if (!isEdited(record)) {
            <button
              type="button"
              class="btn btn-warning btn-sm gk-map-icon-edit"
              [title]="'GK.MAP.EDIT' | translate"
              [attr.aria-label]="'GK.MAP.EDIT' | translate"
              (click)="startEditing(record)"
            ></button>
          } @else {
            <button
              type="button"
              class="btn btn-sm btn-color-from-db"
              (click)="dismissEditing()"
            >
              {{ 'GK.MAP.DISMISS' | translate }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-success"
              (click)="submitEditing()"
            >
              {{ 'GK.MAP.SUBMIT' | translate }}
            </button>
            @if (getMapObjectTableState().isVisibleTopologyValidationLoader) {
              <div class="d-flex justify-content-center my-3">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">{{
                    'LOADING' | translate
                  }}</span>
                </div>
              </div>
            }
            <br />
            @if (getMapObjectTableState().isVisibleTopologyValidationError) {
              <small class="text-danger">{{
                'GK.MAP.TOPOLOGY_ERROR' | translate
              }}</small>
            }
          }
        }
      </td>
    }
  </ng-template>
}
