import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiNewDokumentPowiazanyDalDto,
  AttachmentsComponent,
  ColumnHeader,
  DocSignService,
  EgibObject,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapAction,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  Place,
  PortalId,
  PzService,
  StreetService,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  filter,
  from,
  mergeMap,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import { RENewRequestControlName } from '../services/new-request-form/new-request-form.model';
import { RENewRequestFormService } from '../services/new-request-form/new-request-form.service';
import { RENewRequest } from '../services/new-request/new-request.model';
import { RoadExitRequestType } from '../services/road-exit/road-exit.model';

@Component({
  selector: 'app-build-permission-request',
  templateUrl: './build-permission-request.component.html',
  styleUrls: ['./build-permission-request.component.scss'],
  standalone: false,
})
export class BuildPermissionRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  override controlName = RENewRequestControlName;
  override portalId = PortalId.RoadExit;
  roadExitRequestType = RoadExitRequestType;
  placesOfSelectedParcels: Place[];
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('areaInSquareMeters', 'GK.MAP.AREA_IN_SQUARE_METERS'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
  );
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  successSigned = false;

  constructor(
    private reNewRequestFormService: RENewRequestFormService,
    public override formAlertService: FormAlertService,
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override placeService: PlaceService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    private modalService: NgbModal,
    public override pzService: PzService,
    public override streetService: StreetService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.createForm();
    this.fetchApplicantMapSettings();
  }

  createForm(): void {
    this.formGroup = this.reNewRequestFormService.getFormGroup();
    this.disableOrEnablePurposeOfPropertyUseChangedFormControlOnWillUseOfPropertyChangeCheckboxChange();
  }

  disableOrEnablePurposeOfPropertyUseChangedFormControlOnWillUseOfPropertyChangeCheckboxChange(): void {
    const willUseOfPropertyChangeFormControl =
      this.getWillUseOfPropertyChangeFormControl();
    if (!willUseOfPropertyChangeFormControl) {
      return;
    }
    const purposeOfPropertyUseChangedFormControl =
      this.getPurposeOfPropertyUseChangedFormControl();
    willUseOfPropertyChangeFormControl.valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => {
        if (!value) {
          purposeOfPropertyUseChangedFormControl.disable();
        } else {
          purposeOfPropertyUseChangedFormControl.enable();
        }
      });
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.RoadExitPortalBuildPermissionRequest,
      this.mapStateService.getViewState(
        MapId.RoadExitPortalBuildPermissionRequest,
        mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel, ToolType.AnyPolygon],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.RoadExitPortalBuildPermissionRequest,
        mapSettings,
        MapPortalName.Designer,
      ),
      [this.mapObjectTableState],
    );
  }

  override handleMapAction(mapAction: MapAction): void {
    this.mapState = this.mapStateService.getUpdatedMapState(
      mapAction,
      this.mapState,
    );
    if (
      mapAction.type === MapObjectTableActionType.Remove ||
      (mapAction.type === MapObjectTableActionType.AddToExisting &&
        mapAction.payload &&
        mapAction.payload[0] &&
        mapAction.payload[0].type === MapObjectApiType.LandParcel)
    ) {
      this.setPlacesOfSelectedParcelsWhenIsPossible();
    }
  }

  setPlacesOfSelectedParcelsWhenIsPossible(): void {
    const mapObjects = this.getParcelsMapObjects();
    if (mapObjects && mapObjects.length) {
      const placeFormControl = this.getPlaceFormControl();
      this.placeService
        .getPlacesOfDistrict(mapObjects[0].districtId)
        .pipe(
          takeWhile(() => this.isAlive),
          filter(() => {
            if (this.areAllDistrictsIdsInMapObjectsEqual()) {
              return true;
            } else {
              this.placesOfSelectedParcels = [];
              placeFormControl.setValue(null);

              return false;
            }
          }),
        )
        .subscribe((data) => {
          this.placesOfSelectedParcels = data;
          if (data.length === 1 && !placeFormControl.value) {
            placeFormControl.setValue(data[0]);
          }
        });
    }
  }

  areAllDistrictsIdsInMapObjectsEqual(): boolean {
    return this.getParcelsMapObjects().every(
      (mapObject, _i, arr) => mapObject.districtId === arr[0].districtId,
    );
  }

  isRequestTypeFormControlValid(): boolean {
    return this.getRequestTypeFormControl().valid;
  }

  getRequestTypeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.RequestType,
    ) as UntypedFormControl;
  }

  isLocationFormGroupValid(): boolean {
    return (
      this.isRoadNumberFormControlValid() &&
      this.isPlaceFormControlValid() &&
      this.areParcelsChosen() &&
      this.areIssueRangesChosen()
    );
  }

  isRoadNumberFormControlValid(): boolean {
    return this.getRoadNumberFormControl().valid;
  }

  getRoadNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.RoadNumber,
    ) as UntypedFormControl;
  }

  isPlaceFormControlValid(): boolean {
    return this.getPlaceFormControl().valid;
  }

  isPurposeOfPropertyUseFormControlValid(): boolean {
    return this.getPurposeOfPropertyUseFormControl().valid;
  }

  getPurposeOfPropertyUseFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.PurposeOfPropertyUse,
    ) as UntypedFormControl;
  }

  getPurposeOfPropertyUseChangedFormControl(): UntypedFormControl {
    return this.getPurposeOfPropertyUseChangedFormGroup().get(
      this.controlName.ChangedPurposeOfPropertyUse,
    ) as UntypedFormControl;
  }

  getWillUseOfPropertyChangeFormControlValue(): boolean {
    return this.getWillUseOfPropertyChangeFormControl().value;
  }

  getWillUseOfPropertyChangeFormControl(): UntypedFormControl {
    return this.getPurposeOfPropertyUseChangedFormGroup().get(
      this.controlName.WillUseOfPropertyChange,
    ) as UntypedFormControl;
  }

  isPurposeOfPropertyUseChangedFormGroupValid(): boolean {
    return this.getPurposeOfPropertyUseChangedFormGroup().valid;
  }

  getPurposeOfPropertyUseChangedFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      this.controlName.PurposeOfPropertyUseChanged,
    ) as UntypedFormGroup;
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areParcelsChosen() &&
      this.areIssueRangesChosen()
    );
  }

  areIssueRangesChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
    );
  }

  areParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
    );
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.requestReplyFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            RENewRequest.fromAppToApi(
              this.getFormValue(),
              this.getParcelsMapObjects(),
              this.getIssueRanges(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/budPrzebudZjazd/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  getFormValue(): RENewRequest {
    return this.formGroup.getRawValue();
  }

  getParcelsMapObjects(): EgibObject[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || []).filter(
      <(mapObject: MapObject) => mapObject is EgibObject>(
        ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
      ),
    );
  }

  getIssueRanges(): string[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || [])
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .map((issueRange) => issueRange.geom);
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl('/road-exit-portal/requests-list');
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty ||
      this.attachmentsComponent.areChosenDocuments() ||
      this.areIssueRangesChosen() ||
      this.areParcelsChosen()
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
