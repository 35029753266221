import { BaseNewRequestControlName } from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum FCNewRequestAdditionalControlName {
  BirthPlace = 'birthPlace',
  BirthDate = 'birthDate',
}

export const FCNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...FCNewRequestAdditionalControlName,
};
