import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../../components/electronic-correspondence-statement/models/form/form.model';
import { AuthorizedPersonControlName } from '../../../../../services/authorized-person-form/authorized-person-form.model';
import { AuthorizedPersonFormService } from '../../../../../services/authorized-person-form/authorized-person-form.service';
import { BaseNewRequestControlName } from '../../../../../shared/base-new-request-form/base-new-request-form.model';
import { BaseNewRequestFormService } from '../../../../../shared/base-new-request-form/base-new-request-form.service';
import { CLNewRequestControlName } from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class CLNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
    private authorizedPersonFormService: AuthorizedPersonFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonInvestorFormGroup({
        [ECSControlName.ElectronicCorrespondenceStatement]: false,
        [BaseNewRequestControlName.ContactData]: false,
        [BaseNewRequestControlName.DocumentationForm]: false,
        [BaseNewRequestControlName.ShippingForm]: false,
      }).controls,
      [CLNewRequestControlName.DecisionNumber]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [CLNewRequestControlName.DecisionDate]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [CLNewRequestControlName.DecisionPublishedBy]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [CLNewRequestControlName.ConstructionLogType]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [AuthorizedPersonControlName.AuthorizedDetailsForm]:
        this.authorizedPersonFormService.getFormGroup(),
    });
  }
}
