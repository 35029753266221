import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MapControl } from '../../../../controls';
import { OverlayPositioning, ProjectionCode } from '../../../../models';
import { Coordinate } from 'ol/coordinate';

@Component({
  selector: 'gk-map-tooltip',
  templateUrl: './map-tooltip.component.html',
  styleUrls: ['./map-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapTooltipComponent extends MapControl {
  @Input()
  movingPointerCoordinate: Coordinate;
  overlayOffset = [10, 10];
  overlayPositioning = OverlayPositioning.BottomLeft;
  defaultProjectionCode = ProjectionCode.PseudoMercator;
}
