import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlWidth } from './control.model';

@Directive({
  selector: '[gkControlValue]',
  standalone: false,
})
export class ControlValueDirective implements OnInit {
  @Input() showLabel = true;
  @Input() classList: string | undefined;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const width = this.showLabel ? ControlWidth.Big : ControlWidth.Full;
    this.renderer.addClass(this.el.nativeElement, `w-sm-${width}`);
    this.renderer.addClass(this.el.nativeElement, 'w-100');
    this.renderer.addClass(this.el.nativeElement, 'text-left');
    this.renderer.addClass(this.el.nativeElement, 'ps-2');
    this.renderer.addClass(this.el.nativeElement, 'align-self-center');
    if (this.classList) {
      this.classList
        .split(' ')
        .filter((cls) => cls.trim() !== '')
        .forEach((cls) => this.renderer.addClass(this.el.nativeElement, cls));
    }
  }
}
