import {
  ApiFilter,
  ApiFullFilter,
  DictionaryField,
  FilterOperatorType,
} from '@gk/gk-modules';
import {
  DateHelperUtil,
  NgbDateRange,
} from '../../utils/date-helper/date-helper.util';
import {
  CountyDictionary,
  VoivodeshipDictionary,
} from '../administrative-division/administrative-division.model';

export enum EcoDocumentSearchApiFields {
  SerialNumber = 'NrKol',
  DocumentName = 'Nazwa',
  CardIssueYear = 'Rok',
  DocumentCreator = 'Utworzyl',
  DocumentApprover = 'Zatwierdzil',
  CaseMark = 'ZnakSprawy',
  DocumentType = 'RodzajId',
  DocumentSubject = 'TematId',
  OfficeName = 'Urzad',
  Description = 'Opis',
  DocumentDate = 'DataDokumentu',
  ApprovalDate = 'DataZatwierdzenia',
  PublicationDate = 'DataPublikacji',
  VoivodeshipId = 'WojewodztwoId',
  CountyId = 'PowiatId',
  CommunityId = 'GminaId',
}

export class EcoDocumentSearch {
  constructor(
    public serialNumber: string,
    public documentName: string,
    public cardIssueYear: string,
    public documentCreator: string,
    public documentApprover: string,
    public caseMark: string,
    public documentType: string,
    public documentSubject: string,
    public officeName: string,
    public description: string,
    public documentDate: NgbDateRange,
    public approvalDate: NgbDateRange,
    public publicationDate: NgbDateRange,
    public voivodeship: VoivodeshipDictionary,
    public county: CountyDictionary,
    public community: DictionaryField,
  ) {}

  static fromAppToApi(data: EcoDocumentSearch): ApiFullFilter {
    return new ApiFullFilter(
      [
        new ApiFilter(
          EcoDocumentSearchApiFields.SerialNumber,
          data.serialNumber,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.DocumentName,
          data.documentName,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.CardIssueYear,
          data.cardIssueYear,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.DocumentCreator,
          data.documentCreator,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.DocumentApprover,
          data.documentApprover,
        ),
        new ApiFilter(EcoDocumentSearchApiFields.CaseMark, data.caseMark),
        new ApiFilter(
          EcoDocumentSearchApiFields.DocumentType,
          data.documentType,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.DocumentSubject,
          data.documentSubject,
        ),
        new ApiFilter(EcoDocumentSearchApiFields.OfficeName, data.officeName),
        new ApiFilter(EcoDocumentSearchApiFields.Description, data.description),
        new ApiFilter(
          EcoDocumentSearchApiFields.DocumentDate,
          data.documentDate &&
            DateHelperUtil.ngbDateToApiDateTime(data.documentDate.from),
          FilterOperatorType.GreaterThanOrEquals,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.DocumentDate,
          data.documentDate &&
            DateHelperUtil.ngbDateToApiDateTime(data.documentDate.to),
          FilterOperatorType.LessThanOrEquals,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.ApprovalDate,
          data.approvalDate &&
            DateHelperUtil.ngbDateToApiDateTime(data.approvalDate.from),
          FilterOperatorType.GreaterThanOrEquals,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.ApprovalDate,
          data.approvalDate &&
            DateHelperUtil.ngbDateToApiDateTime(data.approvalDate.to),
          FilterOperatorType.LessThanOrEquals,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.PublicationDate,
          data.publicationDate &&
            DateHelperUtil.ngbDateToApiDateTime(data.publicationDate.from),
          FilterOperatorType.GreaterThanOrEquals,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.PublicationDate,
          data.publicationDate &&
            DateHelperUtil.ngbDateToApiDateTime(data.publicationDate.to),
          FilterOperatorType.LessThanOrEquals,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.VoivodeshipId,
          data.voivodeship && data.voivodeship.id,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.CountyId,
          data.county && data.county.id,
        ),
        new ApiFilter(
          EcoDocumentSearchApiFields.CommunityId,
          data.community && data.community.id,
        ),
      ].filter((element) => element.value),
    );
  }
}
