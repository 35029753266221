import { Component, EventEmitter, Input } from '@angular/core';
import { UploadComponent } from '@progress/kendo-angular-upload';
import {
  GkKendoUploadEvent,
  GkKendoUploadFileInfo,
} from '../gk-kendo-upload.model';
import { ToastrService } from 'ngx-toastr';
import { GkKendoUploadService } from '../gk-kendo-upload.service';

@Component({
  selector: 'gk-upload-action-buttons-component',
  templateUrl: './gk-upload-action-buttons-component.component.html',
  styleUrls: ['./gk-upload-action-buttons-component.component.scss'],
  standalone: false,
})
export class GkUploadActionButtonsComponentComponent {
  @Input() uploadComponent: UploadComponent;

  constructor(
    private toastr: ToastrService,
    private gkKendoUploadService: GkKendoUploadService,
  ) {}

  onClearButtonClick(event: MouseEvent): void {
    event.stopImmediatePropagation();

    this.clearFiles();
  }

  public clearFiles(): void {
    if (!this.uploadComponent.disabled) {
      this.uploadComponent.clearFiles();
      // this.uploadComponent.navigation.focusSelectButton();
    }
  }

  public async onUploadButtonClick(event: MouseEvent): Promise<void> {
    event.stopImmediatePropagation();

    const files = this.uploadComponent.fileList
      .filesFlat as Array<GkKendoUploadFileInfo>;

    if (files.some((file) => !file?.typeOfDoc?.id)) {
      this.toastr.error('Proszę wybrać rodzaj pliku');

      return;
    }

    if (typeof this.gkKendoUploadService.onUploadButtonClick === 'function') {
      await this.gkKendoUploadService.onUploadButtonClick(
        this.uploadComponent
          .upload as unknown as EventEmitter<GkKendoUploadEvent>,
      );
    }

    this.performUpload();
  }

  public performUpload(): void {
    if (!this.uploadComponent.disabled) {
      this.uploadComponent.uploadFiles();
      // this.uploadComponent.navigation.focusSelectButton();
    }
  }
}
