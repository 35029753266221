import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { switchMap, takeWhile } from 'rxjs/operators';
import { TfaService } from '../../../gk-user-settings/services/tfa/tfa.service';
import { LoginService } from '../services/login/login.service';
import { MessageStatus } from '../services/message-status/message-status.model';
import {
  CustomFormError,
  NewPasswordControlName,
} from '../services/new-password-form/new-password-form.model';
import { SessionToolsService } from '../services/session-tools/session-tools.service';
import { TfaConfirmationService } from '../services/tfa-confirmation/tfa-confirmation.service';
import { TfaWebAuthnVerificationService } from '../services/tfa-web-authn-verification/tfa-web-authn-verification.service';
import { SessionToolsBaseComponent } from '../session-tools-base.component';
import { NewPasswordFormService } from './../services/new-password-form/new-password-form.service';
import { NewPasswordService } from './../services/new-password/new-password.service';

@Component({
  selector: 'gk-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: [
    '../session-tools.component.scss',
    './new-password.component.scss',
  ],
  standalone: false,
})
export class NewPasswordComponent
  extends SessionToolsBaseComponent
  implements OnInit
{
  isAlive = true;
  formGroup: UntypedFormGroup;
  controlNameEnum = NewPasswordControlName;
  override actionRequestInProgress: boolean;
  passwordControlVisibility: { [key in NewPasswordControlName]?: boolean } = {};
  successDefaultMessage: string;
  autoLoginMessage: string;
  successLoginMessage: string;

  constructor(
    private newPasswordFormService: NewPasswordFormService,
    public override loginService: LoginService,
    public override translateService: TranslateService,
    public override router: Router,
    public override newPasswordService: NewPasswordService,
    public override tfaService: TfaService,
    public override tfaConfirmationService: TfaConfirmationService,
    public override sessionToolsService?: SessionToolsService,
    public override tfaWebAuthnVerificationService?: TfaWebAuthnVerificationService,
  ) {
    super(
      translateService,
      router,
      newPasswordService,
      tfaService,
      tfaConfirmationService,
      undefined,
      sessionToolsService,
      tfaWebAuthnVerificationService,
      loginService,
    );
  }
  override ngOnInit() {
    this.createForm();
    this.fetchDefaultSuccessMessage();
    this.fetchAutoLoginMessage();
    this.fetchSuccessLoginMessage();
    super.ngOnInit();
  }

  createForm(): void {
    this.formGroup = this.newPasswordFormService.getFormGroup();
  }

  fetchDefaultSuccessMessage(): void {
    this.translateService
      .get('SESSION_TOOLS.NEW_PASSWORD.SUCCESS')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((message) => {
        this.successDefaultMessage = message;
      });
  }

  fetchAutoLoginMessage(): void {
    this.translateService
      .get('SESSION_TOOLS.NEW_PASSWORD.AUTO_LOGIN')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((message) => {
        this.autoLoginMessage = message;
      });
  }

  fetchSuccessLoginMessage(): void {
    this.translateService
      .get('SESSION_TOOLS.SUCCESS')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((message) => {
        this.successLoginMessage = message;
      });
  }

  newPasswordSubmitHandle(): void {
    this.actionRequestInProgress = true;
    this.submitted = true;
    if (!this.formGroup.valid) {
      this.actionRequestInProgress = false;
      return;
    }
    this.newPasswordService
      .newPassword(this.formGroup.getRawValue())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: () => {
          this.actionRequestInProgress = false;
          this.handleSuccessNewPasswordResponse();
        },
        error: (data) => {
          this.setMessageStatus(MessageStatus.Error);
          try {
            const error = JSON.parse(data.error);
            this.messageText = _.get(
              error,
              'ResponseStatus.Message',
              this.errorDefaultMessage,
            );
          } catch {
            this.messageText = this.errorDefaultMessage;
          }
          this.actionRequestInProgress = false;
        },
      });
  }

  handleSuccessNewPasswordResponse(): void {
    this.setMessageStatus(MessageStatus.Success);
    this.messageText = this.successDefaultMessage;
    this.loginAfterPasswordChange();
  }

  loginAfterPasswordChange(): void {
    this.setMessageStatus(MessageStatus.Pending);
    this.messageText = this.autoLoginMessage;
    this.actionRequestInProgress = true;
    const formRawValue = this.formGroup.getRawValue();
    this.newPasswordService.currentUserLogin
      .pipe(
        switchMap((login) =>
          this.loginService.login({
            login,
            password: formRawValue.newPassword,
          }),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (data) => {
          this.actionRequestInProgress = false;
          this.handleSuccessLoginResponse(data, this.successLoginMessage);
        },
        error: () => {
          this.setMessageStatus(MessageStatus.Error);
          this.messageText = this.errorDefaultMessage;
          this.actionRequestInProgress = false;
        },
      });
  }

  override setMessageStatus(status: MessageStatus): void {
    this.messageStatus = status;
  }

  togglePasswordVisibility(
    visible: boolean,
    controlName: NewPasswordControlName,
  ): void {
    this.passwordControlVisibility[controlName] = visible;
  }

  shouldShowRequiredFieldsInfo(): boolean {
    return (
      this.submitted &&
      [
        this.getCurrentPasswordFormControl(),
        this.getNewPasswordFormControl(),
        this.getPasswordConfirmationFormControl(),
      ].some(
        (formControl) => formControl.errors && formControl.errors['required'],
      )
    );
  }

  getCurrentPasswordFormControl(): AbstractControl {
    return this.formGroup.get(NewPasswordControlName.CurrentPassword);
  }

  shouldShowNewPasswordFormControlPatternError(): boolean {
    if (!this.submitted) {
      return false;
    }
    const formControl = this.getNewPasswordFormControl();
    return !!(formControl.errors && formControl.errors['pattern']);
  }

  getNewPasswordFormControl(): AbstractControl {
    return this.formGroup.get(NewPasswordControlName.NewPassword);
  }

  getPasswordConfirmationFormControl(): AbstractControl {
    return this.formGroup.get(NewPasswordControlName.PasswordConfirmation);
  }

  shouldShowNotSamePasswordsFormGroupError(): boolean {
    if (!this.submitted) {
      return false;
    }

    return (
      this.formGroup.errors &&
      this.formGroup.errors[CustomFormError.NotSamePasswords]
    );
  }
}
