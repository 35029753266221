import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  CoordinateSystem,
  CoordinateSystemEndpoint,
  CoordinateSystemFromApi,
} from './coordinate-system.model';

@Injectable()
export class CoordinateSystemService {
  horizontalCoordinateSystems = new BehaviorSubject<CoordinateSystem[]>([]);
  verticalCoordinateSystems = new BehaviorSubject<CoordinateSystem[]>([]);

  constructor(private http: HttpClient) {
    this.fetchCoordinateSystems();
  }

  fetchCoordinateSystems(): void {
    this.getCoordinateSystems(CoordinateSystemEndpoint.Horizontal).subscribe(
      (horizontalCoordinateSystems) =>
        this.horizontalCoordinateSystems.next(horizontalCoordinateSystems),
    );
    this.getCoordinateSystems(CoordinateSystemEndpoint.Vertical).subscribe(
      (verticalCoordinateSystems) =>
        this.verticalCoordinateSystems.next(verticalCoordinateSystems),
    );
  }

  getCoordinateSystems(
    endpoint: CoordinateSystemEndpoint,
  ): Observable<CoordinateSystem[]> {
    return this.http.get<CoordinateSystemFromApi[]>(endpoint).pipe(
      map((coordinateSystems) =>
        coordinateSystems.map((coordinateSystem) =>
          CoordinateSystem.fromApiToApp(coordinateSystem),
        ),
      ),
      catchError((): [] => []),
    );
  }
}
