<div class="d-flex flex-column py-2">
  @if (messageText) {
    <span [ngClass]="'text-' + messageType">
      {{ messageText }}
    </span>
  }

  @if (docSignUrl) {
    <span class="my-2 text-info">
      {{ 'DOC_SIGN.SIGN_INFO' | translate }}
    </span>
  }

  @if (docSignUrl) {
    <a class="link" [href]="docSignUrl" target="_blank">
      {{ 'DOC_SIGN.SIGN' | translate }}
    </a>
  }

  @if (docSignPending) {
    <div class="d-flex justify-content-center my-3">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
      </div>
    </div>
  }
</div>
