import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiNewDokumentPowiazanyDalDto,
  AttachmentsComponent,
  ColumnHeader,
  DocSignService,
  EgibObject,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapAction,
  MapExtentUtils,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  MapViewActionType,
  PortalId,
  PzService,
  ShapeUtils,
  StreetService,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  catchError,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  takeWhile,
  tap,
  throwError,
} from 'rxjs';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import {
  BsMessageType,
  WorkspaceStateDraftVersion,
  WorkspaceStateId,
} from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import { BusinessPurposesCheckboxControlComponent } from '../business-purposes-checkbox-control/business-purposes-checkbox-control.component';
import { PropertyUsageFormGroup } from '../services/business-purposes-checkbox-control-form/business-purposes-checkbox-control-form.model';
import {
  OCFCRNewRequestControlName,
  OCFCRNewRequestFormGroup,
} from '../services/new-request-form/new-request-form.model';
import { OCFCRNewRequestFormService } from '../services/new-request-form/new-request-form.service';
import {
  OCFCRNewRequest,
  OCFCRNewRequestDto,
  OCFCRNewRequestFormWorkspaceState,
} from '../services/new-request/new-request.model';
import { MainRoutes } from './../../../guards/guard.models';

@Component({
  selector: 'app-lbrc-new-request',
  templateUrl: './new-request.component.html',
  standalone: false,
})
export class OCFCRNewRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  override controlName = OCFCRNewRequestControlName;
  override formGroup: FormGroup<OCFCRNewRequestFormGroup>;
  override portalId = PortalId.OwnershipConversionFeeCalculationRequest;
  readonly mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('areaInSquareMeters', 'GK.MAP.AREA_IN_SQUARE_METERS'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
  );
  mapSettings: MapSettings;
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  @ViewChild(BusinessPurposesCheckboxControlComponent)
  businessPurposesCheckboxControlComponent: BusinessPurposesCheckboxControlComponent;
  successSigned = false;
  workspaceStateDraftVersion =
    WorkspaceStateDraftVersion.OwnershipConversionFeeCalculationRequestFormVersion;
  workspaceStateId =
    WorkspaceStateId.OwnershipConversionFeeCalculationRequestForm;

  constructor(
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    private ocfcrNewRequestFormService: OCFCRNewRequestFormService,
    public override streetService: StreetService,
    private toastr: ToastrService,
    public override router: Router,
    private modalService: NgbModal,
    public override pzService: PzService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.createForm();
    this.fetchMapSettings();
  }

  createForm(): void {
    this.formGroup = this.ocfcrNewRequestFormService.getFormGroup();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Applicant)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.mapSettings = mapSettings;
        this.setMapState();
      });
  }

  override setMapState(): void {
    this.mapState = new MapState(
      MapId.OwnershipConversionFeeCalculation,
      this.mapStateService.getViewState(
        MapId.OwnershipConversionFeeCalculation,
        this.mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.AnyPolygon, ToolType.LandParcel],
        this.mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.OwnershipConversionFeeCalculation,
        this.mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty || this.attachmentsComponent.areChosenDocuments()
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areLandParcelsChosen() &&
      this.businessPurposesCheckboxControlComponent.isOtherInformationsGroupValid()
    );
  }

  handleSubmit(): void {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }

    this.sendRequest();
  }

  sendRequest(): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.requestReplyFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() => this.getRequestBody()),
        switchMap((body) =>
          this.docSignService.addToSign(
            body,
            '/api/interesant/wniosek/naliczenieOplatyPrzeksztalcenieUzytkowaniaWieczystegoWeWlasnosc/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (error) => this.handleSendAndValidateFailure(error),
      });
  }

  getRequestBody = (): Observable<OCFCRNewRequestDto> => {
    return from(
      Promise.all(this.attachmentsComponent.getConvertedFiles()).catch(
        (error) => new Error(error),
      ),
    ).pipe(
      switchMap((apiFiles) => {
        const apiFilesArray = Object.values(apiFiles).flatMap((file) => file);
        if (
          (Array.isArray(apiFilesArray) &&
            apiFilesArray.some((file) => file instanceof Error)) ||
          apiFiles instanceof Error
        ) {
          this.errorSubmitMessage = this.wrongFilesErrorText;
          this.toastr.error(this.wrongFilesErrorText);

          return throwError(() => new Error(this.wrongFilesErrorText));
        }

        return of(apiFiles);
      }),
      map((apiFiles) =>
        OCFCRNewRequest.fromAppToApi(
          this.getFormValue(),
          this.getIssueRanges(),
          this.getLandParcels(),
          apiFiles as ApiNewDokumentPowiazanyDalDto[],
        ),
      ),
      catchError((err) => {
        console.error(err);

        return throwError(() => err);
      }),
    );
  };

  getWorkspaceStateToSave = (): OCFCRNewRequestFormWorkspaceState => ({
    formValue: this.getFormValue(),
    parcels: this.getLandParcels(),
    issueRanges: this.getIssueRanges(),
  });

  handleLoadWorkspaceResponse(state: OCFCRNewRequestFormWorkspaceState): void {
    this.formGroup.patchValue(state.formValue);
    if (state.parcels) {
      this.handleMapAction(
        new MapAction(MapObjectTableActionType.AddNew, [...state.parcels]),
      );
    }
    if (state.issueRanges) {
      const mapObjects = state.issueRanges.map((workRange) =>
        ShapeUtils.getAnyGeometryMapObject(
          workRange,
          MapObjectApiType.ExtentOrPolygon,
        ),
      );
      this.handleMapAction(
        new MapAction(MapObjectTableActionType.AddToExisting, mapObjects),
      );
      this.handleMapAction(
        new MapAction(
          MapViewActionType.ExtentToFitToChange,
          MapExtentUtils.getMapExtentFromMapObjects(mapObjects),
        ),
      );
      this.handleMapAction(
        new MapAction(MapObjectTableActionType.SelectRange, mapObjects),
      );
    }
  }

  getLandParcels(): EgibObject[] {
    return [...this.mapState.mapObjectTablesState[0].mapObjects].filter(
      <(mapObject: MapObject) => mapObject is EgibObject>(
        ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
      ),
    );
  }

  getIssueRanges(): string[] {
    return [...this.mapState.mapObjectTablesState[0].mapObjects]
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .map((issueRange) => issueRange.geom);
  }

  getFormValue(): OCFCRNewRequest {
    return this.formGroup.getRawValue();
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.OwnershipConversionFeeCalculationRequest}/requests-list`,
    );
  }

  areLandParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
    );
  }

  getPropertyUsageFormGroup(): FormGroup<PropertyUsageFormGroup> {
    return this.formGroup.get(
      OCFCRNewRequestControlName.PropertyUsage,
    ) as FormGroup<PropertyUsageFormGroup>;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
