import { BaseNewRequestControlName } from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum CPPNewRequestAdditionalControlName {
  Comments = 'comments',
  PremisesAddress = 'premisesAddress',
}

export const CPPNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...CPPNewRequestAdditionalControlName,
};
