import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ControlNetwork,
  ControlNetworkEndpoint,
  ControlNetworkFromApi,
} from './control-network.model';

@Injectable()
export class ControlNetworkService {
  horizontalNetworks = new BehaviorSubject<ControlNetwork[]>([]);
  verticalNetworks = new BehaviorSubject<ControlNetwork[]>([]);

  constructor(private http: HttpClient) {
    this.fetchControlNetworks();
  }

  fetchControlNetworks(): void {
    this.getControlNetworks(ControlNetworkEndpoint.Horizontal).subscribe(
      (horizontalNetworks) => this.horizontalNetworks.next(horizontalNetworks),
    );
    this.getControlNetworks(ControlNetworkEndpoint.Vertical).subscribe(
      (verticalNetworks) => this.verticalNetworks.next(verticalNetworks),
    );
  }

  getControlNetworks(
    endpoint: ControlNetworkEndpoint,
  ): Observable<ControlNetwork[]> {
    return this.http.get<ControlNetworkFromApi[]>(endpoint).pipe(
      map((controlNetworks) =>
        controlNetworks.map((controlNetwork) =>
          ControlNetwork.fromApiToApp(controlNetwork),
        ),
      ),
      catchError((): [] => []),
    );
  }
}
