import { Component } from '@angular/core';

import { Control } from '../control';

@Component({
  selector: 'gk-control-number',
  templateUrl: './control-number.component.html',
  standalone: false,
})
export class ControlNumberComponent extends Control<number> {}
