import { BaseNewRequestControlName } from '../../../../../shared/base-new-request-form/base-new-request-form.model';

export enum TASFNewRequestAdditionalControlName {
  BusinessPurpose = 'businessPurpose',
  FellingDate = 'fellingDate',
  InvestmentDescription = 'investmentDescription',
  GrowthPlace = 'growthPlace',
  FellingCause = 'fellingCause',
  TreesList = 'treesList',
  ShrubsList = 'shrubsList',
  Place = 'fellingPlace',
  Street = 'fellingStreet',
  LandRegister = 'landRegister',
  LandNumber = 'fellingLandNumber',
  StreetAbsence = 'streetAbsence',
}

export const TASFNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...TASFNewRequestAdditionalControlName,
};

export enum FellingObjectsControlName {
  InventoryNumber = 'inventoryNumber',
  SpeciesName = 'speciesName',
  TrunkCircumference = 'trunkCircumference',
  ProjectionSurface = 'projectionSurface',
}

export enum FellingPurpose {
  NotBusiness = 0,
  Business = 1,
}
