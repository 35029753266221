import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, switchMap, takeWhile, tap } from 'rxjs';
import { FormAlertService } from '../../services/form-alert/form-alert.service';
import {
  markFormGroupsPristine,
  markFormGroupsTouched,
  markFormGroupsUntouched,
  setDisabledStatusOfControls,
} from '../../utils/reactive-form/reactive-form.util';
import { nipValidator } from '../../validators/nip-validator/nip-validator';
import { ValidatePostalCode } from '../../validators/postal-code-validator/postal-code-validator';
import { regonValidator } from '../../validators/regon-validator/regon-validator';
import { EnablementType } from '../services/enablement/enablement.model';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  LegalPersonControlName,
  MaxLengthAddressField,
} from '../services/law-person-form/law-person-form.model';
import { LawPersonFormService } from '../services/law-person-form/law-person-form.service';
import {
  LawPersonSearch,
  LegalPersonSearch,
  NaturalPersonSearch,
} from '../services/law-person/law-person-search.model';
import {
  LawPersonType,
  LegalPersonData,
  maxLengthValueOfNaturalPersonFormGroup,
} from '../services/law-person/law-person.model';
import { LawPersonService } from '../services/law-person/law-person.service';
import { PersonType } from '../services/person-type/person-type.model';
import { PersonTypeService } from '../services/person-type/person-type.service';

@Component({
  selector: 'gk-law-person-search',
  templateUrl: './law-person-search.component.html',
  standalone: false,
})
export class LawPersonSearchComponent implements OnInit, OnChanges, OnDestroy {
  private isAlive = true;
  @Input() submitted: boolean;
  @Input() isRequired: boolean;
  @Input() isDesignerAddressRequired = true;
  @Input() multiplePersonSelect: boolean;
  @Input() uniquePersonsOnList = true;
  @Input() selectedLawPersons: LawPersonSearch[] = [];
  @Input() chosenPersonType: PersonType;
  @Input() addingPersonsDirectlyToDb = true;
  @Input() staticSelectedTableValue: LawPersonSearch[] = [];
  @Input() legalPersonOnly: boolean;
  @Input() forcePeselRequired: boolean;
  @Input() buttonsDisabled: boolean;
  @Input() defaultAvailablePersonTypes: PersonType[];
  @Input() primitivePostalCode: boolean;
  @Input() canAddNewPersonEvenIfDefinedInDb: boolean;
  @Input() searchLegalPersonCustomUrl: string;
  @Input() legalPersonSearchFieldsToHide: LegalPersonControlName[];
  @Input() addLawPersonUrl: string;
  @Input() legalPersonAddModeEnabled: boolean;
  @Output() selectedLawPersonsChange = new EventEmitter<LawPersonSearch[]>();
  @ViewChild('noSearchResultModal') noSearchResultModal: NgbModalRef;
  @ViewChild('postOfficeModal') postOfficeModal: NgbModalRef;
  lawPersonForm: UntypedFormGroup;
  enablementTypeEnum = EnablementType;
  newPersonMode: boolean;
  matchingLawPersons: LawPersonSearch[] = [];
  personLoading: boolean;
  personSearchOrAddMsg: string;
  lawPersonSearchTranslations: { [key: string]: string } = {};
  lawPersonAddressControlName = LawPersonAddressControlName;
  legalPersonControlName = LegalPersonControlName;

  constructor(
    private lawPersonFormService: LawPersonFormService,
    private lawPersonService: LawPersonService,
    private changeDetectorRef: ChangeDetectorRef,
    private ngbModal: NgbModal,
    private translateService: TranslateService,
    private personTypeService: PersonTypeService,
    private toastr: ToastrService,
    public formAlertService: FormAlertService,
  ) {}

  private getLawPersonsIdsComparisonFunctionWithDeletedIdsCache(): (
    lawPerson: LawPersonSearch,
    otherLawPerson: LawPersonSearch,
  ) => boolean {
    let deletedIds: { [key: string]: boolean } = {};

    return (
      lawPerson: LawPersonSearch,
      otherLawPerson: LawPersonSearch,
    ): boolean => {
      const shouldBeDeleted =
        this.lawPersonsIdsComparison(lawPerson, otherLawPerson) &&
        !deletedIds[lawPerson.id];

      if (shouldBeDeleted) {
        deletedIds = { ...deletedIds, [lawPerson.id]: true };
      }

      return shouldBeDeleted;
    };
  }

  private lawPersonsIdsComparison = (
    lawPerson: LawPersonSearch,
    otherLawPerson: LawPersonSearch,
  ): boolean =>
    lawPerson.id === otherLawPerson.id &&
    _.get(lawPerson, 'personType.id') ===
      _.get(otherLawPerson, 'personType.id') &&
    !lawPerson.mainPersonOfDesginerPortal;

  ngOnInit(): void {
    this.createForm();
    this.subscribeToLawPersonSearchTranslations();
    this.resetFormOnTypeChange();
    this.adjustVariantOfLegalPersonSearch();
  }

  isDesignerPersonTypeChosen(): boolean {
    return (
      this.chosenPersonType &&
      this.personTypeService.isDesignerPersonType(this.chosenPersonType)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.lawPersonForm) {
      return;
    }
    this.lawPersonFormService.adjustFormByPersonType(
      this.getNaturalPersonFormGroup(),
      this.isDesignerPersonTypeChosen(),
      !this.addingPersonsDirectlyToDb && !this.forcePeselRequired,
    );
    if (this.isDesignerPersonTypeChosen()) {
      this.resetMatchingLawPersons();
      this.maybeChangeToNaturalPerson(changes);
    }
  }

  maybeChangeToNaturalPerson(changes: SimpleChanges): void {
    const chosenPersonTypeChanges = _.get(changes, 'chosenPersonType');

    if (
      chosenPersonTypeChanges &&
      chosenPersonTypeChanges.currentValue !==
        chosenPersonTypeChanges.previousValue
    ) {
      this.getTypeFormControl().setValue(LawPersonType.Natural);
    }
  }

  createForm(): void {
    const isDesignerPersonTypeChosen = this.isDesignerPersonTypeChosen();
    this.lawPersonForm = this.lawPersonFormService.getFormGroup(
      this.newPersonMode,
      isDesignerPersonTypeChosen,
      !this.addingPersonsDirectlyToDb && !this.forcePeselRequired,
      this.primitivePostalCode,
      !isDesignerPersonTypeChosen || this.isDesignerAddressRequired,
    );
  }

  subscribeToLawPersonSearchTranslations(): void {
    this.translateService
      .get('LAW_PERSON_SEARCH')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translations) => (this.lawPersonSearchTranslations = translations),
      );
  }

  resetFormOnTypeChange(): void {
    this.getTypeFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.disableNewPersonModeWhenIsEnabled();
        this.adjustAdressValidation();
      });
  }

  adjustAdressValidation(): void {
    const requiredValidator =
      this.isLegalPersonChosen() ||
      !this.isDesignerPersonTypeChosen() ||
      this.isDesignerAddressRequired
        ? Validators.required
        : null;
    const placeFormControl = this.getPlaceFormControl();
    const streetFormControl = this.getStreetFormControl();
    const buildingNumberFormControl = this.getBuildingNumberFormControl();
    const postalCodeFormControl = this.getPostalCodeFormControl();
    placeFormControl.clearValidators();
    placeFormControl.setValidators(
      [
        requiredValidator,
        Validators.maxLength(MaxLengthAddressField.Place),
      ].filter(Boolean),
    );
    streetFormControl.clearValidators();
    streetFormControl.setValidators(
      [
        requiredValidator,
        Validators.maxLength(MaxLengthAddressField.Street),
      ].filter(Boolean),
    );
    buildingNumberFormControl.clearValidators();
    buildingNumberFormControl.setValidators(
      [
        requiredValidator,
        Validators.maxLength(MaxLengthAddressField.BuildingNumber),
      ].filter(Boolean),
    );
    postalCodeFormControl.clearValidators();
    postalCodeFormControl.setValidators(
      [
        requiredValidator,
        this.primitivePostalCode ? ValidatePostalCode : null,
        Validators.maxLength(MaxLengthAddressField.PostalCode),
      ].filter(Boolean),
    );
  }

  getNaturalPersonFormGroup(): UntypedFormGroup {
    return this.lawPersonForm.get(
      LawPersonControlName.NaturalPersonData,
    ) as UntypedFormGroup;
  }

  adjustVariantOfLegalPersonSearch(): void {
    const regonFormControl = this.getLegalPersonRegonFormControl();
    const nipFormControl = this.getLegalPersonNipFormControl();
    const krsNumberFormControl = this.getLegalPersonKrsNumberFormControl();

    this.getLegalPersonFormGroup()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((formGroupValue: LegalPersonData) => {
        setDisabledStatusOfControls(
          [regonFormControl, nipFormControl, krsNumberFormControl],
          false,
        );
        if (formGroupValue.nip || formGroupValue.regon) {
          setDisabledStatusOfControls([krsNumberFormControl], true);
        } else if (formGroupValue.krsNumber) {
          setDisabledStatusOfControls([regonFormControl, nipFormControl], true);
        }
      });
  }

  getLegalPersonFormGroup(): UntypedFormGroup {
    return this.lawPersonForm.get(
      LawPersonControlName.LegalPersonData,
    ) as UntypedFormGroup;
  }

  getAddressFormGroup(): UntypedFormGroup {
    return this.lawPersonForm.get(
      LawPersonControlName.Address,
    ) as UntypedFormGroup;
  }

  getTypeFormControl(): UntypedFormControl {
    return this.lawPersonForm?.get(
      LawPersonControlName.Type,
    ) as UntypedFormControl;
  }

  isLegalPersonChosen(): boolean {
    return this.getTypeFormControl()?.value === LawPersonType.Legal;
  }

  isNaturalPersonChosen(): boolean {
    return this.getTypeFormControl().value === LawPersonType.Natural;
  }

  searchLawPerson(): void {
    this.clearPersonSearchOrAddMsg();
    this.disableNewPersonModeWhenIsEnabled();
    if (this.isNaturalPersonChosen()) {
      this.searchNaturalPerson();
    } else {
      this.searchLegalPerson();
    }
  }

  searchNaturalPerson(): void {
    const naturalPersonFormGroup = this.getNaturalPersonFormGroup();
    markFormGroupsTouched(naturalPersonFormGroup);
    if (!this.isSumLengthNaturalPersonFirstNameAndLastNameValid()) {
      this.toastr.error(
        `${this.lawPersonSearchTranslations['MAX_LENGTH_NAME']} ${
          maxLengthValueOfNaturalPersonFormGroup - 1
        } ${this.lawPersonSearchTranslations['SIGNS']}`,
      );

      return;
    }
    if (naturalPersonFormGroup.valid) {
      this.personLoading = true;
      this.lawPersonService
        .searchNaturalPerson(
          naturalPersonFormGroup.value,
          this.isDesignerPersonTypeChosen(),
        )
        .pipe(takeWhile(() => this.isAlive))
        .subscribe({
          next: (data) => {
            this.searchLawPersonCallback(data);
          },
          error: () => this.handlePersonSearchFailure(),
        });
    }
  }

  searchLegalPerson(): void {
    const legalPersonFormGroup = this.getLegalPersonFormGroup();
    markFormGroupsTouched(legalPersonFormGroup);
    if (legalPersonFormGroup.valid) {
      this.personLoading = true;
      this.lawPersonService
        .searchLegalPerson(
          legalPersonFormGroup.value,
          this.searchLegalPersonCustomUrl,
        )
        .pipe(takeWhile(() => this.isAlive))
        .subscribe({
          next: (data) => {
            this.searchLawPersonCallback(data);
          },
          error: () => this.handlePersonSearchFailure(),
        });
    }
  }

  handlePersonSearchFailure(): void {
    this.personLoading = false;
  }

  searchLawPersonCallback(data: LawPersonSearch[]): void {
    this.personLoading = false;
    this.resetMatchingLawPersons();
    if (data.length) {
      this.matchingLawPersons = data;
      this.changeDetectorRef.detectChanges();
    } else {
      this.noSearchResultCallback();
    }
  }

  noSearchResultCallback(): void {
    if (this.legalPersonAddModeEnabled || this.isNaturalPersonChosen()) {
      this.enableNewPersonMode();
    }
    this.ngbModal.open(this.noSearchResultModal);
  }

  disableNewPersonModeWhenIsEnabled(): void {
    if (this.newPersonMode) {
      this.disableNewPersonMode();
      this.changeDetectorRef.detectChanges();
    }
  }

  updateLawPersonsTable(selectedLawPersons: LawPersonSearch[]): void {
    this.selectedLawPersons = this.multiplePersonSelect
      ? this.uniquePersonsOnList
        ? _.uniqWith(
            [...this.selectedLawPersons, ...selectedLawPersons],
            this.lawPersonsIdsComparison,
          )
        : [...this.selectedLawPersons, ...selectedLawPersons]
      : selectedLawPersons;
    this.addPersonTypeToSelectedLawPersons();
    this.emitSelectedLawPersons();
    this.resetMatchingLawPersons();
  }

  addPersonTypeToSelectedLawPersons(): void {
    this.selectedLawPersons = this.selectedLawPersons.map((lawPerson) =>
      lawPerson.personType || lawPerson.mainPersonOfDesginerPortal
        ? lawPerson
        : { ...lawPerson, personType: this.chosenPersonType },
    );
  }

  resetMatchingLawPersons(): void {
    this.matchingLawPersons = [];
  }

  emitSelectedLawPersons(): void {
    this.selectedLawPersonsChange.emit(this.selectedLawPersons);
  }

  updateAddingPersonMode(isNewPersonMode: boolean): void {
    this.resetMatchingLawPersons();
    if (isNewPersonMode) {
      this.enableNewPersonMode();
    } else {
      this.disableNewPersonMode();
    }
  }

  enableNewPersonMode(): void {
    const legalPersonFormGroup = this.getLegalPersonFormGroup();
    const naturalPersonFormGroup = this.getNaturalPersonFormGroup();
    markFormGroupsUntouched([legalPersonFormGroup, naturalPersonFormGroup]);
    markFormGroupsPristine([legalPersonFormGroup, naturalPersonFormGroup]);
    this.lawPersonForm.enable();
    this.newPersonMode = true;
    this.setValidatorsOfLegalPersonFormControls();
  }

  disableNewPersonMode(): void {
    this.getAddressFormGroup().disable({ emitEvent: false });
    this.getLegalPersonTypeIdFormControl().disable();
    this.getLegalPersonNameFormControl().disable();
    this.newPersonMode = false;
    this.setValidatorsOfLegalPersonFormControls();
    this.clearLawPersonFormData();
  }

  setValidatorsOfLegalPersonFormControls(): void {
    const nameFormControl = this.getLegalPersonNameFormControl();
    const nipFormControl = this.getLegalPersonNipFormControl();
    const regonFormControl = this.getLegalPersonRegonFormControl();
    if (this.newPersonMode) {
      nameFormControl.setValidators(Validators.required);
      nipFormControl.setValidators([Validators.required, nipValidator()]);
      regonFormControl.setValidators([Validators.required, regonValidator()]);
    } else {
      nameFormControl.clearValidators();
      nipFormControl.setValidators(nipValidator());
      regonFormControl.setValidators(regonValidator());
    }
    nameFormControl.updateValueAndValidity();
    nipFormControl.updateValueAndValidity();
    regonFormControl.updateValueAndValidity();
  }

  getLegalPersonNameFormControl(): UntypedFormControl {
    return this.getLegalPersonFormGroup().get(
      LegalPersonControlName.Name,
    ) as UntypedFormControl;
  }

  getLegalPersonTypeIdFormControl(): UntypedFormControl {
    return this.getLegalPersonFormGroup().get(
      LegalPersonControlName.TypeId,
    ) as UntypedFormControl;
  }

  getLegalPersonNipFormControl(): UntypedFormControl {
    return this.getLegalPersonFormGroup().get(
      LegalPersonControlName.Nip,
    ) as UntypedFormControl;
  }

  getLegalPersonRegonFormControl(): UntypedFormControl {
    return this.getLegalPersonFormGroup().get(
      LegalPersonControlName.Regon,
    ) as UntypedFormControl;
  }

  getLegalPersonKrsNumberFormControl(): UntypedFormControl {
    return this.getLegalPersonFormGroup().get(
      LegalPersonControlName.KrsNumber,
    ) as UntypedFormControl;
  }

  getPostalCodeFormControl(): UntypedFormControl {
    return this.getAddressFormGroup().get(
      this.lawPersonAddressControlName.PostalCode,
    ) as UntypedFormControl;
  }

  getPostOfficeFormControl(): UntypedFormControl {
    return this.getAddressFormGroup().get(
      this.lawPersonAddressControlName.PostOffice,
    ) as UntypedFormControl;
  }

  getPlaceFormControl(): UntypedFormControl {
    return this.getAddressFormGroup().get(
      this.lawPersonAddressControlName.Place,
    ) as UntypedFormControl;
  }

  getStreetFormControl(): UntypedFormControl {
    return this.getAddressFormGroup().get(
      this.lawPersonAddressControlName.Street,
    ) as UntypedFormControl;
  }

  getBuildingNumberFormControl(): UntypedFormControl {
    return this.getAddressFormGroup().get(
      this.lawPersonAddressControlName.BuildingNumber,
    ) as UntypedFormControl;
  }

  isPostOfficeFormControlValid(): boolean {
    const postOfficeFormControlValue = this.getPostOfficeFormControl().value;

    return !!postOfficeFormControlValue;
  }

  isSumLengthNaturalPersonFirstNameAndLastNameValid(): boolean {
    const naturalPersonFormGroup = this.getNaturalPersonFormGroup();
    const sumLengthValueOfNaturalPersonFormGroup =
      naturalPersonFormGroup.value.firstName.length +
      naturalPersonFormGroup.value.lastName.length;

    return (
      sumLengthValueOfNaturalPersonFormGroup <=
      maxLengthValueOfNaturalPersonFormGroup
    );
  }

  addNewLawPerson(): void {
    if (!this.isLawPersonValid()) {
      return;
    }
    this.clearPersonSearchOrAddMsg();
    (this.primitivePostalCode
      ? of(undefined)
      : this.askForPostOfficeWhenPostalCodeIsNotFromDictionary()
    )
      .pipe(
        tap(() => {
          this.personLoading = true;
        }),
        switchMap(() => this.getAddingPersonMethod()),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (data) => {
          this.disableNewPersonModeWhenIsEnabled();
          this.updateLawPersonsTable([data]);
          this.personLoading = false;
        },
        error: (error) => {
          console.error(error);
          this.handlePersonAddFailure();
        },
      });
  }

  askForPostOfficeWhenPostalCodeIsNotFromDictionary(): Observable<void> {
    this.getPostOfficeFormControl().clearValidators();
    const postalCodeFormControl = this.getPostalCodeFormControl();
    return new Observable<void>((observer) => {
      if (postalCodeFormControl.value && !postalCodeFormControl.value.id) {
        this.setPostOfficeValidatorsAndPatchDefaultValue();
        const modal = this.openAndReturnPostOfficeModal();
        modal.result.then(() => {
          observer.next();
          observer.complete();
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  setPostOfficeValidatorsAndPatchDefaultValue(): void {
    const getPostOfficeFormControl = this.getPostOfficeFormControl();
    const placeFormControl = this.getPlaceFormControl();
    const placeFormControlValue =
      placeFormControl.value.name || placeFormControl.value;
    getPostOfficeFormControl.setValidators(Validators.required);
    getPostOfficeFormControl.patchValue(placeFormControlValue);
  }

  openAndReturnPostOfficeModal(): NgbModalRef {
    return this.ngbModal.open(this.postOfficeModal, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  isLawPersonValid(): boolean {
    return (
      (this.isNaturalPersonChosen()
        ? this.isNaturalPersonValid()
        : this.isLegalPersonValid()) && this.getAddressFormGroup().valid
    );
  }

  isNaturalPersonValid(): boolean {
    this.markFormGroupsTouchedOnNaturalPersonSearch();

    return this.getNaturalPersonFormGroup().valid;
  }

  isLegalPersonValid(): boolean {
    this.markFormGroupsTouchedOnLegalPersonSearch();

    return this.getLegalPersonFormGroup().valid;
  }

  getAddingPersonMethod(): Observable<NaturalPersonSearch | LegalPersonSearch> {
    return this.isNaturalPersonChosen()
      ? this.getAddingNewNaturalPersonMethod()
      : this.getAddingNewLegalPersonMethod();
  }

  getAddingNewNaturalPersonMethod(): Observable<NaturalPersonSearch> {
    return this.lawPersonService.addNaturalPerson(
      this.lawPersonForm.value,
      this.isDesignerPersonTypeChosen(),
      this.addingPersonsDirectlyToDb,
    );
  }

  getAddingNewLegalPersonMethod(): Observable<LegalPersonSearch> {
    return this.lawPersonService.addLegalPerson(
      this.lawPersonForm.value,
      this.addingPersonsDirectlyToDb,
      this.addLawPersonUrl,
    );
  }

  markFormGroupsTouchedOnNaturalPersonSearch(): void {
    markFormGroupsTouched([
      this.getAddressFormGroup(),
      this.getNaturalPersonFormGroup(),
    ]);
  }

  markFormGroupsTouchedOnLegalPersonSearch(): void {
    markFormGroupsTouched([
      this.getAddressFormGroup(),
      this.getLegalPersonFormGroup(),
    ]);
  }

  handlePersonAddFailure(): void {
    this.setPersonSearchOrAddMsg('ADD_FAILED');
    this.personLoading = false;
  }

  setPersonSearchOrAddMsg(translationKey: string): void {
    this.personSearchOrAddMsg =
      this.lawPersonSearchTranslations[translationKey];
  }

  clearPersonSearchOrAddMsg(): void {
    this.personSearchOrAddMsg = undefined;
  }

  deleteLawPersonsFromSelectedLawPersons(lawPersons: LawPersonSearch[]): void {
    this.selectedLawPersons = _.differenceWith(
      this.selectedLawPersons,
      lawPersons,
      this.getLawPersonsIdsComparisonFunctionWithDeletedIdsCache(),
    );
    this.emitSelectedLawPersons();
  }

  shouldShowLawPersonTypeForm(): boolean {
    return !this.chosenPersonType || !this.isDesignerPersonTypeChosen();
  }

  clearLawPersonFormData(): void {
    this.getAddressFormGroup().reset(undefined, { emitEvent: false });
    this.getNaturalPersonFormGroup().reset();
    this.getLegalPersonFormGroup().reset();
    this.setValidatorsOfLegalPersonFormControls();
  }

  isHiddenKrsNumber(): boolean {
    return (
      this.legalPersonSearchFieldsToHide &&
      this.legalPersonSearchFieldsToHide.includes(
        LegalPersonControlName.KrsNumber,
      )
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
