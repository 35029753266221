import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  ECSControlName,
  ElectronicCorrespondenceStatement,
} from './models/form/form.model';

@Component({
  selector: 'app-electronic-correspondence-statement',
  templateUrl: './electronic-correspondence-statement.component.html',
  styleUrls: ['./electronic-correspondence-statement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ElectronicCorrespondenceStatementComponent {
  controlName = ECSControlName;
  electronicCorrespondenceStatement = ElectronicCorrespondenceStatement;
  @Input() formGroup: FormGroup;
  @Input() submitted: boolean;

  isElectronicCorrespondenceStatementFormControlValid(): boolean {
    return this.getElectronicCorrespondenceStatementFormControl().valid;
  }

  getElectronicCorrespondenceStatementFormControl(): AbstractControl {
    return this.formGroup.get(
      this.controlName.ElectronicCorrespondenceStatement,
    );
  }
}
