import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { SessionRefreshTriggerService } from '../../../services/session-refresh-trigger/session-refresh-trigger.service';

@Directive({
  selector: '[appSessionRefresher]',
  standalone: false,
})
export class SessionRefresherDirective implements OnInit {
  constructor(
    private elRef: ElementRef,
    private sessionRefreshTriggerService: SessionRefreshTriggerService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.registerClickHandler();
  }

  private registerClickHandler(): void {
    this.renderer.listen(this.elRef.nativeElement, 'click', () =>
      this.sessionRefreshTriggerService.eventActionHandler(),
    );
  }
}
