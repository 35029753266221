import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { takeWhile } from 'rxjs';
import { DictionaryField } from '../../../gk-dynamic-list/services/dictionary/dictionary.model';
import { FormAlertService } from '../../../services/form-alert/form-alert.service';
import { LegalPersonControlName } from '../../services/law-person-form/law-person-form.model';
import { LegalPersonTypeService } from '../../services/legal-person-type/legal-person-type.service';

@Component({
  selector: 'gk-legal-person-form',
  templateUrl: './legal-person-form.component.html',
  standalone: false,
})
export class LegalPersonFormComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input() form: UntypedFormGroup;
  @Input() isRequired: boolean;
  @Input() newPersonMode: boolean;
  @Input() typeVisible = true;
  @Input() idPrefix = '';
  @Input() searchFieldsToHide: LegalPersonControlName[] = [];
  legalPersonTypes: DictionaryField[];
  legalPersonControlName = LegalPersonControlName;

  constructor(
    private legalPersonTypeService: LegalPersonTypeService,
    private formAlertService: FormAlertService,
  ) {}

  ngOnInit(): void {
    this.subscribeToLegalPersonTypesList();
    this.setDefaultLegalPersonTypeOnValueReset();
  }

  subscribeToLegalPersonTypesList(): void {
    this.legalPersonTypeService.legalPersonTypes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((legalPersonTypes) => {
        this.legalPersonTypes = legalPersonTypes;
        this.setDefaultLegalPersonTypeIfParameterIsDefined();
      });
  }

  setDefaultLegalPersonTypeOnValueReset(): void {
    this.getTypeIdFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => {
        if (!value) {
          this.setDefaultLegalPersonTypeIfParameterIsDefined();
        }
      });
  }

  setDefaultLegalPersonTypeIfParameterIsDefined(): void {
    this.getTypeIdFormControl().patchValue(
      this.legalPersonTypes &&
        this.legalPersonTypes[0] &&
        this.legalPersonTypes[0].id,
    );
  }

  getTypeIdFormControl(): UntypedFormControl {
    return this.form.get(
      this.legalPersonControlName.TypeId,
    ) as UntypedFormControl;
  }

  getNameFormControl(): UntypedFormControl {
    return this.form.get(
      this.legalPersonControlName.Name,
    ) as UntypedFormControl;
  }

  getRegonFormControl(): UntypedFormControl {
    return this.form.get(
      this.legalPersonControlName.Regon,
    ) as UntypedFormControl;
  }

  getNipFormControl(): UntypedFormControl {
    return this.form.get(this.legalPersonControlName.Nip) as UntypedFormControl;
  }

  shouldShowKrsNumberAlert(): boolean {
    const formControl = this.getKrsNumberFormControl();
    return !!(
      this.formAlertService.shouldShowErrorAlert(
        formControl,
        'pattern',
        undefined,
      ) && formControl.value
    );
  }

  getKrsNumberFormControl(): UntypedFormControl {
    return this.form.get(
      this.legalPersonControlName.KrsNumber,
    ) as UntypedFormControl;
  }

  shouldShowRequiredAlert(formControl: UntypedFormControl): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      'required',
      undefined,
    );
  }

  shouldShowRegonAlert(formControl: UntypedFormControl): boolean {
    return !!(
      this.formAlertService.shouldShowErrorAlert(
        formControl,
        'regon',
        undefined,
      ) && formControl.value
    );
  }

  shouldShowNipAlert(formControl: UntypedFormControl): boolean {
    return !!(
      this.formAlertService.shouldShowErrorAlert(
        formControl,
        'nip',
        undefined,
      ) && formControl.value
    );
  }

  shouldShowAtLeastOneAlert(): boolean {
    return !!(
      this.formAlertService.shouldShowErrorAlert(
        this.form,
        'atLeastOneRequired',
        undefined,
      ) && !this.newPersonMode
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
