import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { FormBaseComponent } from '../../../form-base/form-base.component';
import { CoordinateSystemFieldConfig } from '../../../gk-dynamic-form.model';
import {
  CoordinateSystem,
  CoordinateSystemType,
} from '../../../services/coordinate-system/coordinate-system.model';
import { CoordinateSystemService } from '../../../services/coordinate-system/coordinate-system.service';

@Component({
  selector: 'gk-form-coordinate-system',
  templateUrl: './coordinate-system.component.html',
  standalone: false,
})
export class CoordinateSystemComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  override config: CoordinateSystemFieldConfig;
  coordinateSystems: CoordinateSystem[] = [];

  constructor(private coordinateSystemService: CoordinateSystemService) {
    super();
  }

  ngOnInit(): void {
    this.patchEmptyValue();
    this.subscribeToCoordinateSystems();
  }

  subscribeToCoordinateSystems(): void {
    this.getProperCoordinateSystems()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (coordinateSystems) => (this.coordinateSystems = coordinateSystems),
      );
  }

  getProperCoordinateSystems(): BehaviorSubject<CoordinateSystem[]> {
    switch (this.config.coordinateSystemType) {
      case CoordinateSystemType.Horizontal:
        return this.coordinateSystemService.horizontalCoordinateSystems;
      case CoordinateSystemType.Vertical:
        return this.coordinateSystemService.verticalCoordinateSystems;
      default:
        return new BehaviorSubject<CoordinateSystem[]>([]);
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
