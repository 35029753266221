import {
  ApiNewDokumentPowiazanyDalDto,
  LawPerson,
  Place,
  Street,
} from '@gk/gk-modules';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../../shared/shipping-form/shipping-form.model';
import {
  DateHelperUtil,
  NgbDateRange,
} from '../../../../../utils/date-helper/date-helper.util';

export interface LOBONewRequestDto extends ApplicantRequestDto {
  DrogaNumer: string | number;
  MiejscId: string | number;
  Miejsc: string;
  UlicaId: string | number;
  Ulica: string;
  Cel: string;
  PowierzchniaPas: string;
  TerminZajeciaOd: string;
  TerminZajeciaDo: string;
}

export class LOBONewRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    public laneOccupancyPurpose: string,
    public laneOccupancyDate: NgbDateRange,
    public roadNumber: string,
    public locationPlace: Place,
    public locationStreet: Street,
    public areaWithoutRoadEngineeringFacilities: string,
    public areaOfRoadEngineeringFacilities: string,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: LOBONewRequest,
    issueRanges: string[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): LOBONewRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        issueRanges,
        files,
      }),
      DrogaNumer: data.roadNumber,
      MiejscId: data.locationPlace.id,
      Miejsc: data.locationPlace.name,
      UlicaId: data.locationStreet && data.locationStreet.id,
      Ulica: data.locationStreet && data.locationStreet.name,
      Cel: data.laneOccupancyPurpose,
      PowierzchniaPas: data.areaWithoutRoadEngineeringFacilities,
      TerminZajeciaOd: DateHelperUtil.ngbDateToApiDateTime(
        data.laneOccupancyDate.from,
      ),
      TerminZajeciaDo: DateHelperUtil.ngbDateToApiDateTime(
        data.laneOccupancyDate.to,
      ),
    };
  }
}
