import { FormControl } from '@angular/forms';
import { FieldId } from '@gk/gk-modules';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum LBRCNNewDataChangesNotificationAdditionalControlName {
  ChangeScope = 'changeScope',
  DemonstrationOfApplicantRight = 'demonstrationOfApplicantRight',
  DeterminationOfLocation = 'determinationOfLocation',
}

export const LBRCNNewDataChangesNotificationControlName = {
  ...BaseNewRequestControlName,
  ...LBRCNNewDataChangesNotificationAdditionalControlName,
};

export interface LBRCNNewDataChangesNotificationFormGroup
  extends LawPersonNewRequestFormGroup {
  [LBRCNNewDataChangesNotificationControlName.ChangeScope]: FormControl<string>;
  [LBRCNNewDataChangesNotificationControlName.DemonstrationOfApplicantRight]: FormControl<FieldId>;
  [LBRCNNewDataChangesNotificationControlName.DeterminationOfLocation]: FormControl<string>;
}
