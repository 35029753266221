//https://github.com/ngx-formly/ngx-formly/blob/main/src/ui/kendo/select/src/select.type.ts
import { Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/kendo/form-field';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { Observable, of } from 'rxjs';
import { DictionariesService } from '../../../services/dictionaries/dictionaries.service';
import { isObjectOfTypeObservable } from '../../../utils/utils';

export interface FormlyFieldKendoSelectComponentProps
  extends FormlyFieldSelectProps {
  /**
   * Specifies the type of the selected value
   * ([more information and example](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/value-binding/#toc-primitive-values-from-object-fields).
   * If set to `true`, the selected value has to be of a primitive value.
   */
  valuePrimitive?: boolean;
  labelProp: string;
  valueProp: string;
  dictUrl?: string;
}

@Component({
  selector: 'gk-formly-field-kendo-select',
  template: `
    <kendo-dropdownlist
      [size]="'small'"
      [ngClass]="{ required: props.required }"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [defaultItem]="field.defaultValue ?? undefined"
      [data]="handleOptions(field.props.options) | async"
      [textField]="props.labelProp"
      [valueField]="props.valueProp"
      [valuePrimitive]="props.valuePrimitive ?? true"
      (valueChange)="props.change && props.change(field, $event)"
    >
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        <span>{{
          (!dataItem
            ? ''
            : props.valueProp
              ? dataItem[props.labelProp]
              : dataItem
          ) | translate
        }}</span>
      </ng-template>
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        <span>{{
          (!dataItem
            ? ''
            : props.labelProp
              ? dataItem[props.labelProp]
              : dataItem
          ) | translate
        }}</span>
      </ng-template>
    </kendo-dropdownlist>
  `,
  standalone: false,
})
export class FormlyFieldKendoSelectComponent extends FieldType<
  FieldTypeConfig<FormlyFieldKendoSelectComponentProps>
> {
  constructor(private dictionariesService: DictionariesService) {
    super();
  }

  isDictionaryUrl(url: string): boolean {
    if (url?.includes('/slo/')) {
      return true;
    } else {
      throw new Error('Wrong dictionary url');
    }
  }

  handleOptions(
    options: any[] | Observable<any[]> | undefined,
  ): Observable<any[]> {
    if (this.props.dictUrl && this.isDictionaryUrl(this.props.dictUrl)) {
      return this.dictionariesService.get(this.props.dictUrl);
    }

    if (isObjectOfTypeObservable(options)) {
      return options;
    }

    if (options instanceof Array) {
      return of(options);
    }

    return of([]);
  }
}
