import { Component } from '@angular/core';

import { Control } from '../control';

@Component({
  selector: 'gk-control-default',
  templateUrl: './control-default.component.html',
  standalone: false,
})
export class ControlDefaultComponent extends Control<any> {}
