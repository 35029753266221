import { Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/kendo/form-field';
import { ButtonConfig, InputProps } from './model';
import { percentIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'gk-formly-field-kendo-input',
  template: `
    @if (props.type !== 'number') {
      <kendo-textbox
        [ngClass]="{ required: props.required }"
        [formlyAttributes]="field"
        [formControl]="formControl"
        [placeholder]="props.placeholder | translate"
        [readonly]="props.readonly || false"
      >
        @if (props.percent) {
          <ng-template kendoTextBoxPrefixTemplate>
            <kendo-svg-icon [icon]="percentIcon"></kendo-svg-icon>
            <kendo-textbox-separator></kendo-textbox-separator>
          </ng-template>
          <ng-template kendoTextBoxSuffixTemplate>
            <kendo-textbox-separator></kendo-textbox-separator>
            <kendo-svg-icon [icon]="percentIcon"></kendo-svg-icon>
          </ng-template>
        }
        @if (!!props.buttonConfig) {
          <ng-template kendoTextBoxSuffixTemplate>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button
              kendoButton
              type="button"
              (click)="props.buttonConfig?.click()"
              [disabled]="getButtonDisabledState(props.buttonConfig)"
            >
              {{ props.buttonConfig.text | translate }}
            </button>
          </ng-template>
        }
      </kendo-textbox>
    } @else {
      <kendo-numerictextbox
        [spinners]="false"
        [ngClass]="{ required: props.required }"
        [formlyAttributes]="field"
        [formControl]="formControl"
        [placeholder]="props.placeholder | translate"
        [autoCorrect]="true"
        [maxlength]="props.maxlength"
        [min]="props.min"
        [max]="props.max"
        format="#"
      >
      </kendo-numerictextbox>
    }
  `,
  standalone: false,
})
export class FormlyFieldKendoInputComponent extends FieldType<
  FieldTypeConfig<InputProps>
> {
  protected readonly percentIcon = percentIcon;

  getButtonDisabledState(buttonsConfig: ButtonConfig): boolean {
    if (typeof buttonsConfig.disabled === 'function') {
      return buttonsConfig.disabled();
    }

    return buttonsConfig.disabled;
  }
}
