import { LawPersonAddress, Place, Street } from '@gk/gk-modules';
import * as _ from 'lodash';
import { PostalCode } from '../../services/postal-code/postal-code.model';

export enum ShippingFormControlName {
  NameSurname = 'nameSurname',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  ShippingForm = 'shippingForm',
  FtpAddress = 'ftpAddress',
  IsOtherAddress = 'isOtherAddress',
}

export interface ShippingData {
  nameSurname: string;
  place: Place;
  street: Street;
  postalCode: PostalCode;
  postOffice: string;
  buildingNumber: string;
  localNumber: string;
  ftpAddress: string;
}

export class ShippingDataDto {
  constructor(
    public Nazwa: string,
    public Email: string,
    public MiejscId: number | string,
    public Miejsc: string,
    public UlicaId: number | string,
    public Ulica: string,
    public KodId: number | string,
    public Kod: string,
    public Poczta: string,
    public Numer: string,
    public Numer2: string,
    public AdresFtp: string,
  ) {}

  static fromAppToApi(
    data: LawPersonAddress,
    nameSurname: string,
    email: string,
    ftpAddress: string,
  ): ShippingDataDto {
    return new this(
      nameSurname,
      email,
      _.get(data, 'place.id'),
      _.get(data, 'place.name'),
      _.get(data, 'street.id'),
      _.get(data, 'street.name'),
      _.get(data, 'postalCode.id'),
      _.get<LawPersonAddress, string>(data, 'postalCode.name') ||
        _.get<LawPersonAddress, string>(data, 'postalCode'),
      _.get(data, 'postOffice'),
      _.get(data, 'buildingNumber'),
      _.get(data, 'localNumber'),
      ftpAddress,
    );
  }
}

export interface Shipping {
  nameSurname: string;
  email: string;
  address: LawPersonAddress;
  shippingForm: number;
  ftpAddress: string;
}

export class ShippingDto {
  constructor(
    public SposobOdbioru: number,
    public OdbiorDane: ShippingDataDto,
  ) {}

  static fromAppToApi(data: Shipping): ShippingDto {
    return new this(
      data.shippingForm,
      ShippingDataDto.fromAppToApi(
        data.address,
        data.nameSurname,
        data.email,
        data.ftpAddress,
      ),
    );
  }
}
