import {
  BaseNewRequestControlName,
  LocationNewRequestControlName,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum BPTNewRequestAdditionalControlName {
  BuildingNameAndType = 'buildingNameAndType',
  CurrentInvestorDetails = 'currentInvestorDetails',
  PermitTransferDate = 'permitTransferDate',
  PermitTransferNumber = 'permitTransferNumber',
}

export const BPTNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...LocationNewRequestControlName,
  ...BPTNewRequestAdditionalControlName,
};
