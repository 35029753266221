import { Injectable } from '@angular/core';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { GridDataService } from '../../../../../gk-kendo';
import { LandDetailsGridDto } from './map-land-details-grid-data-model';

@Injectable({
  providedIn: 'any',
})
export class LandDetailsGridDataService extends GridDataService<LandDetailsGridDto> {
  constructor() {
    super('api/mapa/dzialki/search', 'POST', (data) =>
      orderBy(data, [{ field: 'Id', dir: 'asc' }] as SortDescriptor[]),
    );
  }
}
