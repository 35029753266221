import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, map, Observable, ReplaySubject } from 'rxjs';
import { Community, CommunityFromApi, District, Sheet } from './cached.model';

@Injectable({ providedIn: 'root' })
export class CachedService {
  districtsForCommunity = new BehaviorSubject<District[]>([]);
  sheetsForDistrict = new BehaviorSubject<Sheet[]>([]);
  communitiesWithDistricts = new BehaviorSubject<Community[]>([]);
  allDistricts = new BehaviorSubject<District[]>([]);
  allCommunitiesSelected = new ReplaySubject<boolean>();
  allSheets = new BehaviorSubject<Sheet[]>([]);
  dictionaryUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('COMMUNITY_DICT_URL')
    dictionaryUrl = '/api/interesant/slo/gminy',
  ) {
    this.dictionaryUrl = dictionaryUrl;
    this.fetchCommunitiesWithDistricts();
  }

  getCommunitiesWithDistricts(): Observable<Community[]> {
    return this.http
      .get<{ Response: CommunityFromApi[] }>(this.dictionaryUrl)
      .pipe(
        map((res) =>
          res.Response.map((comunityFromApi) =>
            Community.fromApiToApp(comunityFromApi),
          ),
        ),
      );
  }

  fetchCommunitiesWithDistricts(): void {
    this.getCommunitiesWithDistricts().subscribe((communities) => {
      this.communitiesWithDistricts.next(communities);
      const allDistricts = _.sortBy(_.flatMap(communities, 'districts'), [
        'name',
      ]);
      this.allDistricts.next(allDistricts);
      this.allSheets.next(_.flatMap(allDistricts, 'sheets'));
    });
  }
}
