<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isRequestTypeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.RequestType">
            {{
              'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.REQUEST_TYPE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="d-flex row-select">
            <select
              [attr.id]="controlName.RequestType"
              [title]="'NEW_DESIGNER_REQUEST_FORM.PERSON_TYPE' | translate"
              [name]="controlName.RequestType"
              [formControlName]="controlName.RequestType"
              class="form-select form-select-sm required"
              aria-required="true"
            >
              <option [ngValue]="roadExitRequestType.New">
                {{
                  'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.NEW_ROAD_EXIT'
                    | translate
                }}
              </option>

              <option [ngValue]="roadExitRequestType.Reconstruction">
                {{
                  'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.RECONSTRUCTION_ROAD_EXIT'
                    | translate
                }}
              </option>
            </select>
          </div>
        </div>

        @if (!isRequestTypeFormControlValid() && submitted) {
          <div class="card-footer">
            @if (shouldShowRequiredAlert(getRequestTypeFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.CLIENT_DATA' | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLocationFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.LOCATION' | translate }}
        </div>

        <div class="card-body">
          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.RoadNumber">
              {{
                'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.ROAD_NUMBER'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.RoadNumber"
              [id]="controlName.RoadNumber"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.RoadNumber"
            />

            @if (shouldShowRequiredAlert(getRoadNumberFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="mt-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>

          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Place">
              {{
                'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.PLACE' | translate
              }}
            </label>

            @if (!areParcelsChosen()) {
              <input
                type="text"
                [name]="controlName.Place"
                [id]="controlName.Place"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.Place"
                [ngbTypeahead]="searchPlace"
                [resultFormatter]="formatter"
                [inputFormatter]="formatter"
              />
            }

            @if (areParcelsChosen()) {
              <select
                [name]="controlName.Place"
                [id]="controlName.Place"
                [title]="'NEW_DESIGNER_REQUEST_FORM.PERSON_TYPE' | translate"
                [formControlName]="controlName.Place"
                class="form-select form-select-sm required"
                aria-required="true"
              >
                @for (place of placesOfSelectedParcels; track place.id) {
                  <option [ngValue]="place">
                    {{ place.name }}
                  </option>
                }
              </select>
            }

            @if (shouldShowRequiredAlert(getPlaceFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>
        </div>

        @if (submitted && (!areParcelsChosen() || !areIssueRangesChosen())) {
          <div class="card-footer">
            @if (!areParcelsChosen()) {
              <div class="alert alert-danger mt-2">
                {{
                  'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.PARCELS_REQUIRED'
                    | translate
                }}
              </div>
            }
            @if (!areIssueRangesChosen()) {
              <div class="alert alert-danger mt-2">
                {{
                  'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.ISSUE_RANGE_REQUIRED'
                    | translate
                }}
              </div>
            }
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isPurposeOfPropertyUseFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.PurposeOfPropertyUse">
            {{
              'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.PURPOSE_OF_PROPERTY_USE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.PurposeOfPropertyUse"
            [id]="controlName.PurposeOfPropertyUse"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.PurposeOfPropertyUse"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getPurposeOfPropertyUseFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        [formGroupName]="controlName.PurposeOfPropertyUseChanged"
        class="card mb-4"
        [ngClass]="
          !isPurposeOfPropertyUseChangedFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.PURPOSE_OF_PROPERTY_USE_CHANGED_AFTER_BUILD_OR_RECONSTRUCTION'
              | translate
          }}
        </div>

        <div class="card-body">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [id]="controlName.WillUseOfPropertyChange"
              [name]="controlName.WillUseOfPropertyChange"
              [formControlName]="controlName.WillUseOfPropertyChange"
            />

            <label
              class="form-check-label"
              [attr.for]="controlName.WillUseOfPropertyChange"
            >
              {{
                'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.WILL_USE_OF_PROPERTY_CHANGE'
                  | translate
              }}
            </label>
          </div>

          @if (getWillUseOfPropertyChangeFormControlValue()) {
            <div>
              <label
                class="mb-0"
                [attr.for]="controlName.ChangedPurposeOfPropertyUse"
              >
                {{
                  'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.PURPOSE_OF_PROPERTY_USE_CHANGED'
                    | translate
                }}
              </label>
              <div>
                <textarea
                  [name]="controlName.ChangedPurposeOfPropertyUse"
                  [id]="controlName.ChangedPurposeOfPropertyUse"
                  class="form-control required"
                  aria-required="true"
                  [formControlName]="controlName.ChangedPurposeOfPropertyUse"
                ></textarea>
                @if (
                  shouldShowRequiredAlert(
                    getPurposeOfPropertyUseChangedFormGroup()
                  )
                ) {
                  <div class="alert alert-danger">
                    {{ 'FIELD_REQUIRED' | translate }}
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </section>

    <gk-attachments [portalId]="portalId" [submitted]="submitted">
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          {{
            'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.PERSONAL_DATA_PROCESING_LABEL'
              | translate
          }}
        </div>

        <div class="card-body">
          <P
            [innerHTML]="
              'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.PERSONAL_DATA_PROCESING'
                | translate
            "
          >
          </P>
        </div>
      </div>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST.SUBMIT' | translate }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
