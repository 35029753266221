import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as _ from 'lodash';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import {
  ColumnHeader,
  WrapCellConfig,
} from '../../../gk-components/table/table.model';
import { getFileNameFromResponseHeaders } from '../../../utils/utils';
import { UnpaidInvoice } from '../../payments.model';
import { PaymentsService } from '../../payments.service';

@Component({
  selector: 'gk-unpaid',
  templateUrl: './unpaid.component.html',
  standalone: false,
})
export class UnpaidComponent implements OnInit, OnDestroy {
  private isAlive = true;
  invoices: UnpaidInvoice[] = [];
  selectedInvoices: UnpaidInvoice[] = [];
  columnSetting: ColumnHeader[];
  isInvoiceLoading: boolean;
  selectedInvoicesAmount: number;
  @Output()
  invoicesTotalCount = new EventEmitter<number>();

  constructor(
    private paymentsService: PaymentsService,
    private toastr: ToastrService,
    private fileSaverService: FileSaverService,
  ) {}

  ngOnInit(): void {
    this.columnSetting = this.getColumnSettings();
    this.fetchUnpaidInvoices();
  }

  onInvoicesSelect(selectedInvoices: UnpaidInvoice[]): void {
    this.selectedInvoices = selectedInvoices;
    this.calculateSelectedInvoicesAmount(this.selectedInvoices);
  }

  calculateSelectedInvoicesAmount(selectedInvoices: UnpaidInvoice[]): void {
    this.selectedInvoicesAmount = selectedInvoices
      .map((item) => parseFloat(item.feeDue))
      .reduce((a, b) => a + b, 0);
  }

  onRefreshInvoices(): void {
    this.invoices = [];
    this.selectedInvoices = [];
    this.selectedInvoicesAmount = 0;
    this.fetchUnpaidInvoices();
  }

  fetchUnpaidInvoices(): void {
    this.isInvoiceLoading = true;
    this.paymentsService
      .getUnpaidInvoices()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (invoices) => {
          this.invoices = invoices;
          this.invoicesTotalCount.emit(invoices.length);
          this.isInvoiceLoading = false;
        },
        error: (errorResponse: HttpErrorResponse) =>
          this.handleFailResponse(errorResponse),
      });
  }

  downloadSelectedUnpaidInvoices() {
    const fileUuids = this.selectedInvoices.map((invoice) => invoice.fileUuid);

    this.paymentsService
      .downloadInvoices({ FileUuids: fileUuids })
      .subscribe((data) => {
        this.fileSaverService.save(
          data.body,
          getFileNameFromResponseHeaders(data.headers),
        );
      });
  }

  handleFailResponse(errorResponse: HttpErrorResponse): void {
    this.isInvoiceLoading = false;
    const errorMsg = _.get(errorResponse, 'error.ResponseStatus.Message');
    this.toastr.error(errorMsg);
  }

  getColumnSettings(): ColumnHeader[] {
    return [
      new ColumnHeader('payment', 'GK.PAYMENTS.PAYMENT'),
      new ColumnHeader(
        'applied',
        'GK.PAYMENTS.APPLIED',
        true,
        '400px',
        new WrapCellConfig(true, 60),
      ),
      new ColumnHeader('paymentIssueData', 'GK.PAYMENTS.DAY'),
      new ColumnHeader('feeDue', 'GK.PAYMENTS.FOR_VALUE_OF'),
      new ColumnHeader('ownerSignature', 'GK.PAYMENTS.CASE_NUMBER'),
      new ColumnHeader('ownerType', 'GK.PAYMENTS.CASE_TYPE'),
      new ColumnHeader('bankAccountNumber', 'GK.PAYMENTS.ACCOUNT_NUMBER'),
    ];
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
