import {
  ApiNewDokumentPowiazanyDalDto,
  LawPerson,
  MapObjectApiType,
  MapState,
  Place,
  Street,
} from '@gk/gk-modules';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../../shared/shipping-form/shipping-form.model';
import {
  DateHelperUtil,
  NgbDateRange,
} from '../../../../../utils/date-helper/date-helper.util';
import { LaneOccupancyAreaWidth } from '../new-request-form/new-request-form.model';

export enum LaneOccupancyDtoType {
  LessThan20PercentWidth = 'JezdniaDo20Powierzchnia',
  Between20And50PercentWidth = 'JezdniaOd20Do50Powierzchnia',
  MoreThan50PercentWidth = 'JezdniaOd50Do100Powierzchnia',
}

function getProperLaneOccupancyTypeLabel(
  value: LaneOccupancyAreaWidth,
): LaneOccupancyDtoType {
  switch (value) {
    case LaneOccupancyAreaWidth.LessThan20PercentWidth: {
      return LaneOccupancyDtoType.LessThan20PercentWidth;
    }
    case LaneOccupancyAreaWidth.Between20And50PercentWidth: {
      return LaneOccupancyDtoType.Between20And50PercentWidth;
    }
    case LaneOccupancyAreaWidth.MoreThan50PercentWidth: {
      return LaneOccupancyDtoType.MoreThan50PercentWidth;
    }
  }
}

export interface LOBWNewRequestDto extends ApplicantRequestDto {
  DrogaNumer: string | number;
  MiejscId: string | number;
  Miejsc: string;
  UlicaId: string | number;
  Ulica: string;
  Cel: string;
  Etapy: LaneOccupancyBuildingWorksStageDto[];
}

export interface LaneOccupancyBuildingWorksStageDto {
  Geom: string[];
  JezdniaDo20Powierzchnia?: number;
  JezdniaOd20Do50Powierzchnia?: number;
  JezdniaOd50Do100Powierzchnia?: number;
  ChodnikiPowierzchnia?: number;
  PozostalePowierzchnia?: number;
  TerminZajeciaOd: string;
  TerminZajeciaDo: string;
}

export class LaneOccupancyBuildingWorksArea {
  constructor(
    public roadWidth: LaneOccupancyAreaWidth,
    public road: number,
    public roadsideComponents: number,
    public roadLaneOtherElements: number,
    public mapState: MapState,
    public dateRange: NgbDateRange,
  ) {}

  static fromAppToApi(
    data: LaneOccupancyBuildingWorksArea,
  ): LaneOccupancyBuildingWorksStageDto {
    return {
      Geom: data.mapState.mapObjectTablesState[0].mapObjects
        .filter(
          (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
        )
        .map((mapObject) => mapObject.geom),
      [getProperLaneOccupancyTypeLabel(data.roadWidth)]: data.road,
      ChodnikiPowierzchnia: data.roadsideComponents,
      PozostalePowierzchnia: data.roadLaneOtherElements,
      TerminZajeciaOd: DateHelperUtil.ngbDateToApiDateTime(data.dateRange.from),
      TerminZajeciaDo: DateHelperUtil.ngbDateToApiDateTime(data.dateRange.to),
    };
  }
}

export class LOBWNewRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    public laneOccupancyPurpose: string,
    public laneOccupancyArea: LaneOccupancyBuildingWorksArea[],
    public roadNumber: string,
    public locationPlace: Place,
    public locationStreet: Street,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: LOBWNewRequest,
    files: ApiNewDokumentPowiazanyDalDto[],
  ): LOBWNewRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        files,
      }),
      DrogaNumer: data.roadNumber,
      MiejscId: data.locationPlace.id,
      Miejsc: data.locationPlace.name,
      UlicaId: data.locationStreet && data.locationStreet.id,
      Ulica: data.locationStreet && data.locationStreet.name,
      Cel: data.laneOccupancyPurpose,
      Etapy: data.laneOccupancyArea.map((element) =>
        LaneOccupancyBuildingWorksArea.fromAppToApi(element),
      ),
    };
  }
}
