import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { CommunalPremisePurchasePortalRoutingModule } from './communal-premise-purchase-portal-routing.model';
import { CommunalPremisePurchasePortalComponent } from './communal-premise-purchase-portal.component';
import { NewCommunalPremisePurchaseRequestComponent } from './new-communal-premise-purchase-request/new-communal-premise-purchase-request.component';
import { CPPNewRequestFormService } from './services/new-request-form/new-request-form.service';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    CommunalPremisePurchasePortalRoutingModule,
  ],
  declarations: [
    CommunalPremisePurchasePortalComponent,
    NewCommunalPremisePurchaseRequestComponent,
  ],
  providers: [CPPNewRequestFormService],
})
export class CommunalPremisePurchasePortalModule {}
