import { FormControl } from '@angular/forms';
import {
  BaseNewRequestControlName,
  LawPersonNewRequestFormGroup,
} from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum LBRDUUERNewDataUpdateRequestAdditionalControlName {
  ChangeScope = 'changeScope',
  DeterminationOfLocation = 'determinationOfLocation',
}

export const LBRDUUERNewDataUpdateRequestControlName = {
  ...BaseNewRequestControlName,
  ...LBRDUUERNewDataUpdateRequestAdditionalControlName,
};

export interface LBRDUUERNewDataUpdateRequestFormGroup
  extends LawPersonNewRequestFormGroup {
  [LBRDUUERNewDataUpdateRequestControlName.ChangeScope]: FormControl<string>;
  [LBRDUUERNewDataUpdateRequestControlName.DeterminationOfLocation]: FormControl<string>;
}
