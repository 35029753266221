import { Component, Input } from '@angular/core';

import { ColumnConfig, MessagesListConfig } from '../../gk-dynamic-list.model';

@Component({
  selector: 'gk-column-manager',
  templateUrl: './column-manager.component.html',
  styleUrls: ['./column-manager.component.scss'],
  standalone: false,
})
export class ColumnManagerComponent {
  @Input() columnConfigs: ColumnConfig[] = [];
  @Input() messagesListConfig: MessagesListConfig;
  showManager = false;

  toggle(): void {
    this.showManager = !this.showManager;
  }
}
