import { Injectable } from '@angular/core';
import { District } from '@gk/gk-modules';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class DistrictSelectionService {
  selectedDistricts = new BehaviorSubject<District[]>([]);
  resetDistrictsState = new Subject<boolean>();

  removeDistricts(districts: District[]): void {
    this.selectedDistricts.next(
      this.selectedDistricts.value.filter((dstr) => !_.find(districts, dstr)),
    );
  }

  addDistricts(districts: District[]): void {
    this.selectedDistricts.next(
      _.uniqWith([...districts, ...this.selectedDistricts.value], _.isEqual),
    );
  }

  removeAll(): void {
    this.selectedDistricts.next([]);
    this.resetDistrictsState.next(true);
  }
}
