import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LawPersonControlName } from '../../services/law-person-form/law-person-form.model';
import { LawPersonType } from '../../services/law-person/law-person.model';

@Component({
  selector: 'gk-law-person-type-form',
  templateUrl: './law-person-type-form.component.html',
  standalone: false,
})
export class LawPersonTypeFormComponent {
  @Input() form: UntypedFormGroup;
  @Input() idPrefix = '';
  public lawPersonEnum = LawPersonType;
  public lawPersonControlName = LawPersonControlName;
}
