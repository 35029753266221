import {
  ApiNewDokumentPowiazanyDalDto,
  LawPersonAddress,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  DocumentationFormType,
  ExtendedApplicantRequest,
  ExtendedApplicantRequestDto,
} from '../../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../../shared/shipping-form/shipping-form.model';

export interface WENewRequestDto extends ExtendedApplicantRequestDto {
  RodzajJednostkiPlywajacej: string;
  MiejscePostoju: string;
  RodzajNapedu: string;
  DlugoscCalkowita: number;
  WysokoscBoczna: number;
  NajwiekszaSzerokosc: number;
  IloscMiejscSiedzacych: number;
  RodzajMaterialuKonstrukcyjnego: string;
}

export class WENewRequest extends ExtendedApplicantRequest {
  constructor(
    investorDetails: InvestorDetails,
    proxyDetails: InvestorDetails,
    public birthDate: NgbDate,
    public birthPlace: string,
    public watercraftType: string,
    public stagingPoint: string,
    public driveType: string,
    public totalLength: number,
    public sideHeight: number,
    public largestWidth: number,
    public seatsNumber: number,
    public constructionMaterialType: string,
    public idNumber: number,
    public phoneNumber: number,
    public email: string,
    public address: LawPersonAddress,
    proxyDetailsCheckbox: boolean,
    public attachments: ApiNewDokumentPowiazanyDalDto,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
  ) {
    super(
      investorDetails,
      documentationForm,
      shippingForm,
      undefined,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }
  static fromAppToApi(
    data: WENewRequest,
    files: ApiNewDokumentPowiazanyDalDto[],
  ): WENewRequestDto {
    return {
      ...ExtendedApplicantRequest.mapCommonFields({
        data,
        files,
      }),
      RodzajJednostkiPlywajacej: data.watercraftType,
      MiejscePostoju: data.stagingPoint,
      RodzajNapedu: data.driveType,
      DlugoscCalkowita: data.totalLength,
      WysokoscBoczna: data.sideHeight,
      NajwiekszaSzerokosc: data.largestWidth,
      IloscMiejscSiedzacych: data.seatsNumber,
      RodzajMaterialuKonstrukcyjnego: data.constructionMaterialType,
    };
  }
}
