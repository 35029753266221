import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeWhile } from 'rxjs/operators';
import { MessageStatus } from '../services/message-status/message-status.model';
import { NewPasswordService } from '../services/new-password/new-password.service';
import { SessionToolsService } from '../services/session-tools/session-tools.service';
import { TfaConfirmationControlName } from '../services/tfa-confirmation-form/tfa-confirmation-form.model';
import { TfaConfirmationService } from '../services/tfa-confirmation/tfa-confirmation.service';
import { TfaResetService } from '../services/tfa-reset/tfa-reset.service';
import { TfaTotpInitializationCredentialsService } from '../services/tfa-totp-initialization-credentials/tfa-totp-initialization-credentials.service';
import { SessionToolsBaseComponent } from '../session-tools-base.component';
import { TfaService } from './../../../gk-user-settings/services/tfa/tfa.service';
import { TfaConfirmationFormGroup } from './../services/tfa-confirmation-form/tfa-confirmation-form.model';
import { TfaConfirmationFormService } from './../services/tfa-confirmation-form/tfa-confirmation-form.service';

@Component({
  selector: 'gk-tfa-confirmation',
  templateUrl: './tfa-confirmation.component.html',
  styleUrls: [
    '../session-tools.component.scss',
    './tfa-confirmation.component.scss',
  ],
  standalone: false,
})
export class TfaConfirmationComponent
  extends SessionToolsBaseComponent
  implements OnInit, OnDestroy
{
  successDefaultMessage: string;
  isAlive = true;
  formGroup: FormGroup<TfaConfirmationFormGroup>;
  controlNameEnum = TfaConfirmationControlName;

  constructor(
    public tfaConfirmationFormService: TfaConfirmationFormService,
    public tfaTotpInitializationCredentialsService: TfaTotpInitializationCredentialsService,
    public override tfaService: TfaService,
    public override router: Router,
    public override translateService: TranslateService,
    public override newPasswordService: NewPasswordService,
    public override tfaConfirmationService: TfaConfirmationService,
    public override tfaResetService: TfaResetService,
    public override sessionToolsService?: SessionToolsService,
  ) {
    super(
      translateService,
      router,
      newPasswordService,
      tfaService,
      tfaConfirmationService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    this.fetchDefaultErrorMessage();
    super.ngOnInit();
  }

  createForm(): void {
    this.formGroup = this.tfaConfirmationFormService.getFormGroup();
  }

  tfaConfirmationSubmitHandle(): void {
    this.actionRequestInProgress = true;
    if (!this.formGroup.valid) {
      this.actionRequestInProgress = false;
      return;
    }
    this.tfaConfirmationService
      .verify(this.formGroup.getRawValue())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (data) => {
          this.actionRequestInProgress = false;
          switch (data.errorCode) {
            case 0: {
              this.setMessageStatus(MessageStatus.Success);
              this.messageText = this.successDefaultMessage;
              this.tfaConfirmationService.verifiedConfirmation.next();

              break;
            }
            default: {
              this.setMessageStatus(MessageStatus.Error);
              this.messageText = data.errorMessage;
            }
          }
        },
        error: (error) => {
          console.error(error);
          this.actionRequestInProgress = false;
          this.setMessageStatus(MessageStatus.Error);
          this.messageText = this.errorDefaultMessage;
        },
      });
  }

  cancel(): void {
    this.tfaConfirmationService.canceledConfirmation.next();
    this.tfaConfirmationService.tfaConfirmationInOperation.next(false);
    this.tfaService.tfaTypeInOperation.next(undefined);
    this.switchToLoginModeIfIsLoginConfirmationMode();
  }

  switchToLoginModeIfIsLoginConfirmationMode(): void {
    this.tfaConfirmationService
      .isLoginTfaConfirmationMode()
      .pipe(
        takeWhile(() => this.isAlive),
        filter(Boolean),
      )
      .subscribe(() => {
        this.switchToLoginMode();
      });
  }
}
