import { FormControl } from '@angular/forms';
import { StampDutyOffice } from '../../../../services';

export enum PaymentDetailsControlName {
  PaymentOwner = 'paymentOwner',
  NumberOfPayments = 'numberOfPayments',
  PaymentAmount = 'paymentAmount',
}

export type PaymentDetailsControlsExistenceParams = {
  [key in PaymentDetailsControlName]?: boolean;
};

export interface PaymentDetailsFormGroup {
  [PaymentDetailsControlName.PaymentOwner]: FormControl<StampDutyOffice>;
  [PaymentDetailsControlName.NumberOfPayments]?: FormControl<number>;
  [PaymentDetailsControlName.PaymentAmount]?: FormControl<number>;
}
