<div class="container">
  <h1 class="mt-2 mb-2 fs-3" tabindex="0">
    {{ 'NEW_DESIGNER_REQUEST_FORM.TITLE' | translate }}
  </h1>

  <app-workspace-state
    [workspaceStateDraftVersion]="workspaceStateDraftVersion"
    [workspaceStateId]="workspaceStateId"
    [getWorkspaceStateToSave]="getWorkspaceStateToSave"
    [borderClass]="'card-border-color-from-db'"
    [backgroundClass]="'card-header-color-from-db'"
    [buttonClass]="'btn-color-from-db'"
    (workspaceStateLoaded)="handleLoadWorkspaceResponse($event)"
  ></app-workspace-state>

  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <section class="mt-4">
      <div
        class="card mb-4"
        *ngLet="
          (validated &&
            !getChosenConnectionsAndNetworksFormArrayValue().length) ||
          (triedToAddConnectionAndNetwork &&
            !isNewConnectionAndNetworkValid()) as shouldShowErrors
        "
        [ngClass]="
          shouldShowErrors
            ? 'border-danger border-2'
            : 'card-border-color-from-db'
        "
      >
        <div class="card-header text-black card-header-color-from-db">
          {{ connectionsAndNetworksTranslations['HEADER'] }}
        </div>

        <div class="card-body">
          <h2 class="fs-4">
            <label for="connections-and-network">
              {{ connectionsAndNetworksTranslations['HEADER'] }}
            </label>
          </h2>

          <div
            class="d-flex row-select"
            [formGroupName]="controlName.ConnectionAndNetworkGroup"
          >
            <select
              [title]="connectionsAndNetworksTranslations['TYPE']"
              id="connections-and-network"
              name="connectionAndNetwork"
              formControlName="connectionAndNetwork"
              [ngClass]="{
                required: !getConnectionAndNetworkGroupArray().controls.length,
              }"
              [attr.aria-required]="
                !getConnectionAndNetworkGroupArray().controls.length
              "
              class="form-select form-select-sm"
            >
              <option value="null"></option>

              @for (opt of getNotChosenConnectionsAndNetworks(); track opt.id) {
                <option [ngValue]="opt">
                  {{ opt.name }}
                </option>
              }
            </select>
          </div>

          @if (getConnectionAndNetworkGroupArray().controls.length) {
            <h2 class="my-3 fs-4">
              {{ connectionsAndNetworksTranslations['CHOSEN_HEADER'] }}
            </h2>
          }

          @if (getConnectionAndNetworkGroupArray().controls.length) {
            <app-connections-and-networks-table
              [designerRequestForm]="formGroup"
            ></app-connections-and-networks-table>
          }
        </div>

        @if (shouldShowErrors) {
          <div class="card-footer">
            @if (
              validated &&
              !getChosenConnectionsAndNetworksFormArrayValue().length
            ) {
              <div class="alert alert-danger mb-0">
                {{ connectionsAndNetworksTranslations['REQUIRED'] }}
              </div>
            }
            @if (
              triedToAddConnectionAndNetwork &&
              !isNewConnectionAndNetworkValid()
            ) {
              <div class="alert alert-danger my-2">
                {{ connectionsAndNetworkError }}
              </div>
            }
          </div>
        }
      </div>
    </section>

    <section class="mt-4" id="search-and-choose-parcel">
      <div
        class="card mb-4"
        [ngClass]="
          validated && (!areParcelsChosen() || !areIssueRangesValid())
            ? 'border-danger border-2'
            : 'card-border-color-from-db'
        "
      >
        <div class="card-header text-black card-header-color-from-db">
          {{ 'NEW_DESIGNER_REQUEST_FORM.PARCELS_AND_ISSUE_RANGE' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <h2 class="mt-4 fs-4" tabindex="0" aria-required="true">
            {{ 'NEW_DESIGNER_REQUEST_FORM.CHOSEN_PARCELS' | translate }}
          </h2>

          <gk-map-object-table
            [attr.aria-required]="true"
            [mapState]="mapState"
            [mapObjectTableStateIndex]="mapParcelsObjectTableStateIndex"
            (dispatch)="handleMapAction($event)"
          ></gk-map-object-table>

          @if (validated && !areParcelsChosen()) {
            <div class="alert alert-danger mt-2">
              {{ 'NEW_DESIGNER_REQUEST_FORM.PARCELS_REQUIRED' | translate }}
            </div>
          }

          <h2
            class="mt-2 fs-4"
            tabindex="0"
            [attr.aria-required]="designerSettings?.rangeRequired"
          >
            {{ 'NEW_DESIGNER_REQUEST_FORM.CHOSEN_ISSUE_RANGES' | translate }}
          </h2>

          <gk-map-object-table
            [mapState]="mapState"
            [mapObjectTableStateIndex]="mapIssueRangesObjectTableStateIndex"
            (dispatch)="handleMapAction($event)"
          ></gk-map-object-table>

          @if (validated && !areIssueRangesValid()) {
            <div class="alert alert-danger mt-2">
              {{ 'NEW_DESIGNER_REQUEST_FORM.ISSUE_RANGE_REQUIRED' | translate }}
            </div>
          }
        </div>
      </div>
    </section>

    <section class="mt-4">
      <div
        class="card mb-4"
        *ngLet="
          validated &&
          (!isPayerAdded() ||
            !isInvestorNotRequiredOrAdded() ||
            !getMainPersonTypeOfDesignerPortal() ||
            areDuplicatedNotMultiPersonTypes()) as validatedAndHasErrors
        "
        [ngClass]="
          validatedAndHasErrors
            ? 'border-danger border-2'
            : 'card-border-color-from-db'
        "
      >
        <div class="card-header text-black card-header-color-from-db">
          {{ 'NEW_DESIGNER_REQUEST_FORM.LEGAL_PERSONS' | translate }}
        </div>

        <div class="card-body">
          <div class="form-group">
            <h2 class="fs-4">
              <label for="person-type">
                {{ 'NEW_DESIGNER_REQUEST_FORM.PERSON_TYPE' | translate }}
              </label>
            </h2>

            <div class="d-flex row-select">
              <select
                [attr.title]="
                  'NEW_DESIGNER_REQUEST_FORM.PERSON_TYPE' | translate
                "
                name="currentChosenPersonType"
                formControlName="currentChosenPersonType"
                class="form-select form-select-sm"
                id="person-type"
              >
                @for (opt of allPossiblePersonTypes; track opt.id) {
                  <option
                    [ngClass]="{ required: opt.isRequired }"
                    [attr.aria-required]="opt.isRequired"
                    [ngValue]="opt"
                  >
                    {{ opt.name }}
                  </option>
                }
              </select>
            </div>
          </div>

          <div class="form-group">
            <h2 class="fs-4">
              {{ 'NEW_DESIGNER_REQUEST_FORM.SEARCHING_AND_ADDING' | translate }}
            </h2>

            <div>
              <gk-law-person-search
                [multiplePersonSelect]="true"
                [uniquePersonsOnList]="false"
                [isRequired]="true"
                [isDesignerAddressRequired]="false"
                [chosenPersonType]="
                  formGroup.get('currentChosenPersonType').value
                "
                [addingPersonsDirectlyToDb]="addingPersonsDirectlyToDb"
                [defaultAvailablePersonTypes]="
                  allPossiblePersonTypesToChangeOnList
                "
                [canAddNewPersonEvenIfDefinedInDb]="
                  designerSettings?.canAddNewPersonEvenIfDefinedInDb
                "
                [legalPersonAddModeEnabled]="true"
                [addLawPersonUrl]="'/api/zudp/projektant/petent'"
                [legalPersonSearchFieldsToHide]="[
                  legalPersonControlName.TypeId,
                  legalPersonControlName.Name,
                  legalPersonControlName.KrsNumber,
                ]"
                [(selectedLawPersons)]="chosenLawPersons"
                (selectedLawPersonsChange)="handleChosenLawPersonsChange()"
              ></gk-law-person-search>
            </div>
          </div>
        </div>

        @if (validatedAndHasErrors) {
          <div class="card-footer">
            @if (!isPayerAdded()) {
              <div class="alert alert-danger mt-2">
                <span>{{
                  'NEW_DESIGNER_REQUEST_FORM.PAYER_REQUIRED' | translate
                }}</span>
              </div>
            }
            @if (!isInvestorNotRequiredOrAdded()) {
              <div class="alert alert-danger mt-2">
                <span>{{
                  'NEW_DESIGNER_REQUEST_FORM.INVESTOR_REQUIRED' | translate
                }}</span>
              </div>
            }
            @if (!getMainPersonTypeOfDesignerPortal()) {
              <div class="alert alert-danger mt-2">
                <span>
                  {{
                    'NEW_DESIGNER_REQUEST_FORM.PERSON_TYPE_REQUIRED' | translate
                  }}
                </span>
              </div>
            }
            @if (areDuplicatedNotMultiPersonTypes()) {
              <div class="alert alert-danger mt-2">
                <span>{{ duplicatedSinglePersonTypes.join(', ') }} </span>
                <span>
                  {{
                    'NEW_DESIGNER_REQUEST_FORM.DUPLICATED_SINGLE_TYPES_LABEL'
                      | translate
                  }}
                </span>
              </div>
            }
          </div>
        }
      </div>
    </section>

    <section class="mt-4">
      <div
        class="card mb-4"
        [ngClass]="
          validated && !areDocFilesAttachedAndValid()
            ? 'border-danger border-2'
            : 'card-border-color-from-db'
        "
      >
        <div class="card-header text-black card-header-color-from-db">
          {{ 'NEW_DESIGNER_REQUEST_FORM.FILES' | translate }}
        </div>

        <div class="card-body">
          <div class="col-12 px-0">
            <app-docs-table-editable
              [(files)]="docFiles"
              [submitted]="validated"
            ></app-docs-table-editable>
          </div>
        </div>

        @if (validated && !areObligatoryDocFilesAttached()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'NEW_DESIGNER_REQUEST_FORM.ATTACH_OBLIGATORY_FILES' | translate
              }}
              <ul>
                @for (
                  docType of docTypeService.getObligatoryDocTypes(
                    portalTypeEnum.Designer,
                    docFiles
                  );
                  track docType.id
                ) {
                  <li>
                    {{ docType.name }}
                  </li>
                }
              </ul>
            </div>
          </div>
        }
      </div>
    </section>

    <section class="mt-4">
      <div
        class="card mb-4"
        *ngLet="
          shouldShowCouncilSubjectFormControlLengthError() as shouldShowErrors
        "
        [ngClass]="
          shouldShowErrors
            ? 'border-danger border-2'
            : 'card-border-color-from-db'
        "
      >
        <div class="card-header text-black card-header-color-from-db">
          {{ 'NEW_DESIGNER_REQUEST_FORM.DESCRIPTIVE_INFORMATION' | translate }}
        </div>

        <div class="card-body">
          <div class="form-group">
            <h2 class="fs-4">
              <label for="typedAddress">
                {{ 'NEW_DESIGNER_REQUEST_FORM.LOCALISATION' | translate }}
              </label>
            </h2>

            <div class="mt-2">
              <input
                type="text"
                [maxlength]="maxLengthDesignerRequest.TypedAddress"
                id="typedAddress"
                class="form-control form-control-sm"
                formControlName="typedAddress"
              />
            </div>
          </div>

          <div class="form-group">
            <h2 class="fs-4">
              <label for="council-subject">
                {{
                  'NEW_DESIGNER_REQUEST_FORM.DESIGNER_REQUEST_COUNCIL_SUBJECT'
                    | translate
                }}
              </label>
            </h2>

            <div class="mt-2">
              <textarea
                rows="3"
                type="text"
                [maxlength]="maxLengthDesignerRequest.CouncilSubject"
                id="council-subject"
                class="form-control form-control-sm"
                [formControlName]="controlName.CouncilSubject"
              >
              </textarea>

              @if (shouldShowErrors) {
                <div class="alert alert-danger mt-2 mb-0">
                  {{ 'FIELD_MAX_LENGTH' | translate }}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="card mb-4">
      <div class="card-header text-black card-header-color-from-db">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            [id]="controlName.EmailingConsent"
            [formControlName]="controlName.EmailingConsent"
            (change)="handleChangeEmailingConsent($event, confirmationModal)"
          />
          <label
            class="form-check-label"
            [attr.for]="controlName.EmailingConsent"
            >{{
              'NEW_DESIGNER_REQUEST_FORM.EMAILING_CONSENT' | translate
            }}</label
          >
        </div>
      </div>
    </section>

    <section class="row">
      <section class="col-12 form-group">
        @if (successfullyAdded) {
          <div class="alert alert-success">
            <span>
              {{
                'NEW_DESIGNER_REQUEST_FORM.DESIGNER_REQUEST_SUCCESSFULLY_SUBMITTED'
                  | translate
              }}
            </span>
          </div>
        }

        <app-request-validation-info
          [shouldShowProvideFieldsAlert]="
            submitted && (isFormValid() | async) === false
          "
          [errorSubmitMessage]="errorSubmitMessage"
        ></app-request-validation-info>

        @if (closedAreasInvalid) {
          <div class="alert alert-danger mt-2">
            <span>{{
              'NEW_DESIGNER_REQUEST_FORM.INVALID_CLOSED_AREAS' | translate
            }}</span>
          </div>
        }
      </section>

      @if (
        !docSignPending &&
        (disabledSubmitButton || (loadingClosedAreas | async))
      ) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }

      <div class="col-12 mt-4">
        <div class="row">
          <div class="col-lg-auto col-sm-6 pe-sm-1 pt-2 pt-sm-0 d-grid">
            <button
              type="submit"
              class="btn btn-color-from-db"
              [disabled]="
                disabledSubmitButton ||
                (loadingClosedAreas | async) ||
                closedAreasInvalid
              "
            >
              {{
                'NEW_DESIGNER_REQUEST_FORM.SUBMIT_DESIGNER_REQUEST' | translate
              }}
            </button>
          </div>
          <app-request-preview-button
            class="col-lg-auto col-sm-6 px-lg-1 ps-sm-1 pt-2 pt-sm-0 d-grid"
            [buttonClass]="'btn btn-color-from-db'"
            [getPreviewRequestBody]="getPreviewRequestBody"
            [previewRequestUrl]="'/api/zudp/projektant/wniosek/podglad'"
          ></app-request-preview-button>
        </div>

        <app-doc-sign-info
          [messageText]="docSignMsgTxt"
          [messageType]="docSignMsgType"
          [docSignUrl]="docSignUrl"
          [docSignPending]="docSignPending"
        ></app-doc-sign-info>

        <div class="mt-2">
          <app-form-legend [inRow]="true"></app-form-legend>
        </div>
      </div>
    </section>
  </form>
</div>

<app-permission-number-modal></app-permission-number-modal>
<ng-template
  #confirmationModal
  let-c="close('accept')"
  let-d="dismiss('reject')"
>
  <div class="modal-header">
    <h2 class="modal-title fs-4">
      {{
        'GK.DYNAMIC_LIST.CONFIRMATION_EMAIL_CONSENT_UNCHECK_TITLE' | translate
      }}
    </h2>
  </div>
  <div class="modal-body">
    <span>{{
      'GK.DYNAMIC_LIST.CONFIRMATION_EMAIL_CONSENT_UNCHECK' | translate
    }}</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="(c)">
      {{ 'GK.DYNAMIC_LIST.YES' | translate }}
    </button>
    <button type="button" class="btn btn-outline-danger" (click)="(d)">
      {{ 'GK.DYNAMIC_LIST.NO' | translate }}
    </button>
  </div>
</ng-template>
