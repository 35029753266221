<div class="dropdown">
  <a
    class="btn dropdown-toggle dropdown-toggle-size btn-color-from-db font-color-from-db force-color-from-db"
    [ngClass]="getButtonClass()"
    href="#"
    [id]="dropdownNavItemConfig.navItemId"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    [title]="dropdownNavItemConfig.dropdownTitle | translate"
    tabindex="0"
  >
    {{ dropdownNavItemConfig.dropdownLabel | translate }}
    <span class="float-start m-1" [ngClass]="getIconClassSet()"></span>
    @if (dropdownNavItemConfig.showSelectedImage) {
      <span>
        <img
          class="nav-dropdown-item-icon mb-1"
          [src]="getCustomIconSrc() | async"
          alt="selected-language-img"
        />
      </span>
    }
  </a>
  <div
    class="dropdown-menu p-0"
    [ngClass]="getModifiers()"
    attr.aria-labelledby="{{ dropdownNavItemConfig.navItemId }}"
    role="menu"
  >
    @for (
      dropdownItemConfig of dropdownNavItemConfig.dropdownItemConfigs;
      track dropdownItemConfig
    ) {
      <gk-nav-item-renderer
        [navbarConfig]="navbarConfig"
        [navItemConfig]="dropdownItemConfig"
        [dropdownConfig]="dropdownNavItemConfig"
        role="menuitem"
        [tabindex]="dropdownItemConfig.type === 'link' ? '-1' : '0'"
      ></gk-nav-item-renderer>
    }
  </div>
</div>
