import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPerson,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  DocumentationFormType,
  InvestorRequest,
  InvestorRequestDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../../utils/date-helper/date-helper.util';

export interface BOPUCNewRequestDto extends InvestorRequestDto {
  DotychczasowySposobUzytkowania: string;
  ZamierzonySposobUzytkowania: string;
  InformacjaRobotyBudowlane: string;
  TerminRozpoczeciaRobotBudowlanych: string;
}
export class BOPUCConstructionWorksInformation {
  constructor(
    public executionType: string,
    public plannedStartDate: NgbDate,
  ) {}
}

export class BOPUCNewRequest extends InvestorRequest {
  constructor(
    lawPerson: LawPerson,
    contactData: LawPerson,
    public currentUse: string,
    public intendedUse: string,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public constructionWorksInformation: BOPUCConstructionWorksInformation,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
  ) {
    super(
      lawPerson,
      contactData,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static fromAppToApi(
    data: BOPUCNewRequest,
    parcels: EgibObject[],
    buildings: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): BOPUCNewRequestDto {
    return {
      ...InvestorRequest.mapCommonFields({
        data,
        parcels,
        buildings,
        files,
      }),
      DotychczasowySposobUzytkowania: data.currentUse,
      ZamierzonySposobUzytkowania: data.intendedUse,
      InformacjaRobotyBudowlane:
        data.constructionWorksInformation.executionType,
      ZgodaNaKorespondencjeElektroniczna:
        data.electronicCorrespondenceStatement,
      TerminRozpoczeciaRobotBudowlanych: DateHelperUtil.ngbDateToApiDateTime(
        data.constructionWorksInformation.plannedStartDate,
      ),
    };
  }
}
