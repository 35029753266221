import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[gkTruncatedTextTooltip]',
})
export class TruncatedTextTooltipDirective implements AfterViewInit {
  private isTruncated = false;
  private textContent = '';

  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.checkTruncation();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.checkTruncation();
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.isTruncated) {
      this.forceTooltipRefresh();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.renderer.removeAttribute(this.el.nativeElement, 'title');
  }

  private checkTruncation(): void {
    const element: HTMLElement = this.el.nativeElement;
    this.textContent = element.textContent?.trim() || '';
    this.isTruncated = element.offsetWidth < element.scrollWidth;
  }

  private forceTooltipRefresh(): void {
    const element: HTMLElement = this.el.nativeElement;

    this.renderer.removeAttribute(element, 'title');

    setTimeout(() => {
      this.renderer.setAttribute(element, 'title', this.textContent);
    }, 10);
  }
}
