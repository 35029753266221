import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { NestedAccordionControlConfig } from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-nested-accordion',
  templateUrl: './control-nested-accordion.component.html',
  styleUrls: ['./control-nested-accordion.component.scss'],
  standalone: false,
})
export class ControlNestedAccordionComponent
  extends Control<any>
  implements OnInit
{
  readonly threeDotsLength = 3;
  @Input()
  override controlConfig: NestedAccordionControlConfig;
  collapsed = true;

  constructor(
    protected override translateService: TranslateService,
    protected override dataService: DataService,
  ) {
    super(translateService, dataService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.collapsed = this.controlConfig.collapsed;
  }

  switchCollapse(): void {
    this.collapsed = !this.collapsed;
  }

  getIconClass(): string {
    return `fa ${this.collapsed ? 'fa-angle-right' : 'fa-angle-down'}`;
  }

  getFullLabel(): string {
    const label = this.controlConfig.pathToLabel
      ? _.get(this.data, this.controlConfig.pathToLabel, this.getLabel())
      : this.getLabel();

    return this.getLabelWithCountWhenIsDefined(label);
  }

  getButtonLabel(): string {
    const maxLetters = this.controlConfig.maxLettersToShow;
    const label = this.getFullLabel();

    return label.length > maxLetters
      ? `${label.substring(0, maxLetters - this.threeDotsLength)}...`
      : label;
  }
}
