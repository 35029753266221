@if (documentSections?.length) {
  <section class="px-4 pt-4 border rounded mb-4" [ngClass]="borderClass">
    <h2 class="mb-4">{{ 'ATTACHMENTS.TITLE' | translate }}</h2>
    @for (documentSection of documentSections; track documentSection.id) {
      <section>
        <div
          class="card mb-4"
          [ngClass]="
            shouldShowFileInputInvalidMessage(documentSection)
              ? 'border-danger border-2'
              : borderClass
          "
        >
          <div class="card-header" [ngClass]="backgroundClass">
            {{ documentSection.name | translate }}
          </div>
          <div
            class="card-body"
            [ngClass]="{
              required:
                documentSection.isObligatory &&
                documentSection.alternativeDocumentTypes?.length,
            }"
          >
            @if (
              documentSection.stampDuty?.isPaymentEnabled &&
              (documentSection.stampDuty?.isInitialPaid ||
                (isStampDutyPaidInCurrentSession(documentSection.id) | async))
            ) {
              <div class="alert alert-success m-0">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                {{ 'ATTACHMENTS.PAID' | translate }}
              </div>
            } @else if (
              documentSection.stampDuty?.isPaymentEnabled &&
              (documentSection.stampDuty?.isInitialPending ||
                (stampDutyService.isPendingInCurrentSession(
                  portalId,
                  documentSection.id
                ) | async))
            ) {
              <button
                class="btn btn-outline-primary"
                [disabled]="true"
                [attr.aria-label]="
                  'ATTACHMENTS.PAYMENT_IN_PROGRESS' | translate
                "
                [attr.title]="'ATTACHMENTS.PAYMENT_IN_PROGRESS' | translate"
              >
                <i class="fa fa-spinner fa-pulse fa-fw"></i>
                {{ 'ATTACHMENTS.PAYMENT_IN_PROGRESS' | translate }}
              </button>
            } @else {
              @if (
                documentSection.uploadAvailable &&
                !documentSection.alternativeDocumentTypes?.length
              ) {
                <gk-file-input
                  class="mb-2 bg-color-from-db-for-button-child"
                  [ngClass]="{ required: documentSection.isObligatory }"
                  [attr.aria-required]="documentSection.isObligatory"
                  [showPreviews]="true"
                  (filesChange)="
                    handleFileInputAction(documentSection.id, $event)
                  "
                >
                </gk-file-input>
              }

              @if (
                documentSection.uploadAvailable &&
                documentSection.alternativeDocumentTypes?.length
              ) {
                @for (
                  alternativeDocumentType of documentSection.alternativeDocumentTypes;
                  track alternativeDocumentType.id
                ) {
                  <div class="mb-2 alternative-document-type">
                    <span>{{ alternativeDocumentType.name | translate }}</span>
                    <gk-file-input
                      class="mb-2 bg-color-from-db-for-button-child"
                      [showPreviews]="true"
                      (filesChange)="
                        handleFileInputAction(
                          alternativeDocumentType.id,
                          $event
                        )
                      "
                    ></gk-file-input>
                  </div>
                }
              }
              @if (documentSection.sampleDocuments?.length) {
                @for (
                  sampleDocument of documentSection.sampleDocuments;
                  track sampleDocument.id
                ) {
                  <button
                    type="button"
                    class="btn btn-outline-color-from-db mt-2 me-1"
                    (click)="downloadSampleDocument(sampleDocument.id)"
                  >
                    <i class="fa fa-download"></i>
                    <span class="ms-1">{{ sampleDocument.displayName }}</span>
                  </button>
                }
              }
              @if (
                documentSection.stampDuty?.isPaymentEnabled &&
                !isChosenDocument(documentSection.id)
              ) {
                <gk-payment-button
                  [documentSectionId]="documentSection.id"
                  [portalId]="portalId"
                  [buttonClass]="'btn btn-outline-primary mt-2 me-1'"
                  [buttonIconVisible]="true"
                  [paymentInfoTooltipVisible]="false"
                  [paymentWithoutCheckingLimit]="true"
                  [paymentOwnerRequired]="true"
                  [paymentInNewTab]="true"
                  [paymentAmountType]="documentSection.stampDuty?.amountType"
                  [paymentSuccessSimpleInfo]="true"
                  [abortPendingStatusOnRedirect]="true"
                  (isPaymentBeginCalled)="
                    triggerStampDutyPaymentStatusCheck(documentSection.id)
                  "
                ></gk-payment-button>
              }
            }
          </div>

          @if (documentSection.alternativeDocumentTypes?.length) {
            <div class="card-footer">
              <div
                class="mb-0"
                [ngClass]="
                  shouldShowFileInputInvalidMessage(documentSection)
                    ? 'alert alert-danger'
                    : 'text-danger'
                "
              >
                {{ 'ATTACHMENTS.AT_LEAST_ONE_ATTACHMENT_REQUIRED' | translate }}
              </div>
            </div>
          } @else if (shouldShowFileInputInvalidMessage(documentSection)) {
            <div class="card-footer">
              <div class="alert alert-danger mb-0">
                {{ 'ATTACHMENTS.NO_FILE_CHOSEN' | translate }}
              </div>
            </div>
          }
        </div>
      </section>
    }
  </section>
}
