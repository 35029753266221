import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import {
  GACSDRNewRequestControlName,
  GACSDRNewRequestFormGroup,
} from './new-request-form.model';

@Injectable()
export class GACSDRNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): FormGroup<GACSDRNewRequestFormGroup> {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonFormGroup({
        [GACSDRNewRequestControlName.ProxyDetailsCheckbox]: false,
        [GACSDRNewRequestControlName.ContactData]: true,
        [ECSControlName.ElectronicCorrespondenceStatement]: false,
      }).controls,
      [GACSDRNewRequestControlName.LawPerson]:
        this.baseNewRequestFormService.getFirstAndLastNameFormGroup(),
      [GACSDRNewRequestControlName.WorkSignature]: [getDefaultControlValue()],
      [GACSDRNewRequestControlName.WorkPurpose]: [getDefaultControlValue()],
      [GACSDRNewRequestControlName.Materials]: [getDefaultControlValue()],
      [GACSDRNewRequestControlName.DataRange]: [getDefaultControlValue()],
      [GACSDRNewRequestControlName.AdditionalInfo]: [getDefaultControlValue()],
    });
  }
}
