import { Component, Input } from '@angular/core';
import { LegendConfig } from '../../gk-kendo-grid.model';

@Component({
  selector: 'gk-kendo-grid-legend',
  styleUrls: ['./gk-kendo-grid-legend.component.scss'],
  template: `<div
    class="legend-for-grid d-flex justify-content-md-end  justify-content-between"
  >
    @for (legendItem of legendDataConfig; track legendItem.text) {
      @if (legendItem.isVisible ? legendItem.isVisible() : true) {
        <div class="d-flex mx-2">
          @if (legendItem.class?.length) {
            <div
              class="d-flex align-items-center"
              kendoTooltip
              [closable]="true"
              showOn="click"
            >
              <span
                [ngClass]="legendItem.class"
                title="{{ legendItem.text | translate }}"
              ></span>
            </div>
          }
          @if (legendItem.class?.length) {
            <div class="align-items-center d-none d-md-flex m-1">-</div>
          }
          <div class="align-items-center d-none d-md-flex">
            {{ legendItem.text | translate }}
          </div>
        </div>
      }
    }
  </div>`,
  standalone: false,
})
export class GkKendoGridLegendComponent {
  @Input()
  legendDataConfig: LegendConfig[];
}
