import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EgibObject, Street, StreetService } from '@gk/gk-modules';
import * as _ from 'lodash';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  skipWhile,
  switchMap,
  takeWhile,
} from 'rxjs';
import {
  Community,
  SelectOption,
} from '../../services/community/community.model';
import { CommunityService } from '../../services/community/community.service';
import {
  AdvancedSearchParcelFormModel,
  ParcelListMode,
} from '../../services/parcel/parcel.model';
import { ParcelService } from '../../services/parcel/parcel.service';
import { Place } from '../../services/place/place.model';
import { PlaceService } from '../../services/place/place.service';
import { PostalCode } from '../../services/postal-code/postal-code.model';
import { PostalCodeService } from '../../services/postal-code/postal-code.service';

@Component({
  selector: 'app-advanced-parcel-search-form',
  templateUrl: './advanced-parcel-search-form.component.html',
  standalone: false,
})
export class AdvancedParcelSearchFormComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Output() chosenParcels = new EventEmitter<EgibObject[]>();
  parcelSearchForm = {} as UntypedFormGroup;
  matchingParcels: EgibObject[] = [];
  loading = false;
  parcelListMode = ParcelListMode.Choosing;
  communitySelectOptions: SelectOption[] = [];
  districtSelectOptions: SelectOption[] = [];
  fetchedCommunities: Community[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private parcelService: ParcelService,
    private communityService: CommunityService,
    private placeService: PlaceService,
    private streetService: StreetService,
    private postalCodeService: PostalCodeService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.subscribeToFormValue();
    this.subscribeToCommunities();
    this.subscribeToSearchFormResponse();
  }

  fetchSelectOptions(): void {
    this.communitySelectOptions = CommunityService.getCommunitiesSelectOptions(
      this.fetchedCommunities,
    );
    this.districtSelectOptions = CommunityService.getDistrictsSelectOptions(
      this.fetchedCommunities,
    );
  }

  subscribeToCommunities(): void {
    this.communityService.communities
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((communities) => {
        this.fetchedCommunities = communities;
        this.fetchSelectOptions();
      });
  }

  subscribeToFormValue(): void {
    this.parcelSearchForm.valueChanges
      .pipe(
        takeWhile(() => this.isAlive),
        debounceTime(500),
        distinctUntilChanged((prevValue, nextValue) =>
          _.isEqual(prevValue, nextValue),
        ),
        skipWhile(
          (formValue: AdvancedSearchParcelFormModel) =>
            !_.get(formValue, 'parcelNumber.nominator'),
        ),
      )
      .subscribe((formValue: AdvancedSearchParcelFormModel) => {
        this.getMatchingParcelsToCriteria(formValue);
      });
  }

  handleChosenParcels(parcels: EgibObject[]): void {
    this.chosenParcels.emit(parcels);
  }

  handleCommunityChange(): void {
    this.resetDistrict();
    this.districtSelectOptions = CommunityService.getDistrictsSelectOptions(
      this.fetchedCommunities,
      this.parcelSearchForm.get('communityId').value,
    );
  }

  resetDistrict(): void {
    this.parcelSearchForm.get('districtId').setValue(null);
  }

  subscribeToSearchFormResponse(): void {
    this.parcelService.advancedParcelSearchFormResponse
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((matchedParcels) => {
        this.matchingParcels = matchedParcels;
        this.loading = false;
      });
  }

  getMatchingParcelsToCriteria(formValue: AdvancedSearchParcelFormModel): void {
    this.loading = true;
    this.parcelService.advancedParcelSearchFormRequest.next(formValue);
  }

  getEmptyParcelFormGroup(): UntypedFormGroup {
    return this.fb.group({
      communityId: null,
      districtId: null,
      parcelNumber: this.fb.group({
        nominator: '',
        denominator: '',
      }),
      parcelAddress: this.fb.group({
        place: null,
        street: null,
        ordinalNumber: null,
        localNumber: null,
        postalCode: null,
      }),
    });
  }

  createForm(): void {
    this.parcelSearchForm = this.getEmptyParcelFormGroup();
  }

  searchPlace = ($term: Observable<string>) =>
    $term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => this.placeService.getPlacesByTerm(term)),
    );

  searchStreet = ($term: Observable<string>) =>
    $term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        this.streetService.getStreets(
          term,
          _.get(
            this.parcelSearchForm.get('parcelAddress').get('place'),
            'value.id',
          ),
        ),
      ),
    );

  searchPostalCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => this.postalCodeService.getPostalCodes(term)),
    );

  formatter = (dbModel: Place | Street | PostalCode): string => dbModel.name;

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
