<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <app-workspace-state
      [workspaceStateDraftVersion]="workspaceStateDraftVersion"
      [workspaceStateId]="workspaceStateId"
      [getWorkspaceStateToSave]="getWorkspaceStateToSave"
      [buttonClass]="'btn-outline-primary'"
      (workspaceStateLoaded)="handleLoadWorkspaceResponse($event)"
    ></app-workspace-state>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.CLIENT_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      @let landParcelsChosen = areLandParcelsChosen();
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !landParcelsChosen
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.MAP_LOCATION'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>
        @if (submitted && !landParcelsChosen) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.LAND_PARCEL_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <gk-attachments [portalId]="portalId" [submitted]="submitted">
    </gk-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section>
      @let otherInformationsGroupValid =
        businessPurposesCheckboxControlComponent?.isOtherInformationsGroupValid();
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !otherInformationsGroupValid
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Comments">
            {{
              'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.ADDITIONAL_INFORMATION'
                | translate
            }}
          </label>
        </div>
        <div class="card-body">
          <h5>
            {{
              'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.DISCOUNT_APPLY'
                | translate
            }}
          </h5>
          <div class="form-check mb-3">
            <input
              type="checkbox"
              class="form-check-input"
              [id]="controlName.DiscountConditions"
              [formControlName]="controlName.DiscountConditions"
            />
            <label
              class="form-check-label"
              [attr.for]="controlName.DiscountConditions"
              >{{
                'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.CONDITIONS_OF_DISCOUNT'
                  | translate
              }}</label
            >
          </div>
          <div class="form-check mb-3">
            <input
              type="checkbox"
              class="form-check-input"
              [id]="controlName.ForHousingPurposes"
              [formControlName]="controlName.ForHousingPurposes"
            />
            <label
              class="form-check-label"
              [attr.for]="controlName.ForHousingPurposes"
              >{{
                'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.FOR_HOUSING_PURPOSES'
                  | translate
              }}</label
            >
          </div>

          <app-business-purposes-checkbox-control
            [formGroup]="getPropertyUsageFormGroup()"
          ></app-business-purposes-checkbox-control>

          <div class="row mt-2">
            <div class="col-12">
              <textarea
                [name]="controlName.Comments"
                [id]="controlName.Comments"
                class="form-control form-control-sm"
                [formControlName]="controlName.Comments"
              ></textarea>
            </div>
          </div>
        </div>
        @if (submitted && !otherInformationsGroupValid) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.SHARE_VALUE_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-request-validation-info
      [shouldShowProvideFieldsAlert]="submitted && !isRequestValid()"
      [errorSubmitMessage]="errorSubmitMessage"
    ></app-request-validation-info>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'OWNERSHIP_CONVERSION_FEE_CALCULATION_REQUEST.SUBMIT' | translate }}
      </button>

      <app-request-preview-button
        class="ms-2"
        [buttonClass]="'btn-outline-primary'"
        [getPreviewRequestBody]="getRequestBody"
        [previewRequestUrl]="
          '/api/interesant/wniosek/naliczenieOplatyPrzeksztalcenieUzytkowaniaWieczystegoWeWlasnosc/podglad'
        "
      ></app-request-preview-button>

      <app-request-cancel-button></app-request-cancel-button>

      <app-doc-sign-info
        [messageText]="docSignMsgTxt"
        [messageType]="docSignMsgType"
        [docSignUrl]="docSignUrl"
        [docSignPending]="docSignPending"
      ></app-doc-sign-info>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
