import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, takeWhile } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  ExtensionMimeType,
  FileExtension,
} from '../../utils/files/files.model';
import { getFileSizeString } from '../../utils/files/files.util';

@Component({
  selector: 'gk-file-input',
  templateUrl: './gk-file-input.component.html',
  styleUrls: ['./gk-file-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GkFileInputComponent implements OnDestroy {
  private isAlive = true;
  @Input() files: File[] = [];
  @Output() filesChange = new EventEmitter<File[]>();
  @Input() mimeTypes: ExtensionMimeType[] = [];
  @Input() extensions: FileExtension[] = [];
  @Input() showPreviews = false;
  @Input() multiple = true;
  @Input() fileNameAsDropText = false;
  @Input() dropValid: boolean;
  @Input() dropText: string;
  @Input() maxSize: number;
  @ViewChild('dragAndDrop') dragAndDrop: ElementRef;

  lastInvalids: { file: File; type: string }[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    public translateService: TranslateService,
  ) {}

  getFileSizeString = getFileSizeString;

  getObjectUrl(file: File): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(file),
    );
  }

  removeFile(index: number): void {
    this.files = this.files.filter((_file, fileIndex) => fileIndex !== index);
    this.filesChange.emit(this.files);
  }

  getFileFormattedExtension(ext: FileExtension): string {
    return `*.${ext}`;
  }

  getAllAvailableFormattedFileExtensions(): string {
    return this.extensions.reduce(
      (formattedExts, currentExt) =>
        formattedExts
          ? `${formattedExts}, ${this.getFileFormattedExtension(currentExt)}`
          : this.getFileFormattedExtension(currentExt),
      '',
    );
  }

  getGeneratedInvalidTypeText(): Observable<string> {
    return this.getInputFileTranslations().pipe(
      takeWhile(() => this.isAlive),
      map(
        (translations) =>
          `${translations['INVALID_EXTENSION']} ${
            translations['POSSIBLE_EXTENSIONS']
          } ${this.getAllAvailableFormattedFileExtensions()}`,
      ),
    );
  }

  getInputFileTranslations(): Observable<{ [key: string]: string }> {
    return this.translateService
      .get('GK.DYNAMIC_LIST.CONTROL_INPUT_FILE')
      .pipe(shareReplay(1));
  }

  getAcceptedMimeTypes(): string {
    return this.mimeTypes ? this.mimeTypes.join(',') : '';
  }

  filesChangeHandler(event: File[]): void {
    if (!this.multiple) {
      this.files = event.slice(-1);
    }
    this.clearNativeInputData();
    this.filesChange.emit(event);
  }

  clearNativeInputData(): void {
    const label = this.dragAndDrop.nativeElement.childNodes[2];
    const input = label.childNodes[1];
    input.value = null;
  }

  hasFilesError(errorType: 'accept' | 'fileSize'): boolean {
    return this.lastInvalids?.some(
      (lastInvalid) => lastInvalid.type === errorType,
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
