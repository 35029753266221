import { ApiNewDokumentPowiazanyDalDto, LawPerson } from '@gk/gk-modules';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export interface ATIOEAIPNewRequestDto extends ApplicantRequestDto {
  SzczegolowyZakres: string;
  DodatkoweWskazania: string;
}

export class ATIOEAIPNewRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    contactData: LawPerson,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
    public detailedScope: string,
    public additionalIndications: string,
  ) {
    super(
      lawPerson,
      contactData,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static fromAppToApi(
    data: ATIOEAIPNewRequest,
    issueRanges?: string[],
    files?: ApiNewDokumentPowiazanyDalDto[],
  ): ATIOEAIPNewRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        issueRanges,
        files,
      }),
      SzczegolowyZakres: data.detailedScope,
      DodatkoweWskazania: data.additionalIndications,
    };
  }
}
