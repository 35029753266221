import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[app-test-phone][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useValue: phoneValidator,
      multi: true,
    },
  ],
  standalone: false,
})
export class PhoneValidatorDirective {}

export function phoneValidator(
  control: UntypedFormControl,
): { phoneInvalid: true } | undefined {
  const value = control.value;
  let invalid = false;

  if (value) {
    const regExp = new RegExp(/[ 0-9-+()]{7,18}/);

    if (!regExp.test(value)) {
      invalid = true;
    }
  }

  return invalid ? { phoneInvalid: true } : undefined;
}
