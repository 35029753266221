import { Component } from '@angular/core';
import { GenericGridToolbarItemMessagesListPayload } from '../gk-kendo-grid.model';

@Component({
  selector: 'gk-kendo-messages-list-grid-wrapper',
  template:
    '<gk-kendo-messages-list-grid [payload]="payload"></gk-kendo-messages-list-grid>',
  standalone: false,
})
export class GkKendoMessagesListGridWrapperComponent {
  payload: GenericGridToolbarItemMessagesListPayload;
}
