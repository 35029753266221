<div class="mt-1">
  <h3 class="mb-1 fs-6">{{ 'FORM_LEGEND.HEADER' | translate }}</h3>
  <ul
    class="list-unstyled d-flex"
    [ngClass]="inRow ? 'flex-row' : 'flex-column'"
  >
    <li>
      <div class="form-control form-control-sm required">
        {{ 'FORM_LEGEND.FIELD_REQUIRED' | translate }}
      </div>
    </li>

    <li [ngClass]="inRow ? 'ms-2' : 'mt-2'">
      <div class="form-control form-control-sm">
        {{ 'FORM_LEGEND.FIELD_NOT_REQUIRED' | translate }}
      </div>
    </li>
  </ul>
</div>
