import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentCode, WebPortal } from '@gk/gk-modules';
import { map, Observable, takeWhile } from 'rxjs';
import { WebPortalService } from '../../../services/web-portal/web-portal.service';

@Component({
  selector: 'app-requests-list',
  templateUrl: './requests-list.component.html',
  standalone: false,
})
export class RequestsListComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input() masterId: number;
  departmentCode: DepartmentCode;
  newRequestFromPlanningDepartmentMode: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public webPortalService: WebPortalService,
    @Inject('window') public window: Window,
  ) {}

  ngOnInit(): void {
    this.subscribeToRouteParams();
    this.subscribeToRouteQueryParams();
  }

  getDepartmentRequestsByCode(
    departmentCode: DepartmentCode,
  ): Observable<WebPortal[]> {
    return this.webPortalService.getWebPortalData().pipe(
      takeWhile(() => this.isAlive),

      map((mainPortalData) =>
        mainPortalData.filter(
          (department) => department.departmentCode === departmentCode,
        ),
      ),
    );
  }

  getRequestsByMasterId(
    masterId: number,
    departmentCode: DepartmentCode,
  ): Observable<WebPortal[]> {
    return this.webPortalService.getWebPortalData().pipe(
      takeWhile(() => this.isAlive),

      map((webPortalRequests) =>
        webPortalRequests.filter(
          (webPortal) =>
            webPortal.masterId === masterId &&
            webPortal.departmentCode === departmentCode,
        ),
      ),
    );
  }

  goToService(url: string): void {
    if (this.departmentCode === DepartmentCode.Planning) {
      const relativeUrl = `${url.split('/').pop()}/new-request`;
      this.router.navigate([relativeUrl]);
    } else {
      this.window.location.href = `${window.location.origin}/${url}`;
    }
  }

  subscribeToRouteParams(): void {
    this.route.params
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((params) => {
        this.departmentCode = params['id'];
        this.masterId = +params['masterId'];
      });
  }

  subscribeToRouteQueryParams(): void {
    this.route.queryParams
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (params) =>
          (this.newRequestFromPlanningDepartmentMode =
            params['newRequestFromPlanningDepartmentMode']),
      );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
