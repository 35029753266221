import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPerson,
  Place,
} from '@gk/gk-modules';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
  ZamowienieDzialkaDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import { RoadExitRequestType } from '../road-exit/road-exit.model';

export interface RENewRequestDto extends ApplicantRequestDto {
  DrogaNumer: string | number;
  MiejscId: string | number;
  PrzedmiotWnioskuZjazd: RoadExitRequestType;
  CelWykorzystaniaNieruchomosci: string;
  CzySposobWykorzystaniaNieruchomosciUlegnieZmianie: boolean;
  ZmianaSposobuWykorzystaniaNieruchomosci: string;
}

export interface PurposeOfPropertyUseChanged {
  willUseOfPropertyChange: boolean;
  changedPurposeOfPropertyUse: string;
}

export class RENewRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    public requestType: RoadExitRequestType,
    public roadNumber: string,
    public roadExitLocationPlace: Place,
    public purposeOfPropertyUse: string,
    public purposeOfPropertyUseChanged: PurposeOfPropertyUseChanged,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: RENewRequest,
    parcels: EgibObject[],
    issueRanges: string[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): RENewRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        issueRanges,
        files,
      }),
      DrogaNumer: data.roadNumber,
      Dzialki: parcels.map((parcel) =>
        ZamowienieDzialkaDto.fromAppToApi(parcel),
      ),
      MiejscId: data.roadExitLocationPlace.id,
      PrzedmiotWnioskuZjazd: data.requestType,
      CelWykorzystaniaNieruchomosci: data.purposeOfPropertyUse,
      CzySposobWykorzystaniaNieruchomosciUlegnieZmianie:
        data.purposeOfPropertyUseChanged.willUseOfPropertyChange,
      ZmianaSposobuWykorzystaniaNieruchomosci:
        data.purposeOfPropertyUseChanged.changedPurposeOfPropertyUse,
    };
  }
}
