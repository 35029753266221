import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { LawPerson, PortalId } from '@gk/gk-modules';

export enum GRPNewGrantRequestControlName {
  LawPerson = 'lawPerson',
  ContactData = 'contactData',
  Permission = 'permission',
  PortalIds = 'portalIds',
}

export enum GRPPermissionControlName {
  PortalName = 'portalName',
  PortalId = 'portalId',
  PermissionNumber = 'permissionNumber',
  RequiredDocumentTypeId = 'requiredDocumentTypeId',
  IsPermissionNumberRequired = 'isPermissionNumberRequired',
  Entity = 'entity',
  LegalBasis = 'legalBasis',
}

export interface GRPPermissionFormGroup {
  [GRPPermissionControlName.PortalName]: FormControl<string>;
  [GRPPermissionControlName.PortalId]: FormControl<PortalId>;
  [GRPPermissionControlName.PermissionNumber]: FormControl<string>;
  [GRPPermissionControlName.RequiredDocumentTypeId]: FormControl<
    string | number
  >;
  [GRPPermissionControlName.IsPermissionNumberRequired]: FormControl<boolean>;
  [GRPPermissionControlName.Entity]: FormControl<string>;
  [GRPPermissionControlName.LegalBasis]: FormControl<string>;
}

export interface GRPNewGrantRequestFormGroup {
  [GRPNewGrantRequestControlName.LawPerson]: FormControl<LawPerson>;
  [GRPNewGrantRequestControlName.ContactData]: FormControl<LawPerson>;
  [GRPNewGrantRequestControlName.PortalIds]: FormControl<PortalId[]>;
  [GRPNewGrantRequestControlName.Permission]: FormArray<
    FormGroup<GRPPermissionFormGroup>
  >;
}
